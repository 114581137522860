/**
 * @author jiangxingshang
 * @date 2020/12/16
 */
import ContextMenu from './context-menu'
import ContextMenuItem from './context-menu-item'

export default {
  install (Vue) {
    Vue.component(ContextMenu.name, ContextMenu)
    Vue.component(ContextMenuItem.name, ContextMenuItem)
  }
}
