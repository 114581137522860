<template>
  <drawer
    :visible.sync="visible"
    :disableFooterButton="true"
    :loading="loading"
    :title="title"
    @close="onClose"
  >

    <a-list bordered :data-source="sessions">
      <a-button slot="header" block @click="loadSessions" type="primary">重新加载</a-button>
      <a-list-item slot="renderItem" slot-scope="s">
        <div>
          <div class="info-detail">
            <div class="detail-item">
              <label>会话Id</label>
              <span>{{s.sessionId}}</span>
            </div>
            <div class="detail-item">
              <label>是否登录</label>
              <span>{{s.authenticated ? '已登录' : '未登录'}}</span>
            </div>
            <div class="detail-item">
              <label>是否过期</label>
              <span>{{s.expire ? '是' : '否'}}</span>
            </div>
            <div class="detail-item">
              <label>创建时间</label>
              <span>{{s.createTime}}</span>
            </div>
            <div class="detail-item">
              <label>最后一次访问</label>
              <span>{{s.lastAccessTime}}</span>
            </div>
            <div class="detail-item">
              <label>会话超时</label>
              <span>{{s.maxInactiveInterval}} 小时</span>
            </div>
            <div class="detail-item">
              <label>浏览器</label>
              <span>{{s.agent}}</span>
            </div>
          </div>
          <div style="text-align: right;" v-if="editable">
            <a-button type="danger"
                      @click="handleKillSession(s)"
                      :loading="s.status === 'loading'"
                      :disabled="s.status === 'delete'"
            >
              <span v-if="s.status === 'delete'">已删除</span>
              <span v-else>删除会话</span>
            </a-button>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </drawer>
</template>

<script>
import { getSessionsByUsername, killUserSession } from '@/http/api/admin'

export default {
  data () {
    return {
      title: '',
      visible: false,
      loading: false,
      username: null,
      sessions: [],
      editable: true
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open (user) {
      this.username = user.username
      this.editable = user.editable
      this.title = this.username + '的会话'
      this.visible = true
      this.loading = true
      this.loadSessions()
    },
    onClose () {
      if (this.loadSessionCancel) {
        this.loadSessionCancel.cancel()
      }
      this.sessions = []
      this.username = null
    },
    handleKillSession (session) {
      this.$confirm({
        title: '确认',
        content: '用户将会被踢出登录状态，确定要删除会话吗？',
        onOk: () => {
          session.status = 'loading'
          killUserSession()
            .complete(() => (session.status = 'normal'))
            .success(resp => {
              session.status = 'delete'
              this.$emit('sessionCount', this.username, resp.data)
            })
            .send(session.sessionId)
        }
      })
    },
    loadSessions () {
      this.loadSessionCancel = getSessionsByUsername()
        .complete(() => (this.loading = false))
        .success(resp => {
          for (const item of resp.data) {
            // normal 正常
            // delete 已删除
            // loading 正在删除
            item.status = 'normal'
          }
          this.sessions = resp.data
        })
        .send(this.username)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>

</style>
