<template>
  <div class="notify">
    <div v-if="notify">
      <div class="info-detail small-label">
        <div class="detail-item">
          <label>时间</label>
          <span>{{notify.notifyTime}}</span>
        </div>
        <div class="detail-item">
          <label>状态</label>
          <span v-if="notify.status === 'new'">未到提醒时间</span>
          <span v-else-if="notify.status === 'notify'" style="color: #a94442">已到提醒时间</span>
          <span v-else-if="notify.status === 'completed'" style="color: #2f790e">提醒结束</span>
        </div>
        <div class="detail-item">
          <label>备注</label>
          <span>{{notify.remark || ''}}</span>
        </div>
      </div>

      <div style="margin-top: 10px; margin-left: 10px;" v-if="notify.status !== 'completed'">
        <a-button size="small" :loading="loading" @click="handleCompleteNotify">结束提醒</a-button>
      </div>
    </div>
    <div v-else class="text-danger font-mini">数据无法显示。</div>
  </div>
</template>

<script>
import { completeNotify } from '@/http/api/influencer-communication'

export default {
  props: {
    communicationId: { type: String },
    notifyJson: { type: String }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    notify () {
      if (this.notifyJson) {
        try {
          return JSON.parse(this.notifyJson)
        } catch (e) {
          return null
        }
      } else {
        return null
      }
    }
  },
  methods: {
    handleCompleteNotify () {
      this.$confirm({
        title: '确认',
        content: '确定要结束提醒吗？',
        onOk: () => {
          this.loading = true
          completeNotify()
            .complete(() => (this.loading = false))
            .success(() => {
              this.notify.status = 'completed'
            })
            .send(this.communicationId)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
