<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="客户档案编辑"
    @close="onClose"
    @save="handleSave"
  >
    <a-form-model :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="名称">
        <a-input v-model="form.name"></a-input>
      </a-form-model-item>
      <a-form-model-item label="关联部门">
        <a-select v-model="form.deptId" style="width: 100%;" :allow-clear="true">
          <a-select-option v-for="d in deptOptions" :key="d.id">{{d.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </drawer>
</template>

<script>

import kit from '@/utils/kit'
import { add, update } from '@/http/api/partner'
import { listAll } from '@/http/api/dept'

function createForm () {
  return {
    id: null,
    name: null,
    deptId: null
  }
}

export default {
  props: {
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: createForm(),
      deptOptions: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open (model) {
      this.visible = true

      if (model) {
        kit.obj.getProperties(this.form, model)
        this.model = model
      }

      if (this.deptOptions.length === 0) {
        listAll().success(resp => (this.deptOptions = resp.data)).send()
      }
    },
    onClose () {
      this.form = createForm()
      this.model = null
    },
    handleSave () {
      const data = Object.assign({}, this.form)
      let api
      if (this.model) {
        api = update
      } else {
        api = add
      }
      this.saveButtonLoading = true
      api()
        .complete(() => (this.saveButtonLoading = false))
        .success(resp => {
          this.visible = false
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
        })
        .send(data)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
