<!-- 物流配货 -->
<template>
  <app-page :route-name="routeName" :page-loading="pageLoading" bg-color="#fff">

    <a-button slot="toolbar" type="primary" @click="handleHandover">确认交付至物流公司</a-button>

    <div slot="top" class="top-content">
      <a-form-model layout="inline" :model="searchParams">
          <a-form-model-item label="配货仓库">
            <a-select v-model="searchParams.warehouseId" style="width: 200px;">
              <a-select-option v-for="w in warehouseHasPrepareItems" :key="w.id">
                {{w.name}} （{{warehousePrepareTotalMap[w.id]}}）
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="SKU">
            <a-input ref="skuInput" v-model="skuInput" @pressEnter="handleSkuInputEnter" placeholder="建议使用扫码枪扫描SKU条码，手动输入的话请按回车键" style="width: 500px;"></a-input>
          </a-form-model-item>
          <a-form-model-item label="最后扫描">
            {{lastedScanSku}}
          </a-form-model-item>
      </a-form-model>
    </div>

    <div slot="center" class="center-content">

      <div class="section sku-list">
        <div class="section-title">配货汇总表</div>
        <div class="section-body">
          <ul class="listing">
            <li class="listing-item col-2" v-for="item in skuTotalList" :key="item.sku">
              <div class="full">{{item.sku}}</div>
              <div class="font-mini">
                数量：<span class="text-danger font-bold" style="margin-right: 8px;">{{item.total}}</span>
                已配货：<span class="font-bold" :class="{'text-danger': item.total !== item.prepareTotal, 'text-success': item.total === item.prepareTotal}">{{item.prepareTotal}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section prepare-waiting">
        <div class="section-title text-warning">待配货订单（{{prepareListWaiting.length}}）</div>
        <div class="section-body">
          <div class="delivery-list">
            <order-delivery
                v-if="prepareListWaitingTop"
                position="left"
                :order-delivery="prepareListWaitingTop"
                @prepareConfirm="handleManualPrepareConfirm"
                @prepareCancel="handleManualPrepareCancel"
            ></order-delivery>
            <order-delivery
                position="left"
                :order-delivery="d"
                v-for="d in prepareListWaitingWithoutTop"
                :key="d.id"
                @prepareConfirm="handleManualPrepareConfirm"
                @prepareCancel="handleManualPrepareCancel"
            ></order-delivery>
          </div>
        </div>
      </div>


      <div class="section prepare-completed">
        <div class="section-title text-success left-right-content">
          <div class="full">已配货订单（{{prepareListCompleted.length}}）</div>
          <div>
            <a-button size="small" type="primary" @click="handleDownloadLabel" :disabled="prepareListCompleted.length === 0" :loading="labelDownloading">下载面单</a-button>
            <a-button size="small" @click="handleExport">导出Excel</a-button>
          </div>
        </div>
        <div class="section-body">
          <div class="delivery-list">
            <order-delivery
                position="right"
                :order-delivery="d"
                v-for="d in prepareListCompleted"
                :key="d.id"
                @prepareConfirm="handleManualPrepareConfirm"
                @prepareCancel="handleManualPrepareCancel"
            ></order-delivery>
          </div>
        </div>
      </div>

    </div>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_DELIVERY_PREPARE } from '@/router/router-constants'
import {
  listPrepare,
  printLabel,
  updateDeliveryItemPrepareStatus,
  updateOrderDeliveryPrepareStatus,
  exportList
} from '@/http/api/order-delivery'
import { listCurrentUserWarehouse } from '@/http/api/warehouse-prepare-user'
import { listSupplier } from '@/http/api/supplier'
import { LOGISTICS_COMPANY_MAPPING } from '@/constants/logistics'
import { ORDER_DELIVERY_PREPARE_STATUS_HANDOVER } from '@/constants/order-delivery-prepare-status'
import { getCheckSuccessSound, getCheckFailSound } from '@/constants/audio'
import { splitSkuAndSupplierCode } from '@/utils/sku-supplier-utils'
import OrderDelivery from './comp/order-delivery'
import moment from 'moment'
import { userStore } from '@/store'

export default {
  components: { OrderDelivery },
  data () {
    return {
      routeName: ROUTE_ORDER_DELIVERY_PREPARE,
      pageLoading: false,
      deliveryPrepareList: [],
      searchParams: {
        warehouseId: null
      },
      logisticsCompanyMapping: LOGISTICS_COMPANY_MAPPING,
      skuInput: null,
      lastedScanSku: null,
      sound: {
        success: getCheckSuccessSound(),
        fail: getCheckFailSound()
      },
      supplierList: [],
      labelDownloading: false,
      // 自己负责的仓库
      myWarehouses: []
    }
  },
  computed: {
    loginUserName () {
      return userStore.state.name
    },
    /**
     * deprecated
     */
    warehouseOptions () {
      const map = {}
      for (const d of this.deliveryPrepareList) {
        for (const item of d.deliveryProductItems) {
          if (!map[item.warehouseId]) {
            map[item.warehouseId] = item.warehouseName
          }
        }
      }
      const arr = []
      for (const key of Object.keys(map)) {
        arr.push({ id: key, name: map[key] })
      }
      return arr
    },
    /**
     * { 仓库Id: 可配货的数量 }
     */
    warehousePrepareTotalMap () {
      const map = {}
      for (const d of this.deliveryPrepareList) {
        for (const item of d.deliveryProductItems) {
          const total = map[item.warehouseId] || 0
          map[item.warehouseId] = total + 1
        }
      }
      return map
    },
    /**
     * 有需要配货的仓库
     */
    warehouseHasPrepareItems () {
      const arr = []
      for (const w of this.myWarehouses) {
        if ((this.warehousePrepareTotalMap[w.id] || 0) > 0) {
          arr.push(w)
        }
      }
      return arr
    },
    prepareListBySelectedWarehouse () {
      const arr = []
      for (const d of this.deliveryPrepareList) {
        for (const item of d.deliveryProductItems) {
          if (item.warehouseId === this.searchParams.warehouseId) {
            arr.push(d)
            break
          }
        }
      }
      return arr
    },
    prepareListWaiting () {
      const arr = []
      for (const d of this.prepareListBySelectedWarehouse) {
        let notPrepareReady = false
        for (const item of d.deliveryProductItems) {
          if (!item.prepare) {
            notPrepareReady = true
            break
          }
        }
        if (notPrepareReady) {
          arr.push(d)
        }
      }
      return arr
    },
    prepareListWaitingWithoutTop () {
      const arr = []
      for (const d of this.prepareListWaiting) {
        if (!d.isTop) arr.push(d)
      }
      return arr
    },
    prepareListWaitingTop () {
      for (const d of this.prepareListBySelectedWarehouse) {
        if (d.isTop) {
          return d
        }
      }
      return null
    },
    prepareListCompleted () {
      const arr = []
      for (const d of this.prepareListBySelectedWarehouse) {
        let prepared = true
        for (const item of d.deliveryProductItems) {
          if (!item.prepare) {
            prepared = false
            break
          }
        }
        if (prepared) {
          arr.push(d)
        }
      }
      return arr
    },
    skuTotalList () {
      const map = {}
      for (const d of this.prepareListBySelectedWarehouse) {
        for (const item of d.deliveryProductItems) {
          let obj = map[item.sku]
          if (!obj) {
            obj = { sku: item.sku, total: 0, prepareTotal: 0 }
            map[item.sku] = obj
          }
          obj.total += item.total
          obj.prepareTotal += item.prepareTotal
        }
      }
      const list = []
      for (const key of Object.keys(map)) {
        list.push(map[key])
      }
      return list
    },
    supplierCodeNameMap () {
      const s = {}
      for (const item of this.supplierList) {
        s[item.code] = item.name
      }
      return s
    }
  },
  methods: {
    loadPrepareList () {
      this.pageLoading = true
      listPrepare()
        .complete(() => (this.pageLoading = false))
        .success(resp => {
          for (const d of resp.data) {
            d.isTop = false // 置顶
            for (const item of d.deliveryProductItems) {
              item.prepareTotal = item.prepare ? item.total : 0 // 已经配货的SKU数量
              item.prepareLoading = false
            }
          }
          this.deliveryPrepareList = resp.data
          if (this.warehouseHasPrepareItems.length > 0) {
            this.searchParams.warehouseId = this.warehouseHasPrepareItems[0].id
          }
        })
        .send()
    },
    handleSkuInputEnter () {
      this.lastedScanSku = this.skuInput
      this.prepareSku(this.skuInput)
      this.skuInput = null
    },
    showMessageAndFocusInput (type, message) {
      this[type === 'info' ? '$info' : '$error']({
        title: type === 'info' ? '提示' : '错误',
        content: message,
        onOk: () => {
          this.$refs.skuInput.focus()
        }
      })
    },
    prepareSku (sku) {
      const skuObj = splitSkuAndSupplierCode(sku)
      const supplierCode = skuObj.supplierCode
      sku = skuObj.sku
      // 找出第一个匹配的SKU的物流单
      let prepareOrderDelivery = null
      let deliveryProductItem = null
      let foundButPrepared = false
      let foundButSupplierIncorrect = false
      let foundButSupplierIncorrectCode = null
      let found = false
      for (const d of this.prepareListWaiting) {
        prepareOrderDelivery = d
        for (const item of d.deliveryProductItems) {
          deliveryProductItem = item
          if (item.sku === sku) {
            // 找到了一个SKU，但是该SKU已经配货完成，继续尝试查找
            if (item.prepare) {
              foundButPrepared = true
              continue
            }

            // 找到了一个SKU，但是供应商不匹配，继续尝试查找
            if (item.fromSupplierCode && item.fromSupplierCode !== supplierCode) {
              foundButSupplierIncorrect = true
              foundButSupplierIncorrectCode = item.fromSupplierCode
              continue
            }

            // 找到匹配的SKU，结束循环
            if (!item.fromSupplierCode || item.fromSupplierCode === supplierCode) {
              found = true
              break
            }
          }
        }
        if (found) {
          break
        }
      }

      if (found) {
        // 匹配到第一个发货订单，将它置顶
        if (this.prepareListWaitingTop) {
          this.prepareListWaitingTop.isTop = false
        }
        prepareOrderDelivery.isTop = true

        // 这个SKU已经备齐
        deliveryProductItem.prepareTotal++
        if (deliveryProductItem.prepareTotal === deliveryProductItem.total) {
          this.updatePrepareStatus(prepareOrderDelivery, deliveryProductItem, true, success => {
            if (success) {
              this.sound.success.play()
            } else {
              this.sound.fail.play()
              deliveryProductItem.prepareTotal--
              this.showMessageAndFocusInput('error', '发生错误，请重新扫码。')
            }
          })
        } else {
          // 只是找到SKU，还没有全部配备完成
          this.sound.success.play()
        }

      } else if (foundButPrepared) {
        this.showMessageAndFocusInput('info', `SKU【${sku}】已经配备完毕，请不要重复扫描。`)
      } else if (foundButSupplierIncorrect) {
        const scanSupplierName = this.supplierCodeNameMap[(supplierCode || 'none')] || '通用'
        const deliverySkuSupplierName = this.supplierCodeNameMap[(foundButSupplierIncorrectCode || 'none')] || '通用'
        const msg = `供应商不匹配，扫描的SKU的供应商为【${scanSupplierName}】，匹配到的SKU的供应商为【${deliverySkuSupplierName}】`
        this.showMessageAndFocusInput('info', msg)
      } else {
        // 没有找到匹配的SKU，发出报警
        this.sound.fail.play()
        this.showMessageAndFocusInput('error', `待配货订单中没有找到此SKU【${sku}】`)
      }
    },
    updatePrepareStatus (orderDelivery, deliveryProductItem, isPrepare, callback) {
      updateDeliveryItemPrepareStatus()
        .success(() => {
          deliveryProductItem.prepare = isPrepare
          if (isPrepare) {
            if (isPrepare) {
              deliveryProductItem.prepareUserName = this.loginUserName
              deliveryProductItem.prepareTime = moment().format('YYYY-MM-DD')
            } else {
              deliveryProductItem.prepareUserName = null
              deliveryProductItem.prepareTime = null
            }
            if (this.isAllPrepareReady(orderDelivery)) {
              orderDelivery.isTop = false
              this.$message.success(`${orderDelivery.orderNo} 已经全部配货完成。`)
            } else {
              this.$message.success(`${deliveryProductItem.sku} 已配货完成。`)
            }
          }
          // eslint-disable-next-line standard/no-callback-literal
          callback(true)
        })
        .error(() => {
          // eslint-disable-next-line standard/no-callback-literal
          callback(false)
        })
        .send(deliveryProductItem.id, isPrepare)
    },
    isAllPrepareReady (orderDelivery) {
      const prepareLength = orderDelivery.deliveryProductItems.filter(item => item.prepare).length
      return prepareLength === orderDelivery.deliveryProductItems.length
    },
    handleManualPrepareConfirm (orderDelivery, deliveryProductItem) {
      deliveryProductItem.prepareLoading = true
      this.updatePrepareStatus(orderDelivery, deliveryProductItem, true, success => {
        deliveryProductItem.prepareLoading = false
        deliveryProductItem.prepareTotal = deliveryProductItem.total
        if (!success) {
          this.$message.error('发生错误。')
        }
      })
    },
    handleManualPrepareCancel (orderDelivery, deliveryProductItem) {
      deliveryProductItem.prepareLoading = true
      this.updatePrepareStatus(orderDelivery, deliveryProductItem, false, success => {
        deliveryProductItem.prepareLoading = false
        if (success) {
          deliveryProductItem.prepareTotal = 0
        } else {
          this.$message.error('发生错误。')
        }
      })
    },
    handleHandover () {
      if (this.prepareListCompleted.length > 0) {
        this.$confirm({
          title: '确认',
          content: '确定要更新已配货订单的交付状态吗？',
          onOk: () => {
            const arr = []
            for (const d of this.prepareListCompleted) {
              arr.push(d.id)
            }
            this.pageLoading = true
            updateOrderDeliveryPrepareStatus()
              .complete(() => (this.pageLoading = false))
              .success(() => {
                this.$message.success('包裹已交付。')
                this.loadPrepareList()
              })
              .send(arr, ORDER_DELIVERY_PREPARE_STATUS_HANDOVER)
          }
        })
      } else {
        this.$message.error('没有可交付的订单。')
      }
    },
    /**
     * 下载已经配货的面单
     */
    handleDownloadLabel () {
      const arr = []
      for (const item of this.prepareListCompleted) {
        arr.push(item.id)
      }
      if (arr.length > 0) {
        this.labelDownloading = true
        printLabel()
          .complete(() => (this.labelDownloading = false))
          .success(resp => {
            window.open(resp.data)
          })
          .send(arr)
      }
    },
    handleExport () {
      const arr = []
      for (const item of this.prepareListCompleted) {
        arr.push(item.id)
      }
      const p = {
        idList: arr
      }
      window.open(exportList(p))
    }
  },
  mounted () {
    this.loadPrepareList()
    listSupplier().success(resp => (this.supplierList = resp.data)).send()
    listCurrentUserWarehouse().success(resp => (this.myWarehouses = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

.top-content {
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.center-content {
  display: flex;
  .section + .section {
    margin-left: 20px;
  }
  .section {
    border: solid 1px #eee;
    background-color: #fff;
  }
  .section-title {
    height: 45px;
    line-height: 45px;
    padding: 0 10px;
    border-bottom: solid 1px #eee;
    font-size: 16px;
    font-weight: bold;
  }
  .section-body {
    padding: 10px;
  }
}

.sku-list {
  width: 380px;
  .listing-item:hover {
    background-color: #f9f9f9;
  }
}

.prepare-waiting, .prepare-completed {
  flex: .5;
  max-width: 440px;
}

.delivery-list {
  flex: 1;
}

.delivery-item + .delivery-item {
  margin-top: 20px;
}

</style>
