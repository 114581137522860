<template>
  <div class="container">
    <div class="top" style="margin-top: 0;">
      <a-button icon="plus" type="primary" @click="$refs.returnEditor.open(order.orderNo)">新建退货</a-button>
      <a-button icon="reload" @click="reload()">刷新</a-button>
    </div>
    <div class="bottom" ref="dataContainer">
      <a-table
          slot="center"
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
          :scroll="{ y: antdTableY }"
          :loading="dataLoading"
      >
        <div slot="action" slot-scope="v, record">
          <a-button size="small" @click="$refs.returnEditor.open(record)">编辑</a-button>
          <a-button size="small" type="danger" @click="removeItem(record)" icon="delete"></a-button>
        </div>
        <div slot="status" slot-scope="status">
          <span class="status" :class="'status-' + status">{{statusMapping[status]}}</span>
        </div>
        <div slot="returnItems" slot-scope="returnItems">
          <div class="image-item-wrapper">
            <a-tooltip v-for="item in returnItems" :key="item.id">
              <template slot="title">
                {{item.sku}}
              </template>
              <div class="image-item small" v-lazy:background-image="item.imageUrl" @click="$imageModal.open(item.imageUrl)">
                <span class="total" :class="{'total-more': item.quantity > 1}">{{item.quantity}}</span>
              </div>
            </a-tooltip>
          </div>
        </div>
        <div slot="trackNo" slot-scope="trackNo, record">
          <a v-if="record.trackUrl" :href="record.trackUrl" target="_blank">{{trackNo}}</a>
          <span v-else>{{trackNo}}</span>
        </div>
      </a-table>
    </div>

    <return-editor ref="returnEditor" @saved="reload()"></return-editor>
  </div>
</template>

<script>
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  ORDER_RETURN_STATUS_LIST,
  ORDER_RETURN_STATUS_MAPPING
} from '@/constants/order-return-status'
import { list, deleteById } from '@/http/api/order-return'
import ReturnEditor from '@/pages/aftersale/return/comp/return-editor'
import kit from '@/utils/kit'

export default {
  components: { ReturnEditor },
  props: {
    order: { type: Object }
  },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'action' } },
        { title: '订单号', dataIndex: 'orderNo' },
        { title: '客户邮箱', dataIndex: 'customerEmail' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '创建日期', dataIndex: 'createTime' },
        { title: '签收日期', dataIndex: 'deliveryTime' },
        { title: '跟踪号', dataIndex: 'trackNo', scopedSlots: { customRender: 'trackNo' } },
        { title: '物流跟踪号', dataIndex: 'logisticsName' },
        { title: '退货物品', dataIndex: 'returnItems', scopedSlots: { customRender: 'returnItems' } },
        { title: '备注', dataIndex: 'remark' }
      ],
      statusOptions: ORDER_RETURN_STATUS_LIST,
      statusMapping: ORDER_RETURN_STATUS_MAPPING
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
      this.$nextTick(() => {
        this.contentHeight = this.$refs.dataContainer.clientHeight
      })
    },
    onInactive () {
    },
    onInit () {
      this.reload()
    },
    reload () {
      const p = {
        orderNo: this.order.orderNo,
        start: 0,
        limit: 100
      }
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
        })
        .send(p)
    },
    removeItem (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.orderNo}】吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteById()
            .complete(() => (this.dataLoading = false))
            .success(resp => {
              kit.arr.removeItem(this.dataList, item)
              this.$message.success('删除成功。')
            })
            .send(item.id)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}

.bottom {
  flex: 1;
  overflow: hidden;
}

.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 10;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .total-more {
    background-color: #bb3e30;
  }
}
</style>
