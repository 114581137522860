<template>
  <modal title="佣金批量配置" ref="modal" :hide-buttons="true" :width="800">
    <div class="shop-list">
      <label>正在为这些店铺设置佣金配置：</label>
      <div class="shop-panel">
        <span v-for="s in shops" :key="s.id">{{s.name}}</span>
      </div>
    </div>
    <fee-config-panel @saved="onFeeConfigPanelSaved" :shop-id-list="shopIdList"></fee-config-panel>
  </modal>
</template>

<script>
import FeeConfigPanel from '@/pages/shop/comp/fee-config-panel'
export default {
  components: { FeeConfigPanel },
  data () {
    return {
      shops: []
    }
  },
  computed: {
    shopIdList () {
      const arr = []
      for (const s of this.shops) {
        arr.push(s.id)
      }
      return arr
    }
  },
  methods: {
    open (shops) {
      this.shops = shops
      this.$refs.modal.open()
    },
    onFeeConfigPanelSaved () {
      this.$refs.modal.close()
      this.$emit('saved')
    }
  }
}
</script>

<style lang="less" scoped>
.shop-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
  .shop-panel {
    flex: 1;
    span {
      display: inline-block;
      margin: 5px;
      background-color: #2b2b2b;
      color: #fff;
      border-radius: 5px;
      font-size: 12px;
      padding: 5px;
    }
  }
}
</style>
