<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button @click="$refs.influencerEditor.open()" slot="toolbar">添加达人</a-button>
    <a-button @click="$refs.influencerImport.open()" slot="toolbar">导入达人</a-button>
    <a-button @click="handleOpenBatchEditor" slot="toolbar">批量更新</a-button>
    <a-button @click="$refs.statusManager.open()" slot="toolbar" v-authority="$authority.influencerManager">合作状态管理</a-button>
    <a-button @click="$refs.config.open()" slot="toolbar" v-authority="$authority.influencerManager">合作状态查询标签配置</a-button>
    <a-button @click="$refs.categoryManager.open()" slot="toolbar" v-authority="$authority.influencerManager">分类管理</a-button>

    <div slot="top">

      <div class="total-tab">
        <ul>
          <li v-for="tab in tabs"
              :key="tab.id"
              @click="handleTabSelected(tab)"
              :class="{'selected': tab.selected }">
            {{tab.title}}
            <span class="total">( {{tab.total}} )</span>
          </li>
        </ul>
      </div>

      <div class="search-form" style="margin-top: 5px;">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <div>
            <a-form-model-item label="Handle">
              <a-input v-model="searchParams.cid" :allow-clear="true" style="width: 140px;" />
            </a-form-model-item>
            <a-form-model-item label="邮箱">
              <a-input v-model="searchParams.email" :allow-clear="true" style="width: 200px;" />
            </a-form-model-item>
            <a-form-model-item label="标签">
              <a-input v-model="searchParams.tag" :allow-clear="true" style="width: 220px;"/>
            </a-form-model-item>
            <a-form-model-item label="合作产品">
              <a-input v-model="searchParams.sku" placeholder="请填写SKU" :allow-clear="true" style="width: 220px;"/>
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="回- -复">
              <a-select v-model="searchParams.myself" style="width: 140px;">
                <a-select-option :value="null">全部</a-select-option>
                <a-select-option value="yes">我有回复的</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="分类">
              <a-select v-model="searchParams.influencerCategoryId" style="width: 200px;" :allow-clear="true">
                <a-select-option v-for="item in optionsCategory" :key="item.id">
                  <span style="display: inline-block;" :style="{'padding-left': !item.parent ? '30px' : '0'}">{{item.name}}</span>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="收藏">
              <a-select v-model="searchParams.fav" style="width: 220px;">
                <a-select-option key="all">全部</a-select-option>
                <a-select-option key="t">我的收藏</a-select-option>
                <a-select-option key="f">我未收藏</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="合作状态" v-if="selectedTotalTab && selectedTotalTab.id === 'all'">
              <a-select v-model="searchParams.cooperationStatusId" :allow-clear="true"  style="width: 220px;">
                <a-select-opt-group v-for="item in optionsCooperationStatus" :key="item.id">
                  <span slot="label">{{item.name}}</span>
                  <a-select-option v-for="c in item.children" :key="c.id">{{c.name}}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="性别" >
              <a-select v-model="searchParams.sex" :allow-clear="true"  style="width: 50px;">
                <a-select-option :value="1">男</a-select-option>
                <a-select-option :value="0">女</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData()" icon="reload">查询</a-button>
        </div>
      </div>


    </div>

    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="actions" slot-scope="row">
        <a-button size="small" @click="navDetail(row)">沟通</a-button>
        <a-button size="small" @click="handleFavourite(row)" icon="heart" :type="favouriteIdMap[row.id] ? 'primary' : ''"></a-button>
      </div>

      <div slot="cid" slot-scope="row">
        <span class="font-normal" target="_blank" v-if="row.cooperationStatusName" :style="{'color': row.cooperationStatusColor}" style="font-weight: bold;" @click="handleCopyCid(row.cid)">{{row.cid}}</span>
        <span class="font-normal" target="_blank" style="color: #000;" v-else @click="handleCopyCid(row.cid)">{{row.cid}}</span>
      </div>

      <div slot="tags" slot-scope="row">
        <div v-if="row.tags">
          <a-tag color="blue" v-for="t in row.tags.split(',')" style="margin-bottom: 4px;" :key="t">{{t}}</a-tag>
        </div>
      </div>

      <div slot="followerCount" slot-scope="followerCount">
        {{followerCount | fmtNumber}}
      </div>

      <div slot="viewCount" slot-scope="viewCount">
        {{viewCount | fmtNumber}}
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <cooperation-status-manager ref="statusManager" @updated="onCooperationStatusManagerUpdated"></cooperation-status-manager>
    <influencer-category-manager ref="categoryManager" @updated="onInfluencerCategoryManagerUpdated"></influencer-category-manager>
    <influencer-cooperation-status-search-config ref="config" @updated="handleCooperationStatusSearchConfigUpdated"></influencer-cooperation-status-search-config>
    <influencer-editor ref="influencerEditor"></influencer-editor>
    <influencer-import ref="influencerImport"></influencer-import>
    <influencer-batch-update-editor ref="batchEditor" @updated="reloadData()"></influencer-batch-update-editor>
  </app-page>
</template>

<script>
import { ROUTE_INFLUENCER, ROUTE_INFLUENCER_DETAIL } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import CooperationStatusManager from '@/pages/influencer/comp/cooperation-status-manager'
import InfluencerCategoryManager from '@/pages/influencer/comp/influencer-category-manager'
import InfluencerEditor from '@/pages/influencer/comp/influencer-editor'
import InfluencerImport from '@/pages/influencer/comp/influencer-import'
import InfluencerCooperationStatusSearchConfig from '@/pages/influencer/comp/cooperation-status-search-config'
import influencerBatchUpdateEditor from '@/pages/influencer/comp/influencer-batch-update-editor'
import { list, getFavouriteId, updateFavourite, getCooperationStatusSearchConfig } from '@/http/api/influencer'
import { listAll as listCategories } from '@/http/api/influencer-category'
import { listOptions as listCooperationStatus } from '@/http/api/cooperation-status'
import kit from '@/utils/kit'

export default {
  components: {
    CooperationStatusManager,
    InfluencerCategoryManager,
    InfluencerEditor,
    InfluencerImport,
    InfluencerCooperationStatusSearchConfig,
    influencerBatchUpdateEditor
  },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_INFLUENCER,
      tabs: [],
      searchParamsByTab: null,
      dataList: [],
      pageLoading: false,
      dataLoading: false,
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'actions' }, width: 100 },
        { title: 'Handle', scopedSlots: { customRender: 'cid' }, width: 140 },
        { title: '昵称', dataIndex: 'name', width: 120 },
        { title: '性别', dataIndex: 'sex', width: 80, customRender: sex => sex === 1 ? '男' : (sex === 0 ? '女' : '') },
        { title: '邮箱', dataIndex: 'email', width: 150 },
        {
          title: '分类',
          width: 100,
          customRender: row => {
            return (row.influencerCategoryName || '') +
                (row.influencerSubCategoryName ? ' / ' + row.influencerSubCategoryName : '')
          }
        },
        { title: '评分', dataIndex: 'cooperationScore', width: 50 },
        { title: '粉丝数', dataIndex: 'followerCount', width: 70, scopedSlots: { customRender: 'followerCount' } },
        { title: '平均播放量', dataIndex: 'viewCount', width: 70, scopedSlots: { customRender: 'viewCount' } },
        { title: '成交金额/$', dataIndex: 'transactionAmount', width: 100 },
        { title: '标签', scopedSlots: { customRender: 'tags' } },
        { title: '备注', dataIndex: 'remark' }
      ],
      selectedRowKeys: [],
      optionsCategory: [],
      optionsCooperationStatus: [],
      favouriteIds: [],
      adminOptions: []
    }
  },
  filters: {
    fmtNumber (n) {
      if (n >= 1000000000) {
        return kit.num.toFixedTrimZero(n / 1000000000, 1) + '亿'
      } else if (n >= 10000) {
        return kit.num.toFixedTrimZero(n / 10000, 1) + '万'
      } else {
        return n
      }
    }
  },
  computed: {
    favouriteIdMap () {
      const map = {}
      for (const s of this.favouriteIds) {
        map[s] = true
      }
      return map
    },
    selectedTotalTab () {
      return this.tabs.find(item => item.selected)
    },
    searchParams () {
      if (this.selectedTotalTab) {
        return this.searchParamsByTab[this.selectedTotalTab.id]
      } else {
        return this.buildSearchParams('all')
      }
    }
  },
  methods: {
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    loadData () {
      const params = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      this.selectedRowKeys = []
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(params)
    },
    loadTotals () {
      for (const tab of this.tabs) {
        const p = this.buildSearchParams(tab.id)
        p.limit = 0
        list()
          .success(resp => {
            tab.total = resp.data.total
          })
          .send(p)
      }
    },
    navDetail (row) {
      this.$router.push({ name: ROUTE_INFLUENCER_DETAIL, params: { id: row.id }})
    },
    handleFavourite (row) {
      const r = this.favouriteIdMap[row.id]
      updateFavourite().send(row.id, !r)
      if (r) {
        kit.arr.removeItem(this.favouriteIds, row.id)
      } else {
        this.favouriteIds.push(row.id)
      }
    },
    onInfluencerCategoryManagerUpdated () {
      listCategories().success(resp => {
        const arr = []
        for (const p of resp.data) {
          arr.push({ id: p.id, name: p.name, parent: true })
          for (const c of p.children) {
            arr.push({ id: c.id, name: c.name, parent: false })
          }
        }
        this.optionsCategory = arr
      }).send()
    },
    onCooperationStatusManagerUpdated () {
      listCooperationStatus().success(resp => (this.optionsCooperationStatus = resp.data)).send()
    },
    buildSearchParams (cooperationStatus) {
      const p = {
        cid: null,
        email: null,
        sku: null,
        influencerCategoryId: null,
        tag: null,
        cooperationStatusId: null,
        fav: 'all',
        myself: null,
        notify: null,
        sex: null
      }
      if (cooperationStatus === 'notify') {
        p.notify = 'yes'
      } else if (cooperationStatus !== 'all') {
        p.cooperationStatusId = cooperationStatus
      }
      return p
    },
    initTabs (cooperationStatusOptions) {
      const arr = []
      for (const opt of cooperationStatusOptions) {
        if (!opt.enabled) continue
        arr.push({ id: opt.id, title: opt.name, total: 0, selected: false })
      }
      arr.push({ id: 'notify', title: '提醒', total: 0, selected: false })
      arr.push({ id: 'all', title: '全部', total: 0, selected: false })
      arr[0].selected = true
      this.tabs = arr

      // build search params for each tab
      const paramsMap = {}
      for (const t of this.tabs) {
        paramsMap[t.id] = this.buildSearchParams(t.id)
      }
      this.searchParamsByTab = paramsMap

      this.loadData()
      this.loadTotals()
    },
    handleTabSelected (tab) {
      if (tab.selected) {
        tab.selected = false
      } else {
        this.tabs.forEach(item => (item.selected = false))
        tab.selected = true
      }
      this.reloadData()
    },
    handleCooperationStatusSearchConfigUpdated (config) {
      this.initTabs(config)
    },
    handleOpenBatchEditor () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要更新的行。')
      }
      const map = {}
      for (const k of this.selectedRowKeys) {
        map[k] = true
      }
      const rows = []
      for (const item of this.dataList) {
        if (map[item.id]) {
          rows.push(item)
        }
      }
      this.$refs.batchEditor.open(rows)
    },
    handleCopyCid (cid) {
      this.$copyText(cid).then(() => {
        this.$message.success('复制成功')
      })
    }
  },
  mounted () {
    getCooperationStatusSearchConfig().success(resp => (this.initTabs(resp.data))).send()
    this.onInfluencerCategoryManagerUpdated()
    this.onCooperationStatusManagerUpdated()
    getFavouriteId().success(resp => (this.favouriteIds = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
.total-tab {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 4px 20px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    &:hover {
      color: #bb3e30;
    }
    &.selected {
      border-bottom: solid 2px #bb3e30;
    }
    &.attention {
      font-weight: bold;
      background-color: #fabfc1;
      border-radius: 5px;
    }
    .total {
      margin-left: 4px;
      color: #bb3e30;
      font-size: 12px;
    }
  }
  li + li {
    margin-left: 14px;
  }
}
</style>
