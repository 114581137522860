<template>
  <div>
    <div class="dept-coop" v-for="d in deptCooperationStatusList" :key="d.deptId">
      <div class="dept-name">{{d.deptName}}</div>
      <div class="status-panel">
        <div class="group" v-for="c in cooperationStatusOptions" :key="c.id">
          <div class="group-name">{{c.name}}</div>
          <div class="sub-list">
            <div class="sub-item"
                 :class="{'checked': d.cooperationStatusIdList.includes(cc.id)}"
                 v-for="cc in c.children"
                 :key="cc.id"
                 @click="handleSetStatus(d, c, cc)"
            >
              {{cc.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listOptions as listCooperationStatusOptions } from '@/http/api/cooperation-status'
import { getCooperationStatusList, setCooperationStatus } from '@/http/api/influencer'
import kit from '@/utils/kit'

export default {
  props: {
    influencer: { type: Object, required: false }
  },
  data () {
    return {
      /**
       * {
       *   deptId: String,
       *   deptName: String,
       *   cooperationStatusIdList: [String]
       * }
       */
      deptCooperationStatusList: [],
      cooperationStatusOptions: [],
      updating: false
    }
  },
  watch: {
    influencer () {
      if (this.influencer) {
        this.initData()
      }
    }
  },
  methods: {
    initData () {
      listCooperationStatusOptions().success(resp => (this.cooperationStatusOptions = resp.data)).send()
      this.loadDeptCooperationStatusList()
    },
    loadDeptCooperationStatusList () {
      getCooperationStatusList()
        .success(resp => {
          this.deptCooperationStatusList = resp.data
        })
        .send(this.influencer.id)
    },
    handleSetStatus (dept, group, status) {
      if (this.updating) return
      this.updating = true
      const statusArr = []
      let removeId, setId
      if (dept.cooperationStatusIdList.includes(status.id)) {
        // 已经是选中状态，将移除
        statusArr.push('remove:' + status.id)
        removeId = status.id
      } else {
        // 将当前选中，找到同组中另一个已选中的，将移除
        for (const c of group.children) {
          if (dept.cooperationStatusIdList.includes(c.id)) {
            statusArr.push('remove:' + c.id)
            removeId = c.id
          }
        }
        statusArr.push('set:' + status.id)
        setId = status.id
      }
      setCooperationStatus()
        .complete(() => (this.updating = false))
        .success(() => {
          if (removeId) kit.arr.removeItem(dept.cooperationStatusIdList, removeId)
          if (setId) dept.cooperationStatusIdList.push(setId)
        })
        .send(this.influencer.id, dept.deptId, statusArr.join(','))
    }
  }
}
</script>

<style lang="less" scoped>
.dept-coop + .dept-coop {
  margin-top: 20px;
}
.dept-coop {
  .dept-name {
    margin-bottom: 8px;
    font-size: 18px;
    text-align: center;
  }
  .status-panel {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    .group {
      border-radius: 5px;
      border: solid 1px #ddd;
      .group-name {
        padding: 5px 10px;
        font-weight: bold;
        border-bottom: solid 1px #ddd;
      }
      .sub-list {
        margin: 5px 0;
        .sub-item {
          padding: 3px 10px;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            background-color: #f9f9f9;
          }
        }
        .sub-item.checked {
          background-color: #37557a;
          color: #fff;
        }
      }
    }
  }
}
</style>
