<!--
  将一组客户订单里的SKU分组，按 sku / 订单号1，订单号2的形式展示，方便用户知道
  这批订单里都有哪些SKU。
-->
<template>
  <drawer
    :visible.sync="visible"
    :disableFooterButton="true"
    title="订单SKU"
    :width="1200"
  >
    <div class="content-center text-primary" style="padding: 10px 0;">共 {{skuList.length}} 个SKU</div>
    <a-table
        class="data-table"
        size="middle"
        rowKey="sku"
        :columns="dataColumns"
        :data-source="skuList"
        :pagination="false"
    >
      <div slot="image" slot-scope="imageUrl">
        <div class="image-item" v-lazy:background-image="imageUrl" @click="$imageModal.open([imageUrl])"></div>
      </div>
      <div slot="sku" slot-scope="v, record">
        {{record.sku}} ({{record.total}})
      </div>
      <div slot="stock" slot-scope="v, record">
        <span v-if="record.stocks.length === 0" class="text-danger">无库存</span>
        <div v-else class="stock-item" v-for="s in record.stocks" :key="s.id">
          {{s.warehouseName}} ({{s.quantity}})
        </div>
      </div>
      <div slot="order" slot-scope="v, record">
        <span class="order-number" v-for="o in record.orders" :key="o.id" @click="$emit('orderClick', o.id)">
          {{o.orderNo}} ({{o.skuTotal}})
        </span>
      </div>
    </a-table>
  </drawer>
</template>

<script>
import { getStockBySku } from '@/http/api/stock-product'

export default {
  data () {
    return {
      visible: false,
      skuList: [],
      dataColumns: [
        { title: '图片', width: 100, dataIndex: 'imageUrl', scopedSlots: { customRender: 'image' } },
        { title: 'SKU', width: 180, dataIndex: 'sku', scopedSlots: { customRender: 'sku' } },
        { title: '库存', width: 150, scopedSlots: { customRender: 'stock' } },
        { title: '订单', scopedSlots: { customRender: 'order' } }
      ]
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open (orders) {
      this.visible = true
      const skuMap = {}
      for (const o of orders) {
        for (const p of o.productItems) {
          const sku = p.sku || '无SKU'
          let item = skuMap[sku]
          if (item === undefined) {
            item = {
              sku: sku,
              total: 0,
              imageUrl: p.imageUrl,
              stocks: [],
              orders: [] // { id, orderNo, skuTotal }
            }
            skuMap[sku] = item
          }
          if (!item.orders.includes(o.orderNo)) {
            item.orders.push({ id: o.id, orderNo: o.orderNo, skuTotal: p.total })
          }
          item.total += p.total
        }
      }
      const skuList = Object.keys(skuMap).join(',')
      getStockBySku()
        .success(resp => {
          const items = Object.values(skuMap)
          for (const item of items) {
            const stocks = resp.data[item.sku] || []
            const realStocks = []
            for (const s of stocks) {
              if (s.quantity > 0) {
                realStocks.push(s)
              }
            }
            item.stocks = realStocks
          }
          items.sort((a, b) => (a.sku > b.sku) ? 1 : ((b.sku > a.sku) ? -1 : 0))
          this.skuList = items
        })
        .send(skuList)
    },
    onClose () {
      this.orders = []
    }
  }
}
</script>

<style lang="less" scoped>
  .order-number {
    display: inline-block;
    margin: 2px 10px;
    color: #337ab7;
    cursor: pointer;
  }
</style>
