<template>
  <div class="file-upload" :style="style" :class="{full: full, mini: !full}">
    <div class="top" @click="full = !full">
      <div class="top-wrapper">
        <div class="progress-out">
          <div class="progress-inner">
            <div class="progress-current" :style="{'width': `${uploadingFileProgress}%`}"></div>
          </div>
        </div>
        <div class="text">
          <div class="uploading-file-name">{{uploadingFileName}}</div>
          <div class="progress-total" v-show="files.length > 0">{{files.length - filesWaitForUpload.length}} / {{files.length}}</div>
        </div>
      </div>
    </div>
    <div class="body" v-show="full">
      <div class="file-item" v-for="f in files" :key="f.id">
        <div class="action">
          <span class="remove" @click="abortUploadFile(f)">
            <a-icon type="close" />
          </span>
        </div>
        <div class="name">
          {{f.name}}
          <div v-if="f.status === 'error'" class="text-danger" style="padding-bottom: 5px;">{{f.error.message}}</div>
        </div>
        <div class="size">{{f.size | fmtSize}}</div>
        <div
            class="status"
            :class="{
              'error': f.status === 'error',
              'success': f.status === 'success',
              'uploading': f.status === 'uploading',
              'cancel': f.status === 'cancel'
            }"
        >
          <span v-if="f.status === 'error'">错误</span>
          <span v-else-if="f.status === 'wait'">等待</span>
          <span v-else-if="f.status === 'success'">成功</span>
          <span v-else-if="f.status === 'uploading'">上传中</span>
          <span v-else-if="f.status === 'cancel'">已取消</span>
        </div>
      </div>
    </div>
    <div class="bottom" v-show="full">
      <a-button size="small" type="danger" :disabled="!isUploading" @click="handleStopUpload">停止上传</a-button>
      <a-button size="small" type="primary" :disabled="isUploading || filesWaitForUpload.length === 0" @click="startUpload">开始上传</a-button>
      <a-button size="small" @click="handleClearUploadList" :disabled="isUploading || files.length === 0">清空列表</a-button>
      <a-button size="small" @click="full = false">最小化</a-button>
    </div>
  </div>
</template>

<script>
import kit from '@/utils/kit'
import { uploadFile } from '@/http/api/file'

const STATUS_WAIT = 'wait'
const STATUS_UPLOADING = 'uploading'
const STATUS_COMPLETED = 'success'
const STATUS_ORDER_CANCEL = 'cancel'
const STATUS_ERROR = 'error'
export default {
  data () {
    return {
      full: false,
      files: [],
      uploadingFile: null,
      continueUpload: false, // 当一个文件上传完成后（不管成功或失败），是否继续上传下一个文件
      abortUpload: null // 一个终止上传当前文件的函数，由上传工具返回
    }
  },
  filters: {
    fmtSize (size) {
      return kit.str.fmtSize(size)
    }
  },
  watch: {
    full () {
      if (!this.full && !this.isUploading && this.filesWaitForUpload.length === 0) {
        this.reset()
      }
    }
  },
  computed: {
    isUploading () {
      return this.uploadingFile !== null
    },
    filesWaitForUpload () {
      return this.files.filter(f => f.status === STATUS_WAIT)
    },
    uploadingFileName () {
      if (this.uploadingFile) {
        return this.uploadingFile.name
      } else if (this.filesWaitForUpload.length > 0) {
        return `- ${this.filesWaitForUpload.length}个文件待上传 -`
      } else {
        return '- 无文件需要上传 -'
      }
    },
    uploadingFileProgress () {
      if (this.uploadingFile) {
        return this.uploadingFile.percent
      } else {
        return 0
      }
    },
    style () {
      const tmp = {
        height: `${this.full ? 300 : 30}px`,
        'box-shadow': '0 2px 5px rgba(0,0,0,.3)'
      }
      if (this.full) {
        tmp['box-shadow'] = '0 2px 5px rgba(0,0,0,.3)'
      } else {
        tmp['box-shadow'] = 'none'
      }
      return tmp
    }
  },
  methods: {
    reset () {
      this.execAbortUpload()
      this.files = []
      this.uploadingFile = null
    },
    /**
     * @public
     * @param files {Array} File of Array
     */
    upload (files) {
      if (!Array.isArray(files)) {
        files = [files]
      }
      if (files.length > 0) {
        this.full = true
      }
      for (const file of files) {
        this.files.push({
          id: kit.str.id(),
          name: file.name,
          description: file.description,
          size: file.size,
          file: file,
          params: file.params,
          status: STATUS_WAIT,
          percent: 0,
          error: null
        })
      }
      this.startUpload()
    },
    startUpload () {
      if (this.isUploading || this.filesWaitForUpload.length === 0) {
        return
      }
      this.uploadingFile = this.filesWaitForUpload[0]
      this.uploadingFile.status = STATUS_UPLOADING
      this.continueUpload = true
      this.abortUpload = uploadFile()
        .success(resp => {
          this.uploadingFile.status = STATUS_COMPLETED
          this.$emit('uploaded', resp.data)
        })
        .error(resp => {
          this.uploadingFile.status = STATUS_ERROR
          this.uploadingFile.error = { message: resp.message }
        })
        .cancel(() => {
          this.uploadingFile.status = STATUS_CANCEL
        })
        .final(() => {
          this.uploadingFile = null
          if (this.continueUpload) {
            this.startUpload()
          }
        })
        .config(cfg => {
          cfg.onUploadProgress = e => {
            this.uploadingFile.percent = Math.round((e.loaded * 100) / e.total)
          }
        })
        .send(this.uploadingFile.file, this.uploadingFile.params.folderId)
    },
    execAbortUpload () {
      this.abortUpload && this.abortUpload.cancel()
      this.abortUpload = null
    },
    abortUploadFile (file) {
      if (file === undefined) {
        this.continueUpload = false
        this.execAbortUpload()
      } else if (file === this.uploadingFile) {
        this.execAbortUpload()
      } else if (file.status !== STATUS_UPLOADING) {
        kit.arr.remove(this.files, file)
      }
    },
    handleStopUpload () {
      this.continueUpload = false
      this.execAbortUpload()
    },
    handleClearUploadList () {
      if (!this.isUploading) {
        this.$confirm({
          title: '确认',
          content: '确定要清空上传列表吗？',
          onOk: () => {
            this.files = []
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  @containerWidth: 640px;
  .file-upload {
    position: absolute;
    left: 50%;
    width: @containerWidth;
    height: 30px;
    margin-left: -@containerWidth / 2;
    background-color: #fff;
    z-index: 20;
    transition: height 200ms, box-shadow 200ms;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  .top {
    margin-top: 5px;
    height: 25px;
  }
  .top-wrapper {
    padding: 0 10px;
    cursor: pointer;
    .progress-out {
      @height: 3px;
      margin-bottom: 2px;
      overflow: hidden;
      .progress-inner {
        height: @height;
        background-color: #e0e0e0;
      }
      .progress-current {
        height: @height;
        background-color: #3d71a7;
        transition: width 50ms;
      }
    }
    .text {
      display: flex;
      flex-direction: row;
      color: #828282;
      font-size: 12px;
    }
    .uploading-file-name {
      flex: 1;
      margin-right: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .progress-total {
    }
  }
  .body {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 0;
    .file-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 10px;
      font-size: 12px;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    .action {
      width: 20px;
      .remove {
        cursor: pointer;
        color: #bb3e30;
      }
    }
    .name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .size {
      padding: 0 5px;
    }
    .status {
      width: 40px;
      text-align: right;
      &.uploading {
        color: #155492;
      }
      &.success {
        color: forestgreen;
      }
      &.error {
        color: #bb3e30;
      }
      &.cancel {
        color: #ac843a;
      }
    }
  }
  .bottom {
    height: 40px;
    line-height: 40px;
    border-top: solid 1px #e2e2e2;
    text-align: center;
  }

  .file-upload.mini .bottom {
    border-top: none;
  }
</style>
