/**
 * @author jiangxingshang
 * @date 2023/12/27
 */
import { add } from '@/http/api/influencer-communication'

export default {
  props: {
    influencerId: { type: String }
  },
  data () {
    return {
      visible: false,
      loading: false,
      type: null
    }
  },
  watch: {
    visible (v) {
      if (v) {
        this.onVisible()
      } else {
        this.onHide()
      }
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    handleSave () {
      if (this.handleBeforeSave() === true) {
        this.loading = true
        add()
          .complete(() => (this.loading = false))
          .success(() => {
            this.$message.success('提交成功。')
            this.visible = false
            this.$emit('saved')
          })
          .send(this.type, this.influencerId, this.getContent())
      }
    },
    handleBeforeSave () {
      return true
    },
    // 返回提交给服务器的内容
    getContent () {
      return null
    },
    onVisible () {

    },
    onHide () {

    }
  }
}
