<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading" :page-title="pageTitle">
    <div slot="toolbar">
      <a-button @click="loadData()">刷新</a-button>
      <a-button type="primary" @click="handleOpenSyncEditor">创建同步</a-button>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" v-if="record.status === 4 || record.status === 3" @click="handleContinueSync(record)">继续同步</a-button>
        <a-button size="small" v-if="record.status === 1" @click="handleStopSync(record)">停止同步</a-button>
      </div>

      <div slot="syncDateRange" slot-scope="row">
        {{row.startDate}} - {{row.endDate}}
      </div>

      <div slot="status" slot-scope="status, record">
        <span v-if="status === 1" class="text-muted">正在同步</span>
        <span v-else-if="status === 2" class="text-success">完成</span>
        <span v-else-if="status === 4" class="text-warning">停止</span>
        <a-tooltip v-else-if="status === 3" placement="top">
          <template slot="title">
            {{record.message}}
          </template>
          <span class="text-danger">错误</span>
        </a-tooltip>
      </div>

      <div slot="syncEndTime" slot-scope="syncEndTime, row">
        {{syncEndTime}}<span v-if="syncEndTime">（{{row | syncTimeDiff}}）</span>
      </div>
    </a-table>

    <modal
        ref="syncEditor"
        title="创建同步"
        :width="500"
        :loading="syncEditor.loading"
        @ok="handleSyncEditorOk"
        @closed="handleSyncEditorClosed"
    >
      <a-form-model :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="日期范围">
          <a-range-picker v-model="syncEditor.date" value-format="yyyy-MM-DD" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_SHOP_ORDER_SYNC } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listScheduleByShop, createSchedule, restartSchedule, stopSchedule, getOrderSyncScheduleById } from '@/http/api/order-sync-schedule'
import kit from '@/utils/kit'
import moment from 'moment'
import { getSimpleShopById } from '@/http/api/shop'

export default {
  data () {
    return {
      routeName: ROUTE_SHOP_ORDER_SYNC,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 100, scopedSlots: { customRender: 'action' } },
        { title: '订单同步日期', scopedSlots: { customRender: 'syncDateRange' } },
        { title: '已同步', dataIndex: 'syncTotal', width: 100 },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 80 },
        { title: '同步开始', dataIndex: 'syncStartTime' },
        { title: '同步结束', dataIndex: 'syncEndTime', scopedSlots: { customRender: 'syncEndTime' }, width: 230 },
        { title: '创建时间', dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false,
      syncEditor: {
        date: [],
        loading: false
      },
      shopName: null
    }
  },
  mixins: [AntdTableHeightMixin],
  filters: {
    syncTimeDiff (row) {
      if (row.syncEndTime) {
        const sTime = moment(row.syncStartTime, 'YYYY-MM-DD HH:mm:ss')
        const eTime = moment(row.syncEndTime, 'YYYY-MM-DD HH:mm:ss')
        var duration = moment.duration(eTime.diff(sTime));
        return kit.num.toFixedTrimZero(duration.asMinutes(), 1) + ' 分钟'
      } else {
        return null
      }
    }
  },
  computed: {
    shopId () {
      return this.$route.params.shopId
    },
    pageTitle () {
      return this.shopName ? '订单同步-' + this.shopName : '订单同步'
    }
  },
  methods: {
    loadData () {
      this.stopWatchSync()
      this.dataLoading = true
      listScheduleByShop()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
          for (const item of this.dataList) {
            if (item.status === 1) {
              this.watchSync(item.id)
            }
          }
        })
        .send(this.shopId)
    },
    handleOpenSyncEditor () {
      this.$refs.syncEditor.open()
    },
    handleSyncEditorClosed () {

    },
    handleSyncEditorOk () {
      this.syncEditor.loading = true
      createSchedule()
        .complete(() => (this.syncEditor.loading = false))
        .success(() => {
          this.$message.success('创建成功。')
          this.loadData()
          this.$nextTick(() => (this.$refs.syncEditor.close()))
        })
        .send(this.shopId, this.syncEditor.date[0], this.syncEditor.date[1])
    },
    handleContinueSync (row) {
      this.$confirm({
        title: '确认',
        content: '确定要继续同步吗？',
        onOk: () => {
          this.pageLoading = true
          restartSchedule()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('操作成功。')
              row.status = 1
              this.watchSync(row.id)
            })
            .send(row.id)
        }
      })
    },
    handleStopSync (row) {
      this.$confirm({
        title: '确认',
        content: '确定要停止同步吗？',
        onOk: () => {
          this.pageLoading = true
          stopSchedule()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('操作成功。')
            })
            .send(row.id)
        }
      })
    },
    watchSync (id) {
      this.intervalRemoveId = setInterval(() => {
        getOrderSyncScheduleById()
          .success(resp => {
            kit.arr.pushOrReplace(this.dataList, resp.data)
            if (resp.data.status !== 1) {
              this.stopWatchSync()
            }
          })
          .error(resp => {
            this.$error({
              title: '错误',
              content: resp.message
            })
            this.stopWatchSync()
          })
          .send(id)
      }, 1000 * 15)
    },
    stopWatchSync () {
      if (this.intervalRemoveId) {
        clearInterval(this.intervalRemoveId)
      }
    }
  },
  beforeDestroy () {
    this.stopWatchSync()
  },
  mounted () {
    this.loadData()
    getSimpleShopById()
      .success(resp => {
        this.shopName = resp.data.name
      })
      .send(this.shopId)
  }
}
</script>

<style lang="less" scoped>

</style>
