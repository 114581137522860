<template>
  <transition name="fade" :duration="80">
    <div class="image-modal" v-show="visible">
      <a-icon class="close" type="close" @click="visible = false" />

      <div class="thumb-list" v-show="images.length > 1">
        <div class="thumb-item image-item"
             :class="{'current': currentImage === img}"
             v-for="(img,idx) in images"
             :key="idx"
             :style="{'background-image': `url(${img.url})`}"
             @click="index = idx"
        >

        </div>
      </div>
      <div class="main" v-if="currentImage">
        <img :src="currentImage.url" />

        <div class="image-info" v-if="currentImage.title || currentImage.description || (currentImage.infos && currentImage.infos.length > 0)">
          <div v-if="currentImage.title" class="title">{{currentImage.title}}</div>
          <div v-if="currentImage.description" class="description">{{currentImage.description}}</div>
          <div class="extra-info">
            <div class="info-item" :class="'type-' + info.type" v-for="info in currentImage.infos" :key="info.name">
              <label>{{info.name}}</label>
              <span>{{info.value}}</span>
            </div>
          </div>
        </div>

        <div class="toolbar" v-show="images.length > 1">
          <div class="nav-btn left" @click="handleNav(-1)">
            <a-icon type="left" />
          </div>
          <div class="index">{{index + 1}} / {{images.length}}</div>
          <div class="nav-btn right" @click="handleNav(1)">
            <a-icon type="right" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'image-modal',
  props: {
  },
  data () {
    return {
      visible: false,
      images: [],
      index: 0
    }
  },
  computed: {
    currentImage () {
      return this.images[this.index]
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.images = []
        this.index = 0
      }
    }
  },
  methods: {
    /**
     *
     * @param images {String | Array}
     *          字符串表示一个url地址
     *          字符串数组表示多个url地址
     *          对象数组，对象结构：{
     *            url: String,
     *            title: String, // 可选
     *            description: String, // 可选
     *            infos: [] // 可选，额外的展示信息，{name: String, value: String}
     *          }
     * @param [startIndex] {Number} 默认0
     * @param [imageUrlName] 如果是对象数组且对象的图片地址属性不是url，可以指定一个图片的url属性名
     */
    open (images, startIndex, imageUrlName) {
      this.index = startIndex || 0
      this.visible = true
      if (images && images.length > 0) {
        if (typeof images === 'string') {
          this.images = [{ url: images }]
        } else if (typeof images[0] === 'string') {
          const arr = []
          for (const url of images) {
            arr.push({ url: url })
          }
          this.images = arr
        } else {
          if (imageUrlName) {
            const arr = []
            for (const img of images) {
              arr.push({ url: img[imageUrlName] })
            }
            this.images = arr
          } else {
            this.images = images
          }
        }
      }
    },
    handleNav (n) {
      const newIndex = this.index + n
      if (newIndex >= 0 && newIndex < this.images.length) {
        this.index = newIndex
      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0,0,0,.4);
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 10px;
    border-radius: 100%;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255,255,255,.8);
    }
  }
}
.thumb-list {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 90px;
  padding: 10px 5px;
  background-color: #fff;
  overflow-y: auto;
  .thumb-item {
    width: 60px;
    height: 60px;
    border-color: #9a9999;
    &.current {
      border-color: #bb3e30;
    }
  }
  .thumb-item + .thumb-item {
    margin-top: 10px;
  }
}
.main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
  right: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-info {
    position: absolute;
    width: 400px;
    top: 10px;
    left: 50%;
    margin-left: -200px;
    z-index: 10;
    color: #fff;
    background-color: rgba(0,0,0,.5);
    border-radius: 10px;
    padding: 10px 0;
    text-align: center;
    .title {
      font-size: 14px;
    }
    .description {
      font-size: 12px;
      color: #bbbbbb;
    }
    .extra-info {
      margin-top: 8px;
      .info-item {
        display: flex;
        font-size: 13px;
        label {
          width: 100px;
          min-width: 100px;
          padding-right: 8px;
          text-align: right;
          &::after {
            content: '：';
          }
        }
        span {
          flex: 1;
          text-align: left;
        }
        &.type-danger {
          color: #ff7d82;
          font-weight: bold;
        }
        &.type-remark {
          color: #64b0ff;
        }
      }
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .toolbar {
    @width: 180px;
    position: absolute;
    bottom: 10px;
    width: @width;
    height: 50px;
    left: 50%;
    margin-left: -@width / 2;
    text-align: center;

    .index {
      display: inline-block;
      padding: 4px 20px;
      margin-top: 7px;
      background-color: rgba(0,0,0,.5);
      color: #fff;
      font-size: 13px;
      border-radius: 10px;
    }

    .nav-btn {
      position: absolute;
      top: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      border-radius: 100%;
      cursor: pointer;
      background-color: #fff;
      user-select: none;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      &:hover {
        background-color: #f9f9f9;
      }
      &:active {
        background-color: #f2f2f2;
      }
    }
  }

}
</style>
