<template>
  <div>
    <div class="info-detail" style="margin-top: 20px;">
      <div class="detail-item">
        <label>图片</label>
        <span>
        <image-picker :image-url.sync="section.imageUrl" width="200px" height="200px"></image-picker>
      </span>
      </div>
      <div class="detail-item">
        <label>产品链接</label>
        <span>
          <a-input v-model="section.productUrl" />
        </span>
      </div>
      <div class="detail-item">
        <label>标题</label>
        <span>
          <a-input v-model="section.title" />
        </span>
      </div>
      <div class="detail-item">
        <label>描述</label>
        <span>
          <a-input v-model="section.description" />
        </span>
      </div>
      <div class="detail-item">
        <label>按钮文本</label>
        <span>
          <a-input v-model="section.buttonText" />
        </span>
      </div>
      <div class="detail-item">
        <label>按钮文本颜色</label>
        <span>
          <color-picker :color.sync="section.buttonTextColor"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>按钮背景颜色</label>
        <span>
          <color-picker :color.sync="section.buttonBgColor" :disable-alpha="false"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>按钮边框颜色</label>
        <span>
          <color-picker :color.sync="section.buttonBorderColor" :disable-alpha="false"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>左右反转</label>
        <span>
          <a-switch v-model="section.reverse"></a-switch>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePicker from '../comp/image-picker'
import Model from '../model/single-product'

export default {
  components: { ImagePicker },
  props: {
    section: { type: Model }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
