<template>
  <app-page page-title="数据分析" :route-name="routeName" :use-tcb-layout="false">
    <div class="tab-page">
      <div class="tab-head">
        <div class="head-item"
             v-for="t in tabs"
             :key="t.id"
             :class="{ 'active': t.id === selectedTabId }"
             @click="handleActiveTab(t.id)"
        >
          {{t.title}}
        </div>
      </div>
      <div class="tab-body">
        <tab-cost ref="cost" v-show="selectedTabId === 'cost'" v-if="$authorityCheck.check($authority.analysisCost)"></tab-cost>
        <tab-stock ref="stock" v-show="selectedTabId === 'stock'" v-if="$authorityCheck.check($authority.analysisStock)"></tab-stock>
        <tab-sale ref="sale" v-show="selectedTabId === 'sale'" v-if="$authorityCheck.check($authority.analysisSale)"></tab-sale>
        <tab-after-sale ref="afterSale" v-show="selectedTabId === 'afterSale'" v-if="$authorityCheck.check($authority.analysisAfterSale)"></tab-after-sale>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_DASHBOARD } from '@/router/router-constants'
import TabCost from '../analysis/cost'
import TabStock from '../analysis/stock'
import TabSale from '../analysis/sale'
import TabAfterSale from '../analysis/after-sale'

export default {
  components: { TabCost, TabStock, TabSale, TabAfterSale },
  data () {
    const tabs = []
    if (this.$authorityCheck.check(this.$authority.analysisStock)) {
      tabs.push({ id: 'stock', title: '库存预警', firstActive: true })
    }
    if (this.$authorityCheck.check(this.$authority.analysisSale)) {
      tabs.push({ id: 'sale', title: '销售统计', firstActive: true })
    }
    if (this.$authorityCheck.check(this.$authority.analysisCost)) {
      tabs.push({ id: 'cost', title: '收支统计', firstActive: true })
    }
    if (this.$authorityCheck.check(this.$authority.analysisAfterSale)) {
      tabs.push({ id: 'afterSale', title: '售后分析', firstActive: true })
    }
    return {
      routeName: ROUTE_DASHBOARD,
      tabs: tabs,
      selectedTabId: null
    }
  },
  watch: {
    selectedTabId (id, old) {
      if (old) {
        this.$refs[old].onInactive()
      }
      const tab = this.tabs.find(item => item.id === id)
      if (tab) {
        this.$refs[id].onActive(tab.firstActive)
        tab.firstActive = false
      }
    }
  },
  methods: {
    handleActiveTab (id) {
      this.selectedTabId = id
    }
  },
  mounted () {
    this.handleActiveTab('stock')
  }
}
</script>

<style lang="less" scoped>
.tab-page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .tab-head {
    display: flex;
    padding: 0 20px;
    height: 50px;
    border-bottom: solid 1px #ddd;
    .head-item {
      margin: 15px 5px 0 5px;
      padding: 8px 25px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #fff;
      border: solid 1px #ddd;
      border-bottom: none;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: #4586d4;
        color: #fff;
        border-color: #3d71a7;
      }
    }
  }
  .tab-body {
    position: relative;
    flex: 1;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
  }
}
</style>
