<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="handleAcceptOrder" v-show="selectedTotalTab.id === 'new'" v-authority="$authority.supplierOrderButton">接单</a-button>
    <a-button slot="toolbar" type="danger" @click="$refs.rejectModal.open()" v-show="selectedTotalTab.id === 'new' || selectedTotalTab.id === 'accept'" v-authority="$authority.supplierOrderButton">拒单</a-button>
    <a-button slot="toolbar" @click="handleMoveToDelivery" v-show="selectedTotalTab.id === 'accept'" v-authority="$authority.supplierOrderButton">移到待发货</a-button>
    <a-button slot="toolbar" @click="navDeliveryReady">查看待发货</a-button>
    <a-button slot="toolbar" type="primary" :disabled="!actionButtonEnabled" @click="changeSupplier" v-authority="$authority.supplierOrder">更换供应商</a-button>
    <a-button slot="toolbar" type="danger" :disabled="!actionButtonEnabled" @click="deleteItems" v-authority="$authority.supplierOrder">作废</a-button>
    <a-button slot="toolbar" :disabled="selectedRowKeys.length === 0" @click="handleShareProduceItems">分享</a-button>
    <a-dropdown-button @click="handleExportSearchResult()" slot="toolbar" style="margin-left: 10px;">
      导出查询
      <template #overlay>
        <a-menu @click="handleExportSearchResult(true)">
          <a-menu-item key="1">导出查询（包含图片）</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown-button>

    <div slot="top">
      <div class="total-tab">
        <ul>
          <li v-for="tab in totalTabs" :key="tab.id" @click="handleTotalTabSelected(tab)" :class="{'selected': tab.selected}">
            {{tab.title}}
            <span class="total">( {{tab.total}} )</span>
          </li>
        </ul>
      </div>

      <div class="search-form">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <div>
            <a-form-model-item label="订单编号">
              <a-input v-model="searchParams.number" placeholder="标题关键字" :allow-clear="true" style="width: 220px;" />
            </a-form-model-item>
            <a-form-model-item label="快递单号">
              <a-input v-model="searchParams.trackNo" :allow-clear="true" style="width: 160px;" />
            </a-form-model-item>
            <a-form-model-item label="供应商" v-if="!isSupplier">
              <a-select v-model="searchParams.supplierId" style="width: 160px;" :allow-clear="true">
                <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="SKU">
              <a-input v-model="searchParams.sku" :allow-clear="true" style="width: 160px;" />
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="发货日期">
              <a-range-picker @change="handleCreateDateChanged"
                              value-format="YYYY-MM-DD"
                              style="width: 220px;"
              >
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="延迟生产">
              <a-switch v-model="searchParams.delay" />
            </a-form-model-item>
            <a-form-model-item label="废弃订单">
              <a-switch v-model="searchParams.onlyDelete" />
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData()" icon="reload">查询</a-button>
        </div>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="action" slot-scope="v, r">
        <a-button size="small" @click="$refs.detail.open(r.id)">详情</a-button>
      </div>
      <div slot="number" slot-scope="value, item">
        {{value}}
        <a-tooltip placement="top" v-if="item.delay">
          <template slot="title">
            <span>工厂延迟处理</span>
          </template>
          <a-icon type="fall" style="color: #3679c9; margin-left: 6px;" />
        </a-tooltip>
      </div>
      <div slot="image" slot-scope="v, r">
        <div class="image-item" v-lazy:background-image="r.getFirstImage()" @click="$imageModal.open(getDisplayImages(r))">
          <span v-if="r.getImages().length > 1" class="total">{{r.getImages().length}}</span>
        </div>
      </div>
      <div slot="limitation" class="font-mini" slot-scope="v, item">
        <div v-if="item.dayNewToAccept > 0">
          <span v-if="item.confirmTime">接单用时：{{item.dayNewToAccept}}天</span>
          <span v-else class="text-danger">未接单（已过{{item.dayNewToAccept}}天）</span>
        </div>
        <div v-if="item.dayAcceptToDelivery > 0">
          <span v-if="item.deliveryTime">接单到发货用时：{{item.dayAcceptToDelivery}}天</span>
          <span v-else class="text-danger">未发货（已过{{item.dayAcceptToDelivery}}天）</span>
        </div>
      </div>
    </a-table>


    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <produce-item-detail ref="detail"></produce-item-detail>

    <modal ref="changeSupplierModal" title="请选择新的供应商" @ok="onChangeSupplierConfirm" :loading="changeSupplierModal.loading">
      <a-select v-model="changeSupplierModal.selectedSupplierId" style="width: 100%;">
        <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
      </a-select>
    </modal>

    <modal ref="rejectModal" title="拒单理由" @ok="handleRejectOrder" :loading="rejectOrderReasonEditor.loading">
      <a-textarea
          v-model="rejectOrderReasonEditor.reason"
          placeholder="请填写拒单的原因"
          :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </modal>
  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER_PRODUCE_ITEM, ROUTE_SUPPLIER_DELIVERY_READY } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  listPage,
  deleteProduceItem,
  transferProduceItem,
  shareProduceItems,
  acceptProduceItem,
  rejectProduceItem,
  getExportSearchResultUrl
} from '@/http/api/supplier-produce-item'
import { addSupplierProduceItemToDelivery } from '@/http/api/delivery-produce-item'
import { listSupplier } from '@/http/api/supplier'
import SupplierProduceItem from '@/model/supplier-produce-item'
import ProduceItemDetail from './comp/detail'
import kit from '@/utils/kit'

const initSearchParams = function (status, orderCustom) {
  return {
    status: status,
    number: null,
    supplierId: null,
    delay: false,
    onlyDelete: false,
    trackNo: null,
    createTimeStart: null,
    createTimeEnd: null,
    sku: null,
    orderByName: 'create_time',
    orderByDirection: status && status.startsWith('timeout') ? 'asc' : 'desc',
    orderCustom: orderCustom // 自定义排序，参考：totalTabs.order
  }
}


export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  components: { ProduceItemDetail },
  data () {
    return {
      routeName: ROUTE_SUPPLIER_PRODUCE_ITEM,
      dataList: [],
      pageLoading: false,
      dataLoading: false,
      dataColumns: [
        { title: '操作', dataIndex: 'action', width: 60, scopedSlots: { customRender: 'action' }, fixed: 'left' },
        { title: '图片', width: 90, scopedSlots: { customRender: 'image' } },
        { title: '编号', dataIndex: 'number', width: 120, scopedSlots: { customRender: 'number' } },
        { title: '备注', dataIndex: 'remark' },
        { title: '规格', dataIndex: 'variant' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '时效', width: 160, scopedSlots: { customRender: 'limitation' } },
        { title: '数量', dataIndex: 'quantity', width: 80 },
        { title: '生产费用', dataIndex: 'price', width: 80 },
        { title: '下单日期', dataIndex: 'createTime', width: 140 },
        { title: '供应商', dataIndex: 'supplierName', width: 120 }
      ],
      searchParams: initSearchParams(null, null),
      totalTabs: [
        { id: 'new', status: 'new', title: '新单', order: 'create_time, number', total: 0, selected: true },
        { id: 'accept', status: 'accept', title: '已接单', order: 'create_time, number', total: 0, selected: false },
        { id: 'delivery', status: 'delivery', title: '已发货', order: 'delivery_time desc, number', total: 0, selected: false },
        { id: 'timeoutAccept', status: 'timeoutAccept', title: '接单超时', total: 0, selected: false },
        { id: 'timeoutDelivery', status: 'timeoutDelivery', title: '发货超时', total: 0, selected: false },
        { id: 'reject', status: 'reject', title: '拒单', total: 0, selected: false },
        { id: 'return', status: 'return', title: '退货', total: 0, selected: false },
        { id: 'all', status: null, title: '全部', total: 0, selected: false }
      ],
      selectedRowKeys: [],
      supplierOptions: [],
      changeSupplierModal: {
        selectedSupplierId: null,
        loading: false
      },
      rejectOrderReasonEditor: {
        reason: null,
        loading: false
      }
    }
  },
  computed: {
    selectedTotalTab () {
      return this.totalTabs.find(item => item.selected)
    },
    actionButtonEnabled () {
      return this.selectedTotalTab.id === 'new' || this.selectedTotalTab.id === 'reject'
    },
    isSupplier () {
      return this.$userStore.state.supplier
    }
  },
  methods: {
    createSearchParams () {
      const params = this.buildRequestParams(this.searchParams)
      if (this.selectedTotalTab) {
        params.status = this.selectedTotalTab.status
        params.orderCustom = this.selectedTotalTab.order || null
      } else {
        params.status = null
        params.orderCustom = null
      }
      return params
    },
    loadData () {
      const params = this.createSearchParams()
      this.dataLoading = true
      this.selectedRowKeys = []
      listPage()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          resp.data.records.forEach(item => {
            arr.push(new SupplierProduceItem(item))
          })
          this.dataList = arr
          this.page.total = resp.data.total
        })
        .send(params)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleTotalTabSelected (tab) {
      if (tab.selected) {
        tab.selected = false
      } else {
        this.totalTabs.forEach(item => (item.selected = false))
        tab.selected = true
      }
      this.reloadData()
    },
    loadTotals () {
      for (const tab of this.totalTabs) {
        const p = initSearchParams(tab.status)
        p.limit = 0
        listPage()
          .success(resp => {
            tab.total = resp.data.total
          })
          .send(p)
      }
    },
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.createTimeStart = dates[0] + ' 00:00:00'
        this.searchParams.createTimeEnd = dates[1] + ' 00:00:00'
      } else {
        this.searchParams.createTimeStart = null
        this.searchParams.createTimeEnd = null
      }
    },
    deleteItems () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要操作的行。')
      }
      this.$confirm({
        title: '确认',
        content: `确定要删除选中的 ${this.selectedRowKeys.length} 行吗？`,
        onOk: () => {
          this.pageLoading = true
          deleteProduceItem()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              for (const id of this.selectedRowKeys) {
                kit.arr.remove(this.dataList, { id: id })
              }
              this.selectedRowKeys = []
            })
            .send(this.selectedRowKeys)
        }
      })
    },
    changeSupplier () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要操作的行。')
      }
      this.$refs.changeSupplierModal.open()
    },
    onChangeSupplierConfirm () {
      if (!this.changeSupplierModal.selectedSupplierId) {
        return this.$message.error('请选择供应商。')
      }
      this.changeSupplierModal.loading = true
      transferProduceItem()
        .complete(() => (this.changeSupplierModal.loading = false))
        .success(resp => {
          this.$message.success('转交成功。')
          this.selectedRowKeys = []
          this.loadData()
          this.$nextTick(() => {
            this.$refs.changeSupplierModal.close()
          })
        })
        .send(this.selectedRowKeys, this.changeSupplierModal.selectedSupplierId)
    },
    handleShareProduceItems () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要操作的行。')
      }
      shareProduceItems()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          const h = this.$createElement;
          this.$info({
            title: '复制下面的链接访问给要分享的人',
            content: h('div', {}, [
              h('p', '网页：' + resp.data.page),
              h('p', '表格：' + resp.data.excel)
            ])
          })
        })
        .send(this.selectedRowKeys, '/#/share/produce_item')
    },
    handleExportSearchResult (includeImage) {
      const p = this.createSearchParams()
      p.includeImage = !!includeImage
      window.open(getExportSearchResultUrl(p))
    },
    getDisplayImages (record) {
      const remarks = []
      if (record.remark) remarks.push(record.remark)
      if (record.skuRemark) remarks.push(record.skuRemark)
      const arr = []
      for (const url of record.getImages()) {
        const item = {
          url: url,
          infos: [
            { name: '订单号', value: record.number },
            { name: 'SKU', value: record.sku },
            { name: '备注', value: remarks.join('，'), type: 'remark' }
          ]
        }
        if (record.dayNewToAccept > 0 && !record.confirmTime) {
          item.infos.push({ name: '未接单', value: `已过${record.dayNewToAccept}天`, type: 'danger' })
        }
        if (record.dayAcceptToDelivery > 0 && !record.deliveryTime) {
          item.infos.push({ name: '未发货', value: `已过${record.dayAcceptToDelivery}天`, type: 'danger' })
        }
        arr.push(item)
      }
      return arr
    },
    handleAcceptOrder () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择要操作的行。')
      } else {
        this.$confirm({
          title: '确认',
          content: `确定要接受选中的${this.selectedRowKeys.length}个订单吗？`,
          onOk: () => {
            this.pageLoading = true
            acceptProduceItem()
              .complete(() => (this.pageLoading = false))
              .success(() => {
                this.$message.success('操作成功。')
                this.reloadData()
              })
              .send(this.selectedRowKeys)
          }
        })
      }
    },
    handleRejectOrder () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择要操作的行。')
      } else {
        this.$confirm({
          title: '确认',
          content: `确定要拒绝选中的${this.selectedRowKeys.length}个订单吗？`,
          onOk: () => {
            this.rejectOrderReasonEditor.loading = true
            rejectProduceItem()
              .complete(() => (this.rejectOrderReasonEditor.loading = false))
              .success(() => {
                this.$message.success('操作成功。')
                this.reloadData()
                this.$nextTick(() => (this.$refs.rejectModal.close()))
              })
              .send(this.selectedRowKeys, this.rejectOrderReasonEditor.reason)
          }
        })
      }
    },
    handleMoveToDelivery () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择要操作的行。')
      } else {
        this.$confirm({
          title: '确认',
          content: `确定要将选中的${this.selectedRowKeys.length}个订单移到待发货列表吗？`,
          onOk: () => {
            this.pageLoading = true
            addSupplierProduceItemToDelivery()
              .complete(() => (this.pageLoading = false))
              .success(() => {
                this.$message.success('操作成功。')
                this.reloadData()
              })
              .send(this.selectedRowKeys)
          }
        })
      }
    },
    navDeliveryReady () {
      this.$router.push({ name: ROUTE_SUPPLIER_DELIVERY_READY })
    }
  },
  mounted () {
    this.loadData()
    this.loadTotals()
    if (!this.isSupplier) {
      listSupplier()
        .success(resp => {
          this.supplierOptions = resp.data
        })
        .send()
    }
  }
}
</script>

<style lang="less" scoped>

.search-form {
  padding: 10px;
  margin-top: 10px;
  border: solid 1px #ddd;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.total-tab {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 4px 20px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    &:hover {
      color: #bb3e30;
    }
    &.selected {
      border-bottom: solid 2px #bb3e30;
    }
    .total {
      margin-left: 4px;
      color: #bb3e30;
      font-size: 12px;
    }
  }
  li + li {
    margin-left: 14px;
  }
}


.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 10;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
}
</style>
