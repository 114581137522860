<template>
  <transition name="zoom" :duration="200">
    <div class="full-loading" v-show="loading">
      <a-icon class="spin-icon" type="loading" />
      <span class="spin-text" v-if="text">{{text}}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loading',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.full-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
}
.spin-icon {
  font-size: 18px;
}
.spin-text {
  margin-left: 10px;
  font-size: 12px;
  color: #414141;
}
</style>
