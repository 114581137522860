<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="导入达人"
    save-button-text="开始导入"
    @close="onClose"
    @save="handleSave"
    width="90%"
  >
    <div style="margin-bottom: 10px;">
      <a-button @click="handleDownloadTemplate">下载模版</a-button>
      <a-button @click="handleUploadImportFile" :loading="uploading">
        上传数据
        <span v-if="dataList.length > 0">（共{{dataList.length}}条记录）</span>
      </a-button>
    </div>

    <a-table
        size="middle"
        rowKey="cid"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: 500 }"
    >
      <div slot="no" slot-scope="val, row, index">{{index + 1}}</div>
    </a-table>
  </drawer>
</template>

<script>
import { getTemplateDownloadUrl, uploadImportData, uploadData } from '@/http/api/influencer'
import selectFile from '@/utils/select-file'

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      uploading: false,
      dataColumns: [
        { title: 'No.', scopedSlots: { customRender: 'no' }, width: 50 },
        { title: 'Handle', dataIndex: 'cid', width: 100 },
        { title: '昵称', dataIndex: 'name', width: 120 },
        { title: '邮箱', dataIndex: 'email', width: 150 },
        { title: '性别', dataIndex: 'sex', width: 80, customRender: sex => sex === 1 ? '男' : (sex === 0 ? '女' : '') },
        { title: '分类', dataIndex: 'influencerCategoryName', width: 150 },
        { title: '平均播放量', dataIndex: 'viewCount', width: 100 },
        { title: '粉丝数', dataIndex: 'followerCount', width: 100 },
        { title: '成交金额/¥', dataIndex: 'transactionAmount', width: 100 },
        { title: '备注', dataIndex: 'remark' }
      ],
      dataList: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open () {
      this.visible = true
    },
    onClose () {
      this.dataList = []
    },
    handleDownloadTemplate () {
      window.open(getTemplateDownloadUrl())
    },
    handleUploadImportFile () {
      selectFile({
        accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multi: false,
        select: files => {
          this.uploading = true
          uploadImportData()
            .complete(() => (this.uploading = false))
            .success(resp => {
              if (resp.data.saved) {
                const st = resp.data.successTotal
                const ft = resp.data.failTotal
                if (ft > 0 && st !== ft) {
                  this.$info({
                    title: '提示',
                    content: `成功导入 ${st} 行，失败 ${ft} 行。`
                  })
                } else if (ft === 0) {
                  this.$message.success('导入成功。')
                } else {
                  this.$message.error('导入失败。')
                }
                this.visible = false
              } else {
                this.dataList = resp.data.data
              }
            })
            .send(files[0])
        }
      })
    },
    handleSave () {
      this.saveButtonLoading = true
      uploadData()
        .complete(() => (this.saveButtonLoading = false))
        .success(() => {
          this.$message.success('导入成功。')
          this.visible = false
        })
        .send(this.dataList)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
