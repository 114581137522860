import defineApi from '../define-api'
import http from '../application-api'

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/partner/list', config)
})

export const listOptions = defineApi((config) => {
  http.get('/partner/options', config)
})


export const add = defineApi((config, data) => {
  config.data = data
  http.post('/partner/add', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/partner/update', config)
})
