<template>
  <app-page :route-name="routeName" :use-tcb-layout="false" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.skuOptionManager.open()">SKU规则管理器</a-button>

    <!-- 系统获取款号 -->
    <div v-show="panel === '1'">
      <div class="left-right-content">
        <label>大类</label>
        <div class="full left-align">
          <a-tag class="sku-tag"
                 :color="item.skuCode === selectedTypeSkuCode ? 'blue' : ''"
                 v-for="item in typeSkuOptions"
                 :key="item.skuCode"
                 @click="selectedTypeSkuCode = item.skuCode"
          >{{item.value}}</a-tag>
        </div>
      </div>

      <div class="left-right-content">
        <label>品类</label>
        <div class="full left-align">
          <a-tag class="sku-tag"
                 :color="item.skuCode === selectedSubTypeSkuCode ? 'blue' : ''"
                 @click="selectedSubTypeSkuCode = selectedSubTypeSkuCode === item.skuCode ? null : item.skuCode"
                 v-for="item in subTypeSkuOptions"
                 :key="item.skuCode">{{item.value}}</a-tag>
        </div>
      </div>

      <div class="left-right-content">
        <label>性别</label>
        <div class="full left-align">
          <a-tag class="sku-tag"
                 :color="item.skuCode === selectedSexSkuCode ? 'blue' : ''"
                 @click="selectedSexSkuCode = item.skuCode"
                 v-for="item in sexSkuOptions"
                 :key="item.skuCode">{{item.value}}</a-tag>
        </div>
      </div>

      <div class="sku-style-preview">
        <div style="margin-right: 20px;">
          <div class="style-preview">款号预览：{{skuStyle}}</div>
          <p v-show="skuStyleNumber === '0000'" class="text-muted font-mini">此为预览款号序号，点击右侧按钮获取真实款号序号。</p>
        </div>
        <a-button size="large" type="primary" @click="handleGetSkuStyle">获取款号</a-button>
        <a-button size="large" @click="panel = '2'">已有款号？</a-button>
      </div>
    </div>

    <div v-show="panel === '2'">
      <a-form layout="inline">
        <a-form-item>
          <a-button @click="panel = '1'">返回</a-button>
        </a-form-item>
        <a-form-item>
          <a-input placeholder="款号代码，如：RBF" v-model="inputSkuStyleCode" :maxLength="5" @change="handleInputSkuStyleCodeUpperCase"></a-input>
        </a-form-item>
        <a-form-item>
          <a-input-number placeholder="款号序号，四位数字" style="width: 170px;" v-model="inputSkuStyleNumber" :min="1000" :max="9999"></a-input-number>
        </a-form-item>
      </a-form>
    </div>

    <a-divider />

    <div v-show="canGenerateSkuList">
      <div style="margin: 10px 0 30px 220px;">
        <a-button size="large" @click="handlePreviewSku">预览SKU</a-button>
        <span class="font-mini text-muted" style="margin-left: 10px;">从下面选择（可多选）属性来预览SKU。</span>
      </div>

      <div class="left-right-content" v-for="skuOpt in otherSkuOptions" :key="skuOpt.group">
        <label style="width: 200px;">{{skuOpt.group}}</label>
        <div class="full left-align">
          <a-tag class="sku-tag"
                 v-for="item in skuOpt.tags"
                 :key="item.skuCode"
                 :color="selectedSkuGenerateVariantsText.includes(skuOpt.group + '-' + item.value) ? 'blue' : ''"
                 @click="handleSkuVariantClick(skuOpt.group, item)"
          >{{item.value}}</a-tag>
        </div>
      </div>
    </div>

    <sku-option-manager ref="skuOptionManager" @closed="loadData"></sku-option-manager>

    <modal ref="skuResult" title="SKU预览" :hide-buttons="true">
      <ul class="list-group">
        <li class="list-group-item" v-for="sku in skuResults" :key="sku">{{sku}}</li>
      </ul>
    </modal>
  </app-page>
</template>

<script>
import { ROUTE_PRODUCT_SKU_STYLE } from '@/router/router-constants'
import { listSkuOptions, getSkuStyle, generateSkuList } from '@/http/api/sku-option'
import {
  SKU_GROUP_NAME_TYPE,
  SKU_GROUP_NAME_SUB_TYPE,
  SKU_GROUP_NAME_SEX
} from '@/constants/sku-option-group-name'
import SkuOptionManager from '@/components-business/sku-option-manager'
import kit from '@/utils/kit'

export default {
  components: { SkuOptionManager },
  data () {
    return {
      routeName: ROUTE_PRODUCT_SKU_STYLE,
      pageLoading: false,
      skuOptions: null,
      selectedTypeSkuCode: null,
      selectedSubTypeSkuCode: null,
      selectedSexSkuCode: null,
      skuStyleNumber: '0000',
      loading: false,
      // 生成SKU的选项
      selectedSkuGenerateVariants: [],
      // 1 系统获取款号
      // 2 自己填写款号
      panel: '1',
      inputSkuStyleCode: 'RBF',
      inputSkuStyleNumber: 1000,
      skuResults: []
    }
  },
  computed: {
    typeSkuOptions () {
      return this.skuOptions ? this.skuOptions[SKU_GROUP_NAME_TYPE] : []
    },
    subTypeSkuOptions () {
      return this.skuOptions ? this.skuOptions[SKU_GROUP_NAME_SUB_TYPE] : []
    },
    sexSkuOptions () {
      return this.skuOptions ? this.skuOptions[SKU_GROUP_NAME_SEX] : []
    },
    otherSkuOptions () {
      if (!this.skuOptions) {
        return []
      }
      const arr = []
      for (const group of Object.keys(this.skuOptions)) {
        if (group === SKU_GROUP_NAME_SEX || group === SKU_GROUP_NAME_TYPE || group === SKU_GROUP_NAME_SUB_TYPE) {
          continue
        }
        arr.push({
          group: group,
          tags: this.skuOptions[group]
        })
      }
      return arr
    },
    skuStyle () {
      if (this.canGetStyleNumberFromServer) {
        return this.selectedTypeSkuCode + (this.selectedSubTypeSkuCode || '') + this.selectedSexSkuCode + this.skuStyleNumber
      } else {
        return '请选择大类和性别'
      }
    },
    canGetStyleNumberFromServer () {
      return !!(this.selectedTypeSkuCode && this.selectedSexSkuCode)
    },
    canGenerateSkuList () {
      if (this.panel === '1') {
        return this.skuStyleNumber !== '0000'
      } else {
        return this.inputSkuStyleCode && this.inputSkuStyleNumber >= 1000
      }
    },
    selectedSkuGenerateVariantsText () {
      return this.selectedSkuGenerateVariants.map(item => item.name + '-' + item.value)
    }
  },
  methods: {
    loadData () {
      listSkuOptions().success(resp => (this.skuOptions = resp.data)).send()
    },
    handleGetSkuStyle () {
      if (this.canGetStyleNumberFromServer) {
        this.$confirm({
          title: '确认',
          content: '确定获取这个款号吗？',
          onOk: () => {
            this.loading = true
            getSkuStyle()
              .complete(() => (this.loading = false))
              .success(resp => {
                this.skuStyleNumber = resp.data.number + ''
                this.$info({
                  title: 'Info',
                  content: `款号【${this.skuStyle}】已获得，请记住这个序号。`
                })
              })
              .send()
          }
        })
      } else {
        this.$message.error('请先选择大类和性别。')
      }
    },
    handleSkuVariantClick (group, skuOptionItem) {
      const found = this.selectedSkuGenerateVariants.find(item => item.name === group && item.value === skuOptionItem.value)
      if (found) {
        kit.arr.removeItem(this.selectedSkuGenerateVariants, found)
      } else {
        this.selectedSkuGenerateVariants.push({
          name: group,
          value: skuOptionItem.value
        })
      }
    },
    handlePreviewSku () {
      if (!this.canGenerateSkuList) {
        return this.$message.error('请先选择大类和性别。')
      }
      const map = {}
      for (const item of this.selectedSkuGenerateVariants) {
        let values = map[item.name]
        if (!values) {
          values = []
          map[item.name] = values
        }
        values.push(item.value)
      }
      const arr = []
      for (const group of Object.keys(map)) {
        const tmp = []
        for (const val of map[group]) {
          tmp.push({ name: group, value: val })
        }
        arr.push(tmp)
      }

      let row1 = arr.pop().map(item => [item])
      let row2 = null
      while (true) {
        row2 = arr.pop()
        if (!row2) break
        const tmp = []
        for (const v1Arr of row1) {
          for (const v2 of row2) {
            tmp.push([...v1Arr, v2])
          }
        }
        row1 = tmp
      }

      const data = {
        number: null,
        sexTag: null,
        typeTag: null,
        subTypeTag: null,
        options: row1
      }
      if (this.panel === '1') {
        data.sexTag = this.sexSkuOptions.find(item => item.skuCode === this.selectedSexSkuCode).value
        data.typeTag = this.typeSkuOptions.find(item => item.skuCode === this.selectedTypeSkuCode).value
        data.subTypeTag = (this.typeSkuOptions.find(item => item.skuCode === this.selectedTypeSkuCode) || {}).value
        data.number = this.skuStyleNumber
      } else {
        data.number = this.inputSkuStyleNumber
        if (this.inputSkuStyleCode.length === 3) {
          data.typeTag = (this.typeSkuOptions.find(item => item.skuCode === this.inputSkuStyleCode.substring(0, 2)) || {}).value
          data.sexTag = (this.sexSkuOptions.find(item => item.skuCode === this.inputSkuStyleCode.substring(2, 3)) || {}).value
        } else if (this.inputSkuStyleCode.length === 5) {
          data.typeTag = (this.typeSkuOptions.find(item => item.skuCode === this.inputSkuStyleCode.substring(0, 2)) || {}).value
          data.subTypeTag = (this.subTypeSkuOptions.find(item => item.skuCode === this.inputSkuStyleCode.substring(2, 4)) || {}).value
          data.sexTag = (this.sexSkuOptions.find(item => item.skuCode === this.inputSkuStyleCode.substring(4, 5)) || {}).value
        } else {
          return this.$message.error('款号代码只能是3位或5位。')
        }
      }
      this.pageLoading = true
      generateSkuList()
        .complete(() => (this.pageLoading = false))
        .success(resp => {
          this.skuResults = resp.data
          this.$refs.skuResult.open()
        })
        .send(data)
    },
    handleInputSkuStyleCodeUpperCase () {
      if (this.inputSkuStyleCode) {
        this.inputSkuStyleCode = this.inputSkuStyleCode.toUpperCase()
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.left-right-content + .left-right-content {
  margin-top: 10px;
}
.left-right-content {
  label {
    width: 80px;
    text-align: right;
    margin-right: 20px;
  }
  .sku-tag {
    margin-bottom: 8px;
    cursor: pointer !important;
  }
}

.sku-style-preview {
  display: flex;
  padding: 20px 100px;
  .style-preview {
    font-size: 22px;
    color: #a94442;
    letter-spacing: 2px;
  }
}
</style>
