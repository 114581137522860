import defineApi from '../define-api'
import http from '../application-api'

export const listOrders = defineApi((config, params) => {
  config.params = params
  http.get('/order/airwallex/report', config)
})

export const uploadFileToUpdateAirwallexSubmit = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  return http.upload('/order/airwallex/update_submit', config)
})

export const downloadReportUrl = function (query) {
  return http.baseUrl + '/order/airwallex/report/download/csv?' + query
}
