import kit from '@/utils/kit'

export const ORDER_DELIVERY_PREPARE_STATUS_YES = 'yes'
export const ORDER_DELIVERY_PREPARE_STATUS_NO = 'no'
export const ORDER_DELIVERY_PREPARE_STATUS_HANDOVER = 'hand'

export const ORDER_DELIVERY_PREPARE_STATUS_MAPPING = {
  [ORDER_DELIVERY_PREPARE_STATUS_YES]: '待配货',
  [ORDER_DELIVERY_PREPARE_STATUS_NO]: '无需配货',
  [ORDER_DELIVERY_PREPARE_STATUS_HANDOVER]: '已物流交接'
}

export const ORDER_DELIVERY_PREPARE_STATUS = {
  yes: ORDER_DELIVERY_PREPARE_STATUS_YES,
  no: ORDER_DELIVERY_PREPARE_STATUS_NO,
  handover: ORDER_DELIVERY_PREPARE_STATUS_HANDOVER
}

export const ORDER_DELIVERY_PREPARE_STATUS_LIST = kit.obj.toArray(ORDER_DELIVERY_PREPARE_STATUS_MAPPING)
