import defineApi from '../define-api'
import http from '../application-api'

export const listEdmEmails = defineApi((config, params) => {
  config.params = params
  http.get('/edm/email/list', config)
})


export const importFromCsv = defineApi((config, file, groupId) => {
  const form = new FormData()
  form.append('file', file)
  if (groupId) {
    form.append('groupId', groupId)
  }
  config.data = form
  http.post('/edm/email/import/from_csv', config)
})

/**
 * @param data {Object}
 * {
 *   set: Boolean, true 表示设置分组，false 表示移除分组
 *   groupId: String,
 *   emailIdList: Array
 * }
 */
export const setEmailGroup = defineApi((config, data) => {
  config.data = data
  http.post('/edm/email/set_group', config)
})

export const updateUnsubscribe = defineApi((config, email, unsubscribe) => {
  config.params = {
    email: email,
    unsubscribe: unsubscribe
  }
  http.post('/edm/email/update/unsubscribe', config)
})

export const exportEmails = function (query) {
  const arr = []
  for (const key of Object.keys(query)) {
    if (query[key]) {
      arr.push(key + '=' + query[key])
    }
  }
  window.open(http.baseUrl + '/edm/email/export?' + arr.join('&'))
}
