<template>
  <a-modal
      title="用户选择"
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
      width="80%"
      :body-style="{height: '500px'}"
      :dialog-style="{top: '50px'}"
      wrap-class-name="user-select-dialog"
  >
    <div>
      <a-form-model layout="inline">
        <a-form-model-item label="关键字">
          <a-input v-model="searchForm.keyword" :allowClear="true" :disabled="searchFormDisable.keyword"></a-input>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchForm.status" :allowClear="true" style="width: 100px;" :disabled="searchFormDisable.status">
            <a-select-option :key="1">可用</a-select-option>
            <a-select-option :key="0">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="searchForm.type" :allowClear="true" style="width: 100px;" :disabled="searchFormDisable.type">
            <a-select-option key="admin">管理员</a-select-option>
            <a-select-option key="supplier">供应商</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="供应商">
          <a-select v-model="searchForm.supplierId" style="width: 140px;" :allow-clear="true" :disabled="searchFormDisable.supplierId">
            <a-select-option v-for="opt in supplierOptions" :key="opt.id" :value="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门">
          <a-select v-model="searchForm.deptId" :allow-clear="true" style="width: 120px;" :disabled="searchFormDisable.deptId">
            <a-select-option v-for="s in deptOptions" :key="s.id">{{s.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loadData">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: 300 }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onRowSelectChanged }"
    >
      <div slot="type" slot-scope="type">{{userTypeMapping[type]}}</div>
      <status slot="status" slot-scope="status" :status="status"></status>
      <div slot="dept" slot-scope="row">{{row.deptName}}<span v-show="row.deptLeader" class="text-success">（领导）</span></div>
      <status slot="wechatSubscribe" slot-scope="wechatSubscribe" :status="wechatSubscribe" enabledText="已订阅" disabledText="未订阅"></status>
    </a-table>
    <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
  </a-modal>
</template>

<script>
import { USER_TYPE_MAPPING } from '@/constants/user-type'
import { managerList } from '@/http/api/admin'
import { listSupplier } from '@/http/api/supplier'
import PaginationMixin from '@/mixins/pagination'
import { listAll as listDept } from '@/http/api/dept'

export default {
  mixins: [PaginationMixin],
  data () {
    return {
      visible: false,
      closable: true,
      dataColumns: [
        { title: '姓名', dataIndex: 'name', width: 150 },
        { title: '用户类型', dataIndex: 'type', scopedSlots: { customRender: 'type' } },
        { title: '部门', scopedSlots: { customRender: 'dept' } },
        { title: '所属供应商', dataIndex: 'supplierName' },
        { title: '创建日期', dataIndex: 'createDate' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '微信订阅', dataIndex: 'wechatSubscribe', scopedSlots: { customRender: 'wechatSubscribe' } }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        keyword: null,
        status: null,
        type: null,
        supplierId: null,
        deptId: null
      },
      searchFormDisable: {
        keyword: false,
        status: false,
        type: false,
        supplierId: false,
        deptId: false
      },
      userTypeMapping: USER_TYPE_MAPPING,
      supplierOptions: [],
      deptOptions: [],
      selectedRowKeys: []
    }
  },
  computed: {
    selectedUsers () {
      const users = []
      for (const u of this.dataList) {
        if (this.selectedRowKeys.includes(u.id)) {
          users.push(u)
        }
      }
      return users
    }
  },
  methods: {
    open (defaultSearchValue, searchDisabled) {
      if (defaultSearchValue) {
        Object.assign(this.searchForm, defaultSearchValue)
      }
      if (searchDisabled) {
        Object.assign(this.searchFormDisable, searchDisabled)
      }
      this.visible = true
      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }
      if (this.deptOptions.length === 0) {
        listDept().success(resp => (this.deptOptions = resp.data)).send()
      }
      this.reloadData()
    },
    handleOk () {
      this.$emit('selected', this.selectedRowKeys, this.selectedUsers)
      this.visible = false
      this.onClosed()
    },
    handleCancel () {
      this.visible = false
      this.onClosed()
    },
    onClosed () {
      this.searchFormDisable.type = false
      this.searchFormDisable.deptId = false
      this.searchFormDisable.status = false
      this.searchFormDisable.keyword = false
      this.searchFormDisable.supplierId = false
    },
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      managerList()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.selectedRowKeys = []
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    onRowSelectChanged (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style scoped>

</style>
