<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>图片</label>
        <span>
          <a-button @click="openEditor()">添加图片</a-button>
          <draggable
              tag="div"
              v-model="section.images"
              v-bind="imageDrag.dragOptions"
              @start="imageDrag.drag = true"
              @end="imageDrag.drag = false"
          >
            <transition-group tag="div" type="transition" :name="!imageDrag.drag ? 'flip-list' : null" class="sortable-list image-list">
              <div v-for="item in section.images" :key="item.id" class="list-item">
                <div class="image-item cover"
                     :class="{rounded: section.rounded}"
                     :style="{'background-image': `url(${item.imageUrl})`}"
                >
                  <div class="buttons">
                    <div class="icon sort"><a-icon type="unordered-list" /></div>
                    <div class="icon edit" @click="openEditor(item)"><a-icon type="edit" /></div>
                    <div class="icon delete" style="background-color: #bb3e30; color: #fff;" @click="handleRemoveItem(item)"><a-icon type="delete" /></div>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </span>
      </div>
      <div class="detail-item">
        <label>圆形</label>
        <span>
          <a-switch v-model="section.rounded" />
        </span>
      </div>
      <div class="detail-item">
        <label>对齐</label>
        <span>
          <a-select v-model="section.align" style="width: 200px;">
            <a-select-option value="left">左对齐</a-select-option>
            <a-select-option value="center">居中</a-select-option>
            <a-select-option value="right">右对齐</a-select-option>
          </a-select>
        </span>
      </div>
      <div class="detail-item">
        <label>宽度</label>
        <span>
          <a-input-number v-model="section.width" :min="1" />
        </span>
      </div>
      <div class="detail-item">
        <label>高度</label>
        <span>
          <a-input-number v-model="section.height" :min="1" />
        </span>
      </div>
      <div class="detail-item">
        <label>间隔</label>
        <span>
          <a-input-number v-model="section.gap" :min="0" />
        </span>
      </div>
    </div>

    <modal title="编辑" ref="editor" @ok="onEditorOk" @closed="onEditorClosed">
      <a-form-model ref="form" :model="imageEditor.form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="图片" class="app_required-input">
          <image-picker :image-url.sync="imageEditor.form.imageUrl" width="120px" height="120px"></image-picker>
        </a-form-model-item>
        <a-form-model-item label="图片链接" class="app_required-input">
          <a-input v-model="imageEditor.form.link" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>
import ImagePicker from '../comp/image-picker'
import ImageListModel from '../model/image-list'
import Draggable from 'vuedraggable'
import kit from '@/utils/kit'

export default {
  components: { ImagePicker, Draggable },
  props: {
    section: { type: ImageListModel }
  },
  data () {
    return {
      imageDrag: {
        dragOptions: {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          handle: '.sort'
        },
        drag: false
      },
      imageEditor: {
        editingItem: null,
        form: {
          imageUrl: null,
          link: null
        }
      }
    }
  },
  watch: {
  },
  methods: {
    openEditor (item) {
      if (item) {
        this.imageEditor.editingItem = item
        this.imageEditor.form.imageUrl = item.imageUrl
        this.imageEditor.form.link = item.link
      }
      this.$refs.editor.open()
    },
    onEditorOk () {
      let item
      let insert = true
      if (this.imageEditor.editingItem) {
        item = this.imageEditor.editingItem
        insert = false
      } else {
        item = { id: kit.str.id(), imageUrl: null, link: null }
      }
      Object.assign(item, this.imageEditor.form)
      if (insert) {
        this.section.images.push(item)
      }
      this.$refs.editor.close()
    },
    onEditorClosed () {
      this.imageEditor.editingItem = null
      this.imageEditor.form.imageUrl = null
      this.imageEditor.form.link = null
    },
    handleRemoveItem (item) {
      kit.arr.removeItem(this.section.images, item)
    }
  }
}
</script>

<style lang="less" scoped>
.image-list {
  display: flex;
  .list-item {
    margin: 10px;
  }
  .image-item.rounded {
    border-radius: 100%;
    overflow: hidden;
  }
  .image-item {
    position: relative;
    .buttons {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,.2);
      .icon {
        color: #000;
        background-color: #fff;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 100%;
        font-size: 12px;
        text-align: center;
      }
      .icon + .icon {
        margin-left: 4px;
      }
      .icon.sort {
        cursor: move;
      }
      .icon.edit {
        cursor: pointer;
      }
    }
    &:hover .buttons {
      display: flex;
    }
  }
}
</style>
