<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="用户编辑"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="手机" prop="username" class="app_required-input">
        <a-input v-model="form.username" :read-only="!isUsernameEditable"/>
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name" class="app_required-input">
        <a-input v-model="form.name"/>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="tags">
        <a-input v-model="form.tags"/>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" class="app_required-input">
        <a-select v-model="form.type" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in typeOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门" prop="deptId">
        <a-select v-model="form.deptId" :allow-clear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0;" />
            <div style="padding: 4px 8px; cursor: pointer;" @mousedown="e => e.preventDefault()" @click="$refs.dept.open()">
              <a-icon type="plus" /> 添加新部门
            </div>
          </div>
          <a-select-option v-for="s in deptOptions" :key="s.id">{{s.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门领导" prop="deptLeader" v-show="form.deptId">
        <a-switch v-model="form.deptLeader"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="供应商" prop="type" class="app_required-input" v-show="supplierOptionsVisible">
        <a-select v-model="form.supplierId" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否可用" prop="status">
        <a-switch v-model="form.status"></a-switch>
      </a-form-model-item>
    </a-form-model>

    <a-divider orientation="left">角色列表</a-divider>

    <a-empty v-show="roles.length === 0" description="还没有创建任何角色" />

    <ul class="list-group">
      <li class="list-group-item" v-for="r in roles" :key="r.id">
        <div class="left-right-content">
          <div class="full">
            <strong>{{r.name}}</strong>
            <p class="font-mini text-muted">{{r.description}}</p>
          </div>
          <div>
            <a-switch v-model="r.applied"></a-switch>
          </div>
        </div>
      </li>
    </ul>

    <dept-manager ref="dept" @closed="loadDept()"></dept-manager>
  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import {
  addManager,
  addSupplierUser,
  updateManager,
  getUserRoles
} from '@/http/api/admin'
import { USER_TYPE_LIST, USER_TYPE_SUPPLIER } from '@/constants/user-type'
import UserUpdateDto from '@/data/dto/user-update'
import CurrentUserRoleDto from '@/data/dto/current-user-role'
import DeptManager from '@/components-business/dept-manager'
import { listAll as listDept } from '@/http/api/dept'

export default {
  components: {
    DeptManager,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    supplierOptions: {
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: new UserUpdateDto(),
      typeOptions: USER_TYPE_LIST,
      roles: [],
      deptOptions: []
    }
  },
  watch: {
    'form.id' (id) {
      if (id) {
      }
    }
  },
  computed: {
    editable () {
      if (this.model) {
        return this.model.editable
      } else {
        return true
      }
    },
    isUpdate () {
      return !!this.form.id
    },
    isUsernameEditable () {
      return !this.isUpdate
    },
    appliedRoleIds () {
      const arr = []
      for (const r of this.roles) {
        if (r.applied) {
          arr.push(r.id)
        }
      }
      return arr
    },
    supplierOptionsVisible () {
      return this.form.type === USER_TYPE_SUPPLIER
    }
  },
  methods: {
    open (model) {
      this.model = model
      this.visible = true
      if (model) {
        this.$nextTick(() => {
          kit.obj.getProperties(this.form, model)
        })
      }
      getUserRoles()
        .success(resp => {
          this.roles = kit.arr.newList(resp.data, CurrentUserRoleDto)
          this.roles.sort((a, b) => a.name.localeCompare(b.name))
        })
        .send(model ? model.id : undefined)

      this.loadDept()
    },
    onClose () {
      this.$refs.form.resetFields()
      this.form.id = null
      this.model = null
    },
    loadDept () {
      listDept().success(resp => (this.deptOptions = resp.data)).send()
    },
    onSave () {
      if (this.isUpdate && !this.form.status) {
        this.$confirm({
          title: '警告',
          content: '禁用用户会使该用户被强制登出，是否继续？',
          onOk: () => {
            this.save()
          }
        })
      } else {
        this.save()
      }
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      let api
      if (this.isUpdate) {
        api = updateManager
      } else {
        if (this.form.type === USER_TYPE_SUPPLIER) {
          api = addSupplierUser
        } else {
          api = addManager
        }
      }
      this.form.appliedRoleIds = [...this.appliedRoleIds]
      api()
        .complete((success) => {
          this.closable = true
          this.saveButtonLoading = false
          if (success) {
            this.$nextTick(() => (this.visible = false))
          }
        })
        .success(resp => {
          this.form.id = resp.data.id
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
          this.visible = false
        })
        .send(this.form)
    }
  }
}
</script>

<style scoped>

</style>
