import defineApi from '../define-api'
import http from '../application-api'

export const listShopifyProducts = defineApi((config, params) => {
  config.params = params
  http.get('/shopify/product/list', config)
})

export const searchShopifyProducts = defineApi((config, params) => {
  config.params = params
  http.get('/shopify/product/search', config)
})

export const listAllProducts = defineApi((config, shopId) => {
  config.params = { shopId: shopId }
  http.get('/shopify/product/list/all', config)
})

export const uploadFile = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  return http.upload('/shopify/product/upload/file', config)
})

export const updateProductDescription = defineApi((config, shopId, productId, description) => {
  config.data = {
    shopId: shopId,
    productId: productId,
    description: description
  }
  http.post('/shopify/product/update/description', config)
})

/**
 * @param data {Object}
 */
export const previewSku = defineApi((config, data) => {
  config.data = data
  http.post('/shopify/product/sku/preview', config)
})

/**
 * @param data {Object} 和previewSku相同
 */
export const generateSku = defineApi((config, data) => {
  config.data = data
  http.post('/shopify/product/sku/generate', config)
})



