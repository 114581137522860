<template>
  <div :style="{width: width}">
    <div class="image-selector" :style="{height: height}">
      <div class="preview" :style="{'background-image': `url(${url})`}">
      </div>
      <div class="toolbar">
        <span @click="selectImage">选择</span>
        <span @click="removeImage">删除</span>
      </div>
    </div>
    <p v-if="description" style="font-size: 12px; color: #5c5c5c; margin-top: 10px; line-height: normal;">{{description}}</p>
  </div>
</template>

<script>
import { getUrlByFileId } from '@/http/api/file'

export default {
  name: 'image-selector',
  props: {
    fileId: { type: String },
    description: { type: String },
    width: { type: String, default: '100%' },
    height: { type: String, default: '250px' }
  },
  computed: {
    url () {
      return this.fileId ? getUrlByFileId(this.fileId, true) : null
    }
  },
  methods: {
    selectImage () {
      this.$fileManager.openForSingleSelect(files => {
        this.$emit('update:fileId', files[0].id)
      })
    },
    removeImage () {
      this.$emit('update:fileId', null)
    }
  }
}
</script>

<style lang="less" scoped>
@border: solid 1px #ddd;
.image-selector {
  display: flex;
  flex-direction: column;
  border: @border;
  border-radius: 2px;
  background-color: #fff;
  .preview {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .toolbar {
    height: 40px;
    line-height: 40px;
    border-top: @border;
    display: flex;
    span {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      flex: 1;
      cursor: pointer;
    }
    span + span {
      border-left: @border;
    }
  }
}
</style>
