var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"title":"达人分类管理器","hide-buttons":true},on:{"closed":function($event){return _vm.$emit('closed')}}},[_c('loading',{attrs:{"loading":_vm.loading}}),_c('div',{staticStyle:{"max-height":"450px","padding":"0","overflow-y":"auto"}},[_c('a-alert',{attrs:{"message":"右键某个分类可弹出操作菜单。","type":"info","show-icon":""}}),_c('a-tree',{attrs:{"tree-data":_vm.dataList,"replaceFields":{ key: 'id', title: 'name' },"expandedKeys":_vm.expandedKeys,"selectable":false},on:{"update:expandedKeys":function($event){_vm.expandedKeys=$event},"update:expanded-keys":function($event){_vm.expandedKeys=$event}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var id = ref.id;
var name = ref.name;
var parentId = ref.parentId;
return [_c('a-dropdown',{attrs:{"trigger":['contextmenu']},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',{on:{"click":function (ref) {
	var menuKey = ref.key;

	return _vm.onContextMenuClick(id, menuKey);
}}},[_c('a-menu-item',{key:"add1"},[_vm._v("添加顶级分类")]),_c('a-menu-item',{directives:[{name:"show",rawName:"v-show",value:(!parentId),expression:"!parentId"}],key:"add2"},[_vm._v("添加【"+_vm._s(name)+"】的子分类")]),_c('a-menu-item',{key:"update"},[_vm._v("更新【"+_vm._s(name)+"】")]),_c('a-menu-item',{key:"del"},[_vm._v("删除【"+_vm._s(name)+"】")])],1)]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(name))])])]}}])})],1),_c('modal',{ref:"editor",attrs:{"title":"分类编辑","loading":_vm.loading},on:{"closed":_vm.onEditorClosed,"ok":_vm.handleUpdate}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.editor.form,"label-col":{span: 4},"wrapper-col":{span: 20}}},[_c('a-form-model-item',{staticClass:"app_required-input",attrs:{"label":"上级分类"}},[_c('a-select',{attrs:{"allow-clear":true,"disabled":_vm.editor.disableParentSelect},model:{value:(_vm.editor.form.parentId),callback:function ($$v) {_vm.$set(_vm.editor.form, "parentId", $$v)},expression:"editor.form.parentId"}},_vm._l((_vm.dataList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',{staticClass:"app_required-input",attrs:{"label":"分类名称"}},[_c('a-input',{model:{value:(_vm.editor.form.name),callback:function ($$v) {_vm.$set(_vm.editor.form, "name", $$v)},expression:"editor.form.name"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }