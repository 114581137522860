<!-- 工厂下单生产产品编辑器 -->
<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      :loading="loading"
      title="发货产品成本编辑"
      @close="onClose"
      @save="handleSave"
      :width="650"
  >
    <div style="text-align: right; margin-bottom: 10px;">
      <a-button @click="getShipProductItemCost()">获取参考成本</a-button>
    </div>
    <ul class="list-group">
      <li class="header">
        <div class="wrapper" style="margin-bottom: 10px;">
          <div class="checkbox">
            <a-checkbox v-model="checkAll"></a-checkbox>
          </div>
          <div class="image">图片</div>
          <div class="sku">SKU</div>
          <div class="total">数量</div>
          <div class="from-supplier">供应商</div>
          <div class="cost">总成本</div>
        </div>
      </li>
      <li v-for="item in deliveryItems" :key="item.id" class="list-group-item hover-item">
        <div class="wrapper">
          <div class="checkbox"><a-checkbox v-model="item._checked"></a-checkbox></div>
          <div class="image-item small cover" :style="{'background-image': `url(${item.imageUrl})`}" @click="$imageModal.open(item.imageUrl)"></div>
          <div class="sku">{{item.sku}}</div>
          <div class="total">{{item.total}}</div>
          <div class="from-supplier">
            <a-select v-model="item.fromSupplierId" style="width: 100%;" placeholder="供应商" :allowClear="true">
              <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </div>
          <div class="cost">
            <a-input-number v-model="item.cost" :min="0" :precision="2" style="width: 100%;" />
          </div>
        </div>
      </li>

      <currency-convertor
          :to-curr="currencyConvertor.toCurr"
          :to-curr-editable="false"
          :convert-list="checkedDeliveryItems"
          empty-list-message="请先勾选要转换金额的项目。"
          style="margin-top: 20px;"></currency-convertor>
    </ul>
  </drawer>
</template>

<script>
import { getCosts, updateDeliveryItems } from '@/http/api/order-delivery'
import { listSupplier } from '@/http/api/supplier'
import CurrencyConvertor from '@/components-business/currency-convertor'

export default {
  components: { CurrencyConvertor },
  data () {
    return {
      visible: false,
      closable: true,
      loading: false,
      saveButtonLoading: false,
      deliveryItems: [],
      originDeliveryItems: [],
      supplierOptions: [],
      orderId: null,
      currencyConvertor: {
        toCurr: 'CNY'
      },
      checkAll: false
    }
  },
  computed: {
    checkedDeliveryItems () {
      return this.deliveryItems.filter(item => item._checked)
    }
  },
  watch: {
    checkAll () {
      this.deliveryItems.forEach(item => (item._checked = this.checkAll))
    }
  },
  methods: {
    open (orderId, deliveryItems) {
      this.orderId = orderId
      this.visible = true
      this.originDeliveryItems = deliveryItems
      const arr = []
      for (const item of deliveryItems) {
        arr.push(Object.assign({
          _checked: false
        }, item))
      }
      this.deliveryItems = arr
      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }
    },
    onClose () {
      this.orderId = null
      this.deliveryItems = []
      this.originDeliveryItems = []
    },
    handleSave () {
      const arr = []
      const map = {}
      for (const item of this.deliveryItems) {
        const data = {
          id: item.id,
          fromSupplierId: item.fromSupplierId,
          cost: item.cost
        }
        arr.push(data)
        map[data.id] = data
      }
      this.saveButtonLoading = true
      updateDeliveryItems()
        .complete(() => (this.saveButtonLoading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.visible = false
          for (const item of this.originDeliveryItems) {
            item.fromSupplierId = map[item.id].fromSupplierId
            const supplier = this.supplierOptions.find(s => s.id === item.fromSupplierId)
            if (supplier) {
              item.fromSupplierName = supplier.name
            }
            item.cost = map[item.id].cost
          }
        })
        .send(arr)
    },
    getShipProductItemCost () {
      const items = []
      for (const data of this.deliveryItems) {
        items.push({
          sku: data.sku,
          supplierId: data.fromSupplierId
        })
      }
      this.loading = true
      getCosts()
        .complete(() => (this.loading = false))
        .success(resp => {
          for (const p of this.deliveryItems) {
            const key = (p.sku || '') + (p.fromSupplierId || '')
            if (resp.data[key]) {
              p.cost = resp.data[key] * p.total
            }
          }
        })
        .send({ orderId: this.orderId, items: items })
    }
  }
}
</script>

<style lang="less" scoped>
.list-group .header {
  padding: 0 15px;
}
.wrapper {
  display: flex;
  align-items: center;
  & > div {
    margin: 0 10px;
  }
  & > div:first-of-type {
    margin-left: 0;
  }
  & > div:last-of-type {
    margin-right: 0;
  }
  .checkbox {
    width: 20px;
  }
  .image {
    width: 40px;
  }
  .sku {
    flex: 1;
  }
  .total {
    width: 35px;
    text-align: center;
  }
  .from-supplier {
    width: 150px;
  }
  .cost {
    width: 120px;
  }
}
</style>
