import kit from '@/utils/kit'

export const EVENT_TYPE_TEXT = 'text'
export const EVENT_TYPE_IMAGE = 'image'
export const EVENT_TYPE_REFUND = 'refund'
export const EVENT_TYPE_ORDER_RETURN = 'orderReturn'
export const EVENT_TYPE_ROBOT = 'robot'

export const EVENT_TYPE_MAPPING = {
  [EVENT_TYPE_TEXT]: '文本',
  [EVENT_TYPE_IMAGE]: '图片',
  [EVENT_TYPE_REFUND]: '退款',
  [EVENT_TYPE_ORDER_RETURN]: '退货',
  [EVENT_TYPE_ROBOT]: '系统消息'
}

export const EVENT_TYPE = {
  text: EVENT_TYPE_TEXT,
  image: EVENT_TYPE_IMAGE,
  refund: EVENT_TYPE_REFUND,
  orderReturn: EVENT_TYPE_ORDER_RETURN,
  robot: EVENT_TYPE_ROBOT
}

export const EVENT_TYPE_LIST = kit.obj.toArray(EVENT_TYPE_MAPPING)
