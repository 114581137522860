<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button type="primary" @click="handleOpenEditor">添加</a-button>
      <a-button type="danger" @click="handleDelete" :loading="deleteLoading">删除</a-button>
    </div>
    <div slot="top">
      <a-form-model layout="inline">
        <a-form-model-item label="关键字">
          <a-input v-model="searchForm.keyword" :allowClear="true"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loadData">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onRowSelectChanged }"
    >
    </a-table>

    <user-selector ref="userSelector" @selected="onUserSelected"></user-selector>

    <modal ref="editor" title="仓库配货员编辑" :width="1000" :loading="editor.loading" @closed="onEditorClosed" @ok="onEditorOk">
      <div class="left-right-content">
        <div class="section">
          <div class="section-head">
            <span class="title">请选择用户</span>
            <div class="toolbar">
              <a-button size="small" @click="$refs.userSelector.open()">选择用户</a-button>
            </div>
          </div>
          <div class="section-body">
            <ul class="list-container">
              <li class="list-item" v-for="u in editor.users" :key="u.id">
                <a-button type="danger" size="small" @click="handleRemoveSelectedUser(u)">移除</a-button>
                <span style="margin-left: 10px;">
                  {{u.name}}
                  <span class="text-muted font-mini">（{{u.supplierName}} / {{u.tags}}）</span>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="section">
          <div class="section-head">
            请选择仓库
            <span class="text-muted font-mini" v-show="selectedWarehouseIds.length > 0">（已选择 {{selectedWarehouseIds.length}} 个）</span>
          </div>
          <div class="section-body">
            <ul class="list-container">
              <li class="list-item" v-for="w in editor.warehouses" :key="w.id">
                <a-checkbox v-model="w._checked"></a-checkbox>
                <span style="margin-left: 10px;">{{w.name}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </modal>
  </app-page>
</template>

<script>
import { ROUTE_WAREHOUSE_PREPARE_USER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { list, addBatch, deleteBatch } from '@/http/api/warehouse-prepare-user'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import UserSelector from '@/components-business/user-select'
import kit from '@/utils/kit'

export default {
  components: { UserSelector },
  data () {
    return {
      routeName: ROUTE_WAREHOUSE_PREPARE_USER,
      dataColumns: [
        { title: '姓名', dataIndex: 'name', width: 150 },
        { title: '标签', dataIndex: 'tags' },
        { title: '所属供应商', dataIndex: 'supplierName' },
        { title: '仓库', dataIndex: 'warehouseName' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        keyword: null
      },
      selectedRowKeys: [],
      editor: {
        users: [],
        warehouses: [],
        loading: false
      },
      deleteLoading: false
    }
  },
  mixins: [AntdTableHeightMixin],
  computed: {
    selectedWarehouseIds () {
      const arr = []
      for (const item of this.editor.warehouses) {
        if (item._checked) {
          arr.push(item.id)
        }
      }
      return arr
    },
    selectedUserIds () {
      const arr = []
      for (const item of this.editor.users) {
        arr.push(item.id)
      }
      return arr
    }
  },
  methods: {
    loadData () {
      const p = Object.assign({}, this.searchForm)
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(p)
    },
    onRowSelectChanged (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleOpenEditor () {
      this.$refs.editor.open()
    },
    onUserSelected (idList, users) {
      const arr = []
      for (const u of users) {
        if (this.editor.users.findIndex(item => item.id === u.id) < 0) {
          const data = { id: u.id, name: u.name, supplierName: u.supplierName, tags: u.tags }
          arr.push(data)
        }
      }
      this.editor.users.push(...arr)
    },
    handleRemoveSelectedUser (user) {
      kit.arr.removeItem(this.editor.users, user)
    },
    onEditorClosed () {
      this.editor.users = []
      for (const w of this.editor.warehouses) {
        w._checked = false
      }
    },
    onEditorOk () {
      const arr = []
      for (const wId of this.selectedWarehouseIds) {
        for (const uId of this.selectedUserIds) {
          arr.push({ adminId: uId, warehouseId: wId })
        }
      }
      this.editor.loading = true
      addBatch()
        .complete(() => (this.editor.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.$nextTick(() => (this.$refs.editor.close()))
          this.loadData()
        })
        .send(arr)
    },
    handleDelete () {
      this.$confirm({
        title: '确认',
        content: '确定要删除选中的行吗？',
        onOk: () => {
          this.deleteLoading = true
          deleteBatch()
            .complete(() => (this.deleteLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadData()
            })
            .send(this.selectedRowKeys)
        }
      })
    }
  },
  mounted () {
    this.loadData()
    listWarehouseEnabled()
      .success(resp => {
        for (const item of resp.data) {
          item._checked = false
        }
        this.editor.warehouses = resp.data
      })
      .send()
  }
}
</script>

<style lang="less" scoped>
.section + .section {
  margin-left: 30px;
}
.section {
  border: solid 1px #eee;
  background-color: #fff;
}
.section-head {
  height: 45px;
  padding: 0 10px;
  border-bottom: solid 1px #eee;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .title {
    flex: 1;
    margin-right: 10px;
  }
}
.section-body {
  padding: 10px;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}

.list-container {
  list-style: none;
  margin: 0;
  padding: 0;
  .list-item {
    display: block;
    padding: 5px 10px;
    text-align: left;
    &:not(:last-of-type) {
      border-bottom: solid 1px #f2f2f2;
    }
  }
}
</style>
