<template>
  <div class="web-design">
    <div style="text-align: right;">
      <a-dropdown>
        <a-menu slot="overlay" @click="handleAddSection">
          <a-menu-item key="banner">Banner</a-menu-item>
          <a-menu-item key="paragraph">段落</a-menu-item>
          <a-menu-item key="product">产品</a-menu-item>
          <a-menu-item key="singleProduct">单个产品</a-menu-item>
          <a-menu-item key="button">按钮</a-menu-item>
          <a-menu-item key="image">图片</a-menu-item>
          <a-menu-item key="imageList">图片集</a-menu-item>
          <a-menu-item key="menu">菜单</a-menu-item>
          <a-menu-item key="messageBar">消息栏</a-menu-item>
          <a-menu-item key="promotion">促销方块</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 添加模块 <a-icon type="down" /> </a-button>
      </a-dropdown>
    </div>

    <draggable
        tag="div"
        v-model="designData.sections"
        v-bind="sectionDrag.dragOptions"
        @start="sectionDrag.drag = true"
        @end="sectionDrag.drag = false"
    >
      <transition-group tag="div" type="transition" :name="!sectionDrag.drag ? 'flip-list' : null" class="sortable-list section-list">
        <section-comp v-for="(s, index) in designData.sections" :key="s.id" :section="s" :index="index + 1" @remove="handleRemoveSection"></section-comp>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import kit from '@/utils/kit'
import Draggable from 'vuedraggable'
import SectionComp from './section'
import BannerModel from './model/banner'
import ParagraphModel from './model/paragraph'
import ProductModel from './model/product'
import ButtonModel from './model/button'
import ImageModel from './model/image'
import ImageListModel from './model/image-list'
import MenuModel from './model/menu'
import MessageBarModel from './model/message-bar'
import PromotionBlockModel from './model/promotion-block'
import SingleProductModel from './model/single-product'

function createDefaultData () {
  return {
    sections: []
  }
}
export default {
  components: { SectionComp, Draggable },
  data () {
    return {
      sectionDrag: {
        dragOptions: {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          handle: '.order-icon'
        },
        drag: false
      },
      designData: createDefaultData(),
      designModelMap: {
        banner: BannerModel,
        paragraph: ParagraphModel,
        button: ButtonModel,
        product: ProductModel,
        image: ImageModel,
        imageList: ImageListModel,
        menu: MenuModel,
        messageBar: MessageBarModel,
        promotion: PromotionBlockModel,
        singleProduct: SingleProductModel
      }
    }
  },
  computed: {
  },
  methods: {
    initData (data) {
      if (data) {
        const sections = data.sections
        delete data.sections
        kit.obj.getProperties(this.designData, data)
        for (const s of sections) {
          const Model = this.designModelMap[s.sectionType]
          const model = new Model()
          model.initData(s)
          this.designData.sections.push(model)
        }
      } else {
        this.designData = createDefaultData()
      }
    },
    getData () {
      return this.designData
    },
    handleAddSection (e) {
      const Model = this.designModelMap[e.key]
      this.designData.sections.push(new Model())
    },
    handleRemoveSection (section) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${section.sectionName}】吗？`,
        onOk: () => {
          kit.arr.removeItem(this.designData.sections, section)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.web-design {
  padding: 20px;
  border: solid 1px #ddd;
  border-radius: 5px;
  background-color: #fff;
}
</style>
