<template>
  <div class="file-detail" :class="{visible: visible}" :style="style">
    <div class="header">
      <div class="title"></div>
      <a-icon type="close" class="close-icon" @click="close" />
    </div>
    <div class="body">
      <div class="image-container">
        <div class="image-view">
          <div class="image-display" :style="imageDisplayStyle"></div>
          <div class="image-tool" v-show="!editMode">
            <span @click="handleDownloadFile"><a-icon type="download" /></span>
            <span v-show="fileEditable" @click="editMode = true"><a-icon type="edit" /></span>
          </div>
          <loading :loading="imageLoading">正在加载图片...</loading>
          <vue-cropper
              v-if="file && editMode"
              ref="cropper"
              :viewMode="1"
              :containerStyle="{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}"
              :src="file.getOriginUrl()"
              :checkCrossOrigin="false"
              @crop="handleCropperCrop"
              @ready="handleCropperReady"
          >
          </vue-cropper>
        </div>
        <div class="image-edit-tool" v-show="editMode">
          <a-button-group>
            <a-tooltip>
              <template slot="title">拖拽移动</template>
              <a-button type="primary" icon="drag" @click="handleCropperAction('move')" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">裁减</template>
              <a-button type="primary" icon="scissor" @click="handleCropperAction('crop')" />
            </a-tooltip>
          </a-button-group>
          <a-button-group>
            <a-tooltip>
              <template slot="title">逆时针旋转</template>
              <a-button type="primary" icon="undo" @click="handleCropperAction('rotateLeft')" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">顺时针旋转</template>
              <a-button type="primary" icon="redo" @click="handleCropperAction('rotateRight')" />
            </a-tooltip>
          </a-button-group>
          <a-button-group>
            <a-tooltip>
              <template slot="title">水平翻转</template>
              <a-button type="primary" icon="column-width" @click="handleCropperAction('flipH')" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">垂直翻转</template>
              <a-button type="primary" icon="column-height" @click="handleCropperAction('flipV')" />
            </a-tooltip>
          </a-button-group>
          <a-button-group>
            <a-tooltip>
              <template slot="title">裁减区域 - 正方形</template>
              <a-button type="primary" @click="handleCropperAction('ratio:1-1')">1:1</a-button>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">裁减区域 - 长方形</template>
              <a-button type="primary" @click="handleCropperAction('ratio:16-9')">16:9</a-button>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">裁减区域 - 自定义</template>
              <a-button type="primary" @click="handleCropperAction('ratio:Custom')">自定义</a-button>
            </a-tooltip>
          </a-button-group>
          <a-button-group>
            <a-tooltip>
              <template slot="title">裁减图片</template>
              <a-button type="primary" icon="save" @click="handleCropperAction('save')" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">退出编辑器</template>
              <a-button type="primary" icon="close" @click="handleCropperAction('close')" :loading="imageLoading" />
            </a-tooltip>
          </a-button-group>
        </div>
      </div>
      <div class="info-container">
        <div class="file-description" v-if="file">
          <a-divider orientation="center">图片信息</a-divider>
          <div class="description-item">
            <label>图片名称</label>
            <span>
              <a-input v-model="newFileName">
                <a-icon
                    slot="addonAfter"
                    :type="nameUpdating ? 'loading' : 'check'"
                    style="cursor: pointer;"
                    @click="handleUpdateFileName" />
              </a-input>
            </span>
          </div>
          <div class="description-item">
            <label>原图地址</label>
            <span>
              {{file.getOriginUrl()}}
              <a-button type="link" size="small" @click="handleCopyText(file.getOriginUrl())">复制</a-button>
            </span>
          </div>
          <div class="description-item">
            <label>缩略图地址</label>
            <span>
              {{file.getCoverUrl()}}
              <a-button type="link" size="small" @click="handleCopyText(file.getCoverUrl())">复制</a-button>
            </span>
          </div>
          <div class="description-item">
            <label>上传时间</label>
            <span>{{file.uploadTime}}</span>
          </div>
          <div class="description-item">
            <label>文件大小</label>
            <span>{{file.getSizeText()}}</span>
          </div>
          <div class="description-item">
            <label>文件类型</label>
            <span>{{file.type}}</span>
          </div>
          <div class="description-item" v-if="file.dimension">
            <label>图片尺寸</label>
            <span>{{file.dimension.width}} x {{file.dimension.height}}</span>
          </div>
        </div>
        <div class="file-description" v-if="editMode">
          <a-divider orientation="center">图片编辑信息</a-divider>
          <div class="description-item">
            <label>X</label>
            <span>{{editProfile.x}}</span>
          </div>
          <div class="description-item">
            <label>Y</label>
            <span>{{editProfile.y}}</span>
          </div>
          <div class="description-item">
            <label>宽度</label>
            <span>{{editProfile.width}} px</span>
          </div>
          <div class="description-item">
            <label>高度</label>
            <span>{{editProfile.height}} px</span>
          </div>
          <div class="description-item">
            <label>旋转角度</label>
            <span>{{editProfile.rotate}} deg</span>
          </div>
          <div class="description-item">
            <label>水平翻转</label>
            <span>{{editProfile.scaleX === 1 ? '正常' : '颠倒'}}</span>
          </div>
          <div class="description-item">
            <label>垂直翻转</label>
            <span>{{editProfile.scaleY === 1 ? '正常' : '颠倒'}}</span>
          </div>
        </div>
      </div>
    </div>

    <a-modal
        v-model="cropDialog.visible"
        title="图片裁减预览"
        :confirm-loading="cropDialog.uploading"
        :closable="!cropDialog.uploading"
        :maskClosable="!cropDialog.uploading"
        :keyboard="!cropDialog.uploading"
        :zIndex="10005"
    >
      <div class="image-preview" :style="{'background-image': `url(${previewImageUrl})`}"></div>
      <template slot="footer">
        <a-button :loading="cropDialog.uploading" @click="handleUploadCropImage(true)">裁减并替换原图</a-button>
        <a-button :loading="cropDialog.uploading" @click="handleUploadCropImage(false)">裁减作为副本保存</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import kit from '@/utils/kit'
import { updateFileName, uploadCropFile } from '@/http/api/file'

const editProfileInit = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotate: 0,
  scaleX: 1,
  scaleY: 1,
  aspectRatio: NaN
}
export default {
  components: { VueCropper },
  data () {
    return {
      visible: false,
      file: null,
      editMode: false,
      newFileName: null,
      nameUpdating: false,
      imageLoading: false,
      editProfile: Object.assign({}, editProfileInit),
      imageViewWidth: 0,
      imageViewHeight: 0,
      cropDialog: {
        visible: false,
        imageBlob: null,
        uploading: false
      }
    }
  },
  filters: {
  },
  computed: {
    style () {
      if (this.visible) {
        return {
          top: '30px'
        }
      } else {
        let h = 0
        if (this.$el && this.$el.parentElement) {
          h = this.$el.parentElement.getBoundingClientRect().height
          return {
            top: `${h}px`,
            height: 'auto'
          }
        } else {
          return {
            height: 0
          }
        }
      }
    },
    imageDisplayStyle () {
      if (this.file && !this.editMode) {
        const style = {
          'background-image': `url(${this.file.getOriginUrl()})`
        }
        if (this.file.dimension.width < this.imageViewWidth && this.file.dimension.height < this.imageViewHeight) {
          style['background-size'] = `${this.file.dimension.width}px ${this.file.dimension.height}px`
        } else {
          style['background-size'] = 'contain'
        }
        return style
      } else {
        return null
      }
    },
    fileEditable () {
      return this.file && this.file.isImage()
    },
    previewImageUrl () {
      if (this.cropDialog.imageBlob) {
        return URL.createObjectURL(this.cropDialog.imageBlob)
      } else {
        return null
      }
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        kit.dom.removeEvent(this.$el, 'transitionend', this.handleDialogTransitionEnd)
        this.file = null
        this.newFileName = null
        this.editMode = false
      }
    },
    'cropDialog.visible' (v) {
      if (!v) {
        this.cropDialog.imageBlob = null
      }
    },
    editMode () {
      if (!this.editMode) {
        Object.assign(this.editProfile, editProfileInit)
      }
    }
  },
  methods: {
    open (file) {
      kit.dom.addEvent(this.$el, 'transitionend', this.handleDialogTransitionEnd)
      this.visible = true
      this.file = file
      this.newFileName = file.name
      if (file.image) {
        const img = new Image()
        img.onload = () => {
          this.imageLoading = false
        }
        img.onerror = () => {
          this.imageLoading = false
          this.$message.error('图片加载失败。')
        }
        img.onabort = () => (this.imageLoading = false)
        this.imageLoading = true
        img.src = file.url
      }
    },
    close () {
      this.visible = false
    },
    handleDialogTransitionEnd () {
      const imageViewRect = this.$el.getElementsByClassName('image-display')[0].getBoundingClientRect()
      this.imageViewWidth = imageViewRect.width
      this.imageViewHeight = imageViewRect.height
    },
    handleCopyText (text) {
      this.$copyText(text).then(() => {
        this.$message.success('复制成功')
      })
    },
    handleUpdateFileName () {
      if (!this.nameUpdating) {
        this.nameUpdating = true
        updateFileName()
          .complete(() => (this.nameUpdating = false))
          .success(() => {
            this.$message.success('更新成功。')
            this.file.name = this.newFileName
          })
          .send(this.file.id, this.newFileName)
      }
    },
    handleDownloadFile () {
      window.open(this.file.url)
    },
    handleCropperAction (action) {
      const cropper = this.$refs.cropper
      switch (action) {
        case 'move':
          cropper.setDragMode('move')
          break
        case 'crop':
          cropper.setDragMode('crop')
          break
        case 'rotateLeft':
          cropper.rotate(-90)
          break
        case 'rotateRight':
          cropper.rotate(90)
          break
        case 'flipH':
          cropper.scaleX(this.editProfile.scaleX === 1 ? -1 : 1)
          break
        case 'flipV':
          cropper.scaleY(this.editProfile.scaleY === 1 ? -1 : 1)
          break
        case 'ratio:1-1':
          this.editProfile.aspectRatio = 1
          cropper.setAspectRatio(this.editProfile.aspectRatio)
          break
        case 'ratio:16-9':
          this.editProfile.aspectRatio = 16 / 9
          cropper.setAspectRatio(this.editProfile.aspectRatio)
          break
        case 'ratio:custom':
          this.editProfile.aspectRatio = NaN
          cropper.setAspectRatio(this.editProfile.aspectRatio)
          break
        case 'close':
          this.editMode = false
          break
        case 'save':
          cropper.getCroppedCanvas().toBlob(blob => {
            this.cropDialog.imageBlob = blob
            this.cropDialog.visible = true
          }, 'image/jpeg', 0.9)
          break
      }
    },
    handleCropperCrop (e) {
      const d = e.detail
      this.editProfile.x = Math.round(d.x)
      this.editProfile.y = Math.round(d.y)
      this.editProfile.width = Math.round(d.width)
      this.editProfile.height = Math.round(d.height)
      this.editProfile.rotate = typeof d.rotate !== 'undefined' ? d.rotate : 0
      this.editProfile.scaleX = typeof d.scaleX !== 'undefined' ? d.scaleX : 0
      this.editProfile.scaleY = typeof d.scaleY !== 'undefined' ? d.scaleY : 0
    },
    handleUploadCropImage (isReplace) {
      this.cropDialog.uploading = true
      uploadCropFile()
        .complete(() => (this.cropDialog.uploading = false))
        .success(resp => {
          this.$emit('edit', resp.data, isReplace, this.file)
          this.cropDialog.visible = false
          this.close()
        })
        .send(this.file.id, this.cropDialog.imageBlob, isReplace)
    },
    handleCropperReady () {
      // 图片如果是跨域访问的，需要做如下设置，否则在获取裁减后的图片时出错
      // https://github.com/fengyuanchen/cropperjs/issues/258
      const canvasImg = this.$el.querySelector('img.cropper-hide')
      const src = canvasImg.getAttribute('src')
      canvasImg.setAttribute('crossorigin', 'anonymous')
      // Somehow src needs to be set again in order for crossorigin to work
      canvasImg.setAttribute('src', src)
    }
  }
}
</script>

<style lang="less" scoped>
  .file-detail {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: top 200ms, box-shadow 200ms;
    background-color: #fff;
    z-index: 200;
    overflow: hidden;
    &.visible {
      box-shadow: -4px 0 10px rgba(0,0,0,.3);
    }
  }
  .header {
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex: 1;
      text-align: center;
    }
    .close-icon {
      margin-right: 5px;
      font-size: 14px;
      cursor: pointer;
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition:         transform .2s ease-in-out;
      &:hover {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .image-container {
    flex: 1;
    position: relative;
    @toolHeight: 60px;
    .image-view {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 5px;
      bottom: @toolHeight;
      overflow: hidden;
      .image-display {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .image-tool {
        position: absolute;
        right: 10px;
        top: 4px;
        span {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 20px;
          text-align: center;
          border-radius: 5px;
          background-color: rgba(0,0,0,.5);
          color: #fff;
          cursor: pointer;
          &:hover {
            color: #bb3e30;
          }
        }
        span + span {
          margin-left: 10px;
        }
      }
    }
    .image-edit-tool {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: @toolHeight;
      line-height: @toolHeight;
      border-top: solid 1px #eee;
      text-align: center;
    }
  }
  .info-container {
    width: 500px;
    padding: 0 10px;
    border-left: solid 1px #eee;
    overflow-y: auto;
    .file-description {
      margin-top: 10px;
      .description-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
        font-size: 12px;
        label {
          width: 80px;
          font-weight: bold;
          color: #303133;
          text-align: right;
          &::after {
            content: '：';
          }
        }
        span {
          flex: 1;
          color: #606266;
        }
      }
    }
  }
  .image-preview {
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
