<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="退货编辑器"
    @save="onSave"
    @close="onClose"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="订单号" class="app_required-input" prop="orderNo">
        <a-input v-model="form.orderNo" @blur="handleOrderNoInputBlur"/>
      </a-form-model-item>
      <a-form-model-item label="备注" class="app_required-input" prop="remark">
        <a-textarea v-model="form.remark" :auto-size="{ minRows: 3 }"/>
      </a-form-model-item>
      <a-form-model-item label="状态" class="app_required-input" prop="status">
        <a-select v-model="form.status" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="物流公司" :class="{'app_required-input': form.status === statusMapping.shipped}" prop="logisticsId">
        <a-select
            v-model="form.logisticsId"
            show-search
            placeholder="输入关键字搜索物流"
            option-filter-prop="children"
            style="width: 100%;"
            :filter-option="logisticsFilterOption"
        >
          <a-select-option :value="item.id" v-for="item in logisticsOptions" :key="item.id">{{item.carrierEn}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="跟踪号" :class="{'app_required-input': form.status === statusMapping.shipped}" prop="trackNo">
        <a-input v-model="form.trackNo"/>
      </a-form-model-item>
      <a-form-model-item label="运费" prop="freightPrice">
        <a-input-number v-model="form.freightPrice" :precision="2" :min="0" style="width: 100%;"/>
        <a-alert type="info" message="运费（美元）如果是由我们这边支付的，请填写运费。"></a-alert>
      </a-form-model-item>
      <a-form-model-item label="关联退款" prop="orderRefundId">
        <a-select v-model="form.orderRefundId" style="width: 100%;" :allow-clear="true">
          <a-select-option v-for="f in orderRefunds" :key="f.id">
            {{f.amount}} {{f.currency}}
          </a-select-option>
        </a-select>
        <a-alert type="info" show-icon>
          <div slot="message">
            关联退款后，请填写下面的SKU的退款占比份额。<span class="text-primary" style="cursor:pointer;" @click="autoFillRefundToReturnSku()">点我自动分配</span>
          </div>
        </a-alert>
      </a-form-model-item>
    </a-form-model>

    <a-divider>退货物品</a-divider>

    <div class="list-group return-list">

      <div class="list-group-item list-group-item-info">
        <div class="left-right-content">
          <div class="full" style="margin-right: 10px;">
            <a-input v-model="skuInput" placeholder="请输入SKU" v-on:keyup.enter="handleGetStockProduct">
              <a-icon slot="addonAfter" type="plus" style="cursor: pointer" @click="handleGetStockProductInput" />
            </a-input>
          </div>
          <a-button @click="handleOpenDeliveryItemDialog">从发货项选择</a-button>
        </div>
      </div>
      <div class="list-group-item return-item">
        <div class="return-item-wrapper">
          <div class="col-action">操作</div>
          <div class="col-image">图片</div>
          <div class="col-sku">SKU</div>
          <div class="col-total">退货数量</div>
          <div class="col-refund" v-if="form.orderRefundId">退款金额</div>
        </div>
      </div>
      <div class="list-group-item return-item" v-for="item in skuList" :key="item.sku">
        <div class="return-item-wrapper">
          <div class="col-action">
            <a-button size="small" type="danger" icon="delete" @click="removeSkuItem(item)"></a-button>
          </div>
          <div class="col-image">
            <div class="image-item small" :style="{'background-image': `url(${item.imageUrl})`}"></div>
          </div>
          <div class="col-sku">{{item.sku}}</div>
          <div class="col-total">
            <a-input-number v-model="item.quantity" :min="1" />
          </div>
          <div class="col-refund" v-if="form.orderRefundId">
            <a-input-number v-model="item.refundAmount" :precision="2" />
          </div>
        </div>
      </div>
    </div>

    <modal
        ref="deliveryItemDialog"
        title="已发货SKU"
        :width="500"
        @ok="handleDeliveryItemDialogOk"
        @closed="handleDeliveryItemDialogClose"
    >
      <ul class="list-group delivery-item-list">
        <li class="list-group-item" v-for="item in deliveryItemDialog.orderDeliveryItems" :key="item.id">
          <div class="delivery-item">
            <a-checkbox v-model="item._checked"></a-checkbox>
            <div class="image-item" :style="{'background-image': `url(${item.imageUrl})`}"></div>
            <div class="sku">{{item.sku}}</div>
            <div class="total">{{item.total}}</div>
          </div>
        </li>
      </ul>
    </modal>

  </drawer>
</template>

<script>
import {
  ORDER_RETURN_STATUS_LIST,
  STATUS_SHIPPED
} from '@/constants/order-return-status'
import { listT17trackLogistics } from '@/http/api/t17track'
import { getStockProductBySku, add, update } from '@/http/api/order-return'
import { getOrderDeliveryItemsByOrder } from '@/http/api/order-delivery'
import { listRefundsByOrderNo } from '@/http/api/order-refund'
import { getOrderProductsByOrderNo } from '@/http/api/order'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: {
        orderNo: null,
        remark: null,
        status: null,
        logisticsId: null,
        trackNo: null,
        orderRefundId: null,
        freightPrice: 0
      },
      logisticsOptions: [],
      statusOptions: ORDER_RETURN_STATUS_LIST,
      statusMapping: {
        shipped: STATUS_SHIPPED
      },
      skuInput: null,
      skuList: [],
      deliveryItemDialog: {
        orderDeliveryItems: []
      },
      orderRefunds: [],
      orderProducts: []
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.model
    },
    orderProductPriceMap () {
      const map = {}
      for (const item of this.orderProducts) {
        if (item.sku) {
          map[item.sku] = item.discountPrice
        }
      }
      return map
    },
    selectedRefund () {
      return this.orderRefunds.find(f => f.id === this.form.orderRefundId)
    }
  },
  methods: {
    createReturnSkuItem (sku, imageUrl, quantity, refund) {
      return {
        sku: sku,
        imageUrl: imageUrl,
        quantity: quantity,
        refundAmount: refund
      }
    },
    /**
     * @param [model] {String | Object} 字符串表示订单号
     */
    open (model) {
      this.visible = true
      if (model && typeof model === 'string') {
        this.form.orderNo = model
        model = null
      }
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
        if (model.returnItems.length > 0) {
          for (const item of model.returnItems) {
            const skuItem = this.createReturnSkuItem(item.sku, item.imageUrl, item.quantity, item.refundAmount)
            this.skuList.push(skuItem)
          }
        }
      }
      if (this.logisticsOptions.length === 0) {
        listT17trackLogistics().success(resp => {
          this.logisticsOptions = resp.data
        }).send()
      }

      this.handleOrderNoInputBlur()
    },
    onClose () {
      this.$refs.form.resetFields()
      this.skuList = []
    },
    onSave () {
      const data = Object.assign({
        id: this.isUpdate ? this.model.id : null
      }, this.form)
      data.returnItems = []
      let returnSkuRefund = 0
      for (const skuItem of this.skuList) {
        returnSkuRefund += skuItem.refundAmount
        data.returnItems.push({
          sku: skuItem.sku,
          quantity: skuItem.quantity,
          refundAmount: skuItem.refundAmount
        })
      }
      if (this.selectedRefund && this.selectedRefund.amount !== returnSkuRefund) {
        this.$message.error(`退货里分配的退款金额总计和关联的退款金额不相等：${returnSkuRefund.toFixed(2)} != ${this.selectedRefund.amount}`)
        return
      }
      this.saveButtonLoading = true
      this.closable = false
      const api = this.isUpdate ? update : add
      api()
        .complete(() => {
          this.saveButtonLoading = false
          this.closable = true
        })
        .success(resp => {
          this.$emit('saved', resp.data, this.isUpdate)
          this.$nextTick(() => {
            this.visible = false
          })
        })
        .send(data)
    },
    handleOrderNoInputBlur () {
      this.handleLoadDeliveryItems()
      this.handleLoadOrderRefunds()
      this.handleLoadOrderProducts()
    },
    handleGetStockProductInput () {
      this.handleGetStockProduct(this.skuInput, 1)
    },
    handleGetStockProduct (sku, quantity) {
      if (this.skuList.find(item => item.sku === this.skuInput)) {
        return this.$message.warning('SKU已经在列表中。')
      }
      getStockProductBySku()
        .success(resp => {
          if (resp.data.length > 0) {
            const item = resp.data[0]
            const skuItem = this.createReturnSkuItem(item.sku, item.imageUrl, quantity, 0)
            this.skuList.push(skuItem)
            this.skuInput = null
          } else {
            this.$message.error('SKU不存在。')
          }
        })
        .send(this.skuInput)
    },
    removeSkuItem (item) {
      kit.arr.removeItem(this.skuList, item)
    },
    logisticsFilterOption (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    handleLoadDeliveryItems () {
      getOrderDeliveryItemsByOrder()
        .success(resp => {
          const map = {}
          const arr = []
          for (const item of resp.data) {
            if (item.sku) {
              const first = map[item.sku]
              if (first) {
                first.total += item.total
              } else {
                item._checked = false
                map[item.sku] = item
                arr.push(item)
              }
            }
          }
          this.deliveryItemDialog.orderDeliveryItems = arr
        })
        .send(this.form.orderNo)
    },
    handleOpenDeliveryItemDialog () {
      this.$refs.deliveryItemDialog.open()
    },
    handleDeliveryItemDialogOk () {
      for (const item of this.deliveryItemDialog.orderDeliveryItems) {
        if (item._checked) {
          if (this.skuList.find(a => a.sku === item.sku)) {
            continue
          }
          const skuItem = this.createReturnSkuItem(item.sku, item.imageUrl, item.total, 0)
          this.skuList.push(skuItem)
        }
      }
      this.$refs.deliveryItemDialog.close()
    },
    handleDeliveryItemDialogClose () {
      for (const item of this.deliveryItemDialog.orderDeliveryItems) {
        item._checked = false
      }
    },
    handleLoadOrderRefunds () {
      listRefundsByOrderNo().success(resp => (this.orderRefunds = resp.data)).send(this.form.orderNo)
    },
    handleLoadOrderProducts () {
      getOrderProductsByOrderNo().success(resp => (this.orderProducts = resp.data)).send(this.form.orderNo)
    },
    /**
     * 从关联的退款中自动分配退款金额到退货SKU上
     */
    autoFillRefundToReturnSku () {
      const refund = this.selectedRefund
      if (!refund) return
      let refundAmount = refund.amount
      let lastedSkuItem
      for (const skuItem of this.skuList) {
        lastedSkuItem = skuItem
        if (refundAmount <= 0) {
          skuItem.refundAmount = 0
          continue
        }
        const productPrice = (this.orderProductPriceMap[skuItem.sku] || 0) * skuItem.quantity
        if (productPrice > refundAmount) {
          skuItem.refundAmount = refundAmount
        } else {
          skuItem.refundAmount = productPrice
        }
        refundAmount -= productPrice
      }
      if (refundAmount > 0) {
        lastedSkuItem.refundAmount += refundAmount
        this.$info({
          title: '提示',
          content: `已按照产品的实付金额分配退款金额，但多出部分退款金额【${refundAmount.toFixed(2)}】，已将多余的部分累加到最后一个退款SKU。`
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.return-list {
  .return-item-wrapper {
    display: flex;
    align-items: center;
    .col-image {
      padding: 0 10px;
    }
    .col-sku {
      flex: 1;
    }
    .col-total {
      width: 100px;
      padding: 0 5px;
    }
    .col-refund {
      width: 90px;
    }
  }
}

.delivery-item-list {
  .delivery-item {
    display: flex;
    align-items: center;
    .image-item {
      margin: 0 10px;
    }
    .sku {
      flex: 1;
    }
  }
}
</style>
