<template>
  <div class="card-panel" style="background-color: #fff; margin-left: 20px;">

    <div class="header" style="background-color: #fff;">
      <div class="title">
        <div class="tab-list">
          <div class="tab-item" v-for="t in tabItems" :key="t.id" :class="{ 'current': t.id === currentTab }" @click="currentTab = t.id">{{t.title}}</div>
        </div>
      </div>
    </div>

    <div class="body">
      <info-panel v-show="currentTab === 'info'" :influencer="influencer"></info-panel>
      <cooperation-status-panel v-show="currentTab === 'cs'" :influencer="influencer"></cooperation-status-panel>
    </div>
  </div>
</template>

<script>

import InfoPanel from './base-comp/info'
import CooperationStatusPanel from './base-comp/cooperation-status'

export default {
  props: {
    influencer: { type: Object, required: false }
  },
  components: { InfoPanel, CooperationStatusPanel },
  data () {
    return {
      tabItems: [
        { id: 'info', title: '达人信息' },
        { id: 'cs', title: '合作状态' }
      ],
      currentTab: 'info'
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>

.tab-list {
  display: flex;
  .tab-item {
    padding: 0 18px;
    cursor: pointer;
    &.current {
      color: #a94442;
    }
  }
  .tab-item + .tab-item {
    border-left: solid 1px #ddd;
  }
}
</style>
