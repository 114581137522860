<template>
  <modal ref="modal" title="达人分类管理器" :hide-buttons="true" @closed="$emit('closed')">

    <loading :loading="loading"></loading>

    <div style="max-height: 450px; padding: 0; overflow-y: auto;">
      <a-alert message="右键某个分类可弹出操作菜单。" type="info" show-icon />
      <a-tree :tree-data="dataList" :replaceFields="{ key: 'id', title: 'name' }" :expandedKeys.sync="expandedKeys" :selectable="false">
        <template #title="{ id, name, parentId }">
          <a-dropdown :trigger="['contextmenu']">
            <span>{{ name }}</span>
            <template #overlay>
              <a-menu @click="({ key: menuKey }) => onContextMenuClick(id, menuKey)">
                <a-menu-item key="add1">添加顶级分类</a-menu-item>
                <a-menu-item key="add2" v-show="!parentId">添加【{{name}}】的子分类</a-menu-item>
                <a-menu-item key="update">更新【{{name}}】</a-menu-item>
                <a-menu-item key="del">删除【{{name}}】</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-tree>
    </div>


    <modal ref="editor" title="分类编辑" @closed="onEditorClosed" @ok="handleUpdate" :loading="loading">
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="上级分类" class="app_required-input">
          <a-select v-model="editor.form.parentId" :allow-clear="true" :disabled="editor.disableParentSelect">
            <a-select-option v-for="item in dataList" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分类名称" class="app_required-input">
          <a-input v-model="editor.form.name"/>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </modal>
</template>

<script>
import { listAll, add, update, deleteById } from '@/http/api/influencer-category'

export default {
  data () {
    return {
      loading: false,
      editor: {
        form: {
          name: null,
          parentId: null
        },
        current: null,
        disableParentSelect: false
      },
      dataList: [],
      expandedKeys: []
    }
  },
  computed: {
    dataMap () {
      const m = {}
      for (const p of this.dataList) {
        m[p.id] = p
        if (p.children) {
          for (const c of p.children) {
            m[c.id] = c
          }
        }
      }
      return m
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
      this.loadData()
    },
    loadData () {
      listAll().success(resp => (this.dataList = resp.data)).send()
    },
    handleUpdate () {
      const data = Object.assign({}, this.editor.form)
      let isAdd = true
      if (this.editor.current) {
        data.id = this.editor.current.id
        isAdd = false
      }
      this.loading = true
      const api = isAdd ? add : update
      api()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.loadData()
          this.$emit('updated')
          this.$nextTick(() => (this.$refs.editor.close()))
        })
        .send(data)
    },
    handleDelete (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.name}】吗？`,
        onOk: () => {
          this.loading = true
          deleteById()
            .complete(() => (this.loading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadData()
              this.$emit('updated')
            })
            .send(item.id)
        }
      })
    },
    onContextMenuClick (id, menuKey) {
      switch (menuKey) {
        case 'add1':
          this.openEditor(null, null)
          break
        case 'add2':
          this.openEditor(null, this.dataMap[id])
          break
        case 'update':
          this.openEditor(this.dataMap[id], null)
          break
        case 'del':
          this.handleDelete(this.dataMap[id])
          break
      }
    },
    openEditor (data, parent) {
      if (data) {
        this.editor.current = data
        this.editor.form.name = data.name
        this.editor.form.parentId = data.parentId
        if (data.parentId) {
          this.editor.parent = this.dataMap[data.parentId] || null
        } else {
          this.editor.disableParentSelect = true
        }
      } else if (parent) {
        this.editor.form.parentId = parent.id
      }
      this.$refs.editor.open()
    },
    onEditorClosed () {
      this.editor.form.name = null
      this.editor.form.parentId = null
      this.editor.current = null
      this.editor.parentName = null
      this.editor.disableParentSelect = false
    }
  }
}
</script>

<style lang="less" scoped>

.list-group-item:hover {
  background-color: #f2f2f2;
}

.list-group-item .buttons {
  visibility: hidden;
}
.list-group-item:hover .buttons {
  visibility: visible;
}

.item-wrapper {
  display: flex;
  align-items: center;
  .name {
    flex: 1;
    padding-right: 15px;
  }
  .color {
    width: 60px;
  }
  .buttons {
    width: 180px;
  }
}

.sub-list {
  margin-left: 50px;
  .add-tool {
    margin-top: 10px;
  }
}
</style>
