<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" @click="$refs.editor.open()">添加</a-button>

    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="actions" slot-scope="row">
        <a-button size="small" @click="$refs.editor.open(row)">编辑</a-button>
      </div>
    </a-table>

    <partner-editor ref="editor" @saved="loadData()"></partner-editor>
  </app-page>
</template>

<script>
import { ROUTE_PARTNER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { list } from '@/http/api/partner'
import PartnerEditor from '@/pages/partner/comp/partner-editor'

export default {
  components: { PartnerEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_PARTNER,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'actions' }, width: 100 },
        { title: '名称', dataIndex: 'name' },
        { title: '关联部门', dataIndex: 'deptName' }
      ]
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send()
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
