<template>
  <transition name="fade" :duration="100" v-on:after-enter="handleOpenAnimateCompleted">
    <div class="file-manager-overlay" v-show="visible" :style="{'z-index': zIndex}">
      <div class="file-manager">
        <file-upload ref="fileUpload" @uploaded="handleFileUploadSuccess"></file-upload>
        <div class="header">
          <a-icon type="close" class="close-icon" @click="close" />
        </div>
        <div class="body">
          <folder
              ref="folder"
              class="folder"
              @folderSelected="handleFolderSelected"
          >
          </folder>
          <files
              ref="files"
              class="files"
              @uploadClick="handleUploadClick"
              @editClick="handleFileEditClick"
              @selected="handleFilesSelected"
              :select-mode="selectFileMode"
              :selected-folder="selectedFolder"
              :default-selected-file-ids.sync="defaultSelectedFileIds"
          >
          </files>
        </div>
        <file-detail ref="fileDetail" @edit="handleImageEdit"></file-detail>
      </div>
    </div>
  </transition>
</template>

<script>
import Folder from './folder'
import Files from './files'
import FileUpload from './file-upload'
import FileDetail from './file-detail'
import store from '@/store'
import selectFiles from '@/utils/select-file'
import { FILE_MANAGER_Z_INDEX } from '@/components-business/file-manager/index'

export default {
  components: {
    Folder, Files, FileUpload, FileDetail
  },
  data () {
    return {
      visible: false,
      zIndex: FILE_MANAGER_Z_INDEX,
      /**
       * {
       *   folder: {},
       *   allChildrenIds: [String]
       * }
       */
      selectedFolder: null,
      selectFilesCallback: null,
      selectFileMode: 'none',
      defaultSelectedFileIds: [],
      unCheckFileOnClose: false // 关闭对话框时，是否将选中的文件设置为未选中
    }
  },
  watch: {
    visible () {
      if (this.visible) {
        document.addEventListener('keydown', this.keydown)
      } else {
        document.removeEventListener('keydown', this.keydown)
        this.selectFilesCallback = null
      }
    },
    currentRoutePath () {
      if (this.visible) {
        this.visible = false
        this.$refs.folder.reset()
        this.$refs.files.reset()
      }
    }
  },
  computed: {
    currentRoutePath () {
      return store.state.currentRoutePath
    }
  },
  methods: {
    open () {
      this.selectFileMode = 'none'
      this.visible = true
    },
    /**
     * 以单选模式打开文件对话框。
     * @param selectFilesCallback {function} 选择文件后，点击确认的回调函数，files是已选择的文件数组。
     * @param [defaultSelectedFileIds] {Array} 打开后，默认选中的文件。
     */
    openForSingleSelect (selectFilesCallback, defaultSelectedFileIds) {
      this.selectFileMode = 'single'
      this._openForSelect(selectFilesCallback, defaultSelectedFileIds)
    },
    /**
     * 以多选模式打开文件对话框。
     * @param selectFilesCallback {function} 选择文件后，点击确认的回调函数，files是已选择的文件数组。
     * @param [defaultSelectedFileIds] {Array} 打开后，默认选中的文件。
     */
    openForMultiSelect (selectFilesCallback, defaultSelectedFileIds) {
      this.selectFileMode = 'multi'
      this._openForSelect(selectFilesCallback, defaultSelectedFileIds)
    },
    _openForSelect (selectFilesCallback, defaultSelectedFileIds) {
      this.selectFilesCallback = selectFilesCallback
      this.defaultSelectedFileIds = defaultSelectedFileIds || []
      this.visible = true
      this.unCheckFileOnClose = this.defaultSelectedFileIds.length > 0
      this.$nextTick(() => {
        this.$refs.files.onDialogOpen()
      })
    },
    close () {
      this.visible = false
      this.selectFilesCallback = null
      this.defaultSelectedFileIds = []
      this.selectFileMode = 'none'
      if (this.unCheckFileOnClose) {
        this.$refs.files.reset()
      }
      this.unCheckFileOnClose = false
    },
    keydown (e) {
      if (e.keyCode === 27) { // esc关闭消息
        if (this.visible) {
          this.close()
        }
      }
    },
    handleOpenAnimateCompleted () {
      if (this.visible) {
        this.$refs.folder.loadOnDialogOpen()
        this.$refs.files.loadOnDialogOpen()
      }
    },
    handleUploadClick () {
      selectFiles({
        select: (files, errors) => {
          for (const f of files) {
            f.params = {
              folderId: this.selectedFolder.folder.id
            }
          }
          this.$refs.fileUpload.upload(files)
        }
      })
    },
    handleFileEditClick (file) {
      this.$refs.fileDetail.open(file)
    },
    handleFilesSelected (files) {
      this.selectFilesCallback(files)
      this.$refs.files.reset()
      this.close()
    },
    handleFolderSelected (folderData) {
      this.selectedFolder = folderData
    },
    handleFileUploadSuccess (file) {
      this.$refs.files.insertFiles(file)
    },
    handleImageEdit (file, isReplace, originFile) {
      if (isReplace) {
        Object.assign(originFile, file)
        file = originFile
      } else {
        this.$refs.files.insertFiles(file)
      }
      setTimeout(() => {
        this.$refs.fileDetail.open(file)
      }, 500)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  @borderColor: #e2e2e2;
  .file-manager-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
  }
  .file-manager {
    @padding: 10px;
    position: absolute;
    left: @padding;
    top: @padding;
    right: @padding;
    bottom: @padding;
    box-shadow: 0 0 5px rgba(0,0,0,.8);
    border-radius: 3px;
    background-color: #fff;
  }
  .header {
    padding: 5px 10px;
    text-align: right;
    .close-icon {
      margin-right: 5px;
      font-size: 14px;
      cursor: pointer;
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition:         transform .2s ease-in-out;
      &:hover {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .body {
    position: absolute;
    top: 35px;
    left: 0;
    bottom: 0;
    right: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    .folder {
      position: relative;
      width: 300px;
      border-right: solid 1px @borderColor;
      overflow: hidden;
    }
    .files {
      position: relative;
      flex: 1;
      overflow: hidden;
    }
  }
  .footer {
  }
</style>
