import defineApi from '../define-api'
import http from '../application-api'


export const listByInfluencerId = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/influencer_communication/list/by_influencer_id', config)
})

export const add = defineApi((config, type, influencerId, content) => {
  config.params = {
    type: type,
    influencerId: influencerId,
    content: content
  }
  http.post('/influencer_communication/add', config)
})

export const updateEnabled = defineApi((config, id, enabled) => {
  config.params = {
    id: id,
    enabled: enabled
  }
  http.post('/influencer_communication/update/enabled', config)
})

export const completeNotify = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/influencer_communication/notify/complete', config)
})
