<!--
  售后分析
-->
<template>
  <div class="tag-container">
    <div class="tag-total" :class="'level-' + totalLevel(t.total)" v-for="t in tagTotals" :key="t.name">
      {{t.name}} <span class="total">{{t.total}}</span>
    </div>
  </div>
</template>

<script>

import { getTagTotals } from '@/http/api/after-sale-session'

export default {
  data () {
    return {
      tagTotals: []
    }
  },
  filters: {

  },
  methods: {
    totalLevel (t) {
      if (t > 80) {
        return 5
      } else if (t > 60) {
        return 4
      } else if (t > 40) {
        return 3
      } else if (t > 20) {
        return 2
      } else if (t > 10) {
        return 1
      } else {
        return 0
      }
    },
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
    },
    onInactive () {
    },
    onInit () {
      this.handleLoadData()
    },
    handleLoadData () {
      getTagTotals().success(resp => (this.tagTotals = resp.data)).send()
    }
  }
}
</script>

<style lang="less" scoped>
.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag-total {
    margin: 8px;
    padding: 8px 15px;
    border-radius: 4px;
    border: solid 1px #eee;
    font-size: 12px;
    &.level-5 {
      font-size: 30px;
      color: #c33632;
      border-color: #c33632;
    }
    &.level-4 {
      font-size: 26px;
      color: #b65213;
      border-color: #b65213;
    }
    &.level-3 {
      font-size: 22px;
      color: #1b53bb;
      border-color: #1b53bb;
    }
    &.level-2 {
      font-size: 18px;
      color: #0e7307;
      border-color: #0e7307;
    }
    &.level-1 {
      font-size: 14px;
      color: #35bd37;
      border-color: #35bd37;
    }
  }
}
</style>
