<template>
  <app-page :route-name="routeName" :page-loading="pageLoading">

    <div slot="top">
      <a-dropdown>
        <a-menu slot="overlay" @click="handleSelectFile">
          <a-menu-item v-for="s in shops" :key="s.id">{{s.name}}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 请上传文件（CSV / Excel） <a-icon type="down" /> </a-button>
      </a-dropdown>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableWidth }"
    >
      <div slot="customer" slot-scope="v, item">
        {{item.receiverLastName}} {{item.receiverFirstName}}
      </div>

      <div slot="image" slot-scope="v, r">
        <div class="image-list" v-if="r.products.length > 0">
          <div class="image-wrapper"
               v-for="(pItem,index) in limitArray(r.products, 4)"
               :key="index"
          >
            <div class="image-item small" v-lazy:background-image="pItem.img" @click="$imageModal.open(getImageArray(r), index)"></div>
          </div>
          <div class="image-total" v-if="r.products.length > 4">{{r.products.length}}</div>
        </div>
      </div>

      <div slot="status" slot-scope="v, r">
        <a-tooltip v-if="!r.parseSuccess">
          <template slot="title">{{r.parseError}}</template>
          <status :status="false" disabled-text="错误"></status>
        </a-tooltip>
        <status v-else :status="true" enabled-text="正确"></status>
      </div>

      <div slot="price" slot-scope="v, item">
        <div style="font-weight: bold; font-size: 13px; color: #2568d9">{{item.pricePay}} {{item.currency}}</div>
      </div>

      <div slot="address" slot-scope="v, item">
        <span v-if="item.receiverProvince">{{item.receiverProvince}},</span> {{item.receiverCity}}, {{item.receiverAddress1}} {{item.receiverAddress2}}
      </div>
    </a-table>

    <div slot="bottom" style="text-align: center; padding: 10px;">
      <div style="margin-bottom: 10px;" class="text-primary font-bold font-mini">
        <span v-show="submitted">已经导入 {{successOrders.length}} 个订单。</span>
        <span v-show="!submitted">总共发现 {{dataList.length}} 个订单，成功解析 {{successOrders.length}} 个订单。</span>
      </div>
      <div>
        <a-button type="primary" v-show="successOrders.length > 0 && !submitted" @click="handleSubmitOrders">
          导入 {{successOrders.length}} 个订单到店铺 【{{selectShopName}}】
        </a-button>
        <a-button type="primary" v-show="submitted" @click="handleDelivery">为 {{successOrders.length}} 个订单发货</a-button>
      </div>
    </div>

    <multi-delivery-modal ref="multiDelivery"></multi-delivery-modal>

  </app-page>
</template>

<script>
import { ROUTE_ORDER_IMPORT } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { getImportableShops, importOrderPreProcess, importOrderSubmit } from '@/http/api/order'
import selectFile from '@/utils/select-file'
import kit from '@/utils/kit'
import MultiDeliveryModal from '@/pages/delivery/comp/multi-delivery'

export default {
  mixins: [AntdTableHeightMixin],
  components: { MultiDeliveryModal },
  data () {
    return {
      routeName: ROUTE_ORDER_IMPORT,
      pageLoading: false,
      dataColumns: [
        { title: '平台单号', width: 160, dataIndex: 'originOrderNo', fixed: 'left' },
        { title: '状态', width: 70, dataIndex: 'parseSuccess', scopedSlots: { customRender: 'status' } },
        { title: '订单号', width: 160, dataIndex: 'orderNo' },
        { title: '图片', width: 130, scopedSlots: { customRender: 'image' } },
        { title: '客户', width: 140, dataIndex: 'customer', scopedSlots: { customRender: 'customer' } },
        { title: '金额', width: 100, dataIndex: 'price', scopedSlots: { customRender: 'price' } },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '备注', width: 200, dataIndex: 'remark' },
        { title: '日期', width: 100, dataIndex: 'createTime' },
        { title: '国家', width: 100, dataIndex: 'receiverCountry' },
        { title: '地址', width: 150, scopedSlots: { customRender: 'address' } }
      ],
      dataList: [],
      selectedRowKeys: [],
      shops: [],
      // 选择订单文件时，同时选择店铺
      selectShopId: null,
      // 是否已经导入文件里的订单
      submitted: false
    }
  },
  computed: {
    tableWidth () {
      let w = 0
      for (const col of this.dataColumns) {
        w += col.width
      }
      return w
    },
    successOrders () {
      const arr = []
      for (const item of this.dataList) {
        if (item.parseSuccess) {
          arr.push(item)
        }
      }
      return arr
    },
    selectShopName () {
      const s = this.shops.find(item => item.id === this.selectShopId)
      return s == null ? '' : s.name
    }
  },
  methods: {
    onRowSelectChanged (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSelectFile (e) {
      const shopId = e.key
      selectFile({
        accept: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multi: false,
        select: (files, e) => {
          const f = files[0]
          this.pageLoading = true
          importOrderPreProcess()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              this.selectShopId = shopId
              this.submitted = false
              this.dataList = resp.data
            })
            .send(shopId, f)
        }
      })
    },
    getImageArray (record) {
      const arr = []
      for (const p of record.products) {
        const item = {
          url: p.img,
          infos: [
            { name: '订单号', value: record.orderNo },
            { name: 'SKU', value: p.sku },
            { name: '数量', value: p.total }
          ],
          title: p.title
        }
        if (record.remark) {
          item.infos.push({ name: '客户备注', value: record.remark, type: 'remark' })
        }
        arr.push(item)
      }
      return arr
    },
    limitArray (arr, limit) {
      if (arr.length > limit) {
        return arr.slice(0, limit)
      } else {
        return arr
      }
    },
    handleSubmitOrders () {
      this.$confirm({
        title: '确认',
        content: `确定要导入订单到【${this.selectShopName}】吗？`,
        onOk: () => {
          this.pageLoading = true
          importOrderSubmit()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              this.submitted = true
              this.dataList = resp.data
              this.$message.success('订单导入成功。')
            })
            .send(this.successOrders)
        }
      })
    },
    handleDelivery () {
      const orderNoList = []
      for (const order of this.dataList) {
        orderNoList.push(order.orderNo)
      }
      this.$refs.multiDelivery.open(orderNoList)
    }
  },
  mounted () {
    getImportableShops().success(resp => (this.shops = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
.image-list {
  position: relative;
  width: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  .image-wrapper.stock-in .image-item {
    border-color: #b501ff;
    border-width: 2px;
  }
  .image-total {
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -8px;
    color: #fff;
    background-color: #4586d4;
    padding: 2px 8px;
    border-radius: 20px;
  }
}
</style>
