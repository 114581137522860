import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class MessageBar extends Section {
  constructor () {
    super('messageBar', '消息栏')

    this.message = null
  }

  getHeaderDisplay () {
    return this.message
  }
}
