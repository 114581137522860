<template>
  <div>
    <div v-if="address" class="info-detail">
      <div class="detail-item">
        <label>标题</label>
        <span>{{address.title}}</span>
      </div>
      <div class="detail-item">
        <label>姓名</label>
        <span>{{address.name}}</span>
      </div>
      <div class="detail-item">
        <label>电话</label>
        <span>{{address.phone}}</span>
      </div>
      <div class="detail-item">
        <label>国家</label>
        <span>{{address.country}}</span>
      </div>
      <div class="detail-item">
        <label>省份</label>
        <span>{{address.state}}</span>
      </div>
      <div class="detail-item">
        <label>城市</label>
        <span>{{address.city}}</span>
      </div>
      <div class="detail-item">
        <label>地址1</label>
        <span>{{address.addr1}}</span>
      </div>
      <div class="detail-item">
        <label>地址2</label>
        <span>{{address.addr2}}</span>
      </div>
      <div class="detail-item">
        <label>邮政编码</label>
        <span>{{address.postal}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addressJson: { type: String }
  },
  computed: {
    address () {
      if (this.addressJson) {
        try {
          return JSON.parse(this.addressJson)
        } catch (e) {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
