import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Button extends Section {
  constructor () {
    super('button', '按钮')
    this.title = 'Button'
    this.fontSize = 14
    this.padding = '8px 20px'
    this.color = '#ffffff'
    this.backgroundColor = '#000000'
    this.borderColor = '#000000'
    // 圆角半径
    this.radius = 0
    this.url = null
  }

  getHeaderDisplay () {
    return this.title
  }
}
