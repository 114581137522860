<template>
  <div class="image-picker">
    <h4 class="content-center" v-if="title">{{title}}</h4>
    <div class="image-upload"
         :style="{'background-image': `url(${imageUrl})`, 'height': height, 'line-height': height, 'width': width}"
         @click="uploadImage(true)">
      <span v-show="!imageUrl" class="text-muted">上传</span>
      <loading :loading="loading"></loading>
      <div v-show="imageUrl" class="remove" @click.stop.prevent="$emit('update:imageUrl', null)"><a-icon type="close" /></div>
      <div class="edit" @click.stop.prevent="handleEditUrl">
        <a-icon type="edit" />
      </div>
    </div>

    <modal ref="urlEditor" title="图片URL" @ok="handleUrlConfirm">
      <a-input v-model="urlEditor.url" placeholder="http://example.com/1.jpg" />
    </modal>
  </div>
</template>

<script>
import selectFiles from '@/utils/select-file'
import { uploadFile } from '@/http/api/shopify-product'

export default {
  props: {
    imageUrl: { type: String },
    title: { type: String },
    height: { type: String, default: '200px' },
    width: { type: String, default: '100%' }
  },
  data () {
    return {
      loading: false,
      urlEditor: {
        url: null
      }
    }
  },
  methods: {
    uploadImage () {
      selectFiles({
        accept: ['image/jpg', 'image/jpeg', 'image/png'],
        multi: false,
        select: (files) => {
          this.loading = true
          uploadFile()
            .complete(() => {
              this.loading = false
            })
            .success(resp => {
              this.$emit('update:imageUrl', resp.data)
            })
            .send(files[0])
        }
      })
    },
    handleEditUrl () {
      this.urlEditor.url = this.imageUrl
      this.$refs.urlEditor.open()
    },
    handleUrlConfirm () {
      this.$emit('update:imageUrl', this.urlEditor.url)
      this.$refs.urlEditor.close()
    }
  }
}
</script>

<style lang="less" scoped>
.image-upload {
  position: relative;
  font-size: 12px;
  text-align: center;
  border: solid 1px #b4b4b4;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  .remove, .edit {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    background-color: #bb3e30;
    cursor: pointer;
    &:hover {
      background-color: #992214;
    }
  }
  .edit {
    background-color: #fff;
    color: #000;
    right: 28px;
    &:hover {
      background-color: #f9f9f9;
    }
  }
}
</style>
