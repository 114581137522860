<template>
  <div class="communication card-panel">

    <div class="header" style="background-color: #fff;">
      <span class="title">沟通记录</span>
      <span class="tools">
        <a-button size="small" icon="reload" @click="loadInfluencerCommunication()"></a-button>
        <a-button size="small" icon="search" @click="filterPanelVisible = true"></a-button>
      </span>
    </div>


    <div class="body" id="communication-list-container">
      <loading :loading="loading" text="正在加载记录..."></loading>

      <div class="communication-list">
        <a-empty v-show="communications.length === 0 && !loading" style="margin-top: 50px;"/>

        <div class="communication-item" v-for="c in communicationsFilter" :key="c.id">

          <div class="user-time">
            <span>{{c.adminName}} - {{c.createTime}}</span>
            <a-switch style="margin-left: 20px;"
                      class="enabled-switch"
                      size="small"
                      v-model="c.enabled"
                      checked-children="正常" un-checked-children="作废"
                      @change="handleCommunicationEnabledChanged(c)"
            />
          </div>

          <div v-show="!c.enabled" class="font-mini" style="margin-top: 8px; margin-left: 12px;">** 内容已经作废。</div>

          <div class="content" :class="c.type" v-show="c.enabled">
            <div class="type-tag">{{typeTextMapping[c.type]}}</div>
            <div v-if="c.type === typeMapping.remark" v-html="c.content"></div>
            <div v-if="c.type === typeMapping.image" class="image-item large-2x" v-lazy:background-image="c.imageUrl" @click="$imageModal.open(c.imageUrl)"></div>
            <address-display v-if="c.type === typeMapping.address" :address-json="c.content"></address-display>
            <demo-order-display v-if="c.type === typeMapping.demoOrder" :order-json="c.content"></demo-order-display>
            <notify-display v-if="c.type === typeMapping.notify" :communication-id="c.id" :notify-json="c.content"></notify-display>
          </div>
        </div>

      </div>
    </div>

    <div class="foot">
      <span class="text-muted font-mini">操作：</span>

      <a-button :disabled="true" title="功能未开发">写邮件</a-button>
      <a-button @click="handleOpenEditor(typeMapping.remark)">备注</a-button>
      <a-button @click="handleOpenEditor(typeMapping.image)">图片</a-button>
      <a-button @click="handleOpenEditor(typeMapping.address)">地址</a-button>
      <a-button @click="handleOpenEditor(typeMapping.demoOrder)">寄样</a-button>
      <a-button @click="handleOpenEditor(typeMapping.notify)">提醒</a-button>
    </div>

    <div class="filter-panel" v-show="filterPanelVisible">
      <a-input placeholder="关键字" v-model="filterKeyword"></a-input>
      <div style="margin-top: 10px;">
        类型：
        <a-checkbox v-for="t in filterTypeOptions" :key="t.id" v-model="t.checked">{{t.text}} <span v-show="filterTypeTotalMap[t.id] > 0">({{filterTypeTotalMap[t.id]}})</span></a-checkbox>
      </div>
      <div style="margin-top: 10px;" v-if="filterNameOptions.length > 1">
        用户：
        <a-checkbox v-for="t in filterNameOptions" :key="t.name" v-model="t.checked">{{t.name}} <span v-show="t.total > 0">({{t.total}})</span></a-checkbox>
      </div>

      <div style="text-align: center; padding: 10px 0;">
        <a-button @click="handleFilter()">查询</a-button>
        <a-button @click="filterPanelVisible = false">取消</a-button>
      </div>
    </div>

    <remark-editor ref="remark" :influencer-id="influencerId" @saved="onCommunicationEditorSaved"></remark-editor>
    <image-editor ref="image" :influencer-id="influencerId" @saved="onCommunicationEditorSaved"></image-editor>
    <address-editor ref="address" :influencer-id="influencerId" @saved="onCommunicationEditorSaved"></address-editor>
    <demo-order-editor ref="demoOrder" :influencer-id="influencerId" :influencer="influencer" :address-options="addressList" @saved="onCommunicationEditorSaved"></demo-order-editor>
    <notify-editor ref="notify" :influencer-id="influencerId" @saved="onCommunicationEditorSaved"></notify-editor>
  </div>
</template>

<script>
import { listByInfluencerId, updateEnabled } from '@/http/api/influencer-communication'
import {
  COMMUNICATION_TYPE_MAPPING,
  TYPE_REMARK,
  TYPE_IMAGE,
  TYPE_ADDRESS,
  TYPE_DEMO_ORDER,
  TYPE_NOTIFY
} from '@/constants/communication-type'
import RemarkEditor from './comm-comp/remark'
import ImageEditor from './comm-comp/image'
import AddressEditor from './comm-comp/address'
import DemoOrderEditor from './comm-comp/demo-order'
import NotifyEditor from './comm-comp/notify-me'

import AddressDisplay from './comm-comp/display/address'
import DemoOrderDisplay from './comm-comp/display/demo-order'
import NotifyDisplay from './comm-comp/display/notify-me'

export default {
  components: {
    RemarkEditor,
    ImageEditor,
    AddressEditor,
    DemoOrderEditor,
    NotifyEditor,
    AddressDisplay,
    DemoOrderDisplay,
    NotifyDisplay
  },
  props: {
    influencer: { type: Object }
  },
  data () {
    return {
      loading: false,
      communications: [],
      communicationsFilter: [],
      typeTextMapping: COMMUNICATION_TYPE_MAPPING,
      typeMapping: {
        remark: TYPE_REMARK,
        image: TYPE_IMAGE,
        address: TYPE_ADDRESS,
        demoOrder: TYPE_DEMO_ORDER,
        notify: TYPE_NOTIFY
      },
      filterKeyword: null,
      filterTypeOptions: [
        { id: TYPE_REMARK, text: COMMUNICATION_TYPE_MAPPING[TYPE_REMARK], checked: false },
        { id: TYPE_IMAGE, text: COMMUNICATION_TYPE_MAPPING[TYPE_IMAGE], checked: false },
        { id: TYPE_ADDRESS, text: COMMUNICATION_TYPE_MAPPING[TYPE_ADDRESS], checked: false },
        { id: TYPE_DEMO_ORDER, text: COMMUNICATION_TYPE_MAPPING[TYPE_DEMO_ORDER], checked: false }
      ],
      // 将沟通记录里的用户名提取出来，方便用户过滤查询
      filterNameOptions: [],
      filterPanelVisible: false
    }
  },
  watch: {
    influencer () {
      this.loadInfluencerCommunication()
    }
  },
  computed: {
    influencerId () {
      return this.influencer ? this.influencer.id : null
    },
    filterTypeTotalMap () {
      const map = {}
      for (const item of this.communications) {
        const t = map[item.type] || 0
        map[item.type] = t + 1
      }
      return map
    },
    // 将沟通内容里的地址信息提取
    addressList () {
      const arr = []
      for (const c of this.communications) {
        if (c.type === TYPE_ADDRESS) {
          try {
            arr.push(JSON.parse(c.content))
          } catch (E) {}
        }
      }
      return arr
    }
  },
  methods: {
    loadInfluencerCommunication () {
      if (this.influencer) {
        this.loading = true
        listByInfluencerId()
          .complete(() => (this.loading = false))
          .success(resp => {
            this.communications = resp.data
            this.communicationsFilter = this.communications
            this.scrollDown()

            const tmp = {}
            for (const c of this.communications) {
              if (c.adminName) {
                let item = tmp[c.adminName]
                if (!item) {
                  item = { name: c.adminName, checked: false, total: 0 }
                  tmp[c.adminName] = item
                }
                item.total++
              }
            }
            this.filterNameOptions = Object.values(tmp)
          })
          .send(this.influencer.id)
      }
    },
    handleFilter () {
      const arr = []

      const typeOptions = {}
      for (const opt of this.filterTypeOptions) {
        if (opt.checked) typeOptions[opt.id] = true
      }
      const typeOptionCheckedLength = Object.keys(typeOptions).length

      const nameOptions = {}
      for (const opt of this.filterNameOptions) {
        if (opt.checked) nameOptions[opt.name] = true
      }
      const nameOptionCheckedLength = Object.keys(nameOptions).length

      for (const c of this.communications) {
        let i = 0
        if (!this.filterKeyword || c.content.includes(this.filterKeyword)) {
          i++
        }
        if (typeOptionCheckedLength === 0 || typeOptions[c.type]) {
          i++
        }
        if (nameOptionCheckedLength === 0 || nameOptions[c.adminName]) {
          i++
        }
        if (i === 3) {
          arr.push(c)
        }
      }
      this.communicationsFilter = arr
      this.filterPanelVisible = false
    },
    handleOpenEditor (type) {
      this.$refs[type].open()
    },
    onCommunicationEditorSaved () {
      this.loadInfluencerCommunication()
    },
    scrollDown () {
      this.$nextTick(() => {
        const objDiv = document.getElementById('communication-list-container')
        objDiv.scrollTop = objDiv.scrollHeight
      })
    },
    handleCommunicationEnabledChanged (c) {
      updateEnabled().send(c.id, c.enabled)
    }
  }
}
</script>

<style lang="less" scoped>
.communication {
  position: relative;
  background-color: #fff;

  .filter-panel {
    padding: 10px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-bottom: solid 1px #eee;
  }

  .body {
    position: relative;
    overflow-y: auto;
  }
  .foot {
    padding: 10px;
    text-align: center;
    border-top: solid 1px #eee;
  }

  .communication-list {

    .communication-item {
      padding: 20px 30px;

      .user-time {
        font-size: 12px;
        color: #606266;
      }

      .enabled-switch {
        display: none;
      }

      &:hover .enabled-switch {
        display: inline-block;
      }

      .content {
        position: relative;
        overflow: visible;
        display: inline-block;
        width: auto;
        margin-top: 10px;
        margin-left: 20px;
        padding: 10px;
        border-radius: 5px;
        background-color: #f9f9f9;
        border: solid 1px #ddd;

        .type-tag {
          position: absolute;
          left: -24px;
          top: -8px;
          padding: 2px 8px;
          background-color: #000000;
          color: #fff;
          font-size: 12px;
          border-radius: 15px;
        }

        &.remark .type-tag {
          background-color: #5b5b5b;
        }
        &.image .type-tag {
          background-color: #038ea8;
        }
        &.address .type-tag {
          background-color: #e75701;
        }
        &.demoOrder .type-tag {
          background-color: #8c00dc;
        }
        &.notify .type-tag {
          background-color: #588f31;
        }
      }
    }
  }
}
</style>
