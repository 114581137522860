<template>
  <app-page :page-title="pageTitle" :page-loading="pageLoading" :route-name="routeName" :use-tcb-layout="false">
    <div class="tab-page">
      <div class="tab-head">
        <div class="head-item"
             v-for="t in tabs"
             :key="t.id"
             :class="{ 'active': t.id === selectedTabId }"
             @click="handleActiveTab(t.id)"
        >
          {{t.title}}
          <a-icon style="margin-left: 10px;" type="sync" v-if="t.reload" @click="reloadTab(t.id)" />
        </div>
      </div>
      <div class="tab-body">
        <tab-order ref="order" :order="order" v-show="selectedTabId === 'order'"></tab-order>
        <tab-supplier-produce-items ref="supplier_order" :order-id="orderId" v-show="selectedTabId === 'supplier_order'"></tab-supplier-produce-items>
        <tab-delivery ref="delivery" :order="order" v-show="selectedTabId === 'delivery'"></tab-delivery>
        <tab-return ref="return" :order="order" v-show="selectedTabId === 'return'"></tab-return>
        <tab-after-sale ref="afterSale" :order="order" v-show="selectedTabId === 'afterSale'"></tab-after-sale>
        <tab-profit v-if="$authorityCheck.check($authority.orderProfit)" ref="profit" :order="order" v-show="selectedTabId === 'profit'"></tab-profit>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_DETAIL, ROUTE_ORDER_DEMO_DETAIL } from '@/router/router-constants'
import { getOrderDetail } from '@/http/api/order'
import TabOrder from './order'
import TabSupplierProduceItems from './produce-items'
import TabDelivery from './delivery'
import TabReturn from './return'
import TabProfit from './profit'
import TabAfterSale from './after-sale'

export default {
  components: { TabOrder, TabSupplierProduceItems, TabDelivery, TabReturn, TabProfit, TabAfterSale },
  data () {
    const tabs = [
      { id: 'order', title: '订单详情', firstActive: true }
    ]
    if (this.$authorityCheck.check(this.$authority.orderManage)) {
      tabs.push({ id: 'supplier_order', title: '工厂下单', firstActive: true })
      tabs.push({ id: 'delivery', title: '发货', firstActive: true })
      tabs.push({ id: 'return', title: '退货', firstActive: true })
      tabs.push({ id: 'afterSale', title: '售后', firstActive: true })
    }
    if (this.$authorityCheck.check(this.$authority.orderProfit)) {
      tabs.push({ id: 'profit', title: '利润分析', firstActive: true })
    }
    return {
      routeName: this.$route.name === 'order-detail' ? ROUTE_ORDER_DETAIL : ROUTE_ORDER_DEMO_DETAIL,
      tabs: tabs,
      pageLoading: false,
      selectedTabId: null,
      order: null
    }
  },
  watch: {
    selectedTabId (id, old) {
      if (old) {
        this.$refs[old].onInactive()
      }
      const tab = this.tabs.find(item => item.id === id)
      this.$refs[id].onActive(tab.firstActive)
      tab.firstActive = false
    }
  },
  computed: {
    orderId () {
      return this.$route.params.orderId
    },
    pageTitle () {
      return this.order ? this.order.orderNo : null
    }
  },
  methods: {
    handleActiveTab (id) {
      this.selectedTabId = id
    },
    reloadTab (id) {
      this.$refs[id].reload()
    }
  },
  mounted () {
    this.pageLoading = true
    getOrderDetail()
      .complete(() => (this.pageLoading = false))
      .success(resp => {
        this.order = resp.data
        this.$nextTick(() => {
          this.handleActiveTab('order')
        })
      })
      .send(this.orderId)
  }
}
</script>

<style lang="less" scoped>
.tab-page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .tab-head {
    display: flex;
    padding: 0 20px;
    height: 50px;
    border-bottom: solid 1px #ddd;
    .head-item {
      margin: 15px 5px 0 5px;
      padding: 8px 25px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #fff;
      border: solid 1px #ddd;
      border-bottom: none;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: #4586d4;
        color: #fff;
        border-color: #3d71a7;
      }
    }
  }
  .tab-body {
    position: relative;
    flex: 1;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
  }
}
</style>
