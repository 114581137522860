<!-- 某个款号下的SKU的销售数据 -->
<template>
  <transition name="slideUp">
    <div class="modal" v-show="visible" style="animation-duration: 0.2s">
      <div class="modal-head left-right-content" v-if="productStyle">
        <div>款号：{{productStyle.styleNumber}}</div>
        <div class="full font-mini">
          总销售额：
          <span class="font-bold font-large text-danger" style="margin-right: 20px;">{{productStyle.allSaleAmount | price}}</span>
          总销售量：
          <span class="font-bold font-large text-main">{{productStyle.allSaleTotal}}</span>
        </div>
      </div>

      <div class="modal-body">
        <loading :loading="dataLoading"></loading>
        <a-table
            size="middle"
            rowKey="id"
            :columns="dataColumns"
            :data-source="filterDataList"
            :pagination="false"
            :loading="dataLoading"
        >
          <div slot="imageUrl" slot-scope="imageUrl">
            <div class="image-item" v-lazy:background-image="imageUrl" @click="$imageModal.open(imageUrl)"></div>
          </div>

          <div slot="saleAmount" slot-scope="saleAmount">
            <span class="font-bold text-danger font-large">{{saleAmount | price}}</span>
          </div>

          <div slot="saleTotal" slot-scope="saleTotal">
            <span class="font-bold text-main font-large">{{saleTotal}}</span>
          </div>

          <div slot="warehouseStocks" slot-scope="warehouseStocks">
            <div v-if="warehouseStocks && warehouseStocks.length > 0">
              <div v-for="ws in warehouseStocks.filter(a => a.quantity > 0)" :key="ws.id">
                <label>{{warehouseMap[ws.warehouseId]}}</label>
                /
                <span>{{ws.quantity}}</span>
              </div>
            </div>
          </div>
        </a-table>
      </div>

      <div class="modal-footer left-right-content">
        <div>
          隐藏没有销售的数据
          <a-switch v-model="hideZeroData"></a-switch>
          <span class="font-mini text-muted">
            （共{{dataList.length}}个，已隐藏{{dataList.length - filterDataList.length}}个）
          </span>
        </div>
        <div style="text-align: right;">
          <a-button type="primary" :disabled="currentProductStyleIndex === 0" @click="prevOne" :loading="dataLoading">上一个</a-button>
          （{{currentProductStyleIndex + 1}} / {{productStyleList.length}}）
          <a-button type="primary" :disabled="currentProductStyleIndex + 1 === productStyleList.length" @click="nextOne" :loading="dataLoading">下一个</a-button>
          <a-button @click="visible = false">关闭</a-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import kit from '@/utils/kit'
import { getSaleReport } from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'

export default {
  data () {
    return {
      visible: false,
      productStyleList: [],
      currentProductStyleIndex: 0,
      startTime: null,
      endTime: null,
      dataColumns: [
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          width: 200
        },
        {
          title: '销售额',
          dataIndex: 'saleAmount',
          width: 120,
          scopedSlots: { customRender: 'allSaleAmount' }
        },
        {
          title: '销售数量',
          dataIndex: 'saleTotal',
          width: 120,
          scopedSlots: { customRender: 'allSaleTotal' }
        },
        {
          title: '库存',
          dataIndex: 'warehouseStocks',
          scopedSlots: { customRender: 'warehouseStocks' }
        }
      ],
      dataList: [],
      dataLoading: false,
      hideZeroData: true,
      warehouseMap: {}
    }
  },
  filters: {
    price (v) {
      return kit.str.fmtCurrency(v, 2)
    }
  },
  computed: {
    productStyle () {
      if (this.productStyleList.length > 0) {
        return this.productStyleList[this.currentProductStyleIndex]
      } else {
        return null
      }
    },
    filterDataList () {
      if (this.hideZeroData) {
        return this.dataList.filter(item => item.saleTotal > 0)
      } else {
        return this.dataList
      }
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.productStyleList = []
        this.currentProductStyleIndex = 0
        this.startTime = null
        this.endTime = null
        this.dataList = []
      }
    }
  },
  methods: {
    open (productStyleList, index, startTime, endTime) {
      this.productStyleList = productStyleList
      this.currentProductStyleIndex = index
      this.startTime = startTime
      this.endTime = endTime
      this.visible = true
      this.loadData()
    },
    loadData () {
      this.dataLoading = true
      getSaleReport()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.productStyle.styleNumber, this.startTime, this.endTime)
    },
    prevOne () {
      this.currentProductStyleIndex--
      this.loadData()
    },
    nextOne () {
      this.currentProductStyleIndex++
      this.loadData()
    }
  },
  mounted () {
    listWarehouseEnabled()
      .success(resp => {
        for (const item of resp.data) {
          this.warehouseMap[item.id] = item.name
        }
      })
      .send()
  }
}
</script>

<style lang="less" scoped>
.modal {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  @border: solid 1px #ddd;
  border-left: @border;
  border-top: @border;
  border-right: @border;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  background-color: #fff;

  .modal-head {
    padding: 10px;
    border-bottom: solid 1px #eee;
    background-color: #f9f9f9;
  }

  .modal-body {
    position: relative;
    flex: 1;
    overflow-y: auto;
  }

  .modal-footer {
    border-top: solid 1px #eee;
    padding: 10px;
    align-items: center;
  }
}
</style>
