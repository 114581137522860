<template>
  <app-page :page-title="customerEmail" :route-name="routeName">

    <div slot="center" class="container">
      <div class="section left">
        <loading :loading="leftContainerLoading"></loading>
        <div class="header">
          <div class="session-switch">
            <div class="text">
              <div v-if="sessionList.length === 0">服务列表</div>
              <div v-else-if="sessionList.length === 1" class="text-overflow">{{selectedSession.title}}</div>
              <div v-else>
                <a-select
                    v-model="selectedSessionId"
                    style="width: 100%;"
                    option-label-prop="label"
                >
                  <a-select-option
                      v-for="s in sessionList"
                      :key="s.id"
                      :value="s.id"
                      :label="s.orderNo + ' / ' + s.title"
                  >
                    {{s.orderNo}}
                    /
                    <span :class="'status-' + selectedSession.status">{{sessionStatusMapping[selectedSession.status]}}</span>
                    /
                    {{s.title}}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <a-button v-show="selectedSession" icon="edit" @click="$refs.sessionEditor.open(customerEmail, selectedSession)"></a-button>
            <a-button type="primary" @click="$refs.sessionEditor.open(customerEmail)">新建会话</a-button>
          </div>
          <div v-if="selectedSession" class="session-detail-info">
            <div class="info">
              <span class="info-block">状态：<span :class="'status-' + selectedSession.status">{{sessionStatusMapping[selectedSession.status]}}</span></span>
              <span class="info-block">优先级：<span :class="'priority-' + selectedSession.priority">{{sessionPriorityMapping[selectedSession.priority]}}</span></span>
              <span class="info-block">案件类别：{{selectedSession.category}}</span>
              <span class="info-block">日期：{{selectedSession.createTime}}</span>
            </div>
            <div class="tag-list" v-if="selectedSession.tags && selectedSession.tags.length > 0">
              <span class="tag" v-for="(tag,index) in selectedSession.tags.split(',')" :key="index">{{tag}}</span>
            </div>
          </div>
        </div>


        <div class="content event-list" id="session-event-list-container">
          <a-empty v-show="eventList.length === 0 && !leftContainerLoading" style="margin-top: 50px;"/>
          <div class="event-item" v-for="event in eventList" :key="event.id">

            <div class="user-time">
              <span>{{event.createAdminName}} - {{event.createTime}}</span>
              <a-switch style="margin-left: 20px;"
                        class="enabled-switch"
                        size="small"
                        v-model="event.enabled"
                        checked-children="正常" un-checked-children="作废"
                        @change="handleEventEnableChange(event)"
              />
            </div>

            <div v-show="!event.enabled" class="font-mini" style="margin-top: 8px; margin-left: 12px;">
              ** 内容已经作废，
              <span v-show="!event.disabledVisible" class="text-success" style="cursor: pointer;" @click="event.disabledVisible = true">显示内容</span>
              <span v-show="event.disabledVisible" class="text-danger" style="cursor: pointer;" @click="event.disabledVisible = false">隐藏内容</span>
            </div>

            <div v-show="event.enabled || event.disabledVisible">
              <div class="content text" v-if="event.type === eventType.text" v-html="event.content"></div>

              <div class="content image" v-if="event.type === eventType.image">
                <img :src="event.imageUrl" @click="$refs.imageModal.open(event.imageUrl)" />
              </div>

              <!-- 退货 -->
              <div class="content refund" v-if="event.type === eventType.refund">
                <div class="text-block"><label>订单号：</label><span>{{event.refund.orderNo}}</span></div>
                <div class="text-block"><label>退款渠道：</label><span>{{event.refund.returnChannel}}</span></div>
                <div class="text-block">
                  <label>金额：</label>
                  <span v-if="event.refund.price === 0">全额退款</span>
                  <span v-else>{{event.refund.price}} {{event.refund.currency}}</span>
                </div>
                <div class="text-block" v-if="event.refund.fee > 0">
                  <label>手续费：</label><span>{{event.refund.fee}}%（{{ (event.refund.price * (event.refund.fee / 100)) | numberScale }} {{event.refund.currency}}）</span>
                </div>
                <div class="text-block" v-if="event.refund.fee > 0 && event.refund.returnChannel === 'Paypal'">
                  <label>应退还：</label><span>{{ (event.refund.fee / 100 * event.refund.price + event.refund.price) | numberScale }} {{event.refund.currency}}</span>
                </div>
                <div class="text-block"><label>退款账号：</label><span>{{event.refund.account}}</span></div>
                <div class="text-block">
                  <label>是否已退款：</label>
                  <span>
                    <a-switch v-model="event.refund.done" @change="handleEventRefundStatusChange(event)" />
                  </span>
                </div>
                <div class="refund-label">退款</div>
                <div class="refund-success" v-show="event.refund.done"><a-icon type="check" /></div>
              </div>

              <!-- 系统消息 -->
              <div class="content robot" v-if="event.type === eventType.robot">{{event.content}}</div>

              <!-- 退货内容 -->
              <div class="content order-return" v-if="event.type === eventType.orderReturn">
                <div class="text-block"><label>订单号：</label><span>{{event.orderReturn.orderNo}}</span></div>
                <div class="text-block"><label>状态：</label><span>{{orderReturnStatus[event.orderReturn.status]}}</span></div>
                <div class="text-block"><label>运单号：</label><span>{{event.orderReturn.trackNo}}</span></div>
                <div class="text-block"><label>物流公司：</label><span>{{event.orderReturn.logisticsName}}</span></div>
                <div class="text-block"><label>备注：</label><span>{{event.orderReturn.remark}}</span></div>
                <div class="image-list" v-if="event.orderReturn.returnItems.length > 0">
                  <a-tooltip v-for="item in event.orderReturn.returnItems" :key="item.id">
                    <template slot="title">
                      {{item.sku}}
                    </template>
                    <div class="image-item small"
                         v-lazy:background-image="item.imageUrl"
                         @click="$refs.imageModal.open(item.imageUrl)"
                    >
                      <span class="total" :class="{'total-more': item.quantity > 1}">{{item.quantity}}</span>
                    </div>
                  </a-tooltip>
                </div>
                <div class="order-return-label">退货</div>
                <div class="edit" @click="$refs.orderReturnEditor.open(event.orderReturn)"><a-icon type="edit" /></div>
              </div>
            </div>
          </div>

          <div v-if="isSessionCompleted" class="event-item">
            <div class="content session-complete">服务已经结束</div>
          </div>

        </div>


        <div class="buttons" v-show="selectedSession && !isSessionCompleted">
          <span class="text-primary">添加记录：</span>
          <span class="button-item text" @click="handleAddEvent(eventType.text)">文本</span>
          <span class="button-item image" @click="handleAddEvent(eventType.image)">图片</span>
          <span class="button-item refund" @click="handleAddEvent(eventType.refund)">退款</span>
          <span class="button-item return-order" @click="handleAddEvent(eventType.orderReturn)">退货</span>
          <span class="button-item complete" @click="handleAddEvent('complete')">结案</span>
          <session-event-editor ref="eventEditor" @saved="handleSessionEventSaved"></session-event-editor>
        </div>
      </div>

      <orders :email="customerEmail" ref="orders" @updateOrderReturn="handleOpenOrderReturnEditor"></orders>

    </div>

    <session-editor ref="sessionEditor" @saved="handleSessionSaved"></session-editor>
    <order-return-editor ref="orderReturnEditor" @saved="handleOrderReturnSaved"></order-return-editor>
    <image-modal ref="imageModal"></image-modal>

  </app-page>
</template>

<script>
import { ROUTE_AFTER_SALE_SESSION_DETAIL } from '@/router/router-constants'
import SessionEditor from './comp/session-editor'
import SessionEventEditor from './comp/session-event-editor'
import OrderReturnEditor from '../return/comp/return-editor'
import Orders from './comp/orders'
import {
  listSessionByCustomerEmail,
  listEventsByAfterSaleSessionId,
  addEvent,
  updateEventEnabled,
  updateEventRefundData
} from '@/http/api/after-sale-session'
import { STATUS_MAPPING, STATUS_COMPLETED, PRIORITY_MAPPING } from '@/constants/after-sale-session'
import { ORDER_RETURN_STATUS_MAPPING } from '@/constants/order-return-status'
import { EVENT_TYPE } from '@/constants/after-sale-event-type'
import kit from '@/utils/kit'

export default {
  components: { SessionEditor, SessionEventEditor, OrderReturnEditor, Orders },
  data () {
    return {
      routeName: ROUTE_AFTER_SALE_SESSION_DETAIL,
      sessionList: [],
      selectedSessionId: null,
      eventList: [],
      sessionStatusMapping: STATUS_MAPPING,
      sessionPriorityMapping: PRIORITY_MAPPING,
      eventType: EVENT_TYPE,
      orderReturnStatus: ORDER_RETURN_STATUS_MAPPING,
      leftContainerLoading: false
    }
  },
  computed: {
    customerEmail () {
      return this.$route.params.email
    },
    isNew () {
      return this.customerEmail === 'new'
    },
    selectedSession () {
      return this.sessionList.find(item => item.id === this.selectedSessionId)
    },
    isSessionCompleted () {
      return this.selectedSession && this.selectedSession.status === STATUS_COMPLETED
    }
  },
  filters: {
    numberScale (n) {
      return kit.num.toFixedTrimZero(n, 2)
    }
  },
  watch: {
    customerEmail (email) {
      if (!this.isNew) {
        this.loadSessionByCustomerEmail()
        this.$refs.orders.loadOrders(email)
      }
    },
    selectedSessionId (id) {
      if (id) {
        this.loadEvents()
        this.$refs.orders.reload(this.selectedSession.ordersId)
      }
    }
  },
  methods: {
    handleSessionSaved (data, email) {
      if (this.isNew) {
        this.$router.push({ name: ROUTE_AFTER_SALE_SESSION_DETAIL, params: { email: email } })
      } else {
        kit.arr.insertOrReplace(this.sessionList, 0, data)
        this.selectedSessionId = data.id
      }
      this.$refs.orders.reload(this.selectedSession.ordersId)
    },
    loadSessionByCustomerEmail () {
      listSessionByCustomerEmail()
        .success(resp => {
          this.sessionList = resp.data
          if (this.sessionList.length === 0) {
            this.$refs.sessionEditor.open(this.customerEmail)
          } else {
            if (this.$route.query.selectedId) {
              this.selectedSessionId = this.$route.query.selectedId
            } else {
              this.selectedSessionId = resp.data[0].id
            }
          }
        })
        .send(this.customerEmail)
    },
    loadEvents () {
      if (this.selectedSessionId) {
        this.leftContainerLoading = true
        listEventsByAfterSaleSessionId()
          .complete(() => (this.leftContainerLoading = false))
          .success(resp => {
            for (const item of resp.data) {
              // 被作废的项是否显示内容
              item.disabledVisible = false
            }
            this.eventList = resp.data
            this.$nextTick(() => {
              const objDiv = document.getElementById('session-event-list-container')
              objDiv.scrollTop = objDiv.scrollHeight
            })
          })
          .send(this.selectedSessionId)
      }
    },
    handleAddEvent (type) {
      if (type === this.eventType.orderReturn) {
        this.$refs.orderReturnEditor.open(this.selectedSession.orderNo)
      } else if (type === 'complete') {
        this.$refs.eventEditor.openForComplete(this.selectedSession.id)
      } else {
        this.$refs.eventEditor.open(this.selectedSession.id, type, this.selectedSession.orderNo, this.selectedSession.orderPayPrice)
      }
    },
    handleOrderReturnSaved (data, isUpdate) {
      if (isUpdate) {
        this.loadEvents()
        this.$refs.orders.updateOrderReturn(data)
      } else {
        addEvent()
          .success(resp => {
            this.loadEvents()
          })
          .send({
            content: data.id,
            afterSaleSessionId: this.selectedSessionId,
            task: false,
            enabled: true,
            type: this.eventType.orderReturn
          })
      }
    },
    handleEventEnableChange (item) {
      this.leftContainerLoading = true
      updateEventEnabled()
        .complete(() => (this.leftContainerLoading = false))
        .send(item.id, item.enabled)
    },
    handleOpenOrderReturnEditor (item) {
      this.$refs.orderReturnEditor.open(item)
    },
    handleEventRefundStatusChange (event) {
      const refund = Object.assign({
        afterSaleSessionEventId: event.id
      }, event.refund)
      this.leftContainerLoading = true
      updateEventRefundData()
        .complete(() => (this.leftContainerLoading = false))
        .success(resp => {
          kit.arr.pushOrReplace(this.sessionList, resp.data)
          this.$message.success('更新成功。')
        })
        .send(refund)
    },
    handleSessionEventSaved (afterSaleSession) {
      kit.arr.pushOrReplace(this.sessionList, afterSaleSession)
      this.loadEvents()
    }
  },
  mounted () {
    this.loadSessionByCustomerEmail()
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &.left {
    flex: 1;
    margin-right: 10px;
    border-right: solid 1px #eee;
  }

  .header {
    padding: 10px;
    border-bottom: solid 1px #eee;
  }
  .content {
    flex: 1;
  }
}

.section.left {
  .header {
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    .session-switch {
      display: flex;
      align-items: center;
      height: 40px;
      .text {
        flex: 1;
        font-weight: bold;
        margin-right: 20px;
      }
    }
  }

  .buttons {
    position: relative;
    padding: 15px 10px;
    border-top: solid 1px #eee;
    .button-item {
      margin-left: 20px;
      padding: 4px 15px;
      border-radius: 5px;
      border: solid 1px #ddd;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: #f9f9f9;
      }
      &.complete {
        color: #fff;
        background-color: #46b33a;
        border: none;
      }
    }
  }
}


.session-detail-info {
  font-size: 12px;
  .info {
    .info-block {
      display: inline-block;
    }
    .info-block + .info-block {
      margin-left: 30px;
    }
    .status-service {
      color: #3d71a7;
    }
    .status-completed {
      color: #228018;
    }
    .priority-normal {
      color: #2b2b2b;
    }
    .priority-quick {
      color: #f66a21;
    }
    .priority-important {
      color: #ce0505;
    }
  }

  .tag-list {
    text-align: right;
    margin-top: 10px;
    .tag {
      padding: 2px 10px;
      background-color: #828282;
      color: #fff;
      border-radius: 8px;
    }
    .tag + .tag {
      margin-left: 15px;
    }
  }
}


.event-list {
  overflow-y: auto;
  .event-item {
    padding: 20px 30px;
    .user-time {
      font-size: 12px;
      color: #606266;
    }
    .enabled-switch {
      display: none;
    }
    &:hover .enabled-switch {
      display: inline-block;
    }
    .content {
      overflow: visible;
      display: inline-block;
      width: auto;
      margin-top: 10px;
      margin-left: 20px;
      padding: 10px;
      border-radius: 5px;
      background-color: #f9f9f9;
      border: solid 1px #ddd;
    }
    .content.image {
      img {
        width: 200px;
        height: auto;
      }
    }
    .content.robot {
      margin-top: 0;
      padding: 10px 0;
      background-color: transparent;
      border: none;
      font-style: italic;
      color: #c92929;
      font-size: 12px;
    }
    .content.refund {
      position: relative;
      margin-top: 20px;
      background-color: #e26557;
      color: #fff;
      max-width: 400px;
      border-radius: 10px;
      border-color: #bb3e30;
      .refund-label {
        position: absolute;
        top: -15px;
        padding: 2px 10px;
        background-color: #fff;
        color: #d20909;
        font-weight: bold;
        font-size: 12px;
        border-radius: 5px;
      }
      .refund-success {
        position: absolute;
        top: -15px;
        right: 5px;
        background-color: #20a811;
        color: #fff;
        border: solid 2px #fff;
        font-weight: bold;
        font-size: 12px;
        border-radius: 100%;
        width: 25px;
        height: 25px;
        line-height: 22px;
        text-align: center;
      }
    }

    .content {
      .text-block {
        font-size: 12px;
        label {
          display: inline-block;
          width: 80px;
          text-align: right;
          margin-right: 3px;
        }
      }
    }

    .content.order-return {
      position: relative;
      margin-top: 20px;
      background-color: #efa871;
      color: #fff;
      max-width: 400px;
      border-radius: 10px;
      border-color: #e26e16;
      .order-return-label {
        position: absolute;
        top: -15px;
        padding: 2px 10px;
        background-color: #fff;
        color: #ee5301;
        font-weight: bold;
        font-size: 12px;
        border-radius: 5px;
      }
      .edit {
        position: absolute;
        top: -15px;
        left: 60px;
        color: #fff;
        background-color: #2387ef;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 100%;
        cursor: pointer;
      }
      .image-list {
        display: flex;
        margin-top: 10px;
      }
      .image-item{
        overflow: visible;
        margin: 5px;
        background-color: #fff;
        .total {
          position: absolute;
          right: -8px;
          top: -8px;
          z-index: 10;
          width: 18px;
          height: 18px;
          line-height: 18px;
          border-radius: 100%;
          background-color: #6ca2e3;
          color: #fff;
          text-align: center;
          font-size: 12px;
        }
        .total-more {
          background-color: #bb3e30;
        }
      }
    }

    .content.session-complete {
      display: block;
      text-align: center;
      margin-top: 0;
      padding: 10px 0;
      background-color: transparent;
      border: none;
      font-weight: bold;
      color: #19b82e;
      font-size: 13px;
    }
  }
}
</style>
