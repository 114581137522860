import defineApi from '../define-api'
import http from '../application-api'

export const listScheduleByShop = defineApi((config, shopId) => {
  config.params = {
    shopId: shopId
  }
  http.get('/schedule/order_sync/list', config)
})

export const getOrderSyncScheduleById = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/schedule/order_sync/get_by_id', config)
})

export const createSchedule = defineApi((config, shopId, startDate, endDate) => {
  config.params = {
    shopId: shopId,
    start: startDate,
    end: endDate
  }
  http.post('/schedule/order_sync/start', config)
})

export const restartSchedule = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/schedule/order_sync/restart', config)
})

export const stopSchedule = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/schedule/order_sync/stop', config)
})

export const listByIdList = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.get('/schedule/order_sync/list/by_id_list', config)
})
