import defineApi from '../define-api'
import http from '../application-api'

export const listAll = defineApi((config) => {
  http.get('/dept/list', config)
})

export const listAllDto = defineApi((config) => {
  http.get('/dept/list/dto', config)
})

export const add = defineApi((config, dept) => {
  config.data = dept
  http.post('/dept/add', config)
})

export const update = defineApi((config, dept) => {
  config.data = dept
  http.post('/dept/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/dept/delete', config)
})

export const bindShops = defineApi((config, deptId, shopId) => {
  config.params = { deptId: deptId, shopId: shopId }
  http.post('/dept/bind_shops', config)
})
