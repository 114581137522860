<!-- 订单佣金更新 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" @click="handleSelectFile" :loading="uploading">上传佣金数据</a-button>


    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
    >
      <div slot="status" slot-scope="row" class="font-mini">
        <div v-if="row.status === 'success'" class="text-success">成功</div>
        <div v-else-if="row.status === 'none'" class="text-warning">记录不存在</div>
        <div v-else class="text-danger">错误：{{row.message}}</div>
      </div>
    </a-table>

    <div slot="bottom">
      <div v-show="dataList.length > 0" style="text-align: center; padding: 20px 0;">
        有效数据 <span class="text-success">{{successTotal}}</span> 行，无效数据 <span class="text-danger">{{dataList.length - successTotal}}</span> 行。
        <a-button slot="toolbar" type="primary" @click="handleSubmit" :loading="uploading" :disabled="dataList.length === 0">提交更新</a-button>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_COMMISSION_UPDATE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { previewCommissionUpdate, commissionUpdate } from '@/http/api/order'
import selectFile from '@/utils/select-file'

export default {
  data () {
    return {
      routeName: ROUTE_ORDER_COMMISSION_UPDATE,
      dataColumns: [
        { title: '订单号', dataIndex: 'orderNo' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '佣金', dataIndex: 'commission' },
        { title: '状态', scopedSlots: { customRender: 'status' } }
      ],
      dataList: [],
      uploading: false
    }
  },
  mixins: [AntdTableHeightMixin],
  computed: {
    successTotal () {
      return this.dataList.filter(item => item.status === 'success').length
    }
  },
  methods: {
    handleSelectFile () {
      selectFile({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        multi: false,
        select: (files, errors) => {
          this.dataList = []
          this.uploading = true
          previewCommissionUpdate()
            .complete(() => (this.uploading = false))
            .success(resp => {
              this.dataList = resp.data
              this.$message.success('数据已准备好，请确认后提交。')
            })
            .send(files[0])
        }
      })
    },
    handleSubmit () {
      const arr = []
      for (const item of this.dataList) {
        if (item.status === 'success') {
          arr.push({ id: item.id, number: item.commission })
        }
      }
      this.$confirm({
        title: '确认',
        content: `有效数据${arr.length}行，无效数据${this.dataList.length - arr.length}行，确认提交吗。`,
        onOk: () => {
          this.uploading = true
          commissionUpdate()
            .complete(() => (this.uploading = false))
            .success(() => {
              this.$message.success('数据已经更新。')
              this.dataList = []
            })
            .send(arr)
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>

</style>
