import defineApi from '../define-api'
import http from '../application-api'

export const listSupplierOrderDelivery = defineApi((config, params) => {
  config.params = params
  http.get('/supplier_order_delivery/list', config)
})

export const addSupplierOrderDelivery = defineApi((config, supplierId, orderId, orderDeliveryId) => {
  config.data = {
    supplierId: supplierId,
    orderId: orderId,
    orderDeliveryId: orderDeliveryId
  }
  http.post('/supplier_order_delivery/add', config)
})

export const deleteSupplierOrderDeliveryByOrderDeliveryId = defineApi((config, orderDeliveryId) => {
  config.params = {
    orderDeliveryId: orderDeliveryId
  }
  http.post('/supplier_order_delivery/delete', config)
})

export const acceptSupplierOrderDelivery = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.post('/supplier_order_delivery/accept', config)
})

export const cancelAcceptSupplierOrderDelivery = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.post('/supplier_order_delivery/accept/cancel', config)
})

export const confirmSupplierOrderDelivery = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.post('/supplier_order_delivery/delivery/confirm', config)
})

export const getSupplierOrderDeliveryTotalGroupByStatus = defineApi((config) => {
  http.get('/supplier_order_delivery/total/by_status', config)
})

export const getLogistics = defineApi((config, keyword) => {
  config.params = { keyword: keyword }
  http.get('/supplier_order_delivery/logistics/search', config)
})

export const supplierOrderDeliveryDelivery = defineApi((config, data) => {
  config.data = data
  http.post('/supplier_order_delivery/delivery', config)
})
