<template>
  <app-page :route-name="routeName" :page-title="pageTitle">

    <div slot="toolbar">
      <a-button @click="reloadData" icon="search">查询</a-button>
    </div>

    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <div>
          <a-form-model-item label="仓库">
            <a-select v-model="searchParams.warehouseId" style="width: 100px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="出入库">
            <a-select v-model="searchParams.type" style="width: 100px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in typeOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="来源">
            <a-select v-model="searchParams.fromId" style="width: 100px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in fromIdOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="描述">
            <a-input v-model="searchParams.message" placeholder="描述关键字" style="width: 300px;" :allow-clear="true" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="type" slot-scope="type">
        <span class="text-primary" v-if="type === 2">初始库存</span>
        <span class="text-success" v-else-if="type === 1">入库</span>
        <span class="text-danger" v-else-if="type === 0">出库</span>
      </div>
      <div slot="fromId" slot-scope="fromId" style="font-weight: bold;">
        <span v-if="fromId === 10" style="color: #3d71a7;">供应商订单</span>
        <span v-else-if="fromId === 20" style="color: #096c0b;">发货</span>
        <span v-else-if="fromId === 21" style="color: #ce630d;">取消发货</span>
        <span v-else-if="fromId === 30" style="color: #7c03d0;">文件导入</span>
        <span v-else-if="fromId === 31" style="color: #451a86;">库存盘点</span>
        <span v-else-if="fromId === 40" style="color: #b10843;">情侣SKU出入库</span>
        <span v-else-if="fromId === 50" style="color: #e5db02;">库存迁移</span>
        <span v-else-if="fromId === 60" style="color: #313030;">手动入库</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_WAREHOUSE_STOCK_RECORD } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  getRecords,
  getStockProductById
} from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_WAREHOUSE_STOCK_RECORD,
      dataColumns: [
        {
          title: '仓库',
          dataIndex: 'warehouseName',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 70
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          width: 50
        },
        {
          title: '操作前数量',
          dataIndex: 'quantityLeft',
          width: 90
        },
        {
          title: '操作人',
          dataIndex: 'adminName',
          width: 80
        },
        {
          title: '来源',
          dataIndex: 'fromId',
          scopedSlots: { customRender: 'fromId' },
          width: 100
        },
        {
          title: '描述',
          dataIndex: 'message'
        },
        {
          title: '时间',
          dataIndex: 'createTime',
          width: 140
        }
      ],
      stockProductSku: null,
      searchParams: {
        warehouseId: null,
        fromId: null,
        type: null,
        message: null
      },
      dataList: [],
      dataLoading: false,
      warehouseOptions: [],
      fromIdOptions: [
        { id: 10, name: '供应商订单' },
        { id: 20, name: '发货' },
        { id: 21, name: '取消发货' },
        { id: 30, name: '文件导入' }
      ],
      typeOptions: [
        { id: 1, name: '入库' },
        { id: 0, name: '出库' }
      ]
    }
  },
  computed: {
    pageTitle () {
      return this.stockProductSku
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      p.stockProductId = this.$route.params.stockProductId
      this.dataLoading = true
      getRecords()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    }
  },
  mounted () {
    this.loadData()
    listWarehouseEnabled()
      .success(resp => {
        this.warehouseOptions = resp.data
      })
      .send()

    getStockProductById()
      .success(resp => (this.stockProductSku = resp.data.sku))
      .send(this.$route.params.stockProductId)
  }
}
</script>

<style lang="less" scoped>
</style>
