<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" type="primary" @click="$refs.internalOrderEditor.open()" v-authority="$authority.orderManage">创建内部订单</a-button>
    <a-button slot="toolbar" @click="handleNavImportOrder()" v-authority="$authority.orderManage">导入订单</a-button>
    <a-button slot="toolbar" @click="$refs.orderSync.open()" v-authority="[$authority.orderManage, $authority.orderSync]">同步订单</a-button>
    <a-button slot="toolbar" @click="handleOpenMultiDelivery()" v-authority="[$authority.orderManage, $authority.orderDelivery]">发货</a-button>

    <a-button slot="toolbar" @click="$refs.skuOrderDetail.open(dataList)">查看当前页SKU</a-button>

    <a-button slot="toolbar" @click="handleExportOrder()">导出订单（限2000）</a-button>

    <div slot="top">
      <div class="total-tab">
        <ul>
          <li v-for="tab in totalTabs"
              :key="tab.id"
              @click="handleTotalTabSelected(tab)"
              :class="{'selected': tab.selected, 'attention': tab.id === 'attention'}">
            {{tab.title}}
            <span class="total">( {{tab.total}} )</span>
          </li>
        </ul>
      </div>

      <div class="search-form" style="margin-top: 5px;">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <div>
            <a-form-model-item label="订单号">
              <a-input v-model="searchParams.orderNo" :allow-clear="true" style="width: 220px;" />
            </a-form-model-item>
            <a-form-model-item label="客户邮箱">
              <a-input v-model="searchParams.email" :allow-clear="true" style="width: 160px;" />
            </a-form-model-item>
            <a-form-model-item label="供应商运单号">
              <a-input v-model="searchParams.produceItemDeliveryTrackNo" :allow-clear="true" style="width: 160px;" />
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="店- -铺">
              <a-select v-model="searchParams.shopId" style="width: 220px;" :allow-clear="true">
                <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="数据排序">
              <a-select v-model="searchParams.orderByName" style="width: 160px;">
                <a-select-option :value="item.id" v-for="item in [
                    { id: 'create_time:desc', name: '创建时间-倒序' },
                    { id: 'create_time:asc', name: '创建时间-升序' },
                    { id: 'order_no:desc', name: '订单号-倒序' },
                    { id: 'order_no:asc', name: '订单号-升序' }
                    ]"
                   :key="item.id"
                >
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="创建日期">
              <a-range-picker v-model="searchParams.createDateRange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData()" icon="reload">查询</a-button>
        </div>
      </div>
    </div>

    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableX }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedTabDataContent.selectedRowKeys, onChange: handleSelectChange }"
        :rowClassName="(r,index) => index % 2 === 0 ? 'row-bg' : ''"
    >
      <div slot="action" slot-scope="item">
        <div v-authority="$authority.orderManage">
          <a-button size="small"
                    type="primary"
                    @click="$refs.produceItemEditor.open(item.id)"
                    :disabled="item.produceItemTotal > 0"
                    v-if="selectedTotalTab.id === 'paid' || selectedTotalTab.id === 'noProduce'"
          >
            <span v-if="item.produceItemTotal === 0">生产</span>
            <span v-else>已生产</span>
          </a-button>
        </div>
        <div style="margin-top: 8px;">
          <a-button v-if="selectedTotalTab.id === 'attention'" size="small" @click="handleRemoveOrderAttention(item)">取消关注</a-button>
          <a-button v-else :type="item.impAttend ? '' : 'danger'" :disabled="item.impAttend" size="small" @click="$refs.importantOrder.open(item)">
            <span v-if="item.impAttend">已关注</span>
            <span v-else>关注</span>
          </a-button>
        </div>

        <div v-if="selectedTotalTab.id === 'internal'" style="margin-top: 8px;" v-authority="$authority.orderManage">
          <a-button size="small" @click="$refs.internalOrderEditor.open(item)">编辑</a-button>
        </div>
      </div>

      <div slot="orderNo" slot-scope="orderNo, item">
        <span class="text-primary" style="cursor: pointer;" @click="navOrderDetail(item)">{{orderNo}}</span>
        <div class="text-muted font-mini">
          原：
          <span v-if="item.originOrderNo && item.originOrderNo !== orderNo">{{item.originOrderNo}}</span>
          <span v-else-if="item.originId">{{item.originId}}</span>
        </div>
      </div>

      <div slot="image" slot-scope="v, r">
        <div class="image-list" v-if="r.productItems.length > 0">
          <div class="image-wrapper"
               v-for="(pItem,index) in limitArray(r.productItems, 4)"
               :class="{'stock-in': pItem.stockQuantity > 0}"
               :key="index"
          >
            <div class="image-item small" v-lazy:background-image="pItem.imageUrl" @click="$imageModal.open(getImageArray(r), index)"></div>
          </div>
          <div class="image-total" v-if="r.productItems.length > 4">{{r.productItems.length}}</div>
        </div>
      </div>

      <div slot="customer" slot-scope="v, item">
        {{item.receiverLastName}} {{item.receiverFirstName}}
        <div v-if="item.orderTotal > 1">
          <span class="order-total" @click="handleNavRepeatCustomer(item.customerId)">老客户 {{item.orderTotal}} 个订单</span>
          <span class="refund-total" v-if="item.refundTotal > 0">退款 {{item.refundTotal}} 次</span>
        </div>
      </div>

      <div slot="price" slot-scope="v, item">
        <div style="font-weight: bold; font-size: 13px; color: #2568d9">{{item.pricePay}} {{item.currency}}</div>
      </div>

      <div slot="statusBar" slot-scope="v, item">
        <div class="status-bar">
          <div class="status status-risk" :class="'risk-' + item.risk" v-if="item.risk > 0">欺诈</div>
          <div v-if="item.productItems && item.productItems.filter(t => t.stockQuantity > 0).length > 0" class="status status-stock">库存</div>
          <div class="status status-refund" v-if="item.singleRefundTotal > 0">存在退款</div>
          <div class="status status-default" :class="'status-' + item.status">{{statusTextMapping[item.status]}}</div>
          <div class="status status-pay" :class="'status-' + item.statusPay" v-if="item.status !== statusMapping.cancel">{{statusPayTextMapping[item.statusPay]}}</div>
          <div class="status status-ship" :class="'status-' + item.statusShipping + ' ' + (item.allShip ? 'all-ship' : '')">
            {{item.allShip ? '已发完' : statusShipTextMapping[item.statusShipping]}}
            <span v-if="item.statusShipping === statusShipMapping.wait">（已过{{item.dayNewToDelivery}}天）</span>
            <span v-else>（发货用时{{item.dayNewToDelivery}}天）</span>
          </div>

          <div v-if="item.impAttend && item.impTags" class="status status-attention-tag">关注标签：{{item.impTags}}</div>

          <div v-if="item.impAttend" class="status status-priority" :class="'priority-' + item.impPriority">
            优先级：
            <span v-if="item.impPriority === 0">实时关注</span>
            <span v-else-if="item.impPriority === 3">重要</span>
            <span v-else>一般</span>
          </div>
        </div>
      </div>

      <div slot="remark" slot-scope="v, item">
        <div v-if="item.remark" style="padding: 4px 0;">
          <a-tooltip placement="top">
            <template slot="title">{{item.remark}}</template>
            <a-tag color="red">客户备注</a-tag>
          </a-tooltip>
        </div>
        <div v-if="item.adminRemark" style="padding: 4px 0;">
          <a-tooltip placement="top">
            <template slot="title">{{item.adminRemark}}</template>
            <a-tag color="blue">管理员备注</a-tag>
          </a-tooltip>
        </div>
      </div>

      <div slot="createTime" slot-scope="createTime">
        <div class="font-bold text-main font-normal">{{createTime | readableDate}}</div>
        <div class="font-mini text-muted">{{createTime}}</div>
      </div>
    </a-table>


    <div slot="bottom">
      <pagination :total="pageInfo.total" :start.sync="pageInfo.start" :limit.sync="pageInfo.limit"></pagination>
    </div>

    <produce-item-editor ref="produceItemEditor" @saved="handleProduceItemSaved"></produce-item-editor>
    <sku-order-detail ref="skuOrderDetail" @orderClick="handleSkuOrderDetailOrderClick"></sku-order-detail>
    <order-sync ref="orderSync" :shop-list="shopOptions"></order-sync>
    <important-order-editor ref="importantOrder" @saved="loadData()"></important-order-editor>
    <internal-order-editor ref="internalOrderEditor" @saved="loadData()"></internal-order-editor>
    <multi-delivery-modal ref="multiDelivery"></multi-delivery-modal>
  </app-page>
</template>

<script>
import { ROUTE_ORDER, ROUTE_ORDER_DETAIL, ROUTE_ORDER_IMPORT } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listOrders, exportOrders } from '@/http/api/order'
import { listShopOptionsBySelfDept } from '@/http/api/shop'
import {
  ORDER_SHIP_STATUS_WAIT,
  ORDER_STATUS_MAPPING,
  ORDER_STATUS_CANCEL,
  ORDER_SHIP_STATUS_MAPPING,
  ORDER_PAY_STATUS_MAPPING
} from '@/constants/order-status'
import moment from 'moment'
import ProduceItemEditor from './detail/comp/produce-item-editor'
import SkuOrderDetail from './comp/sku-order-detail'
import OrderSync from './comp/order-sync'
import ImportantOrderEditor from './comp/important-order-editor'
import initSearchParams, { processParamsBeforeRequest } from './order-status-search-params'
import { removeImportantOrder } from '@/http/api/important-order'
import InternalOrderEditor from '@/pages/order/comp/internal-order-editor'
import MultiDeliveryModal from '@/pages/delivery/comp/multi-delivery'
import kit from '@/utils/kit'

export default {
  components: { ProduceItemEditor, SkuOrderDetail, OrderSync, ImportantOrderEditor, InternalOrderEditor, MultiDeliveryModal },
  mixins: [AntdTableHeightMixin],
  data () {
    const tabs = [
      { id: 'paid', title: '已付款', total: 0, selected: true },
      { id: 'noProduce', title: '未下单', total: 0, selected: false },
      { id: 'shippedPart', title: '部分发货', total: 0, selected: false },
      { id: 'shipped', title: '已发货', total: 0, selected: false },
      { id: 'timeout', title: '发货超时', total: 0, selected: false },
      { id: 'all', title: '全部', total: 0, selected: false },
      { id: 'attention', title: '重点关注', total: 0, selected: false },
      { id: 'internal', title: '内部订单', total: 0, selected: false }
    ]

    const tabDataContent = {}
    for (const t of tabs) {
      tabDataContent[t.id] = {
        dataList: [],
        page: {
          start: 0,
          total: 0,
          limit: 100
        },
        searchParams: initSearchParams(t.id),
        selectedRowKeys: []
      }
    }
    return {
      routeName: ROUTE_ORDER,
      pageLoading: false,
      dataLoading: false,
      dataColumns: [
        { title: '', width: 70, scopedSlots: { customRender: 'action' } },
        { title: '图片', width: 130, scopedSlots: { customRender: 'image' } },
        { title: '订单号', width: 170, dataIndex: 'orderNo', scopedSlots: { customRender: 'orderNo' } },
        { title: '客户', width: 140, scopedSlots: { customRender: 'customer' } },
        { title: '金额', width: 100, scopedSlots: { customRender: 'price' } },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '状态', width: 300, scopedSlots: { customRender: 'statusBar' } },
        { title: '备注', width: 200, scopedSlots: { customRender: 'remark' } },
        { title: '日期', width: 80, dataIndex: 'createTime', scopedSlots: { customRender: 'createTime' } },
        { title: '国家', width: 100, dataIndex: 'receiverCountry' },
        { title: '店铺', width: 100, dataIndex: 'shopName' }
      ],
      tabDataContent: tabDataContent,
      totalTabs: tabs,
      shopOptions: [],
      statusShipMapping: {
        wait: ORDER_SHIP_STATUS_WAIT
      },
      statusMapping: {
        cancel: ORDER_STATUS_CANCEL
      },
      statusTextMapping: ORDER_STATUS_MAPPING,
      statusShipTextMapping: ORDER_SHIP_STATUS_MAPPING,
      statusPayTextMapping: ORDER_PAY_STATUS_MAPPING
    }
  },
  computed: {
    selectedTotalTab () {
      return this.totalTabs.find(item => item.selected)
    },
    selectedTabDataContent () {
      return this.tabDataContent[this.selectedTotalTab.id]
    },
    pageInfo () {
      if (this.selectedTotalTab) {
        return this.tabDataContent[this.selectedTotalTab.id].page
      } else {
        return { start: 0, total: 0, limit: 100 }
      }
    },
    searchParams () {
      if (this.selectedTotalTab) {
        return this.tabDataContent[this.selectedTotalTab.id].searchParams
      } else {
        return initSearchParams(null)
      }
    },
    dataList () {
      if (this.selectedTotalTab) {
        return this.tabDataContent[this.selectedTotalTab.id].dataList
      } else {
        return []
      }
    },
    selectedRowKeys () {
      if (this.selectedTotalTab) {
        return this.tabDataContent[this.selectedTotalTab.id].selectedRowKeys
      } else {
        return []
      }
    },
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    }
  },
  filters: {
    readableDate (date) {
      const d = Math.abs(
        parseInt(
          moment.duration(moment().diff(moment(date))).as('days')
        )
      )
      if (d === 0) {
        return '今天'
      } else if (d === 1) {
        return '昨天'
      } else if (d === 2) {
        return '前天'
      } else {
        return ''
      }
    }
  },
  methods: {
    limitArray (arr, limit) {
      if (arr.length > limit) {
        return arr.slice(0, limit)
      } else {
        return arr
      }
    },
    getImageArray (record) {
      const arr = []
      for (const p of record.productItems) {
        const item = {
          url: p.imageUrl,
          infos: [
            { name: '订单号', value: record.orderNo },
            { name: 'SKU', value: p.sku },
            { name: '数量', value: p.total }
          ],
          title: p.title
        }
        if (record.remark) {
          item.infos.push({ name: '客户备注', value: record.remark, type: 'remark' })
        }
        arr.push(item)
      }
      return arr
    },
    buildRequestParams (params) {
      const p = kit.obj.deepCopy(params)
      p.start = this.pageInfo.start
      p.limit = this.pageInfo.limit
      return processParamsBeforeRequest(p)
    },
    reloadData () {
      if (this.selectedTabDataContent.page.start === 0) {
        this.loadData()
      } else {
        this.selectedTabDataContent.page.start = 0
      }
    },
    loadData () {
      const params = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      this.selectedTabDataContent.selectedRowKeys = []
      listOrders()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.selectedTabDataContent.dataList = resp.data.records
          this.selectedTabDataContent.page.total = resp.data.total
        })
        .send(params)
    },
    handleExportOrder () {
      const params = this.buildRequestParams(this.searchParams)
      window.open(exportOrders(params))
    },
    handleTotalTabSelected (tab, reload) {
      if (!tab.selected) {
        this.totalTabs.forEach(item => (item.selected = false))
        tab.selected = true
      }
      if (reload === true || this.dataList.length === 0) {
        this.reloadData()
      }
    },
    loadTotals () {
      for (const tab of this.totalTabs) {
        const p = processParamsBeforeRequest(initSearchParams(tab.id))
        p.limit = 0
        listOrders()
          .success(resp => {
            tab.total = resp.data.total
          })
          .send(p)
      }
    },
    navOrderDetail (order) {
      this.$router.push({ name: ROUTE_ORDER_DETAIL, params: { orderId: order.id } })
    },
    handleProduceItemSaved (order, total) {
      const item = this.dataList.find(item => item.id === order.id)
      item.produceItemTotal = total
    },
    handleSkuOrderDetailOrderClick (orderId) {
      this.$refs.produceItemEditor.open(orderId)
    },
    handleRemoveOrderAttention (item) {
      removeImportantOrder()
        .success(() => {
          this.$message.success('已经从关注列表中移除。')
          this.loadData()
        })
        .send(item.id)
    },
    handleNavImportOrder () {
      this.$router.push({ name: ROUTE_ORDER_IMPORT })
    },
    handleSelectChange (selectedKeys) {
      this.selectedTabDataContent.selectedRowKeys = selectedKeys
    },
    handleOpenMultiDelivery () {
      const orderNoList = []
      for (const id of this.selectedRowKeys) {
        orderNoList.push(this.dataList.find(item => item.id === id).orderNo)
      }
      this.$refs.multiDelivery.open(orderNoList)
    },
    handleNavRepeatCustomer (customerId) {
      this.tabDataContent.all.searchParams.email = customerId
      this.handleTotalTabSelected(this.totalTabs.find(t => t.id === 'all'), true)
    }
  },
  mounted () {
    this.loadData()
    this.loadTotals()
    listShopOptionsBySelfDept()
      .success(resp => (this.shopOptions = resp.data))
      .send()

    for (const tab of this.totalTabs) {
      this.$watch(() => (this.tabDataContent[tab.id].page.start), () => {
        this.loadData()
      })
      this.$watch(() => (this.tabDataContent[tab.id].page.limit), () => {
        this.reloadData()
      })
    }
  }
}
</script>

<style lang="less" scoped>

@deep: ~'>>>';

.total-tab {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 4px 20px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    &:hover {
      color: #bb3e30;
    }
    &.selected {
      border-bottom: solid 2px #bb3e30;
    }
    &.attention {
      font-weight: bold;
      background-color: #fabfc1;
      border-radius: 5px;
    }
    .total {
      margin-left: 4px;
      color: #bb3e30;
      font-size: 12px;
    }
  }
  li + li {
    margin-left: 14px;
  }
}

.image-list {
  position: relative;
  width: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  .image-wrapper.stock-in .image-item {
    border-color: #b501ff;
    border-width: 2px;
  }
  .image-total {
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -8px;
    color: #fff;
    background-color: #4586d4;
    padding: 2px 8px;
    border-radius: 20px;
  }
}

.data-table @{deep} .row-bg {
  background-color: #f4f7fa;
}

.order-total {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 10px;
  color: #fff;
  background-color: #4e81b6;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.refund-total {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 10px;
  color: #fff;
  background-color: #d72e34;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}


.status-bar {
  .status {
    display: inline-block;
    padding: 3px 10px;
    margin: 2px;
    font-size: 12px;
    border-radius: 6px;
    background-color: #d2d2d2;
  }
  .status-stock {
    color: #fff;
    background-color: #b501ff;
  }
  .status-produce {
    color: #fff;
    background-color: #e36f1a;
  }
  .status-complete {
    color: #fff;
    background-color: #368e0c;
  }
  .status-close, .status-cancel {
    color: #fff;
    background-color: #bb3e30;
  }
  .status-payPartially, .status-pay {
    color: #fff;
    background-color: #368e0c;
  }
  .status-refund, .status-refundPartially {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-send {
    color: #fff;
    background-color: #0976bb;
  }
  .status-arrived {
    color: #fff;
    background-color: #368e0c;
  }
  .all-ship {
    background-color: #368e0c !important;
    color: #fff;
  }
  .status-risk.risk-2 {
    color: #fff;
    background-color: #e7710a;
  }
  .status-risk.risk-5 {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-priority.priority-0 {
    background-color: #a10b10;
    color: #fff;
  }
  .status-priority.priority-3 {
    background-color: #ce7e3c;
    color: #fff;
  }
  .status-priority.priority-6 {
    background-color: #0b6985;
    color: #fff;
  }

  .status-attention-tag {
    background-color: #9a9999;
    color: #2a2a2a;
  }
}
</style>
