/**
 * @author jiangxingshang
 * @date 2022/12/13
 */
import kit from '@/utils/kit'

export default class User {
  constructor (initData) {
    this.id = null
    this.name = null
    this.username = null
    this.password = null
    this.createDate = null
    this.type = null
    this.status = true
    this.tags = null
    this.deptId = null
    this.deptLeader = false
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
