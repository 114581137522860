<template>
  <div class="panel login-panel">
    <div class="form-item input-item">
      <label>手机号</label>
      <input type="text" v-model.trim="username" @keyup.enter="login">
      <i class="el-icon-close clear-username" @click="clearUsername"></i>
    </div>
    <div class="form-item input-item">
      <label>验证码</label>
      <input type="text" v-model="code" maxlength="6" @keyup.enter="login">
      <span class="code-image">
        <a-button @click="getValidateCode" :loading="getCodeLoading" :disabled="validateCodeButtonDisabled">{{getValidateCodeButtonText}}</a-button>
      </span>
    </div>
    <div class="or">
      或者
    </div>
    <div class="form-item input-item">
      <label>密码</label>
      <input type="password" v-model.trim="password" @keyup.enter="login">
      <div class="remember">
        <a-checkbox v-model="rememberMe">记住密码</a-checkbox>
      </div>
    </div>
    <div class="button-bar" style="text-align: center; margin-top: 10px; padding: 0 15px;">
      <a-button type="primary" @click="login" block :disabled="loginButtonDisabled">{{loginButtonText}}</a-button>
    </div>
  </div>
</template>

<script>
import { login, getLoginCode } from '@/http/api/admin'
import { Base64 } from 'js-base64'

export default {
  data () {
    return {
      loginValidateCodeUrl: '',
      loginButtonDisabled: false,
      loginButtonText: '登录系统',
      username: '',
      password: null,
      code: '',
      validateCodeButtonDisabled: false,
      getCodeLoading: false,
      getValidateCodeButtonText: '获取验证码',
      rememberMe: false
    }
  },
  computed: {
  },
  methods: {
    getLoginUserDashboard () {
      return this.$userStore.getLoginUserDashboard()
    },
    login () {
      if (this.loginButtonDisabled) return
      this.loginButtonDisabled = true
      this.loginButtonText = '正在验证账号信息，请稍等...'
      login()
        .complete(() => {
          this.loginButtonDisabled = false
          this.loginButtonText = '登录系统'
        })
        .success(() => {
          localStorage.setItem('username', this.username)
          if (this.rememberMe) {
            localStorage.setItem('remember_pwd', Base64.encode(this.password))
          } else {
            localStorage.removeItem('remember_pwd')
          }


          if (this.$route.query.from) {
            this.$router.push(this.$route.query.from)
          } else {
            this.$router.push({ name: this.getLoginUserDashboard() })
          }
        })
        .send(this.username, this.code, this.password)
    },
    clearUsername () {
      this.username = ''
      localStorage.removeItem('username')
    },
    getValidateCode () {
      this.getCodeLoading = true
      getLoginCode()
        .complete(() => (this.getCodeLoading = false))
        .success(() => {
          this.startValidateCodeCounter()
          this.$message.success('短信验证码已经发送到您手机，请查收。')
        })
        .send(this.username)
    },
    startValidateCodeCounter () {
      let sec = 59
      this.validateCodeButtonDisabled = true
      this.getValidateCodeButtonText = `${sec} 秒`
      this.validateCodeCounterId = setInterval(() => {
        if (--sec <= 0) {
          this.stopValidateCodeCounter()
        } else {
          this.getValidateCodeButtonText = `${sec} 秒`
        }
      }, 1000)
    },
    stopValidateCodeCounter () {
      clearInterval(this.validateCodeCounterId)
      this.validateCodeButtonDisabled = false
      this.getValidateCodeButtonText = '获取验证码'
    }
  },
  mounted () {
    this.username = localStorage.getItem('username')
    const pwd = localStorage.getItem('remember_pwd')
    if (pwd) {
      this.rememberMe = true
      this.password = Base64.decode(pwd)
    }
  }
}
</script>

<style lang="less" scoped>
.or {
  padding-top: 15px;
  text-align: center;
  color: #064c94;
  font-size: 12px;
}
</style>
