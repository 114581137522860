<!-- 重量和体积批量编辑 -->
<template>
  <modal
      ref="m"
      title="SKU重量体积编辑"
      :width="850"
      :loading="loading"
      @ok="onConfirm"
      @closed="onClosed"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 3}" :wrapper-col="{span: 21}">
      <a-form-item label="SKU或款号">
        <a-input v-model="sku"></a-input>
        <p class="font-mini text-muted">款号如：RBF1007（只填款号会更新这个款的所有SKU），SKU如：RBF1007-BLU-XS-19。多个款号或SKU可用英文逗号分隔。</p>
      </a-form-item>
      <a-form-model-item label="产品">
        <span class="span-item">
          重量(kg)
          <a-input-number v-model="form.weight" :min="0" :max="10" :precision="2" :step="0.1" />
        </span>
        <span class="span-item">
          长(cm)
          <a-input-number v-model="form.length" :min="0" :step="1" />
        </span>
        <span class="span-item">
          宽(cm)
          <a-input-number v-model="form.width" :min="0" :step="1" />
        </span>
        <span class="span-item">
          高(cm)
          <a-input-number v-model="form.height" :min="0" :step="1" />
        </span>
      </a-form-model-item>
    </a-form-model>
  </modal>
</template>

<script>
import { updateWeightVolume } from '@/http/api/stock-product'

export default {
  data () {
    return {
      sku: null,
      form: {
        weight: 0,
        length: 0,
        width: 0,
        height: 0
      },
      loading: false
    }
  },
  methods: {
    open () {
      this.$refs.m.open()
    },
    onConfirm () {
      this.$confirm({
        title: '确认',
        content: '确定要更新吗？',
        onOk: () => {
          this.loading = true
          updateWeightVolume()
            .complete(() => (this.loading = false))
            .success(resp => {
              if (resp.data.length > 0) {
                const msg = resp.data.join(',')
                this.$error({
                  title: '错误',
                  content: `有${resp.data.length}个SKU或款号不存在，其余已经更新成功。不存在的：${msg}`
                })
              } else {
                this.$message.success('更新成功。')
              }
            })
            .send(this.sku, this.form.weight, this.form.length, this.form.width, this.form.height)
        }
      })
    },
    onClosed () {
      this.sku = null
    }
  }
}
</script>

<style lang="less" scoped>
.span-item {
  display: inline-block;
}
.span-item + .span-item {
  margin-left: 30px;
}
</style>
