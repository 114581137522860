<template>
  <communication-editor :visible.sync="visible" title="地址" :loading="loading" @submit="handleSave()">
    <a-form-model ref="form" :model="address" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="标题">
        <a-input v-model="address.title" placeholder="给地址一个标题，方便快速辨别" />
      </a-form-model-item>

      <a-form-model-item label="姓名">
        <a-input v-model="address.name" />
      </a-form-model-item>

      <a-form-model-item label="电话">
        <a-input v-model="address.phone" />
      </a-form-model-item>

      <a-form-model-item label="国家">
        <a-select v-model="address.countryCode"
                  style="width: 100%;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="countryOptionFilter"
                  :allowClear="true">
          <a-select-option :value="item.code" v-for="item in countryOptions" :key="item.code">{{item.ename}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="省份/州">
        <a-input v-model="address.state" />
      </a-form-model-item>

      <a-form-model-item label="城市">
        <a-input v-model="address.city" />
      </a-form-model-item>

      <a-form-model-item label="地址1">
        <a-input v-model="address.addr1" />
      </a-form-model-item>

      <a-form-model-item label="地址2">
        <a-input v-model="address.addr2" />
      </a-form-model-item>

      <a-form-model-item label="邮政编码">
        <a-input v-model="address.postal" />
      </a-form-model-item>

    </a-form-model>
  </communication-editor>
</template>

<script>
import CommunicationEditor from '@/pages/influencer/detail/comm-comp/communication-editor'
import CommunicationMixin from '@/pages/influencer/detail/comm-comp/communication-mixin'
import { TYPE_ADDRESS } from '@/constants/communication-type'
import { getCountries } from '@/http/api/order-delivery'

function createAddress () {
  return {
    title: null,
    country: null,
    countryCode: null,
    state: null,
    stateCode: null,
    city: null,
    addr1: null,
    addr2: null,
    postal: null,
    name: null,
    phone: null
  }
}

export default {
  components: { CommunicationEditor },
  mixins: [CommunicationMixin],
  data () {
    return {
      type: TYPE_ADDRESS,
      address: createAddress(),
      countryOptions: []
    }
  },
  watch: {
    'address.countryCode' (code) {
      const found = this.countryOptions.find(item => item.code === code)
      if (found) {
        this.address.country = found.ename
      }
    }
  },
  computed: {
    content () {
      return JSON.stringify(this.address)
    }
  },
  methods: {
    getContent () {
      return this.content
    },
    onHide () {
      this.address = createAddress()
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
  },
  mounted () {
    getCountries().success(resp => (this.countryOptions = resp.data)).send()
  }
}
</script>

<style scoped>

</style>
