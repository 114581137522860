<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button @click="$refs.shopAddEditor.open()">增加店铺</a-button>
      <a-button @click="handleOpenHdblModal">更新汇兑比例</a-button>
      <a-button @click="handleOpenFeeEditor">佣金算法配置</a-button>
      <a-button @click="handleOpenSyncModal">同步订单</a-button>
    </div>

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="店铺类型">
          <a-select v-model="searchParams.type" style="width: 120px;">
            <a-select-option value="shopify">Shopify</a-select-option>
            <a-select-option value="tt">Tiktok</a-select-option>
            <a-select-option value="order">内部订单</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="关联部门">
          <a-select v-model="searchParams.deptId" style="width: 120px;" :allow-clear="true">
            <a-select-option v-for="d in deptOptions" :key="d.id">{{d.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.enabled" style="width: 120px;">
            <a-select-option :value="null">全部</a-select-option>
            <a-select-option :value="true">可用</a-select-option>
            <a-select-option :value="false">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
          <a-button @click="loadData" type="primary">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.editor.open(record)">编辑</a-button>
        <a-button size="small" @click="navOrderSync(record)">订单同步</a-button>
      </div>
      <status slot="syncEnabled" slot-scope="syncEnabled" :status="syncEnabled" enabledText="同步" disabledText="不同步"></status>
      <status slot="enabled" slot-scope="enabled" :status="enabled"></status>
    </a-table>

    <shop-editor ref="editor" @saved="loadData()"></shop-editor>

    <modal
        ref="shopAddEditor"
        title="绑定店铺"
        :width="500"
        :loading="addShopEditor.loading"
        @ok="handleAddShopEditorConfirm"
        @closed="handleAddShopEditorClosed"
    >
      <a-form-model ref="addShopForm" :model="addShopEditor.form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="类型" class="app_required-input" prop="type">
          <a-select v-model="addShopEditor.form.type" style="width: 100%;">
            <a-select-option value="shopify">Shopify</a-select-option>
            <a-select-option value="tt">Tiktok</a-select-option>
            <a-select-option value="order">内部订单</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="分配部门" prop="deptId">
          <a-select v-model="addShopEditor.form.deptId" style="width: 100%;" :allow-clear="true">
            <a-select-option v-for="d in deptOptions" :key="d.id">{{d.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <div v-if="addShopEditor.form.type === 'tt'">
          <a-form-model-item label="Tiktok类型" class="app_required-input" prop="type">
            <a-select v-model="addShopEditor.form.tiktokShopType" style="width: 100%;">
              <a-select-option value="local">本土</a-select-option>
              <a-select-option value="global">国际</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="店铺编号" class="app_required-input" prop="shopCode">
            <a-input v-model="addShopEditor.form.shopCode" placeholder="抖音编号格式：TT-AA-01（TT-固定）" @blur="onShopCodeInputBlur"></a-input>
          </a-form-model-item>
          <a-form-model-item label="店铺名称" class="app_required-input" prop="name">
            <a-input v-model="addShopEditor.form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="主要货币" prop="currency">
            <a-input v-model="addShopEditor.form.currency" placeholder="USD"></a-input>
          </a-form-model-item>
        </div>

        <div v-if="addShopEditor.form.type === 'shopify'">
          <a-form-model-item label="店铺地址" class="app_required-input" prop="storeUrl">
            <a-input v-model="addShopEditor.form.storeUrl" placeholder="https://xxx.myshopify.com"></a-input>
          </a-form-model-item>
          <a-form-model-item label="API密钥" class="app_required-input" prop="apiKey">
            <a-input v-model="addShopEditor.form.apiKey"></a-input>
          </a-form-model-item>
          <a-form-model-item label="API密码" class="app_required-input" prop="apiSecret">
            <a-input v-model="addShopEditor.form.apiSecret"></a-input>
          </a-form-model-item>
        </div>
      </a-form-model>
    </modal>

    <modal title="汇兑比例批量更新" ref="hdbl" @ok="handleUpdateHdbl" :loading="hdblModal.loading">
      <a-form-model :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="比例">
          <a-input-number v-model="hdblModal.bl" :min="0" :max="1" :precision="2" placeholder="请输入0～1" style="width: 100%;"></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </modal>

    <modal
        ref="syncEditor"
        title="创建同步"
        :width="500"
        :loading="syncEditor.loading"
        @ok="handleSyncEditorOk"
        @closed="handleSyncEditorClosed"
    >
      <a-button @click="handleReloadOrderSync" v-show="syncEditor.dataList.length > 0" style="margin-bottom: 10px;">刷新</a-button>
      <a-table
          v-if="syncEditor.dataList.length > 0"
          size="middle"
          rowKey="id"
          :columns="syncEditor.dataColumns"
          :data-source="syncEditor.dataList"
          :pagination="false"
          :loading="syncEditor.loading"
      >
        <div slot="status" slot-scope="status, record">
          <span v-if="status === 1" class="text-muted">正在同步</span>
          <span v-else-if="status === 2" class="text-success">完成</span>
          <span v-else-if="status === 4" class="text-warning">停止</span>
          <a-tooltip v-else-if="status === 3" placement="top">
            <template slot="title">
              {{record.message}}
            </template>
            <span class="text-danger">错误</span>
          </a-tooltip>
        </div>
      </a-table>
      <a-form-model v-else :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="日期范围">
          <a-range-picker v-model="syncEditor.date" value-format="yyyy-MM-DD" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>

    <fee-config-batch-editor ref="feeEditor" @saved="loadData()"></fee-config-batch-editor>
  </app-page>
</template>

<script>
import { ROUTE_SHOP, ROUTE_SHOP_ORDER_SYNC } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listShops, addShop, updateHdbl } from '@/http/api/shop'
import ShopEditor from './comp/shop-editor'
import { listAllDto } from '@/http/api/dept'
import { createSchedule, listByIdList } from '@/http/api/order-sync-schedule'
import FeeConfigBatchEditor from '@/pages/shop/comp/fee-config-batch-editor'

export default {
  components: { ShopEditor, FeeConfigBatchEditor },
  data () {
    return {
      routeName: ROUTE_SHOP,
      dataColumns: [
        { title: '操作', width: 150, scopedSlots: { customRender: 'action' } },
        { title: '店铺名称', dataIndex: 'name' },
        { title: '店铺编号', dataIndex: 'shopCode' },
        { title: '类型', dataIndex: 'type' },
        { title: '汇兑比例', dataIndex: 'hdbl' },
        { title: '店铺地址', dataIndex: 'storeUrl' },
        { title: '订单同步', dataIndex: 'syncEnabled', scopedSlots: { customRender: 'syncEnabled' } },
        { title: '是否可用', dataIndex: 'enabled', scopedSlots: { customRender: 'enabled' } }
      ],
      dataList: [],
      dataLoading: false,
      addShopEditor: {
        loading: false,
        form: {
          type: 'tt',
          name: null,
          shopCode: null,
          storeUrl: null,
          apiKey: null,
          apiSecret: null,
          orderPrefix: null,
          currency: 'USD',
          tiktokShopType: 'local',
          deptId: null
        }
      },
      selectedRowKeys: [],
      searchParams: {
        type: null,
        enabled: null,
        deptId: null
      },
      deptOptions: [],
      hdblModal: {
        loading: false,
        bl: 0
      },
      syncEditor: {
        date: [],
        loading: false,
        dataList: [],
        dataColumns: [
          { title: '店铺', dataIndex: 'shopName' },
          { title: '已同步', dataIndex: 'syncTotal' },
          { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } }
        ]
      }
    }
  },
  mixins: [AntdTableHeightMixin],
  watch: {
  },
  computed: {
    selectedShops () {
      const arr = []
      for (const s of this.dataList) {
        if (this.selectedRowKeys.includes(s.id)) {
          arr.push(s)
        }
      }
      return arr
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listShops()
        .complete(() => (this.dataLoading = false))
        .success(resp => (this.dataList = resp.data))
        .send(Object.assign({}, this.searchParams))
    },
    navOrderSync (record) {
      this.$router.push({ name: ROUTE_SHOP_ORDER_SYNC, params: { shopId: record.id } })
    },
    handleAddShopEditorConfirm () {
      this.addShopEditor.loading = true
      const isTiktok = this.addShopEditor.form.type === 'tt'
      addShop()
        .complete(() => (this.addShopEditor.loading = false))
        .success(resp => {
          this.$message.success('店铺已添加。')
          this.loadData()
          this.$nextTick(() => (this.$refs.shopAddEditor.close()))
          if (isTiktok) {
            this.$confirm({
              title: '提示',
              content: '店铺添加成功，是否现在去授权？',
              onOk: () => {
                window.open(resp.data)
              }
            })
          }
        })
        .send(this.addShopEditor.form)
    },
    handleAddShopEditorClosed () {
      this.$refs.addShopForm.resetFields()
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleOpenHdblModal () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要更新的店铺。')
      }
      this.hdblModal.bl = 0
      this.$refs.hdbl.open()
    },
    handleUpdateHdbl () {
      const idList = this.selectedRowKeys.join(',')
      this.hdblModal.loading = true
      updateHdbl()
        .complete(() => (this.hdblModal.loading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.$nextTick(() => (this.$refs.hdbl.close()))
          this.loadData()
        })
        .send(idList, this.hdblModal.bl)
    },
    handleOpenSyncModal () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择店铺。')
      } else {
        this.$refs.syncEditor.open()
      }
    },
    handleSyncEditorOk () {
      if (this.syncEditor.dataList.length > 0) return
      this.syncEditor.loading = true
      createSchedule()
        .complete(() => (this.syncEditor.loading = false))
        .success(resp => {
          this.syncEditor.dataList = resp.data
        })
        .send(this.selectedRowKeys.join(','), this.syncEditor.date[0], this.syncEditor.date[1])
    },
    handleSyncEditorClosed () {
      this.syncEditor.dataList = []
    },
    handleReloadOrderSync () {
      const idArr = []
      for (const item of this.syncEditor.dataList) {
        idArr.push(item.id)
      }
      this.syncEditor.loading = true
      listByIdList()
        .complete(() => (this.syncEditor.loading = false))
        .success(resp => {
          const map = {}
          for (const item of resp.data) {
            map[item.id] = item
          }
          for (const item of this.syncEditor.dataList) {
            item.syncTotal = map[item.id].syncTotal
            item.status = map[item.id].status
          }
        })
        .send(idArr)
    },
    onShopCodeInputBlur () {
      if (this.addShopEditor.form.shopCode) {
        this.addShopEditor.form.shopCode = this.addShopEditor.form.shopCode.toUpperCase()
      }
      if (!this.addShopEditor.form.name) {
        this.addShopEditor.form.name = this.addShopEditor.form.shopCode
      }
    },
    handleOpenFeeEditor () {
      if (this.selectedShops.length === 0) {
        return this.$message.warning('请选择店铺。')
      }
      this.$refs.feeEditor.open(this.selectedShops)
    }
  },
  mounted () {
    this.loadData()
    listAllDto().success(resp => (this.deptOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

</style>
