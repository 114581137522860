/**
 * 定义AP，在UI层，程序员调用api时应该只需关心参数和请求回调，也就是预先定义好api，api告诉程序员需要提供哪些参数即可。
 * 固定的，在使用api时你总是能使用固定的5个请求回调函数，也就是http.js工具中说的回调。
 *
 * 如果定义API
 * ------------------------------
 * import http, { defineApi } from ...
 * export const login = defineApi( (config, username, password, validateCode) => {
 *   config.params = {
 *     username: username,
 *     password: password,
 *     validateCode: validateCode
 *   }
 *   http.post('/login', config)
 * })
 *
 * 如何使用API
 * ------------------------------
 * import { login } from ...
 * login()
 *   .complete(() => {
 *     // http的complete回调
 *   })
 *   .success( (resp) => {
 *     console.log(resp) // 在回调中执行相应的业务逻辑
 *   })
 *   .error(() => {
 *     // http的error回调
 *   })
 *   .final(() => {
 *     // http的final回调
 *   })
 *   .config(cfg => { // config函数允许你在发送请求前得到http的配置以便更改或追加一些东西，但是通常建议不要更改原先有的东西，除非你知道自己在做什么。
 *     cfg.requestId = Math.random()
 *   })
 *   .send(username, password, validateCode) // 开发员只关心提供什么参数给接口
 *
 * 调用api后可以在后面接上自己需要的回调，并在最后执行send函数，send函数需要提供api定义的参数。
 * 在defineApi时，你需要传入一个函数，在这个函数中第一个参数是一个config参数，这个config参数实际
 * 上就是开发员写的success, error等回调函数，config后面的参数就是send函数的参数。
 * 上面的链式函数中不需要全部写上，你需要用到哪个就写哪个即可。
 *
 * @param executor
 * @return {Function}
 */
export default function (executor) {
  return function () {
    return {
      success: function (cb) {
        this.cbSuccess = cb
        return this
      },
      error: function (cb) {
        this.cbError = cb
        return this
      },
      complete: function (cb) {
        this.cbComplete = cb
        return this
      },
      final: function (cb) {
        this.cbFinal = cb
        return this
      },
      cancel: function (cb) {
        this.cbCancel = cb
        return this
      },
      config: function (cb) {
        this.config = cb
        return this
      },
      send: function () {
        const config = {
          success: this.cbSuccess,
          error: this.cbError,
          complete: this.cbComplete,
          final: this.cbFinal,
          cancel: this.cbCancel
        }
        if (this.config) {
          this.config(config)
        }
        const args = [config, ...arguments]
        return executor.apply(this, args)
      }
    }
  }
}
