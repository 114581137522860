<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button type="primary" @click="handleDownloadTrackFile" :loading="trackFileDownloading">下载面单</a-button>

      <a-dropdown>
        <a-menu slot="overlay" @click="handleUpdateDeliveryStatus">
          <a-menu-item key="auto">自动</a-menu-item>
          <a-menu-item :key="item.id" v-for="item in statusOptions">{{item.name}}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 更新物流状态 <a-icon type="down" /> </a-button>
      </a-dropdown>

      <a-dropdown>
        <a-menu slot="overlay" @click="handleUpdateDelayPackage">
          <a-menu-item key="true">设置延迟打包</a-menu-item>
          <a-menu-item key="false">取消延迟打包</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 延迟打包 <a-icon type="down" /> </a-button>
      </a-dropdown>

      <a-button @click="handleOpenT17trackRegEditor">注册T17track</a-button>

      <a-button @click="handleNavUpdateTrackingFromFile">上传物流信息</a-button>

      <a-tooltip placement="bottom">
        <template slot="title">
          <span>查询物流平台的跟踪号，更新"未出跟踪号"的订单，并同步到店铺平台。更新时间可能会比较久。</span>
        </template>
        <a-button :loading="trackingNumberUpdating" @click="handleUpdateTrackingNumber" v-authority="$authority.deliveryAdmin">更新物流跟踪号</a-button>
      </a-tooltip>

      <!--
      <a-tooltip placement="bottom">
        <template slot="title">
          <span>先从抖音下载物流回传模版，然后上传到订单系统填充物流运单号。</span>
        </template>
        <a-button @click="handleTiktokDelivery">下载抖音物流回传文件</a-button>
      </a-tooltip>
      -->
    </div>

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams">
        <div>
          <a-form-model-item label="自定标识">
            <a-input v-model="searchParams.customId" placeholder="标题关键字" :allow-clear="true" />
          </a-form-model-item>
          <a-form-model-item label="运单号">
            <a-input v-model="searchParams.trackNo" :allow-clear="true" />
          </a-form-model-item>
          <a-form-model-item label="订单号码">
            <a-input v-model="searchParams.orderNo" :allow-clear="true" style="width: 220px;" />
          </a-form-model-item>
        </div>

        <div>
          <a-form-model-item label="发货状态">
            <a-select v-model="searchParams.status" mode="multiple" style="width: 470px;">
              <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="发货日期">
            <a-range-picker
                            v-model="searchParams.dates"
                            :ranges="dateRanges"
                            value-format="YYYY-MM-DD"
                            style="width: 220px;"
            >
            </a-range-picker>
          </a-form-model-item>
          <a-form-model-item label="T17">
            <a-tooltip placement="top">
              <template slot="title">是否注册了T17Track平台</template>
              <a-select v-model="searchParams.t17track" style="width: 114px;">
                <a-select-option value="all">所有</a-select-option>
                <a-select-option value="reg">已注册</a-select-option>
                <a-select-option value="unReg">未注册</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-model-item>
        </div>

        <div>
          <a-form-model-item label="快递公司">
            <a-select v-model="searchParams.companyId" style="width: 114px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in logisticsCompanyOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="延迟打包">
            <a-select v-model="delayPackageStatus" style="width: 114px;">
              <a-select-option value="all">全部</a-select-option>
              <a-select-option value="yes">是</a-select-option>
              <a-select-option value="no">不是</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="N天未签收">
            <a-tooltip placement="bottom">
              <template slot="title">0表示忽略此条件。</template>
              <a-input-number v-model="searchParams.shipDays" :allow-clear="true" :min="0" style="width: 60px;"/>
            </a-tooltip>
          </a-form-model-item>
          <a-form-model-item label="备注信息">
            <a-input v-model="searchParams.remark" :allow-clear="true" style="width: 220px;" />
          </a-form-model-item>
          <a-form-model-item label="店铺">
            <a-select v-model="searchParams.shopId" style="width: 114px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>
      <div class="buttons">
        <div>
          <a-button @click="reloadData" type="primary">查询</a-button>
          <a-button @click="handleResetSearchParams">重制条件</a-button>
        </div>
        <div>
          <a-button @click="handleExport" style="width: 100%; margin-top: 10px;">导出（最多5000）</a-button>
        </div>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableX }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="action" slot-scope="v, record">
        <div>
          <a-button type="danger"
                    size="small"
                    @click="handleCancelDelivery(record)" :loading="record._loading"
                    v-if="record.status === statusMap.shipped || record.status === statusMap.waitTrack"
          >
            取消
          </a-button>
        </div>

        <!-- deprecated
        <div style="margin-top: 6px;">
          <a-button size="small"
                    v-authority="$authority.skuCost"
                    @click="$refs.deliveryProductEditor.open(record.ordersId, record.deliveryProductItems)">发货项编辑</a-button>
        </div>
        -->
      </div>

      <div slot="status" slot-scope="status">
        <span class="delivery-status" :class="'status-' + status">{{orderDeliveryStatusTextMapping[status]}}</span>
      </div>
      <div slot="trackNo" slot-scope="trackNo, record">
        <div style="cursor: pointer;" type="link" @click="openTrackUrl(trackNo, record.trackUrl)">{{trackNo}}</div>
      </div>
      <div slot="companyId" slot-scope="companyId, record">
        <span v-if="record.supplierOrderDeliveryId">
              厂商自发：{{logisticsCompanyTextMapping[companyId]}} / {{record.supplierOrderDeliverySupplierName}}（{{supplierOrderDeliveryMapping[record.supplierOrderDeliveryStatus]}}）
            </span>
        <span v-else>{{logisticsCompanyTextMapping[companyId]}} / {{record.shippingMethod}}</span>
      </div>
      <div slot="regT17track" slot-scope="regT17track">
        <span v-if="regT17track" class="t17track-reg">17Track</span>
      </div>
      <div slot="items" slot-scope="items">
        <div class="image-item-wrapper">
          <a-tooltip v-for="item in items" :key="item.id">
            <template slot="title">
              <div>SKU：{{item.sku}}</div>
              <div>规格：{{item.variant}}</div>
              <div>厂商：{{item.fromSupplierName}}</div>
              <div>总成本：{{item.cost}}</div>
            </template>
            <div class="image-item small" v-lazy:background-image="item.imageUrl" @click="$imageModal.open(item.imageUrl)">
              <span class="total" :class="{'total-more': item.total > 1}">{{item.total}}</span>
            </div>
          </a-tooltip>
        </div>
      </div>
      <div slot="arrivedTime" slot-scope="time, record">
        {{time}}<span v-if="time" class="text-success font-mini">（{{record | deliveryUseDays}}天）</span>
      </div>
    </a-table>

    <div slot="bottom">
      <div class="bottom-message content-center">
        <div class="block-item">
          运费共计：<span class="font-bold text-danger">{{deliveryFee}}</span>
        </div>
        <div class="block-item" @click="handleSearchExceptionData">
          异常物流：<span class="text-danger font-bold">{{exceptionCount}}</span>
        </div>
        <div class="block-item" @click="handleSearchUnArriveData" style="text-align: center;">
          超过{{unArriveDays}}天未签收：<span class="text-danger font-bold">{{unArriveCount}}</span>
        </div>
      </div>
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <t17-track-reg-editor ref="t17trackRegEditor"></t17-track-reg-editor>
    <delivery-product-editor ref="deliveryProductEditor"></delivery-product-editor>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_DELIVERY, ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import T17TrackRegEditor from './t17track/comp/t17track-reg-editor'
import {
  ORDER_DELIVERY_STATUS_LIST,
  ORDER_DELIVERY_STATUS_MAPPING,
  ORDER_DELIVERY_STATUS_SHIPPED,
  ORDER_DELIVERY_STATUS_WAIT_TRACK,
  ORDER_DELIVERY_STATUS_EXCEPTION, ORDER_DELIVERY_STATUS_CANCEL
} from '@/constants/order-delivery-status'
import { LOGISTICS_COMPANY_MAPPING, LOGISTICS_COMPANY_LIST } from '@/constants/logistics'
import {
  list,
  printLabel,
  getFeeSummary,
  updateDeliveryStatus,
  getCountWithExceptionAndUnArrive,
  cancelDelivery,
  updateTrackingNumber,
  getTiktokShippingData,
  updateDelayPackage,
  exportList,
  getTrackUrlByTrackNo
} from '@/http/api/order-delivery'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import DeliveryProductEditor from '@/pages/order/detail/comp/delivery-product-editor'
import { SUPPLIER_ORDER_DELIVERY_MAPPING } from '@/constants/supplier-order-delivery-status'
import { listShopOptionsBySelfDept } from '@/http/api/shop'

function buildSearchParams () {
  const today = moment().format('YYYY-MM-DD')
  return {
    dates: [today, today],
    status: [],
    trackNo: null,
    orderNo: null,
    customId: null,
    createTimeStart: null,
    createTimeEnd: null,
    shipDays: 0,
    companyId: null,
    remark: null,
    delayPackage: null,
    shopId: null,
    t17track: null
  }
}

export default {
  components: { T17TrackRegEditor, DeliveryProductEditor },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    const defaultSearchParams = buildSearchParams()
    return {
      routeName: ROUTE_ORDER_DELIVERY,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', dataIndex: 'action', width: 100, scopedSlots: { customRender: 'action' }, fixed: 'left' },
        { title: '客户单号', dataIndex: 'orderNo', width: 150, fixed: 'left' },
        { title: '发货日期', dataIndex: 'createTime', width: 120 },
        { title: '运单号', dataIndex: 'trackNo', width: 160, scopedSlots: { customRender: 'trackNo' } },
        { title: '物流', dataIndex: 'companyId', width: 260, scopedSlots: { customRender: 'companyId' } },
        { title: '发货清单', dataIndex: 'deliveryProductItems', width: 160, scopedSlots: { customRender: 'items' } },
        { title: '备注', dataIndex: 'remark', width: 250 },
        { title: '状态', dataIndex: 'status', width: 100, scopedSlots: { customRender: 'status' } },
        { title: '签收日期', dataIndex: 'arrivedTime', width: 150, scopedSlots: { customRender: 'arrivedTime' } },
        { title: '运费', dataIndex: 'fee', width: 80 },
        { title: '重量kg', dataIndex: 'weight', width: 80 },
        { title: 'T17Track', dataIndex: 'regT17track', width: 80, scopedSlots: { customRender: 'regT17track' } },
        { title: '客户国家', dataIndex: 'countryCode', width: 80 },
        { title: '客户', dataIndex: 'customerName', width: 140 },
        { title: '店铺', dataIndex: 'shopName', width: 100 }
      ],
      searchParams: defaultSearchParams,
      statusOptions: ORDER_DELIVERY_STATUS_LIST,
      orderDeliveryStatusTextMapping: ORDER_DELIVERY_STATUS_MAPPING,
      logisticsCompanyOptions: LOGISTICS_COMPANY_LIST,
      logisticsCompanyTextMapping: LOGISTICS_COMPANY_MAPPING,
      shopOptions: [],
      selectedRowKeys: [],
      trackFileDownloading: false,
      deliveryFee: null,
      updateDeliveryStatusLoading: false,
      exceptionCount: 0,
      unArriveCount: 0,
      // 发货后超过10天未签收的记录查询条件参数
      unArriveDays: 10,
      statusMap: {
        shipped: ORDER_DELIVERY_STATUS_SHIPPED,
        waitTrack: ORDER_DELIVERY_STATUS_WAIT_TRACK,
        cancel: ORDER_DELIVERY_STATUS_CANCEL,
        exception: ORDER_DELIVERY_STATUS_EXCEPTION
      },
      supplierOrderDeliveryMapping: SUPPLIER_ORDER_DELIVERY_MAPPING,
      trackingNumberUpdating: false,
      delayPackageStatus: 'all',
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        一周前: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        两周前: [moment().subtract(2, 'weeks').startOf('week'), moment().subtract(2, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')]
      },
      trackUrlLoading: false
    }
  },
  computed: {
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    },
    selectedRows () {
      const arr = []
      for (const item of this.dataList) {
        if (this.selectedRowKeys.includes(item.id)) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  filters: {
    deliveryUseDays (data) {
      const createTime = moment(data.createTime)
      const arrivedTime = moment(data.arrivedTime)
      return arrivedTime.diff(createTime, 'days')
    }
  },
  methods: {
    createLoadParams () {
      const p = this.buildRequestParams(this.searchParams)
      p.status = [...this.searchParams.status]
      if (p.dates && p.dates.length === 2) {
        p.createTimeStart = p.dates[0] + ' 00:00:00'
        p.createTimeEnd = p.dates[1] + ' 23:59:59'
      } else {
        p.createTimeStart = null
        p.createTimeEnd = null
      }
      delete p.dates
      if (this.delayPackageStatus === 'yes') {
        p.delayPackage = true
      } else if (this.delayPackageStatus === 'no') {
        p.delayPackage = false
      } else {
        p.delayPackage = null
      }
      return p
    },
    loadData () {
      const p = this.createLoadParams()
      this.dataLoading = true
      this.selectedRowKeys = []
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
      this.loadFee(p)
    },
    handleExport () {
      window.open(exportList(this.createLoadParams()))
    },
    handleSearchExceptionData () {
      this.searchParams = buildSearchParams()
      this.searchParams.status = [ORDER_DELIVERY_STATUS_EXCEPTION]
      this.reloadData()
    },
    handleSearchUnArriveData () {
      this.searchParams = buildSearchParams()
      this.searchParams.shipDays = this.unArriveDays
      this.reloadData()
    },
    handleNavUpdateTrackingFromFile () {
      this.$router.push({ name: ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE })
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleDownloadTrackFile () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warn('请选择发货记录。')
      }
      this.trackFileDownloading = true
      printLabel()
        .complete(() => (this.trackFileDownloading = false))
        .success(resp => {
          window.open(resp.data)
        })
        .send(this.selectedRowKeys)
    },
    loadFee (p) {
      getFeeSummary()
        .success(resp => {
          this.deliveryFee = resp.data
        })
        .send(p)
    },
    handleUpdateDelayPackage (e) {
      const status = e.key
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      this.pageLoading = true
      updateDelayPackage()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.loadData()
        })
        .send(this.selectedRowKeys.join(','), status)
    },
    handleUpdateDeliveryStatus (e) {
      const status = e.key
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      const func = () => {
        this.updateDeliveryStatusLoading = true
        updateDeliveryStatus()
          .complete(() => (this.updateDeliveryStatusLoading = false))
          .success(resp => {
            const total = this.selectedRowKeys.length
            let successTotal = 0
            let errorTotal = 0
            const errorList = []
            for (const key of Object.keys(resp.data)) {
              if (resp.data[key] === 'success') {
                successTotal++
              } else {
                errorTotal++
                const item = this.dataList.find(item => item.id === key)
                if (item) {
                  errorList.push(item.orderNo + '：' + resp.data[key])
                }
              }
            }

            Modal.success(({
              title: '更新结果',
              zIndex: 9999,
              content: function (h) {
                const msgArr = []
                msgArr.push(h('div', `共更新${total}个，成功${successTotal}个，失败${errorTotal}个。`))
                for (const errorItem of errorList) {
                  msgArr.push(h('div', { style: { marginLeft: 10, color: '#9d1212', fontSize: '12px' } }, errorItem))
                }
                return h('div', {}, msgArr)
              },
              onOk: () => {
                this.loadData()
              }
            }))
          })
          .send(this.selectedRowKeys, status)
      }
      if (status === 'auto') {
        func()
      } else {
        this.$confirm({
          title: '确认',
          content: `确定要将选中的【${this.selectedRowKeys.length}】行记录的状态更新为【${ORDER_DELIVERY_STATUS_MAPPING[status]}】吗？`,
          onOk: func
        })
      }
    },
    handleOpenT17trackRegEditor () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      const arr = []
      for (const item of this.selectedRows) {
        if (item.trackNo) {
          arr.push({
            trackNo: item.trackNo,
            tag: item.orderNo
          })
        }
      }
      this.$refs.t17trackRegEditor.open(arr)
    },
    loadCountData () {
      getCountWithExceptionAndUnArrive()
        .success(resp => {
          this.exceptionCount = resp.data.exception
          this.unArriveCount = resp.data.unArrive
        })
        .send(this.unArriveDays)
    },
    handleCancelDelivery (item) {
      this.$confirm({
        title: '确认',
        content: `确定要取消【${item.orderNo}】的发货吗？`,
        onOk: () => {
          item._loading = true
          cancelDelivery()
            .complete(() => (item._loading = false))
            .success(resp => {
              this.loadData()
            })
            .send(item.id)
        }
      })
    },
    handleUpdateTrackingNumber () {
      this.$confirm({
        title: '确认',
        content: '确定要开始更新物流跟踪号吗？',
        onOk: () => {
          this.trackingNumberUpdating = true
          updateTrackingNumber()
            .complete(() => (this.trackingNumberUpdating = false))
            .success(() => {
              this.$message.success('更新完成，请刷新检查。')
            })
            .send()
        }
      })
    },
    handleTiktokDelivery () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.error('请选择行。')
      }
      window.open(getTiktokShippingData(this.selectedRowKeys))
    },
    handleResetSearchParams () {
      this.searchParams = buildSearchParams()
      this.$message.success('成功重制条件，请查询。')
    },
    openTrackUrl (trackNo, trackUrlOption) {
      if (trackUrlOption) {
        window.open(trackUrlOption)
      } else if (trackNo && !this.trackUrlLoading) {
        this.trackUrlLoading = true
        getTrackUrlByTrackNo()
          .complete(() => (this.trackUrlLoading = false))
          .success(resp => {
            if (resp.data) {
              window.open(resp.data)
            } else {
              this.$message.error('没有物流跟踪地址，请将跟踪号注册到T17Track。')
            }
          })
          .send(trackNo)
      }
    }
  },
  mounted () {
    this.loadData()
    this.loadCountData()
    listShopOptionsBySelfDept().success(resp => (this.shopOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

.delivery-status {
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 4px;
}
.status-shipped {
  background-color: #0071f6;
}
.status-arrived {
  background-color: #0c9700;
}
.status-ex {
  background-color: #ce0b0b;
}
.status-exSolved {
  color: #0c9700;
  background-color: #f6c1c1;
}
.status-waitTrack {
  background-color: #797979;
}
.status-cancel {
  background-color: #ee9100;
}

.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 1;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .total-more {
    background-color: #bb3e30;
  }
}

.t17track-reg {
  font-size: 12px;
  color: #fff;
  background-color: #bb3e30;
  padding: 4px 6px;
  border-radius: 5px;
}

.bottom-message {
  display: flex;
  justify-content: center;
  .block-item {
    margin: 0 20px;
    cursor: pointer;
  }
}
</style>
