import kit from '@/utils/kit'

export const USER_TYPE_ADMIN = 'admin'
export const USER_TYPE_SUPPLIER = 'supplier'
export const USER_TYPE_PARTNER = 'tp'

export const USER_TYPE_MAPPING = {
  [USER_TYPE_ADMIN]: '管理员',
  [USER_TYPE_SUPPLIER]: '供应商',
  [USER_TYPE_PARTNER]: '合作公司'
}

export const USER_TYPE_LIST = kit.obj.toArray(USER_TYPE_MAPPING)
