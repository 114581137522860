<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="库存盘点编辑"
    @save="save"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="标题" prop="title" class="app_required-input">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="仓库名称" prop="warehouseName" class="app_required-input">
        <a-select v-model="form.warehouseId" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </drawer>
</template>

<script>
import { addStockCheck, updateStockCheck } from '@/http/api/stock-check'
import { listWarehouseEnabled } from '@/http/api/warehouse'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: {
        id: null,
        title: null,
        status: 0,
        warehouseId: null
      },
      warehouseOptions: []
    }
  },
  watch: {
    'form.id' (id) {
      if (id) {
      }
    }
  },
  computed: {
    isUpdate () {
      return !!this.form.id
    }
  },
  methods: {
    open (model) {
      this.model = model
      this.visible = true
      if (model) {
        this.$nextTick(() => {
          this.form.id = model.id
          this.form.title = model.title
          this.form.status = model.status
          this.form.warehouseId = model.warehouseId
        })
      } else {
        this.form.status = 0
      }
      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }
    },
    onClose () {
      this.$refs.form.resetFields()
      this.form.id = null
      this.model = null
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? updateStockCheck() : addStockCheck()
      const p = Object.assign({}, this.form)
      p.status = p.status ? 1 : 0
      api
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(resp => {
          this.form.id = resp.data.id
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
          this.visible = false
        })
        .send(p)
    }
  }
}
</script>

<style scoped>

</style>
