<template>
  <div class="section">
    <div class="section-head">
      <div class="order-icon">
        <a-icon type="unordered-list" />
      </div>
      <div class="section-name" :class="{'disabled': !section.sectionEnabled}">{{index}}. {{section.sectionName}}</div>
      <div class="quick-content">
        <div class="text-content" v-if="section.getContentType() === 'text'">{{section.getHeaderDisplay()}}</div>
        <div class="image-content" v-else>
          <div class="image-item cover" v-for="url in section.getHeaderDisplay().splice(0,4)" :key="url" :style="{'background-image': `url(${url})`}"></div>
        </div>
      </div>
      <div class="toolbar">
        <a-button size="small" icon="setting" @click="section.generalVisible = !section.generalVisible"></a-button>
        <a-button size="small" type="danger" icon="delete" @click="$emit('remove', section)"></a-button>
        <a-button size="small" :icon="section.sectionExpand ? 'up' : 'down'" @click="section.sectionExpand = !section.sectionExpand"></a-button>
        <a-button size="small" :type="section.sectionEnabled ? 'default' : 'danger'" :icon="section.sectionEnabled ? 'eye' : 'eye-invisible'" @click="section.sectionEnabled = !section.sectionEnabled"></a-button>
      </div>
    </div>

    <div class="section-body" :class="{'expand': section.sectionExpand}">
      <!-- 公共设置部分 -->
      <div class="box-config" v-show="section.generalVisible">
        <div class="info-detail">
          <div class="detail-item">
            <label>背景颜色</label>
            <span>
              <color-picker :color.sync="section.generalBackgroundColor" />
            </span>
          </div>
          <div class="detail-item">
            <label>文字颜色</label>
            <span>
              <color-picker :color.sync="section.generalTextColor" />
            </span>
          </div>
        </div>
        <div class="box" style="margin-top: 20px;">
          <div class="margin">
            <h3 class="content-center">外间距 px</h3>
            <ul>
              <li></li>
              <li><a-input-number v-model="section.generalMarginTop" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalMarginLeft" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalMarginRight" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalMarginBottom" style="width: 100%;" /></li>
              <li></li>
            </ul>
          </div>
          <div style="width: 30px;"></div>
          <div class="padding">
            <h3 class="content-center">内间距 px</h3>
            <ul>
              <li></li>
              <li><a-input-number v-model="section.generalPaddingTop" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalPaddingLeft" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalPaddingRight" style="width: 100%;" /></li>
              <li></li>
              <li><a-input-number v-model="section.generalPaddingBottom" style="width: 100%;" /></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 具体的section模块 -->
      <div>
        <section-banner v-if="section.sectionType === 'banner'" :section="section"></section-banner>
        <section-paragraph v-else-if="section.sectionType === 'paragraph'" :section="section"></section-paragraph>
        <section-product v-else-if="section.sectionType === 'product'" :section="section"></section-product>
        <section-button v-else-if="section.sectionType === 'button'" :section="section"></section-button>
        <section-image v-else-if="section.sectionType === 'image'" :section="section"></section-image>
        <section-image-list v-else-if="section.sectionType === 'imageList'" :section="section"></section-image-list>
        <section-menu v-else-if="section.sectionType === 'menu'" :section="section"></section-menu>
        <section-message-bar v-else-if="section.sectionType === 'messageBar'" :section="section"></section-message-bar>
        <section-promotion-block v-else-if="section.sectionType === 'promotion'" :section="section"></section-promotion-block>
        <section-single-product v-else-if="section.sectionType === 'singleProduct'" :section="section"></section-single-product>
      </div>
    </div>
  </div>
</template>

<script>
import Section from '../model/section'
import SectionBanner from './banner'
import SectionParagraph from './paragraph'
import SectionProduct from './product'
import SectionButton from './button'
import SectionImage from './image'
import SectionImageList from './image-list'
import SectionMenu from './menu'
import SectionMessageBar from './message-bar'
import SectionPromotionBlock from './promotion-block'
import SectionSingleProduct from './single-product'

export default {
  components: {
    SectionBanner,
    SectionParagraph,
    SectionProduct,
    SectionButton,
    SectionImage,
    SectionImageList,
    SectionMenu,
    SectionMessageBar,
    SectionPromotionBlock,
    SectionSingleProduct
  },
  props: {
    index: { type: Number, default: 1 },
    section: { type: Section, required: true }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.section {
  margin-top: 20px;
  border: solid 1px #ddd;
  .section-head {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    border-bottom: solid 1px #ddd;
    background-color: #f2f2f2;
    .order-icon {
      font-size: 16px;
      cursor: move;
      margin-right: 10px;
    }
    .section-name {
      font-weight: bold;
      width: 80px;
      &.disabled {
        text-decoration: line-through;
      }
    }
    .quick-content {
      flex: 1;
      padding: 0 10px;
      .text-content {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
      }
      .image-content {
        display: flex;
        .image-item + .image-item {
          margin-left: 10px;
        }
        .image-item {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .section-body {
    position: relative;
    min-height: 100px;
    padding: 8px 10px;
    display: none;
    &.expand {
      display: block;
    }
  }

  .box-config {
    padding: 10px;
    margin-bottom: 16px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,.3);
    .close {
      display: inline-block;
      font-size: 13px;
      cursor: pointer;
    }
    .box {
      display: flex;
      .margin, .padding {
        flex: .5;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          li {
            padding: 0 5px;
          }
        }
      }
    }
  }
}
</style>
