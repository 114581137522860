<!-- 损益表 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :use-tcb-layout="false">

    <a-button slot="toolbar" @click="loadData" icon="search" :loading="dataLoading">查询</a-button>
    <a-button slot="toolbar" @click="handleDownloadReport" icon="search" :loading="dataLoading">导出</a-button>
    <a-button slot="toolbar" @click="navUpdate">更新损益表</a-button>

    <div class="top-container">
      <div class="segment-container">
        <a-tooltip placement="bottomLeft">
          <template slot="title">选中要统计的维度，可以拖拽维度的顺序，确认后点击查询</template>
          <label>维度</label>
        </a-tooltip>
        <draggable
            tag="div"
            v-model="segments"
            v-bind="segmentsDrag.dragOptions"
            @start="segmentsDrag.drag = true"
        >
          <transition-group tag="ul" type="transition" :name="!segmentsDrag.drag ? 'flip-list' : null" class="segment-list sortable-list">
            <li class="segment-item" v-for="s in segments" :key="s.column">
              <a-checkbox v-model="s.checked"></a-checkbox> <span class="segment-name">{{s.name}}</span>
            </li>
          </transition-group>
        </draggable>
      </div>

      <a-form-model layout="inline" :model="searchParams">
        <div>
          <a-form-model-item label="日期">
            <a-range-picker v-model="searchParams.date" value-format="yyyy-MM-DD" :ranges="dateRanges" style="width: 220px;" />
          </a-form-model-item>
          <a-form-model-item label="对比日期">
            <a-range-picker v-model="searchParams.compareDate" value-format="yyyy-MM-DD" :ranges="dateRanges" style="width: 220px;" />
          </a-form-model-item>
          <a-form-model-item label="款号">
            <a-input v-model="searchParams.styleNumber" placeholder="如：PJF1030" :allow-clear="true" style="width: 220px;"></a-input>
          </a-form-model-item>
        </div>

        <div>
          <a-form-model-item label="店铺">
            <a-select v-model="searchParams.shopId" mode="multiple" :max-tag-count="1" :maxTagTextLength="12" style="width: 220px;">
              <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="产品类别">
            <a-select v-model="searchParams.category" mode="multiple" :max-tag-count="1" :maxTagTextLength="12" style="width: 220px;">
              <a-select-option :value="item" v-for="item in categoryOptions" :key="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="颜色">
            <a-select v-model="searchParams.color" mode="multiple" :max-tag-count="1" :maxTagTextLength="12" style="width: 220px;">
              <a-select-option :value="item" v-for="item in colorOptions" :key="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>

    </div>

    <div class="text-muted font-mini profit-desc">
      <p>实销额 = 销售额 - 折扣 - 退款 - 争议退款 - 争议手续费</p>
      <p>毛利 = 实销额 - 采购成本 - 物流费用 - 汇兑损益 - 包材成本</p>
      <p>利润 = 毛利 - 支付手续费 - 佣金</p>
    </div>


    <a-table
        class="profit-table"
        size="middle"
        rowKey="groupKey"
        :columns="dataColumns"
        :data-source="dataList"
        :scroll="{ y: 400, x: tableX }"
        :pagination="false"
        :loading="dataLoading"
    >
      <div slot="salePrice" slot-scope="row">
        <span>{{row.salePrice}}</span>
        <compare-info property-name="salePrice" :profit="row"></compare-info>
      </div>

      <div slot="realSalePrice" slot-scope="row">
        <span>{{row.realSalePrice}}</span>
        <compare-info property-name="realSalePrice" :profit="row"></compare-info>
      </div>

      <div slot="shippingPrice" slot-scope="row">
        <span>{{row.shippingPrice}}</span>
        <compare-info property-name="shippingPrice" :profit="row"></compare-info>
      </div>

      <div slot="discountPrice" slot-scope="row">
        <span>{{row.discountPrice}}</span>
        <compare-info property-name="discountPrice" :profit="row"></compare-info>
      </div>

      <div slot="purchasePrice" slot-scope="row">
        <span>{{row.purchasePrice}}</span>
        <compare-info property-name="purchasePrice" :profit="row"></compare-info>
      </div>

      <div slot="freighPriceLocal" slot-scope="row">
        <span>{{row.freighPriceLocal}}</span>
        <compare-info property-name="freighPriceLocal" :profit="row"></compare-info>
      </div>

      <div slot="freightPriceHead" slot-scope="row">
        <span>{{row.freightPriceHead}}</span>
        <compare-info property-name="freightPriceHead" :profit="row"></compare-info>
      </div>

      <div slot="freightPriceTail" slot-scope="row">
        <span>{{row.freightPriceTail}}</span>
        <compare-info property-name="freightPriceTail" :profit="row"></compare-info>
      </div>

      <div slot="payFee" slot-scope="row">
        <span>{{row.payFee}}</span>
        <compare-info property-name="payFee" :profit="row"></compare-info>
      </div>

      <div slot="refundPrice" slot-scope="row">
        <span>{{row.refundPrice}}</span>
        <compare-info property-name="refundPrice" :profit="row"></compare-info>
      </div>

      <div slot="chargebackPrice" slot-scope="row">
        <span>{{row.chargebackPrice}}</span>
        <compare-info property-name="chargebackPrice" :profit="row"></compare-info>
      </div>

      <div slot="chargebackFee" slot-scope="row">
        <span>{{row.chargebackFee}}</span>
        <compare-info property-name="chargebackFee" :profit="row"></compare-info>
      </div>

      <div slot="dryj" slot-scope="row">
        <span>{{row.dryj}}</span>
        <compare-info property-name="dryj" :profit="row"></compare-info>
      </div>

      <div slot="bccb" slot-scope="row">
        <span>{{row.bccb}}</span>
        <compare-info property-name="bccb" :profit="row"></compare-info>
      </div>

      <div slot="hdsy" slot-scope="row">
        <span>{{row.hdsy}}</span>
        <compare-info property-name="hdsy" :profit="row"></compare-info>
      </div>

      <div slot="profit" slot-scope="row">
        <span :class="{'text-success': row.profit > 0, 'text-danger': row.profit < 0}">{{row.profit}}</span>
        <compare-info property-name="profit" :profit="row"></compare-info>
      </div>

      <div slot="lirun" slot-scope="row">
        <span :class="{'text-success': row.lirun > 0, 'text-danger': row.lirun < 0}">{{row.lirun}}</span>
        <compare-info property-name="lirun" :profit="row"></compare-info>
      </div>

      <div slot="profitPercent" slot-scope="row">
        <span :class="{'text-success': row.profitPercent > 0, 'text-danger': row.profitPercent < 0}">{{row.profitPercent}} %</span>
        <compare-info property-name="profitPercent" :profit="row" :is-percent="true"></compare-info>
      </div>
    </a-table>

    <div ref="lineChartContainer" class="line-chart-container">
      <div style="margin-left: 50px;" v-show="lineChart.data.length > 0">
        数据趋势：
        <a-select v-model="lineChart.numberValue" style="width: 130px;">
          <a-select-option value="salePrice">销售额</a-select-option>
          <a-select-option value="realSalePrice">实销额</a-select-option>
          <a-select-option value="total">销量</a-select-option>
          <a-select-option value="purchasePrice">采购成本</a-select-option>
          <a-select-option value="profit">毛利</a-select-option>
          <a-select-option value="lirun">利润</a-select-option>
        </a-select>
      </div>
      <line-chart ref="lineChart" :data="lineChart.data" v-show="lineChart.data.length > 0"></line-chart>
    </div>
  </app-page>
</template>

<script>
import kit from '@/utils/kit'
import moment from 'moment'
import { ROUTE_ANALYSIS_PROFIT_LOSS, ROUTE_PROFIT_LOSS_SYNC } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { getReport, getReportForLineChart, getReportDownloadUrl } from '@/http/api/profit-loss'
import { listShopOptionsBySelfDept } from '@/http/api/shop'
import { getColorOptions, getCategoryOptions } from '@/http/api/stock-product'
import CompareInfo from './compare-info'
import Draggable from 'vuedraggable'
import LineChart from '@/charts/line'
import { userStore } from '@/store'
import { USER_TYPE_PARTNER } from '@/constants/user-type'

export default {
  components: { CompareInfo, Draggable, LineChart },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    const segments = []
    if (userStore.state.type !== USER_TYPE_PARTNER) {
      segments.push({ column: 'partner', prop: 'partner', width: 150, name: '客户', checked: true })
    }
    segments.push(...[
      { column: 'shop_name', prop: 'shopName', width: 150, name: '店铺', checked: segments.length === 0 },
      { column: 'category', name: '产品类别', width: 150, checked: false },
      { column: 'order_no', prop: 'orderNo', name: '订单', width: 100, checked: false },
      { column: 'color', name: '颜色', width: 100, checked: false },
      { column: 'size', name: '尺码', width: 70, checked: false },
      { column: 'momme', name: '姆数', width: 70, checked: false },
      { column: 'sku', name: 'SKU', width: 170, checked: false }
    ])

    return {
      routeName: ROUTE_ANALYSIS_PROFIT_LOSS,
      dataColumns: [],
      dataList: [],
      dataLoading: false,
      searchParams: {
        date: [],
        compareDate: [],
        shopId: [],
        category: [],
        color: [],
        styleNumber: null,
        groupByColumns: []
      },
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        一周前: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        两周前: [moment().subtract(2, 'weeks').startOf('week'), moment().subtract(2, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        一个月前: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        两个月前: [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')]
      },
      shopOptions: [],
      colorOptions: [],
      categoryOptions: [],
      /**
       * 损益表查询维度
       */
      segments: segments,
      segmentsDrag: {
        dragOptions: { handle: '.segment-name' },
        drag: false
      },
      lineChart: {
        // 折线图的数据
        data: [],
        numberValue: 'salePrice',
        startDate: null,
        endDate: null,
        // 原始报告数据
        originReportData: {}
      }
    }
  },
  filters: {
    price (v) {
      return kit.str.fmtCurrency(v, 2)
    }
  },
  computed: {
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    },
    checkedSegments () {
      return this.segments.filter(item => item.checked)
    }
  },
  watch: {
    'lineChart.numberValue' () {
      this.buildLineChartData()
    }
  },
  methods: {
    getDataDefaultColumns () {
      const colWidth = 110
      const sorter = function (name) {
        return (a, b) => a[name] - b[name]
      }
      return [
        { title: '销量', dataIndex: 'total', width: colWidth, sorter: sorter('total') },
        { title: '销售额', scopedSlots: { customRender: 'salePrice' }, width: colWidth, sorter: sorter('salePrice') },
        { title: '实销额', scopedSlots: { customRender: 'realSalePrice' }, width: colWidth, sorter: sorter('realSalePrice') },
        { title: '客户运费', scopedSlots: { customRender: 'shippingPrice' }, width: colWidth, sorter: sorter('shippingPrice') },
        { title: '折扣', scopedSlots: { customRender: 'discountPrice' }, width: colWidth, sorter: sorter('discountPrice') },
        { title: '采购成本', scopedSlots: { customRender: 'purchasePrice' }, width: colWidth, sorter: sorter('purchasePrice') },
        { title: '本地运费', scopedSlots: { customRender: 'freightPriceLocal' }, width: colWidth, sorter: sorter('freightPriceLocal') },
        { title: '头程运费', scopedSlots: { customRender: 'freightPriceHead' }, width: colWidth, sorter: sorter('freightPriceHead') },
        { title: '尾程运费', scopedSlots: { customRender: 'freightPriceTail' }, width: colWidth, sorter: sorter('freightPriceTail') },
        { title: '平台佣金', scopedSlots: { customRender: 'payFee' }, width: colWidth, sorter: sorter('payFee') },
        { title: '退款', scopedSlots: { customRender: 'refundPrice' }, width: colWidth, sorter: sorter('refundPrice') },
        { title: '争议扣款', scopedSlots: { customRender: 'chargebackPrice' }, width: colWidth, sorter: sorter('chargebackPrice') },
        { title: '争议手续费', scopedSlots: { customRender: 'chargebackFee' }, width: colWidth, sorter: sorter('chargebackFee') },
        { title: '达人佣金', scopedSlots: { customRender: 'dryj' }, width: colWidth, sorter: sorter('dryj') },
        { title: '包材成本', scopedSlots: { customRender: 'bccb' }, width: colWidth, sorter: sorter('bccb') },
        { title: '汇兑损益', scopedSlots: { customRender: 'hdsy' }, width: colWidth, sorter: sorter('hdsy') },
        { title: '毛利', scopedSlots: { customRender: 'profit' }, width: colWidth, fixed: 'right', sorter: sorter('profit') },
        { title: '利润', scopedSlots: { customRender: 'lirun' }, width: colWidth, fixed: 'right', sorter: sorter('lirun') },
        { title: '利润率', scopedSlots: { customRender: 'profitPercent' }, width: colWidth, fixed: 'right', sorter: sorter('profitPercent') }
      ]
    },
    generateSearchParams () {
      const p = Object.assign({}, this.searchParams)
      if (p.date.length === 2) {
        p.startDate = p.date[0]
        p.endDate = p.date[1]
        delete p.date
      } else {
        return this.$message.warning('请选择日期。')
      }
      if (p.compareDate.length === 2) {
        p.compareStartDate = p.compareDate[0]
        p.compareEndDate = p.compareDate[1]
        delete p.compareDate
      }

      p.groupByColumns = []
      for (const s of this.checkedSegments) {
        p.groupByColumns.push(s.column)
      }

      return p
    },
    loadData () {
      const p = this.generateSearchParams()
      this.dataLoading = true
      const segments = [...this.checkedSegments]
      getReport()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const columns = []
          let firstSegName
          let i = 0
          for (const seg of segments) {
            if (i++ === 0) {
              firstSegName = seg.prop || seg.column
            }
            columns.push({ title: seg.name, dataIndex: seg.prop || seg.column, width: seg.width, fixed: 'left' })
          }
          columns.push(...this.getDataDefaultColumns())
          this.dataColumns = columns

          const report = resp.data
          const mainProfits = report.profits
          const compareMap = {}
          if (report.compare) {
            for (const c of report.compare) {
              compareMap[c.groupKey] = c
            }
          }
          i = 0
          for (const p of mainProfits) {
            if (i++ === 0) {
              p[firstSegName] = '汇总'
              p.isSummary = true
            }
            const com = compareMap[p.groupKey]
            p.compare = {}
            p.compare.salePrice = com ? com.salePrice : null
            p.compare.realSalePrice = com ? com.realSalePrice : null
            p.compare.shippingPrice = com ? com.shippingPrice : null
            p.compare.discountPrice = com ? com.discountPrice : null
            p.compare.purchasePrice = com ? com.purchasePrice : null
            p.compare.freightPriceHead = com ? com.freightPriceHead : null
            p.compare.freightPriceTail = com ? com.freightPriceTail : null
            p.compare.payFee = com ? com.payFee : null
            p.compare.refundPrice = com ? com.refundPrice : null
            p.compare.chargebackPrice = com ? com.chargebackPrice : null
            p.compare.chargebackFee = com ? com.chargebackFee : null
            p.compare.freightPrice = com ? com.freightPrice : null
            p.compare.allRefund = com ? com.allRefund : null
            p.compare.dryj = com ? com.dryj : null
            p.compare.bccb = com ? com.bccb : null
            p.compare.hdsy = com ? com.hdsy : null
            p.compare.profit = com ? com.profit : null
            p.compare.lirun = com ? com.lirun : null
            p.compare.profitPercent = com ? com.profitPercent : null
          }
          this.dataList = mainProfits

          this.loadLineChartData()
        })
        .send(p)
    },
    handleDownloadReport () {
      window.open(getReportDownloadUrl(this.generateSearchParams()))
    },
    navUpdate () {
      this.$router.push({ name: ROUTE_PROFIT_LOSS_SYNC })
    },
    loadLineChartData () {
      if (this.checkedSegments.length === 1) {
        const p = this.generateSearchParams()
        this.lineChart.startDate = p.startDate
        this.lineChart.endDate = p.endDate
        getReportForLineChart()
          .success(resp => {
            const map = {}
            for (const item of resp.data.profits) {
              const segment = item[this.checkedSegments[0].prop]
              if (segment) {
                const mapByOrderDate = map[segment] || {}
                mapByOrderDate[item.orderDate] = item
                map[segment] = mapByOrderDate
              }
            }
            /*
              {
                segment: {
                  orderDate: Object
                }
              }
             */
            this.lineChart.originReportData = map
            this.buildLineChartData()
          })
          .send(p)
      } else {
        this.lineChart.data = []
        this.lineChart.originReportData = []
        this.buildLineChartData()
      }
    },
    buildLineChartData () {
      /*
        data = [
          {
            category: segment,
            values: [ { timePoint: date, value: number } ]
          }
        ]
       */
      const numberValueProp = this.lineChart.numberValue
      const chartData = []
      for (const segment of Object.keys(this.lineChart.originReportData)) {
        const dto = {
          category: segment,
          values: []
        }
        chartData.push(dto)
        const mapByOrderDate = this.lineChart.originReportData[segment]
        const startDate = moment(this.lineChart.startDate)
        const endDate = moment(this.lineChart.endDate)
        while (true) {
          if (startDate.isAfter(endDate)) {
            break
          }
          const date = startDate.format('yyyy-MM-DD')
          const valueItem = { timePoint: startDate.format('yyMMDD'), value: 0 }
          const value = mapByOrderDate[date]
          if (value) {
            valueItem.value = value[numberValueProp]
          }
          dto.values.push(valueItem)
          startDate.add(1, 'days')
        }
      }
      this.lineChart.data = chartData
    }
  },
  mounted () {
    listShopOptionsBySelfDept().success(resp => (this.shopOptions = resp.data)).send()
    getColorOptions().success(resp => (this.colorOptions = resp.data)).send()
    getCategoryOptions().success(resp => (this.categoryOptions = resp.data)).send()
    const dates = this.dateRanges['这个月']
    const compareDates = this.dateRanges['一个月前']
    this.searchParams.date = [dates[0].format('yyyy-MM-DD'), dates[1].format('yyyy-MM-DD')]
    this.searchParams.compareDate = [compareDates[0].format('yyyy-MM-DD'), compareDates[1].format('yyyy-MM-DD')]
    this.loadData()
    setTimeout(() => {
      this.$refs.lineChart.resize({ width: this.$refs.lineChartContainer.clientWidth, height: 400 })
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';

.profit-desc {
  margin-top: 8px;
  p {
    margin-bottom: 3px;
  }
}

.profit-table @{deep} .ant-table-row:first-of-type td {
  font-weight: bold;
  font-size: 14px;
  background-color: #fff7f6;
}
.profit-table @{deep} .ant-table-row:nth-child(odd) {
  background-color: #eef3ff;
}

.top-container {
  background-color: #fff;
  padding: 10px;
  border: solid 1px #ddd;
  border-radius: 5px;
}

.segment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  & > label {
    &::after {
      content: '：';
    }
  }
  .segment-list {
    list-style: none;
    margin: 0;
    padding: 0;
    .segment-item {
      display: inline-block;
      margin: 0 10px;
      .segment-name {
        cursor: move;
      }
    }
  }
}

.line-chart-container {
  margin-top: 30px;
  padding: 20px 0;
  background-color: #fff;
  .options {
    text-align: right;
  }
}

</style>
