<template>
  <div style="margin: 10px;">
    <a-empty v-if="dataList.length === 0" />
    <div class="history-item font-normal text-main" v-for="item in dataList" :key="item.id">
      <div class="left-right-content">
        <div class="font-bold">{{item.createTime}}</div>
        <div>
          <span class="status" :class="'status-' + item.status">{{statusMapping[item.status]}}</span>
        </div>
      </div>
      <div class="left-right-content">
        <div>操作人：{{item.userName}}</div>
        <div>供应商：{{item.supplierName}}</div>
      </div>
      <div class="text-muted font-mini">
        描述：{{item.message}}
      </div>
    </div>
  </div>
</template>

<script>
import { listHistoryByProduceItemId } from '@/http/api/produce-item-status-history'
import { SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING } from '@/constants/supplier-produce-item-status'

export default {
  props: {
    itemId: { type: String, required: true }
  },
  data () {
    return {
      dataList: [],
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING
    }
  },
  methods: {

  },
  mounted () {
    listHistoryByProduceItemId()
      .success(resp => {
        this.dataList = resp.data
      })
      .send(this.itemId)
  }
}
</script>

<style lang="less" scoped>

.history-item {
  padding: 10px;
  border-bottom: solid 1px #eee;
}

.status {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 12px;
}
.status-new {
  background-color: #606266;
}
.status-accept {
  background-color: #3d71a7;
}
.status-reject {
  background-color: #bb3e30;
}
.status-delivery {
  background-color: #368e0c;
}
.status-return {
  background-color: #de8418;
}
.status-delete {
  background-color: #000000;
}
</style>
