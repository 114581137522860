<template>
  <div class="demo-order">
    <div v-if="order">
      <div class="info-detail">

        <div class="detail-item">
          <label>店铺</label>
          <span>{{order.shopName}}</span>
        </div>

        <div class="detail-item">
          <label>订单号</label>
          <span>{{order.orderNo}}</span>
        </div>

        <div class="detail-item">
          <label>审核状态</label>
          <span v-if="order.statusCheck === 'verify'" >审核中</span>
          <span v-else-if="order.statusCheck === 'pass'" style="color: #0e7307">审核通过</span>
          <span v-else-if="order.statusCheck === 'reject'" style="color: #a94442">拒绝</span>
        </div>
        <div class="detail-item">
          <label>审核备注</label>
          <span>{{order.statusCheckMessage}}</span>
        </div>

        <div class="detail-item">
          <label>发货日期</label>
          <span v-if="order.deliveryDate">{{order.deliveryDate}}</span>
          <span v-else>未发货</span>
        </div>

        <div class="detail-item">
          <label>发货产品</label>
          <span class="sku-grid" style="max-width: 250px;">
            <a-tooltip placement="top" v-for="p in order.products" :key="p.sku">
              <template slot="title">SKU: {{p.sku}}</template>
              <div class="image-item sku-item" v-lazy:background-image="p.imageUrl" @click="$imageModal.open(p.imageUrl)">
                <div class="total">{{p.total}}</div>
              </div>
            </a-tooltip>
          </span>
        </div>

      </div>
    </div>
    <div v-else>{{orderJson}}</div>
  </div>
</template>

<script>
export default {
  props: {
    orderJson: { type: String }
  },
  data () {
    return {

    }
  },
  computed: {
    order () {
      if (this.orderJson) {
        try {
          return JSON.parse(this.orderJson)
        } catch (e) {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.sku-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  .sku-item {
    position: relative;
    .total {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: #1b77b4;
      color: #fff;
      border-radius: 100%;
      text-align: center;
      padding: 5px 10px;
    }
  }
}
</style>
