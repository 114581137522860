<template>
  <div class="container">
    <div class="top">
      <a-button type="primary" @click="$refs.editor.open(orderId)" icon="plus" v-authority="$authority.supplierOrder">下单</a-button>
      <a-button @click="reload()" icon="reload">刷新</a-button>
    </div>

    <div class="bottom" ref="dataContainer">
      <a-table
          slot="center"
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
          :scroll="{ y: antdTableY }"
          :loading="dataLoading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
      >
        <div slot="action" slot-scope="v, r">
          <a-button size="small" @click="$refs.detail.open(r.id)">详情</a-button>
        </div>
        <div slot="number" slot-scope="value, item">
          {{value}}
          <a-tooltip placement="top" v-if="item.remark">
            <template slot="title">
              <span>有备注</span>
            </template>
            <a-icon type="exception" style="color: #bb3e30; margin-left: 6px;" />
          </a-tooltip>
          <a-tooltip placement="top" v-if="item.delay">
            <template slot="title">
              <span>工厂延迟处理</span>
            </template>
            <a-icon type="fall" style="color: #3679c9; margin-left: 6px;" />
          </a-tooltip>
        </div>
        <div slot="image" slot-scope="v, r">
          <div class="image-item" v-lazy:background-image="r.getFirstImage()" @click="$imageModal.open(r.getImages())">
            <span v-if="r.getImages().length > 1" class="total">{{r.getImages().length}}</span>
          </div>
        </div>

        <div slot="status" slot-scope="status">
          <div class="status" :class="'status-' + status">{{statusMapping[status]}}</div>
        </div>

        <div slot="limitation" class="font-mini" slot-scope="v, item">
          <div v-if="item.dayNewToAccept > 0">
            <span v-if="item.confirmTime">接单用时：{{item.dayNewToAccept}}天</span>
            <span v-else class="text-danger">未接单（已过{{item.dayNewToAccept}}天）</span>
          </div>
          <div v-if="item.dayAcceptToDelivery > 0">
            <span v-if="item.deliveryTime">接单到发货用时：{{item.dayAcceptToDelivery}}天</span>
            <span v-else class="text-danger">未发货（已过{{item.dayAcceptToDelivery}}天）</span>
          </div>
        </div>
      </a-table>
    </div>

    <produce-item-detail ref="detail"></produce-item-detail>
    <produce-item-editor ref="editor" @saved="reload()"></produce-item-editor>
  </div>
</template>

<script>
import { listItemsByOrderId } from '@/http/api/supplier-produce-item'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import ProduceItemDetail from '@/pages/supplier/produce-item/comp/detail'
import ProduceItemEditor from './comp/produce-item-editor'
import { SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING } from '@/constants/supplier-produce-item-status'


export default {
  props: {
    orderId: { type: String, required: true }
  },
  mixins: [AntdTableHeightMixin],
  components: { ProduceItemDetail, ProduceItemEditor },
  data () {
    return {
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '操作', dataIndex: 'action', width: 60, scopedSlots: { customRender: 'action' }, fixed: 'left' },
        { title: '图片', width: 90, scopedSlots: { customRender: 'image' } },
        { title: '编号', dataIndex: 'number', width: 120, scopedSlots: { customRender: 'number' } },
        { title: '规格', dataIndex: 'variant' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '时效', width: 160, scopedSlots: { customRender: 'limitation' } },
        { title: '数量', dataIndex: 'quantity', width: 80 },
        { title: '总成本', dataIndex: 'cost', width: 80 },
        { title: '下单日期', dataIndex: 'createTime', width: 140 },
        { title: '供应商', dataIndex: 'supplierName', width: 120 }
      ],
      selectedRowKeys: [],
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
      this.$nextTick(() => {
        this.contentHeight = this.$refs.dataContainer.clientHeight
      })
    },
    onInactive () {
    },
    onInit () {
      this.loadProduceItems()
    },
    reload () {
      this.loadProduceItems()
    },
    loadProduceItems () {
      this.dataLoading = true
      listItemsByOrderId()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          resp.data.forEach(item => {
            arr.push(new SupplierProduceItemModel(item))
          })
          this.dataList = arr
        })
        .send(this.orderId)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    }
  }
}
</script>

<style lang="less" scoped>

.container {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;

}

.top {
  padding-bottom: 10px;
}

.bottom {
  flex: 1;
  overflow: hidden;
}

.status {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 12px;
}
.status-new {
  background-color: #606266;
}
.status-accept {
  background-color: #3d71a7;
}
.status-reject {
  background-color: #bb3e30;
}
.status-delivery {
  background-color: #368e0c;
}
.status-return {
  background-color: #de8418;
}
.status-delete {
  background-color: #000000;
}

</style>
