import kit from '@/utils/kit'

export const ORDER_DELIVERY_STATUS_SHIPPED = 'shipped'
export const ORDER_DELIVERY_STATUS_ARRIVED = 'arrived'
export const ORDER_DELIVERY_STATUS_CANCEL = 'cancel'
export const ORDER_DELIVERY_STATUS_EXCEPTION = 'ex'
export const ORDER_DELIVERY_STATUS_EXCEPTION_SOLVED = 'exSolved'
export const ORDER_DELIVERY_STATUS_WAIT_TRACK = 'waitTrack'

export const ORDER_DELIVERY_STATUS_MAPPING = {
  [ORDER_DELIVERY_STATUS_SHIPPED]: '已发货',
  [ORDER_DELIVERY_STATUS_ARRIVED]: '已签收',
  [ORDER_DELIVERY_STATUS_CANCEL]: '已取消',
  [ORDER_DELIVERY_STATUS_EXCEPTION]: '异常',
  [ORDER_DELIVERY_STATUS_EXCEPTION_SOLVED]: '异常解决',
  [ORDER_DELIVERY_STATUS_WAIT_TRACK]: '未出跟踪号'
}

export const ORDER_DELIVERY_STATUS = {
  shipped: ORDER_DELIVERY_STATUS_SHIPPED,
  arrived: ORDER_DELIVERY_STATUS_ARRIVED,
  cancel: ORDER_DELIVERY_STATUS_CANCEL,
  exception: ORDER_DELIVERY_STATUS_EXCEPTION,
  waitTrack: ORDER_DELIVERY_STATUS_WAIT_TRACK
}

export const ORDER_DELIVERY_STATUS_LIST = kit.obj.toArray(ORDER_DELIVERY_STATUS_MAPPING)
