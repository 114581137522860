import defineApi from '../define-api'
import http from '../application-api'


export const list = defineApi((config, params) => {
  config.params = params
  http.get('/after_sale_session/list', config)
})

export const getStatusTotal = defineApi((config) => {
  http.get('/after_sale_session/status_total', config)
})

export const listSessionByCustomerEmail = defineApi((config, email) => {
  config.params = {
    email: email
  }
  http.get('/after_sale_session/list/by_email', config)
})

export const addSession = defineApi((config, data) => {
  config.data = data
  http.post('/after_sale_session/add', config)
})

export const updateSession = defineApi((config, data) => {
  config.data = data
  http.post('/after_sale_session/update', config)
})

export const addEvent = defineApi((config, data) => {
  config.data = data
  http.post('/after_sale_session/add/event', config)
})

export const updateEventEnabled = defineApi((config, id, enabled) => {
  config.params = {
    id: id,
    enabled: enabled
  }
  http.post('/after_sale_session/event/update_enabled', config)
})

export const updateEventRefundData = defineApi((config, data) => {
  config.data = data
  http.post('/after_sale_session/event/update_refund', config)
})

export const listEventsByAfterSaleSessionId = defineApi((config, afterSaleSessionId) => {
  config.params = {
    afterSaleSessionId: afterSaleSessionId
  }
  http.get('/after_sale_session/events', config)
})

export const getOrdersByEmail = defineApi((config, email) => {
  config.params = {
    email: email
  }
  http.get('/after_sale_session/orders/by_email', config)
})

export const getTags = defineApi((config) => {
  http.get('/after_sale_session/tags', config)
})

export const getTagTotals = defineApi((config) => {
  http.get('/after_sale_session/report/tag_total', config)
})
