import defineApi from '../define-api'
import http from '../application-api'

/**
 * @type {Function}
 */
export const list = defineApi((config, params) => {
  config.params = params
  http.get('/warehouse_prepare_user/list', config)
})

export const listCurrentUserWarehouse = defineApi(config => {
  http.get('/warehouse_prepare_user/warehouse/by_current_user', config)
})

export const addBatch = defineApi((config, list) => {
  config.data = list
  http.post('/warehouse_prepare_user/add', config)
})

export const deleteBatch = defineApi((config, idList) => {
  config.data = idList
  http.post('/warehouse_prepare_user/delete', config)
})
