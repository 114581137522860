<!-- 批量更新达人的标签，合作状态，分类 -->
<template>
  <modal ref="modal" title="达人批量更新" @ok="handleOk" @closed="handleClosed" :loading="loading">
    <a-divider orientation="left">标签</a-divider>

    <div>
      <a-input-search v-model="newTag" placeholder="新增标签" @search="handleNewTag" style="margin-bottom: 10px;">
        <a-button slot="enterButton">新增</a-button>
      </a-input-search>
      <a-tag v-for="t in tags"
             :key="t.tag"
             style="margin-bottom: 8px;"
             closable
             @close="handleRemoveTag(t, $event)"
             :color="t.status === 'remove' ? 'red' : (t.status === 'new' ? 'green' : 'blue')"
      >
        {{t.tag}}
      </a-tag>
    </div>

    <a-divider orientation="left">备注</a-divider>
    <div>
      <a-radio-group v-model="remarkUpdate.op" button-style="solid">
        <a-radio-button value="ignore">不更新</a-radio-button>
        <a-radio-button value="update">更新</a-radio-button>
      </a-radio-group>
      <a-textarea v-model="remarkUpdate.remark" :auto-size="{ minRows: 3, maxRows: 5 }" style="margin-top: 15px;"/>
    </div>



    <a-divider orientation="left" style="margin-top: 30px;">合作状态</a-divider>
    <div>
      <a-radio-group v-model="cooperationStatusUpdate.op" button-style="solid">
        <a-radio-button value="ignore">不更新</a-radio-button>
        <a-radio-button value="update">更新</a-radio-button>
        <a-radio-button value="remove">删除</a-radio-button>
      </a-radio-group>
      <a-select v-model="cooperationStatusUpdate.id" style="width: 100%; margin-top: 10px;" v-show="cooperationStatusUpdate.op === 'update'">
        <a-select-option v-for="item in cooperationStatusOptions" :key="item.id">{{item.name}}</a-select-option>
      </a-select>
    </div>

    <a-divider orientation="left" style="margin-top: 30px;">达人分类</a-divider>
    <div>
      <a-radio-group v-model="categoryUpdate.op" button-style="solid">
        <a-radio-button value="ignore">不更新</a-radio-button>
        <a-radio-button value="update">更新</a-radio-button>
        <a-radio-button value="remove">删除</a-radio-button>
      </a-radio-group>
      <a-select v-model="categoryUpdate.id" style="width: 100%; margin-top: 10px;" v-show="categoryUpdate.op === 'update'">
        <a-select-option v-for="item in categoryOptions" :key="item.id">{{item.name}}</a-select-option>
      </a-select>
    </div>
  </modal>
</template>

<script>
import kit from '@/utils/kit'
import { listAll as listCategories } from '@/http/api/influencer-category'
import { listOptions as listCooperationStatus } from '@/http/api/cooperation-status'
import { batchUpdate } from '@/http/api/influencer'

export default {
  data () {
    return {
      loading: false,
      influencers: [],
      /**
       * { tag: 'Tag', status: 'exists | remove | new' }
       * exists：达人本身拥有的标签
       * remove：将exists标签移除
       * new：新增标签
       */
      tags: [],
      newTag: null,
      categoryOptions: [],
      categoryUpdate: {
        id: null,
        op: 'ignore'
      },
      cooperationStatusOptions: [],
      cooperationStatusUpdate: {
        id: null,
        op: 'ignore'
      },
      remarkUpdate: {
        remark: null,
        op: 'ignore'
      }
    }
  },
  methods: {
    open (influencerList) {
      this.$refs.modal.open()
      this.influencers = influencerList
      const tagMap = {}
      const tags = []
      for (const item of influencerList) {
        for (const t of (item.tags || '').split(',')) {
          if (t && tagMap[t] !== true) {
            tags.push({ tag: t, status: 'exists' })
          }
          tagMap[t] = true
        }
      }
      this.tags = tags

      listCategories().success(resp => (this.categoryOptions = resp.data)).send()
      listCooperationStatus().success(resp => (this.cooperationStatusOptions = resp.data)).send()
    },
    handleRemoveTag (tag, e) {
      e.preventDefault()
      if (tag.status === 'new') {
        kit.arr.removeItem(this.tags, tag)
      } else if (tag.status === 'remove') {
        tag.status = 'exists'
      } else {
        tag.status = 'remove'
      }
    },
    handleNewTag () {
      if (this.newTag) {
        if (this.tags.findIndex(item => item.tag === this.newTag) === -1) {
          this.tags.push({ tag: this.newTag, status: 'new' })
          this.newTag = null
        }
      }
    },
    handleOk () {
      const data = {
        idList: [],
        removeTags: [],
        newTags: [],
        categoryOp: this.categoryUpdate.op,
        categoryId: this.categoryUpdate.id,
        statusOp: this.cooperationStatusUpdate.op,
        statusId: this.cooperationStatusUpdate.id,
        remarkOp: this.remarkUpdate.op,
        remark: this.remarkUpdate.remark
      }


      const idList = []
      for (const item of this.influencers) {
        idList.push(item.id)
      }
      data.idList = idList

      data.removeTags = []
      data.newTags = []
      for (const tag of this.tags) {
        if (tag.status === 'remove') {
          data.removeTags.push(tag.tag)
        } else if (tag.status === 'new') {
          data.newTags.push(tag.tag)
        }
      }

      this.$confirm({
        title: '确认',
        content: '确定要更新吗？',
        onOk: () => {
          this.loading = true
          batchUpdate()
            .complete(() => (this.loading = false))
            .success(() => {
              this.$message.success('更新成功。')
              this.$emit('updated')
              this.$nextTick(() => (this.$refs.modal.close()))
            })
            .send(data)
        }
      })
    },
    handleClosed () {
      this.influencers = []
      this.tags = []
      this.categoryUpdate.id = null
      this.categoryUpdate.op = 'ignore'
      this.cooperationStatusUpdate.id = null
      this.cooperationStatusUpdate.op = 'ignore'
      this.remarkUpdate.op = 'ignore'
      this.remarkUpdate.remark = null
    }
  }
}
</script>

<style lang="less" scoped>

</style>
