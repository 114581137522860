<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="handleOpenInventory">入库</a-button>
    <a-button slot="toolbar" type="primary" @click="handleUpdateCost">更新成本价</a-button>

    <a-tooltip placement="bottomLeft" slot="toolbar">
      <template slot="title">退回指将它们变为"已接单"状态，请提前跟供应商沟通，避免重复生产。</template>
      <a-button @click="handleBackToAccepted" type="danger">退货</a-button>
    </a-tooltip>

    <div slot="top" class="text-primary font-large font-bold" style="margin-left: 20px;">
      <span>供应商：{{supplierName}}</span>
      <span style="margin-left: 20px;">日期：{{shipDate}}</span>
      <span style="margin-left: 20px;">快递单号：{{trackNo}}</span>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        class="data-table"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >

      <div slot="image" slot-scope="v, item">
        <div class="image-item cover" v-lazy:background-image="item.getFirstImage()" @click="$imageModal.open(item.getFirstImage())"></div>
      </div>

      <div slot="sku" slot-scope="sku">
        <span class="text-primary" style="cursor: pointer;" v-if="sku" @click="$refs.stockProductEditor.openBySku(sku)">{{sku}}</span>
        <span v-else>{{sku}}</span>
        <div>
          <a-button type="link" size="small" @click="handleCopySku(sku)">复制</a-button>
        </div>
      </div>

      <div slot="cost" slot-scope="v, item" :class="{'no-price': item.cost <= 0 && !item._referCost}">
        <a-input-number v-model="item.cost" :placeholder="item._referCost ? `单价：${item._referCost}` : '单价'" style="width: 100%;" />
      </div>

      <div slot="stock" slot-scope="v, item">
        <span class="text-success" v-if="item.stockQuantity > 0">已入库：{{item.stockQuantity}}</span>
        <span class="text-muted" v-else>未入库</span>
      </div>
    </a-table>

    <stock-product-editor ref="stockProductEditor"></stock-product-editor>
    <inventory-change-request-editor ref="inventory"></inventory-change-request-editor>

  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER_DELIVERY_DETAIL } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listPage, updateCost, backToAccepted } from '@/http/api/supplier-produce-item'
import { getReferCostBySkuList } from '@/http/api/stock-product-cost'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import StockProductEditor from '@/pages/stock/comp/stock-product-editor'
import InventoryChangeRequestEditor from '@/pages/stock/inventory/comp/request-editor'

export default {
  components: { StockProductEditor, InventoryChangeRequestEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_SUPPLIER_DELIVERY_DETAIL,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '编号', dataIndex: 'number', width: 90 },
        { title: '图片', width: 100, scopedSlots: { customRender: 'image' } },
        { title: 'SKU', dataIndex: 'sku', align: 'center', scopedSlots: { customRender: 'sku' } },
        { title: '规格', dataIndex: 'variant' },
        { title: '数量', dataIndex: 'quantity', width: 70 },
        { title: '单价', width: 140, scopedSlots: { customRender: 'cost' } },
        { title: '签收日期', dataIndex: 'arrivedTime' },
        { title: '供应商', dataIndex: 'supplierName' },
        { title: '入库状态', scopedSlots: { customRender: 'stock' } }
      ],
      selectedRowKeys: [],
      /**
       * 执行入库后，服务端返回SKU不存在的id，把id存在这里，在表格提示
       * key: id, value: true
       */
      notInStockIds: {}
    }
  },
  computed: {
    trackNo () {
      return this.$route.params.trackNo
    },
    supplierName () {
      return this.$route.query.supplier
    },
    shipDate () {
      return this.$route.query.date
    }
  },
  methods: {
    loadData () {
      const p = {
        start: 0,
        limit: 200,
        trackNo: this.trackNo
      }
      this.dataLoading = true
      listPage()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          for (const item of resp.data.records) {
            const m = new SupplierProduceItemModel(item)
            if (m.cost) {
              m.cost = m.cost / m.quantity
            }
            m._referCost = null
            arr.push(m)
          }
          this.dataList = arr
          this.loadReferCosts()
        })
        .send(p)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleUpdateCost () {
      const arr = []
      const map = {}
      let noPriceTotal = 0
      for (const item of this.dataList) {
        const obj = { id: item.id, cost: null, total: item.quantity }
        if (item.cost != null) {
          obj.cost = item.cost
        }
        if (obj.cost == null && item._referCost) {
          obj.cost = item._referCost
        }
        arr.push(obj)
        map[obj.id] = obj.cost
        if (obj.cost <= 0) {
          noPriceTotal++
        }
      }
      const msg = noPriceTotal > 0 ? `有${noPriceTotal}个产品没有填写价格，确定要更新吗？` : '确定要更新吗？'
      this.$confirm({
        title: '确认',
        content: msg,
        onOk: () => {
          this.pageLoading = true
          updateCost()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              this.$message.success('更新成功。')
              this.loadData()
            })
            .send(arr)
        }
      })
    },
    loadReferCosts () {
      const skuList = []
      let supplierId = null
      for (const item of this.dataList) {
        skuList.push(item.sku)
        supplierId = item.supplierId
      }
      getReferCostBySkuList()
        .success(resp => {
          for (const item of this.dataList) {
            item._referCost = resp.data[item.sku] || null
          }
        })
        .send(skuList, supplierId)
    },
    handleCopySku (sku) {
      this.$copyText(sku).then(() => {
        this.$message.success('复制成功')
      })
    },
    handleBackToAccepted () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择操作的行。')
      } else {
        this.$confirm({
          title: '确认',
          content: `确定要退回选中的 ${this.selectedRowKeys.length} 个项目吗？`,
          onOk: () => {
            this.pageLoading = true
            backToAccepted()
              .complete(() => (this.pageLoading = false))
              .success(() => {
                this.$message.success('操作成功。')
                this.loadData()
              })
              .send(this.selectedRowKeys)
          }
        })
      }
    },
    handleOpenInventory () {
      const selectedKeyMap = {}
      for (const key of this.selectedRowKeys) {
        selectedKeyMap[key] = true
      }
      const arr = []
      let supplierId = null
      for (const item of this.dataList) {
        if (item.stockQuantity > 0) continue
        supplierId = item.supplierId
        if (selectedKeyMap[item.id] === true) {
          arr.push({ sku: item.sku, total: item.quantity, cost: item.cost, fromSupplierProduceItemId: item.id })
        }
      }
      this.$refs.inventory.openForInStock(`供应商生产入库-${this.supplierName}-${this.trackNo}`, arr, supplierId, {
        disableSkuTotalUpdate: true,
        disableModifySku: true,
        disableSupplierUpdate: true
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.no-price @{deep} .ant-input-number {
  border-color: #bb3e30;
}
</style>
