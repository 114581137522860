import defineApi from '../define-api'
import http from '../application-api'

export const add = defineApi((config, jsonContent, customSku) => {
  config.params = {
    content: jsonContent,
    customSku: customSku
  }
  http.post('/store_local_product/add', config)
})

export const update = defineApi((config, jsonContent, customSku, id) => {
  config.params = {
    id: id,
    content: jsonContent,
    customSku: customSku
  }
  http.post('/store_local_product/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/store_local_product/delete', config)
})

export const upload = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  return http.post('/store_local_product/file/upload', config)
})

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/store_local_product/list', config)
})

export const submitToStore = defineApi((config, id, shopId) => {
  config.params = {
    id: id,
    shopId: shopId
  }
  http.post('/store_local_product/submit', config)
})

export const generateSkuPreview = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/store_local_product/sku_generate/preview', config)
})

export const checkSku = defineApi((config, id, customSku) => {
  config.params = {
    id: id,
    customSku: customSku
  }
  http.get('/store_local_product/sku/check', config)
})

