
const CUSTOMIZE_WORDS = {
  height: '身高',
  bust: '胸围',
  waist: '腰围',
  hips: '臀围',
  arm: '臂长',
  legLength: '腿长',
  inseamLength: '裤腿内缝',
  weight: '体重'
}

export default {
  methods: {
    splitCustomize (info, notTranslateName) {
      const list = []
      try {
        if (info == null || info.length === 0) {
          return list
        }
        const arr = info.split(',')
        for (const item of arr) {
          const tmp1 = item.split(':')
          const tmp2 = tmp1[1].split('/')
          list.push({
            name: notTranslateName === true ? tmp1[0] : (CUSTOMIZE_WORDS[tmp1[0]] || tmp1[0]),
            value: tmp2[0],
            unit: tmp2[1]
          })
        }
      } catch (e) {
        list.push({
          name: '',
          value: info,
          unit: null
        })
      }
      return list
    },
    /**
     * 将定制信息数据结构转成按规则排列的字符串。
     * @param obj
     * @param unit
     * @param unitForWeight
     */
    stringifyCustomize (obj, unit, unitForWeight) {
      const arr = []
      for (const key of Object.keys(obj)) {
        const val = obj[key]
        if (val != null && val.length > 0) {
          arr.push(key + ':' + obj[key] + '/' + (key === 'weight' ? unitForWeight : unit))
        }
      }
      if (arr.length > 0) {
        return arr.join(',')
      } else {
        return null
      }
    }
  }
}
