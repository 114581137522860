<template>
  <modal ref="modal" title="达人合作状态管理器" :width="600" @ok="onOk" @closed="$emit('closed')" :loading="loading">

    <div style="padding: 10px 0;">

      <div class="add-block">
        <div class="item-wrapper">
          <div class="name">
            <a-select style="width: 100%; margin-bottom: 10px;" v-model="form.parentId">
              <a-select-option value="root">Root</a-select-option>
              <a-select-option v-for="d in dataList" :key="d.id">{{d.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.name" placeholder="请输入状态名称，如：正在合作"></a-input>
          </div>
          <div class="color">
            <a-tooltip>
              <template slot="title">选择颜色</template>
              <color-picker :color.sync="form.color"></color-picker>
            </a-tooltip>
          </div>
          <div class="buttons">
            <a-button @click="handleAdd" :loading="loading">添加</a-button>
          </div>
        </div>
      </div>

      <draggable
          tag="div"
          style="margin-top: 10px;"
          v-model="dataList"
          v-bind="drag.dragOptions"
          @start="drag.drag = true"
          @end="drag.drag = false"
      >
        <transition-group tag="ul" type="transition" :name="!drag.drag ? 'flip-list' : null" class="sortable-list list-group">
          <div class="list-group-item" v-for="item in dataList" :key="item.id">
            <div class="item-wrapper">
              <a-icon type="menu" class="sort" />
              <div class="name"><a-input v-model="item.name"></a-input></div>
              <div class="color"><color-picker :color.sync="item.color"></color-picker></div>
              <div class="buttons">
                <a-button v-if="item.op === 'remove'" @click="handleDelete(item)">恢复</a-button>
                <a-button v-else @click="handleDelete(item)" type="danger">删除</a-button>
              </div>
            </div>

            <draggable
                tag="div"
                style="margin-top: 10px;"
                v-model="item.children"
                v-bind="childDrag.dragOptions"
                @start="childDrag.drag = true"
                @end="childDrag.drag = false"
            >
              <transition-group tag="div" type="transition" :name="!childDrag.drag ? 'flip-list' : null" class="sortable-list children">
                <div class="child" v-for="c in item.children" :key="c.id">
                  <div class="item-wrapper">
                    <a-icon type="menu" class="sort" />
                    <div class="name"><a-input v-model="c.name"></a-input></div>
                    <div class="color"><color-picker :color.sync="c.color"></color-picker></div>
                    <div class="buttons">
                      <a-button v-if="c.op === 'remove'" @click="handleDelete(c, item)">恢复</a-button>
                      <a-button v-else @click="handleDelete(c, item)" type="danger">删除</a-button>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </transition-group>
      </draggable>


    </div>
  </modal>
</template>

<script>
import { listAll, update } from '@/http/api/cooperation-status'
import kit from '@/utils/kit'
import Draggable from 'vuedraggable'

export default {
  components: { Draggable },
  data () {
    return {
      order: null,
      loading: false,
      form: {
        name: null,
        color: '#000000',
        parentId: 'root'
      },
      dataList: [],
      drag: {
        dragOptions: {
          handle: '.sort'
        },
        drag: false
      },
      childDrag: {
        dragOptions: {
          handle: '.sort'
        },
        drag: false
      }
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
      this.loadData()
    },
    loadData () {
      listAll().success(resp => {
        for (const p of resp.data) {
          p.op = 'update'
          if (p.children) {
            for (const c of p.children) {
              c.op = 'update'
            }
          }
        }
        this.dataList = resp.data
      }).send()
    },
    handleAdd () {
      const data = Object.assign({
        id: kit.str.id(),
        op: 'add',
        children: []
      }, this.form)

      if (data.parentId === 'root') {
        this.dataList.push(data)
      } else {
        const p = this.dataList.find(item => item.id === data.parentId)
        p.children.push(data)
      }

      this.form.name = null
      this.form.color = '#000000'
    },
    handleUpdate (item) {
      this.loading = true
      update()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.loadData()
          this.$emit('updated')
        })
        .send(item)
    },
    handleDelete (item, parent) {
      if (item.op === 'add') {
        if (parent) {
          kit.arr.removeItem(parent.children, item)
        } else {
          kit.arr.removeItem(this.dataList, item)
        }
      } else if (item.op === 'update') {
        item.op = 'remove'
      } else if (item.op === 'remove') {
        item.op = 'update'
      }
    },
    onOk () {
      const data = {
        add: [],
        remove: [],
        update: []
      }
      const list = JSON.parse(JSON.stringify(this.dataList))

      let pos = 0
      for (const p of list) {
        p.position = pos++
        if (p.children) {
          let cpos = 0
          for (const c of p.children) {
            c.position = cpos++
          }
        }
      }

      for (const p of list) {
        if (p.op === 'add') {
          p.parentId = null
          p.id = null
          data.add.push(p)
        } else if (p.op === 'remove') {
          data.remove.push(p.id)
        } else if (p.op === 'update') {
          const children = p.children
          p.children = null
          data.update.push(p)

          if (children && children.length > 0) {
            for (const c of children) {
              if (c.op === 'add') {
                c.parentId = p.id
                c.id = null
                data.add.push(c)
              } else if (c.op === 'update') {
                data.update.push(c)
              } else if (c.op === 'remove') {
                data.remove.push(c.id)
              }
            }
          }
        }
      }

      this.loading = true
      update()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.loadData()
          this.$emit('updated')
        })
        .send(data)
    }
  }
}
</script>

<style lang="less" scoped>
.list-group-item {
  background-color: #fff;
}
.add-block {
  padding: 10px 15px;
  background-color: aliceblue;
  border-radius: 5px;
}
.list-group-item .item-wrapper {
  background-color: #fff;
}
.item-wrapper {
  display: flex;
  align-items: center;
  .sort {
    cursor: pointer;
    margin-right: 10px;
  }
  .name {
    flex: 1;
    padding-right: 15px;
  }
  .color {
    width: 60px;
  }
  .buttons {
  }
}
.children {
  margin-left: 40px;
  margin-top: 10px;
  .child + .child {
    margin-top: 5px;
  }
}
</style>
