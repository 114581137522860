import defineApi from '../define-api'
import http from '../application-api'

export const addOrUpdate = defineApi((config, data) => {
  config.data = data
  http.post('/shipping_method_advice/add_update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/shipping_method_advice/delete', config)
})

export const listAll = defineApi(config => {
  http.get('/shipping_method_advice/list', config)
})
