<!--
  库存预警
-->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button size="small" @click="reloadData">查询</a-button>
      <a-button size="small" type="primary" @click="$refs.editor.open()">添加</a-button>
      <a-button size="small" @click="handleEdit">编辑</a-button>
      <a-button size="small" type="danger" @click="handleDelete">删除</a-button>
      <a-button size="small" type="danger" @click="handleUpdateEnabled(false)">禁用预警</a-button>
      <a-button size="small" @click="handleUpdateEnabled(true)">启用预警</a-button>
    </div>

    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="标题">
          <a-input v-model="searchParams.title" placeholder="标题关键字" style="width: 300px;" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="标签">
          <a-input v-model="searchParams.tags" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="SKU">
          <a-input v-model="searchParams.sku" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="库存状态">
          <a-select v-model="searchParams.enabled" style="width: 114px;" :allow-clear="true">
            <a-select-option value="enabled">启用</a-select-option>
            <a-select-option value="disabled">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="imageUrl" slot-scope="imageUrl">
        <div class="image-item" v-lazy:background-image="imageUrl" @click="$imageModal.open(imageUrl)"></div>
      </div>

      <div slot="enabled" slot-scope="enabled">
        <status :status="enabled"></status>
      </div>

      <div slot="alertContent" slot-scope="record">
        <div v-if="record.stockAlertContent && record.stockAlertContent.alerts && record.stockAlertContent.alerts.length > 0">
          <div class="alert-item" v-for="item in record.stockAlertContent.alerts" :key="item.warehouseId">
            <label>{{warehouseMap[item.warehouseId]}} / </label>
            <span>{{item.minTotal}}</span>
          </div>
        </div>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <stock-alert-editor ref="editor" @saved="loadData()"></stock-alert-editor>

  </app-page>
</template>

<script>
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { ROUTE_STOCK_ALERT } from '@/router/router-constants'
import StockAlertEditor from './comp/stock-alert-editor'
import { list, updateEnabled, deleteById } from '@/http/api/stock-alert'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import kit from '@/utils/kit'

export default {
  components: { StockAlertEditor },
  data () {
    return {
      routeName: ROUTE_STOCK_ALERT,
      dataColumns: [
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          width: 200,
          scopedSlots: { customRender: 'sku' }
        },
        {
          title: '库存预警',
          width: 150,
          scopedSlots: { customRender: 'alertContent' }
        },
        {
          title: '产品标题',
          dataIndex: 'title'
        },
        {
          title: '状态',
          dataIndex: 'enabled',
          width: 60,
          scopedSlots: { customRender: 'enabled' }
        }
      ],
      dataList: [],
      dataLoading: false,
      selectedRowKeys: [],
      searchParams: {
        title: null,
        sku: null,
        tags: null,
        enabled: null
      },
      warehouseMap: {}
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  computed: {
    selectedRows () {
      const arr = []
      for (const id of this.selectedRowKeys) {
        const data = this.dataList.find(item => item.id === id)
        if (data) {
          arr.push(data)
        }
      }
      return arr
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.enabled) {
        p.enabled = p.enabled === 'enabled'
      } else {
        p.enabled = null
      }
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleUpdateEnabled (enabled) {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请先选择要操作的行。')
      }
      this.dataLoading = true
      updateEnabled()
        .complete(() => (this.dataLoading = false))
        .success(() => {
          this.$message.success('更新成功。')
          for (const id of this.selectedRowKeys) {
            const item = this.dataList.find(item => item.id === id)
            item.enabled = enabled
          }
        })
        .send(this.selectedRowKeys, enabled)
    },
    handleDelete () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请先选择要操作的行。')
      }
      this.$confirm({
        title: '确认',
        content: `确定要删除选中的${this.selectedRowKeys.length}行数据吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteById()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              for (const id of this.selectedRowKeys) {
                kit.arr.remove(this.dataList, { id: id })
              }
              this.selectedRowKeys = []
            })
            .send(this.selectedRowKeys)
        }
      })
    },
    handleEdit () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请先选择要操作的行。')
      }
      if (this.selectedRows.length === 1) {
        this.$refs.editor.open(this.selectedRows[0])
      } else {
        this.$refs.editor.open(this.selectedRows)
      }
    }
  },
  mounted () {
    this.reloadData()
    listWarehouseEnabled()
      .success(resp => {
        for (const item of resp.data) {
          this.warehouseMap[item.id] = item.name
        }
      })
      .send()
  }
}
</script>

<style lang="less" scoped>
.alert-item {
  label {
  }
  span {
    font-weight: bold;
    color: #bb3e30;
  }
}
</style>
