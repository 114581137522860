<template>
  <div class="internal-resource-selector" @click.stop.prevent="">
    <div class="input">
      <input class="display" :value="displayText" readonly />
      <span class="select" @click="handleUpIconClick">选择</span>
    </div>
    <transition name="fade">
      <div class="data-list-wrapper" v-show="dataListVisible" style="animation-duration: .2s;">
        <div class="close-icon">
          <a-icon type="close" @click="hideResourcePanel" />
        </div>
        <div class="data-list">
          <div class="resource-type">
            <div class="item" v-for="r in resourceTypes" :key="r.id" @click="handleResourceTypeClick(r)">{{r.name}}</div>
          </div>
          <transition name="slideLeft">
            <div class="resource-panel" v-show="dataList2Visible" style="animation-duration: .2s;">
              <div class="loading-wrapper" v-show="loading">
                <loading :loading="loading"></loading>
              </div>
              <div class="panel-top">
                <a-icon class="icon-back" type="left" @click="dataList2Visible = false"/>
                <input v-show="currentResourceType === 'data'" placeholder="输入关键字搜索" v-model="keyword" v-on:keyup.enter="loadResourceData(currentResourceType.id)" />
                <a-icon v-show="currentResourceType === 'data'" class="icon-search" type="search" @click="loadResourceData(currentResourceType.id)" />
              </div>
              <div class="panel-body">
                <div
                    v-show="currentResourceType === 'data' && !loading && resourceDataList.length === 0"
                    class="text-muted font-mini"
                    style="margin-top: 20px; text-align: center;"
                >
                  没有找到数据
                </div>
                <div v-for="d in resourceDataList" :key="d.id" class="data-item" @click="handleDataItemClick(d)">
                  <div v-if="d.cover" class="cover" v-lazy:background-image="d.cover"></div>
                  <div class="title">{{d.title}}</div>
                </div>
                <div v-if="currentResourceType === 'url'" style="padding: 10px">
                  <a-input v-model="urlInput" placeholder="http://example.com">
                    <a-icon slot="addonAfter" type="check" style="cursor: pointer;" @click="handleSaveUrlInput" />
                  </a-input>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  getInternalUrlsProductList,
  getInternalUrlsCollectionList,
  getInternalUrlsPageList,
  getInternalResourceDataById
} from '@/http/api/internal-urls'
import kit from '@/utils/kit'

export default {
  name: 'internal-resource-selector',
  props: {
    data: { type: String }
  },
  data () {
    return {
      dataListVisible: false,
      dataList2Visible: false,
      resourceTypes: [
        { id: 'home', name: '主页' },
        { id: 'product', name: '产品', type: 'data' },
        { id: 'collection', name: '产品系列', type: 'data' },
        { id: 'page', name: '页面', type: 'data' },
        { id: 'url', name: 'Url', type: 'url' }
      ],
      currentResource: null,
      resourceDataList: [],
      loading: false,
      keyword: null,
      urlInput: null,
      display: {
        prefix: null,
        title: null,
        loading: false
      }
    }
  },
  watch: {
    dataList2Visible (visible) {
      if (!visible) {
        this.clear()
      }
    },
    data: {
      immediate: true,
      handler (data) {
        if (data) {
          const tmp = data.split(':')
          this.display.prefix = tmp[0]
          this.display.title = null
          let requestData = false
          switch (this.display.prefix) {
            case 'home':
              break
            case 'url':
              this.display.title = data.substr(4)
              break
            case 'product':
            case 'collection':
            case 'page':
              requestData = true
              break
          }
          if (requestData) {
            if (this.cancelGetInternalResourceDataById) {
              this.cancelGetInternalResourceDataById.cancel()
            }
            this.display.loading = true
            this.cancelGetInternalResourceDataById = getInternalResourceDataById()
              .complete(() => (this.display.loading = false))
              .success(resp => {
                this.display.title = resp.data.title
              })
              .send(tmp[1], tmp[0])
          }
        } else {
          this.display = {
            prefix: null,
            title: null,
            loading: false
          }
        }
      }
    }
  },
  computed: {
    currentResourceType () {
      return this.currentResource ? this.currentResource.type : null
    },
    displayText () {
      if (this.display.loading) {
        return '正在加载数据...'
      }
      if (this.display.prefix) {
        let text = ''
        switch (this.display.prefix) {
          case 'home':
            text = '主页'
            break
          case 'url':
            text = '链接：'
            break
          case 'product':
            text = '产品：'
            break
          case 'collection':
            text = '产品系列：'
            break
          case 'page':
            text = '页面：'
            break
        }
        return text + (this.display.title || '')
      } else {
        return null
      }
    }
  },
  methods: {
    clear () {
      this.resourceDataList = []
      this.currentResource = null
      this.urlInput = null
    },
    handleUpIconClick () {
      if (this.dataListVisible) {
        this.hideResourcePanel()
      } else {
        this.dataListVisible = true
      }
    },
    handleResourceTypeClick (r) {
      if (r.id === 'home') {
        this.$emit('update:data', r.id + ':')
        this.hideResourcePanel()
      } else {
        this.currentResource = r
        this.dataList2Visible = true
        if (r.type === 'data') {
          this.loadResourceData(r.id)
        }
      }
    },
    handleSaveUrlInput () {
      this.$emit('update:data', this.currentResource.id + ':' + this.urlInput)
      this.$nextTick(() => {
        this.hideResourcePanel()
      })
    },
    handleDataItemClick (data) {
      this.$emit('update:data', this.currentResource.id + ':' + data.id)
      this.$nextTick(() => {
        this.hideResourcePanel()
      })
    },
    hideResourcePanel () {
      this.dataList2Visible = false
      this.dataListVisible = false
      if (this.cancelInternalResourceLoad) {
        this.cancelInternalResourceLoad.cancel()
      }
    },
    loadResourceData (id) {
      let api
      if (id === 'product') {
        api = getInternalUrlsProductList
      } else if (id === 'collection') {
        api = getInternalUrlsCollectionList
      } else if (id === 'page') {
        api = getInternalUrlsPageList
      }
      this.loading = true
      if (this.cancelInternalResourceLoad) {
        this.cancelInternalResourceLoad.cancel()
      }
      this.cancelInternalResourceLoad = api()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.resourceDataList = resp.data
        })
        .send(this.keyword)
    }
  },
  mounted () {
    kit.dom.addEvent(document.body, 'click', this.hideResourcePanel)
  },
  beforeDestroy () {
    kit.dom.removeEvent(document.body, 'click', this.hideResourcePanel)
  }
}
</script>

<style lang="less" scoped>
@height: 32px;
@border: solid 1px #e2e2e2;
.internal-resource-selector {
  position: relative;
  height: @height;
  border: @border;
  border-radius: 5px;
  background-color: #fff;
  .input {
    height: @height;
    line-height: @height;
    display: flex;
    .display {
      flex: 1;
      margin-left: 5px;
      height: @height - 2;
      font-size: 12px;
    }
    .select {
      cursor: pointer;
      color: #3d71a7;
      font-size: 12px;
      padding: 0 8px;
    }
  }

  .data-list-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    background-color: rgba(0,0,0,.4);
    .close-icon {
      position: absolute;
      left: 50%;
      top: 30px;
      margin-left: 220px;
      font-size: 22px;
      cursor: pointer;
      color: #fff;
    }
  }

  .data-list {
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -200px;
    width: 400px;
    height: 400px;
    background-color: #fff;
    border-left: @border;
    border-right: @border;
    border-bottom: @border;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    line-height: normal;
  }

  .resource-type {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9;
    .item {
      padding: 8px 10px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      border-bottom: @border;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }

  .resource-panel {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    overflow: hidden;
    .loading-wrapper {
      position: absolute;
      top: 32px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }
    .panel-top {
      padding: 0 10px;
      height: 42px;
      line-height: 42px;
      border-bottom: @border;
      display: flex;
      input {
        flex: 1;
        margin: 0 10px;
      }
      .icon-back, .icon-search {
        padding-top: 14px;
        cursor: pointer;
      }
    }
    .panel-body {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .data-item {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      border-bottom: @border;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
      .cover {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @size: 30px;
        width: @size;
        height: @size;
        border: solid 1px #eee;
        border-radius: 2px;
        margin-right: 10px;
      }
      .title {
        flex: 1;
        font-size: 12px;
      }
    }
  }
}
</style>
