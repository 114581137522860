<template>
  <div class="delivery-item">
    <div class="delivery-info">
      <div class="info-item">
        <label>订单编号</label>
        <span class="text-primary">{{orderDelivery.orderNo}}</span>
      </div>
      <div class="info-item">
        <label>下单日期</label>
        <span>{{orderDelivery.createTime}}</span>
      </div>
      <div class="info-item">
        <label>客户国家</label>
        <span>{{orderDelivery.countryCode}}</span>
      </div>
      <div class="info-item">
        <label>物流公司</label>
        <span>{{logisticsCompanyMapping[orderDelivery.companyId]}} / {{orderDelivery.shippingMethod}}</span>
      </div>
      <div class="info-item" v-if="orderDelivery.remark">
        <label>备注</label>
        <span class="text-danger">{{orderDelivery.remark}}</span>
      </div>
    </div>

    <div class="items">
      <div class="item" :class="{'prepared': item.prepare}" v-for="item in orderDelivery.deliveryProductItems" :key="item.id">
        <div class="image-cover" :style="{'background-image': `url(${item.imageUrl})`}"></div>
        <div class="info-detail">
          <div class="detail-item">
            <label>SKU</label>
            <span>{{item.sku}}</span>
          </div>
          <!-- todo CAF1770库存清空后可以删除此代码 -->
          <div class="detail-item" v-if="item.sku.startsWith('CAF1770')">
            <label>特殊情况</label>
            <span style="font-weight: bold; color: #c33632;">此款号发货时需加大一码</span>
          </div>
          <div class="detail-item" v-if="item.fromSupplierName">
            <label>供应商</label>
            <span>{{item.fromSupplierName}}</span>
          </div>
          <div class="detail-item">
            <label>数量</label>
            <span>
              共 <span class="font-bold" style="color: #c33632">{{item.total}}</span> 个，已配 <span class="font-bold" style="color: #c33632">{{item.prepareTotal}}</span> 个
            </span>
          </div>
          <div class="detail-item">
            <label>配货人</label>
            <span>{{item.prepareUserName}}</span>
          </div>
          <div class="detail-item">
            <label>配货时间</label>
            <span>{{item.prepareTime}}</span>
          </div>
          <div class="detail-item" style="float: right;">
            <a-button v-if="position === 'left' && !item.prepare" type="link" size="small" :loading="item.prepareLoading" @click="$emit('prepareConfirm', orderDelivery, item)">确认配货</a-button>
            <a-button v-if="item.prepare" type="link" size="small" :loading="item.prepareLoading" @click="$emit('prepareCancel', orderDelivery, item)">取消配货</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGISTICS_COMPANY_MAPPING } from '@/constants/logistics'

export default {
  props: {
    orderDelivery: { type: Object },
    // left表示在"待配货订单"列表中，right表示在"已配货订单"列表中
    position: { type: String },
    loading: { type: Boolean }
  },
  data () {
    return {
      logisticsCompanyMapping: LOGISTICS_COMPANY_MAPPING
    }
  }
}
</script>

<style lang="less" scoped>
.delivery-item {
  padding: 10px 20px;
  border: solid 1px #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;

  .delivery-info {
    border-bottom: solid 1px #ddd;
    padding-bottom: 10px;
    font-size: 12px;
    .info-item + .info-item {
      margin-top: 5px;
    }
    label {
      font-weight: bold;
    }
    label::after {
      content: '：'
    }
  }

  .items {
  }

  .item {
    display: flex;
    margin: 10px 0;
    border: solid 1px #3d71a7;
    border-radius: 4px;
    overflow: hidden;

    &.prepared {
      border-color: #2f790e;
      background-color: #f8fff6;
    }

    .image-cover {
      width: 100px;
      min-height: 120px;
      background-color: #3d71a7;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .info-detail {
      flex: 1;
      padding: 10px;
    }
  }
}
</style>
