<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" size="small" type="primary" @click="$refs.editor.open()">创建营销活动</a-button>


    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="营销代码">
          <a-input v-model="searchParams.activityId" :allow-clear="true" style="width: 120px;" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.status" style="width: 150px;" :allow-clear="true">
            <a-select-option value="wait">编辑中</a-select-option>
            <a-select-option value="started">正在发送</a-select-option>
            <a-select-option value="completed">发送完成</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableX }"
        :loading="dataLoading"
    >
      <div slot="buttons" slot-scope="row">
        <a-tooltip placement="top">
          <template slot="title"><span>编辑</span></template>
          <a-button size="small" @click="$refs.editor.open(row)" icon="edit"></a-button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title"><span>详情</span></template>
          <a-button size="small" @click="navDetail(row)" icon="align-left"></a-button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title"><span>复制</span></template>
          <a-button size="small" @click="handleCopy(row)" icon="copy"></a-button>
        </a-tooltip>
      </div>
      <div slot="clickTotal" slot-scope="clickTotal, row">
        {{row | clickTotal}}%
      </div>
      <div slot="conversionTotal" slot-scope="conversionTotal, row">
        {{row | conversionTotal}}%
      </div>
      <div slot="sender" slot-scope="sender">
        {{sender | senderEmail}}
      </div>
      <div slot="useSec" slot-scope="useSec">
        <span v-if="useSec != null">{{useSec | secToMin}}</span>
      </div>
      <div slot="status" slot-scope="status">
        <span v-if="status === 'wait'" class="text-muted font-mini">待开始</span>
        <span v-else-if="status === 'started'" class="text-primary font-mini">发邮中</span>
        <span v-else-if="status === 'completed'" class="text-success font-mini">完成</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <activity-editor ref="editor" @saved="loadData()"></activity-editor>
  </app-page>
</template>

<script>
import { ROUTE_EDM_ACTIVITY, ROUTE_EDM_ACTIVITY_EMAILS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { listActivity, copyActivity } from '@/http/api/edm-activity'
import ActivityEditor from './comp/activity-editor'
import kit from '@/utils/kit'

export default {
  components: { ActivityEditor },
  data () {
    return {
      routeName: ROUTE_EDM_ACTIVITY,
      searchParams: {
        activityId: null,
        status: null
      },
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '操作', width: 120, scopedSlots: { customRender: 'buttons' } },
        { title: '状态', dataIndex: 'status', width: 60, scopedSlots: { customRender: 'status' } },
        { title: '标题', dataIndex: 'title', width: 240 },
        { title: '营销代码', dataIndex: 'activityCode', width: 80 },
        { title: '转化率', dataIndex: 'conversionTotal', width: 80, scopedSlots: { customRender: 'conversionTotal' } },
        { title: '转化金额($)', dataIndex: 'conversionAmount', width: 100 },
        { title: '创建时间', dataIndex: 'createTime', width: 140 }
      ]
    }
  },
  computed: {
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    }
  },
  filters: {
    secToMin (sec) {
      return kit.num.toFixedTrimZero(sec / 60)
    },
    senderEmail (sender) {
      return null
      // return JSON.parse(sender).email
    },
    conversionTotal (row) {
      if (row.sendTotal > 0) {
        return kit.num.toFixedTrimZero(row.conversionTotal / row.sendTotal * 100.0, 2)
      } else {
        return 0
      }
    },
    clickTotal (row) {
      if (row.clickTotal > 0) {
        return kit.num.toFixedTrimZero(row.clickTotal / row.sendTotal * 100.0, 2)
      } else {
        return 0
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      listActivity()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    navDetail (row) {
      this.$router.push({ name: ROUTE_EDM_ACTIVITY_EMAILS, params: { activityId: row.id } })
    },
    handleCopy (row) {
      this.$confirm({
        title: '确认',
        content: `确定要复制【${row.title}】吗？`,
        onOk: () => {
          this.dataLoading = true
          copyActivity()
            .complete(() => (this.dataLoading = false))
            .success(resp => {
              this.reloadData()
            })
            .send(row.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
