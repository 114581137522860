/**
 * @author jiangxingshang
 * @date 2023/4/3
 */

export const getCheckSuccessSound = function () {
  return new Audio('https://pc.order.xinsitech.com/sound-success.wav')
}

export const getCheckFailSound = function () {
  return new Audio('https://pc.order.xinsitech.com/sound-fail.wav')
}
