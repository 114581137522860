<template>
  <div class="folder-container">
    <div class="header">
      <a-input-search placeholder="输入关键字查找目录" @change="handleSearchValueChange" :allow-clear="true" />
    </div>
    <div class="body" @contextmenu.prevent="handleBodyRightClick">
      <loading :loading="loading"></loading>
      <context-menu ref="menu" @menuItemClick="handleContextMenuClick">
        <context-menu-item name="add-root" :disabled="loading">
          <div class="tree-menu-item">
            <a-icon type="folder-add" theme="filled" />
            <span>新建根目录</span>
          </div>
        </context-menu-item>
        <context-menu-item name="add-sub" :disabled="menuItemDisabled">
          <div class="tree-menu-item">
            <a-icon type="folder-add" theme="filled" />
            <span>新建子目录到<span class="folder-name">{{ folderNameOnContextMenu }}</span></span>
          </div>
        </context-menu-item>
        <context-menu-item name="edit" :disabled="menuItemDisabled">
          <div class="tree-menu-item">
            <a-icon type="edit" theme="filled" />
            <span>修改目录名</span>
          </div>
        </context-menu-item>
        <context-menu-item name="delete" :disabled="menuItemDisabled">
          <div class="tree-menu-item">
            <a-icon type="delete" theme="filled" />
            <span>删除<span class="folder-name">{{ folderNameOnContextMenu }}</span></span>
          </div>
        </context-menu-item>
        <context-menu-item name="reload">
          <div class="tree-menu-item">
            <a-icon type="reload" />
            <span>重新加载</span>
          </div>
        </context-menu-item>
        <context-menu-item name="expand" :disabled="menuItemDisabledOnEmptyTree">
          <div class="tree-menu-item">
            <a-icon type="column-height" />
            <span>展开所有目录</span>
          </div>
        </context-menu-item>
        <context-menu-item name="collapse" :disabled="menuItemDisabledOnEmptyTree">
          <div class="tree-menu-item">
            <a-icon type="vertical-align-middle" />
            <span>收起所有目录</span>
          </div>
        </context-menu-item>
      </context-menu>

      <div v-show="treeData.length === 0" class="text-muted font-mini" style="margin-top: 50px; text-align: center">没有目录，右键创建新目录</div>
      <a-tree
          v-show="treeData.length > 0"
          :tree-data="treeData"
          show-icon
          :replace-fields="replaceFields"
          :expanded-keys.sync="folderExpandedIds"
          :auto-expand-parent="autoExpandParent"
          @rightClick="handleRightClick"
          draggable
          @drop="handleDrop"
          @select="handleSelect"
          @expand="handleExpand"
      >
        <a-icon slot="switcherIcon" type="down" />
        <a-icon type="folder" />
        <a-icon type="folder-open" />
        <template slot="custom" slot-scope="{ expanded }">
          <a-icon :type="expanded ? 'folder-open' : 'folder'" />
        </template>
        <template slot="title" slot-scope="{ name }">
          <span :inner-html.prop="name | nodeTitleHighlight(searchValue)"></span>
        </template>
      </a-tree>
      <folder-editor ref="editor" @saved="handleFolderSaved"></folder-editor>
    </div>
  </div>
</template>

<script>
import FolderEditor from './comp/folder-editor'
import { listFolderByTree, updateFolderPosition, deleteFolder } from '@/http/api/folder'
import kit from '@/utils/kit'

export default {
  components: { FolderEditor },
  data () {
    return {
      loading: false,
      replaceFields: {
        title: 'name',
        key: 'id'
      },
      treeData: [],
      folderOnRightClick: null, // 右键时的目录对象数据
      folderExpandedIds: [],
      searchValue: null,
      autoExpandParent: true
    }
  },
  computed: {
    menuItemDisabled () {
      return this.folderOnRightClick == null
    },
    menuItemDisabledOnEmptyTree () {
      return this.treeData.length === 0
    },
    folderNameOnContextMenu () {
      if (this.folderOnRightClick) {
        let name = this.folderOnRightClick.name
        if (name.length > 30) {
          name = name.substring(0, 30) + '...'
        }
        return `"${name}"`
      } else {
        return '目录'
      }
    }
  },
  filters: {
    nodeTitleHighlight (title, highlightValue) {
      if (title && highlightValue && highlightValue.length > 0) {
        return title.replaceAll(highlightValue, `<span style="color: #3572b1">${highlightValue}</span>`)
      } else {
        return title
      }
    }
  },
  methods: {
    /**
     * 遍历树结构数据。
     * @param treeData {Array} 树结构数据
     * @param callback {Function} (folder, folderIndexInArray, array, parentFolder) 返回false会终止树的继续遍历。
     * @param [parent]
     * @param [level]
     */
    walkTree (treeData, callback, parent, level) {
      kit.tree.walk(treeData, callback, parent, level)
    },
    findNodeInTree (treeData, id) {
      return kit.tree.findNode(treeData, id)
    },
    loadOnDialogOpen () {
      if (this.treeData.length === 0) {
        this.loadFolder()
      }
    },
    loadFolder () {
      if (this.loading) {
        return
      }
      this.loading = true
      listFolderByTree()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.walkTree(resp.data, (item, indexInArray) => {
            item.scopedSlots = { icon: 'custom' }
            item.scopedSlots = { title: 'title' }
          })
          this.treeData = resp.data
        })
        .send()
    },
    getTreeData () {
      return this.treeData
    },
    handleRightClick ({ event, node }) {
      this.openContextMenu({ x: event.clientX + 5, y: event.clientY + 5 }, node.dataRef)
      event.stopPropagation()
    },
    handleBodyRightClick (event) {
      this.openContextMenu({ x: event.clientX + 5, y: event.clientY + 5 }, null)
    },
    openContextMenu (position, folder) {
      this.folderOnRightClick = folder
      this.$refs.menu.open(position, folder)
    },
    handleContextMenuClick (name, data) {
      switch (name) {
        case 'add-root':
          this.$refs.editor.open(null, null, this.treeData.length)
          break
        case 'add-sub':
          this.$refs.editor.open(null, data, data.children.length)
          break
        case 'edit':
          this.$refs.editor.open(data)
          break
        case 'delete':
          this.handleDeleteFolder(data)
          break
        case 'reload':
          this.loadFolder()
          break
        case 'expand':
          this.expandTree()
          break
        case 'collapse':
          this.collapseTree()
          break
        default:
      }
    },
    expandTree () {
      const tmp = []
      this.walkTree(this.treeData, (item, index, arr) => {
        if (item.children && item.children.length > 0) {
          tmp.push(item.id)
        }
      })
      this.folderExpandedIds = tmp
    },
    collapseTree () {
      this.folderExpandedIds = []
    },
    handleExpand (expandedKeys) {
      this.folderExpandedIds = expandedKeys
      this.autoExpandParent = false
    },
    handleDrop (info) {
      const targetNode = info.node // 移动节点后，鼠标释放的节点
      const dragNode = info.dragNode
      const dropToGap = info.dropToGap
      const dropKey = targetNode.eventKey
      const dragKey = dragNode.eventKey
      const dropPos = targetNode.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      const data = [...this.treeData]

      const targetNodeData = this.findNodeInTree(data, dropKey)
      const moveNodeData = this.findNodeInTree(data, dragKey)
      const nodePositionDto = {
        parentId: null,
        moveId: dragKey,
        nodePosList: []
      }
      let nodes = []
      if (dropToGap) {
        let arr = null
        let targetNodeIndex = null
        if (targetNodeData.parentId == null) {
          // 作为根目录放置
          arr = data
          nodePositionDto.parentId = null
        } else {
          // 和targetFolder同级
          const p = this.findNodeInTree(data, targetNodeData.parentId)
          arr = p.children || []
          nodePositionDto.parentId = p.id
        }
        kit.arr.removeItem(arr, moveNodeData)
        targetNodeIndex = arr.findIndex(item => item.id === targetNodeData.id)
        if (dropPosition === -1) {
          // 移到节点的头顶
          arr.splice(targetNodeIndex, 0, moveNodeData)
        } else {
          // 移到节点的下面
          arr.splice(targetNodeIndex + 1, 0, moveNodeData)
        }
        nodes = arr
      } else {
        // 放置到targetNode的下面（最后一个位置）
        kit.arr.removeItem(targetNodeData.children, moveNodeData)
        targetNodeData.children = targetNodeData.children || []
        targetNodeData.children.push(moveNodeData)
        nodes = targetNodeData.children
        nodePositionDto.parentId = targetNodeData.id
      }

      nodes.forEach((f, index) => {
        nodePositionDto.nodePosList.push({
          id: f.id,
          position: index
        })
      })
      this.moveFolder(nodePositionDto)
    },
    handleSelect (selectedKeys) {
      if (selectedKeys.length === 0) {
        this.$emit('folderSelected', null)
      } else {
        const folder = this.findNodeInTree(this.treeData, selectedKeys[0])
        const data = {
          folder: folder,
          allChildrenIds: []
        }
        this.walkTree([folder], f => {
          if (f.id !== folder.id) {
            data.allChildrenIds.push(f.id)
          }
        })
        this.$emit('folderSelected', data)
      }
    },
    handleFolderSaved () {
      this.loadFolder()
    },
    moveFolder (folderPositionDto) {
      this.loading = true
      updateFolderPosition()
        .complete(() => {
          this.loading = false
          this.loadFolder()
        })
        .send(folderPositionDto)
    },
    handleDeleteFolder (folder) {
      let arr
      this.walkTree(this.treeData, (item, index, array, parent) => {
        if (folder.id === item.id) {
          arr = array
          return false
        }
      })
      this.$confirm({
        title: '确认',
        content: '删除目录会连同子目录也一起删除，确定要删除吗？',
        onOk: () => {
          this.loading = true
          deleteFolder()
            .complete(() => {
              this.loading = false
            })
            .success(() => {
              kit.arr.removeItem(arr, folder)
            })
            .send(folder.id)
        }
      })
    },
    handleSearchValueChange (e) {
      const value = e.target.value
      const expandedKeys = []
      this.walkTree(this.treeData, node => {
        if (value && value.length > 0 && node.name.includes(value)) {
          expandedKeys.push(node.id)
        }
      })
      this.autoExpandParent = true
      this.searchValue = value
      this.folderExpandedIds = expandedKeys
    },
    reset () {
      this.treeData = []
      this.folderOnRightClick = null
    }
  }
}
</script>

<style lang="less" scoped>
.folder-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
@headerHeight: 40px;
.header {
  height: @headerHeight;
  line-height: @headerHeight;
  padding: 0 10px;
  text-align: right;
}
.body {
  position: absolute;
  top: @headerHeight;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  overflow: auto
}
.tree-menu-item {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  & > span {
    margin-left: 5px;
  }
  .folder-name {
    color: #818181;
  }
}

</style>
