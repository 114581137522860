import kit from '@/utils/kit'

export const LOGISTICS_COMPANY_YT = 'yt'
export const LOGISTICS_COMPANY_XP = 'ishipper'
export const LOGISTICS_COMPANY_FC = 'fucang'
export const LOGISTICS_COMPANY_YW = 'yanwen'
export const LOGISTICS_COMPANY_YHT = 'yihaitong'
export const LOGISTICS_COMPANY_SUPPLIER = 'supplier'
export const LOGISTICS_COMPANY_CANGSOU = 'cangsou'
export const LOGISTICS_COMPANY_SHIPOUT = 'shipout'
export const LOGISTICS_COMPANY_YAN_LADY = 'yanlady'
export const LOGISTICS_COMPANY_SHIPPO = 'shippo'
export const LOGISTICS_COMPANY_BEIRUI_EXCEL = 'beiruixls'
export const LOGISTICS_COMPANY_HAILIXUAN = 'hailixuan'
export const LOGISTICS_COMPANY_LINGXING = 'lingxing'
export const LOGISTICS_COMPANY_HEXING = 'hexing'
export const LOGISTICS_COMPANY_UAHPET_OMS = 'uahpetoms'
export const LOGISTICS_COMPANY_UAHPET_ZMAZON = 'uahpetamazon'

export const LOGISTICS_COMPANY_MAPPING = {
  [LOGISTICS_COMPANY_YT]: '云途',
  [LOGISTICS_COMPANY_XP]: '夏普',
  [LOGISTICS_COMPANY_FC]: '美国福仓',
  [LOGISTICS_COMPANY_BEIRUI_EXCEL]: '贝瑞Excel',
  [LOGISTICS_COMPANY_YW]: '燕文',
  [LOGISTICS_COMPANY_SHIPPO]: '美国PO',
  [LOGISTICS_COMPANY_YHT]: '一海通',
  [LOGISTICS_COMPANY_SUPPLIER]: '厂商自发',
  [LOGISTICS_COMPANY_CANGSOU]: '仓搜',
  [LOGISTICS_COMPANY_SHIPOUT]: '福仓ERP',
  [LOGISTICS_COMPANY_YAN_LADY]: 'Yan仓',
  [LOGISTICS_COMPANY_HAILIXUAN]: '海力轩',
  [LOGISTICS_COMPANY_LINGXING]: '领星',
  [LOGISTICS_COMPANY_HEXING]: '合星',
  [LOGISTICS_COMPANY_UAHPET_OMS]: '有哈OMS',
  [LOGISTICS_COMPANY_UAHPET_ZMAZON]: '有哈Amazon'
}

export const LOGISTICS_COMPANY_LIST = kit.obj.toArray(LOGISTICS_COMPANY_MAPPING)

/**
 * 海外仓物流
 */
export const LOGISTICS_ABROAD_LIST = [
  { id: LOGISTICS_COMPANY_SHIPOUT, name: LOGISTICS_COMPANY_MAPPING[LOGISTICS_COMPANY_SHIPOUT] },
  { id: LOGISTICS_COMPANY_CANGSOU, name: LOGISTICS_COMPANY_MAPPING[LOGISTICS_COMPANY_CANGSOU] }
]
