<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>行高</label>
        <span>
          <a-input-number v-model="section.lineHeight" :min="1" :max="6" style="width: 100%;" />
        </span>
      </div>
      <div class="detail-item">
        <label>标题</label>
        <span>
          <a-input v-model="section.title" style="width: 100%;" />
        </span>
      </div>
      <div class="detail-item">
        <label>段落</label>
        <span>
          <rich-editor :resize="false" :simple="true" :content.sync="section.text"></rich-editor>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RichEditor from '@/components/rich-editor'
import ParagraphModel from '../model/paragraph'

export default {
  components: { RichEditor },
  props: {
    section: { type: ParagraphModel }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
