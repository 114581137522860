<template>
  <app-page :route-name="routeName" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-form-model layout="inline">
        <a-form-model-item label="订单日期">
          <a-range-picker v-model="updateProfitDate" value-format="yyyy-MM-DD" :ranges="dateRanges" style="width: 220px;" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleUpdate">更新数据</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>


    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="同步日期">
          <a-range-picker v-model="searchParams.date" value-format="yyyy-MM-DD" :ranges="dateRanges" style="width: 220px;" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.status" mode="multiple" style="width: 300px;">
            <a-select-option value="wait">待同步</a-select-option>
            <a-select-option value="error">错误</a-select-option>
            <a-select-option value="completed">同步完成</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reloadData">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :scroll="{ y: antdTableY }"
        :pagination="false"
        :loading="dataLoading"
    >
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_PROFIT_LOSS_SYNC } from '@/router/router-constants'
import moment from 'moment'
import { listSyncRecord, updateFromDateRange } from '@/http/api/profit-loss'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_PROFIT_LOSS_SYNC,
      pageLoading: false,
      searchParams: {
        date: [],
        status: []
      },
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        一周前: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        两周前: [moment().subtract(2, 'weeks').startOf('week'), moment().subtract(2, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        一个月前: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        两个月前: [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')]
      },
      dataColumns: [
        { title: '订单号', dataIndex: 'orderNo' },
        { title: '同步日期', dataIndex: 'syncTime' },
        { title: '状态', dataIndex: 'status' },
        { title: '同步原因', dataIndex: 'description' },
        { title: '错误消息', dataIndex: 'errorMessage' }
      ],
      dataList: [],
      dataLoading: false,
      updateProfitDate: []
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.date.length === 2) {
        p.syncStartDate = p.date[0]
        p.syncEndDate = p.date[1]
        delete p.date
      }
      this.dataLoading = true
      listSyncRecord()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleUpdate () {
      if (this.updateProfitDate.length === 2) {
        const startDate = this.updateProfitDate[0]
        const endDate = this.updateProfitDate[1]
        this.$confirm({
          title: '确认',
          content: `确定要更新订单日期${startDate} ~ ${endDate}范围内的损益数据吗？`,
          onOk: () => {
            this.updateProfit(startDate, endDate)
          }
        })
      } else {
        this.$message.error('请选择一个日期范围。')
      }
    },
    updateProfit (startDate, endDate) {
      this.pageLoading = true
      updateFromDateRange()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.reloadData()
        })
        .send(startDate, endDate)
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style lang="less" scoped>

</style>
