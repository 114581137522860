export default class FileUrl {
  constructor (data) {
    this.url = data.url
    this.thumbnailUrl = data.thumbnailUrl
    this.imageUrl100x = data.imageUrl100x
    this.imageUrl300x = data.imageUrl300x
    this.imageUrl500x = data.imageUrl500x
    this.imageUrl1200x = data.imageUrl1200x
  }

  getCoverUrl () {
    return this.thumbnailUrl
  }

  getOriginUrl () {
    return this.url
  }

  getFileId () {
    // 子类必须覆盖此函数返回文件的ID
  }
}
