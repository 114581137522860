<template>
  <a-modal
      title="部门管理"
      v-model="visible"
      @cancel="handleCancel"
      :width="850"
  >
    <div slot="footer"></div>

    <div style="display: flex; margin-bottom: 10px;">
      <a-input v-model="newName" placeholder="请输入部门名称"></a-input>
      <a-button style="margin-left: 10px;" type="primary" @click="handleAdd" :loading="loading">添加</a-button>
    </div>

    <ul class="dept-list list-group">
      <li class="list-group-item list-head">
        <div class="item-wrapper">
          <div class="col-dept">部门</div>
          <div class="col-shop">关联店铺</div>
        </div>
      </li>
      <li class="list-group-item" v-for="d in deptList" :key="d.id">
        <div class="item-wrapper">
          <div class="col-dept">
            <a-input v-model="d.name"></a-input>
            <div class="tools">
              <a-button size="small" @click="handleUpdate(d)" :loading="loading">更新</a-button>
              <a-button size="small" type="danger" :loading="loading" @click="handleDelete(d)">删除</a-button>
              <a-button size="small" @click="handleOpenBindShop(d)" :loading="loading">关联店铺</a-button>
            </div>
          </div>
          <div class="col-shop">
            <div class="shop-list" v-if="d.shopIds">
              <span class="shop-item" v-for="s in shops" :key="s.id" v-show="d.shopMap[s.id] === true">{{s.name}}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="bind-shop" v-show="bindShopVisible">
      <p class="font-mini" v-if="bindShopForDept">正在为部门【{{bindShopForDept.name}}】关联店铺</p>
      <div class="shop-list">
        <span v-for="s in shops" :key="s.id">
          <a-checkbox v-model="s.checked">{{s.name}}</a-checkbox>
        </span>
      </div>
      <div class="buttons">
        <a-button @click="handleSaveDeptShopBind" type="primary" :loading="bindShopLoading">保存</a-button>
        <a-button @click="bindShopVisible = false" :loading="bindShopLoading">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { listAllDto, add, update, deleteById, bindShops } from '@/http/api/dept'
import kit from '@/utils/kit'
import { listShopOptions } from '@/http/api/shop'

export default {
  components: {
  },
  data () {
    return {
      visible: false,
      deptList: [],
      newName: null,
      loading: false,
      bindShopVisible: false,
      // 当前为哪个部门分配店铺
      bindShopForDept: null,
      bindShopLoading: false,
      shops: []
    }
  },
  watch: {
    bindShopVisible (v) {
      if (v) {
        this.loadShopOptions()
      } else {
        this.bindShopForDept = null
        this.shops.forEach(item => (item.checked = false))
        this.loadDept()
      }
    }
  },
  methods: {
    open () {
      this.visible = true
      this.loadShopOptions()
      this.loadDept()
    },
    handleCancel () {
      this.$emit('closed')
    },
    loadDept () {
      listAllDto()
        .success(resp => {
          for (const d of resp.data) {
            d.shopMap = {}
            if (d.shopIds) {
              for (const sid of d.shopIds.split(',')) {
                d.shopMap[sid] = true
              }
            }
          }
          this.deptList = resp.data
        })
        .send()
    },
    handleAdd () {
      if (this.newName) {
        this.saveDept({
          id: null,
          name: this.newName
        })
      } else {
        this.$message.warning('请输入部门名称。')
      }
    },
    handleUpdate (dept) {
      this.saveDept(dept)
    },
    saveDept (dept) {
      const api = dept.id ? update : add
      this.loading = true
      api()
        .complete(() => (this.loading = false))
        .success(() => {
          this.loadDept()
          this.$message.success('保存成功。')
          this.newName = null
        })
        .send(dept)
    },
    handleDelete (dept) {
      deleteById()
        .complete(() => (this.loading = false))
        .success(() => {
          kit.arr.removeItem(this.deptList, dept)
          this.$message.success('删除成功。')
        })
        .send(dept.id)
    },
    loadShopOptions () {
      if (this.shops.length === 0) {
        listShopOptions()
          .success(resp => {
            for (const d of resp.data) {
              d.checked = false
            }
            this.shops = resp.data
          })
          .send()
      }
    },
    handleOpenBindShop (dept) {
      this.bindShopForDept = dept
      this.bindShopVisible = true
      for (const s of this.shops) {
        s.checked = dept.shopMap[s.id]
      }
    },
    handleSaveDeptShopBind () {
      const shopIdList = this.shops.filter(item => item.checked).map(item => item.id)
      this.bindShopLoading = true
      bindShops()
        .complete(() => (this.bindShopLoading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.bindShopVisible = false
          this.loadDept()
        })
        .send(this.bindShopForDept.id, shopIdList.join(','))
    }
  }
}
</script>

<style lang="less" scoped>
.main-wrapper {
  display: flex;
  align-items: center;
  .main {
    flex: 1;
    padding-right: 10px;
  }
  .buttons {
    min-width: 240px;
  }
}

.dept-list {
  .list-head {
    font-weight: bold;
  }
  .item-wrapper {
    display: flex;
    .col-dept {
      width: 200px;
      max-width: 200px;
      margin-right: 10px;
      padding-right: 10px;
      border-right: solid 1px #eee;
      .tools {
        margin-top: 5px;
        text-align: center;
        visibility: hidden;
      }
    }
    .col-shop {
      flex: 1;
      .shop-list {
        display: flex;
        flex-wrap: wrap;
        .shop-item {
          display: block;
          font-size: 12px;
          background-color: #515152;
          border-radius: 5px;
          color: #fff;
          padding: 5px 8px;
          margin: 0 8px 8px 0;
        }
      }
    }
    &:hover .tools {
      visibility: visible;
    }
  }
  .list-group-item:not(.list-head):hover {
    background-color: #eee;
  }
}

.bind-shop {
  position: absolute;
  left: 0;
  top: 55px;
  right: 0;
  bottom: 0;
  padding: 10px;
  background-color: #fff;

  .shop-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2px 2px;
  }

  .buttons {
    margin-top: 30px;
    text-align: center;
  }
}
</style>
