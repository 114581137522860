<template>
  <communication-editor :visible.sync="visible" title="备注" :loading="loading" @submit="handleSave()">
    <rich-editor :content.sync="content" :simple="true" :resize="true"></rich-editor>
  </communication-editor>
</template>

<script>
import CommunicationEditor from '@/pages/influencer/detail/comm-comp/communication-editor'
import CommunicationMixin from '@/pages/influencer/detail/comm-comp/communication-mixin'
import { TYPE_REMARK } from '@/constants/communication-type'

export default {
  components: { CommunicationEditor },
  mixins: [CommunicationMixin],
  data () {
    return {
      type: TYPE_REMARK,
      content: null
    }
  },
  methods: {
    getContent () {
      return this.content
    },
    onHide () {
      this.content = null
    }
  }
}
</script>

<style scoped>

</style>
