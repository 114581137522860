<template>
    <a-layout-header class="app-header">

      <div class="left-bar" @click="$router.push({name: routeDashboard})">
        <img class="logo" :src="logoUrl" v-if="logoUrl" />
        <div class="app-name">{{ appName }}</div>
        <div class="app-version" v-show="version">（{{version}}）</div>
      </div>
      <div class="center-bar">
        <div class="wrapper">
          <div class="box">
            <div class="row" v-authority="$authority.headSummaryDelivery">
              <label>物流：</label>
              <div class="list">
                <div class="list-item">
                  <span class="label">异常：</span>
                  <span class="total text-danger">{{centerBarData.delivery.exception}}</span>
                </div>
                <div class="list-item">
                  <span class="label">超过10天未送达：</span>
                  <span class="total text-warning">{{centerBarData.delivery.unArrive}}</span>
                </div>
              </div>
            </div>
            <div class="row" v-authority="$authority.headSummaryOrder">
              <label>订单：</label>
              <div class="list">
                <div class="list-item">
                  <span class="label">新单：</span>
                  <span class="total text-primary">{{centerBarData.order.newOrder}}</span>
                </div>
                <div class="list-item">
                  <span class="label">未下单：</span>
                  <span class="total">{{centerBarData.order.noProduce}}</span>
                </div>
                <div class="list-item">
                  <span class="label">部分发货：</span>
                  <span class="total">{{centerBarData.order.partSend}}</span>
                </div>
                <div class="list-item">
                  <span class="label">发货超时：</span>
                  <span class="total text-warning">{{centerBarData.order.timeout}}</span>
                </div>
              </div>
            </div>
            <div class="row" v-authority="$authority.headSummaryOrderSale">
              <label>销售（{{centerBarData.orderAmount.currency}}）：</label>
              <div class="list">
                <div class="list-item">
                  <span class="label">今日售额：</span>
                  <span class="total text-success">{{centerBarData.orderAmount.todaySale}}</span>
                </div>
                <div class="list-item">
                  <span class="label">当月售额：</span>
                  <span class="total text-success">{{centerBarData.orderAmount.thisMonthSale}}</span>
                </div>
                <div class="list-item">
                  <span class="label">当月退款：</span>
                  <span class="total text-danger">{{centerBarData.orderAmount.thisMonthRefund}}</span>
                </div>
                <div class="list-item" v-if="centerBarData.orderAmount.refundRate > 0">
                  <span class="label">当月退款率：</span>
                  <span class="total text-danger">{{centerBarData.orderAmount.refundRate}}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-bar">
        <div class="bar-item user-profile" @click.stop.prevent="handleUserProfileClick">
          <span class="username">
            <span v-if="supplierName">（{{supplierName}}）</span>
            {{name}}
          </span>
          <a-icon type="down" style="font-size: 12px;" />
          <ul class="menu" v-show="menuVisible">
            <li class="menu-item" @click="$refs.pwd.open()">修改密码</li>
            <li class="menu-item" @click="logout">退出系统</li>
          </ul>
        </div>
      </div>


      <modal
          ref="pwd"
          title="密码更新"
          :loading="pwdEditor.updating"
          @ok="handleUpdateMyPassword"
      >
        <a-form-model ref="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
          <a-form-model-item label="密码" class="app_required-input">
            <a-input v-model="pwdEditor.password"/>
          </a-form-model-item>
        </a-form-model>
      </modal>
    </a-layout-header>
</template>

<script>
import { logout, updateMyPassword } from '@/http/api/admin'
import { ROUTE_LOGIN, ROUTE_HOME } from '@/router/router-constants'
import { userStore, appStore } from '@/store'
import kit from '@/utils/kit'
import { getCountWithExceptionAndUnArrive } from '@/http/api/order-delivery'
import initSearchParams, { processParamsBeforeRequest } from '@/pages/order/order-status-search-params'
import { listOrders, getOrderAmounts } from '@/http/api/order'

export default {
  created () {
  },
  data () {
    return {
      passwordModalVisible: false,
      routeDashboard: ROUTE_HOME,
      menuVisible: false,
      centerBarData: {
        delivery: {
          exception: 0,
          unArrive: 0
        },
        order: {
          newOrder: 0,
          noProduce: 0,
          partSend: 0,
          timeout: 0
        },
        orderAmount: {
          currency: null,
          todaySale: 0,
          thisMonthSale: 0,
          thisMonthRefund: 0,
          refundRate: 0
        }
      },
      pwdEditor: {
        updating: false,
        password: null
      }
    }
  },
  computed: {
    ...(userStore.mapState(['name', 'supplierName', 'supplier'])),
    ...(appStore.mapState({
      version: 'version',
      logoUrl: 'logoUrl',
      appName: 'name'
    }))
  },
  watch: {
  },
  filters: {
  },
  methods: {
    logout () {
      this.$confirm({
        title: '提示',
        content: '确定要退出系统吗？',
        onOk: () => {
          logout()
            .success(() => {
              this.$router.push({ name: ROUTE_LOGIN })
            })
            .send()
        }
      })
    },
    handleUserProfileClick () {
      if (!this.menuVisible) {
        kit.dom.addEvent(document.documentElement, 'click', this.hideMenu)
      }
      this.menuVisible = !this.menuVisible
    },
    hideMenu () {
      this.menuVisible = false
      kit.dom.removeEvent(document.documentElement, 'click', this.hideMenu)
    },
    handleUpdateMyPassword () {
      this.pwdEditor.updating = true
      updateMyPassword()
        .complete(() => (this.pwdEditor.updating = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.pwdEditor.password = null
          this.$nextTick(() => {
            this.$refs.pwd.close()
          })
        })
        .send(this.pwdEditor.password)
    }
  },
  mounted () {
    if (this.$authorityCheck.check(this.$authority.headSummaryDelivery)) {
      getCountWithExceptionAndUnArrive()
        .success(resp => {
          this.centerBarData.delivery.exception = resp.data.exception
          this.centerBarData.delivery.unArrive = resp.data.unArrive
        })
        .send(10)
    }

    if (this.$authorityCheck.check(this.$authority.headSummaryOrder)) {
      listOrders()
        .success(resp => {
          this.centerBarData.order.newOrder = resp.data.total
        })
        .send(processParamsBeforeRequest(Object.assign(initSearchParams('paid'), { limit: 0 })))

      listOrders()
        .success(resp => {
          this.centerBarData.order.noProduce = resp.data.total
        })
        .send(processParamsBeforeRequest(Object.assign(initSearchParams('noProduce'), { limit: 0 })))

      listOrders()
        .success(resp => {
          this.centerBarData.order.partSend = resp.data.total
        })
        .send(processParamsBeforeRequest(Object.assign(initSearchParams('shippedPart'), { limit: 0 })))

      listOrders()
        .success(resp => {
          this.centerBarData.order.timeout = resp.data.total
        })
        .send(processParamsBeforeRequest(Object.assign(initSearchParams('timeout'), { limit: 0 })))
    }

    if (this.$authorityCheck.check(this.$authority.headSummaryOrderSale)) {
      getOrderAmounts()
        .success(resp => {
          this.centerBarData.orderAmount.currency = resp.data.currency
          this.centerBarData.orderAmount.todaySale = resp.data.todaySaleAmount
          this.centerBarData.orderAmount.thisMonthSale = resp.data.thisMonthSaleAmount
          this.centerBarData.orderAmount.thisMonthRefund = resp.data.thisMonthRefundAmount
          this.centerBarData.orderAmount.refundRate = resp.data.refundRate
        })
        .send()
    }
  }
}
</script>

<style lang="less" scoped>
  @height: 74px;
  .app-header {
    display: flex;
    flex-direction: row;
    padding: 0 20px !important;
    height: @height;
    line-height: @height;
    background-color: #2a2a2a;
    color: #fff;
    .left-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      cursor: pointer;
      .logo {
        max-width: 120px;
        max-height: 50px;
        margin-right: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .app-name {
        font-size: 16px;
      }
      .app-version {
        font-size: 12px;
      }
    }
    .right-bar {
      position: relative;
      line-height: @height;
      height: @height;
      .bar-item {
        float: right;
        position: relative;
        padding: 0 10px;
        font-size: 14px;
        color: #efefef;
        cursor: pointer;
        &:hover {
          background-color: #393939;
        }
      }
    }

    .center-bar {
      flex: 1;
      text-align: center;
      font-size: 12px;
    }

    .center-bar .box {
      display: inline-block;
      line-height: normal;
      margin-top: 10px;
      color: #e0e0e0;
    }
    .center-bar .row {
      display: flex;
    }
    .center-bar .row > label {
      text-align: right;
      color: #9a9999;
    }
    .center-bar .list {
      display: flex;
      flex: 1;
    }
    .center-bar .list .list-item {
    }
    .center-bar .list .list-item + .list-item {
      margin-left: 40px;
    }
    .center-bar .list-item .total {
      font-weight: bold;
    }
    .center-bar .amount {
      display: inline-block;
    }
    .center-bar .amount + .amount {
      margin-left: 10px;
    }
  }

  .bar-item.user-profile {
    .username {
      margin-right: 10px;
    }

    .menu {
      position: absolute;
      margin: 0;
      padding: 0;
      top: @height;
      right: 0;
      z-index: 500;
      min-width: 150px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #393939;
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      list-style-type: none;
      overflow: hidden;
      .split-line {
        height: 1px;
        padding: 0;
        margin: 0 15px;
        background-color: #5c5c5c;
      }
      .menu-item {
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0 15px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: #414141;
        }
        &:active {
          background-color: #464646;
        }
      }
    }
  }

  .bar-item {
    .text {
      font-size: 13px;
      margin-left: 3px;
    }
    i {
      position: relative;
    }
    .badge {
      display: inline-block;
      position: absolute;
      top: -12px;
      left: -12px;
      padding: 3px 4px;
      min-width: 12px;
      text-align: center;
      border-radius: 10px;
      font-size: 12px !important;
      color: #fff;
      background-color: #df5040;
    }
  }
</style>
