import {
  ROUTE_USER,
  ROUTE_WAREHOUSE,
  ROUTE_STOCK_PRODUCT,
  ROUTE_ORDER_DELIVERY,
  ROUTE_T17TRACK,
  ROUTE_ORDER_RETURN,
  ROUTE_AFTER_SALE_SESSION,
  ROUTE_ACCOUNTING,
  ROUTE_STOCK_ALERT,
  ROUTE_PRODUCT_ADD,
  ROUTE_PRODUCT_SKU_GENERATOR,
  ROUTE_SUPPLIER_PRODUCE_ITEM,
  ROUTE_ORDER,
  ROUTE_ORDER_DEMO,
  ROUTE_SUPPLIER_DELIVERY,
  ROUTE_EDM_EMAIL,
  ROUTE_EDM_ACTIVITY,
  ROUTE_ABANDONED_CHECKOUT,
  ROUTE_SUPPLIER_ORDER_DELIVERY,
  ROUTE_AIRWALLEX,
  ROUTE_SETTING_NOTIFY_USER,
  ROUTE_SUPPLIER,
  ROUTE_ORDER_DELIVERY_PREPARE,
  ROUTE_ANALYSIS_COST,
  ROUTE_ANALYSIS_STOCK_ALERT,
  ROUTE_ANALYSIS_SALE,
  ROUTE_ANALYSIS_PROFIT_LOSS,
  ROUTE_SHOP,
  ROUTE_WAREHOUSE_PREPARE_USER,
  ROUTE_SHIPPING_METHOD_ADVICE,
  ROUTE_PRODUCT_SKU_STYLE,
  ROUTE_INFLUENCER,
  ROUTE_PARTNER,
  ROUTE_PARTNER_ADS_COST,
  ROUTE_ORDER_COMMISSION_UPDATE,
  ROUTE_INVENTORY_CHANGE_REQUEST,
  ROUTE_TEST
} from '@/router/router-constants'


const menu = [
  {
    id: 'data-analysis',
    title: '数据分析',
    menus: [
      { id: ROUTE_ANALYSIS_PROFIT_LOSS, title: '损益表', route: ROUTE_ANALYSIS_PROFIT_LOSS },
      { id: ROUTE_ANALYSIS_SALE, title: '销售分析', route: ROUTE_ANALYSIS_SALE },
      { id: ROUTE_ANALYSIS_STOCK_ALERT, title: '库存预警', route: ROUTE_ANALYSIS_STOCK_ALERT },
      { id: ROUTE_ANALYSIS_COST, title: '费用', route: ROUTE_ANALYSIS_COST }
    ]
  },
  {
    id: 'partner',
    title: '合作伙伴',
    menus: [
      { id: ROUTE_PARTNER, title: '客户档案', route: ROUTE_PARTNER },
      { id: ROUTE_PARTNER_ADS_COST, title: '客户广告消耗', route: ROUTE_PARTNER_ADS_COST }
    ]
  },
  {
    id: 'stock-manage',
    title: '库存',
    menus: [
      { id: ROUTE_WAREHOUSE, title: '仓库', route: ROUTE_WAREHOUSE },
      { id: ROUTE_STOCK_PRODUCT, title: '商品', route: ROUTE_STOCK_PRODUCT },
      { id: ROUTE_INVENTORY_CHANGE_REQUEST, title: '库存变更申请', route: ROUTE_INVENTORY_CHANGE_REQUEST },
      { id: ROUTE_STOCK_ALERT, title: '库存预警设置', route: ROUTE_STOCK_ALERT },
      // { id: ROUTE_STOCK_CHECK, title: '盘点', route: ROUTE_STOCK_CHECK },
      // { id: ROUTE_STOCK_TRANSFER, title: '迁移', route: ROUTE_STOCK_TRANSFER },
      // { id: ROUTE_SKU_STOCK_TRANSFER, title: 'SKU库存转移', route: ROUTE_SKU_STOCK_TRANSFER }
    ]
  },
  {
    id: 'order-manage',
    title: '订单',
    menus: [
      { id: ROUTE_ORDER, title: '客户订单', route: ROUTE_ORDER },
      { id: ROUTE_ORDER_DEMO, title: '寄样订单', route: ROUTE_ORDER_DEMO },
      { id: ROUTE_ABANDONED_CHECKOUT, title: '客户弃单', route: ROUTE_ABANDONED_CHECKOUT },
      { id: ROUTE_ORDER_DELIVERY, title: '订单物流', route: ROUTE_ORDER_DELIVERY },
      { id: ROUTE_ORDER_DELIVERY_PREPARE, title: '物流配货', route: ROUTE_ORDER_DELIVERY_PREPARE },
      { id: ROUTE_SUPPLIER_PRODUCE_ITEM, title: '工厂订单', route: ROUTE_SUPPLIER_PRODUCE_ITEM },
      { id: ROUTE_SUPPLIER_DELIVERY, title: '工厂快递', route: ROUTE_SUPPLIER_DELIVERY },
      // { id: ROUTE_ORDER_DELIVERY_ITEM, title: '补填发货成本', route: ROUTE_ORDER_DELIVERY_ITEM },
      { id: ROUTE_ORDER_COMMISSION_UPDATE, title: '订单佣金更新', route: ROUTE_ORDER_COMMISSION_UPDATE }
    ]
  },
  {
    id: 'influencer',
    title: '达人推广',
    menus: [
      { id: ROUTE_INFLUENCER, title: '达人管理', route: ROUTE_INFLUENCER }
    ]
  },
  {
    id: 'supplier-manage',
    title: '供应商',
    menus: [
      { id: ROUTE_SUPPLIER, title: '供应商管理', route: ROUTE_SUPPLIER },
      { id: ROUTE_SUPPLIER_ORDER_DELIVERY, title: '客户发货表', route: ROUTE_SUPPLIER_ORDER_DELIVERY }
    ]
  },
  {
    id: 'aftersale-manage',
    title: '售后服务',
    menus: [
      { id: ROUTE_AFTER_SALE_SESSION, title: '售后服务', route: ROUTE_AFTER_SALE_SESSION },
      { id: ROUTE_ORDER_RETURN, title: '退货', route: ROUTE_ORDER_RETURN }
    ]
  },
  {
    id: 'financial-manage',
    title: '财务',
    menus: [
      { id: ROUTE_ACCOUNTING, title: '记账', route: ROUTE_ACCOUNTING }
    ]
  },
  {
    id: 'edm-manage',
    title: 'EDM',
    menus: [
      { id: ROUTE_EDM_EMAIL, title: '营销邮箱', route: ROUTE_EDM_EMAIL },
      { id: ROUTE_EDM_ACTIVITY, title: '营销活动', route: ROUTE_EDM_ACTIVITY }
    ]
  },
  {
    id: 'product-manage',
    title: '线上产品',
    menus: [
      { id: ROUTE_PRODUCT_ADD, title: '上传产品', route: ROUTE_PRODUCT_ADD },
      { id: ROUTE_PRODUCT_SKU_GENERATOR, title: 'SKU生成', route: ROUTE_PRODUCT_SKU_GENERATOR },
      { id: ROUTE_PRODUCT_SKU_STYLE, title: 'SKU款号生成', route: ROUTE_PRODUCT_SKU_STYLE }
    ]
  },
  {
    id: 'manage',
    title: '维护',
    menus: [
      { id: ROUTE_USER, title: '用户管理', route: ROUTE_USER },
      { id: ROUTE_WAREHOUSE_PREPARE_USER, title: '仓库配货员', route: ROUTE_WAREHOUSE_PREPARE_USER },
      { id: ROUTE_SETTING_NOTIFY_USER, title: '用户通知', route: ROUTE_SETTING_NOTIFY_USER },
      { id: ROUTE_SHOP, title: '店铺', route: ROUTE_SHOP },
      { id: ROUTE_SHIPPING_METHOD_ADVICE, title: '发货渠道配置', route: ROUTE_SHIPPING_METHOD_ADVICE },
      { id: ROUTE_T17TRACK, title: 'T17track', route: ROUTE_T17TRACK },
      { id: ROUTE_AIRWALLEX, title: '空中云汇', route: ROUTE_AIRWALLEX },
      { id: ROUTE_TEST, title: 'Shop API', route: ROUTE_TEST }
    ]
  }
]

export default menu
