import defineApi from '../define-api'
import http from '../application-api'

export const getInternalUrls = defineApi((config) => {
  http.get('/internal_url/prefix/urls', config)
})

export const getInternalUrlsProductList = defineApi((config, keyword) => {
  config.params = {
    keyword: keyword
  }
  http.get('/internal_url/data/products', config)
})

export const getInternalUrlsCollectionList = defineApi((config, keyword) => {
  config.params = {
    keyword: keyword
  }
  http.get('/internal_url/data/collections', config)
})

export const getInternalUrlsPageList = defineApi((config, keyword) => {
  config.params = {
    keyword: keyword
  }
  http.get('/internal_url/data/pages', config)
})

export const getInternalResourceDataById = defineApi((config, id, type) => {
  config.params = {
    id: id,
    type: type
  }
  http.get('/internal_url/data/by_id', config)
})
