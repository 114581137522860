<!-- 商品库存变更申请表单 -->
<template>
  <modal ref="modal" title="商品库存变更申请表" :width="900" :max-height="600" :loading="loading" @ok="handleConfirm" @closed="onClosed">
    <a-form-model ref="form" :model="form" :label-col="{span: 5}" :wrapper-col="{span: 19}">
      <div class="two-col">
        <a-form-model-item label="标题" class="app_required-input">
          <a-input v-model="form.title" :max-length="64"/>
        </a-form-model-item>

        <a-form-model-item label="运费" v-if="form.changeType !== inventoryChangeTypeConstants.del">
          <a-input-number v-model="form.freight" :min="0" :max="10000" :precision="2" style="width: 100%;"></a-input-number>
          <div class="text-danger font-mini" style="line-height: normal;">如果有运费（人民币），会平摊到每个SKU上。</div>
        </a-form-model-item>
      </div>

      <div class="two-col">
        <a-form-model-item label="变更类型" class="app_required-input">
          <a-radio-group v-model="form.changeType">
            <a-radio v-for="opt in inventoryChangeTypeOptions"
                     :key="opt.id"
                     :value="opt.id"
                     :disabled="defaultChangeType && opt.id !== defaultChangeType"
            >{{opt.name}}</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="入库状态" class="app_required-input" v-if="form.changeType !== inventoryChangeTypeConstants.del">
          <a-tooltip placement="topLeft">
            <template slot="title">在途的库存只能看，不能发货，不计算在有效库存内。</template>
            <a-radio-group v-model="form.inventoryStatus">
              <a-radio v-for="opt in inventoryStatusOptions"
                       :key="opt.id"
                       :value="opt.id"
              >{{opt.name}}</a-radio>
            </a-radio-group>
          </a-tooltip>
        </a-form-model-item>
      </div>

      <div class="two-col">
        <a-form-model-item label="出库仓库" v-if="form.changeType === inventoryChangeTypeConstants.transfer" class="app_required-input">
          <a-select v-model="form.fromWarehouseId" style="width: 100%;">
            <a-select-option v-for="opt in warehouseOptions" :key="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="form.changeType === inventoryChangeTypeConstants.transfer ? '入库仓库' : '仓库'" class="app_required-input">
          <a-select v-model="form.toWarehouseId" style="width: 100%;">
            <a-select-option v-for="opt in warehouseOptions" :key="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
    </a-form-model>

    <a-textarea v-model="form.remark" :auto-size="{ minRows: 2, maxRows: 3 }" :max-length="512" placeholder="备注"/>

    <a-divider orientation="left">商品清单</a-divider>

    <div class="left-right-content vertical-center" style="margin-bottom: 8px;">
      <div>
        <a-button @click="handleSelectStockProduct" :disabled="feature.disableModifySku" style="margin-right: 10px;">选择SKU</a-button>
        <a-tooltip placement="topLeft">
          <template slot="title">数据从第二行开始，列1：SKU，列2：数量，列3：单价（可不填）。</template>
          <a-button :loading="importSkuLoading" @click="handleImportProducts" :disabled="feature.disableModifySku">导入SKU</a-button>
        </a-tooltip>
      </div>
      <div>
        <a-tooltip placement="topLeft" v-show="form.changeType === inventoryChangeTypeConstants.inStock">
          <template slot="title">选择供应商，可自动填写成本参考价。</template>
          供应商：
          <a-select v-model="form.supplierId" style="width: 220px;" placeholder="供应商" :allow-clear="true" :disabled="feature.disableSupplierUpdate">
            <a-select-option v-for="opt in supplierOptions" :key="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-tooltip>
      </div>
    </div>

    <a-table
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="stockProducts"
        :pagination="false"
    >
      <div slot="actions" slot-scope="row">
        <a-button size="small" type="danger" @click="handleRemoveProductRow(row)" :disabled="feature.disableModifySku">移除</a-button>
      </div>

      <div slot="image" slot-scope="imageUrl">
        <div class="image-item cover" :style="{'background-image': `url(${imageUrl})`}"></div>
      </div>

      <div slot="total" slot-scope="row">
        <a-input-number v-model="row.total" :min="1" :max="10000" :precision="0" :disabled="feature.disableSkuTotalUpdate"></a-input-number>
      </div>

      <div slot="cost" slot-scope="row">
        <a-input-number v-model="row.cost" :placeholder="(row.referCost || '0') + ''" :max="10000" :precision="2" style="width: 200px;" v-if="form.changeType === inventoryChangeTypeConstants.inStock"></a-input-number>
        <span v-else class="font-mini text-muted">非入库</span>
      </div>
    </a-table>

    <stock-product-select ref="stockProductSelector"></stock-product-select>

  </modal>
</template>

<script>
import { listBySkuList } from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { addInventoryChangeRequest, importProductsFromFile } from '@/http/api/inventory-change-request'
import { listSupplier } from '@/http/api/supplier'
import {
  INVENTORY_CHANGE_TYPE_IN_STOCK,
  INVENTORY_CHANGE_TYPE_TRANSFER,
  INVENTORY_CHANGE_TYPE_DELETE,
  INVENTORY_CHANGE_TYPE_LIST,
  INVENTORY_CHANGE_STATUS_LIST,
  INVENTORY_CHANGE_STATUS_ON_WAY,
  INVENTORY_CHANGE_STATUS_IN_STOCK, INVENTORY_CHANGE_STATUS_DELETE
} from '@/constants/inventory-change-request'
import StockProductSelect from '@/components-business/stock-product-select'
import kit from '@/utils/kit'
import selectFile from '@/utils/select-file'
import { getReferCostByStockProductIdList } from '@/http/api/stock-product-cost'

function createForm () {
  return {
    title: null,
    remark: null,
    changeType: null,
    freight: null,
    fromWarehouseId: null,
    toWarehouseId: null,
    inventoryStatus: null,
    supplierId: null
  }
}

export default {
  components: { StockProductSelect },
  data () {
    return {
      loading: false,
      importSkuLoading: false,
      defaultChangeType: null,
      feature: {
        disableSkuTotalUpdate: false,
        disableModifySku: false,
        disableSupplierUpdate: false
      },
      /**
       * {
       *   id: string,
       *   sku: string,
       *   imageUrl: null,
       *   cost: double,
       *   referCost: double,
       *   total: int,
       *   fromSupplierProduceItemId: string
       * }
       */
      stockProducts: [],
      dataColumns: [
        { title: '操作', width: 100, scopedSlots: { customRender: 'actions' } },
        { title: '序号', width: 50, customRender: (value, row, index) => index + 1 },
        { title: '图片', width: 100, dataIndex: 'imageUrl', scopedSlots: { customRender: 'image' } },
        { title: 'SKU', width: 220, dataIndex: 'sku' },
        { title: '数量', width: 150, scopedSlots: { customRender: 'total' } },
        { title: '成本单价/人民币', scopedSlots: { customRender: 'cost' } }
      ],
      form: createForm(),
      warehouseOptions: [],
      inventoryChangeTypeOptions: INVENTORY_CHANGE_TYPE_LIST,
      inventoryChangeTypeConstants: {
        inStock: INVENTORY_CHANGE_TYPE_IN_STOCK,
        del: INVENTORY_CHANGE_TYPE_DELETE,
        transfer: INVENTORY_CHANGE_TYPE_TRANSFER
      },
      inventoryStatusOptions: INVENTORY_CHANGE_STATUS_LIST.filter(item => item.id !== INVENTORY_CHANGE_STATUS_DELETE),
      supplierOptions: []
    }
  },
  watch: {
    'form.supplierId' () {
      this.loadSkuReferCost()
    }
  },
  methods: {
    onClosed () {
      this.stockProducts = []
      this.form = createForm()
      this.defaultChangeType = null
    },
    close () {
      this.$refs.modal.close()
    },
    /**
     * 入库操作
     */
    openForInStock (title, skuArray, supplierId, feature) {
      this.form.title = title
      this.form.supplierId = supplierId
      this.open(INVENTORY_CHANGE_TYPE_IN_STOCK, skuArray, feature)
    },
    /**
     *
     * @param [changeType] 如果提供，则表单里只能是这个type，不会有type选项。
     * @param [skuArray] {Array} sku数组，或对象数组 { sku: string, total: int, cost: double }
     */
    open (changeType, skuArray, feature, formProp) {
      if (formProp) {
        kit.obj.getProperties(this.form, formProp)
      }
      kit.obj.getProperties(this.feature, feature || {})
      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }
      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }


      this.$refs.modal.open()
      this.defaultChangeType = changeType || null
      this.form.changeType = this.defaultChangeType || INVENTORY_CHANGE_TYPE_IN_STOCK
      if (this.form.changeType === INVENTORY_CHANGE_TYPE_TRANSFER) {
        this.form.inventoryStatus = INVENTORY_CHANGE_STATUS_ON_WAY
      } else {
        this.form.inventoryStatus = INVENTORY_CHANGE_STATUS_IN_STOCK
      }
      const skuMap = {}
      if (skuArray) {
        const skuString = []
        for (const item of skuArray) {
          let obj = item
          if (typeof item === 'string') {
            obj = { sku: item }
            skuString.push(item)
          } else {
            skuString.push(item.sku)
          }
          skuMap[obj.sku] = Object.assign({ total: 1, cost: null, referCost: null }, obj)
        }

        const stockProducts = []
        listBySkuList()
          .success(resp => {
            for (const sp of resp.data) {
              const skuItem = skuMap[sp.sku]
              if (skuItem) {
                skuItem.id = sp.id
                skuItem.imageUrl = sp.image
                stockProducts.push(skuItem)
              }
            }
            this.stockProducts = stockProducts
            this.loadSkuReferCost()
          })
          .send(skuString)
      }
    },
    handleConfirm () {
      this.$confirm({
        title: '确认',
        content: '检查表单信息无误，确认提交变更申请吗？',
        onOk: () => {
          const data = Object.assign({}, this.form)
          data.skuList = []
          for (const item of this.stockProducts) {
            data.skuList.push({
              id: item.id,
              sku: item.sku,
              total: item.total,
              cost: item.cost || item.referCost,
              fromSupplierProduceItemId: item.fromSupplierProduceItemId
            })
          }
          this.loading = true
          addInventoryChangeRequest()
            .complete(() => (this.loading = false))
            .success(resp => {
              this.$message.success('操作成功。')
              this.$emit('saved', resp.data)
              this.$nextTick(() => (this.close()))
            })
            .send(data)
        }
      })
    },
    handleRemoveProductRow (row) {
      kit.arr.removeItem(this.stockProducts, row)
    },
    handleSelectStockProduct () {
      this.$refs.stockProductSelector.open(selected => {
        for (const item of selected) {
          this.stockProducts.push({
            id: item.id,
            imageUrl: item.imageUrl,
            sku: item.sku,
            total: 1,
            cost: null,
            referCost: null
          })
        }
        this.loadSkuReferCost()
      })
    },
    /**
     * 获取SKU的参考成本单价
     */
    loadSkuReferCost () {
      const idList = []
      for (const data of this.stockProducts) {
        idList.push(data.id)
      }
      if (idList.length === 0) return
      getReferCostByStockProductIdList()
        .success(resp => {
          for (const sp of this.stockProducts) {
            sp.referCost = resp.data[sp.id] || null
          }
        })
        .send(idList, this.form.supplierId)
    },
    handleImportProducts () {
      selectFile({
        accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multi: false,
        select: (files) => {
          this.importSkuLoading = true
          importProductsFromFile()
            .complete(() => (this.importSkuLoading = false))
            .success(resp => {
              for (const item of resp.data) {
                item.referCost = null
              }
              this.stockProducts.push(...resp.data)
              this.loadSkuReferCost()
            })
            .send(files[0])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.two-col {
  display: flex;
  & > div {
    flex: .5;
  }
  & > div + div {
    margin-left: 30px;
  }
}
</style>
