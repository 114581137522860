import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Banner extends Section {
  constructor () {
    super('banner', 'Banner')

    this.imageUrl = null
    this.mobileImageUrl = null
    this.url = null
    this.title = null
    this.description = null
    this.buttonText = null
    this.buttonBgColor = '#000000'
    this.buttonTextColor = '#ffffff'

    // left, center, right
    this.textHorAlign = 'center'
    // top, center, bottom
    this.textVerAlign = 'center'
    this.textMargin = '0 0 0 0'
    this.textPadding = '0 0 0 0'

    this.useTextLayoutbackground = false
    this.textLayoutMaxWidth = 500
    this.textLayoutRounded = 0 // 圆角
    this.textLayoutBackgroundColor = '#FFFFFF4D'
  }

  getContentType () {
    return 'image'
  }

  getHeaderDisplay () {
    return this.imageUrl ? [this.imageUrl] : []
  }
}
