import defineApi from '../define-api'
import http from '../application-api'

export const listStockTransfer = defineApi((config, params) => {
  config.params = params
  http.get('/stock_transfer/list', config)
})

export const updateStockTransfer = defineApi((config, data) => {
  config.data = data
  http.post('/stock_transfer/update', config)
})

export const getStockTransferItems = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/stock_transfer/list/transfer_items', config)
})

export const getStockProductBySkuList = defineApi((config, skuList) => {
  config.params = {
    sku: skuList
  }
  http.get('/stock_transfer/list/stock_product/by_sku', config)
})
