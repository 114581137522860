<template>
  <div class="section-banner">
    <div class="image-container">
      <div class="image-wrapper">
        <image-picker :image-url.sync="section.imageUrl" title="PC端图片"></image-picker>
      </div>
      <div style="width: 30px;"></div>
      <div class="image-wrapper">
        <image-picker :image-url.sync="section.mobileImageUrl" title="移动端图片"></image-picker>
      </div>
    </div>

    <div class="info-detail" style="margin-top: 20px;">
      <div class="detail-item">
        <label>链接</label>
        <span>
          <a-input v-model="section.url" />
        </span>
      </div>
      <div class="detail-item">
        <label>标题</label>
        <span>
          <a-input v-model="section.title" />
        </span>
      </div>
      <div class="detail-item">
        <label>描述</label>
        <span>
          <a-textarea v-model="section.description" auto-size />
        </span>
      </div>

      <div class="detail-group">
        <div class="group-name">按钮</div>
        <div class="group-content">
          <div class="detail-item">
            <label>按钮文字</label>
            <span>
              <a-input v-model="section.buttonText" />
            </span>
          </div>
          <div class="detail-item">
            <label>按钮文字颜色</label>
            <span>
              <color-picker :color.sync="section.buttonTextColor"></color-picker>
            </span>
          </div>
          <div class="detail-item">
            <label>按钮背景颜色</label>
            <span>
              <color-picker :color.sync="section.buttonBgColor"></color-picker>
            </span>
          </div>
        </div>
      </div>

      <div class="detail-group">
        <div class="group-name">内容布局</div>
        <div class="group-content">
          <div class="detail-item">
            <label>文字水平对齐</label>
            <span>
              <a-select v-model="section.textHorAlign" style="width: 200px;">
                <a-select-option value="left">左对齐</a-select-option>
                <a-select-option value="center">居中</a-select-option>
                <a-select-option value="right">右对齐</a-select-option>
              </a-select>
            </span>
          </div>
          <div class="detail-item">
            <label>文字垂直对齐</label>
            <span>
              <a-select v-model="section.textVerAlign" style="width: 200px;">
                <a-select-option value="top">顶部</a-select-option>
                <a-select-option value="center">居中</a-select-option>
                <a-select-option value="bottom">底部</a-select-option>
              </a-select>
            </span>
          </div>
          <div class="detail-item">
            <label>文字外间距</label>
            <span>
              <a-input v-model="section.textMargin" />
              <p class="text-muted font-mini">上 右 下 左，单位可为：px, %</p>
            </span>
          </div>
          <div class="detail-item">
            <label>文字内间距</label>
            <span>
              <a-input v-model="section.textPadding" />
              <p class="text-muted font-mini">上 右 下 左，单位可为：px, %</p>
            </span>
          </div>
          <div class="detail-item">
            <label>内容布局宽度</label>
            <span>
              <a-input-number v-model="section.textLayoutMaxWidth" :min="100" :max="1024" />
            </span>
          </div>
          <div class="detail-item">
            <label>使用内容背景</label>
            <span>
              <a-switch v-model="section.useTextLayoutbackground" />
            </span>
          </div>
          <div class="detail-item">
            <label>内容背景颜色</label>
            <span>
              <color-picker :color.sync="section.textLayoutBackgroundColor" :disable-alpha="false" />
            </span>
          </div>
          <div class="detail-item">
            <label>内容背景圆角</label>
            <span>
              <a-input-number v-model="section.textLayoutRounded" :min="0" :max="100" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePicker from '../comp/image-picker'

export default {
  components: { ImagePicker },
  props: {
    section: { type: Object }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.section-banner {
  .image-container {
    display: flex;
    .image-wrapper {
      flex: .5;
    }
  }
}
</style>
