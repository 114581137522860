
import defineApi from '../define-api'
import http from '../application-api'

export const listAll = defineApi((config) => {
  return http.get('/influencer_category/list', config)
})

export const add = defineApi((config, data) => {
  config.data = data
  http.post('/influencer_category/add', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/influencer_category/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/influencer_category/delete', config)
})
