<template>
  <a-card title="组合SKU">
    <a-button slot="extra" size="small" type="primary" @click="addSku()">添加子SKU</a-button>
    <a-button slot="extra" size="small" @click="loadData()">刷新</a-button>

    <a-alert message="在主SKU下关联其他SKU，当发货的时候，如果客户下单的是主SKU，则自动替换成关联的SKU。" type="info" show-icon />

    <loading :loading="loading"></loading>
    <a-empty v-show="dataList.length === 0" />
    <ul class="listing" style="margin-top: 20px;">
      <li class="listing-item" v-for="item in dataList" :key="item.id">
        <div class="wrapper">
          <div class="image-item" v-lazy:background-image="item.imageUrl"></div>
          <div class="info-content info-detail">
            <div class="detail-item">
              <label>SKU</label>
              <span>{{item.sku}}</span>
            </div>
            <div class="detail-item">
              <label>库存</label>
              <span>{{item.quantity}}</span>
            </div>
            <div class="detail-item vertical-center">
              <label>组合数量</label>
              <span>
                <a-input-number v-model="item.total" :min="1"></a-input-number>
                <a-button style="margin-left: 10px;" icon="edit" @click="handleUpdate(item.id, item.total)"></a-button>
                <a-button type="danger" style="margin-left: 10px;" icon="delete" @click="handleDelete(item)"></a-button>
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <stock-product-select ref="selector"></stock-product-select>
  </a-card>
</template>

<script>
import { listComposite, addComposite, updateComposite, deleteComposite } from '@/http/api/stock-product'
import StockProductSelect from '@/components-business/stock-product-select'

export default {
  components: { StockProductSelect },
  props: {
    mainStockProductId: { type: String }
  },
  data () {
    return {
      loading: false,
      dataColumns: [
        { title: '图片', dataIndex: 'imageUrl' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '库存', dataIndex: 'quantity' },
        { title: '组合数量', dataIndex: 'total' }
      ],
      dataList: []
    }
  },
  watch: {
    mainStockProductId: {
      immediate: true,
      handler () {
        this.loadData()
      }
    }
  },
  methods: {
    loadData () {
      if (!this.mainStockProductId) return
      this.loading = true
      listComposite()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.mainStockProductId)
    },
    addSku () {
      this.$refs.selector.open(selected => {
        const idList = []
        for (const item of selected) {
          idList.push(item.id)
        }
        this.loading = true
        addComposite()
          .complete(() => (this.loading = false))
          .success(() => {
            this.$message.success('操作成功。')
            setTimeout(() => (this.loadData()), 400)
          })
          .send(this.mainStockProductId, idList)
      })
    },
    handleUpdate (id, total) {
      this.loading = true
      updateComposite()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('更新成功。')
        })
        .send(id, total)
    },
    handleDelete (item) {
      this.$confirm({
        title: '确认',
        content: `确定要移除【${item.sku}】吗？`,
        onOk: () => {
          this.loading = true
          deleteComposite()
            .complete(() => (this.loading = false))
            .success(() => {
              this.loadData()
              this.$message.success('删除成功。')
            })
            .send(item.id)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  .info-content {
    flex: 1;
  }
}
</style>
