<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="T17track注册跟踪号"
    @save="onSave"
    @close="onClose"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="物流公司" class="app_required-input">
        <a-select
            v-model="form.logisticsId"
            show-search
            placeholder="输入关键字搜索物流"
            option-filter-prop="children"
            style="width: 100%;"
            :filter-option="logisticsFilterOption"
            :allow-clear="true"
        >
          <a-select-option :value="item.id" v-for="item in logisticsOptions" :key="item.id">{{item.carrierEn}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="跟踪号" class="app_required-input">
        <a-input v-model="form.trackNo" placeholder="多个跟踪号用逗号分割"/>
      </a-form-model-item>
      <a-form-model-item label="标签">
        <a-input v-model="form.tag" placeholder="多个Tag用逗号分开，必须和跟踪号数量一致"/>
      </a-form-model-item>
    </a-form-model>

    <image-modal ref="imageView"></image-modal>

  </drawer>
</template>

<script>
import { listT17trackLogistics, reg } from '@/http/api/t17track'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: {
        logisticsId: null,
        trackNo: null,
        tag: null
      },
      logisticsOptions: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    /**
     * @param arr {Array} {trackNo, tag}
     */
    open (arr) {
      this.visible = true
      if (this.logisticsOptions.length === 0) {
        listT17trackLogistics().success(resp => {
          this.logisticsOptions = resp.data
        }).send()
      }
      if (Array.isArray(arr)) {
        const trackNo = []
        const tag = []
        for (const item of arr) {
          trackNo.push(item.trackNo)
          tag.push(item.tag)
        }
        this.form.trackNo = trackNo.join(',')
        this.form.tag = tag.join(',')
      }
    },
    onClose () {
      this.$refs.form.resetFields()
    },
    onSave () {
      if (this.form.trackNo) {
        const trackNoArr = this.form.trackNo.split(',')
        const tagArr = this.form.tag.split(',')
        const arr = []
        for (let i = 0; i < trackNoArr.length; i++) {
          let tag = this.form.tag
          if (i < tagArr.length) {
            tag = tagArr[i]
          }
          arr.push({
            logisticsId: this.form.logisticsId,
            trackNo: trackNoArr[i],
            tag: tag
          })
        }
        this.closable = false
        this.saveButtonLoading = true
        reg()
          .complete(() => {
            this.closable = true
            this.saveButtonLoading = false
          })
          .success(resp => {
            this.$message.success('注册成功。')
          })
          .send(arr)
      }
    },
    logisticsFilterOption (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
  }
}
</script>

<style scoped>

</style>
