<!--
  this.$refs.productSelect.open(products => {
    const idList = products.map(p => p.id)
  }, this.products, 100)

  参数1：确定选择后的回调函数，可以得到选择的产品列表。
  参数2：提供一个产品列表，作为默认选中的产品。
  参数3：最多可以选择多少个产品。
-->
<template>
  <a-modal
      title="店铺产品选择"
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
      :body-style="{height: '500px'}"
      :dialog-style="{top: '50px'}"
      wrap-class-name="shop-product-select-dialog"
  >
    <div class="body">
      <div class="search-form">
        <a-form-model layout="inline" :model="searchParams">
          <a-form-model-item label="店铺">
            <a-select v-model="searchParams.shopId" style="width: 120px;">
              <a-select-option v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
            <a-form-model-item label="标题">
              <a-input v-model="searchParams.title" style="width: 150px;" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="系列ID">
              <a-input v-model="searchParams.collectionId" style="width: 120px;" :allow-clear="true" />
            </a-form-model-item>
        </a-form-model>
        <div class="buttons">
          <a-button @click="loadProducts()">查询</a-button>
        </div>
      </div>

      <a-table
          slot="center"
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="products"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 300 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
      >
        <div slot="image" slot-scope="imageUrl">
          <div class="image-item small" v-lazy:background-image="imageUrl"></div>
        </div>
      </a-table>
    </div>
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk">确定选择</a-button>
    </template>
  </a-modal>
</template>

<script>
import { searchShopifyProducts } from '@/http/api/shopify-product'
import { listShopOptions } from '@/http/api/shop'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      loading: false,
      searchParams: {
        collectionId: null,
        title: null,
        status: 'active',
        shopId: null
      },
      products: [],
      dataColumns: [
        { title: '图片', dataIndex: 'imageUrl', width: 70, scopedSlots: { customRender: 'image' } },
        { title: '标题', dataIndex: 'title' },
        { title: '价格', dataIndex: 'price', width: 70 }
      ],
      selectedRowKeys: [],
      shopOptions: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open (selectCallback) {
      this.selectCallback = selectCallback
      this.visible = true
      if (this.products.length === 0) {
        this.loadProducts()
      }
      if (this.shopOptions.length === 0) {
        listShopOptions().success(resp => (this.shopOptions = resp.data)).send()
      }
    },
    handleOk () {
      if (this.selectCallback) {
        const map = {}
        for (const id of this.selectedRowKeys) {
          map[id] = true
        }
        const arr = []
        for (const item of this.products) {
          if (map[item.id]) {
            arr.push(item)
          }
        }
        this.selectCallback(arr)
      }
      this.handleCancel()
    },
    handleCancel () {
      this.selectedRowKeys = []
      this.visible = false
    },
    loadProducts () {
      if (this.loading || !this.searchParams.shopId) {
        return
      }
      const p = Object.assign({}, this.searchParams)
      this.loading = true
      searchShopifyProducts()
        .complete(() => {
          this.loading = false
        })
        .success(resp => {
          this.products = resp.data
        })
        .send(p)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    }
  }
}
</script>

<style lang="less" scoped>
</style>
