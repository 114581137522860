<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button size="small" slot="toolbar" icon="search" @click="reloadData">查询</a-button>
    <a-button type="primary" size="small" slot="toolbar" @click="navDetail(null)">新建</a-button>


    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <div>
          <a-form-model-item label="订单号码">
            <a-input v-model="searchParams.orderNo" :allow-clear="true" placeholder="多个订单号用英文逗号分割，不要含空格" style="width: 300px;" />
          </a-form-model-item>
          <a-form-model-item label="客户邮箱">
            <a-input v-model="searchParams.email" :allow-clear="true" style="width: 150px;" />
          </a-form-model-item>
          <a-form-model-item label="标签">
            <a-input v-model="searchParams.tag" :allow-clear="true" style="width: 120px;" />
          </a-form-model-item>
          <a-form-model-item label="类别">
            <a-input v-model="searchParams.category" :allow-clear="true" style="width: 120px;" />
          </a-form-model-item>
          <a-form-model-item label="重要程度">
            <a-select v-model="searchParams.priority" :allow-clear="true" style="width: 120px;">
              <a-select-option :value="item.id" v-for="item in priorityOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item label="状态">
            <a-select mode="tags" v-model="searchParams.status" :allow-clear="true" style="width: 565px;">
              <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">
                {{item.name}}（{{statusTotal[item.id]}}）
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题">
            <a-input v-model="searchParams.title" :allow-clear="true" style="width: 250px;" />
          </a-form-model-item>
          <a-form-model-item label="排序">
            <a-select v-model="searchParams.orderBy" style="width: 140px;">
              <a-select-option value="createTime:asc">创建时间-升序</a-select-option>
              <a-select-option value="createTime:desc">创建时间-降序</a-select-option>
              <a-select-option value="updateTime:asc">更新时间-升序</a-select-option>
              <a-select-option value="updateTime:desc">更新时间-降序</a-select-option>
            </a-select>
          </a-form-model-item>

        </div>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="navDetail(record)">详情</a-button>
      </div>
      <div slot="status" slot-scope="status">
        <span class="status" :class="'status-' + status">{{statusMapping[status]}}</span>
      </div>
      <div slot="priority" slot-scope="priority">
        <span class="priority" :class="'priority-' + priority">{{priorityMapping[priority]}}</span>
      </div>
      <div slot="tags" slot-scope="tags" v-if="tags && tags.length > 0">
        <a-tag color="blue" v-for="(tag, index) in tags.split(',')" :key="index">{{tag}}</a-tag>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_AFTER_SALE_SESSION, ROUTE_AFTER_SALE_SESSION_DETAIL } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  STATUS_MAPPING,
  STATUS_LIST,
  PRIORITY_MAPPING,
  PRIORITY_LIST
} from '@/constants/after-sale-session'
import { list, getStatusTotal } from '@/http/api/after-sale-session'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    const statusTotal = {}
    for (const item of STATUS_LIST) {
      statusTotal[item.id] = 0
    }
    return {
      routeName: ROUTE_AFTER_SALE_SESSION,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', width: 70, scopedSlots: { customRender: 'action' } },
        { title: '订单号', dataIndex: 'orderNo', width: 100 },
        { title: '客户邮箱', dataIndex: 'email', width: 150 },
        { title: '状态', dataIndex: 'status', width: 100, scopedSlots: { customRender: 'status' } },
        { title: '重要程度', dataIndex: 'priority', width: 80, scopedSlots: { customRender: 'priority' } },
        { title: '案件类别', dataIndex: 'category', width: 120 },
        { title: '标题', dataIndex: 'title' },
        { title: '标签', dataIndex: 'tags', scopedSlots: { customRender: 'tags' } },
        { title: '创建人', dataIndex: 'createAdminName', width: 100 },
        { title: '创建时间', dataIndex: 'createTime', width: 120 },
        { title: '更新时间', dataIndex: 'updateTime', width: 120 }
      ],
      statusTotal: statusTotal,
      statusOptions: STATUS_LIST,
      statusMapping: STATUS_MAPPING,
      priorityMapping: PRIORITY_MAPPING,
      priorityOptions: PRIORITY_LIST,
      searchParams: {
        status: [],
        tag: null,
        category: null,
        email: null,
        title: null,
        orderNo: null,
        priority: null,
        orderBy: 'createTime:asc'
      }
    }
  },
  methods: {
    navDetail (data) {
      let query = null
      let params = null
      if (data) {
        query = { selectedId: data.id }
        params = { email: data.email }
      } else {
        params = { email: 'new' }
      }
      this.$router.push({ name: ROUTE_AFTER_SALE_SESSION_DETAIL, params: params, query: query })
    },
    loadData () {
      let data = Object.assign({}, this.searchParams)
      data.status = [...this.searchParams.status]
      if (data.orderNo && data.orderNo.length > 0) {
        data.orderNo = data.orderNo.split(',')
      }
      data = this.buildRequestParams(data)
      const tmp = data.orderBy.split(':')
      delete data.orderBy
      data.orderName = tmp[0]
      data.orderDir = tmp[1]

      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(data)
      this.loadStatusTotal()
    },
    loadStatusTotal () {
      getStatusTotal().success(resp => {
        for (const key of Object.keys(this.statusTotal)) {
          this.statusTotal[key] = resp.data[key] || 0
        }
      }).send()
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style lang="less" scoped>
.status-service {
  color: #3d71a7;
}
.status-completed {
  color: #228018;
}

.priority-normal {
  color: #2b2b2b;
}
.priority-quick {
  color: #e0760c;
}
.priority-important {
  color: #e00c3d;
}
</style>
