<template>
  <a-layout id="home">
    <app-header class="header"></app-header>
    <a-layout>
      <a-layout-sider width="200" style="background: #fff">
        <app-menu></app-menu>
      </a-layout-sider>
      <a-layout id="app-body-container" class="app-content">
        <div class="welcome">
          <p class="text-primary font-large-x font-bold">欣丝科技（深圳）有限公司</p>
          <p class="font-mini text-muted">欣丝订单管理系统</p>
        </div>
        <div class="system-message" v-authority="$authority.systemMessage">
          <div class="left-right-content">
            <div class="text-muted font-mini full" style="margin-bottom: 10px;">系统消息</div>
            <a-button type="link" size="small" @click="loadSystemMessage()">刷新</a-button>
          </div>
          <a-table
              size="middle"
              rowKey="id"
              :columns="sysMsgDataColumns"
              :data-source="sysMsgList"
              :loading="sysMsgLoading"
              :pagination="false"
              :scroll="{ y: 400 }"
          >
            <div slot="action" slot-scope="record">
              <a-button size="small" v-if="!record.readStatus" :loading="record._updating" @click="handleUpdateSystemMessageReadStatus(record)">标记已读</a-button>
              <span class="text-success font-mini" v-if="record.readStatus">已读</span>
            </div>
            <div slot="level" slot-scope="importantLevel">
              <span class="text-success" v-if="importantLevel === 1">低</span>
              <span class="text-warning" v-if="importantLevel === 4">中</span>
              <span class="text-danger font-bold" v-if="importantLevel === 7">高</span>
            </div>
          </a-table>
        </div>
        <router-view></router-view>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import AppMenu from '@/layout/app-menu'
import AppHeader from '@/layout/app-header'
import { listUnReadSystemMessage, updateToRead } from '@/http/api/system-message'

export default {
  components: { AppMenu, AppHeader },
  data () {
    return {
      sysMsgList: [],
      sysMsgLoading: false,
      sysMsgDataColumns: [
        { title: '操作', width: 90, scopedSlots: { customRender: 'action' }, align: 'center' },
        { title: '消息', dataIndex: 'message' },
        { title: '重要', dataIndex: 'importantLevel', scopedSlots: { customRender: 'level' }, width: 80 },
        { title: '时间', dataIndex: 'createTime', width: 140 }
      ]
    }
  },
  methods: {
    loadSystemMessage () {
      this.sysMsgLoading = true
      listUnReadSystemMessage()
        .complete(() => (this.sysMsgLoading = false))
        .success(resp => {
          for (const item of resp.data) {
            item._updating = false
          }
          this.sysMsgList = resp.data
        }).send()
    },
    handleUpdateSystemMessageReadStatus (row) {
      row._updating = true
      updateToRead()
        .complete(() => (row._updating = false))
        .success(() => {
          row.readStatus = true
        })
        .send(row.id)
    }
  },
  mounted () {
    if (this.$authorityCheck.check(this.$authority.systemMessage)) {
      this.loadSystemMessage()
    }
  }
}
</script>

<style scoped>
#home {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.app-content {
  padding: 0 12px 0 12px;
  position: relative !important;
}

.welcome {
  text-align: center;
  margin-top: 50px;
}

.system-message {
  width: 70%;
  margin: 20px auto;
}
</style>
