import defineApi from '../define-api'
import http, { buildUrlWithToken } from '../application-api'

export const listByOrder = defineApi((config, orderId, mergeSku) => {
  config.params = { orderId: orderId, mergeSku: mergeSku }
  http.get('/profit_loss/list/by_order', config)
})

export const getReport = defineApi((config, params) => {
  config.params = params
  http.get('/profit_loss/report', config)
})

export const getReportForLineChart = defineApi((config, params) => {
  config.params = params
  http.get('/profit_loss/report/line_chart', config)
})

export const getReportDownloadUrl = function (params) {
  return buildUrlWithToken('/profit_loss/report/export', false, params)
}

export const updateByOrder = defineApi((config, orderId) => {
  config.params = { orderId: orderId }
  http.post('/profit_loss/update/by_order', config)
})

export const updateFromDateRange = defineApi((config, startDate, endDate) => {
  config.timeout = 1000 * 60 * 10
  config.params = { start: startDate, end: endDate }
  http.post('/profit_loss/update/date_range', config)
})

export const listSyncRecord = defineApi((config, params) => {
  config.params = params
  http.get('/profit_loss/sync/list', config)
})
