<template>
  <div class="currency-convertor">
    <label>货币转换：</label>
    <a-tooltip>
      <template slot="title">货币代码，如：CNY</template>
      <a-input v-model="fromCurr" style="width: 60px;" @blur="handleLoadExchangeRate"></a-input>
    </a-tooltip>

    <span style="margin: 0 10px;">TO</span>

    <a-tooltip>
      <template slot="title">货币代码，如：CNY</template>
      <a-input v-model="toCurr" :disabled="!toCurrEditable" style="width: 60px;" @blur="handleLoadExchangeRate"></a-input>
    </a-tooltip>

    <a-button type="primary" style="margin-left: 10px;" :disabled="exchangeRate <= 0" :loading="loading" @click="handleConvert">
      转换
      <span v-show="exchangeRate > 0">（{{exchangeRate}}）</span>
    </a-button>
  </div>
</template>

<script>
import { getCurrencyConvertRate } from '@/http/api/exchange-rate'

export default {
  props: {
    toCurr: { type: String },
    toCurrEditable: { type: Boolean, default: true },
    convertList: { type: Array },
    /**
     * 从数组里的对象获取金额属性，并做转换。
     * [{ cost: 12 }, { cost: 44 }]
     */
    convertListProp: { type: String, default: 'cost' },
    emptyListMessage: { type: String, default: '请提供要转换的金额。' }
  },
  data () {
    return {
      fromCurr: null,
      exchangeRate: 0,
      loading: false
    }
  },
  methods: {
    handleConvert () {
      if (this.convertList.length === 0) {
        this.$message.error(this.emptyListMessage)
      } else {
        for (const item of this.convertList) {
          item[this.convertListProp] = item[this.convertListProp] * this.exchangeRate
        }
      }
    },
    handleLoadExchangeRate () {
      if (this.fromCurr && this.toCurr) {
        this.loading = true
        getCurrencyConvertRate()
          .complete(() => (this.loading = false))
          .success(resp => {
            if (resp.data) {
              this.exchangeRate = resp.data
            }
          })
          .error(resp => {
            this.$message.error(resp.message)
            this.exchangeRate = 0
          })
          .send(this.fromCurr, this.toCurr)
      } else {
        this.exchangeRate = 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
.currency-convertor {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
}
</style>
