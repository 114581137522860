/**
 * 用户会话的通知。
 * @param path
 * @return {string}
 */
function userBroker (path) {
  return '/user' + path
}

/**
 * 连接器状态发生变化。
 * message：
 *
 */
export const BrokerShopifySkuSync = userBroker('/shopify/sku/sync')

