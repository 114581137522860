import defineApi from '../define-api'
import http from '../application-api'

/**
 * @type {Function}
 */
export const list = defineApi((config, params) => {
  config.params = params
  http.get('/abandoned_checkout/list', config)
})

export const sync = defineApi((config) => {
  config.timeout = 1000 * 60 * 5
  http.post('/abandoned_checkout/sync', config)
})

/**
 * @param data {Object}
 *          idList {Array}
 *          title
 *          message
 *          discountCode
 */
export const sendEmails = defineApi((config, data) => {
  config.timeout = 1000 * 60 * 5
  config.data = data
  http.post('/abandoned_checkout/send_emails', config)
})
