import kit from '@/utils/kit'

export const ORDER_STATUS_NEW = 'new'
export const ORDER_STATUS_PRODUCE = 'produce'
export const ORDER_STATUS_CANCEL = 'cancel'
export const ORDER_STATUS_CLOSE = 'close'
export const ORDER_STATUS_COMPLETE = 'complete'

export const ORDER_STATUS_MAPPING = {
  [ORDER_STATUS_NEW]: '新单',
  [ORDER_STATUS_PRODUCE]: '供应商生产',
  [ORDER_STATUS_CANCEL]: '已取消',
  [ORDER_STATUS_CLOSE]: '已关闭',
  [ORDER_STATUS_COMPLETE]: '已完成'
}

export const ORDER_STATUS_LIST = kit.obj.toArray(ORDER_STATUS_MAPPING)



export const ORDER_PAY_STATUS_PAY = 'pay'
export const ORDER_PAY_STATUS_PARTIALLY = 'payPartially'
export const ORDER_PAY_STATUS_REFUND = 'refund'
export const ORDER_PAY_STATUS_REFUND_PARTIALLY = 'refundPartially'
export const ORDER_PAY_STATUS_WAIT = 'unPay'

export const ORDER_PAY_STATUS_MAPPING = {
  [ORDER_PAY_STATUS_PAY]: '已支付',
  [ORDER_PAY_STATUS_PARTIALLY]: '部分支付',
  [ORDER_PAY_STATUS_REFUND]: '已退款',
  [ORDER_PAY_STATUS_REFUND_PARTIALLY]: '部分退款',
  [ORDER_PAY_STATUS_WAIT]: '待支付'
}
export const ORDER_PAY_STATUS_LIST = kit.obj.toArray(ORDER_PAY_STATUS_MAPPING)




export const ORDER_SHIP_STATUS_WAIT = 'unSend'
export const ORDER_SHIP_STATUS_SEND = 'send'
export const ORDER_SHIP_STATUS_ALL_SEND = 'allSend'
export const ORDER_SHIP_STATUS_ARRIVED = 'arrived'

export const ORDER_SHIP_STATUS_MAPPING = {
  [ORDER_SHIP_STATUS_WAIT]: '待发货',
  [ORDER_SHIP_STATUS_SEND]: '已发货',
  [ORDER_SHIP_STATUS_ALL_SEND]: '已发货-全部',
  [ORDER_SHIP_STATUS_ARRIVED]: '已签收'
}
export const ORDER_SHIP_STATUS_LIST = kit.obj.toArray(ORDER_SHIP_STATUS_MAPPING)

