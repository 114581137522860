<template>
  <app-page :route-name="routeName">
    <div slot="toolbar">
      <a-button type="primary" @click="openEditor()">添加</a-button>
      <a-dropdown>
        <a-menu slot="overlay" @click="handleAbroadWarehouseSync">
          <a-menu-item v-for="c in abroadShippingOptions" :key="c.id"> {{c.name}} </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px" :loading="abroadShippingSyncing"> 海外仓库同步 <a-icon type="down" /> </a-button>
      </a-dropdown>
    </div>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="openEditor(record)">编辑</a-button>
      </div>

      <div slot="address" slot-scope="row">
        <span v-if="row.address">
          {{row.address.country}}, {{row.address.state}}, {{row.address.city}}, {{row.address.addr1}} {{row.address.addr2}}
        </span>
        <span v-else>无地址</span>
      </div>

      <div slot="type" slot-scope="row">
        <span v-if="row.type === 1" class="text-primary">海外仓</span>
        <span v-else-if="row.type === 2">国内仓</span>
      </div>

      <div slot="enabled" slot-scope="enabled">
        <status :status="enabled"></status>
      </div>
    </a-table>

    <modal title="仓库编辑" ref="editor" :loading="editor.loading" @ok="handleEditorSave" @closed="handleEditorClosed" :max-height="500">
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="仓库名称" prop="name" class="app_required-input">
          <a-input v-model="editor.form.name" />
        </a-form-model-item>

        <a-form-model-item label="仓库类型" prop="type" class="app_required-input">
          <a-select v-model="editor.form.type">
            <a-select-option :value="1">海外仓</a-select-option>
            <a-select-option :value="2">国内仓</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="状态" prop="enabled">
          <a-switch checked-children="有效" un-checked-children="无效" v-model="editor.form.enabled" />
        </a-form-model-item>

        <a-form-model-item label="备注" prop="description">
          <a-textarea
              v-model="editor.form.description"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>

        <a-form-model-item label="姓名" prop="address.name">
          <a-input v-model="editor.form.address.name" />
        </a-form-model-item>

        <a-form-model-item label="电话" prop="address.phone">
          <a-input v-model="editor.form.address.phone" />
        </a-form-model-item>

        <a-form-model-item label="公司" prop="address.company">
          <a-input v-model="editor.form.address.company" />
        </a-form-model-item>

        <a-form-model-item label="邮箱" prop="address.email">
          <a-input v-model="editor.form.address.email" />
        </a-form-model-item>

        <a-form-model-item label="国家" prop="address.countryCode">
          <a-select v-model="editor.form.address.countryCode"
                    style="width: 100%;"
                    show-search
                    option-filter-prop="children"
                    :filter-option="countryOptionFilter"
                    :allowClear="true">
            <a-select-option :value="item.code" v-for="item in countryOptions" :key="item.code">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="省份/州" prop="address.state">
          <a-input v-model="editor.form.address.state" />
        </a-form-model-item>

        <a-form-model-item label="城市" prop="address.city">
          <a-input v-model="editor.form.address.city" />
        </a-form-model-item>

        <a-form-model-item label="地址1" prop="address.addr1">
          <a-input v-model="editor.form.address.addr1" />
        </a-form-model-item>

        <a-form-model-item label="地址2" prop="address.addr2">
          <a-input v-model="editor.form.address.addr2" />
        </a-form-model-item>

        <a-form-model-item label="邮政编码" prop="address.postal">
          <a-input v-model="editor.form.address.postal" />
        </a-form-model-item>
      </a-form-model>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_WAREHOUSE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listWarehouse, addWarehouse, updateWarehouse } from '@/http/api/warehouse'
import { syncWarehouse } from '@/http/api/abroad-shipping'
import { LOGISTICS_ABROAD_LIST } from '@/constants/logistics'
import { getCountries } from '@/http/api/order-delivery'

function createAddress () {
  return {
    country: null,
    countryCode: null,
    state: null,
    stateCode: null,
    city: null,
    addr1: null,
    addr2: null,
    postal: null,
    name: null,
    company: null,
    phone: null,
    email: null
  }
}

export default {
  data () {
    return {
      routeName: ROUTE_WAREHOUSE,
      dataColumns: [
        {
          title: '操作',
          width: 80,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '类型',
          width: 80,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '描述',
          dataIndex: 'description'
        },
        {
          title: '来源系统',
          dataIndex: 'source'
        },
        {
          title: '来源系统仓库ID',
          dataIndex: 'sourceId'
        },
        {
          title: '地址',
          scopedSlots: { customRender: 'address' }
        },
        {
          title: '状态',
          dataIndex: 'enabled',
          scopedSlots: { customRender: 'enabled' }
        }
      ],
      dataList: [],
      dataLoading: false,
      countryOptions: [],
      editor: {
        item: null,
        loading: false,
        form: {
          name: null,
          type: 1,
          description: null,
          enabled: true,
          address: createAddress()
        }
      },
      abroadShippingOptions: LOGISTICS_ABROAD_LIST,
      abroadShippingSyncing: false
    }
  },
  mixins: [AntdTableHeightMixin],
  watch: {
    'editor.form.address.countryCode' (code) {
      const found = this.countryOptions.find(item => item.code === code)
      if (found) {
        this.editor.form.address.country = found.ename
      }
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listWarehouse()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          for (const item of resp.data) {
            try {
              item.address = JSON.parse(item.address)
            } catch (e) {
              item.address = createAddress()
            }
          }
          this.dataList = resp.data
        })
        .send()
    },
    openEditor (item) {
      this.$refs.editor.open()
      if (item) {
        this.editor.item = item
        this.editor.form.name = item.name
        this.editor.form.enabled = item.enabled
        this.editor.form.description = item.description
        this.editor.form.address = Object.assign({}, item.address)
      }
    },
    handleEditorSave () {
      let api
      const item = {
        id: this.editor.item ? this.editor.item.id : null,
        name: this.editor.form.name,
        type: this.editor.form.type,
        enabled: this.editor.form.enabled,
        description: this.editor.form.description,
        address: JSON.stringify(this.editor.form.address)
      }
      if (this.editor.item) {
        api = updateWarehouse
      } else {
        api = addWarehouse
      }

      this.editor.loading = true
      api()
        .complete(() => (this.editor.loading = false))
        .success(resp => {
          this.loadData()
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.$refs.editor.close()
          })
        })
        .send(item)
    },
    handleEditorClosed () {
      this.editor.item = null
      this.$refs.form.resetFields()
    },
    handleAbroadWarehouseSync (e) {
      this.abroadShippingSyncing = true
      syncWarehouse()
        .complete(() => (this.abroadShippingSyncing = false))
        .success(resp => {
          this.$message.success('同步完成。')
          this.loadData()
        })
        .send(e.key)
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
  },
  mounted () {
    this.loadData()
    getCountries().success(resp => (this.countryOptions = resp.data)).send()
  }
}
</script>

<style scoped>

</style>
