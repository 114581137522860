<!-- 某个款号下的销售趋势图 -->
<template>
  <transition name="slideUp">
    <div class="modal" v-show="visible" style="animation-duration: 0.2s">
      <div class="modal-head left-right-content" v-if="searchParams.skuPrefix">
        <div>款号：{{searchParams.skuPrefix}}</div>
      </div>

      <div class="modal-body" ref="bodyContainer">
        <loading :loading="dataLoading"></loading>

        <div class="search-form" style="margin: 10px;">
          <a-form-model layout="inline" :model="searchParams" class="form">
            <a-form-model-item label="日期范围">
              <a-range-picker @change="handleCreateDateChanged"
                              v-model="dateRange"
                              value-format="YYYY-MM-DD"
                              style="width: 220px;"
              >
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="归类时段">
              <a-select v-model="searchParams.groupBy" style="width: 120px;">
                <a-select-option value="day">按日</a-select-option>
                <a-select-option value="week">按周</a-select-option>
                <a-select-option value="month">按月</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="SKU过滤">
              <a-select mode="tags" style="width: 200px;" v-model="searchParams.skuKeywords">
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <div class="buttons">
            <a-button @click="loadData()">查询</a-button>
          </div>
        </div>

        <div>
          <line-chart ref="lineChart" :data="dataList" :title="`${searchParams.skuPrefix}的销量趋势`"></line-chart>
        </div>
      </div>

      <div class="modal-footer" style="text-align: right;">
        <a-button @click="visible = false" type="danger">关闭</a-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { getOrderProductSales } from '@/http/api/sale-report'
import LineChart from '@/charts/line'
import moment from 'moment'

export default {
  components: { LineChart },
  data () {
    return {
      visible: false,
      startDate: null,
      endDate: null,
      dataLoading: false,
      dataList: [],
      dateRange: [],
      searchParams: {
        skuPrefix: null, // 款号
        startTime: null,
        endTime: null,
        groupBy: 'month',
        skuKeywords: []
      }
    }
  },
  filters: {
  },
  computed: {
  },
  watch: {
    visible (v) {
      if (!v) {

      }
    }
  },
  methods: {
    open (styleNumber, startDate, endDate) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.lineChart.resize({
          width: this.$refs.bodyContainer.clientWidth,
          height: this.$refs.bodyContainer.clientHeight - 70
        })
      })

      if (this.searchParams.skuPrefix !== styleNumber) {
        this.dataList = []
        this.searchParams.skuKeywords = []
        this.dateRange = []
        this.searchParams.skuPrefix = styleNumber
        if (startDate && endDate) {
          const sDate = moment(startDate, 'YYYY-MM-DD')
          const eDate = moment(endDate, 'YYYY-MM-DD')
          const days = eDate.diff(sDate, 'days')
          if (days <= 31) {
            this.searchParams.groupBy = 'day'
          } else if (days <= 90) {
            this.searchParams.groupBy = 'week'
          } else {
            this.searchParams.groupBy = 'month'
          }
          this.dateRange = [startDate, endDate]
          this.handleCreateDateChanged(this.dateRange)
        }
        this.loadData()
      }
    },
    loadData () {
      this.dataLoading = true
      const p = Object.assign({}, this.searchParams)
      p.skuKeywords = p.skuKeywords.join(',')
      getOrderProductSales()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(p)
    },
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.startTime = dates[0] + ' 00:00:00'
        this.searchParams.endTime = dates[1] + ' 23:59:59'
      } else {
        this.searchParams.startTime = null
        this.searchParams.endTime = null
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.modal {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  @border: solid 1px #ddd;
  border-left: @border;
  border-top: @border;
  border-right: @border;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  background-color: #fff;

  .modal-head {
    padding: 10px;
    border-bottom: solid 1px #eee;
    background-color: #f9f9f9;
  }

  .modal-body {
    position: relative;
    flex: 1;
    overflow-y: auto;
  }

  .modal-footer {
    border-top: solid 1px #eee;
    padding: 10px;
    align-items: center;
  }
}
</style>
