import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Product extends Section {
  constructor () {
    super('product', '产品')
    // { id, title, imageUrl, productUrl, price }
    this.products = []
    this.rounded = true
    // 每行放几个产品
    this.countPerRow = 3
    this.gap = 20
    this.showPrice = true
    this.priceSymbol = '$'
  }

  getContentType () {
    return 'image'
  }

  getHeaderDisplay () {
    const arr = []
    for (const p of this.products) {
      if (p.imageUrl) {
        arr.push(p.imageUrl)
      }
    }
    return arr
  }
}
