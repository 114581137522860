<template>
  <a-form-model :model="order" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
    <a-form-model-item label="国家">
      <a-select v-model="order.customer.countryCode"
                style="width: 100%;"
                show-search
                option-filter-prop="children"
                :filter-option="countryOptionFilter"
                :allowClear="true">
        <a-select-option :value="item.code" v-for="item in countryOptions" :key="item.code">{{item.name}}</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="省份/州">
      <a-input v-model="order.customer.state" />
    </a-form-model-item>
    <a-form-model-item label="城市" class="app_required-input">
      <a-input v-model="order.customer.city" />
    </a-form-model-item>
    <a-form-model-item label="地址1" class="app_required-input">
      <a-input v-model="order.customer.address1" />
    </a-form-model-item>
    <a-form-model-item label="地址2">
      <a-input v-model="order.customer.address2" />
    </a-form-model-item>
    <a-form-model-item label="邮政编号" class="app_required-input">
      <a-input v-model="order.customer.postalCode" />
    </a-form-model-item>
    <a-form-model-item label="姓氏">
      <a-input v-model="order.customer.firstName" />
    </a-form-model-item>
    <a-form-model-item label="名字" class="app_required-input">
      <a-input v-model="order.customer.lastName" />
    </a-form-model-item>
    <a-form-model-item label="联系电话" class="app_required-input">
      <a-input v-model="order.customer.phone" />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getCountries } from '@/http/api/order-delivery'

export default {
  props: {
    order: { type: Object }
  },
  data () {
    return {
      countryOptions: []
    }
  },
  computed: {
  },
  watch: {
    'order.customer.countryCode' (code) {
      const found = this.countryOptions.find(item => item.code === code)
      if (found) {
        this.order.customer.country = found.ename
      }
    }
  },
  methods: {
    loadCountries () {
      if (this.countryOptions.length === 0) {
        getCountries().success(resp => (this.countryOptions = resp.data)).send()
      }
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
  },
  mounted () {
    this.loadCountries()
  }
}
</script>

<style lang="less" scoped>
</style>
