<template>
  <a-modal
      title="文件夹编辑"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :closable="closable"
      :maskClosable="closable"
      :keyboard="closable"
      :zIndex="zIndex"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div v-if="parent" style="margin: 5px 0; font-weight: bold;">
      上级文件夹：{{parent.name}}
    </div>
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item has-feedback label="文件夹名称" prop="name">
        <a-input ref="nameInput" v-model="form.name" type="text" autocomplete="off" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addFolder, updateFolderName } from '@/http/api/folder'
import { FILE_MANAGER_Z_INDEX } from '@/components-business/file-manager'

export default {
  data () {
    return {
      zIndex: FILE_MANAGER_Z_INDEX + 2,
      confirmLoading: false,
      closable: true,
      visible: false,
      form: {
        name: null
      },
      position: 0,
      model: null,
      parent: null
    }
  },
  computed: {
  },
  watch: {
    visible () {
      if (!this.visible) {
        this.$refs.form.resetFields()
        this.model = null
        this.parent = null
        this.position = 0
      }
    }
  },
  methods: {
    open (model, parent, position) {
      this.model = model
      this.parent = parent
      this.position = position
      if (model) {
        this.$nextTick(() => {
          this.form.name = model.name
        })
      }
      this.visible = true
      this.$nextTick(() => {
        const input = this.$refs.nameInput.$el
        input.focus()
        input.setSelectionRange(0, input.value.length)
      })
    },
    handleOk () {
      this.confirmLoading = true
      this.closable = false
      const completeCallback = success => {
        this.confirmLoading = false
        this.closable = true
        if (success) {
          this.visible = false
          this.$message.success('保存成功。')
        }
      }
      if (this.model) {
        updateFolderName()
          .complete(completeCallback)
          .success(() => {
            this.model.name = this.form.name
          })
          .send(this.model.id, this.form.name)
      } else {
        const data = Object.assign({
          parentId: this.parent ? this.parent.id : null,
          position: this.position
        }, this.form)
        addFolder()
          .complete(completeCallback)
          .success(resp => {
            this.$emit('saved', resp.data)
          })
          .send(data)
      }
    },
    handleCancel () {
      if (this.closable) {
        this.visible = false
      }
    }
  }
}
</script>

<style scoped>

</style>
