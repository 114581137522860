<template>
  <div class="color-picker"
       :style="{'background-color': color}"
       :class="{short: mode === 'short'}"
       @click.stop.prevent="handleShowPicker"
  >
    <sketch-picker
        class="picker"
        v-show="visible"
        @input="handleColorPickerChange"
        :value="color"
        :disable-alpha="disableAlpha"
    >
    </sketch-picker>
  </div>
</template>

<script>
import SketchPicker from 'vue-color/src/components/Sketch'
import kit from '@/utils/kit'
export default {
  name: 'color-picker',
  components: { SketchPicker },
  props: {
    /**
     * hex #ffffff
     */
    color: { type: String, default: '#ffffff' },
    // long, short
    mode: { type: String, default: 'short' },
    disableAlpha: { type: Boolean, default: true },
    // true时，在颜色选择器上滑动就会立即将值更新到绑定的color上，false时当关闭颜色选择后，才把最终值更新到绑定的color上。
    updateOnChange: { type: Boolean, default: false }
  },
  data () {
    return {
      visible: false,
      colorHolder: null
    }
  },
  computed: {
  },
  watch: {
    visible () {
      if (this.visible) {
        kit.dom.addEvent(document.body, 'click', this.hidePicker)
      } else {
        kit.dom.removeEvent(document.body, 'click', this.hidePicker)
        if (this.colorHolder != null) {
          this.$emit('update:color', this.disableAlpha ? this.colorHolder.hex : this.colorHolder.hex8)
        }
        this.colorHolder = null
      }
    }
  },
  methods: {
    handleShowPicker () {
      this.visible = true
    },
    hidePicker () {
      this.visible = false
    },
    handleColorPickerChange (color) {
      this.colorHolder = color
      if (this.updateOnChange) {
        this.$emit('update:color', this.disableAlpha ? color.hex : color.hex8)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.color-picker {
  position: relative;
  height: 24px;
  border: solid 1px #323030;
  border-radius: 3px;
  cursor: pointer;
}
.color-picker.short {
  width: 24px;
}
.picker {
  position: absolute;
  top: 24px;
  left: -1px;
  z-index: 100;
}
</style>
