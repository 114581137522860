<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :disable-footer-button="true"
    title="库存迁移"
    @close="onClose"
    :width="1000"
  >
    <div class="container">
      <div class="left">
        <a-form-model ref="form" :model="transferData" :label-col="{span: 6}" :wrapper-col="{span: 18}" style="width: 400px;">
          <a-form-model-item label="标题" prop="title" class="app_required-input">
            <a-input v-model="transferData.title" :disabled="isUpdate"/>
          </a-form-model-item>
          <a-form-model-item label="出库仓库" prop="fromWarehouseId" class="app_required-input">
            <a-select v-model="transferData.fromWarehouseId" style="width: 100%;" :disabled="isUpdate">
              <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="入库仓库" prop="toWarehouseId" :class="{'app_required-input': isUpdate}">
            <a-select v-model="transferData.toWarehouseId" style="width: 100%;" :disabled="isUpdate && transferData.status === statusMap.inStock">
              <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{span: 24}">
            <a-alert
                v-if="transferData.status === statusMap.lock"
                style="width: 100%;"
                message="库存被冻结，你可以操作入库。"
                banner
            />
            <a-alert
                v-if="transferData.status === statusMap.inStock"
                type="success"
                message="库存已经迁移。"
                banner
            />
          </a-form-model-item>

        </a-form-model>
      </div>

      <div class="right">

        <a-button v-show="!isUpdate" type="primary" size="large" block style="margin-bottom: 10px;" @click="handleSelectProduct">选择SKU</a-button>

        <div class="sku-list list-group">
          <div class="list-group-item"
               :class="{'list-group-item-warning': item.total === 0, 'list-group-item-danger': errorSkuItems[item.sku]}"
               v-for="item in transferData.items"
               :key="item.stockProductId"
          >
            <div>
              <div class="sku-item-wrapper">
                <a-button v-if="!isUpdate" type="danger" icon="delete" size="small" @click="handleRemoveSku(item)"></a-button>
                <div class="image-item small" v-lazy:background-image="item.imageUrl"></div>
                <div class="sku">{{item.sku}}</div>
                <div class="total">
                  <span v-if="isUpdate">{{item.total}}</span>
                  <a-input-number v-else v-model="item.total" :min="0" />
                </div>
              </div>
              <div class="error-message text-danger font-mini"
                   style="margin-top: 5px; margin-left: 60px;"
                   v-show="errorSkuItems[item.sku]">{{errorSkuItems[item.sku]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="button-container">
      <a-button v-show="!isUpdate" type="primary" @click="handleSave('transfer')" :loading="saveButtonLoading">迁移库存</a-button>
      <a-button v-show="!isUpdate" type="danger" @click="handleSave('lock')"  :loading="saveButtonLoading">冻结库存</a-button>
      <a-button v-show="isUpdate && transferData.status === statusMap.lock"
                type="primary"
                @click="handleSave('unlock')"
                :loading="saveButtonLoading"
      >
        入库
      </a-button>
      <a-button v-show="transferData.status === statusMap.inStock" @click="handleCopyTransfer" :loading="saveButtonLoading">复制迁移</a-button>
    </div>

    <stock-product-select ref="stockProduct"></stock-product-select>
  </drawer>
</template>

<script>

import StockProductSelect from '@/components-business/stock-product-select'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { updateStockTransfer, getStockTransferItems, getStockProductBySkuList } from '@/http/api/stock-transfer'
import {
  STOCK_TRANSFER_STATUS_LIST,
  STOCK_TRANSFER_STATUS_LOCK,
  STOCK_TRANSFER_STATUS_IN_STOCK
} from '@/constants/stock-transfer-status'
import kit from '@/utils/kit'

export default {
  components: { StockProductSelect },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      transferData: {
        id: null,
        title: null,
        status: null,
        fromWarehouseId: null,
        toWarehouseId: null,
        items: []
      },
      errorSkuItems: {},
      statusMap: {
        lock: STOCK_TRANSFER_STATUS_LOCK,
        inStock: STOCK_TRANSFER_STATUS_IN_STOCK
      },
      warehouseOptions: [],
      statusOptions: STOCK_TRANSFER_STATUS_LIST
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.model
    }
  },
  methods: {
    /**
     *
     * @param model
     * @param [skuItems] {Array} { stockProductId, sku, imageUrl, total }
     */
    open (model, skuItems) {
      if (Array.isArray(model)) {
        skuItems = model
        model = null
      }
      this.model = model
      if (model) {
        this.$nextTick(() => {
          this.transferData.id = model.id
          this.transferData.title = model.title
          this.transferData.status = model.status
          this.transferData.fromWarehouseId = model.fromWarehouseId
          this.transferData.toWarehouseId = model.toWarehouseId
        })
      }
      this.visible = true
      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }

      if (model) {
        getStockTransferItems()
          .success(resp => {
            this.transferData.items = resp.data
          })
          .send(model.id)
      } else if (skuItems) {
        this.transferData.items = skuItems
      }
    },
    onClose () {
      this.model = null
      this.transferData.id = null
      this.transferData.title = null
      this.transferData.status = null
      this.transferData.fromWarehouseId = null
      this.transferData.toWarehouseId = null
      this.transferData.items = []
      this.errorSkuItems = {}
    },
    handleSave (updateType) {
      this.$confirm({
        title: '警告',
        content: '确定要保存吗？',
        onOk: () => {
          this.transferData.updateType = updateType
          this.closable = false
          this.saveButtonLoading = true
          this.errorSkuItems = {}
          updateStockTransfer()
            .complete(() => {
              this.closable = true
              this.saveButtonLoading = false
            })
            .success(resp => {
              if (resp.data.success) {
                this.$message.success('保存成功。')
                this.$emit('saved', resp.data.data)
                this.visible = false
              } else {
                this.$message.error('发生错误，请查看SKU列表错误提示。')
                this.errorSkuItems = resp.data.data
              }
            })
            .send(this.transferData)
        }
      })
    },
    handleSelectProduct () {
      this.$refs.stockProduct.open(items => {
        for (const item of items) {
          if (!this.transferData.items.find(a => a.stockProductId === item.id)) {
            this.transferData.items.push({
              stockProductId: item.id,
              imageUrl: item.imageUrl,
              sku: item.sku,
              total: item.quantity
            })
          }
        }
      })
    },
    handleRemoveSku (item) {
      const sku = item.sku
      kit.arr.removeItem(this.transferData.items, item)
      delete this.errorSkuItems[sku]
    },
    handleCopyTransfer () {
      const skuList = this.transferData.items.map(item => item.sku)
      this.saveButtonLoading = true
      getStockProductBySkuList()
        .complete(() => (this.saveButtonLoading = false))
        .success(resp => {
          const skuTotalMap = {}
          for (const item of this.transferData.items) {
            skuTotalMap[item.sku] = item.total
          }
          const skuItems = []
          for (const item of resp.data) {
            skuItems.push({
              stockProductId: item.id,
              sku: item.sku,
              imageUrl: item.image,
              total: skuTotalMap[item.sku] || 0
            })
          }
          this.onClose()
          this.$info({
            title: '信息',
            content: '复制成功，请填写仓库信息。',
            onOk: () => {
              this.open(skuItems)
            }
          })
        })
        .send(skuList)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  display: flex;

  .left {
    width: 400px;
    overflow: hidden;
  }
  .right {
    flex: 1;
    overflow-y: auto;
    padding-left: 20px;
  }
}

.sku-item-wrapper {
  display: flex;
  align-items: center;
  .image-item {
    margin-left: 5px;
  }
  .sku {
    flex: 1;
    padding: 0 10px;
    font-size: 14px;
  }
  .total {
    font-weight: bold;
    font-size: 16px;
    color: #3d71a7;
  }
}

.button-container {
}
</style>
