<!--
  工厂下单产品项的编辑器
-->
<template>
  <modal ref="modal"
         :width="600"
         :max-height="450"
         :title="title"
         :loading="loading"
         :button-disabled="shopifyImage.visible"
         @cancel="onClose"
         @ok="onOk"
  >
    <loading :loading="loading"></loading>
    <div>

      <div style="text-align: right;">
        <a-button type="link" size="small" @click="handleUploadUpdatingImage">本地上传</a-button>
        <a-button type="link" size="small" @click="handleOpenShopifyImages" :disabled="!updateItem || !updateItem.orderProductId">从Shopify选择</a-button>
      </div>

      <!-- 表单 -->
      <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}" class="small-form">
        <a-form-model-item label="图片">
          <div class="form-image-list image-item-wrapper">
            <div class="image-item small cover"
                 :style="{'background-image': `url(${img})`}"
                 v-for="(img, index) in form.images"
                 :key="img"
                 @click="$imageModal.open(form.images, index)"
            >
              <div class="remove-image" @click.stop="handleRemoveUpdatingImageItem(img)">
                <a-icon type="close" />
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="属性" prop="variant" class="app_required-input">
          <a-input v-model="form.variant"/>
        </a-form-model-item>
        <a-form-model-item label="数量" prop="quantity" class="app_required-input">
          <a-input-number v-model="form.quantity" :min="1"/>
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="supplierId">
          <a-select v-model="form.supplierId" style="width: 100%;">
            <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-textarea
              v-model="form.remark"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>


        <a-divider>定制信息（选填）</a-divider>

        <a-form-model-item label="身高" prop="customizeInfo.height">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.height" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="胸围" prop="customizeInfo.bust">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.bust" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="腰围" prop="customizeInfo.waist">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.waist" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="臀围" prop="customizeInfo.hips">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.hips" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="臂长" prop="customizeInfo.arm">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.arm" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="腿长" prop="customizeInfo.legLength">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.legLength" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="裤腿内缝合" prop="customizeInfo.inseamLength">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoUnit">
              <a-select-option :value="item.id" v-for="item in lengthUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.inseamLength" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="体重" prop="customizeInfo.weight">
          <a-input-group compact>
            <a-select v-model="form.customizeInfoWeightUnit">
              <a-select-option :value="item.id" v-for="item in weightUnitOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input v-model="form.customizeInfo.weight" style="width: 100%"/>
          </a-input-group>
        </a-form-model-item>
      </a-form-model>


      <!-- Shopify图片选择面板 -->
      <div class="shopify-image-select-panel" v-show="shopifyImage.visible">
        <div class="toolbar">
          <a-button icon="left" type="link" @click="shopifyImage.visible = false">返回</a-button>
          <a-button type="link" @click="handleConfirmSelectShopifyImages">
            确认选择
            <span v-show="shopifyImage.selected.length > 0"> ({{shopifyImage.selected.length}})</span>
          </a-button>
        </div>
        <div style="position: relative;">
          <loading :loading="shopifyImage.loading"></loading>
          <div class="image-list image-item-wrapper">
            <div class="image-item cover"
                 :style="{'background-image': `url(${img})`}"
                 v-for="(img, index) in shopifyImage.images"
                 :key="img"
            >
              <ul>
                <li @click="handleSelectShopifyImage(img)" :class="{'selected': shopifyImage.selected.includes(img)}"><a-icon type="check" /></li>
                <li @click="$imageModal.open(shopifyImage.images, index)"><a-icon type="eye" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import kit from '@/utils/kit'
import selectFiles from '@/utils/select-file'
import { uploadImageByAddItem } from '@/http/api/delivery-produce-item'
import { getShopifyProductImages } from '@/http/api/shopify-product-images'
import CustomizeMixin from '@/mixins/customize'

export default {
  props: {
    supplierOptions: { type: Array }
  },
  mixins: [CustomizeMixin],
  data () {
    return {
      loading: false,
      updateItem: null,
      isUpdate: false,
      form: {
        images: [],
        variant: null,
        quantity: 1,
        supplierId: null,
        remark: null,
        // 三围尺寸单位
        customizeInfoUnit: 'inch',
        // 体重单位
        customizeInfoWeightUnit: 'lbs',
        customizeInfo: {
          height: null,
          bust: null,
          waist: null,
          hips: null,
          arm: null,
          legLength: null,
          inseamLength: null,
          weight: null
        }
      },
      shopifyImage: {
        loading: false,
        visible: false,
        selected: [],
        images: []
      },
      lengthUnitOptions: [
        { id: 'inch', name: 'inch' },
        { id: 'cm', name: 'cm' }
      ],
      weightUnitOptions: [
        { id: 'kg', name: 'kg' },
        { id: 'lbs', name: 'lbs' }
      ]
    }
  },
  computed: {
    title () {
      return this.updateItem ? this.updateItem.title : '编辑'
    }
  },
  methods: {
    /**
     *
     * @param item {SupplierProduceItemModel}
     * @param isUpdate {Boolean}
     */
    open (item, isUpdate) {
      this.isUpdate = isUpdate
      this.updateItem = item
      kit.obj.getProperties(this.form, item, ['images', 'customizeInfo'])
      this.form.images = [...item.getImages()]

      const customizes = this.splitCustomize(item.customizeInfo, true)
      for (const c of customizes) {
        if (this.form.customizeInfo[c.name] !== undefined) {
          this.form.customizeInfo[c.name] = c.value
          if (c.name === 'weight') {
            if (this.form.customizeInfoWeightUnit == null) {
              this.form.customizeInfoWeightUnit = c.unit
            }
          } else {
            if (this.form.customizeInfoUnit == null) {
              this.form.customizeInfoUnit = c.unit
            }
          }
        }
      }
      this.$refs.modal.open()
    },
    onClose () {
      this.updateItem = null
      this.form.images = []
      this.shopifyImage.images = []
      this.shopifyImage.selected = []
      this.$refs.form.resetFields()
    },
    onOk () {
      this.updateItem.setImages([...this.form.images])
      this.updateItem.customizeInfo = this.stringifyCustomize(
        this.form.customizeInfo,
        this.form.customizeInfoUnit,
        this.form.customizeInfoWeightUnit
      )
      this.updateItem.variant = this.form.variant
      this.updateItem.quantity = this.form.quantity
      this.updateItem.remark = this.form.remark
      this.updateItem.supplierId = this.form.supplierId
      this.$emit('saved', this.isUpdate, this.updateItem)
      this.$refs.modal.close()
    },
    handleRemoveUpdatingImageItem (img) {
      kit.arr.removeItem(this.form.images, img)
    },
    handleUploadUpdatingImage () {
      selectFiles({
        multi: false,
        accept: ['image/jpg', 'image/png', 'image/jpeg'],
        select: files => {
          const reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = () => {
            this.loading = true
            uploadImageByAddItem()
              .complete(() => (this.loading = false))
              .success(resp => {
                this.form.images.push(resp.data)
              })
              .send(reader.result)
          }
        }
      })
    },
    handleOpenShopifyImages () {
      this.shopifyImage.visible = true
      if (this.shopifyImage.images.length === 0 && !this.shopifyImage.loading) {
        this.shopifyImage.loading = true
        getShopifyProductImages()
          .complete(() => (this.shopifyImage.loading = false))
          .success(resp => {
            this.shopifyImage.images = resp.data
            this.shopifyImage.visible = true
          })
          .send(this.updateItem.orderProductId)
      }
    },
    handleSelectShopifyImage (img) {
      if (this.shopifyImage.selected.includes(img)) {
        kit.arr.removeItem(this.shopifyImage.selected, img)
      } else {
        this.shopifyImage.selected.push(img)
      }
    },
    handleConfirmSelectShopifyImages () {
      for (const img of this.shopifyImage.selected) {
        if (!this.form.images.includes(img)) {
          this.form.images.push(img)
        }
      }
      this.shopifyImage.visible = false
      this.shopifyImage.images = []
      this.shopifyImage.selected = []
    }
  }
}
</script>

<style lang="less" scoped>

@deep: ~'>>>';

.form-image-list {
  display: flex;
  margin-top: 4px;
  padding: 4px;
  border: solid 1px #ddd;
  border-radius: 5px;
  min-height: 40px;
  label {
    width: 80px;
    margin-right: 10px;
    padding-top: 5px;
    padding-left: 15px;
    font-size: 14px;
    color: #606266;
  }
  .image-list {
    display: flex;
  }
  .image-item {
    position: relative;
    margin: 5px;
    overflow: visible !important;
    .remove-image {
      position: absolute;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 20px;
      top: -8px;
      right: -8px;
      text-align: center;
      background-color: #bb3e30;
      color: #fff;
      font-size: 11px;
    }
  }
  .upload-image {
    text-align: center;
    border: dashed 1px #b1b1b1;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.shopify-image-select-panel {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow-y: auto;

  .image-item {
    position: relative;
    cursor: auto !important;
    ul {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      padding: 5px 0;
      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 20px;
        background-color: rgba(0,0,0,.6);
        color: #fff;
        font-size: 11px;
        cursor: pointer;
      }
      li + li {
        margin-left: 10px;
      }
      li.selected {
        background-color: rgba(107, 203, 66, 0.6);
      }
    }
  }
}

.small-form @{deep} .ant-input-group-compact {
  display: flex;
  .ant-select {
    width: 120px;
  }
  .input {
    flex: 1;
  }
}
</style>
