import defineApi from '../define-api'
import http from '../application-api'

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/stock_alert/list', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/stock_alert/update', config)
})

export const updateEnabled = defineApi((config, idList, enabled) => {
  config.params = {
    idList: idList.join(','),
    enabled: enabled
  }
  http.post('/stock_alert/update/enabled', config)
})

export const deleteById = defineApi((config, idList) => {
  config.params = {
    idList: idList.join(',')
  }
  http.post('/stock_alert/delete', config)
})

export const checkAlert = defineApi((config) => {
  http.post('/stock_alert/check_alert', config)
})

