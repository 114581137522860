<template>
  <div class="order-container">
    <div class="header" v-if="!simple">
      <span v-if="orders.length === 0">客户订单</span>
      <span v-else-if="orders.length === 1">{{order.orderNo}}</span>
      <div v-else>
        订单：
        <a-select
            v-model="selectedOrderId"
            style="width: 100px;"
        >
          <a-select-option
              v-for="item in orders"
              :key="item.id"
              :value="item.id"
          >
            {{item.orderNo}}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="content">

      <a-tabs type="card">
        <a-tab-pane v-if="!simple" key="1" tab="订单信息">
          <div v-if="order" class="order-panel">
            <div style="margin-bottom: 20px;">
              <div class="status-bar">
                <div class="status status-default" :class="'status-' + order.status">{{statusMapping[order.status]}}</div>
                <div class="status status-pay" :class="'status-' + order.statusPay">{{statusPayMapping[order.statusPay]}}</div>
                <div class="status status-ship" style="background-color: #368e0c; color: #fff;" v-if="order.allShip">全部发完</div>
                <div class="status status-ship" :class="'status-' + order.statusShipping" v-else>{{statusShipMapping[order.statusShipping]}}</div>
              </div>
            </div>


            <div class="section customer-section">
              <div class="section-title">备注</div>
              <div class="section-body">
                <div>
                  <div class="text-normal">客户备注</div>
                  <div class="font-normal text-danger" style="padding-left: 20px;" v-if="order.remark">{{order.remark}}</div>
                  <div class="font-mini  text-muted" v-else>无</div>
                </div>
                <div style="margin-top: 10px;">
                  <div class="text-normal">
                    管理员备注
                    <a-button type="link"
                              v-show="!adminRemarkEdit.edit"
                              @click="adminRemarkEdit.content = order.adminRemark; adminRemarkEdit.edit = true"
                    >
                      编辑
                    </a-button>
                  </div>
                  <div v-show="adminRemarkEdit.edit">
                    <a-textarea
                        v-model="adminRemarkEdit.content"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                    <div style="text-align: right; margin-top: 10px;">
                      <a-button size="small" @click="adminRemarkEdit.edit = false" :loading="adminRemarkEdit.saving">取消</a-button>
                      <a-button type="primary" size="small" @click="handleSaveAdminRemark" :loading="adminRemarkEdit.saving">保存</a-button>
                    </div>
                  </div>
                  <div v-show="!adminRemarkEdit.edit">
                    <div class="font-normal text-danger" style="padding-left: 20px;" v-if="order.adminRemark">{{order.adminRemark}}</div>
                    <div class="font-mini  text-muted" v-else>无</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section customer-section">
              <div class="section-title">客户</div>
              <div class="section-body">
                <div style="padding: 6px 0;" class="left-right-content">
                  <span class="font-large-x full">{{order.email}}</span>
                </div>

                <div style="display: flex;">
                  <span class="font-mini font-bold" style="flex: 1; margin-right: 10px;">收货地址</span>
                </div>
                <div style="margin-top: 10px;">
                  <div class="info-block">
                    <label>姓名</label>
                    <span>{{order.receiverLastName}} {{order.receiverFirstName}}</span>
                  </div>
                  <div class="info-block">
                    <label>电话</label>
                    <span>{{order.receiverPhone}}</span>
                  </div>
                  <div class="info-block">
                    <label>国家</label>
                    <span>{{order.receiverCountry}}</span>
                  </div>
                  <div class="info-block">
                    <label>省 / 州</label>
                    <span>{{order.receiverState}}</span>
                  </div>
                  <div class="info-block">
                    <label>城市</label>
                    <span>{{order.receiverCity}}</span>
                  </div>
                  <div class="info-block">
                    <label>地址</label>
                    <span>{{order.receiverAddress1}} {{order.receiverAddress2}}</span>
                  </div>
                  <div class="info-block">
                    <label>邮编</label>
                    <span>{{order.receiverPostalCode}}</span>
                  </div>
                </div>
              </div>
            </div>


            <div class="section products-section">
              <div class="section-title">产品</div>
              <div class="section-body">
                <ul class="product-list">
                  <li class="product-item" v-for="p in order.products" :key="p.id">
                    <div class="product-image" :style="{'background-image': `url(${p.imageUrl})`}" @click="$refs.imageModal.open(p.imageUrl)"></div>
                    <div class="main-info">
                      <div class="product-title">
                        <a :href="p.productUrl" target="_blank" style="color: #3d71a7;">{{p.title}}</a>
                      </div>
                      <div class="variant">规格：{{p.variant}}</div>
                      <div class="font-mini text-muted">SKU：{{p.sku}}</div>
                      <div class="single">
                        <span v-if="p.discountPrice > 0">{{order.currency}} {{p.discountPrice}}</span>
                        <span v-else>免费</span>
                        x {{p.total}}（<del>{{p.salePrice}}</del>）
                      </div>
                      <div style="font-weight: bold; font-size: 13px; color: #e87608" v-if="p.produceItemTotal > 0">
                        供应商生产：{{p.produceItemTotal}} 个订单
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="section price-section">
              <div class="section-title">金额</div>
              <div class="section-body">
                <ul>
                  <li>
                    <div class="label">小计</div>
                    <div class="info">{{order.products | orderItemTotal}} 件产品</div>
                    <div class="price">{{order.currency}} {{order.priceProducts}}</div>
                  </li>
                  <li>
                    <div class="label">物流</div>
                    <div class="info">{{order.shippingMethod}}</div>
                    <div class="price">{{order.currency}} {{order.priceShipping}}</div>
                  </li>
                  <li>
                    <div class="label">优惠</div>
                    <div class="info"></div>
                    <div class="price">- {{order.currency}} {{order.priceDiscount}}</div>
                  </li>
                  <li>
                    <div class="label">总计</div>
                    <div class="info"></div>
                    <div class="price">{{order.currency}} {{order.priceOrder}}</div>
                  </li>
                  <li style="font-weight: bold; font-size: 16px;">
                    <div class="label">客户付款</div>
                    <div class="info"></div>
                    <div class="price">{{order.currency}} {{order.pricePay}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane v-if="!simple" key="2" tab="已发货">
          <div class="order-delivery-panel order-delivery-list">
            <a-empty v-show="orderDeliveryList.length === 0 && !orderDeliveryListLoading" />
            <div style="text-align: center; padding: 10px 0;" v-show="orderDeliveryListLoading">
              <a-spin />
            </div>
            <div class="item list-item-inset font-mini text-main" v-for="item in orderDeliveryList" :key="item.id">
              <div style="border-bottom: solid 1px #eee; padding: 5px 0; font-weight: bold;">
                运单号：<a :href="item.trackUrl" target="_blank">{{item.trackNo}}</a>
              </div>
              <div style="margin-top: 5px;">
                客户单号：{{item.orderNo}}
              </div>
              <div class="status" :class="'status-' + item.status" style="margin-top: 5px;">
                状态：{{deliveryStatusMapping[item.status]}}
                <span v-if="item.feeStatus" class="font-mini text-danger">（运费 {{item.fee}}）</span>
              </div>
              <div style="margin-top: 5px;">
                物流：{{deliveryLogisticsMapping[item.companyId]}}（{{item.shippingMethod}}）
              </div>
              <div class="left-right-content" style="margin-top: 5px;">
                <div>发货：{{item.createTime}}</div>
                <div v-if="item.arrivedTime" class="text-success">签收：{{item.arrivedTime}}</div>
              </div>
              <div class="delivery-items">
                <a-tooltip v-for="op in item.deliveryProductItems" :key="op.orderProductId">
                  <template slot="title">
                    {{op.sku || '无SKU'}}
                  </template>
                  <div class="image-item"
                       :style="{'background-image': `url(${op.imageUrl})`}"
                       @click="$refs.imageModal.open(op.imageUrl)"
                  >
                    <div class="total">{{op.total}}</div>
                  </div>
                </a-tooltip>
              </div>
              <div class="text-danger font-bold" v-if="item.remark">
                备注：<span>{{item.remark}}</span>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="3" tab="退货">
          <div class="order-return-panel">
            <a-empty v-show="orderReturnList.length === 0 && !orderReturnListLoading" />
            <div style="text-align: center; padding: 10px 0;" v-show="orderReturnListLoading">
              <a-spin />
            </div>

            <div class="order-return-item list-item-inset font-mini"
                 v-for="item in orderReturnList"
                 :key="item.id"
            >
              <div style="flex: 1; margin-right: 10px;">
                <div class="text-block">
                  <label>订单号：</label>
                  <span>{{item.orderNo}}</span>
                </div>
                <div class="text-block">
                  <label>状态：</label>
                  <span>{{orderReturnStatusMapping[item.status]}}</span>
                </div>
                <div class="text-block">
                  <label>跟踪号：</label>
                  <span>
                    <a :href="item.trackUrl" v-if="item.trackUrl" target="_blank">{{item.trackNo}}</a>
                    <span v-else>{{item.trackNo}}</span>
                  </span>
                  <span>{{item.trackNo}}</span>
                </div>
                <div class="text-block">
                  <label>物流公司：</label>
                  <span>{{item.logisticsName}}</span>
                </div>
                <div class="text-block">
                  <label>创建时间：</label>
                  <span>{{item.createTime}}</span>
                </div>
                <div class="text-block">
                  <label>签收时间：</label>
                  <span>{{item.deliveryTime}}</span>
                </div>
                <div class="text-block">
                  <label>备注：</label>
                  <span>{{item.remark}}</span>
                </div>
                <div class="return-items">
                  <a-tooltip v-for="rItem in item.returnItems" :key="rItem.id">
                    <template slot="title">
                      {{rItem.sku || '无SKU'}}
                    </template>
                    <div class="image-item small"
                         :style="{'background-image': `url(${rItem.imageUrl})`}"
                         @click="$refs.imageModal.open(rItem.imageUrl)"
                    >
                      <div class="total">{{rItem.quantity}}</div>
                    </div>
                  </a-tooltip>
                </div>
              </div>
              <div>
                <div>
                  <a-button size="small" type="primary" @click="$emit('updateOrderReturn', item)">编辑</a-button>
                </div>
                <div style="margin-top: 10px;">
                  <a-button size="small" type="danger" @click="handleDeleteOrderReturn(item.id)">删除</a-button>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="4" tab="供应商订单">
          <div class="supplier-produce-item-panel">
            <a-empty v-show="supplierProduceItemList.length === 0 && !supplierProduceItemListLoading" />
            <div style="text-align: center; padding: 10px 0;" v-show="supplierProduceItemListLoading">
              <a-spin />
            </div>

            <div class="supplier-produce-item list-item-inset font-mini"
                 v-for="item in supplierProduceItemList"
                 :key="item.id"
            >
              <div>
                <div class="image-item" :style="{'background-image': `url(${item.images[0]})`}" @click="$refs.imageModal.open(item.images[0])"></div>
                <div class="status" :class="'status-' + item.status">{{supplierProduceItemStatusMapping[item.status]}}</div>
              </div>
              <div class="main-content">
                <div class="left-right-content">
                  <div class="full font-bold text-primary">规格：{{item.variant}}</div>
                  <div>x <span class="font-large-x font-bold text-danger">{{item.quantity}}</span></div>
                </div>
                <div>
                  <label>编号：</label>
                  <span style="letter-spacing: 2px; color: #2b2b2b; font-size: 14px; font-weight: bold;">{{item.number}}</span>
                </div>
                <div>
                  <label>SKU：</label>
                  <span>{{item.sku}}</span>
                </div>
                <div>
                  <label>日期：</label>
                  <span>{{item.createTime}}</span>
                </div>
                <div>
                  <label>供应商：</label>
                  <span>{{item.supplierName}}</span>
                </div>
                <div class="left-right-content">
                  <div v-if="item.dayNewToAccept > 0">
                    <span v-if="item.confirmTime">接单用时：{{item.dayNewToAccept}}天</span>
                    <span v-else class="text-danger">未接单（已过{{item.dayNewToAccept}}天）</span>
                  </div>
                  <div v-if="item.dayAcceptToDelivery > 0">
                    <span v-if="item.deliveryTime">接单到发货用时：{{item.dayAcceptToDelivery}}天</span>
                    <span v-else class="text-danger">未发货（已过{{item.dayAcceptToDelivery}}天）</span>
                  </div>
                </div>
                <div class="left-right-content">
                  <div>
                    <span v-if="item.delay" class="text-danger">延迟处理</span>
                  </div>
                  <div>
                    <span v-if="item.remark" class="font-bold font-large text-danger">订单备注</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>

    </div>

    <image-modal ref="imageModal"></image-modal>
  </div>
</template>

<script>
import { getOrdersByEmail } from '@/http/api/after-sale-session'
import { listByOrder } from '@/http/api/order-delivery'
import { listByOrder as listOrderReturnByOrder, deleteById } from '@/http/api/order-return'
import { updateAdminRemark } from '@/http/api/order'
import { listItemsByOrderId } from '@/http/api/supplier-produce-item'
import { ORDER_STATUS_MAPPING, ORDER_PAY_STATUS_MAPPING, ORDER_SHIP_STATUS_MAPPING } from '@/constants/order-status'
import { ORDER_DELIVERY_STATUS_MAPPING, ORDER_DELIVERY_STATUS } from '@/constants/order-delivery-status'
import { LOGISTICS_COMPANY_MAPPING } from '@/constants/logistics'
import { SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING } from '@/constants/supplier-produce-item-status'
import { ORDER_RETURN_STATUS_MAPPING } from '@/constants/order-return-status'
import kit from '@/utils/kit'

export default {
  props: {
    email: { type: String },
    simple: { type: Boolean, default: false }
  },
  data () {
    return {
      orders: [],
      selectedOrderId: null,
      statusMapping: ORDER_STATUS_MAPPING,
      statusPayMapping: ORDER_PAY_STATUS_MAPPING,
      statusShipMapping: ORDER_SHIP_STATUS_MAPPING,
      deliveryStatusMapping: ORDER_DELIVERY_STATUS_MAPPING,
      deliveryStatus: ORDER_DELIVERY_STATUS,
      deliveryLogisticsMapping: LOGISTICS_COMPANY_MAPPING,
      supplierProduceItemStatusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING,
      orderReturnStatusMapping: ORDER_RETURN_STATUS_MAPPING,
      orderDeliveryList: [],
      orderDeliveryListLoading: false,
      supplierProduceItemList: [],
      supplierProduceItemListLoading: false,
      orderReturnList: [],
      orderReturnListLoading: false,
      adminRemarkEdit: {
        edit: false,
        content: null,
        saving: false
      }
    }
  },
  filters: {
    orderItemTotal (items) {
      if (items) {
        let total = 0
        for (const item of items) {
          total += item.total
        }
        return total
      }
      return 0
    }
  },
  computed: {
    order () {
      return this.orders.find(item => item.id === this.selectedOrderId)
    }
  },
  watch: {
    selectedOrderId (id) {
      if (id) {
        this.reloadAllData()
      }
    }
  },
  methods: {
    reload (orderId) {
      this.selectedOrderId = orderId
    },
    reloadAllData () {
      if (!this.simple) {
        this.loadOrderDeliveries()
      }
      this.loadSupplierProduceItems()
      this.loadOrderReturns()
    },
    updateOrderReturn (orderReturn) {
      kit.arr.pushOrReplace(this.orderReturnList, orderReturn)
    },
    loadOrders (email) {
      if (this.simple) {
        this.reloadAllData()
      } else {
        getOrdersByEmail()
          .success(resp => {
            this.orders = resp.data
            if (this.orders.length > 0) {
              if (this.selectedOrderId == null) {
                this.selectedOrderId = this.orders[0].id
              } else {
                this.reloadAllData()
              }
            }
          })
          .send(email || this.email)
      }
    },
    loadOrderDeliveries () {
      this.orderDeliveryListLoading = true
      listByOrder()
        .complete(() => (this.orderDeliveryListLoading = false))
        .success(resp => {
          this.orderDeliveryList = resp.data
        })
        .send(this.selectedOrderId)
    },
    loadOrderReturns () {
      this.orderReturnListLoading = true
      listOrderReturnByOrder()
        .complete(() => (this.orderReturnListLoading = false))
        .success(resp => {
          this.orderReturnList = resp.data
        })
        .send(this.selectedOrderId)
    },
    loadSupplierProduceItems () {
      this.supplierProduceItemListLoading = true
      listItemsByOrderId()
        .complete(() => (this.supplierProduceItemListLoading = false))
        .success(resp => {
          this.supplierProduceItemList = resp.data
        })
        .send(this.selectedOrderId)
    },
    handleSaveAdminRemark () {
      this.adminRemarkEdit.saving = true
      updateAdminRemark()
        .complete(() => (this.adminRemarkEdit.saving = false))
        .success(resp => {
          this.order.adminRemark = this.adminRemarkEdit.content
          this.adminRemarkEdit.edit = false
        })
        .send(this.order.id, this.adminRemarkEdit.content)
    },
    handleDeleteOrderReturn (id) {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          deleteById()
            .success(resp => {
              kit.arr.remove(this.orderReturnList, { id: id })
              this.$message.success('删除成功。')
            })
            .send(id)
        }
      })
    }
  },
  mounted () {
    this.loadOrders()
  }
}
</script>

<style lang="less" scoped>
.order-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 500px;
  margin-left: 10px;
  border-left: solid 1px #eee;

  .header {
    padding: 10px;
    border-bottom: solid 1px #eee;
  }
  .content {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
}


.order-panel {
  .status-bar {
    .status {
      & + .status {
        margin-left: 15px;
      }
      display: inline-block;
      padding: 3px 10px;
      font-size: 13px;
      border-radius: 20px;
      background-color: #d2d2d2;
    }
    .status-produce {
      color: #fff;
      background-color: #e36f1a;
    }
    .status-complete {
      color: #fff;
      background-color: #368e0c;
    }
    .status-close, .status-cancel {
      color: #fff;
      background-color: #bb3e30;
    }
    .status-payPartially, .status-pay {
      color: #fff;
      background-color: #368e0c;
    }
    .status-refund, .status-refundPartially {
      color: #fff;
      background-color: #bb3e30;
    }

    .status-send {
      color: #fff;
      background-color: #0976bb;
    }
    .status-arrived {
      color: #fff;
      background-color: #368e0c;
    }
  }

  .section + .section {
    margin-top: 20px;
  }
  .section {
    background-color: #fff;
    border-top: solid 1px #eee;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
    font-size: 13px;
    .section-title {
      font-size: 14px;
      font-weight: bold;
      padding: 10px;
      border-bottom: solid 1px #f2f2f2;
    }
    .section-body {
      padding: 10px;
    }
  }

  .customer-section {
    .copy {
      font-size: 12px;
      color: #0976bb;
    }
    .info-block {
      label {
        display: inline-block;
        text-align: right;
        width: 60px;
      }
      label::after {
        content: '：';
      }
      span {
        color: #606266;
      }
    }
  }


  .products-section {

    .product-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .product-item + .product-item {
      margin-top: 16px;
    }
    .product-item {
      display: flex;
      .product-image {
        width: 50px;
        height: 50px;
        border: solid 1px #ddd;
        border-radius: 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .main-info {
        flex: 1;
        margin-left: 15px;
        color: #404040;
      }
      .product-title {
        font-size: 14px;
        color: #3d71a7;
      }
      .variant {
        color: #8b8b8b;
      }
    }
  }

  .price-section {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        padding: 5px 0;
        .label {
          width: 100px;
        }
        .info {
          flex: 1;
          padding: 0 10px;
        }
        .price {
          text-align: right;
        }
      }
    }
  }
}


.order-delivery-panel {

  .data-list {
    margin-bottom: 10px;
  }
  .item {
    padding: 10px;
  }

  .status {
    font-size: 13px;
  }

  .status-shipped {
    color: #3d71a7;
  }
  .status-arrived {
    color: #368e0c;
  }
  .status-cancel {
    color: #ef8629;
  }
  .status-ex {
    color: #bb3e30;
  }

  .button-text {
    color: #4586d4;
    font-size: 13px;
  }
  .button-text + .button-text {
    margin-left: 10px;
  }

  .delivery-items {
    margin-top: 10px;
    .image-item {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 5px;
      background-color: #eee;
      overflow: visible;
      .total {
        position: absolute;
        top: -10px;
        right: -10px;
        color: #fff;
        background-color: #3d71a7;
        font-size: 12px;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

.supplier-produce-item-panel {
  .supplier-produce-item {
    padding: 10px;
    display: flex;
    background-color: #fff;
    &:active {
      background-color: #f2f2f2;
    }
    .main-content {
      flex: 1;
      margin-left: 15px;
    }
  }
  .supplier-produce-item + .supplier-produce-item {
    border-top: solid 1px #eee;
  }

  .status {
    margin-top: 6px;
    text-align: center;
    color: #fff;
    padding: 4px 6px;
    border-radius: 10px;
    font-size: 12px;
  }
  .status-new {
    background-color: #606266;
  }
  .status-accept {
    background-color: #3d71a7;
  }
  .status-reject {
    background-color: #bb3e30;
  }
  .status-delivery {
    background-color: #368e0c;
  }
  .status-return {
    background-color: #de8418;
  }
  .status-delete {
    background-color: #000000;
  }
}


.order-return-panel {
  .order-return-item {
    display: flex;
    padding: 10px;
    .text-block {
      display: flex;
      align-items: center;
      label {
        width: 80px;
        text-align: right;
      }
      span {
        flex: 1;
      }
    }
    .text-block + .text-block {
      margin-top: 5px;
    }

    .return-items {
      margin-top: 10px;
      .image-item {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 5px;
        background-color: #eee;
        overflow: visible;
        .total {
          position: absolute;
          top: -10px;
          right: -10px;
          color: #fff;
          background-color: #3d71a7;
          font-size: 12px;
          border-radius: 100%;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
