<template>
  <app-page :route-name="routeName" :use-tcb-layout="false">

    <div class="container">

      <ul class="list-group history">
        <li class="list-group-item" v-for="(h, index) in sendHistory" :key="index" @click="handleFromHistory(h)">
          {{index+1}} - {{h.reqMethod}} - {{h.reqPath}}
          <div>
            {{shopNameMap[h.shopId]}}
          </div>
        </li>
      </ul>

      <div class="test-panel">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">

          <a-form-item label="Shop">
            <a-select v-model="form.shopId" style="width: 100%;">
              <a-select-option v-for="s in shopList" :key="s.id">{{s.name}}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Method">
            <a-select v-model="form.reqMethod" style="width: 100%;">
              <a-select-option value="post">Post</a-select-option>
              <a-select-option value="get">Get</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Version">
            <a-select v-model="form.reqVersion" style="width: 100%;">
              <a-select-option value="202212">202212</a-select-option>
              <a-select-option value="202305">202305</a-select-option>
              <a-select-option value="202306">202306</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Path">
            <a-input v-model="form.reqPath"></a-input>
          </a-form-item>

          <a-form-item label="Query">
            <a-input v-model="form.reqQuery" placeholder="a=1&b=2"></a-input>
          </a-form-item>

          <a-form-item label="Body">
            <a-textarea
                v-model="form.reqBody"
                placeholder="JSON"
                :auto-size="{ minRows: 5, maxRows: 10 }"
            />
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 19, offset: 5 }">
            <a-button type="primary" @click="send(false)" :loading="sending">Send</a-button>
            <a-button type="primary" @click="send(true)" :loading="sending">Send And History</a-button>
          </a-form-item>
        </a-form>
      </div>

      <div class="response">
        <div class="box">
          {{apiResponse}}
        </div>
      </div>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_TEST } from '@/router/router-constants'
import defineApi from '@/http/define-api'
import http from '@/http/application-api'
import { listShopOptions } from '@/http/api/shop'

const sendTest = defineApi((config, data) => {
  config.data = data
  http.post('/shop_api_test', config)
})

export default {
  data () {
    return {
      routeName: ROUTE_TEST,
      form: {
        reqMethod: 'post',
        reqPath: null,
        reqVersion: null,
        reqQuery: null,
        reqBody: null,
        shopId: null
      },
      sending: false,
      apiResponse: null,
      shopList: [],
      sendHistory: []
    }
  },
  computed: {
    shopNameMap () {
      const map = {}
      for (const s of this.shopList) {
        map[s.id] = s.name
      }
      return map
    }
  },
  watch: {
  },
  methods: {
    send (addHistory) {
      const p = Object.assign({}, this.form)
      this.sending = true
      sendTest()
        .complete(() => (this.sending = false))
        .success(resp => {
          if (addHistory) {
            this.sendHistory.push(p)
          }
          this.apiResponse = resp.data
          this.$message.success('发送成功。')
        })
        .send(p)
    },
    handleFromHistory (h) {
      this.$confirm({
        title: '确认',
        content: '确定要使用这个请求参数吗？',
        onOk: () => {
          Object.assign(this.form, h)
        }
      })
    }
  },
  mounted () {
    listShopOptions().success(resp => (this.shopList = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

.container {
  display: flex;
}
.history {
  width: 300px;
  .list-group-item {
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}
.test-panel {
  margin: 0 20px;
  width: 500px;
}
.response {
  flex: 1;
  .box {
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    min-height: 100px;
  }
}
</style>
