<template>
  <modal ref="modal" :title="title" :width="700" @ok="handleEditorConfirm" @closed="handleEditorClosed" :loading="loading">
    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="标签">
        <a-select mode="tags" style="width: 100%" v-model="form.tags">
        </a-select>
        <div>
          <span>快速填充：</span>
          <a-tag style="cursor: pointer;" @click="handleFastTag('PP Case')" color="red">PP Case</a-tag>
          <a-tag style="cursor: pointer;" @click="handleFastTag('Shopify Case')" color="orange">Shopify Case</a-tag>
          <a-tag style="cursor: pointer;" @click="handleFastTag('缺货')" color="cyan">缺货</a-tag>
          <a-tag style="cursor: pointer;" @click="handleFastTag('物流问题')" color="blue">物流问题</a-tag>
        </div>
      </a-form-model-item>
      <a-form-model-item label="优先级">
        <a-select v-model="form.priority" style="width: 100%;">
          <a-select-option :value="0">实时关注</a-select-option>
          <a-select-option :value="3">重要</a-select-option>
          <a-select-option :value="6">一般</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </modal>
</template>

<script>
import { addImportantOrder } from '@/http/api/important-order'

export default {
  data () {
    return {
      order: null,
      loading: false,
      form: {
        id: null,
        message: [],
        tags: [],
        priority: 6
      }
    }
  },
  computed: {
    title () {
      if (this.order) {
        return '关注订单 - ' + this.order.orderNo
      } else {
        return '关注订单'
      }
    }
  },
  methods: {
    open (order) {
      this.order = order
      this.form.id = order.id
      if (order.impTags) {
        try {
          this.form.tags = order.impTags.split(',')
        } catch (ignore) {
          this.form.tags = []
        }
      }
      if (order.impPriority != null) {
        this.form.priority = order.impPriority
      }
      this.$refs.modal.open()
    },
    handleEditorClosed () {
      this.order = null
      this.form.id = null
      this.form.tags = []
      this.form.message = []
      this.form.priority = 6
    },
    handleFastTag (text) {
      if (!this.form.tags.includes(text)) {
        this.form.tags.push(text)
      }
    },
    handleEditorConfirm () {
      this.loading = true
      const data = {
        id: this.form.id,
        tags: this.form.tags.join(','),
        message: null,
        priority: this.form.priority
      }
      addImportantOrder()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.order.impTags = data.tags
          this.order.impPriority = data.priority
          this.$emit('saved', this.order)
          this.$nextTick(() => {
            this.$refs.modal.close()
          })
        })
        .send(data)
    }
  }
}
</script>

<style scoped>

</style>
