<!--
  库存分析
-->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="日期">
          <a-range-picker v-model="searchParams.date" value-format="yyyy-MM-DD" :ranges="dateRanges" style="width: 200px;" />
        </a-form-model-item>
        <a-form-model-item label="款号">
          <a-input placeholder="款号关键字" v-model="searchParams.styleNumber" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="来源店铺">
          <a-select v-model="searchParams.source" style="width: 120px;" :allow-clear="true">
            <a-select-option :value="item" v-for="item in sourceOptions" :key="item">{{item}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-select v-model="searchParams.orderBy">
            <a-select-option value="amount,desc">销售额（大到小）</a-select-option>
            <a-select-option value="amount,asc">销售额（小到到）</a-select-option>
            <a-select-option value="total,desc">销售量（多到少）</a-select-option>
            <a-select-option value="total,asc">销售量（少到多）</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="loadData" icon="search">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-dropdown>
            <a-menu slot="overlay" @click="handleExportReport">
              <a-menu-item key="style">按款号（前100个）</a-menu-item>
              <a-menu-item key="sku">按SKU（前1000个）</a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px"> 导出查询结果 <a-icon type="down" /> </a-button>
          </a-dropdown>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
        slot="center"
        size="middle"
        rowKey="styleNumber"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :loading="dataLoading"
    >
      <div slot="detail" slot-scope="v, record">
        <a-button size="small" @click="openSkuSale(record)">详情</a-button>
        <a-button size="small" @click="$refs.saleTrend.open(record.styleNumber, searchParams.date[0], searchParams.date[1])">趋势</a-button>
      </div>
      <div slot="imageUrl" slot-scope="imageUrl">
        <div class="image-item" v-lazy:background-image="imageUrl" @click="$imageModal.open(imageUrl)"></div>
      </div>

      <div slot="allSaleAmount" slot-scope="allSaleAmount">
        <span class="font-bold text-danger font-large">{{allSaleAmount | price}}</span>
      </div>

      <div slot="allSaleTotal" slot-scope="allSaleTotal">
        <span class="font-bold text-main font-large">{{allSaleTotal}}</span>
      </div>

      <div slot="productTitle" slot-scope="title, record">
        <a :href="record.productUrl" v-if="record.productUrl" target="_blank">
          <div style="white-space: pre-wrap;">{{title}}</div>
        </a>
        <div v-else style="white-space: pre-wrap;">{{title}}</div>
      </div>
    </a-table>
    <sku-sale ref="skuSale"></sku-sale>
    <sale-trend ref="saleTrend"></sale-trend>
  </app-page>
</template>

<script>
import { getSaleReportGroupByStyle, exportSaleReport, getSourceOptions } from '@/http/api/stock-product'
import kit from '@/utils/kit'
import moment from 'moment'
import SkuSale from './sku-sale'
import SaleTrend from './sale-trend'
import { ROUTE_ANALYSIS_SALE } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'

export default {
  components: { SkuSale, SaleTrend },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ANALYSIS_SALE,
      dataColumns: [
        {
          title: '',
          width: 120,
          scopedSlots: { customRender: 'detail' }
        },
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: '款号',
          dataIndex: 'styleNumber',
          width: 120
        },
        {
          title: '销售额',
          dataIndex: 'allSaleAmount',
          width: 120,
          scopedSlots: { customRender: 'allSaleAmount' }
        },
        {
          title: '销售数量',
          dataIndex: 'allSaleTotal',
          width: 120,
          scopedSlots: { customRender: 'allSaleTotal' }
        },
        {
          title: '产品标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'productTitle' }
        }
      ],
      dataList: [],
      dataLoading: false,
      searchParams: {
        date: [],
        styleNumber: null,
        source: null,
        orderBy: 'amount,desc'
      },
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        上周: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        上个月: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      sourceOptions: []
    }
  },
  filters: {
    price (v) {
      return kit.str.fmtCurrency(v, 2)
    }
  },
  computed: {
  },
  methods: {
    loadData () {
      const p = Object.assign({}, this.searchParams)
      if (p.date.length === 2) {
        p.startTime = p.date[0] + ' 00:00:00'
        p.endTime = p.date[1] + ' 23:59:59'
      } else {
        return this.$message.warning('请选择日期。')
      }
      const orderTmp = p.orderBy.split(',')
      p.orderByName = orderTmp[0]
      p.orderByDirection = orderTmp[1]
      delete p.date
      delete p.orderBy
      this.dataLoading = true
      getSaleReportGroupByStyle()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(p)
    },
    openSkuSale (item) {
      const index = this.dataList.findIndex(a => a.styleNumber === item.styleNumber)
      this.$refs.skuSale.open(
        this.dataList,
        index,
        this.searchParams.date[0] + ' 00:00:00',
        this.searchParams.date[1] + ' 23:59:59'
      )
    },
    handleExportReport (e) {
      const p = Object.assign({}, this.searchParams)
      if (p.date.length === 2) {
        p.startTime = p.date[0] + ' 00:00:00'
        p.endTime = p.date[1] + ' 23:59:59'
      } else {
        return this.$message.warning('请选择日期。')
      }
      p.exportType = e.key
      window.open(exportSaleReport(p))
    }
  },
  mounted () {
    const dates = this.dateRanges['上周']
    this.searchParams.date = [dates[0].format('yyyy-MM-DD'), dates[1].format('yyyy-MM-DD')]
    this.loadData()
    getSourceOptions().success(resp => (this.sourceOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
</style>
