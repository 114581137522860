import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Image extends Section {
  constructor () {
    super('image', '图片')

    this.imageUrl = null
    this.mobileImageUrl = null
    this.url = null
    // left, center, right
    this.align = 'center'
    this.width = '100%'
    this.height = 'auto'
  }

  getContentType () {
    return 'image'
  }

  getHeaderDisplay () {
    return this.imageUrl ? [this.imageUrl] : []
  }
}
