<template>
  <transition name="slideUp">
    <div class="session-event-editor" v-show="visible" style="animation-duration: 0.2s">
      <div class="header">添加服务记录</div>

      <div class="body">
        <div v-show="form.type === eventType.text">
          <rich-editor :resize="false" :simple="true" :content.sync="textContent"></rich-editor>
        </div>

        <div v-show="form.type === eventType.image"
             class="image-upload-container"
             @click="handleSelectImageFile"
             :style="{'background-image': `url(${imageContent})`}"
        >
          <div class="select-image-text" v-show="!imageContent">请选择图片</div>
        </div>

        <div v-show="form.type === eventType.refund">
          <a-form-model ref="refundForm" :model="refundContent" :label-col="{span: 4}" :wrapper-col="{span: 20}">
            <a-form-model-item label="订单号" class="app_required-input" prop="orderNo">
              <a-input v-model="refundContent.orderNo"/>
            </a-form-model-item>
            <a-form-model-item label="退回渠道" class="app_required-input" prop="returnChannel">
              <a-select v-model="refundContent.returnChannel" style="width: 100%;">
                <a-select-option value="原路退回">原路退回</a-select-option>
                <a-select-option value="Paypal">Paypal</a-select-option>
                <a-select-option value="其他">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="货币" class="app_required-input" prop="currency">
              <a-input v-model="refundContent.currency"/>
            </a-form-model-item>
            <a-form-model-item label="退款金额" class="app_required-input" prop="price">
              <a-input-number v-model="refundContent.price"/>
            </a-form-model-item>
            <a-form-model-item label="手续费" prop="fee">
              <a-input-number v-model="refundContent.fee" placeholder="Paypal手续费5%"/>
              <span style="margin-left: 10px;" class="text-normal font-mini">手续费按百分比填，1～100。</span>
            </a-form-model-item>
            <a-form-model-item label="退款账号" prop="account">
              <a-input v-model="refundContent.account" />
            </a-form-model-item>
          </a-form-model>
        </div>

      </div>

      <div class="footer">
        <a-button type="primary" @click="handleSave" :loading="saving">添加</a-button>
        <a-button @click="visible = false" :loading="saving">取消</a-button>
      </div>
    </div>
  </transition>
</template>

<script>
import RichEditor from '@/components/rich-editor'
import { EVENT_TYPE, EVENT_TYPE_TEXT } from '@/constants/after-sale-event-type'
import { addEvent } from '@/http/api/after-sale-session'
import selectFiles from '@/utils/select-file'

function getFormData () {
  return {
    afterSaleSessionId: null,
    task: false,
    taskAdminId: null,
    taskDate: null,
    taskStatus: null,
    enabled: true,
    type: null,
    completeSession: false
  }
}

export default {
  components: { RichEditor },
  data () {
    return {
      visible: false,
      eventType: EVENT_TYPE,
      textContent: null,
      imageContent: null,
      refundContent: {
        orderNo: null,
        returnChannel: '原路退回',
        currency: 'USD',
        price: 0,
        fee: 0,
        account: null
      },
      form: Object.assign({}, getFormData()),
      saving: false
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.onClose()
      }
    },
    'refundContent.returnChannel' (channel) {
      if (channel === 'Paypal') {
        this.refundContent.fee = 5
      } else {
        this.refundContent.fee = 0
      }
    }
  },
  methods: {
    open (id, type, orderNo, orderPayPrice) {
      this.form.afterSaleSessionId = id
      this.form.type = type
      this.refundContent.orderNo = orderNo
      this.refundContent.price = orderPayPrice || 0
      this.visible = true
    },
    openForComplete (id) {
      this.form.afterSaleSessionId = id
      this.form.type = EVENT_TYPE_TEXT
      this.form.completeSession = true
      this.visible = true
    },
    onClose () {
      this.$refs.refundForm.resetFields()
      this.textContent = null
      this.imageContent = null
      Object.assign(this.form, getFormData())
    },
    handleSave () {
      const data = Object.assign({}, this.form)
      switch (this.form.type) {
        case this.eventType.text:
          data.content = this.textContent
          break
        case this.eventType.image:
          if (!this.imageContent) {
            return this.$message.warning('请选择一张图片。')
          }
          data.content = this.imageContent.split(',')[1]
          break
        case this.eventType.refund:
          data.content = JSON.stringify(this.refundContent)
          break
      }
      this.saving = true
      addEvent()
        .complete(() => (this.saving = false))
        .success(resp => {
          this.$message.success('添加成功。')
          this.$emit('saved', resp.data)
          this.visible = false
        })
        .send(data)
    },
    handleSelectImageFile () {
      selectFiles({
        multi: false,
        accept: ['image/jpg', 'image/jpeg', 'image/png'],
        select: (files) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            this.imageContent = reader.result
          }, false)
          reader.readAsDataURL(files[0])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.session-event-editor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 500px;
  z-index: 10;
  background-color: #fff;
  box-shadow: -6px 0 10px rgba(0,0,0,.3);

  display: flex;
  flex-direction: column;
}

.header {
  padding: 10px;
  border-bottom: solid 1px #eee;
  font-weight: bold;
}

.body {
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  .image-upload-container {
    border: solid 1px #ddd;
    height: 200px;
    line-height: 200px;
    text-align: center;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.footer {
  padding: 10px;
  text-align: center;
  border-top: solid 1px #eee;
  background-color: #f9f9f9;
}
</style>
