<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="toolbar">
      <a-button @click="$refs.skuOptionManager.open()">SKU规则管理器</a-button>
    </div>

    <div slot="top" class="left-right-content search-form">
      <a-form-model layout="inline">
        <a-form-model-item label="店铺">
          <a-select v-model="selectedShopId" style="width: 130px">
            <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="产品ID">
          <a-input v-model="filterOptions.productId" placeholder="多个ID使用逗号分隔" :allow-clear="true" :disabled="generateContinue" style="width: 500px;" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loadProducts" :disabled="generateContinue" :loading="dataLoading">加载产品</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="filterProducts"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="action" slot-scope="item">
        <a-button size="small" type="primary" @click="generateSkuWithProduct(item, null)" :loading="item.processStatus.processing">生成SKU</a-button>
        <a-button size="small" @click="$refs.skuPreview.open(selectedShopId, [item])">预览SKU</a-button>
      </div>
      <div slot="productTitle" slot-scope="title, item">
        <a target="_blank" :href="item.productUrl">{{title}}</a>
      </div>

      <div slot="processMessage" slot-scope="item">
        <span v-if="item.processStatus.processing">
          <a-spin :indicator="true" /> 正在生成...
        </span>
        <span v-else-if="item.processStatus.processed" :class="{'text-danger': item.processStatus.error > 0, 'text-success': item.processStatus.error === 0}">
          成功：{{item.processStatus.success}}，失败：{{item.processStatus.error}}，忽略：{{item.processStatus.ignore}}
        </span>
      </div>
    </a-table>

    <div slot="bottom">
      <div class="bottom-content">
        <div class="total-info">
          共找到<span class="font-bold text-primary" style="margin: 0 5px;">{{filterProducts.length}}</span>个产品
          <span style="margin-left: 10px;" v-show="generateContinue">，正在生成第 {{skuProcessingProductIndex + 1}} 个产品</span>
        </div>
        <div style="margin-left: 20px;">
          <a-button @click="handlePreviewSelected">预览选中</a-button>
          <a-button :disabled="generateContinue" type="primary" @click="startGenerateSku">批量生成SKU</a-button>
          <a-button :disabled="!generateContinue" @click="stopGenerateSku">停止生成</a-button>
          <a-button @click="removeNonErrorRecord">清除零错误记录</a-button>
          <a-button type="danger" @click="removeErrorRecord">清除有错误记录</a-button>
          <a-button @click="handleResetHasErrorRecord">重置有错误的记录</a-button>
        </div>
      </div>
    </div>

    <sku-preview ref="skuPreview" @openSkuManager="$refs.skuOptionManager.open()"></sku-preview>
    <sku-option-manager ref="skuOptionManager"></sku-option-manager>

  </app-page>
</template>

<script>
import { ROUTE_PRODUCT_SKU_GENERATOR } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listShopOptions } from '@/http/api/shop'
import { generateSku, searchShopifyProducts } from '@/http/api/shopify-product'
import SkuPreview from './comp/sku-preview'
import SkuOptionManager from '@/components-business/sku-option-manager'

export default {
  components: { SkuPreview, SkuOptionManager },
  data () {
    return {
      routeName: ROUTE_PRODUCT_SKU_GENERATOR,
      products: [],
      dataLoading: false,
      dataColumns: [
        { title: '序号', width: 50, customRender: (text, record, index) => `${index + 1}` },
        { title: '操作', width: 180, scopedSlots: { customRender: 'action' } },
        { title: '产品ID', width: 120, dataIndex: 'id' },
        { title: '标题', dataIndex: 'title', scopedSlots: { customRender: 'productTitle' } },
        { title: '标签', dataIndex: 'tags' },
        { title: '消息', scopedSlots: { customRender: 'processMessage' } }
      ],
      shopOptions: [],
      selectedShopId: null,
      filterOptions: {
        productId: null
      },
      generateContinue: false,
      selectedRowKeys: []
    }
  },
  mixins: [AntdTableHeightMixin],
  computed: {
    selectedShop () {
      return this.shopOptions.find(item => item.id === this.selectedShopId)
    },
    filterProducts () {
      const arr = []
      const idMap = {}
      if (this.filterOptions.productId) {
        this.filterOptions.productId.split(',').forEach(id => (idMap[id] = true))
      }
      const filterIdSize = Object.keys(idMap).length
      for (const p of this.products) {
        if (filterIdSize === 0 || idMap[p.id] === true) {
          arr.push(p)
        }
      }
      return arr
    },
    skuProcessingProductIndex () {
      return this.filterProducts.findIndex(item => item.processStatus.processing)
    }
  },
  watch: {
  },
  methods: {
    loadProducts () {
      if (this.selectedShopId) {
        this.dataLoading = true
        searchShopifyProducts()
          .complete(() => (this.dataLoading = false))
          .success(resp => {
            for (const item of resp.data) {
              item.productUrl = this.selectedShop.storeUrl + '/admin/products/' + item.id
              item.processStatus = {
                processing: false, // 正在生成
                processed: false, // 是否已经生成
                success: 0, // 成功数量
                error: 0, // 错误数量
                ignore: 0 // 忽略数量
              }
            }
            this.products = resp.data
          })
          .send({
            shopId: this.selectedShopId,
            idList: this.filterOptions.productId ? this.filterOptions.productId.split(',') : []
          })
      }
    },
    generateSku (product) {
      this.generateSkuWithProduct(product, () => {
        if (this.generateContinue) {
          const idx = this.filterProducts.findIndex(item => item.id === product.id)
          if (idx === this.filterProducts.length - 1) {
            this.generateContinue = false
          } else {
            this.generateSku(this.filterProducts[idx + 1])
          }
        }
      })
    },
    generateSkuWithProduct (product, successCallback) {
      const data = {
        shopId: this.selectedShopId,
        productId: product.id,
        tags: product.tags,
        variants: []
      }
      for (const v of product.variants) {
        const variant = {
          id: v.id,
          sku: v.sku,
          optionNames: [],
          values: []
        }
        for (const opt of v.options) {
          variant.optionNames.push(opt.name)
          variant.values.push(opt.value)
        }
        data.variants.push(variant)
      }
      product.processStatus.processing = true
      generateSku()
        .complete(() => (product.processStatus.processing = false))
        .success(resp => {
          product.processStatus.processed = true
          product.processStatus.success = resp.data.success
          product.processStatus.error = resp.data.error
          product.processStatus.ignore = resp.data.ignore
          if (successCallback) {
            successCallback()
          }
        })
        .send(data)
    },
    startGenerateSku () {
      const p = this.filterProducts.find(item => !item.processStatus.processed)
      if (p) {
        this.generateContinue = true
        this.generateSku(p)
      } else {
        this.$message.warning('已经没有可以生成的产品。')
      }
    },
    stopGenerateSku () {
      this.generateContinue = false
    },
    removeNonErrorRecord () {
      this.products = this.products.filter(item => !(item.processStatus.processed && item.processStatus.error === 0))
    },
    removeErrorRecord () {
      this.products = this.products.filter(item => !(item.processStatus.processed && item.processStatus.error > 0))
    },
    handleResetHasErrorRecord () {
      this.products.forEach(item => {
        if (item.processStatus.error > 0) {
          item.processStatus.processed = false
        }
      })
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handlePreviewSelected () {
      const selected = this.products.filter(item => this.selectedRowKeys.includes(item.id))
      this.$refs.skuPreview.open(this.selectedShop.id, selected)
    }
  },
  mounted () {
    listShopOptions().success(resp => (this.shopOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
.bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-top: solid 1px #eee;
}
</style>
