import { render, staticRenderFns } from "./stock-product.vue?vue&type=template&id=23daf4fc&scoped=true&"
import script from "./stock-product.vue?vue&type=script&lang=js&"
export * from "./stock-product.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23daf4fc",
  null
  
)

export default component.exports