<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button slot="toolbar" @click="$refs.editor.open()">创建产品</a-button>
      <a-button slot="toolbar" @click="handleSubmitProduct" type="primary">提交产品</a-button>
    </div>

    <div class="search-form" slot="top">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="有提交">
          <a-switch v-model="searchParams.submit"></a-switch>
        </a-form-model-item>
        <a-form-model-item label="创建日期">
          <a-range-picker @change="handleCreateDateChanged"
                          value-format="YYYY-MM-DD"
                          style="width: 220px;"
          >
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="buttons" slot-scope="record">
        <a-button :disabled="record.submiting" size="small" icon="edit" @click="$refs.editor.open(record.id, record.product)"></a-button>
        <a-button :disabled="record.submiting" size="small" type="danger" icon="delete" @click="handleDelete(record)"></a-button>
      </div>

      <div slot="status" slot-scope="record">
        <a-icon v-if="record.submiting" type="loading" style="font-size: 18px; color: #4586d4;" />
        <span v-else-if="record.submit" class="text-success font-mini">已提交</span>
        <span v-else class="text-danger font-mini">未提交</span>
      </div>

      <div slot="productTitle" slot-scope="record">
        {{record.title}}
      </div>

      <div slot="cover" slot-scope="record">
        <div class="image-item-wrapper" v-if="record.product.images">
          <div class="image-item small"
               v-for="url in record.product.images.slice(0,3)"
               :key="url" :style="{'background-image': `url(${url})`}"
               @click="$imageModal.open(url)"
          ></div>
        </div>
        <div class="text-primary font-mini"
             style="margin-top: 8px;"
             v-if="record.product.images && record.product.images.length > 3">
          还有 {{record.product.images.length - 3}} 个
        </div>
      </div>
      <div slot="price" slot-scope="record">{{record.product.basePrice}}</div>
      <div slot="variant" slot-scope="record">
        <div v-for="v in record.product.variants"
             :key="v.id"
             style="display: inline-block; margin-right: 10px;"
        >
          {{v.name}}
          <span>({{v.values.length}})</span>
        </div>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <add-editor ref="editor" @saved="handleEditorSaved"></add-editor>
    <submit-panel ref="submit" @remove="onRemoveFromSubmit"></submit-panel>
  </app-page>
</template>

<script>
import { ROUTE_PRODUCT_ADD } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import AddEditor from './comp/add-editor'
import { list, deleteById } from '@/http/api/store-local-product'

import kit from '@/utils/kit'
import SubmitPanel from './comp/submit-panel'

export default {
  mixins: [AntdTableHeightMixin, PaginationMixin],
  components: { AddEditor, SubmitPanel },
  data () {
    return {
      routeName: ROUTE_PRODUCT_ADD,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', width: 90, scopedSlots: { customRender: 'buttons' } },
        { title: '状态', width: 90, align: 'center', scopedSlots: { customRender: 'status' } },
        { title: '标题', width: 300, scopedSlots: { customRender: 'productTitle' } },
        { title: '图片', scopedSlots: { customRender: 'cover' } },
        { title: '价格', width: 100, scopedSlots: { customRender: 'price' } },
        { title: '多属性', width: 120, scopedSlots: { customRender: 'variant' } },
        { title: '创建日期', width: 100, dataIndex: 'createTime' },
        { title: '创建人', width: 100, dataIndex: 'createAdminName' }
      ],
      shops: [],
      selectedRowKeys: [],
      searchParams: {
        submit: false,
        startTime: null,
        endTime: null
      }
    }
  },
  computed: {
    selectedRows () {
      const arr = []
      for (const item of this.dataList) {
        if (this.selectedRowKeys.includes(item.id)) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      const p = this.buildRequestParams(this.searchParams)
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          for (const item of resp.data.records) {
            item.submiting = false
            item.submitMessage = null
            try {
              item.product = JSON.parse(item.content)
            } catch (e) {
              item.product = {}
              console.error(e)
            }
            item.title = item.product.title
            arr.push(item)
          }
          this.dataList = arr
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleEditorSaved (data, updateId) {
      this.loadData()
    },
    handleDelete (data) {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          deleteById()
            .success(() => {
              kit.arr.removeItem(this.dataList, data)
            })
            .send(data.id)
        }
      })
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleSubmitProduct () {
      if (this.selectedRows.length === 0) {
        return this.$message.warning('请选择要提交的产品。')
      }
      this.$refs.submit.addToSubmit(this.selectedRows)
      for (const item of this.selectedRows) {
        kit.arr.removeItem(this.dataList, item)
      }
      this.selectedRowKeys = []
    },
    onRemoveFromSubmit (data) {
      this.dataList.push(data)
    },
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.startTime = dates[0] + ' 00:00:00'
        this.searchParams.endTime = dates[1] + ' 23:59:59'
      } else {
        this.searchParams.startTime = null
        this.searchParams.endTime = null
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
