import defineApi from '../define-api'
import http from '../application-api'

export const listUnReadSystemMessage = defineApi((config) => {
  http.get('/sys_msg/list/un_read', config)
})

export const updateToRead = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/sys_msg/update/read', config)
})
