<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="订单同步"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="店铺" class="app_required-input" v-if="shopList">
        <a-select v-model="form.shopId" style="width: 100%;">
          <a-select-option v-for="s in shopList" :key="s.id">{{s.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="订单ID" class="app_required-input">
        <a-textarea v-model="form.orderIds" placeholder="多个Id用逗号分隔" :auto-size="{ minRows: 3 }" />
      </a-form-model-item>
    </a-form-model>

    <a-divider>同步成功的订单</a-divider>
    <ul class="list-group">
      <li class="list-group-item" v-for="s in syncSuccess" :key="s.id">
        <div class="left-right-content">
          <div class="full">{{s.id}}</div>
          <div style="width: 100px; text-align: right;">
            <span v-if="s.orderNo" class="text-success">{{s.orderNo}}</span>
            <span v-else class="text-danger">没有找到订单</span>
          </div>
        </div>
      </li>
    </ul>

  </drawer>
</template>

<script>

import { syncFromShop } from '@/http/api/order'

export default {
  props: {
    shopList: { type: Array, required: false }
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      form: {
        shopId: null,
        orderIds: null
      },
      syncSuccess: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open () {
      this.visible = true
    },
    onSave () {
      this.saveButtonLoading = true
      this.closable = false
      syncFromShop()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(resp => {
          const arr = []
          for (const key of Object.keys(resp.data)) {
            arr.push({ id: key, orderNo: resp.data[key] })
          }
          this.syncSuccess = arr
        })
        .send(this.form.shopId, this.form.orderIds)
    },
    onClose () {
      this.form.orderIds = null
      this.syncSuccess = []
    }
  }
}
</script>

<style lang="less" scoped>
</style>
