import kit from '@/utils/kit'

export const TYPE_REMARK = 'remark'
export const TYPE_EMAIL = 'email'
export const TYPE_IMAGE = 'image'
export const TYPE_ADDRESS = 'address'
export const TYPE_DEMO_ORDER = 'demoOrder'
export const TYPE_NOTIFY = 'notify'

export const COMMUNICATION_TYPE_MAPPING = {
  [TYPE_REMARK]: '备注',
  [TYPE_EMAIL]: '邮件',
  [TYPE_IMAGE]: '图片',
  [TYPE_ADDRESS]: '地址',
  [TYPE_DEMO_ORDER]: '寄样',
  [TYPE_NOTIFY]: '提醒'
}

export const COMMUNICATION_TYPE_LIST = kit.obj.toArray(COMMUNICATION_TYPE_MAPPING)
