import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Menu extends Section {
  constructor () {
    super('menu', '菜单')

    // { title, link }
    this.menus = []
    // hor, ver
    this.direction = 'hor'
    this.fontSize = 14
    this.fontBold = true
    this.padding = '10px 20px'
  }

  getHeaderDisplay () {
    const arr = []
    for (const item of this.menus) {
      if (item.title) {
        arr.push(item.title)
      }
    }
    return arr.join(', ')
  }
}
