import { render, staticRenderFns } from "./user-select.vue?vue&type=template&id=718897f7&scoped=true&"
import script from "./user-select.vue?vue&type=script&lang=js&"
export * from "./user-select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718897f7",
  null
  
)

export default component.exports