/**
 * 在编辑用户的时候，获取编辑用户的角色列表，根据{@link #applied}来判断该角色是否已经分配给当前编辑的用户了。
 * @author jiangxingshang
 * @date 2022/12/13
 */
import Role from '../model/role'
import kit from '@/utils/kit'
export default class CurrentUserRole extends Role {
  constructor (initData) {
    super(initData)
    this.applied = false
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
