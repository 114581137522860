<template>
  <app-page :route-name="routeName">
    <div slot="toolbar">
    </div>

    <div slot="top">
      <div class="block-wrapper">
        <a-textarea
            v-model="searchParams.sku"
            placeholder="多个SKU可以用换行，逗号或空格来分隔"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            style="width: 600px;"
        />
        <div style="margin-left: 10px;">
          <a-button @click="loadData()" :disabled="uploadContinue">查询</a-button>
          <a-button @click="handleSelectSku" :disabled="uploadContinue">SKU选择器</a-button>
          <a-button @click="handleClearSku" type="danger" :disabled="uploadContinue">清空SKU</a-button>
          <a-dropdown :disabled="dataList.length === 0">
            <a-menu slot="overlay" @click="handleStartUploadSku">
              <a-menu-item v-for="c in abroadShippingOptions" :key="c.id"> {{c.name}} </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px" :disabled="dataList.length === 0" :loading="uploadContinue"> 开始上传 <a-icon type="down" /> </a-button>
          </a-dropdown>
        </div>
      </div>
    </div>


    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="record">
        <a-button size="small" type="danger" icon="delete" @click="handleRemoveItem(record)"></a-button>
      </div>
      <div slot="sku" slot-scope="sku, record">
        <a-tooltip v-if="record.coupleSku">
          <template slot="title">
            <div>男：{{record.coupleSkuForMen}}</div>
            <div>女：{{record.coupleSkuForWomen}}</div>
          </template>
          {{sku}}
        </a-tooltip>
        <span v-else>{{sku}}</span>
      </div>
      <div slot="imageUrl" slot-scope="imageUrl">
        <div class="image-item" v-lazy:background-image="imageUrl" @click="$refs.imageView.open(imageUrl)"></div>
      </div>
      <div slot="productTitle" slot-scope="title">
        <div style="white-space: pre-wrap;">{{title}}</div>
      </div>
      <div slot="upload" slot-scope="item">
        <span v-if="item._uploadStatus === 0" class="text-muted">待处理</span>
        <a-spin :indicator="true" v-if="item._uploadStatus === 1" />
        <span v-else :class="{'text-success': item._uploadSuccess, 'text-danger': !item._uploadSuccess}">
          {{item._uploadResult}}
        </span>
      </div>
    </a-table>

    <stock-product-select ref="stockProduct"></stock-product-select>

  </app-page>
</template>

<script>
import { ROUTE_ABROAD_UPLOAD_SKU } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listStockProducts } from '@/http/api/stock-product'
import StockProductSelect from '@/components-business/stock-product-select'
import { uploadSku } from '@/http/api/abroad-shipping'
import { LOGISTICS_ABROAD_LIST } from '@/constants/logistics'
import kit from '@/utils/kit'

export default {
  components: { StockProductSelect },
  data () {
    return {
      routeName: ROUTE_ABROAD_UPLOAD_SKU,
      dataColumns: [
        {
          title: '操作',
          width: 60,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          width: 200,
          scopedSlots: { customRender: 'sku' }
        },
        {
          title: '规格',
          dataIndex: 'variant',
          width: 130
        },
        {
          title: '产品标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'productTitle' }
        },
        {
          title: '来源',
          dataIndex: 'source',
          width: 90
        },
        {
          title: '上传状态',
          scopedSlots: { customRender: 'upload' },
          width: 150
        }
      ],
      dataList: [],
      dataLoading: false,
      searchParams: {
        sku: null
      },
      uploadContinue: false,
      abroadShippingOptions: LOGISTICS_ABROAD_LIST
    }
  },
  mixins: [AntdTableHeightMixin],
  methods: {
    initItemProps (item) {
      // 0 待处理，1 正在上传，2 已处理
      item._uploadStatus = 0
      item._uploadSuccess = false
      // 如果_uploadSuccess == false，表示错误消息
      item._uploadResult = null
      return item
    },
    loadData () {
      this.dataLoading = true
      const p = Object.assign({
        start: 0,
        limit: 300
      }, this.searchParams)
      this.dataLoading = true
      listStockProducts()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          for (const item of resp.data.records) {
            this.initItemProps(item)
          }
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleSelectSku () {
      this.$refs.stockProduct.open(items => {
        for (const item of items) {
          if (!this.dataList.find(a => a.stockProductId === item.id)) {
            this.dataList.push(this.initItemProps(Object.assign({}, item)))
          }
        }
      })
    },
    handleClearSku () {
      this.dataList = []
    },
    handleStartUploadSku (e) {
      const name = this.abroadShippingOptions.find(item => item.id === e.key).name
      this.$confirm({
        title: '确认',
        content: `确定要开始上传SKU到【${name}】吗？`,
        onOk: () => {
          for (const item of this.dataList) {
            item._uploadStatus = 0
          }
          this.uploadContinue = true
          this.uploadSku(this.dataList[0], e.key)
          this.$info({ title: '提示', content: '已经开始上传，请不要离开当前页面直至上传全部完成。' })
        }
      })
    },
    uploadSku (item, companyId) {
      if (item == null) {
        this.uploadContinue = false
        return this.$success({
          title: '完成',
          content: '上传已完成，请检查结果。'
        })
      }
      const getNextItem = currentItem => {
        const nextIndex = this.dataList.findIndex(i => i === currentItem) + 1
        return nextIndex >= this.dataList.length ? null : this.dataList[nextIndex]
      }
      const data = item
      if (data._uploadStatus === 2) {
        return this.uploadSku(getNextItem(item), companyId)
      }
      data._uploadStatus = 1
      data._uploadResult = null
      uploadSku()
        .complete(() => {
          data._uploadStatus = 2
          if (this.uploadContinue) {
            this.uploadSku(getNextItem(item), companyId)
          }
        })
        .success(() => {
          data._uploadSuccess = true
          data._uploadResult = '成功'
        })
        .error(resp => {
          data._uploadSuccess = false
          data._uploadResult = resp.message
        })
        .send(companyId, data.id)
    },
    handleRemoveItem (r) {
      kit.arr.removeItem(this.dataList, r)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.block-wrapper {
  display: flex;
  align-items: center;
}
</style>
