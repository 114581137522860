import defineApi from '../define-api'
import http, { buildUrlWithToken } from '../application-api'

export const listByOrder = defineApi((config, orderId) => {
  config.params = {
    orderId: orderId
  }
  http.get('/order/delivery/list/by_order', config)
})

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/order/delivery/list', config)
})

export const exportList = function (params) {
  const url = http.baseUrl + '/order/delivery/export?'
  const paramArr = []
  for (const key of Object.keys(params)) {
    if (params[key] != null && params[key] !== undefined) {
      paramArr.push(key + '=' + params[key])
    }
  }
  return url + paramArr.join('&')
}

export const listByTrackingFile = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/order/delivery/update_tracking/list', config)
})

export const updateTrackingInfo = defineApi((config, data) => {
  config.data = data
  http.post('/order/delivery/update_tracking', config)
})

export const getUpdateTrackingSample = function () {
  return buildUrlWithToken('/order/delivery/update_tracking/sample')
}


export const getShippingCompanies = defineApi((config) => {
  http.get('/order/delivery/shipping_companies', config)
})

export const getShippingMethods = defineApi((config, companyId, reload, params) => {
  config.params = {
    companyId: companyId,
    reload: reload,
    params: params
  }
  http.get('/order/delivery/shipping_methods', config)
})

export const getShopShippingProvider = defineApi((config, shopId) => {
  config.params = {
    shopId: shopId
  }
  http.get('/order/delivery/shop/shipping_provider', config)
})

export const getCountries = defineApi((config) => {
  http.get('/order/delivery/countries', config)
})

export const delivery = defineApi((config, data) => {
  config.data = data
  http.post('/order/delivery/ship', config)
})

export const uploadImageByDelivery = defineApi((config, imageBase64) => {
  config.params = {
    base64: imageBase64
  }
  http.post('/order/delivery/ship/upload/image', config)
})

export const cancelDelivery = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/order/delivery/ship/cancel', config)
})

export const printLabel = defineApi((config, idList) => {
  config.timeout = 1000 * 60 * 5
  config.params = {
    ids: idList
  }
  http.get('/order/delivery/print/label', config)
})

export const getProducts = defineApi((config, orderId) => {
  config.params = { orderId: orderId }
  http.get('/order/delivery/products', config)
})

/**
 * 和list函数一样的参数。
 */
export const getFeeSummary = defineApi((config, params) => {
  config.params = params
  http.get('/order/delivery/fee/summary', config)
})

export const getCountWithExceptionAndUnArrive = defineApi((config, days) => {
  config.params = {
    days: days
  }
  http.get('/order/delivery/count/exception_and_un_arrive', config)
})

export const updateDeliveryStatus = defineApi((config, idList, status) => {
  config.params = {
    idList: idList.join(','),
    status: status
  }
  http.post('/order/delivery/update/delivery_status', config)
})


export const getFeeSummaryGroupByCompany = defineApi((config, startDate, endDate) => {
  config.params = {
    startDate: startDate,
    endDate: endDate
  }
  http.get('/order/delivery/fee/summary/group_company', config)
})

export const getDeliveryCountByOrder = defineApi((config, orderId) => {
  config.params = {
    orderId: orderId
  }
  http.get('/order/delivery/count/by_order', config)
})

export const getDeliveryCountByOrders = defineApi((config, orderIdList) => {
  config.params = {
    orderIdList: orderIdList
  }
  http.get('/order/delivery/count/by_orders', config)
})

/**
 * 获取发货产品的成本价格
 * @param data { orderId: String, items: [{sku: String, supplierId: String}]}
 *   supplierId没有可不提供
 */
export const getCosts = defineApi((config, data) => {
  config.data = data
  http.post('/order/delivery/get_cost', config)
})

/**
 * 更新发货产品信息
 * @deprecated
 * @param items {Array} {id: String, cost: Number, fromSupplierId: String}
 */
export const updateDeliveryItems = defineApi((config, items) => {
  config.data = items
  http.post('/order/delivery/update/delivery_items', config)
})

/**
 * 更新发货产品的成本
 */
export const updateDeliveryItemsCost = defineApi((config, items) => {
  config.data = items
  http.post('/order/delivery/update/delivery_items/cost', config)
})

/**
 * 更新发货产品的供应商
 */
export const updateDeliveryItemsSupplier = defineApi((config, items) => {
  config.data = items
  http.post('/order/delivery/update/delivery_items/supplier', config)
})

export const updateTrackingNumber = defineApi((config) => {
  config.timeout = 1000 * 60 * 60
  http.post('/order/delivery/update/tracking_number', config)
})

export const listPrepare = defineApi(config => {
  http.get('/order/delivery/list/prepare_ready', config)
})

export const updateDeliveryItemPrepareStatus = defineApi((config, itemId, prepare) => {
  config.params = {
    id: itemId,
    prepare: prepare
  }
  http.post('/order/delivery/delivery_item/prepare/update', config)
})

export const updateOrderDeliveryPrepareStatus = defineApi((config, idArray, status) => {
  config.params = {
    idList: idArray.join(','),
    prepareStatus: status
  }
  http.post('/order/delivery/prepare_status/update', config)
})

export const getOrderDeliveryItemsByOrder = defineApi((config, orderNo) => {
  config.params = { orderNo: orderNo }
  http.get('/order/delivery/delivery_items/by_order_no', config)
})

/**
 * 上传tiktok物流模版，填充在系统里的物流运单号
 */
export const getTiktokShippingData = function (idList) {
  return http.baseUrl + '/order/delivery/tiktok/delivery_fill?idList=' + idList.join(',')
}

export const updateDelayPackage = defineApi((config, ids, status) => {
  config.params = {
    idList: ids,
    status: status
  }
  http.post('/order/delivery/update/delay_package', config)
})

export const listEmptyCostDeliveryItems = defineApi((config, p) => {
  config.params = p
  http.get('/order/delivery/list/delivery_items/empty_cost', config)
})

export const getTrackUrlByTrackNo = defineApi((config, trackNo) => {
  config.params = { trackNo: trackNo }
  http.get('/order/delivery/get_track_url', config)
})
