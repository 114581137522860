<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>菜单</label>
        <span>
          <a-button @click="openEditor()">添加菜单</a-button>
          <draggable
              tag="div"
              style="margin: 10px 0;"
              v-model="section.menus"
              v-bind="drag.dragOptions"
              @start="drag.drag = true"
              @end="drag.drag = false"
          >
            <transition-group tag="ul" type="transition" :name="!drag.drag ? 'flip-list' : null" class="sortable-list list-group">
              <li v-for="item in section.menus" :key="item.id" class="list-group-item">
                <div class="item-wrapper">
                  <div class="order-icon">
                    <a-icon type="unordered-list" />
                  </div>
                  <div class="menu-title">{{item.title}}</div>
                  <div>
                    <a-button size="small" icon="edit" @click="openEditor(item)"></a-button>
                    <a-button size="small" type="danger" icon="delete" @click="handleRemoveMenu(item)"></a-button>
                  </div>
                </div>
              </li>
            </transition-group>
          </draggable>
        </span>
      </div>
      <div class="detail-item">
        <label>字体大小</label>
        <span>
          <a-input-number v-model="section.fontSize" :min="12" />
        </span>
      </div>
      <div class="detail-item">
        <label>文本粗体</label>
        <span>
          <a-switch v-model="section.fontBold" />
        </span>
      </div>
      <div class="detail-item">
        <label>内间距</label>
        <span>
          <a-input v-model="section.padding" />
          <p class="text-muted font-mini">10px 20px表示上下10个像素，左右20个像素</p>
        </span>
      </div>
      <div class="detail-item">
        <label>菜单方向</label>
        <span>
          <a-select v-model="section.direction" style="width: 200px;">
            <a-select-option value="hor">水平</a-select-option>
            <a-select-option value="ver">垂直</a-select-option>
          </a-select>
        </span>
      </div>
    </div>

    <modal title="编辑" ref="editor" @ok="onEditorOk" @closed="onEditorClosed">
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="菜单标题" class="app_required-input">
          <a-input v-model="editor.form.title" style="width: 100%;" />
        </a-form-model-item>
        <a-form-model-item label="菜单链接" class="app_required-input">
          <a-input v-model="editor.form.link" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>
import MenuModel from '../model/menu'
import Draggable from 'vuedraggable'
import kit from '@/utils/kit'

export default {
  components: { Draggable },
  props: {
    section: { type: MenuModel }
  },
  data () {
    return {
      drag: {
        dragOptions: {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          handle: '.order-icon'
        },
        drag: false
      },
      editor: {
        editingItem: null,
        form: {
          title: null,
          link: null
        }
      }
    }
  },
  watch: {
  },
  methods: {
    openEditor (item) {
      if (item) {
        this.editor.editingItem = item
        this.editor.form.title = item.title
        this.editor.form.link = item.link
      }
      this.$refs.editor.open()
    },
    onEditorOk () {
      let item
      let insert = true
      if (this.editor.editingItem) {
        item = this.editor.editingItem
        insert = false
      } else {
        item = { id: kit.str.id(), title: null, link: null }
      }
      Object.assign(item, this.editor.form)
      if (insert) {
        this.section.menus.push(item)
      }
      this.$refs.editor.close()
    },
    onEditorClosed () {
      this.editor.editingItem = null
      this.editor.form.title = null
      this.editor.form.link = null
    },
    handleRemoveMenu (item) {
      kit.arr.removeItem(this.section.menus, item)
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrapper {
  display: flex;
  .order-icon {
    cursor: move;
  }
  .menu-title {
    flex: 1;
    padding: 0 10px;
  }
}
</style>
