<template>
<span class="compare" v-if="profit.compare[propertyName]">
  <a-tooltip placement="top">
    <template slot="title">
      <span>Compare: {{profit.compare[propertyName]}} <span v-if="isPercent">%</span></span>
      <span style="margin-left: 20px;">{{diffText}}: {{comparePercent}} <span v-if="!isPercent">%</span></span>
    </template>
    <a-icon class="icon-rise" type="vertical-align-top" v-if="diff > 0" />
    <a-icon class="icon-fall" type="vertical-align-bottom" v-if="diff < 0" />
  </a-tooltip>
</span>
</template>

<script>
import kit from '@/utils/kit'
export default {
  props: {
    profit: { type: Object, required: true },
    propertyName: { type: String, required: true },
    isPercent: { type: Boolean, default: false }
  },
  computed: {
    diff () {
      return this.profit[this.propertyName] - this.profit.compare[this.propertyName]
    },
    diffText () {
      return this.diff > 0 ? '增长' : '下降'
    },
    comparePercent () {
      if (this.isPercent) {
        return Math.abs(kit.num.toFixedTrimZero(this.diff))
      } else {
        return Math.abs(kit.num.toFixedTrimZero(this.diff / this.profit[this.propertyName] * 100))
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.compare {
  margin-left: 5px;
  .icon-rise {
    color: #396c08;
  }
  .icon-fall {
    color: #c33632;
  }
}
</style>
