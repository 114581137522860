/**
 * @author jiangxingshang
 * @date 2022/12/7
 */

import defineApi from '../define-api'
import http from '../application-api'


/**
 * 获取所有系统可用的保护资源。
 * @return
 * [
 *   {
 *     name: String,
 *     groups: [
 *       {
 *         id: String,
 *         name: String,
 *         resources: [
 *           {
 *             name: String,
 *             path: String
 *           }
 *         ]
 *       }
 *     ]
 *   }
 * ]
 */
export const listAvailableResources = defineApi((config) => {
  http.get('/protect_resource/available_resources', config)
})

/**
 * 获取权限的已有分组名称列表。
 * @return {Array} string of array
 */
export const listGroupNames = defineApi((config) => {
  http.get('/protect_resource/all_group_names', config)
})

/**
 * @param data {ProtectResourceUpdate}
 */
export const addProtectResource = defineApi((config, data) => {
  config.data = data
  http.post('/protect_resource/add', config)
})

/**
 * @param data {ProtectResourceUpdate}
 */
export const updateProtectResource = defineApi((config, data) => {
  config.data = data
  http.post('/protect_resource/update', config)
})

/**
 * @param id {String}
 */
export const deleteProtectResourceById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/protect_resource/delete', config)
})

/**
 * @param id
 * @return array of string
 */
export const getItemValuesById = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/protect_resource/item_values/by_id', config)
})

