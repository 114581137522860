<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">
    <div slot="top">
      <a-alert type="info" show-icon description="SKU库存转移是指将一个SKU的所有库存转移到另一个SKU上，旧SKU的库存会被清零。请上传一个CSV文件，不要表头，第一列是旧SKU，第二列是新SKU，其他列会被忽略。"/>
      <a-button @click="handleSelectCsvFile" style="margin-top: 10px;">请选择CSV文件</a-button>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
    >
      <div slot="stock" slot-scope="record">
        <span class="text-danger">{{record.oldQuantity}}</span>
        <span style="padding: 0 10px;"><a-icon type="swap-right" /></span>
        <span class="text-primary">{{record.newQuantity}}</span>
        <span style="padding: 0 10px;"> = </span>
        <span class="text-success">{{record.newQuantity + record.oldQuantity}}</span>
      </div>
    </a-table>

    <div slot="bottom">
      <div class="buttons">
        <a-checkbox v-model="deleteOldSkuWhenTransfer">转移成功后删除旧SKU</a-checkbox>
        <a-button type="primary" :disabled="dataList.length === 0" @click="handleStartTransfer">开始为 {{dataList.length}} 个SKU转移库存</a-button>
      </div>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_SKU_STOCK_TRANSFER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import selectFile from '@/utils/select-file'
import { uploadSkuCsvToGetStock, transferSkuStock } from '@/http/api/stock-product'

export default {
  data () {
    return {
      routeName: ROUTE_SKU_STOCK_TRANSFER,
      pageLoading: false,
      dataColumns: [
        { title: '旧SKU', dataIndex: 'oldSku' },
        { title: '新SKU', dataIndex: 'newSku' },
        { title: '库存转移(旧SKU库存 > 新SKU库存 = 新库存)', scopedSlots: { customRender: 'stock' } }
      ],
      dataList: [],
      deleteOldSkuWhenTransfer: true
    }
  },
  mixins: [AntdTableHeightMixin],
  methods: {
    handleSelectCsvFile () {
      selectFile({
        accept: ['text/csv'],
        multi: false,
        select: (files) => {
          this.pageLoading = true
          uploadSkuCsvToGetStock()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              const list = []
              for (const arr of resp.data) {
                const obj = {}
                if (arr[0]) {
                  obj.oldSku = arr[0].sku
                  obj.oldQuantity = arr[0].quantity
                  obj.oldWarehouseTotal = arr[0].warehouseTotal
                } else {
                  obj.oldSku = '无记录'
                  obj.oldQuantity = 0
                  obj.oldWarehouseTotal = 0
                }

                if (arr[1]) {
                  obj.newSku = arr[1].sku
                  obj.newQuantity = arr[1].quantity
                  obj.newWarehouseTotal = arr[1].warehouseTotal
                } else {
                  obj.newSku = '无记录'
                  obj.newQuantity = 0
                  obj.newWarehouseTotal = 0
                }
                list.push(obj)
              }
              this.dataList = list
            })
            .send(files[0])
        }
      })
    },
    handleStartTransfer () {
      this.$confirm({
        title: '确认',
        content: '确定要开始转移库存吗？',
        onOk: () => {
          const arr = []
          for (const item of this.dataList) {
            if (item.oldSku === '无记录' || item.newSku === '无记录') {
              continue
            }
            arr.push([item.oldSku, item.newSku])
          }
          const p = {
            deleteOldSku: this.deleteOldSkuWhenTransfer,
            skuList: arr
          }
          this.pageLoading = true
          transferSkuStock()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('操作成功。')
              this.dataList = []
            })
            .send(p)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .buttons {
    padding: 10px;
    text-align: center;
  }
</style>
