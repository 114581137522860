import kit from '@/utils/kit'

export default class SupplierProduceItem {
  constructor (data) {
    this.id = null
    this.title = null
    this.sku = null
    this.orderProductId = null
    this.imgs = null
    this.quantity = 1
    this.variant = null
    this.remark = null
    this.skuRemark = null
    this.ordersId = null
    this.supplierId = null
    this.number = null
    this.customizeInfo = null
    this.adminId = null
    this.adminName = null
    this.arrivedTime = null
    this.confirmTime = null
    this.createTime = null
    this.dayAcceptToDelivery = null
    this.dayNewToAccept = null
    this.delay = null
    this.delayReason = null
    this.deliveryId = null
    this.deliveryImagePath = null
    this.deliveryImageUrl = null
    this.deliveryTime = null
    this.readyDelivery = null
    this.shipStatus = null
    this.status = null
    this.stockQuantity = null
    this.supplierName = null
    this.warehouseId = null
    this.warehouseName = null
    this.warehouseStockRecordId = null
    this.cost = null
    if (data) {
      kit.obj.getProperties(this, data)
    }
  }

  setOrderProduct (orderProduct) {
    if (orderProduct) {
      this.title = orderProduct.title
      this.sku = orderProduct.sku
      this.orderProductId = orderProduct.id
      this.imgs = orderProduct.imageUrl
      this.quantity = orderProduct.total
      this.variant = orderProduct.variant
      this.customizeInfo = orderProduct.customizeInfo
      this.ordersId = orderProduct.orderId
    }
    return this.applyTempId()
  }

  applyTempId () {
    this.id = kit.str.id()
    return this
  }

  getFirstImage () {
    const images = this.getImages()
    if (images.length > 0) {
      return images[0]
    } else {
      return null
    }
  }

  getImages () {
    return this.imgs ? this.imgs.split(',') : []
  }

  setImages (images) {
    if (typeof images === 'string') {
      this.imgs = images
    } else if (images && Array.isArray(images) && images.length > 0) {
      this.imgs = images.join(',')
    } else {
      this.imgs = null
    }
  }
}
