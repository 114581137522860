<!-- 库存盘点 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="toolbar">
      <a-button type="primary" @click="$refs.editor.open()">创建库存盘点</a-button>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.editor.open(record)">编辑</a-button>
        <a-button size="small" type="primary" @click="navStockCheckItem(record)">盘点明细</a-button>
      </div>
      <div slot="status" slot-scope="status">
        <span class="text-danger" v-if="status === 0">正在盘点</span>
        <span class="text-primary" v-else-if="status === 1">盘点结束</span>
        <span class="text-success" v-else>库存已更新</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <stock-check-editor ref="editor" @saved="handleSaved"></stock-check-editor>

  </app-page>
</template>

<script>
import { ROUTE_STOCK_CHECK, ROUTE_STOCK_CHECK_ITEM } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listStockCheck } from '@/http/api/stock-check'
import StockCheckEditor from './comp/stock-check-editor'
import kit from '@/utils/kit'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  components: { StockCheckEditor },
  data () {
    return {
      routeName: ROUTE_STOCK_CHECK,
      dataColumns: [
        {
          title: '操作',
          width: 180,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '盘点仓库',
          dataIndex: 'warehouseName'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建日期',
          dataIndex: 'createTime'
        }
      ],
      dataList: [],
      dataLoading: false
    }
  },
  watch: {
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listStockCheck()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send()
    },
    handleSaved (data) {
      kit.arr.insertOrReplace(this.dataList, 0, data)
    },
    navStockCheckItem (data) {
      this.$router.push({ name: ROUTE_STOCK_CHECK_ITEM, params: { stockCheckId: data.id } })
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style scoped>

</style>
