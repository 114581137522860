import defineApi from '../define-api'
import http from '../application-api'

export const listActivity = defineApi((config, params) => {
  config.params = params
  http.get('/edm/activity/list', config)
})

export const addActivity = defineApi((config, data) => {
  config.data = data
  http.post('/edm/activity/add', config)
})

export const copyActivity = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/edm/activity/copy', config)
})

export const updateActivity = defineApi((config, data) => {
  config.data = data
  http.post('/edm/activity/update', config)
})

export const startActivity = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/edm/activity/start', config)
})

export const listEmails = defineApi((config, params) => {
  config.params = params
  http.get('/edm/activity/list/emails', config)
})

export const getEmailBody = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/edm/activity/email_body', config)
})
