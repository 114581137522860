<!--
  库存分析
-->
<template>
  <div class="container">
    <div class="top-wrapper">
      <div class="text-primary">
        下表为已经设置了库存预警，且到达了库存最低限制的商品。
      </div>
      <div>
        <a-form-model layout="inline" @submit.native.prevent>
          <a-form-model-item>
            <a-input placeholder="SKU或标题关键字" v-model="skuFilterKeyword" :allow-clear="true" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="loadStockProductAlerts">刷新</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="handleCheckAlert">更新库存预警</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="table-wrapper">
      <a-table
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="filterDataList"
          :pagination="false"
          :loading="dataLoading"
      >
        <div slot="imageUrl" slot-scope="imageUrl">
          <div class="image-item" v-lazy:background-image="imageUrl" @click="$imageModal.open(imageUrl)"></div>
        </div>

        <div slot="warehouseStocks" slot-scope="warehouseStocks">
          <div v-if="warehouseStocks && warehouseStocks.length > 0">
            <div v-for="item in warehouseStocks" :key="item.warehouseId" :class="{ 'text-danger': item.total === 0}">
              <label class="font-mini">{{warehouseMap[item.warehouseId]}} / </label>
              <span class="font-large">{{item.total}}</span>
            </div>
          </div>
        </div>

        <div slot="productTitle" slot-scope="title, record">
          <a :href="record.productUrl" v-if="record.productUrl" target="_blank">
            <div style="white-space: pre-wrap;">{{title}}</div>
          </a>
          <div v-else style="white-space: pre-wrap;">{{title}}</div>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getAlerts } from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { checkAlert } from '@/http/api/stock-alert'

export default {
  data () {
    return {
      dataColumns: [
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          width: 200
        },
        {
          title: '总销量',
          dataIndex: 'saleTotal',
          width: 80
        },
        {
          title: '库存',
          dataIndex: 'warehouseStocks',
          width: 200,
          scopedSlots: { customRender: 'warehouseStocks' }
        },
        {
          title: '产品标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'productTitle' }
        },
        {
          title: '来源',
          dataIndex: 'source',
          width: 90
        }
      ],
      dataList: [],
      dataLoading: false,
      warehouseMap: {},
      skuFilterKeyword: null
    }
  },
  computed: {
    filterDataList () {
      if (this.skuFilterKeyword) {
        const arr = []
        for (const item of this.dataList) {
          if (item.sku.includes(this.skuFilterKeyword) || item.title.includes(this.skuFilterKeyword)) {
            arr.push(item)
          }
        }
        return arr
      } else {
        return this.dataList
      }
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
    },
    onInactive () {
    },
    onInit () {
      listWarehouseEnabled()
        .success(resp => {
          for (const item of resp.data) {
            this.warehouseMap[item.id] = item.name
          }
          this.loadStockProductAlerts()
        })
        .send()
    },
    loadStockProductAlerts () {
      this.dataLoading = true
      getAlerts()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send()
    },
    handleCheckAlert () {
      this.dataLoading = true
      checkAlert()
        .final(success => {
          if (!success) {
            this.dataLoading = false
          } else {
            this.loadStockProductAlerts()
          }
        })
        .send()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  @padding: 10px;
  left: @padding;
  top: @padding;
  right: @padding;
  bottom: @padding;
  display: flex;
  flex-direction: column;
  .top-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    :first-child {
      flex: 1;
    }
  }
  .table-wrapper {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
