<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>标题</label>
        <span>
          <a-input v-model="section.title" />
        </span>
      </div>
      <div class="detail-item">
        <label>文字间距</label>
        <span>
          <a-input-number v-model="section.fontSize" :min="12" :max="100" />
        </span>
      </div>
      <div class="detail-item">
        <label>文字间距</label>
        <span>
          <a-input v-model="section.padding" />
        </span>
      </div>
      <div class="detail-item">
        <label>文字颜色</label>
        <span>
          <color-picker :color.sync="section.color"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>按钮颜色</label>
        <span>
          <color-picker :color.sync="section.backgroundColor"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>边框颜色</label>
        <span>
          <color-picker :color.sync="section.borderColor"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>圆角半径</label>
        <span>
          <a-input-number v-model="section.radius" :min="0" :max="100" />
        </span>
      </div>
      <div class="detail-item">
        <label>按钮链接</label>
        <span>
          <a-input v-model="section.url" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonModel from '../model/button'

export default {
  props: {
    section: { type: ButtonModel }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
