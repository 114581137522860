<template>
  <div>
    <div class="image-container">
      <div class="image-wrapper">
        <image-picker :image-url.sync="section.imageUrl" title="PC端图片"></image-picker>
      </div>
      <div style="width: 30px;"></div>
      <div class="image-wrapper">
        <image-picker :image-url.sync="section.mobileImageUrl" title="移动端图片"></image-picker>
      </div>
    </div>

    <div class="info-detail" style="margin-top: 20px;">
      <div class="detail-item">
        <label>链接</label>
        <span>
          <a-input v-model="section.url" />
        </span>
      </div>
      <div class="detail-item">
        <label>对齐</label>
        <span>
          <a-select v-model="section.align" style="width: 200px;">
            <a-select-option value="left">左对齐</a-select-option>
            <a-select-option value="center">居中</a-select-option>
            <a-select-option value="right">右对齐</a-select-option>
          </a-select>
        </span>
      </div>
      <div class="detail-item">
        <label>宽度</label>
        <span>
          <a-input v-model="section.width" />
          <p class="text-muted font-mini">百分比：50%，固定宽度：50px</p>
        </span>
      </div>
      <div class="detail-item">
        <label>高度</label>
        <span>
          <a-input v-model="section.height" />
          <p class="text-muted font-mini">百分比：50%，固定宽度：50px，自适应宽度：auto</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePicker from '../comp/image-picker'
import ImageModel from '../model/image'

export default {
  components: { ImagePicker },
  props: {
    section: { type: ImageModel }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.image-container {
  display: flex;
  .image-wrapper {
    flex: .5;
  }
}
</style>
