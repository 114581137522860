<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button size="small" slot="toolbar" @click="syncData">同步数据</a-button>
    <a-button size="small" slot="toolbar" type="primary" @click="$refs.sendEmails.open()" :disabled="selectedRowKeys.length === 0">发送邮件</a-button>

    <div slot="top" class="search-form" style="align-items: center;">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="结账状态">
          <a-select v-model="searchParams.completedOrder" style="width: 120px;">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="1">已结账</a-select-option>
            <a-select-option :value="2">未结账</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="店铺">
          <a-select v-model="searchParams.shopId" style="width: 120px;">
            <a-select-option :value="s.id" v-for="s in shops" :key="s.id">{{s.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="创建日期">
          <a-range-picker @change="handleCreateDateChanged"
                          value-format="YYYY-MM-DD"
                          :default-value="datePickerDefaultValues"
                          :ranges="datePickerRanges"
                          style="width: 220px;"
          >
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>


    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="email" slot-scope="email, record">
        <a target="_blank" :href="record.checkoutUrl">{{email}}</a>
      </div>

      <div slot="price" slot-scope="record">
        {{record.currency}}
        <span class="text-danger font-large">{{record.price}}</span>
      </div>

      <div slot="products" slot-scope="productList">
        <div class="image-item-wrapper">
          <a-tooltip v-for="item in productList" :key="item.id">
            <template slot="title">
              <div>金额：{{item.price}}</div>
              <div>标题：{{item.productTitle}} / {{item.variantTitle}}</div>
            </template>
            <div class="image-item small" v-lazy:background-image="item.imageUrl" @click="$imageModal.open(item.imageUrl)">
              <span class="total" :class="{'total-more': item.total > 1}">{{item.total}}</span>
            </div>
          </a-tooltip>
        </div>
      </div>

      <div slot="order" slot-scope="record">
        <span v-if="record.orderId" class="text-success">是</span>
        <span v-else class="text-danger">否</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>


    <modal ref="sendEmails" title="发送邮件" @ok="onSendEmailsConfirm" :loading="sendEmails.loading">
      <a-form-model ref="form" :model="sendEmails.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="标题" class="app_required-input">
          <a-input v-model="sendEmails.form.title" />
        </a-form-model-item>
        <a-form-model-item label="内容" class="app_required-input">
          <a-input type="textarea" v-model="sendEmails.form.message"/>
        </a-form-model-item>
        <a-form-model-item label="折扣码">
          <a-input v-model="sendEmails.form.discountCode"/>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </app-page>
</template>

<script>
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { ROUTE_ABANDONED_CHECKOUT } from '@/router/router-constants'
import { list, sync, sendEmails } from '@/http/api/abandoned-checkout'
import { listShopOptions } from '@/http/api/shop'
import moment from 'moment'

function buildSearchParams () {
  return {
    shopId: null,
    completedOrder: 2,
    createTimeStart: moment().format('YYYY-MM-DD') + ' 00:00:00',
    createTimeEnd: moment().format('YYYY-MM-DD') + ' 23:59:59'
  }
}

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ABANDONED_CHECKOUT,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '店铺', dataIndex: 'shopName', width: 100 },
        { title: '邮箱', dataIndex: 'email', width: 150, scopedSlots: { customRender: 'email' } },
        { title: '客户', dataIndex: 'customerName' },
        { title: '金额', width: 120, scopedSlots: { customRender: 'price' } },
        { title: '产品', dataIndex: 'productList', scopedSlots: { customRender: 'products' } },
        { title: '邮件发送/次', dataIndex: 'emailSendTimes' },
        { title: '已结账', width: 80, scopedSlots: { customRender: 'order' } },
        { title: '创建时间', dataIndex: 'createTime', width: 140 }
      ],
      searchParams: buildSearchParams(),
      selectedRowKeys: [],
      shops: [],
      datePickerDefaultValues: [moment(), moment()],
      datePickerRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        这两天: [moment().subtract(1, 'days'), moment()]
      },
      sendEmails: {
        loading: false,
        form: {
          title: 'An exclusive discount code your unfinished orders',
          message: 'Thank you for loving our silk pajamas. We found that you have unfinished orders and we can offer you more discounts.',
          discountCode: null
        }
      }
    }
  },
  computed: {
    selectedRows () {
      const arr = []
      for (const item of this.dataList) {
        if (this.selectedRowKeys.includes(item.id)) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  methods: {
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.createTimeStart = dates[0] + ' 00:00:00'
        this.searchParams.createTimeEnd = dates[1] + ' 23:59:59'
      } else {
        this.searchParams.createTimeStart = null
        this.searchParams.createTimeEnd = null
      }
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.completedOrder === 0) {
        p.completedOrder = null
      } else {
        p.completedOrder = p.completedOrder === 1
      }
      this.dataLoading = true
      this.selectedRowKeys = []
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    syncData () {
      this.pageLoading = true
      sync()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.$message.success('同步完成。')
          this.loadData()
        })
        .send()
    },
    onSendEmailsConfirm () {
      this.$confirm({
        title: '确认',
        content: '确认开始发送邮件？',
        onOk: () => {
          const data = Object.assign({}, this.sendEmails.form)
          data.idList = this.selectedRowKeys
          this.sendEmails.loading = true
          sendEmails()
            .complete(() => (this.sendEmails.loading = false))
            .success(() => {
              this.$message.success('已经发送完成。')
              this.$nextTick(() => {
                this.$refs.sendEmails.close()
                this.loadData()
              })
            })
            .send(data)
        }
      })
    }
  },
  mounted () {
    this.reloadData()
    listShopOptions().success(resp => (this.shops = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 1;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .total-more {
    background-color: #bb3e30;
  }
}
</style>
