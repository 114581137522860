<!-- 寄样订单 -->
<template>
  <communication-editor :visible.sync="visible" title="寄样" :loading="loading" @submit="handleSave()">

    <a-divider orientation="left">店铺</a-divider>
    <div class="shop-options">
      <div class="shop-option" :class="{'selected': selectedShopId === s.id}" v-for="s in shopOptions" :key="s.id" @click="selectedShopId = s.id">{{s.name}}</div>
    </div>


    <a-divider orientation="left">地址</a-divider>

    <div class="addr-options" v-show="addressOptions.length > 0">
      <div class="addr-option label text-muted font-bold">可用地址：</div>
      <div class="addr-option" v-for="(addr, index) in addressOptions" :key="index" @click="handleSetAddr(addr)">{{addr.name}}</div>
    </div>

    <div class="addr-delivery">
      <div class="text-muted font-bold">
        收货地址：
        <a-button size="small" type="link" @click="$refs.addr.open()">编辑</a-button>
      </div>
      <p>联系人：{{addressForm.receiverLastName}} {{addressForm.receiverFirstName}} / {{addressForm.receiverPhone}}</p>
      <p>地址：{{addressForm.receiverCountry}}, {{addressForm.receiverState}}, {{addressForm.receiverCity}} {{addressForm.receiverAddress1}} {{addressForm.receiverAddress2}}</p>
    </div>

    <a-divider orientation="left" style="margin-top: 40px;">可以为订单设置产品</a-divider>

    <div style="margin-bottom: 10px; align-items: center;" class="left-right-content">
      <div class="full">
        <a-button @click="handleSkuSelect">选择SKU</a-button>

        <a-tooltip>
          <template slot="title">请上传Excel或CSV文件，不要标题行，列1：SKU，列2：数量。</template>
          <a-button :loading="saveButtonLoading" @click="handleSkuSelectFromFile">从文件导入</a-button>
        </a-tooltip>

        <a-button type="danger" @click="handleRemoveAllProducts">移除所有SKU</a-button>
      </div>
      <div class="font-mini text-primary">{{products.length}} 个SKU，共 {{skuTotal}} 件产品。</div>
    </div>

    <a-table
        size="middle"
        rowKey="id"
        :columns="productColumns"
        :data-source="products"
        :pagination="false"
        :scroll="{ y: 500 }"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" type="danger" icon="delete" @click="handleRemoveProduct(row)"></a-button>
      </div>
      <div slot="image" slot-scope="imageUrl" class="image-item" :style="{'background-image': `url(${imageUrl})`}"></div>
      <div slot="total" slot-scope="total, row">
        <a-input-number :min="1" v-model="row.total"></a-input-number>
      </div>
    </a-table>

    <stock-product-select ref="skuSelector"></stock-product-select>

    <modal ref="addr" title="编辑地址" :width="700" :hide-buttons="true">
      <a-form-model ref="form" :model="addressForm" :label-col="{span: 5}" :wrapper-col="{span: 19}">
        <div class="form-item-wrapper">
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="addressForm.email" />
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="receiverPhone">
            <a-input v-model="addressForm.receiverPhone" />
          </a-form-model-item>
        </div>
        <div class="form-item-wrapper">
          <a-form-model-item label="姓" prop="receiverFirstName">
            <a-input v-model="addressForm.receiverFirstName" />
          </a-form-model-item>
          <a-form-model-item label="名" prop="receiverLastName">
            <a-input v-model="addressForm.receiverLastName" />
          </a-form-model-item>
        </div>

        <div class="form-item-wrapper">
          <a-form-model-item label="省份/州" prop="receiverState">
            <a-input v-model="addressForm.receiverState" />
          </a-form-model-item>
          <a-form-model-item label="城市" prop="receiverCity">
            <a-input v-model="addressForm.receiverCity" />
          </a-form-model-item>
        </div>

        <div class="form-item-wrapper">
          <a-form-model-item label="地址1" prop="receiverAddress1">
            <a-input v-model="addressForm.receiverAddress1" />
          </a-form-model-item>
          <a-form-model-item label="地址2" prop="receiverAddress2">
            <a-input v-model="addressForm.receiverAddress2" />
          </a-form-model-item>
        </div>

        <div class="form-item-wrapper">
          <a-form-model-item label="邮政编号" prop="receiverPostalCode">
            <a-input v-model="addressForm.receiverPostalCode" />
          </a-form-model-item>
          <a-form-model-item label="国家" prop="countryCode">
            <a-select v-model="addressForm.receiverCountryCode"
                      style="width: 100%;"
                      show-search
                      option-filter-prop="children"
                      :filter-option="countryOptionFilter"
                      :allowClear="true">
              <a-select-option :value="item.code" v-for="item in countryOptions" :key="item.code">{{item.ename}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>

        <div class="form-item-wrapper">
          <a-form-model-item label="公司" prop="receiverCompany">
            <a-input v-model="addressForm.receiverCompany" />
          </a-form-model-item>
          <a-form-model-item label="传真" prop="receiverFax">
            <a-input v-model="addressForm.receiverFax" />
          </a-form-model-item>
        </div>

        <div class="form-item-wrapper">
          <a-form-model-item label="备注" prop="adminRemark">
            <a-textarea v-model="addressForm.adminRemark" :auto-size="{ minRows: 3 }" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </modal>

  </communication-editor>
</template>

<script>
import CommunicationEditor from '@/pages/influencer/detail/comm-comp/communication-editor'
import CommunicationMixin from '@/pages/influencer/detail/comm-comp/communication-mixin'
import { getCountries } from '@/http/api/order-delivery'
import { checkSku } from '@/http/api/stock-product'
import { listShopOptionsBySelfDept } from '@/http/api/shop'
import kit from '@/utils/kit'
import selectFiles from '@/utils/select-file'
import StockProductSelect from '@/components-business/stock-product-select'
import { TYPE_DEMO_ORDER } from '@/constants/communication-type'

function createAddressForm () {
  return {
    email: null,
    adminRemark: null,
    receiverFirstName: null,
    receiverLastName: null,
    receiverPhone: null,
    receiverCompany: null,
    receiverCountryCode: null,
    receiverCountry: null,
    receiverState: null,
    receiverCity: null,
    receiverAddress1: null,
    receiverAddress2: null,
    receiverPostalCode: null,
    receiverFax: null
  }
}

export default {
  mixins: [CommunicationMixin],
  components: { CommunicationEditor, StockProductSelect },
  props: {
    addressOptions: { type: Array },
    influencer: { type: Object }
  },
  data () {
    return {
      type: TYPE_DEMO_ORDER,
      visible: false,
      closable: true,
      saveButtonLoading: false,
      addressForm: createAddressForm(),
      products: [],
      countryOptions: [],
      shopOptions: [],
      selectedShopId: null,
      productColumns: [
        { title: '', width: 70, scopedSlots: { customRender: 'action' } },
        { title: '图片', dataIndex: 'imageUrl', width: 130, scopedSlots: { customRender: 'image' } },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '数量', dataIndex: 'total', width: 200, scopedSlots: { customRender: 'total' } }
      ]
    }
  },
  watch: {
    visible () {
      if (this.visible) {
        this.onVisible()
      }
    },
    'addressForm.receiverCountryCode' (code) {
      const c = this.countryOptions.find(item => item.code === code)
      if (c) this.addressForm.receiverCountry = c.ename
    }
  },
  computed: {
    skuTotal () {
      let t = 0
      for (const item of this.products) {
        t += item.total
      }
      return t
    }
  },
  methods: {
    onVisible () {
      if (this.countryOptions.length === 0) {
        getCountries().success(resp => (this.countryOptions = resp.data)).send()
      }
      if (this.shopOptions.length === 0) {
        listShopOptionsBySelfDept().success(resp => (this.shopOptions = resp.data)).send()
      }
      if (this.influencer) {
        this.addressForm.email = this.influencer.email
      }
    },
    onHide () {
      this.addressForm = createAddressForm()
      this.products = []
    },
    getContent () {
      const data = {
        products: [],
        shopId: this.selectedShopId
      }
      Object.assign(data, this.addressForm)
      if (!data.email) {
        data.email = this.influencer.email
      }
      for (const p of this.products) {
        data.products.push({ sku: p.sku, total: p.total || 1 })
      }
      return JSON.stringify(data)
    },
    handleBeforeSave () {
      if (this.selectedShopId == null) {
        return this.$message.warning('请选择店铺。')
      }
      if (!this.addressForm.receiverPhone ||
          !this.addressForm.receiverCountryCode ||
          !this.addressForm.receiverAddress1 ||
          !this.addressForm.receiverPostalCode ||
          !this.addressForm.receiverFirstName
      ) {
        return this.$message.warning('收货地址缺少必要信息（电话，国家，地址，邮编，姓名）。')
      }
      if (this.products.length === 0) {
        return this.$message.warning('请添加发货产品。')
      }
      return true
    },
    handleSetAddr (addr) {
      this.addressForm.receiverAddress1 = addr.addr1
      this.addressForm.receiverAddress2 = addr.addr2
      this.addressForm.receiverCountry = addr.country
      this.addressForm.receiverCountryCode = addr.countryCode
      this.addressForm.receiverState = addr.state
      this.addressForm.receiverCity = addr.city
      this.addressForm.receiverFirstName = addr.name
      this.addressForm.receiverPhone = addr.phone
      this.addressForm.receiverPostalCode = addr.postal
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    handleSkuSelect () {
      this.$refs.skuSelector.open(selected => {
        for (const item of selected) {
          this.products.push({
            imageUrl: item.imageUrl,
            sku: item.sku,
            total: 1
          })
        }
      })
    },
    handleRemoveProduct (row) {
      kit.arr.removeItem(this.products, row)
    },
    handleRemoveAllProducts () {
      this.$confirm({
        title: '确认',
        content: '确定要移除所有SKU吗？',
        onOk: () => {
          this.products = []
        }
      })
    },
    handleSkuSelectFromFile () {
      selectFiles({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        multi: false,
        select: (files, errors) => {
          this.saveButtonLoading = true
          checkSku()
            .complete(() => (this.saveButtonLoading = false))
            .success(resp => {
              for (const item of resp.data.found) {
                this.products.push({
                  imageUrl: item.imageUrl,
                  sku: item.sku,
                  total: item.total
                })
              }
              if (resp.data.notFound.length > 0) {
                this.$info({
                  title: '提示',
                  content: '一些SKU不存在系统中，点击确定后会打开不存在的SKU页面，请确认。',
                  onOk: () => {
                    const arr = []
                    arr.push(['SKU', 'Total'])
                    for (const item of resp.data.notFound) {
                      arr.push([item.sku, item.total])
                    }
                    const csvContent = 'data:text/csv;charset=utf-8,' + arr.map(e => e.join(',')).join('\n')
                    window.open(encodeURI(csvContent))
                  }
                })
              }
            })
            .send(files[0])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.addr-options {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #f2f2f2;
  padding-bottom: 10px;
  margin-bottom: 10px;
  .addr-option {
    margin: 5px;
    padding: 5px 10px;
    font-size: 12px;
    border: solid 1px #ddd;
    border-radius: 5px;
    cursor: pointer;
    &.label {
      border: none;
      margin: 0;
      padding: 0;
      cursor: auto;
      font-size: 14px;
    }
  }
}

.addr-delivery {
  p {
    font-size: 12px;
    margin-top: 8px;
    padding-left: 20px;
  }
}

.shop-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .shop-option {
    margin: 5px;
    padding: 5px 10px;
    font-size: 12px;
    border: solid 1px #ddd;
    border-radius: 5px;
    cursor: pointer;
    &.selected {
      border-color: #a94442;
      color: #a94442;
    }
  }
}

@deep: ~'>>>';
.form-item-wrapper {
  display: flex;
}
.form-item-wrapper @{deep} .ant-form-item {
  flex: .5;
}
.form-item-wrapper @{deep} .ant-form-item + .ant-form-item {
  margin-left: 20px;
}
</style>
