import defineApi from '../define-api'
import http from '../application-api'

export const listT17track = defineApi((config, params) => {
  config.params = params
  http.get('/t17track/list', config)
})

export const listT17trackLogistics = defineApi((config) => {
  http.get('/t17track/list/logistics', config)
})

export const reg = defineApi((config, dataList) => {
  config.data = dataList
  http.post('/t17track/reg', config)
})

export const sync = defineApi((config, trackNoList) => {
  config.params = {
    list: trackNoList
  }
  http.post('/t17track/sync', config)
})
