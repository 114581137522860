<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button @click="printLabel" v-show="statusMap.accept === selectedTotalTab.id">打印面单</a-button>
      <a-button @click="acceptOrder" type="primary" v-show="statusMap.create === selectedTotalTab.id">受理订单</a-button>
      <a-button @click="cancelAcceptOrder" type="danger" v-show="statusMap.accept === selectedTotalTab.id">取消受理</a-button>
      <a-button @click="confirmDelivery" type="primary" v-show="statusMap.accept === selectedTotalTab.id">标记已发货</a-button>
    </div>

    <div slot="top">
      <div class="total-tab">
        <ul>
          <li v-for="tab in totalTabs" :key="tab.id" @click="handleTotalTabSelected(tab)" :class="{'selected': tab.selected}">
            {{tab.title}}
            <span class="total">( {{tab.total}} )</span>
          </li>
        </ul>
      </div>

      <div class="search-form">
        <a-form-model layout="inline" :model="searchParams">
          <div>
            <a-form-model-item label="运单号">
              <a-input v-model="searchParams.trackNo" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="客户单号">
              <a-input v-model="searchParams.orderNo" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="发货日期">
              <a-range-picker @change="handleCreateDateChanged"
                              value-format="YYYY-MM-DD"
                              style="width: 220px;"
              >
              </a-range-picker>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData">查询</a-button>
        </div>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableX }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="action" slot-scope="v, record">
        <a-button v-if="selectedTotalTab.id === statusMap.accept && record.companyId === logisticsCompanySupplier" @click="handleOpenDeliveryUpdateEditor(record)">更新物流</a-button>
      </div>

      <div slot="status" slot-scope="supplierOrderDeliveryStatus">
        <span class="delivery-status" :class="'status-' + supplierOrderDeliveryStatus">{{supplierOrderDeliveryStatusMapping[supplierOrderDeliveryStatus]}}</span>
      </div>
      <div slot="trackNo" slot-scope="trackNo, record">
        <a v-if="trackNo" target="_blank" :href="record.trackUrl">
          {{trackNo}}
        </a>
      </div>
      <div slot="companyId" slot-scope="companyId, record">
        {{logisticsCompanyTextMapping[companyId]}} / {{record.shippingMethod}}
      </div>
      <div slot="regT17track" slot-scope="regT17track">
        <span v-if="regT17track" class="t17track-reg">17Track</span>
      </div>

      <div slot="customerInfo" slot-scope="record">
        <div>名字：{{record.customerName}}</div>
        <div>邮箱：{{record.customerEmail}}</div>
        <div>电话：{{record.customerPhone}}</div>
        <div>地址：{{record.countryCode}}/{{record.customerAddress}}</div>
      </div>

      <div slot="items" slot-scope="items">

        <div class="product-item" v-for="item in items" :key="item.id">
          <div class="image" v-lazy:background-image="item.imageUrl" @click="$imageModal.open(item.imageUrl)">
          </div>
          <div class="info">
            <div>SKU：{{item.sku}}</div>
            <div>规格：{{item.variant}}</div>
          </div>
          <div class="total"><span style="font-weight: bold; font-size: 22px;">{{item.total}}</span></div>
        </div>
      </div>
      <div slot="arrivedTime" slot-scope="time, record">
        {{time}}<span v-if="time" class="text-success font-mini">（{{record | deliveryUseDays}}天）</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <modal title="更新物流信息" ref="deliveryUpdateEditor"
           @cancel="handleDeliveryUpdateEditorClose"
           @ok="handleDeliveryUpdateEditorConfirm"
           :loading="deliveryUpdateEditor.formSubmitting"
    >
      <a-form-model ref="form" :model="deliveryUpdateEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="快递单号" prop="trackNo" class="app_required-input">
          <a-input v-model="deliveryUpdateEditor.form.trackNo" />
        </a-form-model-item>
        <a-form-model-item label="快递公司" prop="trackNo" class="app_required-input">
          <a-select
              show-search
              v-model="deliveryUpdateEditor.form.logisticsId"
              placeholder="输入关键字搜索"
              style="width: 100%"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="deliveryUpdateEditor.logisticsLoading ? undefined : null"
              @search="debounceSearchLogistics"
              @change="handleLogisticsSelectChange"
          >
            <a-spin v-if="deliveryUpdateEditor.logisticsLoading" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in deliveryUpdateEditor.logisticsOptions" :key="d.id">
              {{ d.carrierEn }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="运费" prop="fee" class="app_required-input">
          <a-input-number v-model="deliveryUpdateEditor.form.fee" />
        </a-form-model-item>
        <a-form-model-item label="货币">
          <a-radio-group v-model="deliveryUpdateEditor.form.feeCurrency">
            <a-radio value="USD">美元</a-radio>
            <a-radio value="CNY">人民币</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER_ORDER_DELIVERY } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  SUPPLIER_ORDER_DELIVERY_MAPPING,
  SUPPLIER_ORDER_DELIVERY_CREATE,
  SUPPLIER_ORDER_DELIVERY_ACCEPT,
  SUPPLIER_ORDER_DELIVERY_DELIVERY
} from '@/constants/supplier-order-delivery-status'
import { LOGISTICS_COMPANY_MAPPING, LOGISTICS_COMPANY_SUPPLIER } from '@/constants/logistics'
import {
  listSupplierOrderDelivery,
  getSupplierOrderDeliveryTotalGroupByStatus,
  acceptSupplierOrderDelivery,
  cancelAcceptSupplierOrderDelivery,
  getLogistics,
  supplierOrderDeliveryDelivery,
  confirmSupplierOrderDelivery
} from '@/http/api/supplier-order-delivery'
import moment from 'moment'
import debounce from 'lodash/debounce';
import { printLabel } from '@/http/api/order-delivery'

function buildSearchParams (supplierOrderDeliveryStatus) {
  return {
    supplierOrderDeliveryStatus: supplierOrderDeliveryStatus,
    trackNo: null,
    orderNo: null,
    createTimeStart: null,
    createTimeEnd: null
  }
}

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    const tabs = [
      { id: SUPPLIER_ORDER_DELIVERY_CREATE, title: SUPPLIER_ORDER_DELIVERY_MAPPING[SUPPLIER_ORDER_DELIVERY_CREATE], total: 0, selected: true },
      { id: SUPPLIER_ORDER_DELIVERY_ACCEPT, title: SUPPLIER_ORDER_DELIVERY_MAPPING[SUPPLIER_ORDER_DELIVERY_ACCEPT], total: 0, selected: false },
      { id: SUPPLIER_ORDER_DELIVERY_DELIVERY, title: SUPPLIER_ORDER_DELIVERY_MAPPING[SUPPLIER_ORDER_DELIVERY_DELIVERY], total: 0, selected: false }
    ]
    const searchParamsByTab = {}
    for (const t of tabs) {
      searchParamsByTab[t.id] = buildSearchParams(t.id)
    }

    return {
      routeName: ROUTE_SUPPLIER_ORDER_DELIVERY,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', dataIndex: 'action', width: 100, scopedSlots: { customRender: 'action' }, fixed: 'left' },
        { title: '客户单号', dataIndex: 'orderNo', width: 150, fixed: 'left' },
        { title: '发货清单', dataIndex: 'deliveryProductItems', width: 300, scopedSlots: { customRender: 'items' } },
        { title: '客户信息（国家/地址/城市/省份/邮编）', width: 280, scopedSlots: { customRender: 'customerInfo' } },
        { title: '状态', dataIndex: 'supplierOrderDeliveryStatus', width: 100, scopedSlots: { customRender: 'status' } },
        { title: '发货日期', dataIndex: 'createTime', width: 120 },
        { title: '运单号', dataIndex: 'trackNo', width: 160, scopedSlots: { customRender: 'trackNo' } },
        { title: '物流', dataIndex: 'companyId', width: 260, scopedSlots: { customRender: 'companyId' } },
        { title: '备注', dataIndex: 'remark', width: 250 },
        { title: '签收日期', dataIndex: 'arrivedTime', width: 150, scopedSlots: { customRender: 'arrivedTime' } },
        { title: '运费', dataIndex: 'fee', width: 80 }
      ],
      searchParamsByTab: searchParamsByTab,
      searchParams: searchParamsByTab.create,
      totalTabs: tabs,
      logisticsCompanyTextMapping: LOGISTICS_COMPANY_MAPPING,
      logisticsCompanySupplier: LOGISTICS_COMPANY_SUPPLIER,
      selectedRowKeys: [],
      statusMap: {
        create: SUPPLIER_ORDER_DELIVERY_CREATE,
        accept: SUPPLIER_ORDER_DELIVERY_ACCEPT,
        delivery: SUPPLIER_ORDER_DELIVERY_DELIVERY
      },
      supplierOrderDeliveryStatusMapping: SUPPLIER_ORDER_DELIVERY_MAPPING,
      deliveryUpdateEditor: {
        model: null,
        logisticsOptions: [],
        logisticsLoading: false,
        formSubmitting: false,
        form: {
          trackNo: null,
          logisticsId: null,
          logisticsName: null,
          fee: 0,
          feeCurrency: 'USD'
        }
      }
    }
  },
  computed: {
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    },
    selectedRows () {
      const arr = []
      for (const item of this.dataList) {
        if (this.selectedRowKeys.includes(item.id)) {
          arr.push(item)
        }
      }
      return arr
    },
    selectedTotalTab () {
      return this.totalTabs.find(item => item.selected)
    }
  },
  filters: {
    deliveryUseDays (data) {
      const createTime = moment(data.createTime)
      const arrivedTime = moment(data.arrivedTime)
      return arrivedTime.diff(createTime, 'days')
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      this.selectedRowKeys = []
      listSupplierOrderDelivery()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.createTimeStart = dates[0] + ' 00:00:00'
        this.searchParams.createTimeEnd = dates[1] + ' 00:00:00'
      } else {
        this.searchParams.createTimeStart = null
        this.searchParams.createTimeEnd = null
      }
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleTotalTabSelected (tab) {
      if (tab.selected) {
        tab.selected = false
      } else {
        this.totalTabs.forEach(item => (item.selected = false))
        tab.selected = true
      }
      this.searchParams = this.searchParamsByTab[tab.id]
      this.reloadData()
    },
    loadTotalGroupByStatus () {
      getSupplierOrderDeliveryTotalGroupByStatus()
        .success(resp => {
          for (const key of Object.keys(resp.data)) {
            for (const item of this.totalTabs) {
              if (item.id === key) {
                item.total = resp.data[key]
                break
              }
            }
          }
        })
        .send()
    },
    acceptOrder () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      const idList = []
      for (const item of this.selectedRows) {
        idList.push(item.supplierOrderDeliveryId)
      }
      this.pageLoading = true
      acceptSupplierOrderDelivery()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.reloadData()
          this.loadTotalGroupByStatus()
          this.$message.success('受理成功。')
        })
        .send(idList)
    },
    cancelAcceptOrder () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      const idList = []
      for (const item of this.selectedRows) {
        idList.push(item.supplierOrderDeliveryId)
      }
      this.$confirm({
        title: '确认',
        content: '确定要取消订单的受理吗？',
        onOk: () => {
          this.pageLoading = true
          cancelAcceptSupplierOrderDelivery()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.reloadData()
              this.loadTotalGroupByStatus()
              this.$message.success('受理取消成功。')
            })
            .send(idList)
        }
      })
    },
    handleOpenDeliveryUpdateEditor (model) {
      this.deliveryUpdateEditor.model = model
      this.$refs.deliveryUpdateEditor.open()
    },
    handleDeliveryUpdateEditorClose () {
      this.deliveryUpdateEditor.form.fee = 0
      this.deliveryUpdateEditor.form.trackNo = null
    },
    handleDeliveryUpdateEditorConfirm () {
      this.$confirm({
        title: '确认',
        content: '是否已确认信息无误？',
        onOk: () => {
          const data = Object.assign({
            supplierOrderDeliveryId: this.deliveryUpdateEditor.model.supplierOrderDeliveryId
          }, this.deliveryUpdateEditor.form)
          this.deliveryUpdateEditor.formSubmitting = true
          supplierOrderDeliveryDelivery()
            .complete(() => (this.deliveryUpdateEditor.formSubmitting = false))
            .success(() => {
              this.reloadData()
              this.loadTotalGroupByStatus()
              this.$message.success('更新成功。')
              this.$nextTick(() => {
                this.$refs.deliveryUpdateEditor.close()
              })
            })
            .send(data)
        }
      })
    },
    handleSearchLogistics (keyword) {
      this.deliveryUpdateEditor.logisticsLoading = true
      getLogistics()
        .complete(() => (this.deliveryUpdateEditor.logisticsLoading = false))
        .success(resp => {
          this.deliveryUpdateEditor.logisticsOptions = resp.data
        })
        .send(keyword)
    },
    handleLogisticsSelectChange (id) {
      this.deliveryUpdateEditor.form.logisticsName = this.deliveryUpdateEditor.logisticsOptions.find(item => item.id === id).carrierEn
    },
    printLabel () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warn('请选择发货记录。')
      }
      this.pageLoading = true
      printLabel()
        .complete(() => (this.pageLoading = false))
        .success(resp => {
          window.open(resp.data)
        })
        .send(this.selectedRowKeys)
    },
    confirmDelivery () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行。')
      }
      const idList = []
      for (const item of this.selectedRows) {
        idList.push(item.supplierOrderDeliveryId)
      }
      this.$confirm({
        title: '确认',
        content: '确定要将选中的记录标记为已发货吗？',
        onOk: () => {
          this.pageLoading = true
          confirmSupplierOrderDelivery()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.reloadData()
              this.loadTotalGroupByStatus()
              this.$message.success('操作成功。')
            })
            .send(idList)
        }
      })
    }
  },
  mounted () {
    this.loadData()
    this.loadTotalGroupByStatus()
    this.debounceSearchLogistics = debounce(keyword => {
      this.handleSearchLogistics(keyword)
    }, 800)
  }
}
</script>

<style lang="less" scoped>


.total-tab {
  margin-bottom: 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 4px 20px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    &:hover {
      color: #bb3e30;
    }
    &.selected {
      border-bottom: solid 2px #bb3e30;
    }
    .total {
      margin-left: 4px;
      color: #bb3e30;
      font-size: 12px;
    }
  }
  li + li {
    margin-left: 14px;
  }
}

.delivery-status {
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 4px;
}
.status-create {
  background-color: #797979;
}
.status-accept {
  background-color: #0071f6;
}
.status-delivery {
  background-color: #0c9700;
}


.product-item {
  @height: 50px;
  position: relative;
  border: solid 1px #1b77b4;
  background-color: #fff;
  border-radius: 5px;
  min-height: @height;
  overflow: hidden;
  .image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .info {
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px 10px;
    flex: 1;
    font-size: 12px;
  }
  .total {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1b77b4;
    color: #fff;
  }
}
.product-item + .product-item {
  margin-top: 10px;
}
</style>
