<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>促销方块</label>
        <span>
          <a-button @click="openEditor()">添加促销</a-button>
          <draggable
              tag="div"
              style="margin: 10px 0;"
              v-model="section.promotions"
              v-bind="drag.dragOptions"
              @start="drag.drag = true"
              @end="drag.drag = false"
          >
            <transition-group tag="ul" type="transition" :name="!drag.drag ? 'flip-list' : null" class="sortable-list list-group">
              <li v-for="item in section.promotions" :key="item.id" class="list-group-item">
                <div class="item-wrapper">
                  <div class="order-icon">
                    <a-icon type="unordered-list" />
                  </div>
                  <div class="title">{{item.title}}（{{item.code}}）</div>
                  <div>
                    <a-button size="small" icon="edit" @click="openEditor(item)"></a-button>
                    <a-button size="small" type="danger" icon="delete" @click="handleRemoveMenu(item)"></a-button>
                  </div>
                </div>
              </li>
            </transition-group>
          </draggable>
        </span>
      </div>
      <div class="detail-item">
        <label>文字颜色</label>
        <span>
          <color-picker :color.sync="section.textColor"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>折扣码颜色</label>
        <span>
          <color-picker :color.sync="section.codeColor"></color-picker>
        </span>
      </div>
      <div class="detail-item">
        <label>使用分隔栏</label>
        <span>
          <a-switch v-model="section.useSplitBorder" />
        </span>
      </div>
      <div class="detail-item">
        <label>分隔栏颜色</label>
        <span>
          <color-picker :color.sync="section.splitBorderColor"></color-picker>
        </span>
      </div>
    </div>

    <modal title="编辑" ref="editor" @ok="onEditorOk" @closed="onEditorClosed">
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="标题" class="app_required-input">
          <a-input v-model="editor.form.title" style="width: 100%;" />
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-input v-model="editor.form.description" style="width: 100%;" />
        </a-form-model-item>
        <a-form-model-item label="折扣码" class="app_required-input">
          <a-input v-model="editor.form.code" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>
import Model from '../model/promotion-block'
import ListItemMixin from '../mixin/list-item-editor'
import kit from '@/utils/kit'

export default {
  props: {
    section: { type: Model }
  },
  mixins: [ListItemMixin],
  data () {
    return {
      drag: {
        dragOptions: {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          handle: '.order-icon'
        },
        drag: false
      },
      editor: {
        editingItem: null,
        form: {
          title: null,
          description: null,
          code: null
        }
      }
    }
  },
  watch: {
  },
  methods: {
    createEditorForm () {
      return {
        title: null,
        description: null,
        code: null
      }
    },
    getListItems () {
      return this.section.promotions
    },
    handleRemoveMenu (item) {
      kit.arr.removeItem(this.section.promotions, item)
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrapper {
  display: flex;
  .order-icon {
    cursor: move;
  }
  .title {
    flex: 1;
    padding: 0 10px;
  }
}
</style>
