<!--
  费用分析
-->
<template>
  <div class="cost-container">

    <div class="search">
      <a-form-model layout="inline" :model="search">
        <a-form-model-item label="日期">
          <a-range-picker
              v-model="search.date"
              value-format="yyyy-MM-DD"
              :ranges="dateRanges"
          >
          </a-range-picker>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleLoadData">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div style="display: flex;">
      <a-card style="flex: .5;">
        <div slot="title">
          销售额
          <span class="font-large text-primary" v-if="saleAmountData.length > 0">（{{saleAmount | price}} 美元）</span>
          <span class="font-mini text-danger" v-else>（没有数据）</span>
        </div>
        <pie :data="saleAmountData" series-title="销售额" style="height: 400px; width: 450px; margin: 0 auto;"></pie>
      </a-card>


      <a-card style="flex: .5; margin-left: 30px;">
        <div slot="title">
          销售额 / 退款
        </div>
        <a-table :columns="[
            { title: '店铺', dataIndex: 'shop' },
            { title: '销售额', dataIndex: 'pay' },
            { title: '退款', dataIndex: 'refund' },
            { title: '退款%', scopedSlots: { customRender: 'refundPercent' } }
        ]"
                 rowKey="shop"
                 :pagination="false"
                 :data-source="saleRefundAmountData"
        >
          <div slot="refundPercent" slot-scope="v, record">
            {{record.refund / (record.pay + record.refund) * 100 | floor}} %
          </div>
        </a-table>
      </a-card>
    </div>


    <div style="display: flex; margin-top: 20px;">
      <a-card style="flex: .5;">
        <div slot="title">
          一般支出
          <span class="font-large text-primary" v-if="accountingData.length > 0">（{{accountingAmount | price}} 人民币）</span>
          <span class="font-mini text-danger" v-else>（没有数据）</span>
        </div>
        <pie :data="accountingData" series-title="一般支出" style="height: 400px; width: 450px; margin: 0 auto;"></pie>
      </a-card>


      <a-card style="flex: .5; margin-left: 30px;">
        <div slot="title">
          物流支出
          <span class="font-large text-primary" v-if="orderDeliveryData.length > 0">（{{orderDeliveryAmount | price}} 人民币）</span>
          <span class="font-mini text-danger" v-else>（没有数据）</span>
        </div>
        <pie :data="orderDeliveryData" series-title="物流支出" style="height: 400px; width: 450px; margin: 0 auto;"></pie>
      </a-card>
    </div>
  </div>
</template>

<script>
import Pie from '@/charts/pie'
import { getSummaryGroupByCategory } from '@/http/api/accounting'
import { getFeeSummaryGroupByCompany } from '@/http/api/order-delivery'
import { getPriceSummaryGroupByShop } from '@/http/api/order'
import kit from '@/utils/kit'
import moment from 'moment'
import { LOGISTICS_COMPANY_MAPPING } from '@/constants/logistics'

export default {
  components: { Pie },
  data () {
    return {
      search: {
        date: []
      },
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        上周: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        上个月: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      // 各类支出
      accountingData: [],
      accountingAmount: 0,
      // 按物流公司的运费支出
      orderDeliveryData: [],
      orderDeliveryAmount: 0,
      // 各店铺销售额
      saleAmountData: [],
      saleAmount: 0,
      // 各店铺销售额和退款
      saleRefundAmountData: []
    }
  },
  filters: {
    price (amount) {
      return kit.str.fmtCurrency(amount)
    },
    floor (n) {
      return kit.num.toFixedTrimZero(n, 2)
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
    },
    onInactive () {
    },
    onInit () {
      const dates = this.dateRanges['上周']
      this.search.date = [dates[0].format('yyyy-MM-DD'), dates[1].format('yyyy-MM-DD')]
      this.handleLoadData()
    },
    handleLoadData () {
      if (this.search.date.length !== 2) {
        return this.$message.warning('请选择日期范围。')
      }
      getSummaryGroupByCategory()
        .success(resp => {
          this.accountingData = [...resp.data]
          this.accountingAmount = 0
          for (const item of this.accountingData) {
            this.accountingAmount += item.value
          }
        })
        .send(this.search.date[0], this.search.date[1])

      getFeeSummaryGroupByCompany()
        .success(resp => {
          this.orderDeliveryAmount = 0
          for (const item of resp.data) {
            this.orderDeliveryAmount += item.value
            item.name = LOGISTICS_COMPANY_MAPPING[item.name] || item.name
          }
          this.orderDeliveryData = [...resp.data]
        })
        .send(this.search.date[0], this.search.date[1])

      getPriceSummaryGroupByShop()
        .success(resp => {
          this.saleAmount = 0
          const pay = []
          for (const item of resp.data) {
            this.saleAmount += item.pay
            pay.push({ name: item.shop, value: item.pay })
          }
          this.saleAmountData = [...pay]
          this.saleRefundAmountData = resp.data
        })
        .send(this.search.date[0], this.search.date[1])
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  text-align: center;
  margin-bottom: 20px;
}
</style>
