import kit from '@/utils/kit'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Section {
  constructor (type, name) {
    this.id = kit.str.id()
    this.sectionType = type
    this.sectionName = name
    // 模块是否可用
    this.sectionEnabled = true
    // 展开或收起模块的配置
    this.sectionExpand = true
    // 显示或隐藏通用设置
    this.generalVisible = false

    this.generalMarginTop = 0
    this.generalMarginBottom = 0
    this.generalMarginLeft = 0
    this.generalMarginRight = 0
    this.generalPaddingTop = 0
    this.generalPaddingBottom = 0
    this.generalPaddingLeft = 0
    this.generalPaddingRight = 0
    this.generalTextColor = '#000000'
    this.generalBackgroundColor = '#ffffff'
  }

  initData (data) {
    Object.assign(this, data)
  }

  /**
   * 表明这个模块的内容类型，只有text和image两个返回值。
   * @return {string}
   */
  getContentType () {
    return 'text'
  }

  /**
   * 由具体的模块返回内容，用来在模块头部显示提示内容，帮助用户在模块最小化时，快速找到对应的模块。
   * 如果getContentType返回的是text，那么这里应该返回一个文本字符串，如果getContentType返回的
   * 是image，这里应该返回一个字符串数组，表示图片url。
   * @return {String | Array}
   */
  getHeaderDisplay () {
    return null
  }
}
