<template>
  <transition name="fade" :duration="200">
    <div class="login-page fixed-full">

      <div class="background-image absolute-full"></div>
      <div class="background-image-description">{{backgroundImageDescription}}</div>

      <div class="overlay absolute-full">

        <div class="panel-wrapper">
          <div class="logo" :style="{'background-image': `url(${logoUrl})`}" :class="{'no-bg-color': !!logoUrl}">
            <span v-show="!logoUrl">{{logoName}}</span>
          </div>
          <div class="app-info">
            <span class="app-name">{{name}}</span>
            <span class="app-version" v-show="version">{{version}}</span>
          </div>
          <div class="animate-wrapper">
            <login-panel ref="login" @changePanel="switchPanel"></login-panel>
          </div>
        </div>
        <div class="copyright">Copyright @ 2020 JiangXingShang All Rights Reserved power by</div>
      </div>
    </div>
  </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import LoginPanel from './login'
import { appStore } from '@/store'

export default {
  components: { LoginPanel },
  data () {
    return {
      backgroundImageDescription: '停下脚步，就只能看到同行人的背影。'
    }
  },
  computed: {
    ...(appStore.mapState(['version', 'name', 'logoUrl'])),
    logoName () {
      if (this.name) {
        return this.name.substr(0, 1).toUpperCase()
      } else {
        return 'S'
      }
    }
  },
  mounted () {
  },
  methods: {
    switchPanel (name) {
      let styleName = '0px'
      if (name === 'password') {
        styleName = '-400px'
        this.pageTitle = '忘记密码'
        this.$refs.password.username = this.$refs.login.username
        this.$refs.password.disabled = false
      } else {
        this.$refs.password.disabled = true
        this.$refs.login.username = this.$refs.password.username
      }
      Velocity(
        this.$el.getElementsByClassName('animate-wrapper')[0],
        { 'margin-left': styleName },
        { duration: 150 }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.nice-words {
  width: 600px;
  margin-top: 200px;
  margin-left: 100px;
  font-size: 24px;
  color: #d7d7d7;
  text-shadow: 2px 2px 2px rgba(0,0,0,.3);
  .chinese {
    font-family: STKaiti,sans-serif;
    font-weight: bold;
  }
  .english {
    margin-top: 20px;
    font-size: 18px;
    font-family: Arial,sans-serif;
  }
}
</style>

<style lang="less">

.login-page {
  background-color: #fff;
  .background-image {
    z-index: 0;
    background-size: 100%;
    background: url(~@/assets/images/bg.jpg) no-repeat center;
  }
  .background-image-description {
    position: absolute;
    top: 50%;
    margin-top: -150px;
    left: 80px;
    right: 600px;
    color: #fff;
    font-size: 22px;
    z-index: 999999;
    letter-spacing: 5px;
    line-height: 40px;
  }

  .overlay {
    z-index: 1;
    background-color: rgba(0,0,0,.3);
  }

  @panelWrapperWidth: 400px;
  @panelWidth: @panelWrapperWidth - 2; // 2px边框，20左间距
  @panelBorderColor: #efefef;
  .panel-wrapper {
    position: absolute;
    top: 50%;
    right: 80px;
    margin-top: -237px;
    padding-top: 12px;
    width: @panelWrapperWidth;
    overflow: hidden;
    background-color: rgba(255,255,255,.8);
    box-shadow: 0 0 8px rgba(0,0,0,.4);
    border-radius: 3px;
    .app-info {
      margin-top: 5px;
      text-align: center;
      color: #155492;
    }
    .app-name {
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
    }
    .app-version {
      font-weight: normal;
      font-size: 12px;
      text-align: center;
    }
    .logo {
      display: block;
      margin: 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #262525;
      @size: 100px;
      width: @size;
      height: @size;
      line-height: @size;
      color: #fff;
      text-align: center;
      font-size: 62px;
      font-weight: bold;
      &.no-bg-color {
        background-color: transparent !important;
      }
    }
  }
  .animate-wrapper {
    width: @panelWrapperWidth * 2;
    white-space: nowrap;
    .panel {
      display: block;
      float: left;
      min-height: 100px;
      width: @panelWidth;
      padding: 10px 0;
      margin: 0 !important;
      vertical-align: top;
    }
  }

  .login-panel {
    .code-image {
      width: 100px;
      height: 29px !important;
      cursor: pointer;
      img {
        width: inherit;
        height: inherit;
      }
    }
    .clear-username {
      display: flex;
      flex-flow: column;
      justify-content: center;
      color: #949494;
      cursor: pointer;
    }
  }

  .panel {
    .form-item {
      display: flex;
      padding: 5px 15px;
      &:not(:first-of-type) {
        margin-top: 10px;
      }
      &.input-item {
        border-bottom: solid 1px @panelBorderColor;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
      }
      &.button-bar {
        display: block;
        text-align: right !important;
      }
      & > label {
        display: flex;
        width: 60px;
        flex-flow: column;
        justify-content: center;
        text-align: right;
        margin-right: 10px;
        color: #474747;
        font-size: 14px;
      }
      input {
        flex: 1;
        padding: 4px 0;
        background-color: transparent;
        border: none;
        color: #414141;
        font-weight: bold;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
      .remember {
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
    }
  }

  .copyright {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
</style>
