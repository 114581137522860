<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button type="primary" size="small" @click="handleUpload" :loading="uploading">上传文件更新提交状态</a-button>
      <a-button type="primary" size="small" @click="handleDownload">下载提交文件</a-button>
    </div>

    <div slot="top">
      <div class="search-form" style="margin-top: 5px;">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <div>
            <a-form-model-item label="开始日期">
              <a-input v-model="searchParams.start" placeholder="格式：2022-01-01" :allow-clear="true" style="width: 180px;" />
            </a-form-model-item>
            <a-form-model-item label="结束日期">
              <a-input v-model="searchParams.end" placeholder="格式：2022-01-31" :allow-clear="true" style="width: 180px;" />
            </a-form-model-item>
            <a-form-model-item label="只看未提交">
              <a-switch v-model="searchParams.onlyNotSubmit"></a-switch>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="buttons">
          <a-button @click="loadData()" icon="reload">查询</a-button>
        </div>
      </div>
    </div>

    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >

      <div slot="price" slot-scope="v, item">
        <div style="font-weight: bold; font-size: 13px; color: #2568d9">{{item.pricePay}} {{item.currency}}</div>
      </div>

      <div slot="submit" slot-scope="airwallexSubmit">
        <status :status="airwallexSubmit" enabled-text="已提交" disabled-text="未提交"></status>
      </div>
    </a-table>

    <div slot="bottom">
      <div class="bottom-wrapper">
        <div class="text-muted font-mini" style="margin-right: 20px;">共找到{{dataList.length}}个订单</div>
        <div class="price-item" v-for="currency in Object.keys(priceGroupByCurrency)" :key="currency">
          <span class="currency">{{currency}}</span>
          <span class="price">{{priceGroupByCurrency[currency] | priceFormat}}</span>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_AIRWALLEX } from '@/router/router-constants'
import { listOrders, uploadFileToUpdateAirwallexSubmit, downloadReportUrl } from '@/http/api/order-airwallex'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import kit from '@/utils/kit'
import selectFile from '@/utils/select-file'


export default {
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_AIRWALLEX,
      dataList: [],
      pageLoading: false,
      dataLoading: false,
      dataColumns: [
        { title: '订单号', width: 80, dataIndex: 'orderNo' },
        { title: '金额', width: 100, scopedSlots: { customRender: 'price' } },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '日期', width: 80, dataIndex: 'createTime' },
        { title: '提交', width: 100, dataIndex: 'airwallexSubmit', scopedSlots: { customRender: 'submit' } },
        { title: '店铺', width: 100, dataIndex: 'shopName' }
      ],
      searchParams: {
        start: '2022-10-01',
        end: '2022-10-31',
        onlyNotSubmit: true
      },
      uploading: false
    }
  },
  computed: {
    priceGroupByCurrency () {
      const priceMap = {}
      for (const item of this.dataList) {
        priceMap[item.currency] = (priceMap[item.currency] || 0) + item.pricePay
      }
      return priceMap
    }
  },
  filters: {
    priceFormat (price) {
      return kit.str.fmtCurrency(price, '')
    }
  },
  methods: {
    loadData () {
      const params = Object.assign({}, this.searchParams)
      if (params.start && params.end) {
        this.dataLoading = true
        this.selectedRowKeys = []
        listOrders()
          .complete(() => (this.dataLoading = false))
          .success(resp => {
            this.dataList = resp.data
          })
          .send(params)
      } else {
        this.$message.warning('请先选择日期。')
      }
    },
    handleUpload () {
      selectFile({
        accept: ['text/csv'],
        multi: false,
        select: files => {
          this.uploading = true
          uploadFileToUpdateAirwallexSubmit()
            .complete(() => (this.uploading = false))
            .success(() => {
              this.$message.success('更新完成。')
            })
            .send(files[0])
        }
      })
    },
    handleDownload () {
      if (this.searchParams.start && this.searchParams.end) {
        const query = `start=${this.searchParams.start}&end=${this.searchParams.end}&onlyNotSubmit=${this.searchParams.onlyNotSubmit}`
        window.open(downloadReportUrl(query))
      } else {
        this.$message.warning('请先选择日期。')
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.bottom-wrapper {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #eee;
  .price-item {
    padding: 0 10px;
    .currency {
      font-weight: bold;
      color: #1b77b4;
      margin-right: 8px;
    }
    .price {
      color: #83261b;
      font-size: 16px;
    }
  }
}
</style>
