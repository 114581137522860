<template>
  <div>
    <div v-if="!role" class="font-mini text-muted content-center">要编辑角色的UI，请先选择一个角色。</div>
    <div v-else class="main-content">
      <div class="head left-right-content" style="align-items: center">
        <div class="full">
          <span class="font-bold font-large text-danger">UI分配</span>
        </div>
        <div>
          <a-button @click="handleUiEditorOpen(null)">新增UI</a-button>
        </div>
      </div>
      <div class="ui-list">
        <div class="pr-group" v-for="g in uiList" :key="g.id">
          <div class="title">
            <a-icon type="container" />
            <span v-if="g.id === 'pcm'">PC端菜单</span>
            <span v-else-if="g.id === 'pcu'">PC端UI</span>
            <span v-else-if="g.id === 'mbm'">移动端菜单</span>
            <span v-else-if="g.id === 'mbu'">移动端UI</span>
          </div>
          <div class="pr-grid">
            <div class="pr-grid-item" v-for="r in g.uis" :key="r.id">
              <div class="checkbox"><a-checkbox v-model="r.applied" @change="handleRoleUiApplyChanged($event, r)"></a-checkbox></div>
              <div class="name">
                {{r.name}}（{{r.code}}）
                <span class="edit-btn">
                  <a-button size="small" type="link" @click="handleUiEditorOpen(r)">编辑</a-button>
                  <a-button size="small" type="link" @click="handleDeleteUi(r)">删除</a-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
        ref="uiEditor"
        title="系统菜单UI编辑"
        :loading="uiEditor.updating"
        @ok="handleUiEditorSave"
        @closed="handleUiEditorClose"
    >
      <a-form-model ref="form" :model="uiEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="编码" class="app_required-input">
          <a-input v-model="uiEditor.form.code"/>
        </a-form-model-item>
        <a-form-model-item label="名称" class="app_required-input">
          <a-input v-model="uiEditor.form.name"/>
        </a-form-model-item>
        <a-form-model-item label="类型" class="app_required-input">
          <a-select v-model="uiEditor.form.type" style="width: 100%;" :disabled="uiEditor.isUpdate">
            <a-select-option value="pcm">PC端菜单</a-select-option>
            <a-select-option value="pcu">PC端UI</a-select-option>
            <a-select-option value="mbm">移动端菜单</a-select-option>
            <a-select-option value="mbu">移动端UI</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>

import RoleModel from '@/data/model/role'
import { updateRoleUiJson, getRoleUiJson, applyUiToRole, removeUiFromRole, listRoleUiCodeList } from '@/http/api/role-ui'
import kit from '@/utils/kit'

export default {
  props: {
    role: RoleModel
  },
  data () {
    return {
      /*
        [
          { id: 'pcm', uis: [ { code: 'abc', name: '用户管理', type: 'pcm' } ] }
        ]
       */
      uiList: [],
      uiEditor: {
        updating: false,
        isUpdate: false,
        form: {
          code: null,
          name: null,
          type: 'pcm'
        }
      }
    }
  },
  computed: {
    uiTypeMap () {
      const map = {}
      for (const g of this.uiList) {
        map[g.id] = g
      }
      return map
    },
    allUis () {
      const arr = []
      for (const g of this.uiList) {
        for (const item of g.uis) {
          arr.push(item)
        }
      }
      return arr
    },
    allUiMap () {
      const map = {}
      for (const ui of this.allUis) {
        map[ui.type + ':' + ui.code] = ui
      }
      return map
    }
  },
  watch: {
    role () {
      for (const ui of this.allUis) {
        ui.applied = false
      }
      if (this.role) {
        this.loadUiCodeByRole(this.role)
      }
    }
  },
  methods: {
    copyUiList () {
      const list = []
      for (const g of this.uiList) {
        const group = {
          id: g.id,
          uis: []
        }
        for (const ui of g.uis) {
          group.uis.push({
            code: ui.code,
            name: ui.name,
            type: ui.type
          })
        }
        list.push(group)
      }
      return list
    },
    handleUiEditorOpen (data) {
      this.uiEditor.isUpdate = !!data
      if (data) {
        Object.assign(this.uiEditor.form, data)
      } else {
        this.uiEditor.form.code = null
        this.uiEditor.form.name = null
      }
      this.$refs.uiEditor.open()
    },
    handleUiEditorSave () {
      const ui = Object.assign({}, this.uiEditor.form)
      let g = this.uiTypeMap[ui.type]
      if (!g) {
        g = { id: ui.type, uis: [], applied: false }
        this.uiList.push(g)
      }
      if (this.uiEditor.isUpdate) {
        kit.arr.pushOrReplace(g.uis, ui, 'code')
      } else {
        if (g.uis.find(item => item.code === ui.code)) {
          return this.$message.error('编码已经存在。')
        }
        g.uis.push(ui)
      }
      this.uiEditor.updating = true
      updateRoleUiJson()
        .complete(() => (this.uiEditor.updating = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.$refs.uiEditor.close()
          })
        })
        .send(JSON.stringify(this.copyUiList()))
    },
    handleUiEditorClose () {

    },
    handleDeleteUi (ui) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${ui.name}】吗？`,
        onOk: () => {
          const list = this.uiTypeMap[ui.type].uis
          kit.arr.removeItem(list, ui)
          updateRoleUiJson()
            .success(() => {
              this.$message.success('保存成功。')
            })
            .send(JSON.stringify(this.copyUiList()))
        }
      })
    },
    handleRoleUiApplyChanged (e, ui) {
      if (e.target.checked) {
        applyUiToRole()
          .success(() => (this.$message.success('操作成功。')))
          .send(this.role.id, ui.code, ui.type)
      } else {
        removeUiFromRole()
          .success(() => (this.$message.success('操作成功。')))
          .send(this.role.id, ui.code)
      }
    },
    loadUiCodeByRole (role) {
      listRoleUiCodeList()
        .success(resp => {
          for (const code of resp.data) {
            if (this.allUiMap[code]) this.allUiMap[code].applied = true
          }
        })
        .send(role.id)
    }
  },
  mounted () {
    getRoleUiJson()
      .success(resp => {
        if (resp.data) {
          for (const g of resp.data) {
            for (const item of g.uis) {
              item.applied = false
            }
            g.uis.sort((a, b) => a.name.localeCompare(b.name))
          }
          this.uiList = resp.data
        }
      })
      .send()
  }
}
</script>

<style lang="less" scoped>
.main-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .head {
    padding: 4px 0;
    border-bottom: solid 1px #eee;
  }
}

.ui-list {
  flex: 1;
  margin-top: 20px;
  overflow-y: auto;
  .pr-group + .pr-group {
    margin-top: 20px;
  }
  .pr-group {
    border: solid 1px #eee;
    &>.title {
      padding: 8px;
      font-size: 16px;
      border-bottom: solid 1px #eee;
      background-color: #f9f9f9;
    }
    .pr-grid {
      //display: grid;
      padding: 14px;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
      .pr-grid-item {
        display: flex;
        .name {
          flex: 1;
          margin-left: 10px;
          .disabled {
            text-decoration: line-through;
            color: #80181b;
          }
          .edit-btn {
            visibility: hidden;
          }
        }
        &:hover .name .edit-btn {
          visibility: visible;
        }
      }
    }
  }
}

</style>
