<template>
  <div class="file-container">
    <div class="toolbar">
      <div class="buttons">
        <a-button @click="handleUploadFile">
          <a-icon type="cloud-upload" />
          上传
        </a-button>
        <a-button @click="handleFilesMoveTo" :disabled="filesChecked.length === 0">
          <a-icon type="folder-open" />
          移动到...
        </a-button>
        <a-button type="danger" @click="handleDeleteChecked" :disabled="filesChecked.length === 0 || hasFileDeleting">删除</a-button>
        <a-dropdown-button
            @click="handleSelectMenuClick({ key: 'all' })"
            style="margin: 0 8px;"
        >
          全选
          <a-menu slot="overlay" @click="handleSelectMenuClick" :style="{zIndex: zIndex + 200}">
            <a-menu-item key="clear" :disabled="selectMode === 'single'">清空选择</a-menu-item>
            <a-menu-item key="reverse" :disabled="selectMode === 'single'">反选</a-menu-item>
          </a-menu>
          <a-icon slot="icon" type="down" />
        </a-dropdown-button>
        <a-button type="primary" @click="handleConfirmFileSelected" v-show="selectMode !== 'none'">确定选择</a-button>
      </div>
      <div class="search">
        <a-input-search
            placeholder="文件名关键字"
            style="width: 400px; font-size: 12px;"
            v-model="searchParams.name"
            enter-button
            @search="loadData"
            :allowClear="true"
        />
      </div>
    </div>
    <div class="file-list">
      <loading :loading="loading">正在加载...</loading>
      <div class="file-item" v-for="f in files" :key="f.id">
        <div class="cover" :style="{'background-image': `url(${f.thumbnailUrl})`}">
          <div class="overlay" @click="handleFileItemClick(f)" :class="{checked: f._checked}">
            <div class="checkbox">
              <a-checkbox :checked="f._checked"></a-checkbox>
            </div>
            <div class="operation" @click.stop.prevent="">
              <a-button size="small" type="danger" @click="handleDeleteFile(f)">删除</a-button>
              <a-button size="small" @click="$emit('editClick', f)">详情</a-button>
            </div>
          </div>
          <div class="deleting-overlay" v-show="f._deleting">
            <a-icon type="loading" style="margin-right: 5px;" /> 正在删除
          </div>
        </div>
        <div class="name">{{f.name}}</div>
      </div>
    </div>
    <div class="pagination">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
    <a-modal
        title="移动文件"
        v-model="folderSelector.visible"
        :confirm-loading="folderSelector.loading"
        :closable="folderSelectorDialogClosable"
        :maskClosable="folderSelectorDialogClosable"
        :keyboard="folderSelectorDialogClosable"
        @ok="handleMoveFilesToFolder"
        @cancel="handleMoveFilesToFolderCancel"
        :z-index="zIndex + 5"
    >
      <a-tree-select
          v-model="folderSelector.selectedFolderId"
          style="width: 100%"
          :replace-fields="{ title: 'name', key: 'id', value: 'id' }"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto', zIndex: zIndex + 100 }"
          :tree-data="folderSelector.folders"
      >
      </a-tree-select>
    </a-modal>
  </div>
</template>

<script>
import { listFiles, deleteFiles, moveFilesToFolder } from '@/http/api/file'
import PaginationMixin from '@/mixins/pagination'
import kit from '@/utils/kit'
import { listFolderByTree } from '@/http/api/folder'
import FileItem from '@/model/file-item'
import { FILE_MANAGER_Z_INDEX } from '@/components-business/file-manager/index'

export default {
  props: {
    /**
     * 左侧目录树选中的目录
     * {
     *   folder: {Object}, 左侧目录列表选中的文件夹
     *   allChildrenIds: {Array} 选中目录下的所有子目录id（不包含选中目录本身）
     * }
     */
    selectedFolder: { type: Object },
    /**
     * 文件选择模式，出现一个按钮，将选中的文件返回给其他组件。
     * none 不是选择模式
     * single 单选
     * multi 多选
     */
    selectMode: { type: String, default: 'none' },
    defaultSelectedFileIds: { type: Array }
  },
  mixins: [PaginationMixin],
  data () {
    return {
      files: [],
      loading: false,
      zIndex: FILE_MANAGER_Z_INDEX,
      searchParams: {
        name: null
      },
      /**
       * 点击"移动目录"按钮后，弹出目录选择对话框，该对象的属性都是关于这个对话框的
       */
      folderSelector: {
        visible: false,
        loading: false,
        folders: [], // 目录列表，从this.getFolderTreeData()获取
        selectedFolderId: null // 列表选中的目标目录id
      }
    }
  },
  computed: {
    isFolderHasSelected () {
      return !!this.selectedFolder
    },
    filesChecked () {
      return this.files.filter(f => f._checked)
    },
    fileIdsChecked () {
      return this.filesChecked.map(f => f.id)
    },
    hasFileDeleting () {
      return this.files.filter(f => f._deleting).length > 0
    },
    folderSelectorDialogClosable () {
      return !this.folderSelector.loading
    }
  },
  watch: {
    selectedFolder () {
      this.reloadData()
    },
    'folderSelector.visible' (v) {
      if (!v) {
        this.folderSelector.selectedFolderId = null
        this.folderSelector.folders = []
      }
    }
  },
  methods: {
    initFile (files) {
      let tmp = files
      if (!Array.isArray(files)) {
        tmp = [files]
      }
      const arr = []
      for (const f of tmp) {
        const fileItem = new FileItem(f)
        f._checked = this.defaultSelectedFileIds.includes(f.id)
        arr.push(fileItem)
      }
      return arr
    },
    insertFiles (files) {
      if (!Array.isArray(files)) {
        files = [files]
      }
      this.files = [...this.initFile(files), ...this.files]
    },
    loadOnDialogOpen () {
      if (this.files.length === 0) {
        this.reloadData()
      }
    },
    loadData () {
      const params = this.buildRequestParams(this.searchParams)
      if (this.selectedFolder) {
        params.folderId = this.selectedFolder.folder.id
      }
      this.loading = true
      listFiles()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.files = this.initFile(resp.data.records)
        })
        .send(params)
    },
    handleUploadFile () {
      if (this.isFolderHasSelected) {
        this.$emit('uploadClick')
      } else {
        this.$message.warn('请在左侧选中一个目录再上传。')
      }
    },
    handleDeleteChecked () {
      if (this.filesChecked.length === 0) {
        this.$message.warn('请选择要删除的文件。')
      } else {
        this.$confirm({
          title: '删除确认',
          content: `确定要删除选中的${this.filesChecked.length}个文件吗？`,
          zIndex: FILE_MANAGER_Z_INDEX + 1,
          onOk: () => {
            this.deleteFiles(this.filesChecked)
          }
        })
      }
    },
    handleDeleteFile (file) {
      this.$confirm({
        title: '删除确认',
        content: `确定要删除[${file.name}]文件吗？`,
        zIndex: FILE_MANAGER_Z_INDEX + 1,
        onOk: () => {
          this.deleteFiles([file])
        }
      })
    },
    deleteFiles (fileList) {
      fileList.forEach(f => (f._deleting = true))
      deleteFiles()
        .complete(() => {
          fileList.forEach(f => (f._deleting = false))
        })
        .success(() => {
          this.$message.success('文件已删除。')
          for (const file of fileList) {
            kit.arr.removeItem(this.files, file)
          }
          if (this.files.length === 0) {
            this.reloadData()
          }
        })
        .send(fileList.map(f => f.id))
    },
    handleFilesMoveTo () {
      listFolderByTree()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.folderSelector.folders = resp.data
        })
        .send()
      this.folderSelector.visible = true
    },
    handleMoveFilesToFolder () {
      let currentFolderId
      if (this.selectedFolder) {
        currentFolderId = this.selectedFolder.folder.id
      }
      if (this.folderSelector.selectedFolderId === currentFolderId) {
        this.$message.warn('请选择一个不同的目录。')
      } else if (this.folderSelector.selectedFolderId) {
        this.folderSelector.loading = true
        moveFilesToFolder()
          .complete(() => (this.folderSelector.loading = false))
          .success(() => {
            this.$message.success('文件已经移动到其他目录。')
            if (
              this.selectedFolder &&
              !this.selectedFolder.allChildrenIds.includes(this.folderSelector.selectedFolderId)
            ) {
              // 当左侧目录树有选中的目录时，移动的新目录不是左侧选中的子目录就从当前页面移除这些文件
              for (const id of [...this.fileIdsChecked]) {
                kit.arr.remove(this.files, { id: id })
              }
            }
            this.folderSelector.visible = false
          })
          .send(this.fileIdsChecked, this.folderSelector.selectedFolderId)
      } else {
        this.$message.warn('请选择要移动的目录')
      }
    },
    handleMoveFilesToFolderCancel () {
      if (!this.folderSelector.loading) {
        this.folderSelector.visible = false
      }
    },
    handleConfirmFileSelected () {
      if (this.filesChecked.length > 0) {
        const arr = []
        this.filesChecked.forEach(data => {
          arr.push(new FileItem(data))
        })
        this.$emit('selected', arr)
      } else {
        this.$message.warn('还没有选择任何文件。')
      }
    },
    handleSelectMenuClick (e) {
      switch (e.key) {
        case 'clear':
          this.files.forEach(f => {
            f._checked = false
          })
          break
        case 'all':
          this.files.forEach(f => {
            f._checked = true
          })
          break
        case 'reverse':
          var checkedFileIds = this.filesChecked.map(f => f.id)
          this.files.forEach(f => {
            f._checked = !checkedFileIds.includes(f.id)
          })
          break
      }
    },
    handleFileItemClick (file) {
      if (this.selectMode === 'single') {
        if (!file._checked) {
          if (this.filesChecked.length > 0) {
            this.filesChecked[0]._checked = false
          }
        }
      }
      file._checked = !file._checked
      if (!file._checked) {
        kit.arr.removeItem(this.defaultSelectedFileIds, file.id)
      }
    },
    reset () {
      this.files.forEach(item => {
        item._checked = false
      })
    },
    onDialogOpen () {
      if (this.files.length > 0) {
        this.files.forEach(f => {
          if (this.defaultSelectedFileIds.includes(f.id)) {
            f._checked = true
          }
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  .file-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  .toolbar {
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .buttons {
      flex: 1;
    }
    .search {
      padding: 0 20px;
    }
  }
  .file-list {
    position: relative;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    margin: 10px 0;
    .file-item {
      @width: 150px;
      width: @width;
      margin: 8px;
      .cover {
        position: relative;
        width: @width;
        height: @width;
        border: solid 1px #d0d0d0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        .overlay {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0,0,0,.5);
          &:not(.checked) {
            display: none;
          }
          .checkbox {
            margin: 5px 0 0 5px;
          }
          .operation {
            display: none;
            position: absolute;
            width: 100%;
            bottom: 10px;
            text-align: center;
          }
        }
        &:hover .overlay {
          display: block;
          .operation {
            display: block;
          }
        }
        .deleting-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          background-color: rgba(0,0,0,.6);
          color: #fff;
        }
      }
      .name {
        text-align: center;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .pagination {
    height: 54px;
  }
</style>
