import defineApi from '../define-api'
import http from '../application-api'

export const listByStockProductId = defineApi((config, stockProductId) => {
  config.params = {
    stockProductId: stockProductId
  }
  http.get('/stock/cost/list/by_stock_product_id', config)
})

export const updateCost = defineApi((config, stockProductId, supplierId, price) => {
  config.params = {
    stockProductId: stockProductId,
    supplierId: supplierId,
    price: price
  }
  http.post('/stock/cost/update', config)
})

/**
 * 使用商品ID查询其参考单价。
 * @param idList {Array}
 * @param [optSupplierId] 如果提供，则获取指定供应商的单价，否则获取通用单价。
 * @return { id: cost }
 */
export const getReferCostByStockProductIdList = defineApi((config, idList, optSupplierId) => {
  config.data = {
    idList: idList,
    supplierId: optSupplierId
  }
  http.post('/stock/cost/list/by_stock_product_id_list', config)
})

/**
 * 使用商品SKU查询其参考单价。
 * @param idList {Array}
 * @param [optSupplierId] 如果提供，则获取指定供应商的单价，否则获取通用单价。
 * @return { sku: cost }
 */
export const getReferCostBySkuList = defineApi((config, skuList, optSupplierId) => {
  config.params = {
    skuList: skuList.join(','),
    optSupplierId: optSupplierId
  }
  http.get('/stock/cost/list/by_sku_list', config)
})
