import defineApi from '../define-api'
import http from '../application-api'

export const listStockCheck = defineApi((config) => {
  http.get('/stock_check/list', config)
})

export const getById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/stock_check/get_by_id', config)
})

export const addStockCheck = defineApi((config, data) => {
  config.data = data
  http.post('/stock_check/add', config)
})

export const updateStockCheck = defineApi((config, data) => {
  config.data = data
  http.post('/stock_check/update', config)
})

export const listStockCheckItems = defineApi((config, stockCheckId) => {
  config.params = {
    stockCheckId: stockCheckId
  }
  http.get('/stock_check/list/items', config)
})

export const checkSku = defineApi((config, stockCheckId, sku, groupName) => {
  config.params = {
    stockCheckId: stockCheckId,
    sku: sku,
    groupName: groupName
  }
  http.post('/stock_check/check_sku', config)
})

export const updateCheckItemTotal = defineApi((config, id, total, groupName) => {
  config.params = {
    id: id,
    total: total,
    groupName: groupName
  }
  http.post('/stock_check/update/check_item', config)
})

export const updateStockInit = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/stock_check/update/stock_init', config)
})
