import defineApi from '../define-api'
import http from '../application-api'

export const listSender = defineApi((config) => {
  http.get('/edm/sender/list', config)
})

export const addSender = defineApi((config, data) => {
  config.data = data
  http.post('/edm/sender/add', config)
})

export const updateSender = defineApi((config, data) => {
  config.data = data
  http.post('/edm/sender/update', config)
})

export const deleteSender = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/edm/sender/delete', config)
})
