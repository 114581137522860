import defineApi from '../define-api'
import http, { buildUrlWithToken } from '../application-api'

export const listOrders = defineApi((config, params) => {
  config.params = params
  http.get('/order/list', config)
})

export const listOrdersByOrderNo = defineApi((config, orderNos) => {
  config.params = { orderNos: orderNos.join(',') }
  http.get('/order/list/by_order_no', config)
})

export const exportOrders = function (params) {
  return buildUrlWithToken('/order/list/export', false, params)
}

export const listSimpleByEmail = defineApi((config, email) => {
  config.params = {
    email: email
  }
  http.get('/order/list/simple/by_email', config)
})

export const getOrderDetail = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/order/detail', config)
})

export const updateAdminRemark = defineApi((config, id, remark) => {
  config.params = {
    id: id,
    remark: remark
  }
  http.post('/order/update/admin_remark', config)
})

export const getPriceSummaryGroupByShop = defineApi((config, startDate, endDate) => {
  config.params = {
    startDate: startDate,
    endDate: endDate
  }
  http.get('/order/price/summary/group_shop', config)
})

export const getTodayOrderAmounts = defineApi(config => {
  http.get('/order/today/amount', config)
})

export const getOrderAmounts = defineApi(config => {
  http.get('/order/sale/amount', config)
})

export const syncFromShop = defineApi((config, shopId, orderIds) => {
  config.timeout = 1000 * 60
  config.params = {
    shopId: shopId,
    orderIds: orderIds
  }
  http.post('/order/sync_from_shop', config)
})

export const getOrderProductsByOrderNo = defineApi((config, orderNo) => {
  config.params = { orderNo: orderNo }
  http.get('/order/products/by_order_no', config)
})

export const addInternalOrder = defineApi((config, data) => {
  config.data = data
  http.post('/order/add/internal', config)
})

export const updateInternalOrder = defineApi((config, data) => {
  config.data = data
  http.post('/order/update/internal', config)
})


export const updateAllShipStatus = defineApi((config, id, allShip) => {
  config.params = {
    id: id,
    allShip: allShip
  }
  http.post('/order/update/all_ship', config)
})


export const getImportableShops = defineApi((config) => {
  http.get('/order/importable_shops', config)
})

export const importOrderPreProcess = defineApi((config, shopId, file) => {
  const form = new FormData()
  form.append('file', file)
  form.append('shopId', shopId)
  config.data = form
  http.post('/order/import/preprocess', config)
})

export const importOrderSubmit = defineApi((config, orders) => {
  config.data = orders
  http.post('/order/import/submit', config)
})


export const previewCommissionUpdate = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/order/import/commission/preview', config)
})

/**
 * @param list [{id:string, number: double}]
 */
export const commissionUpdate = defineApi((config, list) => {
  config.data = list
  http.post('/order/import/commission', config)
})
