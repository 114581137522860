import defineApi from '../define-api'
import http from '../application-api'


export const importData = defineApi((config, file, partnerId, batchId, platform) => {
  const form = new FormData()
  form.append('file', file)
  form.append('partnerId', new Blob([partnerId], { type: 'text/plain' }))
  form.append('batchId', new Blob([batchId], { type: 'text/plain' }))
  form.append('platform', new Blob([platform], { type: 'text/plain' }))
  config.data = form
  http.post('/partner_ads_cost/import', config)
})

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/partner_ads_cost/list', config)
})
