import kit from '@/utils/kit'


// 库存变更类型

export const INVENTORY_CHANGE_TYPE_IN_STOCK = 'in'
export const INVENTORY_CHANGE_TYPE_DELETE = 'delete'
export const INVENTORY_CHANGE_TYPE_TRANSFER = 'transfer'

export const INVENTORY_CHANGE_TYPE_MAPPING = {
  [INVENTORY_CHANGE_TYPE_IN_STOCK]: '入库',
  [INVENTORY_CHANGE_TYPE_DELETE]: '删除库存',
  [INVENTORY_CHANGE_TYPE_TRANSFER]: '转仓'
}

export const INVENTORY_CHANGE_TYPE_LIST = kit.obj.toArray(INVENTORY_CHANGE_TYPE_MAPPING)





// 商品库存状态
export const INVENTORY_CHANGE_STATUS_ON_WAY = 'onWay'
export const INVENTORY_CHANGE_STATUS_IN_STOCK = 'inStock'
export const INVENTORY_CHANGE_STATUS_DELETE = 'delete'

export const INVENTORY_CHANGE_STATUS_MAPPING = {
  [INVENTORY_CHANGE_STATUS_ON_WAY]: '在途',
  [INVENTORY_CHANGE_STATUS_IN_STOCK]: '已入库',
  [INVENTORY_CHANGE_STATUS_DELETE]: '作废'
}

export const INVENTORY_CHANGE_STATUS_LIST = kit.obj.toArray(INVENTORY_CHANGE_STATUS_MAPPING)
