import defineApi from '../define-api'
import http from '../application-api'

export const listOrders = defineApi((config, params) => {
  config.params = params
  http.get('/order_demo/list', config)
})

export const verify = defineApi((config, id, statusCheck, statusCheckMessage) => {
  config.params = {
    id: id,
    status: statusCheck,
    message: statusCheckMessage
  }
  http.post('/order_demo/verify', config)
})
