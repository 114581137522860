<template>
  <communication-editor :visible.sync="visible" title="提醒" :loading="loading" @submit="handleSave()">
    <a-alert message="到了提醒时间，在达人查询页面的【提醒】标签下可看到记录。" style="width: 80%; margin: 0 auto 20px auto;" type="info" show-icon />

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="日期" class="app_required-input">
        <a-date-picker v-model="form.notifyDate" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 150px;"/>
      </a-form-model-item>
      <a-form-model-item label="时间" class="app_required-input">
        <a-time-picker v-model="form.notifyTime" format="HH:mm" valueFormat="HH:mm" style="width: 150px;" />
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
      </a-form-model-item>
    </a-form-model>
  </communication-editor>
</template>

<script>
import CommunicationEditor from '@/pages/influencer/detail/comm-comp/communication-editor'
import CommunicationMixin from '@/pages/influencer/detail/comm-comp/communication-mixin'
import { TYPE_NOTIFY } from '@/constants/communication-type'

export default {
  components: { CommunicationEditor },
  mixins: [CommunicationMixin],
  data () {
    return {
      type: TYPE_NOTIFY,
      form: {
        notifyDate: null,
        notifyTime: null,
        remark: null
      }
    }
  },
  methods: {
    getContent () {
      return JSON.stringify(this.form)
    },
    onHide () {
      this.form.notifyDate = null
      this.form.notifyTime = null
      this.form.remark = null
    },
    handleBeforeSave () {
      if (!this.form.notifyDate || !this.form.notifyTime) {
        this.$message.error('请选择日期和时间。')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
