<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" type="primary" @click="handleUpdateProductCost">更新成本</a-button>
    <a-button slot="toolbar" @click="handleExportProduct">导出清单</a-button>

    <div slot="center" class="main-content">
      <div class="left-panel card-panel">
        <div class="header">
          <div class="title">申请表单</div>
        </div>

        <div class="body" v-if="inventoryChangeRequest">
          <a-form>
            <a-form-item label="标题" class="app_required-input">
              <a-input v-model="inventoryChangeRequest.title" :disabled="inventoryChangeRequestIsDeleted"></a-input>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-model="inventoryChangeRequest.remark" :disabled="inventoryChangeRequestIsDeleted" :auto-size="{ minRows: 2, maxRows: 3 }" :max-length="512"/>
            </a-form-item>
          </a-form>
          <a-divider></a-divider>
          <div class="info-detail">
            <div class="detail-item">
              <label>申请编号</label>
              <span>{{inventoryChangeRequest.reqId}}</span>
            </div>
            <div class="detail-item">
              <label>申请日期</label>
              <span>{{inventoryChangeRequest.createDate}}</span>
            </div>
            <div class="detail-item">
              <label>申请人</label>
              <span>{{inventoryChangeRequest.createUserName}}</span>
            </div>
            <div class="detail-item">
              <label>变更类型</label>
              <span>{{inventoryChangeTypeTextMap[inventoryChangeRequest.changeType]}}</span>
            </div>
            <div class="detail-item">
              <label>供应商</label>
              <span>{{inventoryChangeRequest.supplierName}}</span>
            </div>
            <div class="detail-item" v-if="inventoryChangeTypeConstants.del !== inventoryChangeRequest.changeType">
              <label>入库状态</label>
              <span class="inventory-status" :class="'status-' + inventoryChangeRequest.inventoryStatus">{{inventoryChangeStatusTextMap[inventoryChangeRequest.inventoryStatus]}}</span>
            </div>
            <div class="detail-item" v-if="inventoryChangeTypeConstants.del !== inventoryChangeRequest.changeType">
              <label>出库仓库</label>
              <span>{{inventoryChangeRequest.fromWarehouseName}}</span>
            </div>
            <div class="detail-item">
              <label>入库仓库</label>
              <span>{{inventoryChangeRequest.toWarehouseName}}</span>
            </div>
            <div class="detail-item">
              <label>运费</label>
              <span>{{inventoryChangeRequest.freight}}</span>
            </div>
          </div>

          <div style="display: flex; text-align: center; margin-top: 10px;" v-if="!inventoryChangeRequestIsDeleted">
            <a-button style="flex: 1;" type="primary" @click="handleUpdateInventoryChangeRequest">更新</a-button>
            <a-button style="flex: 1;" type="danger" @click="handleDeleteInventoryChangeRequest" v-if="inventoryChangeTypeConstants.inStock === inventoryChangeRequest.changeType">作废</a-button>
            <a-tooltip v-if="inventoryChangeStatusConstants.onWay === inventoryChangeRequest.inventoryStatus">
              <template slot="title">将在途库存入库，变为可用库存。</template>
              <a-button style="flex: 1;" @click="handleUpdateOnWay2InStock">入库</a-button>
            </a-tooltip>
          </div>
        </div>

      </div>


      <div class="data-table">
        <a-table
            size="middle"
            rowKey="id"
            :columns="dataColumns"
            :data-source="dataList"
            :pagination="false"
            :scroll="{ y: antdTableY }"
            :loading="dataLoading"
        >
          <div slot="image" slot-scope="imageUrl">
            <div class="image-item" v-lazy:background-image="imageUrl"></div>
          </div>

          <div slot="cost" slot-scope="row">
            <a-input-number v-model="row.cost" :min="0" :max="10000" :precision="2" style="width: 130px;"></a-input-number>
          </div>
        </a-table>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  listProducts,
  getById,
  updateInventoryChangeRequest,
  deleteById as deleteInventoryChangeRequestById,
  updateOnWay2InStock,
  updateProductCost,
  buildExportProductsUrl
} from '@/http/api/inventory-change-request'
import {
  INVENTORY_CHANGE_TYPE_IN_STOCK,
  INVENTORY_CHANGE_TYPE_TRANSFER,
  INVENTORY_CHANGE_TYPE_DELETE,
  INVENTORY_CHANGE_TYPE_MAPPING,
  INVENTORY_CHANGE_STATUS_MAPPING,
  INVENTORY_CHANGE_STATUS_ON_WAY,
  INVENTORY_CHANGE_STATUS_DELETE
} from '@/constants/inventory-change-request'

export default {
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS,
      pageLoading: false,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '图片', width: 90, dataIndex: 'imageUrl', scopedSlots: { customRender: 'image' } },
        { title: 'SKU', width: 180, dataIndex: 'sku' },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '仓库', dataIndex: 'warehouseName' },
        { title: '成本/件', scopedSlots: { customRender: 'cost' } },
        { title: '本地运费/件', width: 120, dataIndex: 'freightLocal' },
        { title: '头程运费/件', width: 120, dataIndex: 'freightOut' }
      ],
      inventoryChangeRequest: null,
      inventoryChangeTypeConstants: {
        inStock: INVENTORY_CHANGE_TYPE_IN_STOCK,
        del: INVENTORY_CHANGE_TYPE_DELETE,
        transfer: INVENTORY_CHANGE_TYPE_TRANSFER
      },
      inventoryChangeStatusConstants: {
        onWay: INVENTORY_CHANGE_STATUS_ON_WAY,
        delete: INVENTORY_CHANGE_STATUS_DELETE
      },
      inventoryChangeTypeTextMap: INVENTORY_CHANGE_TYPE_MAPPING,
      inventoryChangeStatusTextMap: INVENTORY_CHANGE_STATUS_MAPPING
    }
  },
  computed: {
    inventoryChangeRequestId () {
      return this.$route.params.id
    },
    inventoryChangeRequestIsDeleted () {
      if (!this.inventoryChangeRequest) return true
      return this.inventoryChangeRequest.inventoryStatus === this.inventoryChangeStatusConstants.delete
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listProducts()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.inventoryChangeRequestId)
    },
    loadAll () {
      getById().success(resp => (this.inventoryChangeRequest = resp.data)).send(this.inventoryChangeRequestId)
      this.loadData()
    },
    handleUpdateInventoryChangeRequest () {
      this.$confirm({
        title: '确认',
        content: '确定要更新吗？',
        onOk: () => {
          this.pageLoading = true
          updateInventoryChangeRequest()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('更新成功。')
              this.loadAll()
            })
            .send(
              this.inventoryChangeRequest.id,
              this.inventoryChangeRequest.title,
              this.inventoryChangeRequest.remark
            )
        }
      })
    },
    handleDeleteInventoryChangeRequest () {
      this.$confirm({
        title: '确认',
        content: '确定要作废此申请表单吗？',
        onOk: () => {
          this.pageLoading = true
          deleteInventoryChangeRequestById()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('表单已作废。')
              this.loadAll()
            })
            .send(this.inventoryChangeRequestId)
        }
      })
    },
    handleUpdateOnWay2InStock () {
      this.$confirm({
        title: '确认',
        content: '确定要操作吗？',
        onOk: () => {
          this.pageLoading = true
          updateOnWay2InStock()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('操作成功。')
              this.loadAll()
            })
            .send(this.inventoryChangeRequestId)
        }
      })
    },
    handleUpdateProductCost () {
      this.$confirm({
        title: '确认',
        content: '确定要更新吗？',
        onOk: () => {
          const costList = []
          for (const item of this.dataList) {
            costList.push({ id: item.stockProductId, number: item.cost })
          }
          this.pageLoading = true
          updateProductCost()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('更新成功。')
            })
            .send(this.inventoryChangeRequestId, costList)
        }
      })
    },
    handleExportProduct () {
      window.open(buildExportProductsUrl(this.inventoryChangeRequestId))
    }
  },
  mounted () {
    this.loadAll()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.main-content {
  display: flex;
}
.left-panel {
  width: 340px;
  margin-right: 10px;
  .inventory-status {
    font-weight: bold;
    &.status-onWay {
      color: #b05d07;
    }
    &.status-inStock {
      color: #328303;
    }
    &.status-delete {
      color: #d72e34;
    }
  }
}
.left-panel @{deep} .ant-form-item {
  margin-bottom: 5px;
}


.data-table {
  flex: 1;
}
</style>
