<!-- 达人基本信息面板 -->
<template>
  <div v-if="influencer">
    <div class="info-detail">
      <div class="detail-item">
        <label>邮箱</label>
        <span>{{influencer.email}}</span>
      </div>

      <div class="detail-item">
        <label>Handle</label>
        <span>{{influencer.cid}}</span>
      </div>

      <div class="detail-item">
        <label>昵称</label>
        <span>{{influencer.name}}</span>
      </div>

      <div class="detail-item">
        <label>性别</label>
        <span>{{influencer.sex === 1 ? '男' : (influencer.sex === '0' ? '女' : '')}}</span>
      </div>

      <div class="detail-item">
        <label>主页</label>
        <span><a :href="influencer.homePage" target="_blank">{{influencer.homePage}}</a></span>
      </div>

      <div class="detail-item">
        <label>分类</label>
        <span>{{influencer.influencerCategoryName}}</span>
      </div>

      <div class="detail-item">
        <label>标签</label>
        <span>{{influencer.tags}}</span>
      </div>

      <div class="detail-item">
        <label>平台</label>
        <span>{{influencer.platform}}</span>
      </div>

      <div class="detail-item">
        <label>创建日期</label>
        <span>{{influencer.createTime}}</span>
      </div>

      <div class="detail-item">
        <label>成就</label>
        <span>
            <a-tag color="green">粉丝数：{{influencer.followerCount | fmtNumber}}</a-tag>
            <a-tag color="cyan">平均播放量：{{influencer.viewCount | fmtNumber}}</a-tag>
            <a-tag color="red">成交金额：¥{{influencer.transactionAmount}}</a-tag>
          </span>
      </div>

      <div class="detail-item">
        <label>备注</label>
        <span>{{influencer.remark}}</span>
      </div>
    </div>

    <div style="text-align: right;">
      <a-button type="link" @click="$refs.influencerEditor.open(influencer)">编辑</a-button>
    </div>

    <a-divider orientation="left">合作意向评分</a-divider>
    <div class="cooperation-score-list">
      <div class="score" :class="{ 'selected': s === cooperationScore}" v-for="s in scoreList" :key="s" @click="cooperationScore = s">
        {{s}}
        <span class="current-icon">
          <a-icon type="caret-up" />
        </span>
      </div>
    </div>
    <div class="cooperation-score-remark">
      <a-textarea
          v-model="cooperationScoreRemark" placeholder="评分描述" :auto-size="{ minRows: 3, maxRows: 10 }" :max-length="512"
      />
      <div style="margin-top: 10px;" class="left-right-content">
        <span class="full font-mini">{{(cooperationScoreRemark || '').length}} / 512</span>
        <a-button size="small" type="primary" @click="handleUpdateCooperationScore">保存评价</a-button>
      </div>
    </div>

    <a-divider orientation="left">合作产品</a-divider>

    <a-button type="link" @click="handleOpenStockProductSelector">设置合作产品</a-button>

    <ul class="list-group">
      <li class="list-group-item" v-for="c in cooperationProductList" :key="c.id">
        <div class="coop-product-item">
          <div class="dept">{{c.deptName}}</div>
          <div class="sku-list">
            <div class="sku-item" v-for="p in c.products" :key="p.id">
              <div class="buttons">
                <a-button type="danger" icon="delete" size="small" @click="handleDeleteCooperationProduct(p)"></a-button>
              </div>
              <div class="image-item small" v-lazy:background-image="p.url" @click="$imageModal.open(p.url)"></div>
              <div class="sku">{{p.productSku}}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>



    <influencer-editor ref="influencerEditor" @saved="onInfluencerSaved"></influencer-editor>
    <stock-product-select ref="stockProductSelector"></stock-product-select>
  </div>
</template>

<script>
import InfluencerEditor from '@/pages/influencer/comp/influencer-editor'
import StockProductSelect from '@/components-business/stock-product-select'
import {
  updateScore,
  getCooperationStatusList,
  setCooperationStatus,
  getCooperationProductList,
  addCooperationProduct,
  deleteCooperationProduct
} from '@/http/api/influencer'
import { listOptions as listCooperationStatusOptions } from '@/http/api/cooperation-status'
import kit from '@/utils/kit'

export default {
  components: { InfluencerEditor, StockProductSelect },
  props: {
    influencer: { type: Object, required: false }
  },
  data () {
    return {
      scoreList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cooperationStatusOptions: [],
      deptCooperationStatusList: [],
      cooperationProductList: [],
      cooperationScore: 1,
      cooperationScoreRemark: null
    }
  },
  filters: {
    fmtNumber (n) {
      if (n >= 1000000000) {
        return kit.num.toFixedTrimZero(n / 1000000000, 1) + '亿'
      } else if (n >= 10000) {
        return kit.num.toFixedTrimZero(n / 10000, 1) + '万'
      } else {
        return n
      }
    }
  },
  watch: {
    influencer () {
      if (this.influencer) {
        this.initData()
      }
    }
  },
  methods: {
    initData () {
      listCooperationStatusOptions().success(resp => (this.cooperationStatusOptions = resp.data)).send()
      // this.loadDeptCooperationStatusList()
      this.loadCooperationProductList()
      this.cooperationScoreRemark = this.influencer.cooperationScoreRemark
      this.cooperationScore = this.influencer.cooperationScore
    },
    onInfluencerSaved (data) {
      Object.assign(this.influencer, data)
    },
    handleUpdateCooperationScore () {
      updateScore()
        .success(() => {
          this.influencer.cooperationScore = this.cooperationScore
          this.influencer.cooperationScoreRemark = this.cooperationScoreRemark
          this.$message.success('评分更新成功。')
        })
        .send(this.influencer.id, this.cooperationScore, this.cooperationScoreRemark)
    },
    loadDeptCooperationStatusList () {
      getCooperationStatusList()
        .success(resp => {
          if (resp.data.length === 0) {
            this.deptCooperationStatusList.push({
              deptId: null,
              deptName: '-',
              influencerId: this.influencer.id,
              cooperationStatusId: null
            })
          } else {
            this.deptCooperationStatusList = resp.data
          }
        })
        .send(this.influencer.id)
    },
    onCooperationStatusChanged (item, value) {
      setCooperationStatus()
        .success(() => {
          this.loadDeptCooperationStatusList()
        })
        .send(this.influencer.id, item.deptId, value)
    },
    loadCooperationProductList () {
      getCooperationProductList()
        .success(resp => {
          this.cooperationProductList = resp.data
        })
        .send(this.influencer.id)
    },
    handleOpenStockProductSelector () {
      this.$refs.stockProductSelector.open(selected => {
        const idList = []
        for (const item of selected) {
          idList.push(item.id)
        }
        addCooperationProduct()
          .success(() => {
            this.loadCooperationProductList()
            this.$message.success('设置成功。')
          })
          .send(this.influencer.id, idList.join(','))
      })
    },
    handleDeleteCooperationProduct (item) {
      deleteCooperationProduct()
        .success(() => {
          this.loadCooperationProductList()
          this.$message.success('删除成功。')
        })
        .send(item.id)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.cooperation-score-list {
  display: flex;
  justify-content: center;
  .score + .score {
    margin-left: 10px;
  }
  .score {
    position: relative;
    padding: 4px 10px;
    border: solid 1px #eee;
    border-radius: 5px;
    cursor: pointer;
    &.selected {
      border-color: #a94442;
      color: #a94442;
    }
    .current-icon {
      position: absolute;
      display: none;
      bottom: -20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #969696;
      font-size: 16px;
    }
    &.selected .current-icon {
      display: block;
    }
  }
}
.cooperation-score-remark {
  margin-top: 10px;
  padding: 0 40px;
}


.coop-product-item {
  display: flex;
  align-items: center;
  .dept {
    min-width: 120px;
    max-width: 200px;
    text-align: right;
    padding-right: 10px;
  }
  .sku-list {
    flex: 1;
  }
  .sku-item + .sku-item {
    margin-top: 5px;
  }
  .sku-item {
    display: flex;
    align-items: center;
    .image-item {
      margin: 0 10px;
    }
    .sku {
      flex: 1;
    }
  }
}
</style>
