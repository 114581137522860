import defineApi from '../define-api'
import http from '../application-api'

export const listAll = defineApi((config, withEmailTotal) => {
  config.params = {
    withEmailTotal: withEmailTotal
  }
  http.get('/edm/email_group/list_all', config)
})

export const addGroup = defineApi((config, group) => {
  config.data = group
  http.post('/edm/email_group/add', config)
})

export const updateGroup = defineApi((config, group) => {
  config.data = group
  http.post('/edm/email_group/update', config)
})

export const deleteGroup = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/edm/email_group/delete', config)
})
