<!-- 供应商发货快递 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="toolbar">

    </div>

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="供应商">
          <a-select v-model="searchParams.supplierId" :allow-clear="true" style="width: 150px;">
            <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="快递单号">
          <a-input v-model="searchParams.trackNo" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="发货日期">
          <a-range-picker
              v-model="searchParams.shipDates"
              :ranges="dateRanges"
              value-format="YYYY-MM-DD"
              style="width: 220px;"
          >
          </a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="成本价状态">
          <a-select v-model="searchParams.costStatus" style="width: 100px;">
            <a-select-option value="NU">漏填</a-select-option>
            <a-select-option value="ALL">全部</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        class="data-table"
    >

      <div slot="action" slot-scope="v, item">
        <a-button size="small" @click="handleNavDetails(item)">管理</a-button>
        <a-button size="small" type="danger" @click="handleDeleteItem(item)">删除</a-button>
      </div>

      <div slot="trackNo" slot-scope="trackNo, item">
        <div v-show="item._editing" style="display: flex;">
          <a-input v-model="item._trackNo" size="small" style="flex: 1; margin-right: 10px;" />
          <a-button size="small" icon="close" @click="item._editing = false"></a-button>
          <a-button size="small" icon="check" type="primary" @click="handleUpdateItemInfo(item)"></a-button>
        </div>
        <div v-show="!item._editing">
          <a-button size="small" icon="edit" style="margin-right: 10px;" @click="item._trackNo = item.trackNo; item._editing = true" />
          <a :href="`https://www.sf-express.com/mobile/cn/sc/dynamic_functions/waybill/detail/${trackNo}`" target="_blank">{{trackNo}}</a>
        </div>
      </div>

      <div slot="status" slot-scope="status, item">
        <a-switch checked-children="已签收"
                  un-checked-children="已发货"
                  v-model="item._statusBool"
                  @change="handleStatusChange(item, $event)"
        />
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER_DELIVERY, ROUTE_SUPPLIER_DELIVERY_DETAIL } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listSupplier } from '@/http/api/supplier'
import { listSupplierShipments, deleteSupplierShipment, updateSupplierShipment } from '@/http/api/produce-item-shipment'
import kit from '@/utils/kit'
import moment from 'moment'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_SUPPLIER_DELIVERY,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', width: 130, scopedSlots: { customRender: 'action' } },
        { title: '快递单号', width: 260, dataIndex: 'trackNo', scopedSlots: { customRender: 'trackNo' } },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '快递公司', dataIndex: 'logisticsName' },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '发货日期', dataIndex: 'shipTime' },
        { title: '供应商', dataIndex: 'supplierName' }
      ],
      searchParams: {
        trackNo: null,
        supplierId: null,
        costStatus: 'NU',
        shipDates: [
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        ]
      },
      supplierOptions: [],
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        一周前: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        两周前: [moment().subtract(2, 'weeks').startOf('week'), moment().subtract(2, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        近两个月: [moment().subtract(1, 'month').startOf('month'), moment().endOf('month')]
      }
    }
  },
  methods: {
    moment,
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.shipDates.length === 2) {
        p.startShipDate = p.shipDates[0]
        p.endShipDate = p.shipDates[1]
      }
      delete p.shipDates
      this.dataLoading = true
      listSupplierShipments()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          let id = 1;
          resp.data.records.forEach(item => {
            item.id = id++
            // 用于switch组件
            item._statusBool = item.status === 1
            item._statusBoolLoading = false

            // 快递单号编辑模式
            item._editing = false
            item._trackNo = item.trackNo
          })
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleStatusChange (item) {
      item._statusBoolLoading = true
      updateSupplierShipment()
        .complete((success) => {
          item._statusBoolLoading = false
          if (success) {
            item.status = item._statusBool ? 1 : 0
          } else {
            item._statusBool = !item._statusBool
          }
        })
        .success(() => {
          this.$message.success('保存成功。')
        })
        .send(item.trackNo, item.trackNo, item._statusBool ? 1 : 0)
    },
    handleUpdateItemInfo (item) {
      if (item._trackNo == null || item._trackNo.length === 0) {
        return this.$message.warning('请填写单号。')
      } else {
        this.dataLoading = true
        updateSupplierShipment()
          .complete(() => (this.dataLoading = false))
          .success(() => {
            item._editing = false
            item.trackNo = item._trackNo
            this.$message.success('保存成功。')
          })
          .send(item.trackNo, item._trackNo, item.status)
      }
    },
    handleDeleteItem (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.trackNo}】吗？`,
        onOk: () => {
          this.dataLoading = true
          deleteSupplierShipment()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              kit.arr.removeItem(this.dataList, item)
              this.$message.success('删除成功。')
            })
            .send(item.trackNo)
        }
      })
    },
    handleNavDetails (row) {
      this.$router.push({
        name: ROUTE_SUPPLIER_DELIVERY_DETAIL,
        params: { trackNo: row.trackNo },
        query: { date: row.shipTime.split(' ')[0], supplier: row.supplierName, freight: row.freight }
      })
    }
  },
  mounted () {
    listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
    this.reloadData()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';

.data-table @{deep} .ant-switch-checked {
  background-color: #368e0c;
}
</style>
