import defineApi from '../define-api'
import http from '../application-api'

export const listAll = defineApi((config) => {
  return http.get('/cooperation_status/list', config)
})

export const listOptions = defineApi((config) => {
  return http.get('/cooperation_status/options', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/cooperation_status/update', config)
})
