import kit from '@/utils/kit'

export const SUPPLIER_ORDER_DELIVERY_CREATE = 'create'
export const SUPPLIER_ORDER_DELIVERY_ACCEPT = 'accept'
export const SUPPLIER_ORDER_DELIVERY_DELIVERY = 'delivery'

export const SUPPLIER_ORDER_DELIVERY_MAPPING = {
  [SUPPLIER_ORDER_DELIVERY_CREATE]: '待处理',
  [SUPPLIER_ORDER_DELIVERY_ACCEPT]: '已受理',
  [SUPPLIER_ORDER_DELIVERY_DELIVERY]: '已发货'
}

export const SUPPLIER_ORDER_DELIVERY_LIST = kit.obj.toArray(SUPPLIER_ORDER_DELIVERY_MAPPING)
