import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import VueLazyload from 'vue-lazyload'
import VirtualList from 'vue-virtual-scroll-list'
import Draggable from 'vuedraggable'
import VueFriendlyIframe from 'vue-friendly-iframe'
import AppPage from '@/layout/app-page'
import Pagination from '@/components/pagination'
import Drawer from '@/components/drawer'
import Modal from '@/components/modal'
import Loading from '@/components/loading'
import Tag from '@/components/tag'
import ColorPicker from '@/components/color-picker'
import Status from '@/components/status'
import RichEditor from '@/components/rich-editor'
import ContextMenu from '@/components/context-menu'
import VueClipboard from 'vue-clipboard2'
import initFileManager from '@/components-business/file-manager'
import InternalResourceSelector from '@/components-business/internal-resource-selector'
import ImageSelector from '@/components-business/image-selector'
import Dispatcher from '@/utils/dispatcher'
import ImageModal from '@/components/image-modal'
import InlineSvg from 'vue-inline-svg'
import Print from 'vue-print-nb'
import AuthorityDirective, { initAuthorityConstants } from '@/directives/authority'

Vue.use(Antd)
Vue.use(ContextMenu)
Vue.use(VueClipboard)
Vue.use(VueLazyload)
Vue.use(VueFriendlyIframe)
Vue.use(Dispatcher)
Vue.use(Print)
Vue.component(AppPage.name, AppPage)
Vue.component(Pagination.name, Pagination)
Vue.component(Drawer.name, Drawer)
Vue.component(Loading.name, Loading)
Vue.component(Status.name, Status)
Vue.component(Tag.name, Tag)
Vue.component(Modal.name, Modal)
Vue.component(ColorPicker.name, ColorPicker)
Vue.component(RichEditor.name, RichEditor)
Vue.component('draggable', Draggable)
Vue.component(InternalResourceSelector.name, InternalResourceSelector)
Vue.component(ImageSelector.name, ImageSelector)
Vue.component('VirtualList', VirtualList)
Vue.component(ImageModal.name, ImageModal)
Vue.component('inline-svg', InlineSvg)

Vue.config.productionTip = false

initAuthorityConstants(Vue)
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    initFileManager(Vue)
  }
}).$mount('#app')
