import defineApi from '../define-api'
import http from '../application-api'

export const listFolderByTree = defineApi((config) => {
  http.load('/folder/list/tree', config)
})

export const addFolder = defineApi((config, folder) => {
  config.data = folder
  http.post('/folder/add', config)
})

export const updateFolderName = defineApi((config, id, name) => {
  config.params = {
    id: id,
    name: name
  }
  http.post('/folder/update_name', config)
})

export const updateFolderPosition = defineApi((config, folderPositionDto) => {
  config.data = folderPositionDto
  http.post('/folder/update_pos', config)
})

export const deleteFolder = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/folder/delete', config)
})
