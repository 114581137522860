import kit from '@/utils/kit'
import FileUrl from '@/model/file-url'
export default class FileItem extends FileUrl {
  constructor (data) {
    super(data)
    this.id = data.id
    this.dimension = Object.assign({}, data.dimension)
    this.folderId = data.folderId
    this.hash = data.hash
    this.image = data.image
    this.name = data.name
    this.size = data.size
    this.tag = data.tag
    this.target = data.target
    this.type = data.type
    this.uploadTime = data.uploadTime
    this.deleted = data.deleted

    this._checked = false
    this._deleting = false
  }

  getFileId () {
    return this.id
  }

  getSizeText () {
    return kit.str.fmtSize(this.size)
  }

  isImage () {
    return this.image
  }
}
