/**
 * @author jiangxingshang
 * @date 2020/12/16
 */
import File from './file-manager'

let instance = null

export default function (Vue) {
  const FileManagerConstructor = Vue.extend(File)
  instance = new FileManagerConstructor()
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.dom = instance.vm.$el
  Vue.use(() => {
    Vue.prototype.$fileManager = instance
  })
}

export const getFileManager = function () {
  return instance
}

export const FILE_MANAGER_Z_INDEX = 1050
