<!-- 库存盘点明细 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-title="pageTitle" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="handleExport">导出</a-button>
    <a-button slot="toolbar" type="primary" @click="handleStockTransfer" v-authority="$authority.stockCheckTransferBtn">库存迁移</a-button>

    <div slot="top" class="left-right-content" style="align-items: center;">
      <div class="full">
        <a-alert v-if="isStockInit" message="此次盘点已经结束，并已经更新库存。" type="success" />
        <a-form layout="inline" v-else>
          <a-form-item label="分组" v-show="isChecking">
            <a-input v-model="check.groupName" style="width: 100px;" />
          </a-form-item>
          <a-form-item label="SKU" v-show="isChecking">
            <a-input v-model="check.sku" style="width: 250px;" v-on:keyup.enter="handleSkuInputEnter" />
          </a-form-item>
          <a-form-item v-show="isChecking">
            <a-button type="primary" @click="handleCheckSku" :loading="check.checking">盘点</a-button>
          </a-form-item>
          <a-form-item v-show="isCheckCompleted">
            <a-button type="primary" @click="handleUpdateStatus(0)">继续盘点</a-button>
          </a-form-item>
          <a-form-item v-show="isChecking">
            <a-button @click="handleUpdateStatus(1)" :loading="check.checking">结束盘点</a-button>
          </a-form-item>
          <a-form-item v-show="isCheckCompleted" v-authority="$authority.stockCheckResetStockBtn">
            <a-tooltip placement="bottom">
              <template slot="title">使用盘点的库存数量重置当前仓库的库存</template>
              <a-button @click="handleInitStock">更新库存</a-button>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <span v-show="check.lastedScanSku" class="font-mini font-normal">
              最后一次扫描的SKU：<span class="text-primary">{{check.lastedScanSku}}</span>
            </span>
          </a-form-item>
        </a-form>
      </div>
      <div style="margin-left: 20px;">
        <a-form layout="inline">
          <a-form-item label="盘点结果">
            <a-select v-model="selectedCheckResultOption" style="width: 120px;">
              <a-select-option :value="item.id" v-for="item in checkResultOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="分组">
            <a-select v-model="selectedCheckGroupNameOption" style="width: 120px;" :allow-clear="true">
              <a-select-option :value="item" v-for="item in optionsForCheckGroup" :key="item">{{item}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="filterDataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button v-show="canCheck" size="small" @click="handleOpenTotalEditor(record)">编辑数量</a-button>
      </div>

      <div slot="imageUrl" slot-scope="imageUrl" class="image-item small" v-lazy:background-image="imageUrl"></div>

      <div slot="sku" slot-scope="sku" class="font-bold font-large">{{sku}}</div>

      <div slot="total" slot-scope="total" class="font-bold font-large text-primary">{{total}}</div>

      <div slot="totalInStock" slot-scope="totalInStock" class="font-bold font-large text-success">{{totalInStock}}</div>

      <div slot="checkResult" slot-scope="record">
        <span v-if="record.total === record.totalInStock" class="text-success">相同</span>
        <span v-else-if="record.total > record.totalInStock" class="text-danger">盘点大于库存</span>
        <span v-else class="text-warning">盘点小于库存</span>
      </div>
    </a-table>

    <div slot="bottom" style="padding-bottom: 10px;" class="font-bold font-large">
      <div style="margin-left: 340px;">盘点总数：{{checkTotal}}</div>
    </div>

    <modal
        :title="dataItemEditor.item ? dataItemEditor.item.sku : ''"
        :loading="dataItemEditor.saving"
        @ok="handleItemUpdateClick"
        @cancel="handleItemUpdateCancel"
        ref="totalEditor"
    >
      <a-form layout="inline">
        <a-form-item label="盘点数量">
          <a-input-number v-model="dataItemEditor.total" :min="0" style="width: 100px;" />
        </a-form-item>
        <a-form-item label="分组">
          <a-input v-model="dataItemEditor.groupName" />
        </a-form-item>
        <a-alert style="margin-top: 20px;" message="数量小于等于0，表示删除。" type="info" show-icon />
      </a-form>
    </modal>

    <stock-transfer-editor ref="stockTransferEditor"></stock-transfer-editor>

  </app-page>
</template>

<script>
import { ROUTE_STOCK_CHECK_ITEM } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listStockCheckItems, checkSku, updateCheckItemTotal, getById, updateStockCheck, updateStockInit } from '@/http/api/stock-check'
import kit from '@/utils/kit'
import StockTransferEditor from '../transfer/comp/stock-transfer-editor'
import { removeSupplierCodeFromSku } from '@/utils/sku-supplier-utils'
import { getCheckSuccessSound, getCheckFailSound } from '@/constants/audio'

export default {
  mixins: [AntdTableHeightMixin],
  components: { StockTransferEditor },
  data () {
    return {
      routeName: ROUTE_STOCK_CHECK_ITEM,
      pageTitle: '盘点明细',
      pageLoading: false,
      dataColumns: [
        {
          title: '操作',
          width: 100,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'imageUrl',
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          scopedSlots: { customRender: 'sku' }
        },
        {
          title: '盘点数量',
          dataIndex: 'total',
          scopedSlots: { customRender: 'total' }
        },
        {
          title: '库存数量',
          dataIndex: 'totalInStock',
          scopedSlots: { customRender: 'totalInStock' }
        },
        {
          title: '盘点结果',
          scopedSlots: { customRender: 'checkResult' }
        },
        {
          title: '分组',
          dataIndex: 'groupName'
        }
      ],
      dataList: [],
      dataLoading: false,
      check: {
        sku: null,
        groupName: null,
        lastedScanSku: null,
        checking: false,
        sound: {
          success: getCheckSuccessSound(),
          fail: getCheckFailSound()
        }
      },
      selectedCheckGroupNameOption: null,
      selectedCheckResultOption: 'all',
      checkResultOptions: [
        { id: 'all', name: '全部' },
        { id: 'eq', name: '相同' },
        { id: 'gt', name: '盘点大于库存' },
        { id: 'lt', name: '盘点小于库存' }
      ],
      dataItemEditor: {
        item: null,
        total: 0,
        groupName: null,
        saving: false
      },
      stockCheckModel: null
    }
  },
  computed: {
    stockCheckId () {
      return this.$route.params.stockCheckId
    },
    isChecking () {
      return this.stockCheckModel && this.stockCheckModel.status === 0
    },
    isCheckCompleted () {
      return this.stockCheckModel && this.stockCheckModel.status === 1
    },
    isStockInit () {
      return this.stockCheckModel && this.stockCheckModel.status === 2
    },
    optionsForCheckGroup () {
      const map = {}
      for (const item of this.dataList) {
        if (item.groupName) {
          map[item.groupName] = true
        }
      }
      return Object.keys(map)
    },
    filterDataList () {
      const arr = []
      for (const item of this.dataList) {
        let flag = 0
        if (this.selectedCheckGroupNameOption == null || item.groupName === this.selectedCheckGroupNameOption) {
          flag++
        }
        switch (this.selectedCheckResultOption) {
          case 'eq':
            if (item.total === item.totalInStock) {
              flag++
            }
            break
          case 'gt':
            if (item.total > item.totalInStock) {
              flag++
            }
            break
          case 'lt':
            if (item.total < item.totalInStock) {
              flag++
            }
            break
          case 'all':
            flag++
            break
        }
        if (flag === 2) {
          arr.push(item)
        }
      }
      return arr
    },
    canCheck () {
      return this.stockCheckModel && this.stockCheckModel.status === 0
    },
    checkTotal () {
      let t = 0
      for (const item of this.dataList) {
        t += item.total
      }
      return t
    }
  },
  watch: {
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listStockCheckItems()
        .complete(() => (this.dataLoading = false))
        .success(resp => (this.dataList = resp.data))
        .send(this.stockCheckId)
    },
    handleCheckSku () {
      this.checkSku(this.check.sku)
    },
    handleSkuInputEnter () {
      this.check.sku = removeSupplierCodeFromSku(this.check.sku)
      this.check.lastedScanSku = this.check.sku
      this.checkSku(this.check.sku)
      this.check.sku = null
    },
    checkSku (sku) {
      this.check.checking = true
      checkSku()
        .complete(() => (this.check.checking = false))
        .success(resp => {
          const item = resp.data
          kit.arr.pushOrReplace(this.dataList, item)
          if (item.total > item.totalInStock) {
            this.check.sound.fail.play()
          } else {
            this.check.sound.success.play()
          }
        })
        .error((resp) => {
          this.$error({
            title: '错误',
            content: resp.message
          })
          this.check.sound.fail.play()
        })
        .send(this.stockCheckId, sku, this.check.groupName)
    },
    handleOpenTotalEditor (data) {
      this.dataItemEditor.item = data
      this.dataItemEditor.total = data.total
      this.dataItemEditor.groupName = data.groupName
      this.$refs.totalEditor.open()
    },
    handleItemUpdateClick () {
      this.dataItemEditor.saving = true
      updateCheckItemTotal()
        .complete(() => (this.dataItemEditor.saving = false))
        .success(resp => {
          if (resp.data) {
            kit.arr.pushOrReplace(this.dataList, resp.data)
          } else {
            kit.arr.removeItem(this.dataList, this.dataItemEditor.item)
          }
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.$refs.totalEditor.close()
          })
        })
        .send(this.dataItemEditor.item.id, this.dataItemEditor.total, this.dataItemEditor.groupName)
    },
    handleItemUpdateCancel () {
      this.dataItemEditor.item = null
      this.dataItemEditor.total = 0
      this.dataItemEditor.groupName = null
    },
    handleExport () {
      if (this.dataList.length === 0) {
        return this.$message.error('没有盘点数据。')
      }
      const rows = []
      for (const item of this.dataList) {
        rows.push([item.sku, item.total])
      }

      let csvContent = 'data:text/csv;charset=utf-8,'

      rows.forEach(function (rowArray) {
        csvContent += rowArray.join(',') + '\r\n'
      })
      window.open(encodeURI(csvContent))
    },
    handleStockTransfer () {
      if (this.dataList.length === 0) {
        return this.$message.error('没有盘点数据。')
      }
      const arr = []
      for (const item of this.dataList) {
        arr.push({
          stockProductId: item.stockProductId,
          imageUrl: item.imageUrl,
          sku: item.sku,
          total: item.total
        })
      }
      this.$refs.stockTransferEditor.open(arr)
    },
    handleUpdateStatus (status) {
      const data = Object.assign({}, this.stockCheckModel)
      data.status = status
      this.pageLoading = false
      updateStockCheck()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.stockCheckModel.status = status
        })
        .send(data)
    },
    handleInitStock () {
      this.$confirm({
        title: '确认',
        content: '确定要更新盘点的库存吗？',
        onOk: () => {
          this.pageLoading = true
          updateStockInit()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.stockCheckModel.status = 2
              this.$message.success('库存已经更新。')
            })
            .send(this.stockCheckModel.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
    getById()
      .success(resp => {
        this.stockCheckModel = resp.data
        this.pageTitle = `【${this.stockCheckModel.warehouseName}】盘点明细`
      })
      .send(this.stockCheckId)
  }
}
</script>

<style scoped>

</style>
