<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="handleVerify()" :disabled="selectedTotalTab.id !== 'demoVerify'">审核</a-button>
    <a-button slot="toolbar" @click="handleOpenMultiDelivery()" :disabled="!canSend">发货</a-button>

    <div slot="top">
      <div class="total-tab">
        <ul>
          <li v-for="tab in totalTabs"
              :key="tab.id"
              @click="handleTotalTabSelected(tab)"
              :class="{'selected': tab.selected, 'attention': tab.id === 'attention'}">
            {{tab.title}}
            <span class="total">( {{tab.total}} )</span>
          </li>
        </ul>
      </div>

      <div class="search-form" style="margin-top: 5px;">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <a-form-model-item label="订单号">
            <a-input v-model="searchParams.orderNo" :allow-clear="true" style="width: 220px;" />
          </a-form-model-item>
          <a-form-model-item label="客户邮箱">
            <a-input v-model="searchParams.email" :allow-clear="true" style="width: 160px;" />
          </a-form-model-item>
          <a-form-model-item label="店- -铺">
            <a-select v-model="searchParams.shopId" style="width: 220px;" :allow-clear="true">
              <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData()" icon="reload">查询</a-button>
        </div>
      </div>
    </div>

    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY, x: tableX }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
        :rowClassName="(r,index) => index % 2 === 0 ? 'row-bg' : ''"
    >
      <div slot="action" slot-scope="item">
        <div>
          <a-button size="small"
                    type="primary"
                    @click="$refs.produceItemEditor.open(item.id)"
                    :disabled="item.produceItemTotal > 0"
                    v-if="selectedTotalTab.id === 'demoPass' || selectedTotalTab.id === 'demoShipped'"
          >
            <span v-if="item.produceItemTotal === 0">生产</span>
            <span v-else>已生产</span>
          </a-button>
        </div>
      </div>

      <div slot="orderNo" slot-scope="orderNo, item">
        <span class="text-primary" style="cursor: pointer;" @click="navOrderDetail(item)">{{orderNo}}</span>
        <div class="text-muted font-mini">
          原：{{item.originOrderNo}}
        </div>
      </div>

      <div slot="image" slot-scope="v, r">
        <div class="image-list" v-if="r.productItems.length > 0">
          <div class="image-wrapper"
               v-for="(pItem,index) in limitArray(r.productItems, 4)"
               :class="{'stock-in': pItem.stockQuantity > 0}"
               :key="index"
          >
            <div class="image-item small" v-lazy:background-image="pItem.imageUrl" @click="$imageModal.open(getImageArray(r), index)"></div>
          </div>
          <div class="image-total" v-if="r.productItems.length > 4">{{r.productItems.length}}</div>
        </div>
      </div>

      <div slot="customer" slot-scope="v, item">
        {{item.receiverLastName}} {{item.receiverFirstName}}
      </div>

      <div slot="price" slot-scope="v, item">
        <div style="font-weight: bold; font-size: 13px; color: #2568d9">{{item.pricePay}} {{item.currency}}</div>
      </div>

      <div slot="statusBar" slot-scope="v, item">
        <div class="status-bar">
          <div class="status status-risk" :class="'risk-' + item.risk" v-if="item.risk > 0">欺诈</div>
          <div v-if="item.productItems && item.productItems.filter(t => t.stockQuantity > 0).length > 0" class="status status-stock">库存</div>
          <div class="status status-default" :class="'status-' + item.status">{{statusTextMapping[item.status]}}</div>
          <div class="status status-pay" :class="'status-' + item.statusPay" v-if="item.status !== statusMapping.cancel">{{statusPayTextMapping[item.statusPay]}}</div>
          <div class="status status-ship" :class="'status-' + item.statusShipping + ' ' + (item.allShip ? 'all-ship' : '')">
            {{item.allShip ? '已发完' : statusShipTextMapping[item.statusShipping]}}
            <span v-if="item.statusShipping === statusShipMapping.wait">（已过{{item.dayNewToDelivery}}天）</span>
            <span v-else>（发货用时{{item.dayNewToDelivery}}天）</span>
          </div>

          <div v-if="item.impAttend && item.impTags" class="status status-attention-tag">关注标签：{{item.impTags}}</div>

          <div v-if="item.impAttend" class="status status-priority" :class="'priority-' + item.impPriority">
            优先级：
            <span v-if="item.impPriority === 0">实时关注</span>
            <span v-else-if="item.impPriority === 3">重要</span>
            <span v-else>一般</span>
          </div>
        </div>
      </div>

      <div slot="remark" slot-scope="v, item">
        <div v-if="item.remark" style="padding: 4px 0;">
          <a-tooltip placement="top">
            <template slot="title">{{item.remark}}</template>
            <a-tag color="red">客户备注</a-tag>
          </a-tooltip>
        </div>
        <div v-if="item.adminRemark" style="padding: 4px 0;">
          <a-tooltip placement="top">
            <template slot="title">{{item.adminRemark}}</template>
            <a-tag color="blue">管理员备注</a-tag>
          </a-tooltip>
        </div>
      </div>

      <div slot="createTime" slot-scope="createTime">
        <div class="font-bold text-main font-normal">{{createTime | readableDate}}</div>
        <div class="font-mini text-muted">{{createTime}}</div>
      </div>
    </a-table>


    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <produce-item-editor ref="produceItemEditor" @saved="handleProduceItemSaved"></produce-item-editor>
    <multi-delivery-modal ref="multiDelivery"></multi-delivery-modal>

    <modal ref="verifyDialog" title="审核" ok-text="通过" @ok="verifyOrder(true)" @closed="handleVerifyDialogClose" :loading="verifyDialog.loading">
      <a-textarea
          v-model="verifyDialog.message"
          placeholder="请输入原因，不超过100个字。"
          :auto-size="{ minRows: 3, maxRows: 5 }"
      />
      <a-button type="danger" slot="buttons" @click="verifyOrder(false)" :loading="verifyDialog.loading">拒绝</a-button>
    </modal>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_DEMO, ROUTE_ORDER_DEMO_DETAIL } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listOrders, verify } from '@/http/api/order-demo'
import { listShopOptionsBySelfDept } from '@/http/api/shop'
import {
  ORDER_SHIP_STATUS_WAIT,
  ORDER_STATUS_MAPPING,
  ORDER_STATUS_CANCEL,
  ORDER_SHIP_STATUS_MAPPING,
  ORDER_PAY_STATUS_MAPPING, ORDER_SHIP_STATUS_SEND, ORDER_SHIP_STATUS_ARRIVED, ORDER_SHIP_STATUS_ALL_SEND
} from '@/constants/order-status'
import moment from 'moment'
import ProduceItemEditor from '../detail/comp/produce-item-editor'
import { removeImportantOrder } from '@/http/api/important-order'
import MultiDeliveryModal from '@/pages/delivery/comp/multi-delivery'

function buildSearchParams (tabId) {
  let statusCheck = null
  let statusShipping = []
  if (tabId === 'demoVerify') {
    statusCheck = 'verify'
  } else if (tabId === 'demoPass') {
    statusCheck = 'pass'
    statusShipping = [ORDER_SHIP_STATUS_WAIT]
  } else if (tabId === 'demoReject') {
    statusCheck = 'reject'
  } else if (tabId === 'demoShipped') {
    statusCheck = 'pass'
    statusShipping = [ORDER_SHIP_STATUS_SEND, ORDER_SHIP_STATUS_ALL_SEND, ORDER_SHIP_STATUS_ARRIVED]
  }

  return {
    statusCheck: statusCheck,
    statusShipping: statusShipping,
    orderNo: null,
    email: null,
    shopId: null,
    orderByName: 'create_time:desc'
  }
}

export default {
  components: { ProduceItemEditor, MultiDeliveryModal },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    const tabs = [
      { id: 'demoVerify', title: '审核中', total: 0, selected: true },
      { id: 'demoPass', title: '审核通过', total: 0, selected: false },
      { id: 'demoReject', title: '审核拒绝', total: 0, selected: false },
      { id: 'demoShipped', title: '已发货', total: 0, selected: false }
    ]

    const searchParamsByTab = {}
    for (const t of tabs) {
      searchParamsByTab[t.id] = buildSearchParams(t.id)
    }
    return {
      routeName: ROUTE_ORDER_DEMO,
      dataList: [],
      pageLoading: false,
      dataLoading: false,
      dataColumns: [
        { title: '', width: 70, scopedSlots: { customRender: 'action' } },
        { title: '图片', width: 130, scopedSlots: { customRender: 'image' } },
        { title: '订单号', width: 170, dataIndex: 'orderNo', scopedSlots: { customRender: 'orderNo' } },
        { title: '客户', width: 140, scopedSlots: { customRender: 'customer' } },
        { title: '金额', width: 100, scopedSlots: { customRender: 'price' } },
        { title: '数量', width: 80, dataIndex: 'total' },
        { title: '状态', width: 300, scopedSlots: { customRender: 'statusBar' } },
        { title: '备注', width: 200, scopedSlots: { customRender: 'remark' } },
        { title: '日期', width: 80, dataIndex: 'createTime', scopedSlots: { customRender: 'createTime' } },
        { title: '国家', width: 100, dataIndex: 'receiverCountry' },
        { title: '店铺', width: 100, dataIndex: 'shopName' }
      ],
      /**
       * 每个标签下的搜索条件保存一份
       */
      searchParamsByTab: searchParamsByTab,
      totalTabs: tabs,
      shopOptions: [],
      statusShipMapping: {
        wait: ORDER_SHIP_STATUS_WAIT
      },
      statusMapping: {
        cancel: ORDER_STATUS_CANCEL
      },
      statusTextMapping: ORDER_STATUS_MAPPING,
      statusShipTextMapping: ORDER_SHIP_STATUS_MAPPING,
      statusPayTextMapping: ORDER_PAY_STATUS_MAPPING,
      selectedRowKeys: [],
      verifyDialog: {
        message: null,
        loading: false
      }
    }
  },
  computed: {
    selectedTotalTab () {
      return this.totalTabs.find(item => item.selected)
    },
    searchParams () {
      return this.searchParamsByTab[this.selectedTotalTab.id]
    },
    tableX () {
      let w = 0
      for (const c of this.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    },
    canSend () {
      return this.selectedTotalTab.id === 'demoPass' || this.selectedTotalTab.id === 'demoShipped'
    }
  },
  filters: {
    readableDate (date) {
      const d = Math.abs(
        parseInt(
          moment.duration(moment().diff(moment(date))).as('days')
        )
      )
      if (d === 0) {
        return '今天'
      } else if (d === 1) {
        return '昨天'
      } else if (d === 2) {
        return '前天'
      } else {
        return ''
      }
    }
  },
  methods: {
    limitArray (arr, limit) {
      if (arr.length > limit) {
        return arr.slice(0, limit)
      } else {
        return arr
      }
    },
    getImageArray (record) {
      const arr = []
      for (const p of record.productItems) {
        const item = {
          url: p.imageUrl,
          infos: [
            { name: '订单号', value: record.orderNo },
            { name: 'SKU', value: p.sku },
            { name: '数量', value: p.total }
          ],
          title: p.title
        }
        if (record.remark) {
          item.infos.push({ name: '客户备注', value: record.remark, type: 'remark' })
        }
        arr.push(item)
      }
      return arr
    },
    loadData () {
      const params = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      this.selectedRowKeys = []
      listOrders()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(params)
    },
    handleTotalTabSelected (tab) {
      if (tab.selected) {
        tab.selected = false
      } else {
        this.totalTabs.forEach(item => (item.selected = false))
        tab.selected = true
      }
      this.reloadData()
    },
    loadTotals () {
      for (const tab of this.totalTabs) {
        const p = buildSearchParams(tab.id)
        p.limit = 0
        listOrders()
          .success(resp => {
            tab.total = resp.data.total
          })
          .send(p)
      }
    },
    navOrderDetail (order) {
      this.$router.push({ name: ROUTE_ORDER_DEMO_DETAIL, params: { orderId: order.id } })
    },
    handleProduceItemSaved (order, total) {
      const item = this.dataList.find(item => item.id === order.id)
      item.produceItemTotal = total
    },
    handleSkuOrderDetailOrderClick (orderId) {
      this.$refs.produceItemEditor.open(orderId)
    },
    handleRemoveOrderAttention (item) {
      removeImportantOrder()
        .success(() => {
          this.$message.success('已经从关注列表中移除。')
          this.loadData()
        })
        .send(item.id)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleOpenMultiDelivery () {
      const orderNoList = []
      for (const id of this.selectedRowKeys) {
        orderNoList.push(this.dataList.find(item => item.id === id).orderNo)
      }
      this.$refs.multiDelivery.open(orderNoList)
    },
    handleVerify () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择要审核的订单。')
      } else {
        this.$refs.verifyDialog.open()
      }
    },
    handleVerifyDialogClose () {
      this.verifyDialog.message = null
    },
    verifyOrder (statusCheck) {
      const t = statusCheck ? '通过' : '拒绝'
      this.$confirm({
        title: '确认',
        content: `确定要【${t}】选中的${this.selectedRowKeys.length}个订单吗？`,
        onOk: () => {
          this.verifyDialog.loading = true
          verify()
            .complete(() => (this.verifyDialog.loading = false))
            .success(() => {
              this.$nextTick(() => (this.$refs.verifyDialog.close()))
              this.$message.success('审核成功。')
              this.selectedRowKeys = []
              this.loadData()
              this.loadTotals()
            })
            .send(this.selectedRowKeys.join(','), statusCheck ? 'pass' : 'reject', this.verifyDialog.message)
        }
      })
    }
  },
  mounted () {
    this.page.limit = 100
    this.loadData()
    this.loadTotals()
    listShopOptionsBySelfDept()
      .success(resp => (this.shopOptions = resp.data))
      .send()
  }
}
</script>

<style lang="less" scoped>

@deep: ~'>>>';

.total-tab {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 4px 20px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    &:hover {
      color: #bb3e30;
    }
    &.selected {
      border-bottom: solid 2px #bb3e30;
    }
    &.attention {
      font-weight: bold;
      background-color: #fabfc1;
      border-radius: 5px;
    }
    .total {
      margin-left: 4px;
      color: #bb3e30;
      font-size: 12px;
    }
  }
  li + li {
    margin-left: 14px;
  }
}

.image-list {
  position: relative;
  width: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  .image-wrapper.stock-in .image-item {
    border-color: #b501ff;
    border-width: 2px;
  }
  .image-total {
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -8px;
    color: #fff;
    background-color: #4586d4;
    padding: 2px 8px;
    border-radius: 20px;
  }
}

.data-table @{deep} .row-bg {
  background-color: #f4f7fa;
}


.status-bar {
  .status {
    display: inline-block;
    padding: 3px 10px;
    margin: 2px;
    font-size: 12px;
    border-radius: 6px;
    background-color: #d2d2d2;
  }
  .status-stock {
    color: #fff;
    background-color: #b501ff;
  }
  .status-produce {
    color: #fff;
    background-color: #e36f1a;
  }
  .status-complete {
    color: #fff;
    background-color: #368e0c;
  }
  .status-close, .status-cancel {
    color: #fff;
    background-color: #bb3e30;
  }
  .status-payPartially, .status-pay {
    color: #fff;
    background-color: #368e0c;
  }
  .status-refund, .status-refundPartially {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-send {
    color: #fff;
    background-color: #0976bb;
  }
  .status-arrived {
    color: #fff;
    background-color: #368e0c;
  }
  .all-ship {
    background-color: #368e0c !important;
    color: #fff;
  }
  .status-risk.risk-2 {
    color: #fff;
    background-color: #e7710a;
  }
  .status-risk.risk-5 {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-priority.priority-0 {
    background-color: #a10b10;
    color: #fff;
  }
  .status-priority.priority-3 {
    background-color: #ce7e3c;
    color: #fff;
  }
  .status-priority.priority-6 {
    background-color: #0b6985;
    color: #fff;
  }

  .status-attention-tag {
    background-color: #9a9999;
    color: #2a2a2a;
  }
}
</style>
