import defineApi from '../define-api'
import http from '../application-api'

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/accounting/list', config)
})

export const getCategories = defineApi((config) => {
  http.get('/accounting/categories', config)
})

/**
 * @param p 和list的params一样
 */
export const getAmountSummary = defineApi((config, p) => {
  config.params = p
  http.get('/accounting/amount/summary', config)
})

export const add = defineApi((config, data) => {
  config.data = data
  http.post('/accounting/add', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/accounting/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/accounting/delete', config)
})

export const upload = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/accounting/upload', config)
})

export const getSummaryGroupByCategory = defineApi((config, startDate, endDate) => {
  config.params = {
    startDate: startDate,
    endDate: endDate
  }
  http.get('/accounting/amount/summary/group_category', config)
})
