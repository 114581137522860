import defineApi from '../define-api'
import http from '../application-api'


export const listInventoryChangeRequest = defineApi((config, params) => {
  config.params = params
  http.get('/inventory_change_request/list', config)
})

export const listProducts = defineApi((config, inventoryChangeRequestId) => {
  config.params = { requestId: inventoryChangeRequestId }
  http.get('/inventory_change_request/list/products', config)
})

export const buildExportProductsUrl = function (id) {
  return http.baseUrl + '/inventory_change_request/export/products?requestId=' + id
}

export const importProductsFromFile = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/inventory_change_request/import/products', config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/inventory_change_request/get/by_id', config)
})

export const addInventoryChangeRequest = defineApi((config, data) => {
  config.data = data
  http.post('/inventory_change_request/add', config)
})

export const updateInventoryChangeRequest = defineApi((config, id, title, remark) => {
  config.data = {
    id: id,
    title: title,
    remark: remark
  }
  http.post('/inventory_change_request/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/inventory_change_request/delete', config)
})

/**
 * 将在途更新为入库
 */
export const updateOnWay2InStock = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/inventory_change_request/update/onway2instock', config)
})

/**
 * @param productCost {Object} { id: string, // StockProduct.id number: double // 成本单价 }
 */
export const updateProductCost = defineApi((config, id, productCost) => {
  config.data = {
    id: id,
    skuCostList: productCost
  }
  http.post('/inventory_change_request/update/cost', config)
})

