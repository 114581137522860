<template>
  <communication-editor :visible.sync="visible" title="图片" :loading="loading" @submit="handleSave()">

    <div style="display: flex; align-items: center;">
      <a-input v-model="url" addon-before="图片URL"></a-input>
      <div style="width: 60px; text-align: center;">或者</div>
      <a-button @click="handleSelectImage">选择图片...</a-button>
    </div>

    <div class="image-wrapper">
      <div class="remove-image" v-show="imageUrl" @click="handleRemoveImage">删除图片</div>
      <img :src="imageUrl">
    </div>
  </communication-editor>
</template>

<script>
import CommunicationEditor from '@/pages/influencer/detail/comm-comp/communication-editor'
import CommunicationMixin from '@/pages/influencer/detail/comm-comp/communication-mixin'
import { TYPE_IMAGE } from '@/constants/communication-type'
import selectFiles from '@/utils/select-file'

export default {
  components: { CommunicationEditor },
  mixins: [CommunicationMixin],
  data () {
    return {
      type: TYPE_IMAGE,
      url: null,
      imageBase64: null
    }
  },
  computed: {
    imageUrl () {
      return this.url || this.imageBase64
    }
  },
  methods: {
    getContent () {
      return this.imageUrl
    },
    onHide () {
      this.url = null
      this.imageBase64 = null
    },
    handleSelectImage () {
      selectFiles({
        multi: false,
        accept: ['image/jpg', 'image/jpeg', 'image/png'],
        select: (files) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            this.imageBase64 = reader.result
          }, false)
          reader.readAsDataURL(files[0])
        }
      })
    },
    handleRemoveImage () {
      this.resetContentOnClose()
    }
  }
}
</script>

<style lang="less" scoped>
.image-wrapper {
  position: relative;
  margin-top: 20px;
  img {
    width: 100%;
    height: auto;
  }
  .remove-image {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 4px 10px;
    color: #fff;
    background-color: #a94442;
    font-size: 12px;
    cursor: pointer;
    border-radius: 10px;
  }
}
</style>
