import defineApi from '../define-api'
import http from '../application-api'


export const listCategories = defineApi((config) => {
  http.get('/influencer/options/category', config)
})

export const listTags = defineApi((config) => {
  http.get('/influencer/options/tag', config)
})

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/influencer/list', config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/influencer/get_by_id', config)
})

export const add = defineApi((config, data) => {
  config.data = data
  http.post('/influencer/add', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/influencer/update', config)
})

export const batchUpdate = defineApi((config, data) => {
  config.data = data
  http.post('/influencer/batch_update', config)
})

export const updateScore = defineApi((config, id, score, remark) => {
  config.params = {
    id: id,
    score: score,
    remark: remark
  }
  http.post('/influencer/update/score', config)
})

export const getTemplateDownloadUrl = function () {
  return http.baseUrl + '/influencer/import/template/download'
}

export const uploadImportData = defineApi((config, file) => {
  config.timeout = 1000 * 60 * 5
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/influencer/import/preview', config)
})

export const uploadData = defineApi((config, list) => {
  config.data = list
  http.post('/influencer/import', config)
})

export const getFavouriteId = defineApi((config) => {
  http.get('/influencer/get_fav_id', config)
})

export const updateFavourite = defineApi((config, influencerId, isFav) => {
  config.params = {
    influencerId: influencerId,
    fav: isFav
  }
  http.post('/influencer/fav', config)
})

export const getCooperationStatusList = defineApi((config, influencerId) => {
  config.params = {
    influencerId: influencerId
  }
  http.get('/influencer/coop_status/list', config)
})

/**
 * @param influencerId
 * @param [deptId] 如果是管理员，则可提供相应的部门id来修改其他部门的合作状态信息。
 * @param cooperationStatusId
 */
export const setCooperationStatus = defineApi((config, influencerId, deptId, cooperationStatusId) => {
  config.params = {
    influencerId: influencerId,
    deptId: deptId,
    statusId: cooperationStatusId
  }
  http.post('/influencer/coop_status/set', config)
})

export const updateCooperationStatusSearchConfig = defineApi((config, setting) => {
  config.params = {
    config: setting
  }
  http.post('/influencer/coop_status/search/config/update', config)
})

export const getCooperationStatusSearchConfig = defineApi((config) => {
  http.get('/influencer/coop_status/search/config/get', config)
})

export const getCooperationProductList = defineApi((config, influencerId) => {
  config.params = {
    influencerId: influencerId
  }
  http.get('/influencer/coop_product/list', config)
})

export const addCooperationProduct = defineApi((config, influencerId, stockProductId) => {
  config.params = {
    influencerId: influencerId,
    stockProductId: stockProductId
  }
  http.post('/influencer/coop_product/add', config)
})

export const deleteCooperationProduct = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/influencer/coop_product/delete', config)
})
