<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <span v-show="!supplierEditing" style="margin-right: 10px;" class="font-mini">当前页面共有 <span style="font-size: 16px; font-weight: bold; color: #a94442;">{{emptyCostTotal}}</span> 个发货项未填写成本价。</span>
      <a-button v-show="!supplierEditing" type="primary" @click="handleUpdateCost">更新成本价</a-button>
      <a-button v-show="!supplierEditing" @click="handleStartModifySupplier">修改供应商</a-button>
      <a-button v-show="supplierEditing" type="primary" @click="handleSubmitModifySupplier">保存修改</a-button>
      <a-button v-show="supplierEditing" @click="handleCancelModifySupplier">取消修改</a-button>
    </div>

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="订单号">
          <a-input v-model="searchParams.orderNo" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="SKU">
          <a-input v-model="searchParams.skuKeyword" :allow-clear="true" placeholder="SKU模糊查询" />
        </a-form-model-item>
        <a-form-model-item label="发货日期">
          <a-range-picker
              v-model="searchParams.shipDates"
              :ranges="dateRanges"
              value-format="YYYY-MM-DD"
              style="width: 220px;"
          >
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="image" slot-scope="row">
        <div class="image-item" :style="{'background-image': `url(${row.imageUrl})`}"></div>
      </div>

      <div slot="orderNo" slot-scope="row">
        {{row.orderNo}}
        <div class="text-muted font-mini" style="margin-top: 5px" v-if="row.orderNo !== row.originOrderNo">原订单号：{{row.originOrderNo}}</div>
        <div class="text-muted font-mini" style="margin-top: 5px">发货编号：{{row.shipOrderNo}}</div>
      </div>

      <div slot="cost" slot-scope="row" :class="{'no-price': row.cost == null && row.referCost == null}">
        <a-input-number :min="0" :precision="2" :placeholder="row.referCost || '单价'" v-model="row.cost" size="large" style="width: 120px;"></a-input-number>
      </div>

      <div slot="supplier" slot-scope="row">
        <a-select style="width: 100%;" v-model="row.fromSupplierId" v-show="supplierEditing" :allow-clear="true">
          <a-select-option v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
        <span v-show="!supplierEditing">{{row.fromSupplierName}}</span>
      </div>
    </a-table>

    <pagination slot="bottom" :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
  </app-page>
</template>

<script>
import { ROUTE_ORDER_DELIVERY_ITEM } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listEmptyCostDeliveryItems, updateDeliveryItemsCost, updateDeliveryItemsSupplier } from '@/http/api/order-delivery'
import { listSupplier } from '@/http/api/supplier'
import moment from 'moment'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ORDER_DELIVERY_ITEM,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '店铺', dataIndex: 'shopName', width: 120 },
        { title: '订单号', width: 220, scopedSlots: { customRender: 'orderNo' } },
        { title: 'SKU', dataIndex: 'sku', width: 200 },
        { title: '生产商', width: 150, scopedSlots: { customRender: 'supplier' } },
        { title: '发货日期', dataIndex: 'shipDate', width: 100 },
        { title: '图片', width: 100, scopedSlots: { customRender: 'image' } },
        { title: '数量', dataIndex: 'total', width: 60 },
        { title: '成本单价（人民币）', scopedSlots: { customRender: 'cost' } }
      ],
      searchParams: {
        shipDates: [
          moment().subtract(7, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        skuKeyword: null,
        orderNo: null
      },
      dateRanges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        这周: [moment().startOf('week'), moment().endOf('week')],
        一周前: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        两周前: [moment().subtract(2, 'weeks').startOf('week'), moment().subtract(2, 'weeks').endOf('week')],
        这个月: [moment().startOf('month'), moment().endOf('month')],
        近两个月: [moment().subtract(1, 'month').startOf('month'), moment().endOf('month')]
      },
      supplierEditing: false,
      // 开始修改供应商前，先保存原来的数据，在取消修改时可恢复
      supplierBackup: [],
      supplierOptions: []
    }
  },
  computed: {
    emptyCostTotal () {
      let t = 0
      for (const item of this.dataList) {
        if (item.cost == null && item.referCost == null) {
          t++
        }
      }
      return t
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.shipDates.length === 2) {
        p.createDateStart = p.shipDates[0]
        p.createDateEnd = p.shipDates[1]
      }
      delete p.shipDates
      this.dataLoading = true
      listEmptyCostDeliveryItems()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleUpdateCost () {
      this.$confirm({
        title: '确认',
        content: '确定更新当前页面的发货项成本价吗？',
        onOk: () => {
          const arr = []
          for (const item of this.dataList) {
            let cost = item.cost
            if (cost == null) {
              cost = item.referCost
            }
            if (cost == null) {
              continue
            }
            arr.push({
              id: item.id,
              cost: cost,
              supplierProduceItemDeliveryCost: item.supplierProduceItemDeliveryCost,
              orderId: item.orderId,
              supplierId: item.fromSupplierId
            })
          }
          this.pageLoading = true
          updateDeliveryItemsCost()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('更新成功，页面已刷新。')
              this.reloadData()
            })
            .send(arr)
        }
      })
    },
    handleStartModifySupplier () {
      this.supplierEditing = true
      this.supplierBackup = []
      for (const item of this.dataList) {
        this.supplierBackup.push(item.fromSupplierId)
      }
    },
    handleCancelModifySupplier () {
      this.supplierEditing = false
      for (let i = 0; i < this.supplierBackup.length; i++) {
        this.dataList[i].fromSupplierId = this.supplierBackup[i]
      }
      this.supplierBackup = []
    },
    handleSubmitModifySupplier () {
      const arr = []
      for (const item of this.dataList) {
        arr.push({ id: item.id, supplierId: item.fromSupplierId })
      }
      this.pageLoading = true
      updateDeliveryItemsSupplier()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.supplierEditing = false
          this.$message.success('更新成功。')
          this.loadData()
        })
        .send(arr)
    }
  },
  mounted () {
    this.loadData()
    listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.no-price @{deep} .ant-input-number {
  border-color: #bb3e30;
}
</style>
