<template>
  <a-form-model :model="order" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
    <a-form-model-item label="包裹重量" prop="weight" class="app_required-input">
      <a-input-number v-model="order.package.weight" :step=".1" :formatter="value => `${value} 千克`" style="width: 200px;" />
      <div v-if="packageAdvice && packageAdvice.weightMax > 0"
           class="text-primary font-mini"
           :class="{'text-danger': order.package.weight <= packageAdvice.weightMin || order.package.weight > packageAdvice.weightMax}"
           style="line-height: normal;"
      >
        渠道建议：大于{{packageAdvice.weightMin}}kg，且小于等于{{packageAdvice.weightMax}}kg
      </div>
    </a-form-model-item>
    <a-form-model-item label="包裹长度" prop="length" class="app_required-input">
      <a-input-number v-model="order.package.length" :step=".1" :formatter="value => `${value} 厘米`" style="width: 200px;" />
      <div v-if="packageAdvice && packageAdvice.sizeLength > 0"
           class="text-primary font-mini"
           :class="{'text-danger': order.package.length > packageAdvice.sizeLength}"
           style="line-height: normal;"
      >
        渠道建议：小于等于{{packageAdvice.sizeLength}}cm
      </div>
    </a-form-model-item>
    <a-form-model-item label="包裹宽度" prop="width" class="app_required-input">
      <a-input-number v-model="order.package.width" :step=".1" :formatter="value => `${value} 厘米`" style="width: 200px;" />
      <div v-if="packageAdvice && packageAdvice.sizeWidth > 0"
           class="text-primary font-mini"
           :class="{'text-danger': order.package.width > packageAdvice.sizeWidth}"
           style="line-height: normal;"
      >
        渠道建议：小于等于{{packageAdvice.sizeWidth}}cm
      </div>
    </a-form-model-item>
    <a-form-model-item label="包裹高度" prop="height" class="app_required-input">
      <a-input-number v-model="order.package.height" :step=".1" :formatter="value => `${value} 厘米`" style="width: 200px;" />
      <div v-if="packageAdvice && packageAdvice.sizeHeight > 0"
           class="text-primary font-mini"
           :class="{'text-danger': order.package.height > packageAdvice.sizeHeight}"
           style="line-height: normal;"
      >
        渠道建议：小于等于{{packageAdvice.sizeHeight}}cm
      </div>
    </a-form-model-item>
    <a-form-model-item label="外包装数量" prop="packageTotal" class="app_required-input">
      <a-input-number v-model="order.package.total" :min="1" style="width: 200px;" />
    </a-form-model-item>
    <a-form-model-item>
      <a-button @click="$emit('packageChanged', order)" v-show="showButton">将当前数据应用到其他订单</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>

export default {
  props: {
    order: { type: Object },
    packageAdvice: { type: Object },
    showButton: { type: Boolean, default: false }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
