<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      :disableFooterButton="true"
      title="工厂订单详情"
      @close="onClose"
  >
    <div v-if="model">
      <div class="image-list">
        <div class="image-item"
             v-for="(img, index) in model.images"
             :key="index"
             :style="{'background-image': `url(${img})`}"
             @click="$imageModal.open(model.images, index)"
        >
        </div>
      </div>

      <div class="info-detail">
        <div class="detail-item">
          <label>规格</label>
          <span>{{model.variant}}</span>
        </div>
        <div class="detail-item">
          <label>数量</label>
          <span class="font-bold" style="color: #bb3e30;">x {{model.quantity}}</span>
        </div>
        <div class="detail-item">
          <label>备注</label>
          <span style="color: #bb3e30">{{model.remark}}</span>
        </div>
        <div class="detail-item" v-if="model.skuRemark">
          <label>备注2</label>
          <span style="color: #bb3e30">{{model.skuRemark}}</span>
        </div>
        <div class="detail-item" v-if="model.customizeInfo">
          <label>定制</label>
          <span>
            <div v-for="(item, index) in splitCustomize(model.customizeInfo)" :key="index" class="customize-item">
              {{item.name}}：{{item.value}} ({{item.unit}})
            </div>
          </span>
        </div>
        <div class="detail-item">
          <label>供应商</label>
          <span>{{model.supplierName}}</span>
        </div>
        <div class="detail-item">
          <label>下单人</label>
          <span>{{model.adminName}}</span>
        </div>
        <div class="detail-item">
          <label>创建时间</label>
          <span>{{model.createTime}}</span>
        </div>
        <div class="detail-item">
          <label>接单时间</label>
          <span>
            <span v-if="model.confirmTime">{{model.confirmTime}}</span>
            <span v-else>未接单</span>
            <span v-if="model.dayNewToAccept >= 0">
              <span v-if="model.confirmTime && model.dayNewToAccept === 0" class="text-success">（当天）</span>
              <span v-else-if="model.confirmTime" class="text-danger">（用时{{model.dayNewToAccept}}天）</span>
              <span v-else class="text-danger">（已过{{model.dayNewToAccept}}天）</span>
            </span>
          </span>
        </div>
        <div class="detail-item">
          <label>发货时间</label>
          <span>
            <span v-if="model.deliveryTime">{{model.deliveryTime}}</span>
            <span v-else>未发货</span>
            <span v-if="model.confirmTime && model.dayAcceptToDelivery >= 0">
              <span v-if="model.deliveryTime && model.dayAcceptToDelivery === 0" class="text-success">（当天）</span>
              <span v-else-if="model.deliveryTime" class="text-danger">（用时{{model.dayAcceptToDelivery}}天）</span>
              <span v-else class="text-danger">（已过{{model.dayAcceptToDelivery}}天）</span>
            </span>
          </span>
        </div>
        <div class="detail-item">
          <label>状态</label>
          <span>
            <div class="status" :class="'status-' + model.status">{{statusMapping[model.status]}}</div>
          </span>
        </div>
        <div class="detail-item">
          <label>延迟生产</label>
          <span>
            <span v-if="model.delay" class="font-bold text-danger">延迟</span>
            <span v-else class="text-success">正常生产</span>
          </span>
          <span style="text-align: right;" v-if="model.status === statusKeys.accept && isSupplier">
            <span style="font-size: 13px; color: #3d71a7;" @click="$refs.delayEditor.open(model)">设置延迟</span>
          </span>
        </div>
        <div class="detail-item" v-if="model.delay">
          <label>延迟原因</label>
          <span>{{model.delayReason}}</span>
        </div>
      </div>
    </div>

    <div v-if="model">
      <a-tabs default-active-key="logistics">
        <a-tab-pane key="logistics" tab="物流信息">
          <tab-shipment :item-id="model.id"></tab-shipment>
        </a-tab-pane>
        <a-tab-pane key="history" tab="历史状态">
          <tab-history :item-id="model.id"></tab-history>
        </a-tab-pane>
      </a-tabs>
    </div>
  </drawer>
</template>

<script>

import { getItemById } from '@/http/api/supplier-produce-item'
import {
  SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING,
  SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT
} from '@/constants/supplier-produce-item-status'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import CustomizeMixin from '@/mixins/customize'
import TabShipment from './shipment'
import TabHistory from './status-history'

export default {
  components: { TabShipment, TabHistory },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING,
      statusKeys: {
        accept: SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT
      }
    }
  },
  mixins: [CustomizeMixin],
  methods: {
    open (id) {
      this.visible = true
      getItemById()
        .success(resp => {
          this.model = new SupplierProduceItemModel(resp.data)
        })
        .send(id)
    },
    onClose () {
      this.model = null
    }
  }
}
</script>

<style lang="less" scoped>
.detail-item {
  font-size: 16px !important;
}

.customize-item {
  color: #a738f6;
}

.image-list {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  .image-item {
    margin-bottom: 10px;
  }
}

.status {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 12px;
}
.status-new {
  background-color: #606266;
}
.status-accept {
  background-color: #3d71a7;
}
.status-reject {
  background-color: #bb3e30;
}
.status-delivery {
  background-color: #368e0c;
}
.status-return {
  background-color: #de8418;
}
.status-delete {
  background-color: #000000;
}
</style>
