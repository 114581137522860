<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :loading="loading"
    :disableFooterButton="true"
    title="库存信息"
    :width="1000"
    @save="onSave"
    @close="onClose"
  >

    <div class="wrapper" style="display: flex;">

      <div class="left-wrapper" style="flex: .5; margin-right: 20px;" v-show="isUpdate">
        <!-- 仓库库存 -->
        <a-card title="库存">
          <a-button slot="extra" size="small" type="primary" @click="handleUpdateStockQuantity()" v-authority="$authority.skuStockManage">变更库存</a-button>

          <div class="list-group">
            <div class="list-group-item" v-for="s in stocks" :key="s.id">
              <div class="left-right-content">
                <div class="full">{{s.warehouseName}}</div>
                <div class="font-mini">剩余 <span class="font-large font-bold text-danger">{{s.quantity}}</span></div>
                <a-button size="small" @click="handleUpdateStockQuantity(s.warehouseId)" v-authority="$authority.skuStockManage">编辑</a-button>
              </div>
            </div>
            <a-empty v-show="stocks.length === 0" />
          </div>
        </a-card>

        <a-card title="成本参考" style="margin-top: 20px;">
          <a-button slot="extra" size="small" type="primary" @click="handleUpdateCost()" v-authority="$authority.skuCost">设置成本</a-button>

          <div class="list-group">
            <div class="list-group-item" v-for="s in costs" :key="s.id">
              <div class="left-right-content">
                <div class="full">
                  <span v-if="s.supplierId">{{s.supplierName}}</span>
                  <span v-else class="text-danger">通用</span>
                </div>
                <div>{{s.price}}</div>
                <a-button size="small" @click="handleUpdateCost(s.supplierId, s.price)" v-authority="$authority.skuCost">编辑</a-button>
              </div>
            </div>
            <a-empty v-show="costs.length === 0" />
          </div>
        </a-card>

        <stock-product-composite-editor style="margin-top: 20px;" :main-stock-product-id="modelId"></stock-product-composite-editor>

      </div>


      <div class="right-wrapper" style="flex: .5;">
        <!-- SKU标签 -->
        <a-card title="SKU" v-show="isUpdate">
          <div>
            <div v-if="model && model.coupleSku" style="text-align: center; padding: 5px 0;">
              <a-button-group>
                <a-button :type="skuType === 'couple' ? 'primary' : ''" @click="skuType = 'couple'">情侣</a-button>
                <a-button :type="skuType === 'man' ? 'primary' : ''" @click="skuType = 'man'">男</a-button>
                <a-button :type="skuType === 'women' ? 'primary' : ''" @click="skuType = 'women'">女</a-button>
              </a-button-group>
            </div>
            <img v-if="imageSku" id="sku-image" :src="imageSkuUrl" style="width: 100%; height: auto; max-height: 170px;" />

            <a-row style="margin-top: 10px;">
              <a-col :span="12">
                <a-dropdown-button>
                  <span v-if="skuSupplierId === null">通用SKU</span>
                  <span v-else>{{supplierOptions.find(item => item.id === skuSupplierId).name}}</span>
                  <a-menu slot="overlay" @click="handleSkuSupplierSwitchClick">
                    <a-menu-item key="default">通用SKU</a-menu-item>
                    <a-menu-item v-for="s in supplierOptions" :key="s.id">{{s.name}}</a-menu-item>
                  </a-menu>
                </a-dropdown-button>
              </a-col>
              <a-col :span="12">
                <a-button type="info" v-print="'#sku-image'">打印条码</a-button>
                <a-button type="info" @click="handleCopySku">复制SKU</a-button>
              </a-col>
            </a-row>
          </div>
        </a-card>


        <a-card title="基本信息" style="margin-top: 20px;">
          <div class="image-box">
            <div
                class="image-item large"
                style="margin: 10px auto;"
                :style="{'background-image': `url(${form.imageUrl})`}"
                @click="form.imageUrl && $imageModal.open(form.imageUrl)"
            >
              <span class="remove" @click.stop="form.imageUrl = null" v-show="editable"><a-icon type="close" /></span>
            </div>
            <a-button type="link" size="small" v-show="editable" @click="handleSelectImage">选择图片</a-button>
          </div>

          <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
            <a-form-model-item label="SKU" class="app_required-input" prop="sku">
              <a-input v-model="form.sku" :disabled="!editable || isUpdate"/>
            </a-form-model-item>
            <a-form-model-item label="标题" class="app_required-input" prop="title">
              <a-input v-model="form.title" :disabled="!editable"/>
            </a-form-model-item>
            <a-form-model-item label="标签" prop="tags">
              <a-input v-model="form.tags" :disabled="!editable"/>
            </a-form-model-item>
            <a-form-model-item label="规格" prop="variant">
              <a-input v-model="form.variant" :disabled="!editable"/>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input v-model="form.remark"/>
            </a-form-model-item>
            <a-form-model-item label="重量(kg)" prop="weight">
              <a-input-number v-model="form.skuWeight" :min="0" :precision="2" :step="0.1" />
            </a-form-model-item>
            <a-form-model-item label="长度(cm)" prop="length">
              <a-input-number v-model="form.skuLength" :min="0" />
            </a-form-model-item>
            <a-form-model-item label="宽度(cm)" prop="length">
              <a-input-number v-model="form.skuWidth" :min="0" />
            </a-form-model-item>
            <a-form-model-item label="高度(cm)" prop="length">
              <a-input-number v-model="form.skuHeight" :min="0" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
              <a-button type="primary" block @click="handleSaveStockProduct" :loading="formUpdating" v-authority="$authority.skuManage">保存</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-card>
      </div>

    </div>

    <!-- deprecated
    <modal ref="stockQuantityEditor"
           :max-height="450"
           title="库存编辑"
           :loading="stockQuantityEditor.loading"
           @cancel="onStockQuantityEditorCancel"
           @ok="onStockQuantityEditorOk"
    >
      <a-form-model ref="stockQuantityEditorForm" :model="stockQuantityEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}" class="small-form">

        <a-form-model-item label="仓库" prop="warehouseId">
          <a-select v-model="stockQuantityEditor.form.warehouseId" style="width: 100%;">
            <a-select-option v-for="w in warehouseOptions" :key="w.id" :value="w.id">{{w.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="数量" prop="quantity">
          <a-input-number v-model="stockQuantityEditor.form.quantity" />
          <span class="font-mini text-danger" style="margin-left: 10px;">正数加库存，负数减库存</span>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-textarea
              v-model="stockQuantityEditor.form.message"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </modal>
    -->

    <modal ref="costEditor"
           :max-height="450"
           title="成本编辑"
           :loading="costEditor.loading"
           @cancel="onCostEditorCancel"
           @ok="onCostEditorOk"
    >
      <a-form-model ref="costEditorForm" :model="stockQuantityEditor.form" :label-col="{span: 7}" :wrapper-col="{span: 17}" class="small-form">

        <a-form-model-item label="供应商" prop="supplierId">
          <a-select v-model="costEditor.form.supplierId" style="width: 100%;" :allow-clear="true">
            <a-select-option v-for="w in supplierOptions" :key="w.id" :value="w.id">{{w.name}}</a-select-option>
          </a-select>
          <p class="text-muted font-mini">如果不选择供应商，表示"通用"成本价格。</p>
        </a-form-model-item>
        <a-form-model-item label="成本（人民币）" prop="price">
          <a-input-number v-model="costEditor.form.price" style="width: 100%;" />
        </a-form-model-item>
      </a-form-model>
    </modal>

    <inventory-request-change-editor ref="inventoryEditor" @saved="loadStockQuantityBySku()"></inventory-request-change-editor>

  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { listSupplier } from '@/http/api/supplier'
import { getStockBySku, updateStockQuantityManual, getStockProductBySku, addStockProduct, updateStockProduct } from '@/http/api/stock-product'
import { listByStockProductId, updateCost } from '@/http/api/stock-product-cost'
import selectFile, { readFileAsDataUrl } from '@/utils/select-file'
import { appendSupplierCodeToSku } from '@/utils/sku-supplier-utils'
import StockProductCompositeEditor from './stock-product-composite-editor'
import InventoryRequestChangeEditor from '@/pages/stock/inventory/comp/request-editor'

export default {
  components: { StockProductCompositeEditor, InventoryRequestChangeEditor },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      loading: false,
      model: null,
      form: {
        sku: null,
        title: null,
        tags: null,
        variant: null,
        imageUrl: null,
        remark: null,
        skuWeight: null,
        skuLength: null,
        skuWidth: null,
        skuHeight: null
      },
      formUpdating: false,
      // 仓库库存
      stocks: [],
      skuType: 'couple',
      stockQuantityEditor: {
        loading: false,
        form: {
          warehouseId: null,
          sku: null,
          quantity: 1,
          message: null
        }
      },
      // 商品成本
      costs: [],
      costEditor: {
        loading: false,
        form: {
          supplierId: null,
          stockProductId: null,
          price: 0
        }
      },
      warehouseOptions: [],
      supplierOptions: [],
      skuSupplierButtonName: '通用SKU',
      skuSupplierId: null
    }
  },
  watch: {
    'form.id' (id) {
      if (id) {
      }
    }
  },
  computed: {
    modelId () {
      return this.model ? this.model.id : null
    },
    imageSku () {
      let sku = null
      if (this.model) {
        switch (this.skuType) {
          case 'couple':
            sku = this.model.sku
            break
          case 'man':
            sku = this.model.coupleSkuForMen
            break
          case 'women':
            sku = this.model.coupleSkuForWomen
            break
          default:
            sku = null
        }
      }
      if (sku == null) {
        return null
      } else {
        if (this.skuSupplierId) {
          return appendSupplierCodeToSku(sku, this.supplierOptions.find(item => item.id === this.skuSupplierId).code)
        } else {
          return sku
        }
      }
    },
    imageSkuUrl () {
      return '/api/barcode/sku?sku=' + this.imageSku
    },
    isUpdate () {
      return !!this.model
    },
    /**
     * StockProduct是否是手动添加的，而不是从其他地方导入的
     * @returns {boolean}
     */
    isManualSource () {
      if (this.model) {
        return this.model.source === 'manual'
      } else {
        return true
      }
    },
    editable () {
      return this.isManualSource
    }
  },
  methods: {
    open (model) {
      this.model = model
      this.visible = true
      if (model) {
        this.$nextTick(() => {
          kit.obj.getProperties(this.form, model)
        })
        this.loadStockQuantityBySku()
        this.loadCosts()
      }


      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }
      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }
    },
    openBySku (sku) {
      this.visible = true
      this.loading = true
      getStockProductBySku()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.open(resp.data)
        })
        .send(sku)
    },
    onClose () {
      this.$refs.form.resetFields()
      this.form.id = null
      this.form.imageUrl = null
      this.model = null
      this.$emit('closed')
    },
    onSave () {
    },
    loadStockQuantityBySku () {
      getStockBySku()
        .success(resp => {
          this.stocks = resp.data[this.model.sku] || []
        })
        .send(this.model.sku)
    },
    handleUpdateStockQuantity (warehouseId) {
      // if (warehouseId) {
      //   this.stockQuantityEditor.form.warehouseId = warehouseId || null
      // }
      // this.stockQuantityEditor.form.quantity = quantity || 1
      // this.stockQuantityEditor.form.sku = this.model.sku
      // this.$refs.stockQuantityEditor.open()
      this.$refs.inventoryEditor.open(
        null,
        [this.model.sku],
        { disableModifySku: true },
        {
          title: '库存变更-' + this.model.sku,
          toWarehouseId: warehouseId || null
        }
      )
    },
    onStockQuantityEditorOk () {
      this.stockQuantityEditor.loading = true
      updateStockQuantityManual()
        .complete(() => (this.stockQuantityEditor.loading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.loadStockQuantityBySku()
          this.$nextTick(() => {
            this.$refs.stockQuantityEditor.close()
          })
        })
        .send(this.stockQuantityEditor.form)
    },
    onStockQuantityEditorCancel () {
      this.$refs.stockQuantityEditorForm.resetFields()
    },
    loadCosts () {
      listByStockProductId()
        .success(resp => (this.costs = resp.data))
        .send(this.model.id)
    },
    handleUpdateCost (supplierId, price) {
      this.costEditor.form.price = price
      this.costEditor.form.stockProductId = this.model.id
      this.costEditor.form.supplierId = supplierId || null
      this.$refs.costEditor.open()
    },
    onCostEditorOk () {
      this.costEditor.loading = true
      updateCost()
        .complete(() => (this.costEditor.loading = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.loadCosts()
          this.$nextTick(() => {
            this.$refs.costEditor.close()
          })
        })
        .send(this.model.id, this.costEditor.form.supplierId, this.costEditor.form.price)
    },
    onCostEditorCancel () {
      this.$refs.costEditorForm.resetFields()
    },
    handleSelectImage () {
      selectFile({
        accept: ['image/jpg', 'image/png', 'image/jpeg'],
        multi: false,
        select: files => {
          readFileAsDataUrl(files[0], (success, result) => {
            if (success) {
              this.form.imageUrl = result
            }
          })
        }
      })
    },
    handleSaveStockProduct () {
      const data = Object.assign({}, this.form)
      this.formUpdating = true
      const api = this.isUpdate ? updateStockProduct : addStockProduct
      api()
        .complete(() => (this.formUpdating = false))
        .success(resp => {
          this.$message.success('保存成功。')
          if (!this.isUpdate) {
            this.open(resp.data)
          }
        })
        .send(data)
    },
    handleSkuSupplierSwitchClick (e) {
      if (e.key === 'default') {
        this.skuSupplierId = null
      } else {
        this.skuSupplierId = e.key
      }
    },
    handleCopySku () {
      this.$copyText(this.imageSku).then(() => {
        this.$message.success('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.image-box {
  text-align: center;
  margin-bottom: 15px;
}
.image-item {
  overflow: visible;
}
.image-item .remove {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
  background-color: #db2c2c;
  color: #fff;
  @size: 25px;
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 30px;
  font-size: 12px;
  text-align: center;
}
</style>
