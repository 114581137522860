<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="添加达人"
    @close="onClose"
    @save="handleSave"
  >
    <a-form-model :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="达人唯一标识" class="app_required-input">
        <a-input v-model="form.cid"></a-input>
      </a-form-model-item>
      <a-form-model-item label="昵称">
        <a-input v-model="form.name"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址">
        <a-input v-model="form.email"></a-input>
      </a-form-model-item>

      <a-form-model-item label="性别">
        <a-select v-model="form.sex" :allow-clear="true">
          <a-select-option :value="1">男</a-select-option>
          <a-select-option :value="0">女</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="分类">
        <a-select v-model="form.influencerCategoryId" :allow-clear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0;" />
            <div style="padding: 4px 8px; cursor: pointer;" @mousedown="e => e.preventDefault()" @click="$refs.categoryManager.open()">
              <a-icon type="plus" /> 添加新分类
            </div>
          </div>
          <a-select-option v-for="s in commonCategory" :key="s.id">
            <span style="display: inline-block;" :style="{'padding-left': !s.parent ? '30px' : '0'}">{{s.name}}</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item label="标签">
        <a-select mode="tags" style="width: 100%" v-model="form.tags">
          <a-select-option v-for="tag in commonTags" :key="tag">{{tag}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="粉丝数">
        <a-input-number v-model="form.followerCount" :precision="0"></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="平均播放量">
        <a-input-number v-model="form.viewCount" :precision="0"></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="成交金额($)">
        <a-input-number v-model="form.transactionAmount" :precision="2"></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="备注">
        <a-textarea v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
      </a-form-model-item>
    </a-form-model>

    <influencer-category-manager ref="categoryManager" @updated="onInfluencerCategoryManagerUpdated"></influencer-category-manager>
  </drawer>
</template>

<script>

import { listOptions as listCooperationStatus } from '@/http/api/cooperation-status'
import { listAll as listCategoryOptions } from '@/http/api/influencer-category'
import { add, update, listTags } from '@/http/api/influencer'
import InfluencerCategoryManager from '@/pages/influencer/comp/influencer-category-manager'
import kit from '@/utils/kit'

function createForm () {
  return {
    id: null,
    cid: null,
    name: null,
    email: null,
    sex: null,
    followerCount: 0,
    viewCount: 0,
    transactionAmount: 0,
    remark: null,
    influencerCategoryId: null,
    tags: []
  }
}

export default {
  components: {
    InfluencerCategoryManager,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: createForm(),
      cooperationStatus: [],
      commonTags: [],
      commonCategory: []
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    open (model) {
      this.visible = true

      this.model = model
      if (model) {
        const tmp = Object.assign({}, model)
        tmp.tags = tmp.tags ? tmp.tags.split(',') : []
        kit.obj.getProperties(this.form, tmp)
      }

      this.loadCooperationStatus()
      this.loadInfluencerCategories()
      listTags().success(resp => (this.commonTags = resp.data)).send()
    },
    onClose () {
      this.form = createForm()
      this.model = null
    },
    onCooperationStatusManagerUpdated () {
      this.loadCooperationStatus()
    },
    onInfluencerCategoryManagerUpdated () {
      this.loadInfluencerCategories()
    },
    loadCooperationStatus () {
      listCooperationStatus().success(resp => (this.cooperationStatus = resp.data)).send()
    },
    loadInfluencerCategories () {
      listCategoryOptions().success(resp => {
        const arr = []
        for (const p of resp.data) {
          arr.push({ id: p.id, name: p.name, parent: true })
          for (const c of p.children) {
            arr.push({ id: c.id, name: c.name, parent: false })
          }
        }
        this.commonCategory = arr
      }).send()
    },
    handleSave () {
      const data = Object.assign({}, this.form)
      if (data.tags.length === 0) {
        data.tags = null
      } else {
        data.tags = data.tags.join(',')
      }
      let api
      if (this.model) {
        api = update
      } else {
        api = add
      }
      this.saveButtonLoading = true
      api()
        .complete(() => (this.saveButtonLoading = false))
        .success(resp => {
          this.visible = false
          this.$message.success('保存成功。')
          this.$emit('saved', resp.data)
        })
        .send(data)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
