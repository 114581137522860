<template>
  <modal ref="pr" :title="title" :width="500" @closed="onClosed" :hide-buttons="true">
    <div v-if="order" style="max-height: 400px; overflow-y: auto;">
      <multi-delivery-parcel-content :show-button="true" :order="order" @parcelChanged="$emit('parcelChanged', order)"></multi-delivery-parcel-content>
    </div>
  </modal>
</template>

<script>
import MultiDeliveryParcelContent from '@/pages/delivery/comp/multi-delivery-parcel-content'
export default {
  components: { MultiDeliveryParcelContent },
  data () {
    return {
      order: null
    }
  },
  computed: {
    title () {
      return this.order ? '物流包装 - ' + this.order.orderNo : '物流包装'
    }
  },
  methods: {
    open (order) {
      this.order = order
      this.$refs.pr.open()
    },
    onClosed () {
      this.order = null
    }
  }
}
</script>

<style lang="less" scoped>
</style>
