<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="有转化">
          <a-select v-model="searchParams.hasConversion" style="width: 120px;" :allow-clear="true">
            <a-select-option :value="item.id" v-for="item in [
                  { id: 'true', name: '有转化' },
                  { id: 'false', name: '无转化' }]"
                             :key="item.id"
            >
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="有点击">
          <a-select v-model="searchParams.hasClick" style="width: 120px;" :allow-clear="true">
            <a-select-option :value="item.id" v-for="item in [
                  { id: 'true', name: '有点击' },
                  { id: 'false', name: '无点击' }]"
                             :key="item.id"
            >
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.sendStatus" style="width: 120px;" :allow-clear="true">
            <a-select-option :value="item.id" v-for="item in [
                  { id: 'wait', name: '正在发送' },
                  { id: 'sent', name: '已发送' }]"
                             :key="item.id"
            >
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="status" slot-scope="sendStatus">
        <span v-if="sendStatus === 'wait'" class="text-muted font-mini">正在发送</span>
        <span v-else class="text-success font-mini">已发送</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_EDM_ACTIVITY_EMAILS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { listEmails } from '@/http/api/edm-activity'

export default {
  data () {
    return {
      routeName: ROUTE_EDM_ACTIVITY_EMAILS,
      searchParams: {
        hasConversion: null,
        hasClick: null,
        sendStatus: null
      },
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '邮箱', dataIndex: 'email', width: 300 },
        { title: '转化金额($)', dataIndex: 'amount', width: 100 },
        { title: '点击项', dataIndex: 'clickObject' },
        { title: '状态', dataIndex: 'sendStatus', width: 140, scopedSlots: { customRender: 'status' } },
        { title: '发送时间', dataIndex: 'sendTime', width: 140 }
      ]
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  computed: {
    activityId () {
      return this.$route.params.activityId
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      p.activityId = this.activityId
      this.dataLoading = true
      listEmails()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style scoped>

</style>
