import kit from '@/utils/kit'

export const STATUS_WAIT_TRACK_NO = 'waitTrackNo'
export const STATUS_SHIPPED = 'shipped'
export const STATUS_DELIVERED = 'delivered'
export const STATUS_LOSE = 'lose'

export const ORDER_RETURN_STATUS_MAPPING = {
  [STATUS_WAIT_TRACK_NO]: '等客户发货',
  [STATUS_SHIPPED]: '运输中',
  [STATUS_DELIVERED]: '已签收',
  [STATUS_LOSE]: '丢失'
}

export const ORDER_RETURN_STATUS_LIST = kit.obj.toArray(ORDER_RETURN_STATUS_MAPPING)
