<template>
  <div class="container">

    <div class="card-panel product-panel" :class="{'mini': productPanel.min}">
      <div class="header">
        <span class="title">
          <span v-if="productPanel.min">产品</span>
          <span v-else>订单产品</span>
        </span>
        <div class="tools">
          <a-button size="small" @click="loadProducts()" icon="reload" v-show="!productPanel.min"></a-button>
          <a-button size="small" :icon="productPanel.min ? 'right' : 'left'" @click="productPanel.min = !productPanel.min"></a-button>
        </div>
      </div>
      <div class="body">
        <ul class="products">
          <li v-for="p in productPanel.products" :key="p.id">
            <div class="main-content">
              <span class="total">{{p.total}}</span>
              <div class="image-item small cover" :style="{'background-image': `url(${p.image})`}" @click="$imageModal.open(p.image)">
              </div>
              <div class="description">
                <h4 class="title">{{p.title}}</h4>
                <div class="sub-title" v-show="p.variant">规格：{{p.variant}}</div>
                <div class="sub-title" v-show="p.sku">
                  SKU：{{p.sku}}
                  <a-tooltip placement="right" v-if="hasStocks(productPanel.skuStocks[p.sku])">
                    <template slot="title">
                      <ul style="margin: 0; padding: 0;">
                        <li v-for="stock in productPanel.skuStocks[p.sku]" :key="stock.id">
                          {{stock.warehouseName}}：{{stock.quantity}}
                        </li>
                      </ul>
                    </template>
                    <span class="text-primary">有库存</span>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="delivery-info">
              <div class="info-row">
                <label>工厂下单</label>
                <span>
                  <a-icon v-if="p.produce" type="check" style="color: #368e0c;" />
                  <a-icon v-else type="close" size="18px" style="color: #bb3e30;" />
                </span>
              </div>
              <div class="info-row">
                <label>工厂快递</label>
                <span>
                  <span v-if="p.shipStatus === 0" class="text-danger font-bold">运输</span>
                  <a-icon v-else-if="p.shipStatus === 1" type="check" style="color: #368e0c;" />
                  <a-icon v-else type="close" style="color: #bb3e30;" />
                </span>
              </div>
              <div class="info-row">
                <label>客户发货</label>
                <a-icon v-if="p.deliveryTotal === 0" type="close" style="color: #bb3e30;"/>
                <span v-else style="color: #3d71a7;">{{p.deliveryTotal}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-panel delivery-panel">
      <div class="header">
        <span class="title">发货记录</span>
        <div class="tools">
          <a-button size="small" @click="loadOrderDeliveries()" icon="reload"></a-button>
          <a-button size="small" type="primary" @click="handleDelivery" v-authority="$authority.orderDelivery">发货</a-button>
          <a-button size="small"
                    :style="{'background-color': order && order.allShip ? '#ddffe2' : '#fff'}"
                    @click="handleMarkAllShip"
                    v-authority="$authority.orderUpdateAllShip"
                    :loading="allShipUpdating">
            <span v-if="order && order.allShip">设置为部分发货</span>
            <span v-else>设置为发货全部完成</span>
          </a-button>
        </div>
      </div>
      <div class="body" ref="dataContainer">
        <a-table
            size="middle"
            rowKey="id"
            :columns="deliveryPanel.dataColumns"
            :data-source="deliveryPanel.dataList"
            :pagination="false"
            :scroll="{ y: antdTableY, x: tableX }"
            :loading="deliveryPanel.dataLoading"
        >
          <div slot="action" slot-scope="v, record">
            <a-dropdown v-authority="$authority.orderDelivery">
              <a-menu slot="overlay" @click="handleMenuClick($event, record)">
                <a-menu-item key="cancel" v-show="record.status === statusMap.shipped || record.status === statusMap.waitTrack">
                  <span class="text-danger">取消发货</span>
                </a-menu-item>
                <!-- deprecated
                <a-menu-item key="item">编辑发货项</a-menu-item>
                -->
                <a-menu-item key="toSupplierDelivery"
                             v-show="!record.supplierOrderDeliveryId && (record.status === statusMap.waitTrack || record.status === statusMap.shipped)">
                  转给厂商发货
                </a-menu-item>
                <a-menu-item key="cancelSupplierDelivery" v-show="!!record.supplierOrderDeliveryId">取消厂商发货</a-menu-item>
                <a-menu-item key="prepareYes" v-show="record.prepareStatus === orderDeliveryPrepareStatus.no">配货</a-menu-item>
                <a-menu-item key="prepareNo" v-show="record.prepareStatus === orderDeliveryPrepareStatus.yes">取消配货</a-menu-item>
              </a-menu>
              <a-button style="margin-left: 8px"> 操作 <a-icon type="down" /> </a-button>
            </a-dropdown>
          </div>
          <div slot="status" slot-scope="status">
            <span class="delivery-status" :class="'status-' + status">{{orderDeliveryStatusTextMapping[status]}}</span>
          </div>
          <div slot="trackNo" slot-scope="trackNo, record">
            <a v-if="trackNo" target="_blank" :href="record.trackUrl">
              {{trackNo}}
            </a>
          </div>
          <div slot="companyId" slot-scope="companyId, record">
            <span v-if="record.supplierOrderDeliveryId">
              厂商自发：{{logisticsCompanyTextMapping[companyId]}} / {{record.supplierOrderDeliverySupplierName}}（{{supplierOrderDeliveryMapping[record.supplierOrderDeliveryStatus]}}）
            </span>
            <span v-else>{{logisticsCompanyTextMapping[companyId]}} / {{record.shippingMethod}}</span>
          </div>
          <div slot="regT17track" slot-scope="regT17track">
            <span v-if="regT17track" class="t17track-reg">17Track</span>
          </div>
          <div slot="items" slot-scope="items">
            <div class="image-item-wrapper">
              <a-tooltip v-for="item in items" :key="item.id">
                <template slot="title">
                  <div>SKU：{{item.sku}}</div>
                  <div>规格：{{item.variant}}</div>
                  <div>厂商：{{item.fromSupplierName}}</div>
                  <div>总成本：{{item.cost}}</div>
                </template>
                <div class="image-item small cover" v-lazy:background-image="item.imageUrl" @click="$imageModal.open(item.imageUrl)">
                  <span class="total" :class="{'total-more': item.total > 1}">{{item.total}}</span>
                </div>
              </a-tooltip>
            </div>
          </div>
          <div slot="arrivedTime" slot-scope="time, record">
            {{time}}<span v-if="time" class="text-success font-mini">（{{record | deliveryUseDays}}天）</span>
          </div>
        </a-table>
      </div>
    </div>

    <delivery-product-editor ref="deliveryProductEditor"></delivery-product-editor>
    <multi-delivery-editor ref="multiDelivery" @deliveryCompleted="loadOrderDeliveries()"></multi-delivery-editor>


    <modal ref="supplierOrderDeliveryEditor"
           title="发货交给厂商"
           @ok="onTransferToSupplierDeliveryConfirm"
           :loading="supplierOrderDeliveryEditor.loading"
    >
      <a-select v-model="supplierOrderDeliveryEditor.selectedSupplierId" style="width: 100%;" :allowClear="false">
        <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
      </a-select>
    </modal>
  </div>
</template>

<script>
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { getProducts, listByOrder, cancelDelivery, updateOrderDeliveryPrepareStatus } from '@/http/api/order-delivery'
import { getStockBySku } from '@/http/api/stock-product'
import { LOGISTICS_COMPANY_MAPPING, LOGISTICS_COMPANY_SUPPLIER } from '@/constants/logistics'
import { SUPPLIER_ORDER_DELIVERY_MAPPING } from '@/constants/supplier-order-delivery-status'
import { listSupplier } from '@/http/api/supplier'
import { addSupplierOrderDelivery, deleteSupplierOrderDeliveryByOrderDeliveryId } from '@/http/api/supplier-order-delivery'
import { updateAllShipStatus } from '@/http/api/order'
import {
  ORDER_DELIVERY_STATUS_MAPPING,
  ORDER_DELIVERY_STATUS_SHIPPED,
  ORDER_DELIVERY_STATUS_WAIT_TRACK,
  ORDER_DELIVERY_STATUS_CANCEL,
  ORDER_DELIVERY_STATUS_EXCEPTION
} from '@/constants/order-delivery-status'
import { ORDER_DELIVERY_PREPARE_STATUS, ORDER_DELIVERY_PREPARE_STATUS_MAPPING } from '@/constants/order-delivery-prepare-status'
import moment from 'moment'
import DeliveryProductEditor from './comp/delivery-product-editor'
import MultiDeliveryEditor from '@/pages/delivery/comp/multi-delivery'

export default {
  props: {
    order: { type: Object }
  },
  components: { DeliveryProductEditor, MultiDeliveryEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      productPanel: {
        min: false, // 最小化
        products: [],
        skuStocks: {}
      },
      deliveryPanel: {
        dataLoading: false,
        dataList: [],
        dataColumns: [
          { title: '操作', dataIndex: 'action', width: 100, scopedSlots: { customRender: 'action' }, fixed: 'left' },
          { title: '客户单号', dataIndex: 'orderNo', width: 150, fixed: 'left' },
          { title: '运单号', dataIndex: 'trackNo', width: 160, scopedSlots: { customRender: 'trackNo' } },
          { title: '状态', dataIndex: 'status', width: 100, scopedSlots: { customRender: 'status' } },
          { title: '配货状态', dataIndex: 'prepareStatus', width: 100, customRender: v => ORDER_DELIVERY_PREPARE_STATUS_MAPPING[v] },
          { title: '发货清单', dataIndex: 'deliveryProductItems', width: 160, scopedSlots: { customRender: 'items' } },
          { title: '备注', dataIndex: 'remark', width: 250 },
          { title: '物流', dataIndex: 'companyId', width: 260, scopedSlots: { customRender: 'companyId' } },
          { title: '发货日期', dataIndex: 'createTime', width: 120 },
          { title: '签收日期', dataIndex: 'arrivedTime', width: 150, scopedSlots: { customRender: 'arrivedTime' } },
          { title: '运费', dataIndex: 'fee', width: 80 },
          { title: '重量kg', dataIndex: 'weight', width: 80 },
          { title: 'T17Track', dataIndex: 'regT17track', width: 80, scopedSlots: { customRender: 'regT17track' } },
          { title: '客户国家', dataIndex: 'countryCode', width: 80 },
          { title: '客户', dataIndex: 'customerName', width: 140 },
          { title: '店铺', dataIndex: 'shopName', width: 100 }
        ]
      },
      logisticsCompanyTextMapping: LOGISTICS_COMPANY_MAPPING,
      logisticsCompanyMapping: {
        supplier: LOGISTICS_COMPANY_SUPPLIER
      },
      orderDeliveryStatusTextMapping: ORDER_DELIVERY_STATUS_MAPPING,
      statusMap: {
        shipped: ORDER_DELIVERY_STATUS_SHIPPED,
        waitTrack: ORDER_DELIVERY_STATUS_WAIT_TRACK,
        cancel: ORDER_DELIVERY_STATUS_CANCEL,
        exception: ORDER_DELIVERY_STATUS_EXCEPTION
      },
      orderDeliveryPrepareStatus: ORDER_DELIVERY_PREPARE_STATUS,
      supplierOrderDeliveryMapping: SUPPLIER_ORDER_DELIVERY_MAPPING,
      supplierOptions: [],
      supplierOrderDeliveryEditor: {
        selectedSupplierId: null,
        model: null,
        loading: false
      },
      allShipUpdating: false
    }
  },
  filters: {
    deliveryUseDays (data) {
      const createTime = moment(data.createTime)
      const arrivedTime = moment(data.arrivedTime)
      return arrivedTime.diff(createTime, 'days')
    }
  },
  computed: {
    tableX () {
      let w = 0
      for (const c of this.deliveryPanel.dataColumns) {
        if (c.width) {
          w += c.width
        }
      }
      return w
    },
    orderId () {
      return this.order ? this.order.id : null
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
      this.$nextTick(() => {
        this.contentHeight = this.$refs.dataContainer.clientHeight
      })
    },
    onInactive () {
    },
    onInit () {
      this.reload()
    },
    reload () {
      this.loadProducts()
      this.loadOrderDeliveries()
    },
    loadSkuStocks (skuList) {
      if (skuList && skuList.length > 0) {
        getStockBySku()
          .success(resp => {
            this.productPanel.skuStocks = resp.data || {}
          })
          .send(skuList.join(','))
      }
    },
    loadProducts () {
      getProducts()
        .success(resp => {
          this.productPanel.products = resp.data
          const skuList = []
          for (const p of this.productPanel.products) {
            if (p.sku) {
              skuList.push(p.sku)
            }
          }
          this.loadSkuStocks(skuList)
        })
        .send(this.orderId)
    },
    loadOrderDeliveries () {
      this.deliveryPanel.dataLoading = true
      listByOrder()
        .complete(() => (this.deliveryPanel.dataLoading = false))
        .success(resp => {
          for (const item of resp.data) {
            item._loading = false
          }
          this.deliveryPanel.dataList = resp.data
        })
        .send(this.orderId)
    },
    handleCancelDelivery (item) {
      this.$confirm({
        title: '确认',
        content: `确定要取消【${item.orderNo}】的发货吗？`,
        onOk: () => {
          item._loading = true
          cancelDelivery()
            .complete(() => (item._loading = false))
            .success(resp => {
              this.loadOrderDeliveries()
            })
            .send(item.id)
        }
      })
    },
    handleDelivery () {
      if (this.order) {
        this.$refs.multiDelivery.open([this.order.orderNo])
      }
    },
    handleMenuClick (e, row) {
      switch (e.key) {
        case 'cancel':
          this.handleCancelDelivery(row)
          break
        case 'item':
          this.$refs.deliveryProductEditor.open(this.orderId, row.deliveryProductItems)
          break
        case 'toSupplierDelivery':
          this.supplierOrderDeliveryEditor.model = row
          this.$refs.supplierOrderDeliveryEditor.open()
          break
        case 'cancelSupplierDelivery':
          this.cancelTransferToSupplierDelivery(row)
          break
        case 'prepareYes':
          this.updatePrepareStatus(row.id, this.orderDeliveryPrepareStatus.yes)
          break
        case 'prepareNo':
          this.updatePrepareStatus(row.id, this.orderDeliveryPrepareStatus.no)
          break
      }
    },
    /**
     * 将发货交给厂商
     */
    onTransferToSupplierDeliveryConfirm () {
      if (!this.supplierOrderDeliveryEditor.selectedSupplierId) {
        return this.$message.error('请选择厂商。')
      }
      this.supplierOrderDeliveryEditor.loading = true
      addSupplierOrderDelivery()
        .complete(() => (this.supplierOrderDeliveryEditor.loading = false))
        .success(() => {
          this.loadOrderDeliveries()
          this.$message.success('已经转交给厂商。')
          this.$nextTick(() => {
            this.$refs.supplierOrderDeliveryEditor.close()
          })
        })
        .send(
          this.supplierOrderDeliveryEditor.selectedSupplierId,
          this.supplierOrderDeliveryEditor.model.ordersId,
          this.supplierOrderDeliveryEditor.model.id
        )
    },
    cancelTransferToSupplierDelivery (row) {
      this.$confirm({
        title: '确认',
        content: '确定要取消吗？',
        onOk: () => {
          deleteSupplierOrderDeliveryByOrderDeliveryId()
            .success(() => {
              this.loadOrderDeliveries()
              this.$message.success('取消成功。')
            })
            .send(row.id)
        }
      })
    },
    hasStocks (skuStock) {
      if (skuStock) {
        let quantity = 0
        for (const item of skuStock) {
          quantity += item.quantity
        }
        return quantity > 0
      }
      return false;
    },
    updatePrepareStatus (id, status) {
      updateOrderDeliveryPrepareStatus()
        .success(() => {
          this.loadOrderDeliveries()
        })
        .send([id], status)
    },
    handleMarkAllShip () {
      this.allShipUpdating = true
      updateAllShipStatus()
        .complete(() => (this.allShipUpdating = false))
        .success(() => {
          this.order.allShip = !this.order.allShip
          this.$message.success('更新成功。')
        })
        .send(this.orderId, !this.order.allShip)
    }
  },
  mounted () {
    listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

.container {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;

  .product-panel {
    width: 300px;
    min-width: 300px;
    margin-right: 20px;
    &.mini {
      @miniWidth: 100px;
      min-width: @miniWidth;
      width: @miniWidth;
    }
  }

  .delivery-panel {
    flex: 1;
    overflow: hidden;
    .body {
      overflow: hidden;
    }
  }
}

.card-panel {
  display: flex;
  flex-direction: column;
  border: solid 1px #eee;

  .header {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-bottom: solid 1px #eee;
    background-color: #f9f9f9;
    .title {
      flex: 1;
      font-weight: bold;
    }
    .tools {
      margin-left: 10px;
      text-align: right;
    }
  }

  .body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
}

.product-panel {
  .products {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 10px 5px;
      &:not(:first-of-type) {
        border-top: solid 1px #ddd;
      }
      &:hover {
        background-color: #f9f9f9;
      }
    }
    .main-content {
      position: relative;
      display: flex;

      .total {
        position: absolute;
        left: -10px;
        top: -5px;
        z-index: 10;
        padding: 2px 6px;
        border-radius: 8px;
        background-color: #bb3e30;
        color: #fff;
        font-size: 11px;
        strong {
          margin-left: 5px;
          font-size: 13px;
        }
      }

      .description {
        flex: 1;
        margin-left: 10px;
        .title {
          font-size: 13px;
          color: #535353;
        }
        .sub-title {
          font-size: 12px;
        }
      }
    }

    .delivery-info {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 0;
      margin-top: 5px;
      font-size: 12px;
      background-color: #f2f2f2;
      border-radius: 5px;
      .info-row:nth-child(2) {
        margin: 0 20px;
      }
      .info-row label {
        margin-right: 4px;
      }
      .info-row span {
        font-weight: bold;
      }
    }
  }

  &.mini {

    .image-item {
      width: 65px;
      height: 65px;
    }
    .main-content .description {
      display: none;
    }

    .delivery-info {
      display: none;
    }
  }
}

.delivery-panel {
  .image-item {
    overflow: visible;
    .total {
      position: absolute;
      right: -8px;
      top: -8px;
      z-index: 1;
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 100%;
      background-color: #6ca2e3;
      color: #fff;
      text-align: center;
      font-size: 12px;
    }
    .total-more {
      background-color: #bb3e30;
    }
  }
}


.delivery-status {
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 4px;
}
.status-shipped {
  background-color: #0071f6;
}
.status-arrived {
  background-color: #0c9700;
}
.status-ex {
  background-color: #ce0b0b;
}
.status-exSolved {
  color: #0c9700;
  background-color: #f6c1c1;
}
.status-waitTrack {
  background-color: #797979;
}
.status-cancel {
  background-color: #ee9100;
}

</style>
