<template>
  <v-chart ref="chart" :option="option" :update-options="{ notMerge: true }"/>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])

export default {
  props: {
    title: { type: String },
    seriesTitle: { type: String },
    // {name, value}
    data: { type: Array },
    showLegend: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  components: {
    VChart
  },
  provide: {
  },
  data () {
    const labels = []
    for (const item of this.data) {
      labels.push(item.name)
    }
    return {
      option: {
        title: {
          show: !!this.title,
          text: this.title,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          show: this.showLegend,
          orient: 'vertical',
          left: 'left',
          data: labels
        },
        series: [
          {
            name: this.seriesTitle,
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    loading (f) {
      if (f) {
        this.$refs.chart.showLoading()
      } else {
        this.$refs.chart.hideLoading()
      }
    },
    data () {
      this.option.series[0].data = [...this.data]
      this.$refs.chart.setOption(this.option)
    }
  }
}
</script>

<style scoped>
</style>
