<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :title="title"
    :disable-footer-button="true"
    :width="700"
    @close="onClose"
  >
    <a-tabs v-model="selectedTab">
      <a-tab-pane key="base" tab="基本信息">
        <a-form-model v-if="model" ref="shopForm" :model="shopForm" :label-col="{span: 6}" :wrapper-col="{span: 18}">
          <a-form-model-item label="店铺名称" class="app_required-input">
            <a-input v-model="shopForm.name"/>
          </a-form-model-item>
          <a-form-model-item label="店铺编号">
            <a-input v-model="shopForm.shopCode"/>
          </a-form-model-item>
          <a-form-model-item label="域名">
            <a-input v-model="model.domain"/>
          </a-form-model-item>
          <a-form-model-item label="店铺地址">
            <a-input v-model="model.storeUrl"/>
          </a-form-model-item>
          <a-form-model-item label="订单ID前缀">
            <a-input v-model="model.orderPrefix" :disabled="true"/>
          </a-form-model-item>
          <a-form-model-item label="主要货币">
            <a-input v-model="shopForm.currency" placeholder="三位大小字母，如：USD"/>
          </a-form-model-item>
          <a-form-model-item label="API Key">
            <a-input v-model="shopForm.apiKey"/>
          </a-form-model-item>
          <a-form-model-item label="API Secret">
            <a-input v-model="shopForm.apiSecret"/>
          </a-form-model-item>
          <a-form-model-item label="API授权地址">
            <a-input v-model="shopForm.apiAuthUrl"/>
          </a-form-model-item>
          <a-form-model-item label="API Token">
            <a-input v-model="shopForm.apiToken"/>
          </a-form-model-item>
          <a-form-model-item label="API Refresh Token">
            <a-input v-model="shopForm.apiRefreshToken"/>
            <div class="text-muted font-mini" v-if="model && model.apiRefreshTokenExpire">有效期{{model.apiRefreshTokenExpire}}</div>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-input v-model="shopForm.remark"></a-input>
            <div class="text-muted font-mini">TikTok店铺在授权成功后会自动设置为卖家名称。</div>
          </a-form-model-item>
          <a-form-model-item label="订单定时同步" class="app_required-input">
            <a-switch v-model="shopForm.syncEnabled"></a-switch>
          </a-form-model-item>
          <a-form-model-item label="发货同步" class="app_required-input">
            <a-switch v-model="shopForm.syncDeliveryEnabled"></a-switch>
            <div class="text-muted font-mini">发货时，是否将运单号同步到店铺平台。</div>
          </a-form-model-item>
          <a-form-model-item label="未找到支付网关手续费配置时是否提醒" class="app_required-input">
            <a-switch v-model="shopForm.notifyOnPaymentMissing"></a-switch>
          </a-form-model-item>
          <a-form-model-item label="可用状态" class="app_required-input">
            <a-switch v-model="shopForm.enabled"></a-switch>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleSaveShop" :loading="saveButtonLoading">保存</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>



      <a-tab-pane key="email" tab="邮箱客户端">
        <a-form-model ref="emailForm" :model="emailForm" :label-col="{span: 4}" :wrapper-col="{span: 20}">
          <a-form-model-item label="邮箱" prop="email" class="app_required-input">
            <a-input v-model="emailForm.email"/>
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password" class="app_required-input">
            <a-input v-model="emailForm.password"/>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name" class="app_required-input">
            <a-input v-model="emailForm.name"/>
          </a-form-model-item>
          <a-form-model-item label="服务器" prop="host" class="app_required-input">
            <a-input v-model="emailForm.host"/>
          </a-form-model-item>
          <a-form-model-item label="端口" prop="port" class="app_required-input">
            <a-input v-model="emailForm.port"/>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleSaveEmailClient" :loading="saveButtonLoading">保存</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>

      <a-tab-pane key="fee" tab="店铺佣金配置" class="payment-gateway-config">
        <fee-config-panel :shop-id-list="shopIdList" :pay-fee-config-json="payFeeConfigJson" @saved="onPaymentGatewayConfigSaved"></fee-config-panel>
      </a-tab-pane>
    </a-tabs>
  </drawer>
</template>

<script>

import kit from '@/utils/kit'
import { updateShop } from '@/http/api/shop'
import { getEmailSetting, updateEmailSetting } from '@/http/api/shop-email-client'
import FeeConfigPanel from './fee-config-panel'

function createShopForm () {
  return {
    id: null,
    name: null,
    shopCode: null,
    apiSecret: null,
    apiKey: null,
    apiToken: null,
    apiRefreshToken: null,
    syncEnabled: true,
    enabled: true,
    currency: null,
    notifyOnPaymentMissing: true,
    apiAuthUrl: null,
    remark: null,
    syncDeliveryEnabled: true
  }
}

function createEmailForm () {
  return {
    id: null,
    host: null,
    email: null,
    name: null,
    password: null,
    port: null
  }
}

export default {
  components: { FeeConfigPanel },
  props: {
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      selectedTab: 'base',
      shopForm: createShopForm(),
      emailForm: createEmailForm()
    }
  },
  watch: {
  },
  computed: {
    title () {
      return this.model ? '店铺-' + this.model.id : '店铺'
    },
    shopIdList () {
      return this.model ? [this.model.id] : []
    },
    payFeeConfigJson () {
      return this.model ? this.model.payFeeConfig : null
    }
  },
  methods: {
    open (model) {
      this.model = model
      this.visible = true
      kit.obj.getProperties(this.shopForm, model)

      try {
        this.paymentGatewayFees = JSON.parse(model.payFeeConfig) || []
      } catch (ignore) {
        this.paymentGatewayFees = []
      }

      getEmailSetting().success(resp => {
        kit.obj.getProperties(this.emailForm, resp.data)
        this.emailForm.id = model.id
      }).send(model.id)
    },
    onClose () {
      this.selectedTab = 'base'
      this.model = null
      this.shopForm = createShopForm()
      this.emailForm = createEmailForm()
    },
    handleSaveShop () {
      let p = Object.assign({}, this.model)
      p = Object.assign(p, this.shopForm)
      this.closable = false
      this.saveButtonLoading = true
      updateShop()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(() => {
          this.$message.success('保存成功。')
          this.$emit('saved', p)
        })
        .send(p)
    },
    handleSaveEmailClient () {
      this.closable = false
      this.saveButtonLoading = true
      updateEmailSetting()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(() => {
          this.$message.success('保存成功。')
        })
        .send(this.emailForm)
    },
    onPaymentGatewayConfigSaved (config) {
      this.model.payFeeConfig = config
    }
  }
}
</script>

<style lang="less" scoped>


</style>
