<template>
  <div>
    <a-button type="primary" @click="handleProductItemFromSku()">从库存选择</a-button>
    <div class="order-products">
      <div class="product-item header">
        <div class="wrapper">
          <div class="image">图片</div>
          <div class="sku">SKU</div>
          <div class="total">数量</div>
          <div class="buttons">操作</div>
        </div>
      </div>
      <div v-for="p in shipProducts" :key="p.id" class="product-item">
        <div class="wrapper">
          <div class="image-item cover" :style="{'background-image': `url(${p.imageUrl})`}" @click="$imageModal.open(p.imageUrl)"></div>
          <div class="sku">
            <div class="text-muted font-mini">
              <span v-if="p.sku">{{p.sku}}</span>
              <span v-else>无SKU</span>
            </div>
            <div class="sku-stock-info font-mini text-normal" v-show="selectedWarehouse">
              <div v-if="p.sku && skuStocks[p.sku] && skuStocks[p.sku] >= shipSkuTotal[p.sku]">
                库存剩余：<span class="font-bold text-success font-normal">{{skuStocks[p.sku]}}</span>
              </div>
              <div v-else class="text-danger font-normal">库存不足：{{skuStocks[p.sku] || 0}}</div>
            </div>
          </div>
          <div class="total">
            <a-input-number v-model="p.total" :min="1" :precision="0" />
          </div>
          <div class="buttons">
            <a-button type="danger" icon="delete" @click="handleRemoveProductItem(p)"></a-button>
          </div>
        </div>
        <div v-if="p.remark" class="remark">备注：{{p.remark}}</div>
      </div>
    </div>

    <stock-product-select ref="stockProductSelector"></stock-product-select>
  </div>
</template>

<script>
// import { getStockBySku } from '@/http/api/stock-product'
import kit from '@/utils/kit'
import StockProductSelect from '@/components-business/stock-product-select'
import buildShipProductItem from './build-ship-product-item'

export default {
  components: { StockProductSelect },
  props: {
    order: { type: Object },
    selectedWarehouse: { type: Object },
    skuStocks: { type: Object },
    shipSkuTotal: { type: Object }
  },
  data () {
    return {
    }
  },
  computed: {
    shipProducts () {
      return this.order ? this.order.shipProducts : []
    }
  },
  watch: {
    // selectedWarehouse () {
    //   this.loadSkuStocks()
    // }
  },
  methods: {
    // loadSkuStocks () {
    //   if (this.selectedWarehouse && this.shipProducts.length > 0) {
    //     const sku = this.shipProducts.map(item => item.sku).join(',')
    //     getStockBySku()
    //       .success(resp => {
    //         this.skuStocks = {}
    //         for (const key of Object.keys(resp.data)) {
    //           this.skuStocks[key] = resp.data[key][0]
    //         }
    //       })
    //       .send(sku, this.selectedWarehouse.id)
    //   }
    // },
    handleRemoveProductItem (item) {
      kit.arr.removeItem(this.shipProducts, item)
      this.$emit('skuTotalChanged')
    },
    handleProductItemFromSku () {
      this.$refs.stockProductSelector.open(selected => {
        for (const item of selected) {
          const obj = Object.assign(buildShipProductItem(), {
            imageUrl: item.imageUrl,
            variant: item.variant,
            sku: item.sku,
            total: 1,
            skuWeight: item.skuWeight,
            skuLength: item.skuLength,
            skuWidth: item.skuWidth,
            skuHeight: item.skuHeight
          })
          this.shipProducts.push(obj)
        }
        this.$emit('skuTotalChanged')
        // this.loadSkuStocks()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-products {
  position: relative;
  margin-top: 10px;
  .product-item + .product-item {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 1px #eee;
  }
  .product-item {
    padding: 5px 0;
    .wrapper {
      align-items: center;
      display: flex;
      width: 100%;
    }
    .image-item {
      margin-right: 10px;
    }
    .sku {
      flex: 1;
      text-align: center;
    }
    .total {
      width: 140px;
      text-align: center;
    }
    .cost {
      width: 160px;
      text-align: center;
    }
    .buttons {
      margin-left: 10px;
      width: 80px;
      text-align: right;
    }
  }
  .remark {
    font-size: 12px;
    padding: 4px 0;
    color: #bb3e30;
  }
  .product-item.header {
    .image {
      width: 70px;
      text-align: center;
    }
  }
}
</style>
