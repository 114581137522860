import defineApi from '../define-api'
import http from '../application-api'

export const addImportantOrder = defineApi((config, data) => {
  config.data = data
  http.post('/order/important/attend', config)
})

export const removeImportantOrder = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/order/important/remove', config)
})

export const updateImportantOrderMessage = defineApi((config, id, message) => {
  config.data = { id: id, message: message }
  http.post('/order/important/update/message', config)
})
