import defineApi from '../define-api'
import http from '../application-api'

export const listSkuOptions = defineApi((config) => {
  http.get('/sku_option/list/all', config)
})

export const addSkuOption = defineApi((config, data) => {
  config.data = data
  http.post('/sku_option/add', config)
})

export const updateSkuOption = defineApi((config, data) => {
  config.data = data
  http.post('/sku_option/update', config)
})

export const deleteSkuOption = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/sku_option/delete', config)
})

export const getSkuStyle = defineApi(config => {
  http.get('/sku_option/sku/style_number/create', config)
})

export const generateSkuList = defineApi((config, data) => {
  config.data = data
  http.post('/sku_option/sku/generate', config)
})
