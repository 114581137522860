/**
 * @author jiangxingshang
 * @date 2022/12/12
 */
import ProtectResource from '@/data/model/protect-resource'

export default class ProtectResourceUpdate extends ProtectResource {
  constructor (initData) {
    super(initData)
    this.protectResourceItemValues = []
  }
}
