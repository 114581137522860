import defineApi from '../define-api'
import http from '../application-api'

export const listShopOptions = defineApi((config) => {
  http.get('/shop/list/options', config)
})

/**
 * 根据自己部分分配到的店铺资源返回店铺列表
 */
export const listShopOptionsBySelfDept = defineApi((config) => {
  http.get('/shop/list/options/dept', config)
})

export const listShops = defineApi((config, params) => {
  config.params = params
  http.get('/shop/list', config)
})

export const getSimpleShopById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/shop/by_id/simple', config)
})

export const getShopById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/shop/by_id', config)
})

export const addShop = defineApi((config, shop) => {
  config.data = shop
  return http.post('/shop/add', config)
})

export const updateShop = defineApi((config, shop) => {
  config.data = shop
  return http.post('/shop/update', config)
})

export const updatePaymentGatewayFee = defineApi((config, shopIdList, feeList) => {
  config.data = {
    shopIdList: shopIdList,
    fees: feeList
  }
  http.post('/shop/update/payment_gateway_fee', config)
})

export const updateHdbl = defineApi((config, idList, bl) => {
  config.params = {
    idList: idList,
    bl: bl
  }
  http.post('/shop/update/hdbl/batch', config)
})

