<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button @click="handleOpenSkuEditorToAdd" type="primary" v-authority="$authority.skuManage">添加SKU</a-button>


      <a-dropdown>
        <a-menu slot="overlay" @click="handleMoreAction">
          <a-menu-item key="handleSyncFromShop" v-authority="$authority.skuManage">店铺产品同步</a-menu-item>
          <a-menu-item key="handleOpenWeightVolumeEditor" v-authority="$authority.skuManage">更新重量体积</a-menu-item>
          <a-menu-item key="handleSelectCsvToUpdateRemark" v-authority="$authority.skuManage">
            <a-tooltip placement="left"><template slot="title">选择CSV或Excel文件（不包含标题行），列1：SKU，列2：备注</template>更新备注</a-tooltip>
          </a-menu-item>
          <a-menu-item key="handleExportInventory">导出库存（限5千个）</a-menu-item>
          <a-menu-item key="handleNavAbroadSku" v-authority="$authority.skuManage">海外仓上传SKU</a-menu-item>
          <a-menu-item key="deleteSku" v-authority="$authority.skuManage">删除SKU</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 更多操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
    </div>

    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline" :model="searchParams">
          <div>
            <a-form-model-item label="标题">
              <a-input v-model="searchParams.title" placeholder="标题关键字" style="width: 300px;" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="标签">
              <a-input v-model="searchParams.tags" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="SKU">
              <a-input v-model="searchParams.sku" :allow-clear="true" />
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="类别">
              <a-select mode="multiple" :max-tag-count="2" :maxTagTextLength="15" v-model="searchParams.category" style="width: 300px;" :allow-clear="true">
                <a-select-option :value="item" v-for="item in skuTypeOptions" :key="item">{{item}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="仓库">
              <a-select v-model="searchParams.warehouseId" style="width: 120px;" :allow-clear="true">
                <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="库存状态">
              <a-select v-model="searchParams.stockStatus" style="width: 114px;">
                <a-select-option :value="item.id" v-for="item in stockOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="来源店铺">
              <a-select v-model="searchParams.source" style="width: 140px;" :allow-clear="true">
                <a-select-option :value="item" v-for="item in sourceOptions" :key="item">{{item}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="reloadData" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.editor.open(record)" v-authority="$authority.skuManage">编辑</a-button>
        <a-button size="small" @click="handleNavWarehouseStockRecord(record)">日志</a-button>
      </div>
      <div slot="sex" slot-scope="v, record">
        <span v-if="record.coupleSku" class="text-primary">通用</span>
        <span v-else-if="record.sku.startsWith('CPM')" class="text-danger">男</span>
        <span v-else-if="record.sku.startsWith('CPF')" class="text-success">女</span>
      </div>
      <div slot="quantity" slot-scope="quantity">
        <span v-if="quantity === 0" class="text-danger font-bold">空</span>
        <span v-else class="text-danger">{{quantity}}</span>
      </div>
      <div slot="sku" slot-scope="sku, record">
        <a-tooltip v-if="record.coupleSku">
          <template slot="title">
            <div>男：{{record.coupleSkuForMen}}</div>
            <div>女：{{record.coupleSkuForWomen}}</div>
          </template>
          {{sku}}
        </a-tooltip>
        <span v-else>{{sku}}</span>
      </div>
      <div slot="imageUrl" slot-scope="imageUrl">
        <div class="image-item" v-lazy:background-image="imageUrl" @click="$refs.imageView.open(imageUrl)"></div>
      </div>
      <div slot="productTitle" slot-scope="title">
        <div style="white-space: pre-wrap;">{{title}}</div>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <stock-product-editor ref="editor" @closed="loadData()"></stock-product-editor>

    <image-modal ref="imageView"></image-modal>

    <modal
        ref="shopSyncDialog"
        title="店铺同步产品"
        ok-text="开始同步"
        cancel-text="关闭"
        :loading="shopProductSync.loading"
        @ok="handleStartSyncFromShop"
        @closed="handleCloseSyncFromShop"
    >
      <div>
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
          <a-form-item label="店铺">
            <a-select v-model="shopProductSync.shopId" style="width: 100%;" :disabled="shopProductSync.loading" placeholder="请选择要同步数据的店铺">
              <a-select-option :value="item.id" v-for="item in shopOptions" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="过滤产品ID">
            <a-input v-model="shopProductSync.productIds" :disabled="shopProductSync.loading" />
            <div class="font-mini text-primary" style="line-height: normal;">多个产品ID使用逗号分割，不要有空格。如果不提供产品ID，则表示同步全部。</div>
          </a-form-item>
          <a-form-item label="过滤标签">
            <a-input v-model="shopProductSync.tags" :disabled="shopProductSync.loading" />
            <div class="font-mini text-primary" style="line-height: normal;">多个标签使用逗号分割，多个标签表示包含所有标签的产品才会同步。</div>
          </a-form-item>
        </a-form>

        <div v-if="shopProductSync.loading" style="margin-top: 10px;">
          <div class="text-normal">正在处理 {{ shopProductSync.current.index }} / {{ shopProductSync.total }}</div>
          <div class="text-primary" v-show="shopProductSync.current.index > 0">{{ shopProductSync.current.title }}（{{ shopProductSync.current.variantTotal }}
            个SKU）</div>
        </div>
        <div v-else>
          同步完成 <span class="text-primary">{{ shopProductSync.current.index }} / {{ shopProductSync.total }}</span>
        </div>
        <div style="max-height: 300px; margin-top: 15px; overflow-y: auto;">
          <div style="padding: 8px 0;">同步失败 <span class="text-danger font-bold">{{ shopProductSync.errors.length }}</span> 个</div>
          <div class="list-group">
            <div class="list-group-item" v-for="err in shopProductSync.errors" :key="err.id">
              <div class="text-normal text-overflow">{{err.id}} / {{err.title}}</div>
              <div class="font-mini text-danger">{{err.message}}</div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <weight-volume-editor ref="weightVolumeEditor"></weight-volume-editor>
  </app-page>
</template>

<script>
import { ROUTE_STOCK_PRODUCT, ROUTE_WAREHOUSE_STOCK_RECORD, ROUTE_ABROAD_UPLOAD_SKU } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import {
  listStockProducts,
  syncFromShop,
  getShopOptions,
  exportInventory,
  deleteStockProduct,
  getSourceOptions,
  updateRemarkFromFile,
  getSkuTypeOptions
} from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { syncStock } from '@/http/api/abroad-shipping'
import Stomp from '@/stomp/stomp'
import { BrokerShopifySkuSync } from '@/stomp/constants'
import selectFiles from '@/utils/select-file'
import StockProductEditor from './comp/stock-product-editor'
import { LOGISTICS_ABROAD_LIST } from '@/constants/logistics'
import WeightVolumeEditor from './comp/size-volume-editor'

export default {
  components: { StockProductEditor, WeightVolumeEditor },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_STOCK_PRODUCT,
      pageLoading: false,
      dataColumns: [
        {
          title: '操作',
          width: 120,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 100,
          scopedSlots: { customRender: 'imageUrl' }
        },
        {
          title: '性别',
          width: 50,
          scopedSlots: { customRender: 'sex' }
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          width: 200,
          scopedSlots: { customRender: 'sku' }
        },
        {
          title: '库存',
          dataIndex: 'quantity',
          width: 80,
          scopedSlots: { customRender: 'quantity' }
        },
        {
          title: '仓库',
          dataIndex: 'warehouseTotal',
          width: 60
        },
        {
          title: '规格',
          dataIndex: 'variant',
          width: 130
        },
        {
          title: '产品标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'productTitle' }
        },
        {
          title: '来源',
          dataIndex: 'source',
          width: 90
        }
      ],
      searchParams: {
        warehouseId: null,
        stockStatus: 'all',
        title: null,
        tags: null,
        sku: null,
        source: null,
        category: []
      },
      dataList: [],
      dataLoading: false,
      shopProductSync: {
        shopId: null,
        productIds: null, // 条件过滤：产品ID，多个用逗号分割
        tags: null, // 条件过滤：标签，多个用逗号分割，表示必须包含全部提供的标签
        loading: false,
        total: 0,
        errors: [],
        current: {
          title: null,
          index: 0,
          variantTotal: 0
        }
      },
      shopOptions: [],
      skuTypeOptions: [],
      warehouseOptions: [],
      stockOptions: [
        { id: 'all', name: '所有' },
        { id: 'empty', name: '空库存' },
        { id: 'notEmpty', name: '有库存' }
      ],
      sourceOptions: [],
      abroadShippingOptions: LOGISTICS_ABROAD_LIST,
      selectedRowKeys: []
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      listStockProducts()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleSyncFromShop () {
      this.$refs.shopSyncDialog.open()
      this.stomp = new Stomp()
      this.stomp.connect(success => {
        if (success) {
          this.stomp.subscribe(BrokerShopifySkuSync, resp => {
            const action = resp.action
            if (action === 'end') {
              this.shopProductSync.loading = false
            } else if (action === 'start') {
              this.shopProductSync.current.index = resp.current
              this.shopProductSync.current.title = resp.title
              this.shopProductSync.current.variantTotal = resp.variantTotal
              this.shopProductSync.total = resp.total
            } else if (action === 'processed') {
              if (resp.error) {
                this.shopProductSync.errors.push(resp.error)
              }
            }
          })
        } else {
          this.$message.error('Stomp没有连接成功，请刷新页面重新尝试。')
        }
      })
    },
    handleCloseSyncFromShop () {
      if (this.stomp) {
        this.stomp.close()
      }
      this.shopProductSync.current.index = 0
      this.shopProductSync.current.title = null
      this.shopProductSync.current.variantTotal = 0
      this.shopProductSync.total = 0
      this.shopProductSync.errors = []
      this.shopProductSync.shopId = null
    },
    handleStartSyncFromShop () {
      if (this.shopProductSync.shopId) {
        this.shopProductSync.loading = true
        syncFromShop()
          .complete(success => {
            if (!success) this.shopProductSync.loading = false
          })
          .send(this.shopProductSync.shopId, this.shopProductSync.productIds, this.shopProductSync.tags)
      } else {
        this.$message.error('请选择店铺')
      }
    },
    handleNavWarehouseStockRecord (record) {
      this.$router.push({ name: ROUTE_WAREHOUSE_STOCK_RECORD, params: { stockProductId: record.id } })
    },
    handleNavAbroadSku () {
      this.$router.push({ name: ROUTE_ABROAD_UPLOAD_SKU })
    },
    handleMoreAction (e) {
      this[e.key]()
    },
    handleAbroadStockSyncClick (e) {
      const a = this.abroadShippingOptions.find(item => item.id === e.key)
      this.$confirm({
        title: '提示',
        content: `同步库存会初始化本地仓库【${a.name}】的库存，是否要操作？`,
        onOk: () => {
          this.pageLoading = true
          syncStock()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              const errorTotal = Object.keys(resp.data.error).length
              if (errorTotal > 0) {
                const h = this.$createElement
                const error = []
                for (const key of Object.keys(resp.data.error)) {
                  error.push(h('p', resp.data.error[key]))
                }
                this.$error({
                  title: `同步结果：${resp.data.successTotal}个成功，${errorTotal}个失败。`,
                  content: h('div', {}, error)
                })
              } else {
                this.$message.success('成功同步 ' + resp.data.successTotal + ' 个SKU的库存。')
              }
            })
            .send(a.id)
        }
      })
    },
    handleExportInventory () {
      window.open(exportInventory(this.searchParams))
    },
    handleOpenSkuEditorToAdd () {
      this.$refs.editor.open()
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    deleteSku () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.error('请选择SKU。')
      }
      this.$confirm({
        title: '确认删除',
        content: '确定要删除选中的SKU吗？',
        onOk: () => {
          this.pageLoading = true
          deleteStockProduct()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadData()
            })
            .send(this.selectedRowKeys)
        }
      })
    },
    handleSelectCsvToUpdateRemark () {
      selectFiles({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        multi: false,
        select: (files, errors) => {
          this.pageLoading = true
          updateRemarkFromFile()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('更新成功。')
            })
            .send(files[0])
        }
      })
    },
    handleOpenWeightVolumeEditor () {
      this.$refs.weightVolumeEditor.open()
    }
  },
  mounted () {
    this.loadData()
    listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
    getShopOptions().success(resp => (this.shopOptions = resp.data)).send()
    getSourceOptions().success(resp => (this.sourceOptions = resp.data)).send()
    getSkuTypeOptions().success(resp => (this.skuTypeOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
</style>
