/**
 * UI权限指令，决定用户是否能看到自己的UI。
 * 用法：
 * <div v-authority="$authority.headSummary"></div>
 */

import { userStore } from '@/store'

/**
 * 预定义的UI权限编号
 */
export const authorityMapping = {
  // 导航栏的统计信息
  headSummaryDelivery: 'head-summary-delivery',
  headSummaryOrder: 'head-summary-order',
  headSummaryOrderSale: 'head-summary-sale',
  // 花费统计分析 - deprecated
  analysisCost: 'analysisCost',
  // 销售统计分析 - deprecated
  analysisSale: 'analysisSale',
  // 库存预警报告 - deprecated
  analysisStock: 'analysisStock',
  // 售后分析 - deprecated
  analysisAfterSale: 'analysisAfterSale',
  // 商品管理（添加，删除，修改等UI）
  skuManage: 'sku-manage',
  // 商品库存更新
  skuStockManage: 'sku-stock-manage',
  // 商品成本价更新
  skuCost: 'sku-cost',
  // 工厂下单
  supplierOrder: 'supplier-order',
  // 订单相关的更新操作（如发货，改备注，同步订单之类的）
  orderManage: 'order-manage',
  // 订单同步
  orderSync: 'order-sync',
  // 客户订单发货
  orderDelivery: 'order-delivery',
  // 订单详情里的利润分析标签页
  orderProfit: 'order-profit',
  // 订单详情里的备注更新
  orderRemarkUpdate: 'order-remark-update',
  // 标记订单为全部发完货
  orderUpdateAllShip: 'order-update-all-ship',
  // 订单物流页面中，管理员才能操作的UI
  deliveryAdmin: 'delivery-admin',
  // 系统消息
  systemMessage: 'sys-msg',
  // 库存盘点 > 盘点明细 > 库存迁移
  stockCheckTransferBtn: 'stockCheckTransferBtn',
  // 库存盘点 > 盘点明细 > 更新库存
  stockCheckResetStockBtn: 'stockCheckResetStockBtn',
  // 工厂订单 > 供应商按钮
  supplierOrderButton: 'supplierOrderButton',
  // 达人模块中的管理员操作
  influencerManager: 'influencerManager'
}

export const hasAuthority = function (aid) {
  return (userStore.state.uiMap.pcu || []).includes(aid)
}

/**
 * 将权限指令以及预定义的权限编号注册到全局
 */
export const initAuthorityConstants = function (Vue) {
  // 注册权限指令
  Vue.directive('authority', {
    inserted (el, { value }) {
      const auth = userStore.state.uiMap.pcu || []
      let vals = value
      if (!Array.isArray(value)) {
        vals = [value]
      }
      let flag = false
      for (const v of vals) {
        if (auth.includes(v)) {
          flag = true
          break
        }
      }
      if (!flag) {
        el.remove()
      }
    }
  })

  // 将预定义UI权限编号注册到全局
  Object.defineProperty(Vue.prototype, '$authority', {
    get () { return authorityMapping }
  })

  /**
   * 提供一个检查UI权限的工具函数。
   * if (this.$authorityCheck.check(this.$authority.headSummary)) {
   *   // do something
   * }
   */
  Object.defineProperty(Vue.prototype, '$authorityCheck', {
    get () {
      return {
        check: function (a) {
          return hasAuthority(a)
        }
      }
    }
  })
}
