<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" @click="$refs.imp.open()">导入消耗数据</a-button>


    <div slot="top">
      <div class="search-form">
        <a-form-model layout="inline" :model="searchParams" class="form">
          <a-form-model-item label="客户">
            <a-select v-model="searchParams.partnerId" style="width: 160px;" :allow-clear="true">
              <a-select-option v-for="opt in partnerOptions" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="平台">
            <a-select v-model="searchParams.platform" style="width: 100px;" :allow-clear="true">
              <a-select-option value="google">Google</a-select-option>
              <a-select-option value="tiktok">Tiktok</a-select-option>
              <a-select-option value="facebook">Facebook</a-select-option>
              <a-select-option value="bing">Bing</a-select-option>
              <a-select-option value="other">其他</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="批次">
            <a-input v-model="searchParams.batchId" style="width: 240px;" :allow-clear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-range-picker v-model="searchParams.dates" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
          </a-form-model-item>
        </a-form-model>
        <div class="buttons">
          <a-button @click="reloadData()" icon="reload">查询</a-button>
        </div>
      </div>


    </div>



    <a-table
        class="data-table"
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <import-modal ref="imp"></import-modal>

  </app-page>
</template>

<script>
import { ROUTE_PARTNER_ADS_COST } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import ImportModal from './comp/import-modal'
import { listOptions } from '@/http/api/partner'
import { list } from '@/http/api/partner-ads-cost'

export default {
  components: { ImportModal },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  data () {
    return {
      routeName: ROUTE_PARTNER_ADS_COST,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '客户', width: 140, dataIndex: 'partnerName' },
        { title: '日期', width: 90, dataIndex: 'createDate' },
        { title: '平台金额', width: 90, dataIndex: 'costOrigin' },
        { title: '平台货币', width: 90, dataIndex: 'currencyOrigin' },
        { title: '人民币', width: 90, dataIndex: 'costCny' },
        { title: '平台', width: 110, dataIndex: 'platform' },
        { title: '批次', dataIndex: 'batchId' }
      ],
      searchParams: {
        batchId: null,
        dates: [],
        platform: null,
        partnerId: null
      },
      partnerOptions: []
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.dates.length === 2) {
        p.startDate = p.dates[0]
        p.endDate = p.dates[1]
      }
      delete p.dates
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    }
  },
  mounted () {
    listOptions().success(resp => (this.partnerOptions = resp.data)).send()
    this.reloadData()
  }
}
</script>

<style lang="less" scoped>

</style>
