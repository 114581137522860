<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="创建产品"
    @save="onSave"
    @close="onClose"
    :width="1200"
  >
    <div class="wrapper">
      <div class="left">
        <div>
          <div class="form-item app_required-input">
            <label>标题</label>
            <div><a-input v-model="model.title" /></div>
          </div>
          <div class="form-item">
            <label>描述</label>
            <div>
              <rich-editor :resize="false" :simple="true" :content.sync="model.description"></rich-editor>
            </div>
          </div>
          <div class="form-item">
            <label>类型</label>
            <div><a-input v-model="model.type" /></div>
          </div>
          <div class="form-item app_required-input">
            <label>性别标签</label>
            <div style="padding-top: 8px">
              <a-tag @click="model.sexTag = t.value" :color="model.sexTag === t.value ? 'red' : ''" v-for="t in sexTags" :key="t.id">{{t.value}}</a-tag>
            </div>
          </div>

          <div class="form-item app_required-input">
            <label>类别标签</label>
            <div>
              <a-select style="width: 100%" v-model="model.typeTag">
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0;" />
                  <div
                      style="padding: 4px 8px; cursor: pointer;"
                      @mousedown="e => e.preventDefault()"
                      @click="$refs.skuOptionManager.open(null, skuOptionGroupNameMap.type)"
                  >
                    <a-icon type="edit" /> 管理标签
                  </div>
                </div>
                <a-select-option v-for="item in typeTags" :key="item.id" :value="item.value">
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="form-item">
            <label>品类标签</label>
            <div>
              <a-select style="width: 100%" v-model="model.subTypeTag" :allow-clear="true">
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0;" />
                  <div
                      style="padding: 4px 8px; cursor: pointer;"
                      @mousedown="e => e.preventDefault()"
                      @click="$refs.skuOptionManager.open(null, skuOptionGroupNameMap.subType)"
                  >
                    <a-icon type="edit" /> 管理标签
                  </div>
                </div>
                <a-select-option v-for="item in subTypeTags" :key="item.id" :value="item.value">
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="form-item" v-show="model.typeTag === 'FifthLoop'">
            <label>潮牌SKU</label>
            <div style="display: flex; align-items: center;">
              <div>
                <div>
                  <span>上衣</span>
                  <a-input v-model="fifthLoopSku.top" :maxLength="3" style="width: 80px; margin-left: 8px;" />
                </div>
                <div style="margin-top: 8px;">
                  <span>裤子</span>
                  <a-input v-model="fifthLoopSku.bottom" :maxLength="3" style="width: 80px; margin-left: 8px;" />
                </div>
              </div>
              <div style="margin-left: 8px;">
                <span>尾号</span>
                <a-input-number v-model="fifthLoopSku.number" style="width: 80px; margin-left: 8px;" />
              </div>
              <div style="margin-left: 8px;">
                <a-button type="primary" @click="checkSku">检查</a-button>
              </div>
            </div>
          </div>

          <div class="form-item app_required-input" v-show="model.typeTag === 'FifthLoop'">
            <label>潮牌标签</label>
            <div>
              <a-tag
                  style="margin-bottom: 6px;"
                  v-for="tag in fifthLoopTags" :key="tag"
                  @click="handleAddFifthLoopTag(tag)"
                  :color="model.customTags.includes(tag) ? 'red' : ''"
              >{{tag}}</a-tag>
            </div>
          </div>
          <div class="form-item">
            <label>自定义标签</label>
            <div>
              <a-select mode="tags" style="width: 100%" v-model="model.customTags" />
            </div>
          </div>
        </div>
      </div>

      <div class="split-line"></div>


      <div class="right">
        <div class="cover-container">
          <a-button type="link" @click="handleUploadImage">添加图片</a-button>
          <draggable
              tag="div"
              v-model="model.images"
              v-bind="imageDrag.dragOptions"
              @start="imageDrag.drag = true"
              @end="handleProductCoverSortEnd"
          >
            <transition-group tag="div" type="transition" :name="!imageDrag.drag ? 'flip-list' : null" class="image-list sortable-list">
              <div class="cover-item" v-for="(url, index) in model.images" :key="url">
                <div class="image" :style="{'background-image': `url(${url})`}" @click="$imageModal.open(url)"></div>
                <div class="is-cover" v-if="index === 0">C</div>
                <div class="remove" @click="handleRemoveCoverItem(url)"><a-icon type="delete" /></div>
              </div>
            </transition-group>
          </draggable>
          <div class="file-upload-list">
            <div class="upload-item" v-for="file in uploadFiles" :key="file.id">
              <div style="flex: 1;">{{file.file.name}}</div>
              <a-spin :spinning="true" v-show="file.loading">
                <a-icon slot="indicator" type="loading" style="font-size: 14px" spin />
              </a-spin>
            </div>
          </div>
        </div>

        <div class="left-right-content" style="margin-top: 20px;">
          <div class="form-item app_required-input">
            <label style="width: auto;">基础价格</label>
            <div>
              <a-input-number v-model="model.basePrice"/>
            </div>
          </div>
          <div>
            <a-button @click="handleAddProductVariantOption">添加产品选项</a-button>
          </div>
        </div>


        <div class="variant-container">

          <draggable
              tag="div"
              v-model="model.variants"
              v-bind="variantDrag.dragOptions"
              @start="variantDrag.drag = true"
              @end="variantDrag.drag = false"
          >
            <transition-group tag="div" type="transition" :name="!variantDrag.drag ? 'flip-list' : null" class="sortable-list">
              <div class="variant-section" v-for="v in model.variants" :key="v.id">
                <div class="name" style="display: flex; align-items: center;">
                  <a-icon type="drag" class="drag-icon" style="cursor: move; margin-right: 10px;"/>
                  <div style="flex: 1;">{{v.name}}</div>

                  <a-button type="danger" size="small" icon="delete" @click="handleRemoveAllVariantItem(v)"></a-button>

                </div>
                <div class="value-list">

                  <draggable
                      tag="div"
                      v-model="v.values"
                      v-bind="variantValueDrag.dragOptions"
                      @start="variantValueDrag.drag = true"
                      @end="variantValueDrag.drag = false"
                  >
                    <transition-group tag="div" type="transition" :name="!variantValueDrag.drag ? 'flip-list' : null" class="sortable-list">
                      <div class="value-item" v-for="val in v.values" :key="val.text">

                        <a-icon type="unordered-list" class="item-drag-icon" style="cursor: move; margin-right: 20px;"/>

                        <div class="text" v-if="v.id.startsWith('custom')">
                          <a-input v-model="val.text"  style="width: 100%" />
                        </div>
                        <div v-else class="text">{{val.text}}</div>

                        <div v-if="v.id.toLowerCase() === 'color'">
                          <div class="color-image"
                               @click="handleSelectImage(val)"
                               :style="{'background-image': `url(${val.image})`}"
                          >
                            <a-icon type="file-image" v-show="!val.image" />
                          </div>
                        </div>
                        <div class="price">
                          价格调整：
                          <a-input-number v-model="val.price" />
                        </div>
                        <a-button @click="handleRemoveVariantItem(v, val)" size="small" type="danger" shape="circle" icon="delete" />
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </transition-group>
          </draggable>
          <!-- 多属性 -->
        </div>

        <a-button :loading="skuPreviewLoading"
                  v-show="!!updateId"
                  type="primary"
                  block
                  style="margin: 10px 0;"
                  @click="handleLoadSkuPreview">预览SKU</a-button>
      </div>
    </div>

    <sku-option-manager ref="skuOptionManager" @change="onSkuOptionChanged"></sku-option-manager>

    <modal
        title="颜色图片选择"
        ref="colorImageSelector"
    >
      <div>
        <div class="image-item"
             style="display: inline-block; margin: 8px;"
             v-for="url in model.images"
             :key="url"
             :style="{'background-image': `url(${url})`}"
             @click="onColorImageSelected(url)"
        ></div>
      </div>
    </modal>

    <modal title="SKU" ref="skuPreview">
      <div class="content-center font-mini text-primary" style="padding: 10px 0;">
        共 {{skuPreviewList.length}} 个SKU
        <span v-if="skuPreviewListRepeatSize > 0">，有 {{skuPreviewListRepeatSize}} 个SKU存在重复。</span>
      </div>
      <ul class="list-group" style="max-height: 300px; overflow-y: auto;">
        <li class="list-group-item"
            :class="{'list-group-item-danger': skuPreviewListRepeat[variant.sku] === true }"
            v-for="variant of skuPreviewList" :key="variant.sku">
          <div style="display: flex; align-items: center;">
            <div class="image-item small" v-lazy:background-image="variant.imageUrl"></div>
            <div style="flex: 1; padding: 0 10px;">
              <div class="text-primary font-large">{{variant.sku}}</div>
              <div class="font-mini text-muted">{{variant.options.join(', ')}}</div>
            </div>
            <div class="text-danger">{{variant.price}}</div>
          </div>
        </li>
      </ul>
    </modal>
  </drawer>
</template>

<script>
import Draggable from 'vuedraggable'
import kit from '@/utils/kit'
import RichEditor from '@/components/rich-editor'
import SkuOptionManager from '@/components-business/sku-option-manager'
import { add, update, upload, generateSkuPreview, checkSku } from '@/http/api/store-local-product'
import { listSkuOptions } from '@/http/api/sku-option'
import {
  SKU_GROUP_NAME_TYPE,
  SKU_GROUP_NAME_SUB_TYPE,
  SKU_GROUP_NAME_MOMME,
  SKU_GROUP_NAME_SIZE,
  SKU_GROUP_NAME_COLOR,
  SKU_GROUP_NAME_SEX
} from '@/constants/sku-option-group-name'
import selectFiles from '@/utils/select-file'

function createDefaultModel () {
  return {
    title: 'New product title',
    description: null,
    type: null,
    sexTag: null,
    typeTag: null,
    subTypeTag: null,
    customTags: [],
    basePrice: 0,
    compareAtPrice: 0,
    images: [],
    variants: [],
    customSku: null
  }
}
export default {
  components: {
    Draggable,
    RichEditor,
    SkuOptionManager,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      updateId: null,
      model: createDefaultModel(),
      imageDrag: {
        dragOptions: { },
        drag: false
      },
      variantDrag: {
        dragOptions: { handle: '.drag-icon' },
        drag: false
      },
      variantValueDrag: {
        dragOptions: { handle: '.item-drag-icon' },
        drag: false
      },
      // Color variant正在选择图片的项
      imageSelectingValue: null,
      skuOptionGroupNameMap: {
        type: SKU_GROUP_NAME_TYPE,
        subType: SKU_GROUP_NAME_SUB_TYPE,
        size: SKU_GROUP_NAME_SIZE,
        color: SKU_GROUP_NAME_COLOR,
        momme: SKU_GROUP_NAME_MOMME
      },
      typeTags: [],
      subTypeTags: [],
      sexTags: [],
      fifthLoopTags: [
        'T-shirt',
        'Tranks',
        'Sweater',
        'Shorts',
        'Polo',
        'Matching Sets',
        'Joggers',
        'Jean',
        'Jackets',
        'Button-up',
        'Crewnecks',
        'Hoodie',
        'Sweat Suits',
        'Sweat Pant',
        'Sweat Shirt',
        'Track Suits',
        'Track Pant',
        'Shorts Sets',
        'Back Packs',
        'Belts',
        'Hats',
        'Classes'
      ],
      uploadFiles: [],
      skuPreviewList: [],
      skuPreviewLoading: false,
      // 潮牌的SKU组合
      fifthLoopSku: {
        top: null, // 上衣的三位字母
        bottom: null, // 裤子的三位字母
        number: null // 末尾的三位数字款号
      }
    }
  },
  watch: {
    'fifthLoopSku.top' (top) {
      this.fifthLoopSku.top = top.toUpperCase()
    },
    'fifthLoopSku.bottom' (bot) {
      this.fifthLoopSku.bottom = bot.toUpperCase()
    },
    fifthLoopSku: {
      deep: true,
      handler () {
        const top = this.fifthLoopSku.top || ''
        const bot = this.fifthLoopSku.bottom || ''
        const n = this.fifthLoopSku.number || ''
        this.model.customSku = top + bot + n
      }
    },
    'model.typeTag' (type) {
      if (type === 'FifthLoop') {
        this.model.sexTag = 'Men'
      }
    }
  },
  computed: {
    /**
     * 有重复项
     */
    skuPreviewListRepeat () {
      const map = {}
      const repeat = {}
      for (const item of this.skuPreviewList) {
        if (map[item.sku]) {
          repeat[item.sku] = true
        }
        map[item.sku] = true
      }
      return repeat
    },
    skuPreviewListRepeatSize () {
      return Object.keys(this.skuPreviewListRepeat).length
    }
  },
  methods: {
    open (id, updateData) {
      this.visible = true
      if (updateData) {
        this.updateId = id
        this.model = JSON.parse(JSON.stringify(updateData))
        if (this.model.typeTag === 'FifthLoop' && this.model.customSku) {
          const top = this.model.customSku.substring(0, 3)
          let bottom = null
          let number = null
          if (this.model.customSku.length > 6) {
            bottom = this.model.customSku.substring(3, 6)
            number = parseInt(this.model.customSku.substring(6, 9))
          } else {
            number = this.model.customSku.substring(3, 6)
          }
          this.fifthLoopSku.top = top
          this.fifthLoopSku.bottom = bottom
          this.fifthLoopSku.number = number
        }
      }

      listSkuOptions().success(resp => (this.updateSkuOptionMap(resp.data))).send()
    },
    onClose () {
      this.model = createDefaultModel()
      this.fifthLoopSku.top = null
      this.fifthLoopSku.bottom = null
      this.fifthLoopSku.number = null
      this.updateId = null
    },
    onSave () {
      if (this.validate()) {
        this.save()
      }
    },
    save () {
      const content = JSON.stringify(this.model)
      this.closable = false
      this.saveButtonLoading = true
      const api = this.updateId ? update : add
      api()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(resp => {
          this.$emit('saved', this.updateId ? this.model : resp.data, this.updateId)
          if (!this.updateId) {
            this.updateId = resp.data.id
          }
          this.$message.success('保存成功。')
        })
        .send(content, this.model.customSku, this.updateId)
    },
    validate () {
      if (kit.str.isBlank(this.model.title)) {
        this.$error({ title: '验证失败', content: '标题不能为空。' })
        return false
      }
      if (kit.str.isBlank(this.model.typeTag)) {
        this.$error({ title: '验证失败', content: '类别标签没有选择。' })
        return false
      }
      if (this.model.basePrice <= 0) {
        this.$error({ title: '验证失败', content: '基础价格必须大于0。' })
        return false
      }
      if (this.model.images.length === 0) {
        this.$error({ title: '验证失败', content: '请上传产品图片。' })
        return false
      }
      if (this.model.typeTag === 'FifthLoop') {
        if ((kit.str.isBlank(this.fifthLoopSku.top) && kit.str.isBlank(this.fifthLoopSku.bottom)) || this.fifthLoopSku.number == null) {
          this.$error({ title: '验证失败', content: '请填写潮牌的SKU。' })
          return false
        }
      }
      const variantNameMap = {}
      for (const variant of this.model.variants) {
        variant.name = variant.name.trim()
        if (variantNameMap[variant.name] === true) {
          this.$error({ title: '验证失败', content: `多属性【${variant.name}】的名称存在重复。` })
          return false
        }
        variantNameMap[variant.name] = true
        if (kit.str.isBlank(variant.name)) {
          this.$error({ title: '验证失败', content: '自定义多属性的名称不能为空。' })
          return false
        }
        const variantValueMap = {}
        for (const value of variant.values) {
          value.text = value.text.trim()
          if (variantValueMap[value.text] === true) {
            this.$error({ title: '验证失败', content: `多属性【${variant.name}】下的值【${value.text}】存在重复。` })
            return false
          }
          variantValueMap[value.text] = true
          if (kit.str.isBlank(value.text)) {
            this.$error({ title: '验证失败', content: `【${variant.name}】中发现留空的属性值，请填写。` })
            return false
          }
          if (variant.name === SKU_GROUP_NAME_COLOR && value.image == null) {
            this.$error({ title: '验证失败', content: `【${variant.name}】的${value.text}颜色没有图片。` })
            return false
          }
        }
      }
      return true
    },
    handleProductCoverSortEnd () {
      this.imageDrag.drag = false
    },
    handleRemoveCoverItem (url) {
      kit.arr.removeItem(this.model.images, url)
      const colorVariant = this.model.variants.find(item => item.name === SKU_GROUP_NAME_COLOR)
      if (colorVariant) {
        for (const value of colorVariant.values) {
          if (value.image === url) {
            value.image = null
          }
        }
      }
    },
    handleSelectImage (v) {
      this.imageSelectingValue = v
      this.$refs.colorImageSelector.open()
    },
    onColorImageSelected (url) {
      this.imageSelectingValue.image = url
      this.$refs.colorImageSelector.close()
      this.imageSelectingValue = null
    },
    createVariant (optionName) {
      let variant = this.model.variants.find(item => item.name === optionName)
      if (!variant) {
        if (this.model.variants.length >= 3) {
          this.$message.error('多属性不能超过3个。')
          return null
        }
        variant = {
          id: optionName,
          name: optionName,
          values: []
        }
        this.model.variants.push(variant)
      }
      return variant
    },
    createVariantFor (e) {
      const variant = this.createVariant('custom')
      if (variant == null) {
        return
      }
      const texts = []
      if (e.key === 'Pillowcase') {
        texts.push('Standard (20" * 26")')
        texts.push('Queen (20" * 30")')
        texts.push('King (20" * 36")')
      } else if (e.key === 'Flat Sheet') {
        texts.push('Twin (66"x96")')
        texts.push('Full (81"x96")')
        texts.push('Queen (91"x102")')
        texts.push('King (108"x102")')
        texts.push('Cal.King (102"x110")')
      } else if (e.key === 'Fitted Sheet') {
        texts.push('Twin (39"x75"+16")')
        texts.push('Full (54"x75"+16")')
        texts.push('Queen (60"x80"+16")')
        texts.push('King (78"x80"+16")')
        texts.push('Cal.King (72"x84"+16")')
      } else if (e.key === 'Duvet Cover') {
        texts.push('Twin (67x87")')
        texts.push('US Full(76x87")')
        texts.push('Queen (87x90")')
        texts.push('King (104x92")')
        texts.push('CalKing(110x96")')
      } else if (e.key === 'Bed 4PCS') {
        texts.push('Twin')
        texts.push('Full')
        texts.push('Queen')
        texts.push('King')
        texts.push('Cal.King')
      }
      variant.values = []
      for (const t of texts) {
        variant.values.push({ text: t, price: 0 })
      }
    },
    addVariantOption (optionName, value) {
      const variant = this.createVariant(optionName)
      if (variant == null) {
        return
      }
      const values = variant.values
      const found = values.find(item => item.text === value)
      if (!found) {
        let val
        if (optionName === SKU_GROUP_NAME_COLOR) {
          val = { text: value, price: 0, image: null }
        } else {
          val = { text: value, price: 0 }
        }

        if (optionName === SKU_GROUP_NAME_SIZE) {
          this.initSizeVariantPrice(val)
        } else if (optionName === SKU_GROUP_NAME_MOMME) {
          this.initMommeVariantPrice(val)
        }
        values.push(val)
      }
    },
    initSizeVariantPrice (item) {
      switch (item.text) {
        case 'XL':
          item.price = 10
          break
        case 'XXL':
          item.price = 20
          break
        case 'XXXL':
          item.price = 30
          break
        case 'XXXXL':
          item.price = 40
          break
        case 'Customize':
          item.price = 60
          break
      }
    },
    initMommeVariantPrice (item) {
      if (item.text === '22') {
        item.price = 50
      }
    },
    handleRemoveAllVariantItem (variant) {
      kit.arr.removeItem(this.model.variants, variant)
    },
    handleRemoveVariantItem (variant, value) {
      kit.arr.removeItem(variant.values, value)
      if (variant.values.length === 0) {
        this.handleRemoveAllVariantItem(variant)
      }
    },
    handleUploadImage () {
      selectFiles({
        accept: ['image/jpg', 'image/png', 'image/jpeg'],
        select: files => {
          const arr = []
          for (const f of files) {
            const fileData = { id: kit.str.id(6), file: f, loading: false }
            arr.push(fileData)
          }
          this.uploadFiles = arr
          this.startUploadFileQueue()
        }
      })
    },
    startUploadFileQueue () {
      if (this.uploadFiles.length === 0) return
      const QUEUE_LIMIT = 3
      const startUploads = []
      const tmp = this.uploadFiles.length >= QUEUE_LIMIT ? QUEUE_LIMIT : this.uploadFiles.length
      for (let i = 0; i < tmp; i++) {
        startUploads.push(this.uploadFiles[i])
      }
      let tt = 0
      for (const fileData of startUploads) {
        this.startUploadFile(fileData, data => {
          tt++
          kit.arr.removeItem(this.uploadFiles, fileData)
          if (tt === startUploads.length) {
            this.startUploadFileQueue()
          }
        })
      }
    },
    startUploadFile (fileData, onCompleted) {
      fileData.loading = true
      upload()
        .complete(success => {
          fileData.loading = false
          onCompleted(fileData)
        })
        .success(resp => {
          this.model.images.push(resp.data)
        })
        .send(fileData.file)
    },
    handleLoadSkuPreview () {
      this.skuPreviewLoading = true
      generateSkuPreview()
        .complete(() => (this.skuPreviewLoading = false))
        .success(resp => {
          this.skuPreviewList = resp.data
          this.$refs.skuPreview.open()
        })
        .send(this.updateId)
    },
    handleAddFifthLoopTag (tag) {
      if (this.model.customTags.includes(tag)) {
        kit.arr.removeItem(this.model.customTags, tag)
      } else {
        this.model.customTags.push(tag)
      }
    },
    onSkuOptionChanged (map) {
      this.updateSkuOptionMap(map)
    },
    updateSkuOptionMap (map) {
      this.typeTags = map[SKU_GROUP_NAME_TYPE]
      this.subTypeTags = map[SKU_GROUP_NAME_SUB_TYPE]
      this.sexTags = map[SKU_GROUP_NAME_SEX]
    },
    handleAddProductVariantOption () {
      this.$refs.skuOptionManager.open(data => {
        for (const opt of data.options) {
          this.addVariantOption(opt.groupName, opt.value)
        }
      })
    },
    checkSku () {
      checkSku()
        .success(resp => (this.$message.success('此SKU可用。')))
        .send(this.updateId, this.model.customSku)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  padding: 15px 25px;
  margin: 20px 0;
  overflow-y: auto;
}

.section-css {
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 10px;
}

.left {
  flex: .5;
}

.split-line {
  margin: 0 20px;
  width: 1px;
  background-color: #ddd;
}

.right {
  flex: .5;
}

.form-item {
  display: flex;
  align-items: flex-start;
  label {
    width: 100px;
    text-align: right;
    padding-right: 10px;
    padding-top: 8px;
  }
  & > div {
    flex: 1;
  }
}
.form-item + .form-item {
  margin-top: 15px;
}

.cover-container {
  .section-css();
  .image-list {
    display: flex;
    flex-wrap: wrap;
  }
  .cover-item {
    position: relative;
    margin: 5px;
    .image {
      width: 50px;
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: solid 1px #ddd;
      border-radius: 5px;
      background-color: #fff;
      cursor: pointer;
    }
    .is-cover {
      position: absolute;
      top: -10px;
      left: -10px;
      color: #fff;
      background-color: #3d71a7;
      font-size: 12px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 100%;
    }
    .remove {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
      color: #fff;
      background-color: #ee3333;
      font-size: 12px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 100%;
      cursor: pointer;
    }
    &:hover .remove {
      display: block;
    }
  }
}


.file-upload-list {

  .upload-item + .upload-item {
    margin-top: 4px;
  }

  .upload-item {
    display: flex;
    font-size: 12px;
  }
}


.variant-container {
  margin-top: 20px;
  .variant-section + .variant-section {
    margin-top: 20px;
  }
  .variant-section {
    .section-css();
  }
  .name {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: solid 1px #ddd;
  }
  .value-list {
    margin-left: 20px;
    .value-item + .value-item {
      margin-top: 5px;
    }
    .value-item {
      display: flex;
      align-items: center;
      .text {
        flex: 1;
        font-size: 14px;
      }
      .price {
        margin: 0 20px;
        font-size: 12px;
      }
      .color-image {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: solid 1px #ddd;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 5px;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}


.variant-options {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eee;
  margin-bottom: 20px;
  .option-item {
    padding: 5px 20px;
    cursor: pointer;
    position: relative;
    .option-values {
      position: absolute;
      z-index: 100;
      top: 30px;
      left: -100px;
      width: 400px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0 3px 5px rgba(0,0,0,.3);
      border-radius: 5px;
      border: solid 1px #eee;
      display: none;
      flex-wrap: wrap;
      align-items: center;
      .checkbox-wrapper {
        padding: 5px 8px;
        margin: 5px;
        border: solid 1px #fff;
        border-radius: 10px;
        i {
          visibility: hidden;
        }
        &.selected {
          border-color: #368e0c;
          i {
            visibility: visible;
          }
        }
      }
    }
    &:hover .option-values {
      display: flex;
    }
  }
}
</style>
