<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" type="primary" @click="handleOpenImportModal">导入邮箱</a-button>
    <a-button slot="toolbar" @click="handleExportEmails">导出邮箱</a-button>
    <a-button slot="toolbar" @click="$refs.groupDialog.open()">分组管理</a-button>
    <a-dropdown slot="toolbar">
      <a-menu slot="overlay" @click="handleSetGroup">
        <a-menu-item v-for="item in emailGroupOptions" :key="item.id">{{item.name}}（{{item.emailTotal}}）</a-menu-item>
      </a-menu>
      <a-button style="margin-left: 8px"> 设置分组 <a-icon type="down" /> </a-button>
    </a-dropdown>
    <a-dropdown slot="toolbar">
      <a-menu slot="overlay" @click="handleRemoveGroup">
        <a-menu-item v-for="item in emailGroupOptions" :key="item.id">{{item.name}}（{{item.emailTotal}}）</a-menu-item>
      </a-menu>
      <a-button type="danger" style="margin-left: 8px"> 取消分组 <a-icon type="down" /> </a-button>
    </a-dropdown>


    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="邮箱">
          <a-input v-model="searchParams.email" :allow-clear="true" style="width: 200px;" />
        </a-form-model-item>
        <a-form-model-item label="分组">
          <a-select v-model="searchParams.groupId" style="width: 220px;" :allow-clear="true">
            <a-select-option :value="item.id" v-for="item in emailGroupOptions" :key="item.id">
              {{item.name}}（{{item.emailTotal}}）
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="来源">
          <a-input v-model="searchParams.source" :allow-clear="true" style="width: 120px;" />
        </a-form-model-item>
        <a-form-model-item label="标签">
          <a-input v-model="searchParams.tag" :allow-clear="true" style="width: 120px;" />
        </a-form-model-item>
        <a-form-model-item label="发货日期">
          <a-range-picker @change="handleCreateDateChanged"
                          value-format="YYYY-MM-DD"
                          style="width: 220px;"
          >
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        class="table"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="unsubscribe" slot-scope="unsubscribe, row">
        <a-switch @change="handleSubscribeStatusChange(row, $event)" v-model="row.unsubscribe"></a-switch>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <modal ref="importModal" title="导入邮箱" @ok="handleUploadEmailFile" :loading="importModal.loading">
      <a-form-model :model="importModal.form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-form-model-item label="分组">
          <a-select v-model="importModal.form.groupId" style="width: 100%">
            <a-select-option :value="item.id" v-for="item in emailGroupOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="文件">
          <a-button @click="handleSelectFile" icon="file" style="width: 100%;">
            <span v-if="importModal.form.file">
              {{importModal.form.file.name}}（{{importModal.form.file.size | size}}）
            </span>
            <span v-else>选择文件（CSV文件，一行一个邮箱）</span>
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </modal>

    <group-dialog ref="groupDialog" @close="loadEmailGroups()"></group-dialog>
  </app-page>
</template>

<script>
import { ROUTE_EDM_EMAIL } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { listEdmEmails, importFromCsv, setEmailGroup, updateUnsubscribe, exportEmails } from '@/http/api/edm-email'
import { listAll as listAllEmailGroups } from '@/http/api/edm-email-group'
import selectFiles from '@/utils/select-file'
import kit from '@/utils/kit'
import GroupDialog from './comp/group-dialog'

export default {
  components: { GroupDialog },
  data () {
    return {
      routeName: ROUTE_EDM_EMAIL,
      pageLoading: false,
      searchParams: {
        groupId: null,
        email: null,
        tag: null,
        source: null,
        createTimeStart: null,
        createTimeEnd: null
      },
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '邮箱', dataIndex: 'email', width: 240 },
        { title: 'Subscribe', dataIndex: 'unsubscribe', width: 120, scopedSlots: { customRender: 'unsubscribe' } },
        { title: '分组', dataIndex: 'groupName' },
        { title: '创建日期', dataIndex: 'createTime' },
        { title: '标签', dataIndex: 'tags' },
        { title: '来源', dataIndex: 'source' }
      ],
      emailGroupOptions: [],
      importModal: {
        loading: false,
        form: {
          groupId: null,
          file: null
        }
      },
      selectedRowKeys: []
    }
  },
  filters: {
    size (size) {
      return kit.str.fmtSize(size)
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      listEdmEmails()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleOpenImportModal () {
      this.$refs.importModal.open()
    },
    handleSelectFile () {
      selectFiles({
        multi: false,
        accept: ['text/csv'],
        select: files => {
          this.importModal.form.file = files[0]
        }
      })
    },
    handleUploadEmailFile () {
      this.importModal.loading = true
      importFromCsv()
        .complete(() => (this.importModal.loading = false))
        .success(resp => {
          this.importModal.form.groupId = null
          this.importModal.form.file = null
          this.$message.success('导入成功。')
          this.$nextTick(() => {
            this.$refs.importModal.close()
          })
        })
        .send(this.importModal.form.file, this.importModal.form.groupId)
    },
    loadEmailGroups () {
      listAllEmailGroups().success(resp => (this.emailGroupOptions = resp.data)).send(true)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleSetGroup (e) {
      const g = this.emailGroupOptions.find(item => item.id === e.key)
      this.updateEmailGroup(g, true)
    },
    handleRemoveGroup (e) {
      const g = this.emailGroupOptions.find(item => item.id === e.key)
      this.updateEmailGroup(g, false)
    },
    updateEmailGroup (group, setOrRemove) {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请先选择邮箱。')
      }
      let msg
      if (setOrRemove) {
        msg = `确定要将选中的 ${this.selectedRowKeys.length} 个邮箱分配到【${group.name}】组中吗？`
      } else {
        msg = `确定要将选中的 ${this.selectedRowKeys.length} 个邮箱从【${group.name}】组中移除吗？`
      }
      this.$confirm({
        title: '确认',
        content: msg,
        onOk: () => {
          this.pageLoading = true
          setEmailGroup()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.loadData()
              this.loadEmailGroups()
            })
            .send({
              set: setOrRemove,
              groupId: group.id,
              emailIdList: [...this.selectedRowKeys]
            })
        }
      })
    },
    handleSubscribeStatusChange (email) {
      updateUnsubscribe()
        .success(() => {
          this.$message.success('更新成功。')
        })
        .error(() => {
          this.$message.error('更新出错。')
          email.unsubscribe = !email.unsubscribe
        })
        .send(email.email, email.unsubscribe)
    },
    handleCreateDateChanged (dates) {
      if (dates && dates.length === 2) {
        this.searchParams.createTimeStart = dates[0] + ' 00:00:00'
        this.searchParams.createTimeEnd = dates[1] + ' 23:59:59'
      } else {
        this.searchParams.createTimeStart = null
        this.searchParams.createTimeEnd = null
      }
    },
    handleExportEmails () {
      this.$confirm({
        title: '确认',
        content: '导出操作会导出当前查询条件的邮箱，最多导出1万个邮箱，确定要导出吗？',
        onOk: () => {
          exportEmails(this.searchParams)
        }
      })
    }
  },
  mounted () {
    this.loadData()
    this.loadEmailGroups()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.table @{deep} .ant-switch {
  background-color: #41a713;
}
.table @{deep} .ant-switch-checked {
  background-color: #d43725;
}
</style>
