<template>
  <ckeditor ref="editor"
            :editor-url="editorUrl"
            :config="editorConfig"
            @namespaceloaded="onNamespaceLoaded"
            @ready="onEditorReady"
            :readOnly="readOnly"
  >
  </ckeditor>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
let CKEDITOR_HOLDER = null
export default {
  name: 'rich-editor',
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    resize: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    content: { type: String, default: null },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    let removeButtons = null
    let toolbarGroups = null
    if (this.simple) {
      // removeButtons = 'Source,Save,NewPage,ExportPdf,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Maximize,BGColor,ShowBlocks,TextColor,About,Styles,Format,Font,FontSize,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Anchor,Language,BidiRtl,BidiLtr,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Blockquote,CreateDiv,Indent,Outdent,NumberedList,BulletedList,CopyFormatting,RemoveFormat,Strike,Subscript,Superscript,Templates'
      removeButtons = 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,Maximize,About,ShowBlocks,Styles,Format,Font'
      toolbarGroups = [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] }
      ]
    } else {
      removeButtons = 'Save,NewPage,ExportPdf,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Language,Flash,Image,Smiley,Iframe,ShowBlocks,About'
      toolbarGroups = [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ]
    }

    return {
      editor: null,
      // https://cdn.ckeditor.com/
      editorUrl: 'https://cdn.ckeditor.com/4.15.1/full-all/ckeditor.js',
      editorConfig: {
        language: 'zh-cn',
        // 在线toolbar配置
        // https://ckeditor.com/latest/samples/toolbarconfigurator/index.html#basic
        toolbarGroups: toolbarGroups,
        removeButtons: removeButtons,
        resize_enabled: this.resize
      },
      unReadyContent: null // 当编辑器未准备好时，外部就将内容设置进来，先暂存这个内容，待编辑器准备好后再设置
    }
  },
  computed: {
    isReady () {
      return this.editor != null
    }
  },
  watch: {
    content (content, content2) {
      if (this.isReady) {
        const editorContent = this.editor.getData()
        // 少了这个if判断，会导致在编辑器里敲回车使焦点返回第一个位置
        // 这是因为敲回车后content和conteng2的内容不相同
        if (editorContent !== content) {
          this.editor.setData(content)
        }
      }
    }
  },
  methods: {
    /**
     * 插件安装
     * @param CKEDITOR
     */
    onNamespaceLoaded (CKEDITOR) {
      if (!this.simple) {
        this.editorConfig.extraPlugins = [
          'maxheight',
          'html5video',
          'youtube',
          'tableresizerowandcolumn',
          'file-manager-adapter'
        ]
        for (const plugName of this.editorConfig.extraPlugins) {
          CKEDITOR.plugins.addExternal(plugName, '/script/ckeditor-plugins/' + plugName + '/plugin.js')
        }
      }
      CKEDITOR_HOLDER = CKEDITOR
    },
    onEditorReady () {
      this.editor = CKEDITOR_HOLDER.replace(this.$refs.editor.$el, this.editorConfig)
      this.initAdapter(this.editor)
      this.editor.setData(this.content)
      this.editor.on('change', (e) => {
        const content = e.editor.getData()
        this.$emit('update:content', content)
      })
    },
    initAdapter (editor) {
      editor.commandOpenFileManager = () => {
        this.$fileManager.openForMultiSelect(files => {
          for (const file of files) {
            editor.insertHtml(`<img src="${file.url}" data-file-id="${file.id}">`)
          }
        })
      }
    }
  },
  beforeDestroy () {
    if (this.isReady) {
      this.editor.destroy()
    }
  }
}
</script>

<style scoped>

</style>
