<template>
  <div style="margin: 10px;">
    <a-empty v-if="dataList.length === 0" />
    <div class="list-item font-normal text-main" v-for="item in dataList" :key="item.id">
      <div class="font-bold font-large" style="margin-bottom: 5px;">
        <span v-if="item.type === 1" class="text-success">供应商发货</span>
        <span v-else class="text-danger">退货给供应商</span>
      </div>
      <div class="left-right-content">
        <div>发货：{{item.shipTime}}</div>
        <div v-if="item.arrivedTime">签收：{{item.arrivedTime}}</div>
      </div>
      <div class="left-right-content">
        <div>物流公司：{{item.logisticsName}}</div>
        <div>
          <span v-if="item.trackNo.startsWith('SF')" class="text-primary" style="cursor: pointer;" @click="handleOpenSFTracking(item.trackNo)">
            运单号：{{item.trackNo}}
          </span>
          <span v-else>运单号：{{item.trackNo}} </span>
        </div>
      </div>
      <div class="left-right-content">
        <div>操作人：{{item.userName}}</div>
        <div style="text-align: right;">
          <div v-if="item.imageUrl"
               :style="{'background-image': `url(${item.imageUrl})`}"
               style="display: inline-block;"
               class="image-item small"
               @click="$imageModal.open(item.imageUrl)"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING } from '@/constants/supplier-produce-item-status'
import { listShipmentByProduceItemId } from '@/http/api/produce-item-shipment'

export default {
  props: {
    itemId: { type: String, required: true }
  },
  data () {
    return {
      dataList: [],
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING
    }
  },
  watch: {
  },
  methods: {
    handleOpenSFTracking (no) {
      window.open(`https://www.sf-express.com/mobile/cn/sc/dynamic_functions/waybill/detail/${no}`)
    }
  },
  mounted () {
    listShipmentByProduceItemId()
      .success(resp => {
        this.dataList = resp.data
      })
      .send(this.itemId)
  }
}
</script>

<style lang="less" scoped>

.list-item {
  padding: 10px;
  border-bottom: solid 1px #eee;
}

.iframe {
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
