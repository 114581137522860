<!-- 工厂下单生产产品编辑器 -->
<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      :loading="loading"
      title="供应商下单"
      @close="onClose"
      @save="handleSaveProduceItem"
      :width="650"
  >
    <div v-if="order">
      <div class="form-item app_required-input">
        <label>订单号</label>
        <div><a-input v-model="form.number" /></div>
        <p class="text-danger font-mini">供应商会将这个订单编号和货物一起发货。</p>
      </div>
      <div class="form-item app_required-input">
        <label>供应商</label>
        <div>
          <a-select v-model="form.supplierId" style="width: 100%;">
            <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </div>
      </div>


      <a-divider orientation="left">生产商品列表 x {{produceTotal}}</a-divider>
      <div style="text-align: right; padding: 6px 0;">
        <a-button type="primary" @click="addProduceItemManual">新增</a-button>
        <a-button type="primary" @click="addProduceItemFromSku">新增（从SKU导入）</a-button>
      </div>
      <ul class="produce-list list-group">
        <li class="list-group-item" v-for="item in produceItems" :key="item.id">
          <div class="produce-item">
            <div class="image-item" :style="{'background-image': `url(${item.getFirstImage()})`}" @click="$imageModal.open(item.getImages())">
              <div class="image-total" v-if="item.getImages().length > 1">{{item.getImages().length}}</div>
            </div>
            <div class="main-content">
              <div style="display: flex;">
                <div style="flex: 1; margin-right: 10px;">{{item.variant}}</div>
                <div>x <span class="font-bold font-large-x text-danger">{{item.quantity}}</span></div>
              </div>

              <div class="text-primary font-mini">
                {{item.title}}
              </div>

              <div class="text-muted font-mini">
                SKU：<span>{{item.sku}}</span>
              </div>

              <div style="margin-top: 6px;" class="font-mini text-success font-bold" v-if="skuStocks[item.sku]">
                库存：
                <span v-for="stock in skuStocks[item.sku]" :key="stock.id" style="margin-right: 8px;">
                {{stock.warehouseName}} / <strong>{{stock.quantity}}</strong>
              </span>
              </div>
              <div v-else style="margin-top: 6px;" class="font-mini text-muted">
                库存：无
              </div>

              <div class="text-muted font-mini" style="margin-top: 6px;">
                备注：<span>{{item.remark}}</span>
              </div>

              <div v-if="item.customizeInfo" class="text-muted font-mini" style="margin-top: 6px;">
                定制：
                <span v-for="(c, index) in splitCustomize(item.customizeInfo)" :key="index" class="customize-item">
                {{c.name}}：{{c.value}} ({{c.unit}})
              </span>
              </div>

              <div class="text-muted font-mini" style="margin-top: 6px;">
                供应商：{{suppliersNameMap[item.supplierId]}}
                <div style="margin-top: 6px;" v-if="skuProduceTotal[item.sku]">
                  厂家推荐：
                  <span v-for="skuTotal in skuProduceTotal[item.sku]" :key="skuTotal.supplierId" style="margin-right: 5px;">
                  {{skuTotal.supplierName}} / <strong>{{skuTotal.total}}</strong>
                  </span>
                </div>
              </div>

              <div style="text-align: right;">
                <a-button size="small" @click="updateProduceItem(item)">修改</a-button>
                <a-button size="small" type="danger" @click="removeProduceItem(item)">移除</a-button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <stock-product-select ref="productSelector"></stock-product-select>
    <sub-editor ref="subEditor" :supplier-options="supplierOptions" @saved="handleSubEditorSaved"></sub-editor>
  </drawer>
</template>

<script>

import { getOrderDetail } from '@/http/api/order'
import { listSupplier } from '@/http/api/supplier'
import { getProduceSkuTotal, addSupplierProduceItem } from '@/http/api/supplier-produce-item'
import { getStockBySku } from '@/http/api/stock-product'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import kit from '@/utils/kit'
import CustomizeMixin from '@/mixins/customize'
import StockProductSelect from '@/components-business/stock-product-select'
import SubEditor from './produce-item-sub-editor'

export default {
  components: { StockProductSelect, SubEditor },
  data () {
    return {
      visible: false,
      closable: true,
      loading: false,
      saveButtonLoading: false,
      order: null,
      form: {
        number: null,
        supplierId: null
      },
      supplierOptions: [],
      skuProduceTotal: {},
      produceItems: [],
      skuStocks: {}
    }
  },
  mixins: [CustomizeMixin],
  computed: {
    suppliersNameMap () {
      const map = {}
      for (const s of this.supplierOptions) {
        map[s.id] = s.name
      }
      return map
    },
    produceTotal () {
      let total = 0
      for (const item of this.produceItems) {
        total += item.quantity
      }
      return total
    },
    title () {
      return '供应商下单' + (this.produceTotal > 0 ? ' x ' + this.produceTotal : '')
    }
  },
  watch: {
    // 'form.supplierId' (newId, oldId) {
    //   for (const item of this.produceItems) {
    //     if (item.supplierId === oldId) {
    //       item.supplierId = this.form.supplierId
    //     }
    //   }
    // }
  },
  methods: {
    open (orderId) {
      this.visible = true
      this.$nextTick(() => {
        this.loadOrder(orderId)
      })
      this.loadSupplierOptions()

      if (window._orderProduceItemSupplierId) {
        this.form.supplierId = window._orderProduceItemSupplierId
      }
    },
    loadOrder (orderId) {
      this.loading = true
      getOrderDetail()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.order = resp.data
          this.form.number = this.order.orderNo
          this.processAfterLoadOrder()
        })
        .send(orderId)
    },
    newProduceItem (orderProduct) {
      return new SupplierProduceItemModel().setOrderProduct(orderProduct)
    },
    newProduceItemFromSkuStock (item) {
      const produceItem = new SupplierProduceItemModel().applyTempId()
      produceItem.title = item.title
      produceItem.sku = item.sku
      produceItem.setImages(item.imageUrl)
      produceItem.variant = item.variant
      produceItem.supplierId = this.form.supplierId
      produceItem.customizeInfo = item.customizeInfo
      produceItem.ordersId = this.order.id
      this.produceItems.push(produceItem)
    },
    processAfterLoadOrder () {
      const skuProduceTotal = {}
      const skuList = []
      for (const orderProduct of this.order.products) {
        if (orderProduct.title.toLowerCase().includes('free gift')) {
          continue
        }
        this.produceItems.push(this.newProduceItem(orderProduct))
        if (orderProduct.sku) {
          skuList.push(orderProduct.sku)
          skuProduceTotal[orderProduct.sku] = []
        }
      }
      this.skuProduceTotal = skuProduceTotal
      this.loadSkuProduceTotal()
      this.loadSkuStocks(skuList)
    },
    onClose () {
      this.order = null
      this.form.number = null
      this.form.supplierId = null
      this.produceItems = []
    },
    loadSupplierOptions () {
      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }
    },
    loadSkuProduceTotal () {
      const skuList = Object.keys(this.skuProduceTotal)
      getProduceSkuTotal()
        .success(resp => {
          for (const sku of skuList) {
            const totals = resp.data[sku]
            if (totals) {
              this.skuProduceTotal[sku] = totals
            }
          }
        })
        .send(skuList)
    },
    loadSkuStocks (skuList) {
      if (skuList && skuList.length > 0) {
        getStockBySku()
          .success(resp => {
            this.skuStocks = resp.data || {}
          })
          .send(skuList.join(','))
      }
    },
    removeProduceItem (item) {
      kit.arr.removeItem(this.produceItems, item)
    },
    addProduceItemManual () {
      const item = new SupplierProduceItemModel().applyTempId()
      item.ordersId = this.order.id
      item.supplierId = this.form.supplierId
      this.$refs.subEditor.open(item, false)
    },
    addProduceItemFromSku () {
      this.$refs.productSelector.open(selected => {
        for (const item of selected) {
          this.newProduceItemFromSkuStock(item)
        }
      })
    },
    updateProduceItem (item) {
      this.$refs.subEditor.open(item, true)
    },
    handleSubEditorSaved (isUpdate, item) {
      if (!isUpdate) {
        this.produceItems.push(item)
      }
    },
    handleSaveProduceItem () {
      if (!this.form.supplierId || !this.form.number) {
        return this.$message.error('供应商或订单编号不能为空。')
      }
      this.$confirm({
        title: '确认',
        content: '信息无误，确认下单？',
        onOk: () => {
          for (const item of this.produceItems) {
            item.number = this.form.number
            if (!item.supplierId) {
              item.supplierId = this.form.supplierId
            }
          }
          this.saveButtonLoading = true
          addSupplierProduceItem()
            .complete(() => (this.saveButtonLoading = false))
            .success(() => {
              this.visible = false
              this.$message.success('下单成功。')
              this.$emit('saved', this.order, this.produceItems.length)
              window._orderProduceItemSupplierId = this.form.supplierId
            })
            .send(this.produceItems)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.produce-list {
  margin-top: 20px;
}
.produce-item {
  display: flex;
  .image-item {
    overflow: visible;
    .image-total {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #fff;
      background-color: #3d71a7;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 13px;
    }
  }

  .main-content {
    margin-left: 20px;
    flex: 1;
  }

  .customize-item + .customize-item {
    margin-left: 38px;
  }
  .customize-item {
    display: inline-block;
    color: #d4371a;
  }
}
</style>
