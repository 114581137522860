<template>
  <a-modal
      title="SKU规则管理器"
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
      width="80%"
      :body-style="{height: '500px'}"
      :dialog-style="{top: '50px'}"
      wrap-class-name="sku-option-manager"
      :zIndex="1005"
  >
    <div class="wrapper" v-if="dataMap">

      <div class="group-content">
        <ul class="list-group">
          <li class="list-group-item"
              style="cursor: pointer"
              :class="{'active': item === selectedGroupName}"
              @click="selectedGroupName = item"
              v-for="item in groupNames"
              :key="item">{{getGroupTitle(item)}}</li>
        </ul>
      </div>

      <div class="main-content">
        <div class="toolbar">
          <div class="filter">
            <a-input v-model="valueFilter" placeholder="输入关键字" />
          </div>
          <a-button type="primary" @click="handleOpenEditor(null)">添加SKU规则</a-button>
        </div>
        <div class="list-wrapper">
          <div class="option-item-header">
            <div class="select">
              <a-button size="small" @click="handleCheckAll">全选</a-button>
            </div>
            <div class="value">值</div>
            <div class="sku-code">SKU代码</div>
            <div class="buttons">操作</div>
          </div>
          <div class="sku-option-list">
            <div class="option-item" v-for="item in filterSkuOptions" :key="item.id">
              <div class="select">
                <a-checkbox v-model="item._checked"></a-checkbox>
              </div>
              <div class="value">{{item.value}}</div>
              <div class="sku-code">{{item.skuCode}}</div>
              <div class="buttons">
                <a-button icon="edit" size="small" @click="handleOpenEditor(item)"></a-button>
                <a-button icon="delete" size="small" type="danger" @click="handleDelete(item)" :loading="deleting"></a-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="editor" v-show="editor.visible">
        <a-form-model ref="editorForm" :model="editor.form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item label="值" prop="value">
            <a-input v-model="editor.form.value" />
            <p class="font-mini text-primary" style="line-height: normal;">单词之间用一个空格隔开，每个单词的首字母都要大写</p>
          </a-form-model-item>
          <a-form-model-item label="SKU代码" prop="skuCode">
            <a-input v-model="editor.form.skuCode" />
            <p class="font-mini text-primary" style="line-height: normal;">请填3～5位大写字母用于表示该值的SKU代码，不填的话系统在生成SKU时会随机生成，一旦填写请不要随意改动。</p>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="groupName">
            <a-auto-complete
                v-model="editor.form.groupName"
                :data-source="editor.groupNames"
                style="width: 100%"
                placeholder="输入关键字"
                @search="onGroupNameSearch"
            />
          </a-form-model-item>
        </a-form-model>
        <div class="buttons" style="text-align: right;">
          <a-button @click="handleEditorSave" type="primary" :loading="editor.saving">保存</a-button>
          <a-button @click="handleEditorClose" :loading="editor.saving">取消</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { listSkuOptions, addSkuOption, updateSkuOption, deleteSkuOption } from '@/http/api/sku-option'
import {
  SKU_GROUP_NAME_MOMME,
  SKU_GROUP_NAME_TYPE,
  SKU_GROUP_NAME_SIZE,
  SKU_GROUP_NAME_COLOR,
  SKU_GROUP_NAME_SUB_TYPE,
  SKU_GROUP_NAME_SEX
} from '@/constants/sku-option-group-name'
import kit from '@/utils/kit'

/**
 * 默认的分组名：
 * size：尺码
 * color：颜色
 * momme：姆数（真丝产品）
 * type：类型标签，用于生成SKU的前缀代码，也是产品的分类
 */
const DEFAULT_GROUP_NAMES = [SKU_GROUP_NAME_SIZE, SKU_GROUP_NAME_COLOR, SKU_GROUP_NAME_MOMME, SKU_GROUP_NAME_TYPE]

export default {
  data () {
    return {
      visible: false,
      closable: true,
      selectCallback: null,
      dataMap: null,
      selectedGroupName: null,
      valueFilter: null,
      editor: {
        visible: false,
        groupNames: DEFAULT_GROUP_NAMES,
        saving: false,
        model: null,
        form: {
          value: null,
          skuCode: null,
          groupName: null
        }
      },
      deleting: false,
      checkAll: false
    }
  },
  watch: {
    selectedGroupName (newGroupName, oldGroupName) {
      if (oldGroupName) {
        for (const item of this.dataMap[oldGroupName]) {
          item._checked = false
        }
      }
    },
    visible () {
      if (!this.visible) {
        this.$emit('closed')
      }
    }
  },
  computed: {
    groupNames () {
      if (this.dataMap) {
        const arr = Object.keys(this.dataMap).filter(g => {
          return g !== SKU_GROUP_NAME_SEX && g !== SKU_GROUP_NAME_TYPE && g !== SKU_GROUP_NAME_SUB_TYPE
        })
        return [SKU_GROUP_NAME_TYPE, SKU_GROUP_NAME_SUB_TYPE, SKU_GROUP_NAME_SEX, ...arr]
      } else {
        return []
      }
    },
    skuOptions () {
      if (this.dataMap) {
        return this.dataMap[this.selectedGroupName]
      } else {
        return []
      }
    },
    filterSkuOptions () {
      if (this.valueFilter) {
        const v = this.valueFilter.toLowerCase()
        const arr = []
        for (const item of this.skuOptions) {
          if (item.value.toLowerCase().includes(v)) {
            arr.push(item)
          }
        }
        return arr
      } else {
        return this.skuOptions
      }
    },
    checkedOptions () {
      return this.skuOptions.filter(item => item._checked)
    }
  },
  methods: {
    /**
     * @param selectCallback {Function}
     * @param [defaultSelectedGroup] {String}
     */
    open (selectCallback, defaultSelectedGroup) {
      this.selectCallback = selectCallback
      this.visible = true
      listSkuOptions()
        .success(resp => {
          for (const key of Object.keys(resp.data)) {
            for (const item of resp.data[key]) {
              item._checked = false
            }
            if (key === SKU_GROUP_NAME_COLOR) {
              resp.data[key].sort((a, b) => a.value.localeCompare(b.value))
            } else if (key === SKU_GROUP_NAME_MOMME) {
              resp.data[key].sort((a, b) => a.value - b.value)
            }
          }
          this.dataMap = resp.data
          if (this.groupNames.length > 0) {
            this.selectedGroupName = defaultSelectedGroup || this.groupNames[0]
          }
        })
        .send()
    },
    handleOk () {
      if (this.selectCallback) {
        const data = {
          group: this.selectedGroupName,
          options: []
        }
        for (const item of this.checkedOptions) {
          data.options.push(Object.assign({}, item))
        }
        this.selectCallback(data)
      }
      this.handleCancel(true)
    },
    handleCancel (clearSelected) {
      this.visible = false
    },
    onGroupNameSearch (text) {
      this.editor.groupNames = !text ? DEFAULT_GROUP_NAMES : [text]
    },
    handleOpenEditor (model) {
      if (model) {
        this.editor.model = model
        this.editor.form.value = model.value
        this.editor.form.skuCode = model.skuCode
        this.editor.form.groupName = model.groupName
      } else {
        this.editor.form.groupName = this.selectedGroupName
      }
      this.editor.visible = true
    },
    handleEditorSave () {
      let data = Object.assign({}, this.editor.model || {})
      data = Object.assign(data, this.editor.form)
      if (data.skuCode) {
        data.skuCode = data.skuCode.toUpperCase()
      }

      const api = this.editor.model ? updateSkuOption : addSkuOption
      this.editor.saving = true
      api()
        .complete(() => (this.editor.saving = false))
        .success(resp => {
          resp.data._checked = false
          if (this.editor.model) {
            Object.assign(this.editor.model, resp.data)
          } else {
            let list = this.dataMap[resp.data.groupName]
            if (!list) {
              list = []
              this.$set(this.dataMap, resp.data.groupName, list)
            }
            list.push(resp.data)
          }
          this.$message.success('保存成功。')
          this.$emit('change', this.dataMap)
          this.handleEditorClose()
        })
        .send(data)
    },
    handleEditorClose () {
      this.$refs.editorForm.resetFields()
      this.editor.model = null
      this.editor.visible = false
    },
    handleCheckAll () {
      const check = this.checkedOptions.length === 0
      for (const item of this.skuOptions) {
        item._checked = check
      }
    },
    handleDelete (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.value}】吗？`,
        zIndex: 1100,
        onOk: () => {
          this.deleting = true
          deleteSkuOption()
            .complete(() => (this.deleting = false))
            .success(() => {
              kit.arr.removeItem(this.dataMap[this.selectedGroupName], item)
              this.$message.success('删除成功。')
            })
            .send(item.id)
        }
      })
    },
    getGroupTitle (group) {
      if (group === SKU_GROUP_NAME_SEX) {
        return '性别'
      } else if (group === SKU_GROUP_NAME_TYPE) {
        return '大类'
      } else if (group === SKU_GROUP_NAME_SUB_TYPE) {
        return '品类'
      } else {
        return group
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
  height: 452px;
  display: flex;
}
.group-content {
  width: 240px;
  min-width: 240px;
  margin-right: 50px;
  overflow-y: auto;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  position: relative;

  .toolbar {
    position: sticky;
    top: 0;
    display: flex;
    padding-bottom: 8px;
    z-index: 100;
    background-color: #fff;
    .filter {
      flex: 1;
      margin-right: 20px;
    }
  }
}

.option-item-header {
  font-weight: bold;
}
.option-item, .option-item-header {
  display: flex;
  border-bottom: solid 1px #eee;
  padding: 8px 0;
  &:hover {
    background-color: #f2f2f2;
  }
  .select {
    width: 60px;
  }
  .value {
    flex: .5;
  }
  .sku-code {
    flex: .5;
  }
  .buttons {
    width: 80px;
  }
}

.editor {
  position: absolute;
  top: 80px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  padding: 25px 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px 3px rgba(0,0,0,.3);
}
</style>
