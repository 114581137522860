export const ROUTE_HOME = 'home'
export const ROUTE_DASHBOARD = 'dashboard'
export const ROUTE_LOGIN = 'login'
export const ROUTE_404 = 'not-found'


export const ROUTE_USER = 'admin'
export const ROUTE_ROLE = 'role'

// 数据分析
export const ROUTE_ANALYSIS_COST = 'analysis-cost'
export const ROUTE_ANALYSIS_STOCK_ALERT = 'analysis-stock-alert'
export const ROUTE_ANALYSIS_SALE = 'analysis-sale'
export const ROUTE_ANALYSIS_PROFIT_LOSS = 'analysis-profit-loss'
export const ROUTE_PROFIT_LOSS_SYNC = 'profit-loss-sync'


// 库存
export const ROUTE_WAREHOUSE = 'warehouse'
export const ROUTE_STOCK_PRODUCT = 'stock-product'
export const ROUTE_WAREHOUSE_STOCK_RECORD = 'warehouse-stock-record'
export const ROUTE_STOCK_CHECK = 'stock-check'
export const ROUTE_STOCK_CHECK_ITEM = 'stock-check-item'
export const ROUTE_STOCK_TRANSFER = 'stock-transfer'
export const ROUTE_STOCK_ALERT = 'stock-alert'
export const ROUTE_ABROAD_UPLOAD_SKU = 'abroad'
export const ROUTE_SKU_STOCK_TRANSFER = 'sku-stock-transfer'
export const ROUTE_WAREHOUSE_PREPARE_USER = 'warehouse-prepare-user'
export const ROUTE_INVENTORY_CHANGE_REQUEST = 'inventory-change-request'
export const ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS = 'inventory-change-request-products'

// 订单
export const ROUTE_ORDER = 'order'
export const ROUTE_ORDER_DEMO = 'order-demo'
export const ROUTE_ORDER_DETAIL = 'order-detail'
export const ROUTE_ORDER_DEMO_DETAIL = 'order-demo-detail'
export const ROUTE_ABANDONED_CHECKOUT = 'abandoned-checkout'
export const ROUTE_AIRWALLEX = 'airwallex'
export const ROUTE_ORDER_IMPORT = 'order-import'
export const ROUTE_ORDER_COMMISSION_UPDATE = 'order-commission-update'

// 物流
export const ROUTE_ORDER_DELIVERY = 'order-delivery'
export const ROUTE_ORDER_DELIVERY_ITEM = 'order-delivery-item'
export const ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE = 'order-delivery-update-tracking-from-file'
export const ROUTE_T17TRACK = 't17track'
export const ROUTE_ORDER_DELIVERY_PREPARE = 'order-delivery-prepare'

// 售后
export const ROUTE_ORDER_RETURN = 'order-return'
export const ROUTE_AFTER_SALE_SESSION = 'after-sale-session'
export const ROUTE_AFTER_SALE_SESSION_DETAIL = 'after-sale-session-detail'

// 财务
export const ROUTE_ACCOUNTING = 'accounting'

// 线上店铺 - 产品
export const ROUTE_PRODUCT_ADD = 'product-add'
export const ROUTE_PRODUCT_SKU_GENERATOR = 'product-sku-generator'
export const ROUTE_PRODUCT_3C = 'product-3c'
export const ROUTE_PRODUCT_SKU_STYLE = 'sku-style'

// 供应商
export const ROUTE_SUPPLIER = 'supplier'
export const ROUTE_SUPPLIER_PRODUCE_ITEM = 'supplier-produce-item'
export const ROUTE_SUPPLIER_DELIVERY = 'supplier-delivery'
export const ROUTE_SUPPLIER_DELIVERY_DETAIL = 'supplier-delivery-detail'
export const ROUTE_SUPPLIER_DELIVERY_READY = 'supplier-delivery-ready'

// EDM
export const ROUTE_EDM_EMAIL_GROUP = 'edm_email_group'
export const ROUTE_EDM_EMAIL = 'edm_email'
export const ROUTE_EDM_ACTIVITY = 'edm_activity'
export const ROUTE_EDM_ACTIVITY_EMAILS = 'edm_activity_emails'

// 供应商用户
export const ROUTE_SUPPLIER_ORDER_DELIVERY = 'supplier-self-delivery'


// 设置
export const ROUTE_SETTING_NOTIFY_USER = 'notify-user'

export const ROUTE_SHOP = 'shop'
export const ROUTE_SHOP_ORDER_SYNC = 'shop-order-sync'

export const ROUTE_SHIPPING_METHOD_ADVICE = 'shipping-method-advice'

// 红人推广
export const ROUTE_INFLUENCER = 'influencer'
export const ROUTE_INFLUENCER_DETAIL = 'influencer-detail'

// 合作公司
export const ROUTE_PARTNER = 'partner'
// 合作公司消耗的广告费
export const ROUTE_PARTNER_ADS_COST = 'partner-ads-cost'


export const ROUTE_TEST = 'test'
