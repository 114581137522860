<template>
  <modal ref="pr" :title="title" :width="500" @closed="onClosed" :hide-buttons="true">
    <div v-if="order" style="max-height: 400px; overflow-y: auto;">
      <multi-delivery-package-content :order="order" :package-advice="packageAdvice" :show-button="true" @packageChanged="$emit('packageChanged', order)"></multi-delivery-package-content>
    </div>
  </modal>
</template>

<script>
import MultiDeliveryPackageContent from '@/pages/delivery/comp/multi-delivery-package-content'
export default {
  components: { MultiDeliveryPackageContent },
  data () {
    return {
      order: null,
      packageAdvice: null
    }
  },
  computed: {
    title () {
      return this.order ? '物流包装 - ' + this.order.orderNo : '物流包装'
    }
  },
  methods: {
    open (order, packageAdvice) {
      this.order = order
      this.packageAdvice = packageAdvice
      this.$refs.pr.open()
    },
    onClosed () {
      this.order = null
      this.packageAdvice = null
    }
  }
}
</script>

<style lang="less" scoped>
</style>
