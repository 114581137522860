<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="售后会话编辑器"
    @save="onSave"
    @close="onClose"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="客户邮箱" class="app_required-input" prop="email">
        <a-input v-model="form.email" :disabled="emailReadonly" @blur="handleEmailBlur()" placeholder="请填写客户下单时的邮箱"/>
        <div v-show="totalSessionWithCustomerEmail > 0" class="font-mini" style="line-height: normal;">
          该邮箱下已经有{{totalSessionWithCustomerEmail}}个服务话题，<span class="text-primary" style="cursor: pointer;" @click="navCustomer">点击跳转</span>到该客户的话题下。
        </div>
      </a-form-model-item>
      <a-form-model-item label="订单号" class="app_required-input" prop="ordersId">
        <a-select v-model="form.ordersId" style="width: 100%;" :disabled="!!useOrderId">
          <a-select-option :value="item.id" v-for="item in orderOptions" :key="item.id">{{item.orderNo}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" class="app_required-input" prop="title">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="tags">
        <a-select mode="tags" style="width: 100%" v-model="form.tags">
          <a-select-option :value="item" v-for="item in tagOptions" :key="item">{{item}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" class="app_required-input" prop="status">
        <a-select v-model="form.status" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="优先级" class="app_required-input" prop="priority">
        <a-select v-model="form.priority" style="width: 100%;">
          <a-select-option :value="item.id" v-for="item in priorityOptions" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="案件类别" class="app_required-input" prop="category">
        <a-input v-model="form.category"/>
        <div>
          <a-tag color="#CA66F5" style="cursor: pointer;" @click="form.category = '退货'">退货</a-tag>
          <a-tag color="orange" style="cursor: pointer;" @click="form.category = '换货'">换货</a-tag>
          <a-tag color="#F14426" style="cursor: pointer;" @click="form.category = '取消订单'">取消订单</a-tag>
          <a-tag color="#62A6DB" style="cursor: pointer;" @click="form.category = '订单咨询'">订单咨询</a-tag>
          <a-tag color="#999999" style="cursor: pointer;" @click="form.category = '其他'">其他</a-tag>
        </div>
      </a-form-model-item>
    </a-form-model>

  </drawer>
</template>

<script>
import {
  STATUS_LIST,
  STATUS_SERVICE,
  PRIORITY_LIST,
  PRIORITY_NORMAL
} from '@/constants/after-sale-session'
import { addSession, updateSession, getTags, listSessionByCustomerEmail } from '@/http/api/after-sale-session'
import { listSimpleByEmail } from '@/http/api/order'
import { ROUTE_AFTER_SALE_SESSION_DETAIL } from '@/router/router-constants'

export default {
  props: {
    // 如果提供这个id，那么订单下拉列表就不可选择
    useOrderId: { type: String }
  },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      emailReadonly: false,
      form: {
        email: null,
        title: null,
        ordersId: null,
        tags: [],
        status: STATUS_SERVICE,
        priority: PRIORITY_NORMAL,
        category: null
      },
      statusOptions: STATUS_LIST,
      priorityOptions: PRIORITY_LIST,
      orderOptions: [],
      tagOptions: [],
      totalSessionWithCustomerEmail: 0
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.model
    }
  },
  methods: {
    /**
     * @param email {String}
     * @param [model] {Object}
     */
    open (email, model) {
      this.visible = true
      this.emailReadonly = email !== 'new'
      if (this.emailReadonly) {
        this.form.email = email
        this.loadOrders()
      }
      if (model) {
        this.model = model
        this.$nextTick(() => {
          this.form.title = model.title
          this.form.ordersId = model.ordersId
          this.form.tags = model.tags ? model.tags.split(',') : []
          this.form.status = model.status || STATUS_SERVICE
          this.form.priority = model.priority || PRIORITY_NORMAL
          this.form.category = model.category
        })
      }
      if (this.useOrderId) {
        this.form.ordersId = this.useOrderId
      }
      getTags().success(resp => (this.tagOptions = resp.data)).send()
    },
    onClose () {
      this.$refs.form.resetFields()
      this.model = null
      this.totalSessionWithCustomerEmail = 0
    },
    onSave () {
      const data = Object.assign({}, this.form)
      data.tags = data.tags.join(',')
      if (this.model) {
        data.id = this.model.id
      }
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? updateSession : addSession
      api()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(resp => {
          this.$emit('saved', resp.data, data.email)
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.visible = false
          })
        })
        .send(data)
    },
    handleEmailBlur () {
      this.loadOrders()
      this.loadSessionByEmail()
    },
    loadOrders () {
      if (this.form.email) {
        listSimpleByEmail()
          .success(resp => {
            this.orderOptions = resp.data
          })
          .send(this.form.email)
      }
    },
    loadSessionByEmail () {
      listSessionByCustomerEmail()
        .success(resp => {
          this.totalSessionWithCustomerEmail = resp.data.length
        })
        .send(this.form.email)
    },
    navCustomer () {
      this.$router.push({ name: ROUTE_AFTER_SALE_SESSION_DETAIL, params: { email: this.form.email } })
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
