<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button size="small" @click="reloadData">查询</a-button>
      <a-button size="small" type="primary" @click="$refs.returnEditor.open()">新建退货</a-button>
    </div>

    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="订单号">
          <a-input v-model="searchParams.orderNo" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="运单号">
          <a-input v-model="searchParams.trackNo" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.status" :allow-clear="true" style="width: 150px;">
            <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.returnEditor.open(record)">编辑</a-button>
        <a-button size="small" type="danger" @click="removeItem(record)" icon="delete"></a-button>
      </div>
      <div slot="status" slot-scope="status">
        <span class="status" :class="'status-' + status">{{statusMapping[status]}}</span>
      </div>
      <div slot="returnItems" slot-scope="returnItems">
        <div class="image-item-wrapper">
          <a-tooltip v-for="item in returnItems" :key="item.id">
            <template slot="title">
              {{item.sku}}
            </template>
            <div class="image-item small" v-lazy:background-image="item.imageUrl">
              <span class="total" :class="{'total-more': item.quantity > 1}">{{item.quantity}}</span>
            </div>
          </a-tooltip>
        </div>
      </div>
      <div slot="trackNo" slot-scope="trackNo, record">
        <a v-if="record.trackUrl" :href="record.trackUrl" target="_blank">{{trackNo}}</a>
        <span v-else>{{trackNo}}</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <return-editor ref="returnEditor" @saved="reloadData()"></return-editor>
  </app-page>
</template>

<script>
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { ROUTE_ORDER_RETURN } from '@/router/router-constants'
import {
  ORDER_RETURN_STATUS_LIST,
  ORDER_RETURN_STATUS_MAPPING
} from '@/constants/order-return-status'
import { list, deleteById } from '@/http/api/order-return'
import ReturnEditor from './comp/return-editor'
import kit from '@/utils/kit'

function buildSearchParams () {
  return {
    status: null,
    trackNo: null,
    orderNo: null
  }
}

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  components: { ReturnEditor },
  data () {
    return {
      routeName: ROUTE_ORDER_RETURN,
      pageLoading: false,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'action' } },
        { title: '订单号', dataIndex: 'orderNo' },
        { title: '客户邮箱', dataIndex: 'customerEmail' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '创建日期', dataIndex: 'createTime' },
        { title: '签收日期', dataIndex: 'deliveryTime' },
        { title: '跟踪号', dataIndex: 'trackNo', scopedSlots: { customRender: 'trackNo' } },
        { title: '物流跟踪号', dataIndex: 'logisticsName' },
        { title: '退货物品', dataIndex: 'returnItems', scopedSlots: { customRender: 'returnItems' } },
        { title: '备注', dataIndex: 'remark' }
      ],
      searchParams: buildSearchParams(),
      statusOptions: ORDER_RETURN_STATUS_LIST,
      statusMapping: ORDER_RETURN_STATUS_MAPPING
    }
  },
  computed: {
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    removeItem (item) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${item.orderNo}】吗？`,
        onOk: () => {
          this.pageLoading = true
          deleteById()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              kit.arr.removeItem(this.dataList, item)
              this.$message.success('删除成功。')
            })
            .send(item.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.status {
  font-size: 14px;
}
.status-waitTrackNo {
  color: #5a5a5a;
}
.status-shipped {
  color: #3d71a7;
}
.status-delivered {
  color: #228018;
}
.status-lose {
  color: #bb3e30;
}


.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 10;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .total-more {
    background-color: #bb3e30;
  }
}
</style>
