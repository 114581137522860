<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="toolbar">
      <a-button size="small" @click="reloadData">查询</a-button>
      <a-button size="small" type="primary" @click="$refs.editor.open()">新建记账</a-button>
    </div>

    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="类别">
          <a-select v-model="searchParams.category" style="width: 150px;" :allow-clear="true">
            <a-select-option v-for="c in categoryOptions" :value="c" :key="c">{{c}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="摘要">
          <a-input v-model="searchParams.remark" placeholder="关键字" :allow-clear="true" style="width: 150px;" />
        </a-form-model-item>
        <a-form-model-item label="收支">
          <a-select v-model="searchParams.type" style="width: 100px;">
            <a-select-option :value="0">支出</a-select-option>
            <a-select-option :value="1">收入</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="日期">
          <a-range-picker v-model="searchParams.date" value-format="yyyy-MM-DD" style="width: 220px;"></a-range-picker>
        </a-form-model-item>

        <a-form-model-item label="报销">
          <a-select v-model="searchParams.reimbursement" style="width: 110px;">
            <a-select-option :value="true">需要报销</a-select-option>
            <a-select-option :value="false">不需要报销</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="排序">
          <a-select v-model="searchParams.orderBy" style="width: 150px;">
            <a-select-option value="create_date,asc">时间-较早到晚</a-select-option>
            <a-select-option value="create_date,desc">时间-较晚到早</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.editor.open(record)">编辑</a-button>
        <a-button size="small" type="danger" @click="removeItem(record)" icon="delete"></a-button>
      </div>
      <div slot="type" slot-scope="type">
        <span v-if="type === 0" class="text-danger">支出</span>
        <span v-else class="text-success">收入</span>
      </div>
      <div slot="imageUrl" slot-scope="imageUrl">
        <div v-if="imageUrl" class="image-item small" v-lazy:background-image="imageUrl" @click="$imageModal.open(imageUrl)"></div>
      </div>
    </a-table>

    <div slot="bottom">
      <div class="amount-summary">
        <span>当前查询条件下的</span>
        <span v-show="searchParams.type === 0" class="text-danger">总支出</span>
        <span v-show="searchParams.type === 1" class="text-success">总收入</span>
        <span class="amount">{{amountSummary | price}}</span>
      </div>
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <editor ref="editor" @saved="handleSaved"></editor>

  </app-page>
</template>

<script>
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { ROUTE_ACCOUNTING } from '@/router/router-constants'
import kit from '@/utils/kit'
import { list, deleteById, getCategories, getAmountSummary } from '@/http/api/accounting'
import Editor from './comp/editor'

function buildSearchParams () {
  return {
    category: null,
    remark: null,
    type: 0,
    date: [],
    reimbursement: null,
    orderBy: 'create_date,asc'
  }
}

export default {
  components: { Editor },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ACCOUNTING,
      pageLoading: false,
      amountSummary: 0,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '操作', scopedSlots: { customRender: 'action' } },
        { title: '日期', dataIndex: 'createDate' },
        { title: '类别', dataIndex: 'category' },
        { title: '摘要', dataIndex: 'remark' },
        { title: '收支', dataIndex: 'type', scopedSlots: { customRender: 'type' } },
        { title: '金额', dataIndex: 'amount' },
        { title: '创建人', dataIndex: 'adminName' },
        { title: '附件', dataIndex: 'imageUrl', scopedSlots: { customRender: 'imageUrl' } }
      ],
      searchParams: buildSearchParams(),
      categoryOptions: []
    }
  },
  computed: {
  },
  filters: {
    price (amount) {
      return kit.str.fmtCurrency(amount)
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      const tmp = p.orderBy.split(',')
      p.orderByName = tmp[0]
      p.orderByDirection = tmp[1]
      if (p.date.length === 2) {
        p.startDate = p.date[0]
        p.endDate = p.date[1]
      }
      delete p.date
      delete p.orderBy
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)

      getAmountSummary()
        .success(resp => (this.amountSummary = resp.data))
        .send(p)
    },
    removeItem (item) {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          this.pageLoading = true
          deleteById()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              kit.arr.removeItem(this.dataList, item)
              this.$message.success('删除成功。')
            })
            .send(item.id)
        }
      })
    },
    handleSaved (data) {
      kit.arr.pushOrReplace(this.dataList, data)
      if (!this.categoryOptions.includes(data.category)) {
        this.categoryOptions.push(data.category)
      }
    }
  },
  mounted () {
    this.loadData()
    getCategories().success(resp => (this.categoryOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
.status {
  font-size: 14px;
}
.status-waitTrackNo {
  color: #5a5a5a;
}
.status-shipped {
  color: #3d71a7;
}
.status-delivered {
  color: #228018;
}
.status-lose {
  color: #bb3e30;
}


.image-item{
  overflow: visible;
  .total {
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 10;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: #6ca2e3;
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .total-more {
    background-color: #bb3e30;
  }
}


.amount-summary {
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  .amount {
    margin-left: 10px;
    font-weight: bold;
    color: #3d71a7;
  }
}
</style>
