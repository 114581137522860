<!-- 库存盘点 -->
<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="toolbar">
      <a-button size="small" type="primary" @click="$refs.editor.open()">迁移库存</a-button>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.editor.open(record)">编辑</a-button>
      </div>
      <div slot="status" slot-scope="status">
        <span v-if="status === statusMap.inStock" class="text-success">已入库</span>
        <span v-else-if="status === statusMap.lock" class="text-danger">冻结</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <editor ref="editor" @saved="handleSaved"></editor>

  </app-page>
</template>

<script>
import { ROUTE_STOCK_TRANSFER } from '@/router/router-constants'
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listStockTransfer } from '@/http/api/stock-transfer'
import Editor from './comp/stock-transfer-editor'
import { STOCK_TRANSFER_STATUS_LOCK, STOCK_TRANSFER_STATUS_IN_STOCK } from '@/constants/stock-transfer-status'
import kit from '@/utils/kit'

export default {
  mixins: [PaginationMixin, AntdTableHeightMixin],
  components: { Editor },
  data () {
    return {
      routeName: ROUTE_STOCK_TRANSFER,
      dataColumns: [
        {
          title: '操作',
          width: 180,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '日期',
          dataIndex: 'createDate'
        },
        {
          title: '出库仓库',
          dataIndex: 'fromWarehouseName'
        },
        {
          title: '入库仓库',
          dataIndex: 'toWarehouseName'
        },
        {
          title: '操作员',
          dataIndex: 'adminName'
        }
      ],
      dataList: [],
      dataLoading: false,
      statusMap: {
        lock: STOCK_TRANSFER_STATUS_LOCK,
        inStock: STOCK_TRANSFER_STATUS_IN_STOCK
      }
    }
  },
  watch: {
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listStockTransfer()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send()
    },
    handleSaved (data) {
      kit.arr.insertOrReplace(this.dataList, 0, data)
    }
  },
  mounted () {
    this.reloadData()
  }
}
</script>

<style scoped>

</style>
