<template>
  <div style="display: inline-block;" :class="{'use-icon': useIcon}">
    <span class="status status-enabled" v-if="status">
      <a-icon v-if="useIcon" type="check-circle" />
      <span v-else>{{ enabledText }}</span>
    </span>
    <span class="status status-disabled" v-else>
      <a-icon v-if="useIcon" type="stop" />
      <span v-else>{{ disabledText }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'status',
  props: {
    status: { type: [Number, Boolean], default: true },
    useIcon: { type: Boolean, default: false },
    enabledText: { type: String, default: '可用' },
    disabledText: { type: String, default: '禁用' }
  }
}
</script>

<style lang="less" scoped>
.status {
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
}
.status-disabled {
  color: #F56C6C;
  border: solid 1px #F56C6C;
}
.status-enabled {
  color: #67C23A;
  border: solid 1px #67C23A;
}
.use-icon .status {
  font-size: 18px;
  border: none;
  padding: 0;
}
</style>
