<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :disableFooterButton="true"
      :loading="loading"
      title="分组管理"
      @close="onClose"
  >

    <div style="margin-bottom: 30px;">
      <a-button style="width: 100%;" type="primary" @click="handleOpenEditor()">添加分组</a-button>
    </div>


    <a-empty v-show="dataList.length === 0" />
    <ul class="list-group">
      <li class="list-group-item" v-for="g in dataList" :key="g.id">
        <div class="left-right-content" style="align-items: center">
          <div class="full">
            {{g.name}}
            <span class="text-primary">（{{g.emailTotal}}）</span>
          </div>
          <div>
            <a-button size="small" icon="edit" @click="handleOpenEditor(g)"></a-button>
            <a-button size="small" type="danger" icon="delete" @click="handleDeleteGroup(g)"></a-button>
          </div>
        </div>
      </li>
    </ul>

    <modal ref="editor" title="分组编辑" @ok="handleEditorSave" @closed="handleEditorClose" :loading="editor.loading">
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="名称" prop="name" class="app_required-input">
          <a-input v-model="editor.form.name" />
        </a-form-model-item>
      </a-form-model>
    </modal>

  </drawer>
</template>

<script>
import { listAll, addGroup, updateGroup, deleteGroup } from '@/http/api/edm-email-group'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      loading: false,
      saveButtonLoading: false,
      dataList: [],
      editor: {
        loading: false,
        editingItem: null,
        form: {
          name: null
        }
      }
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open () {
      this.visible = true
      if (this.dataList.length === 0) {
        this.loadData()
      }
    },
    onClose () {
      this.$emit('close')
    },
    handleOpenEditor (item) {
      if (item) {
        this.editor.editingItem = item
        this.editor.form.name = item.name
      }
      this.$refs.editor.open()
    },
    handleEditorClose () {
      this.editor.form.name = null
      this.editor.editingItem = null
    },
    handleEditorSave () {
      let isUpdate
      let data
      if (this.editor.editingItem) {
        isUpdate = true
        data = {
          id: this.editor.editingItem.id,
          name: this.editor.form.name
        }
      } else {
        isUpdate = false
        data = {
          name: this.editor.form.name
        }
      }
      const api = isUpdate ? updateGroup : addGroup
      this.editor.loading = true
      api()
        .complete(() => (this.editor.loading = false))
        .success(resp => {
          this.loadData()
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.$refs.editor.close()
          })
        })
        .send(data)
    },
    loadData () {
      this.loading = true
      listAll()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(true)
    },
    handleDeleteGroup (g) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${g.name}】吗？`,
        onOk: () => {
          this.loading = true
          deleteGroup()
            .complete(() => (this.loading = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadData()
            })
            .send(g.id)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
