<template>
  <div class="container" :class="{'visible': visible}">
    <div class="small-panel">
      <div class="visible-icon" @click="visible = true">
        <a-icon type="left" />
      </div>
      <div class="content">
        {{submittingIndex >= 0 ? submittingIndex + 1 : 'N'}}
        /
        {{dataList.length}}
      </div>
    </div>


    <div class="large-panel">
      <div class="header">
        <a-icon type="right" style="cursor: pointer; font-size: 14px;" @click="visible = false"/>
        <div class="title">
          <span v-if="submittingItem">{{submittingItem.title}}</span>
        </div>
        <div class="progress">
          {{submittingIndex >= 0 ? submittingIndex + 1 : 'N'}}
          /
          {{dataList.length}}
        </div>
      </div>
      <div class="body">
        <a-table
            size="middle"
            rowKey="id"
            :columns="dataColumns"
            :data-source="dataList"
            :pagination="false"
        >
          <div slot="buttons" slot-scope="record">
            <a-button size="small"
                      type="link"
                      :disabled="record.submiting || record.submit"
                      @click="handleRemove(record)">移除</a-button>
          </div>

          <div slot="status" slot-scope="record">
            <span v-if="record.submitMessage" class="text-danger font-mini">失败：{{record.submitMessage}}</span>
            <span v-else>
              <a-icon v-if="record.submiting" type="loading" style="font-size: 18px; color: #4586d4;" />
              <span v-else-if="record.submit" class="text-success font-mini">已提交</span>
              <span v-else class="text-danger font-mini">未提交</span>
            </span>
          </div>

          <div slot="productTitle" slot-scope="record">
            {{record.title}}
          </div>


          <div slot="cover" slot-scope="record">
            <div class="image-item small"
                 :style="{'background-image': `url(${record.product.images[0]})`}"
                 @click="$imageModal.open(record.product.images[0])"
            ></div>
          </div>
          <div slot="price" slot-scope="record">{{record.product.basePrice}}</div>
        </a-table>
      </div>
      <div class="footer">
        <a-dropdown size="small" :disabled="dataList.length === 0 || !!submittingItem" style="margin-right: 10px;">
          <a-menu slot="overlay" @click="handleSubmitProduct">
            <a-menu-item v-for="s in shops" :key="s.id">{{s.name}}</a-menu-item>
          </a-menu>
          <a-button type="primary" style="margin-left: 8px"> 提交产品 <a-icon type="down" /> </a-button>
        </a-dropdown>

        <a-tooltip placement="top">
          <template slot="title">下一个将不会继续提交，当前正在提交的不会停止。</template>
          <a-button type="danger" @click="submitContinue = false" :disabled="!submitContinue">停止提交</a-button>
        </a-tooltip>

        <a-button :disabled="!!submittingItem" @click="handleClearList" style="margin-left: 10px;">清空已提交</a-button>
      </div>
    </div>

  </div>
</template>

<script>
import { listShopOptions } from '@/http/api/shop'
import { submitToStore } from '@/http/api/store-local-product'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      dataList: [],
      dataColumns: [
        { title: '', width: 40, scopedSlots: { customRender: 'buttons' } },
        { title: '状态', width: 250, align: 'center', scopedSlots: { customRender: 'status' } },
        { title: '图片', width: 60, scopedSlots: { customRender: 'cover' } },
        { title: '标题', scopedSlots: { customRender: 'productTitle' } },
        { title: '价格', width: 100, scopedSlots: { customRender: 'price' } }
      ],
      visible: false,
      /**
       * 提交完一个产品后，是否继续提交下一个
       */
      submitContinue: false,
      shops: []
    }
  },
  computed: {
    submittingIndex () {
      return this.dataList.findIndex(item => item.submiting)
    },
    submittingItem () {
      return this.dataList.find(item => item.submiting)
    },
    submittedIdList () {
      const arr = []
      for (const item of this.dataList) {
        if (item.submit) {
          arr.push(item.id)
        }
      }
      return arr
    }
  },
  methods: {
    addToSubmit (list) {
      for (const item of list) {
        if (!this.dataList.includes(item)) {
          this.dataList.push(item)
        }
      }
      this.visible = true
    },
    handleSubmitProduct (e) {
      if (this.dataList.length === 0) {
        return this.$message.warning('请选择要提交的产品。')
      }
      const shopId = e.key
      const shop = this.shops.find(item => item.id === shopId)
      this.$confirm({
        title: '确认',
        content: `确定要提交 ${this.dataList.length} 个产品到 ${shop.name} 吗？`,
        onOk: () => {
          for (const item of this.dataList) {
            item.submit = false
          }
          this.submitContinue = true
          this.submitProductToStore(this.dataList[0], shopId)
          this.$info({ title: '提示', content: '已经开始提交，请不要离开当前页面直至提交全部完成。' })
        }
      })
    },
    submitProductToStore (item, shopId) {
      if (item == null) {
        return this.$message.success('所有产品已经成功提交到平台。')
      }
      const getNextItem = currentItem => {
        const nextIndex = this.dataList.findIndex(i => i === currentItem) + 1
        return nextIndex >= this.dataList.length ? null : this.dataList[nextIndex]
      }
      const data = item
      if (data.submit) {
        return this.submitProductToStore(getNextItem(item), shopId)
      }
      data.submiting = true
      data.submitMessage = null
      submitToStore()
        .complete(() => {
          data.submiting = false
          if (this.submitContinue) {
            this.submitProductToStore(getNextItem(item), shopId)
          }
        })
        .success(() => {
          data.submit = true
        })
        .error(resp => {
          data.submitMessage = resp.message
        })
        .send(data.id, shopId)
    },
    handleRemove (data) {
      kit.arr.removeItem(this.dataList, data)
      this.$emit('remove', data)
    },
    handleClearList () {
      const idList = [...this.submittedIdList]
      for (const id of idList) {
        kit.arr.remove(this.dataList, { id: id })
      }
    }
  },
  mounted () {
    listShopOptions().success(resp => (this.shops = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>
@border: solid 1px #eee;
.container {
  position: fixed;
  right: 0;
  top: 200px;
  z-index: 100;
  border: @border;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  background-color: #fff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.small-panel {
  display: flex;
  align-items: center;
  width: 100px;
  height: 60px;
  .visible-icon {
    padding-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .content {
    flex: 1;
    text-align: center;
  }
}

.large-panel {
  display: none;
  flex-direction: column;
  width: 800px;
  height: 400px;
  .body {
    flex: 1;
    overflow-y: auto;
  }
  .header {
    display: flex;
    padding: 10px;
    align-items: center;
    border-bottom: @border;
    .title {
      padding: 0 10px;
      flex: 1;
    }
  }
  .footer {
    padding: 10px 0;
    text-align: center;
  }
}


.container.visible {
  border-radius: 5px;
  .large-panel {
    display: flex;
  }
  .small-panel {
    display: none;
  }
}
</style>
