<template>
  <app-page :route-name="routeName" :page-loading="pageLoading">

    <a-button @click="handleEditorOpen()" slot="toolbar">添加</a-button>

    <div slot="center" class="cc-container">
      <ul class="list-group company-list">
        <li class="list-group-item hover-item"
            :class="{'active': com.id === selectedCompany}"
            v-for="com in companyOptions"
            :key="com.id"
            @click="selectedCompany = com.id"
        >
          {{com.name}}
          <a-badge v-if="dataListOfCompany[com.id] && dataListOfCompany[com.id].length > 0" :count="(dataListOfCompany[com.id] || []).length" />
        </li>
      </ul>

      <ul class="list-group shipping-methods">
        <li class="list-group-item hover-item"
            :class="{'active': item.id === selectedShipId}"
            v-for="item in (dataListOfCompany[selectedCompany] || [])"
            :key="item.id"
            @click="selectedShipId = item.id"
        >
          {{item.shipName}} /
          <span v-if="item.countryCode">{{countryMap[item.countryCode]}}</span>
          <span class="text-danger" v-else>全部国家</span>
          <div class="font-mini text-muted">
            （
            <span>重量：{{item.weightMin}} &lt; w ≤ {{item.weightMax}} kg</span>
            <span style="margin-left: 20px;">体积：{{item.sizeLength}} x {{item.sizeWidth}} x {{item.sizeHeight}} cm</span>
            ）
          </div>
        </li>
      </ul>

      <div class="value-list" v-show="selectedShipId">
        <a-form-model :model="selectedShipForm" :label-col="{span:6}" :wrapper-col="{span:18}">
          <a-form-model-item label="最小重量（kg）">
            <a-input-number v-model="selectedShipForm.weightMin" :min="0" :precision="2" :step="0.1" />
            <span class="text-muted font-mini" style="margin-left: 10px;">包裹重量需大于此值。</span>
          </a-form-model-item>
          <a-form-model-item label="最大重量（kg）">
            <a-input-number v-model="selectedShipForm.weightMax" :min="0" :precision="2" :step="0.1" />
            <span class="text-muted font-mini" style="margin-left: 10px;">包裹重量需小于等于此值，零表示不限制。</span>
          </a-form-model-item>
          <a-form-model-item label="长（cm）">
            <a-input-number v-model="selectedShipForm.sizeLength" :min="0" />
            <span class="text-muted font-mini" style="margin-left: 10px;">包裹长度需小于等于此值。</span>
          </a-form-model-item>
          <a-form-model-item label="宽（cm）">
            <a-input-number v-model="selectedShipForm.sizeWidth" :min="0" />
            <span class="text-muted font-mini" style="margin-left: 10px;">包裹宽度需小于等于此值。</span>
          </a-form-model-item>
          <a-form-model-item label="高（cm）">
            <a-input-number v-model="selectedShipForm.sizeHeight" :min="0" />
            <span class="text-muted font-mini" style="margin-left: 10px;">包裹高度需小于等于此值。</span>
          </a-form-model-item>
          <a-form-model-item style="text-align: center;">
            <a-button type="primary" @click="handleUpdateItem" :laoding="pageLoading">保存</a-button>
            <a-button type="danger" @click="handleRemoveItem">删除</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>

    <modal ref="editor" title="编辑器" :loading="editor.loading" @ok="handleEditorConfirm" @closed="handleEditorClosed">
      <a-form-model :model="editor.form" :label-col="{span:6}" :wrapper-col="{span:18}">
        <a-form-model-item label="物流" class="app_required-input">
          <a-select v-model="editor.form.company" style="width: 100%;">
            <a-select-option v-for="com in companyOptions" :key="com.id">{{com.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="渠道" class="app_required-input">
          <a-select v-model="editor.form.shipCode"
                    show-search
                    option-filter-prop="children"
                    :filter-option="shippingMethodOptionFilter"
                    style="width: 100%;"
          >
            <a-select-option v-for="sm in (shippingMethodsOfCompany[editor.form.company] || [])" :key="sm.id">{{sm.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="国家">
          <a-select v-model="editor.form.countryCode"
                    :allow-clear="true"
                    show-search
                    option-filter-prop="children"
                    :filter-option="countryOptionFilter"
                    style="width: 100%;"
          >
            <a-select-option v-for="c in countryOptions" :key="c.code">{{c.name}}</a-select-option>
          </a-select>
          <p class="font-mini text-muted" style="line-height: normal; padding: 0; margin: 0;">不选择国家表示任何国家都适用。</p>
        </a-form-model-item>
        <a-form-model-item label="最小重量（kg）">
          <a-input-number v-model="editor.form.weightMin" :min="0" :precision="2" :step="0.1"/>
          <span class="text-muted font-mini" style="margin-left: 10px;">包裹重量需大于此值。</span>
        </a-form-model-item>
        <a-form-model-item label="最大重量（kg）">
          <a-input-number v-model="editor.form.weightMax" :min="0" :precision="2" :step="0.1" />
          <span class="text-muted font-mini" style="margin-left: 10px;">包裹重量需小于等于此值，零表示不限制。</span>
        </a-form-model-item>
        <a-form-model-item label="长（cm）">
          <a-input-number v-model="editor.form.sizeLength" :min="0" />
          <span class="text-muted font-mini" style="margin-left: 10px;">包裹长度需小于等于此值。</span>
        </a-form-model-item>
        <a-form-model-item label="宽（cm）">
          <a-input-number v-model="editor.form.sizeWidth" :min="0" />
          <span class="text-muted font-mini" style="margin-left: 10px;">包裹宽度需小于等于此值。</span>
        </a-form-model-item>
        <a-form-model-item label="高（cm）">
          <a-input-number v-model="editor.form.sizeHeight" :min="0" />
          <span class="text-muted font-mini" style="margin-left: 10px;">包裹高度需小于等于此值。</span>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </app-page>
</template>

<script>
import { ROUTE_SHIPPING_METHOD_ADVICE } from '@/router/router-constants'
import { LOGISTICS_COMPANY_LIST } from '@/constants/logistics'
import { getShippingMethods, getCountries } from '@/http/api/order-delivery'
import { addOrUpdate, listAll, deleteById } from '@/http/api/shipping-method-advice'
import kit from '@/utils/kit'

export default {
  data () {
    const shippingMethodsOfCompany = {}
    LOGISTICS_COMPANY_LIST.forEach(item => (shippingMethodsOfCompany[item.id] = []))
    return {
      routeName: ROUTE_SHIPPING_METHOD_ADVICE,
      pageLoading: false,
      companyOptions: LOGISTICS_COMPANY_LIST,
      shippingMethodsOfCompany: shippingMethodsOfCompany,
      countryOptions: [],
      editor: {
        loading: false,
        form: {
          company: null,
          shipCode: null,
          countryCode: null,
          weightMin: 0,
          weightMax: 0,
          sizeLength: 0,
          sizeWidth: 0,
          sizeHeight: 0
        }
      },
      dataList: [],
      selectedCompany: null,
      selectedShipId: null,
      selectedShipForm: {
        weightMin: 0,
        weightMax: 0,
        sizeLength: 0,
        sizeWidth: 0,
        sizeHeight: 0
      }
    }
  },
  computed: {
    dataListOfCompany () {
      const map = {}
      for (const item of this.dataList) {
        let arr = map[item.company]
        if (!arr) {
          arr = []
          map[item.company] = arr
        }
        arr.push(item)
      }
      for (const key of Object.keys(map)) {
        map[key].sort((a, b) => (a.shipCode.localeCompare(b.shipCode)))
      }
      return map
    },
    countryMap () {
      const map = {}
      this.countryOptions.forEach(item => (map[item.code] = item.name))
      return map
    },
    selectedItem () {
      return this.dataList.find(item => item.id === this.selectedShipId)
    }
  },
  watch: {
    selectedCompany () {
      this.selectedShipId = null
    },
    selectedShipId () {
      if (this.selectedItem) {
        this.selectedShipForm.weightMin = this.selectedItem.weightMin
        this.selectedShipForm.weightMax = this.selectedItem.weightMax
        this.selectedShipForm.sizeLength = this.selectedItem.sizeLength
        this.selectedShipForm.sizeWidth = this.selectedItem.sizeWidth
        this.selectedShipForm.sizeHeight = this.selectedItem.sizeHeight
      } else {
        this.selectedShipForm.weightMin = 0
        this.selectedShipForm.weightMax = 0
        this.selectedShipForm.sizeLength = 0
        this.selectedShipForm.sizeWidth = 0
        this.selectedShipForm.sizeHeight = 0
      }
    }
  },
  methods: {
    loadData () {
      listAll().success(resp => (this.dataList = resp.data)).send()
    },
    loadShippingMethodsByCompany (company) {
      if (this.shippingMethodsOfCompany[company].length === 0) {
        getShippingMethods()
          .success(resp => {
            this.shippingMethodsOfCompany[company] = resp.data
          })
          .send(company)
      }
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    shippingMethodOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    addOrUpdate (data, successCallback) {
      this.pageLoading = true
      addOrUpdate()
        .complete(() => (this.pageLoading = false))
        .success(resp => {
          this.$message.success('保存成功。')
          successCallback && successCallback(resp.data)
          this.loadData()
        })
        .send(data)
    },
    handleEditorOpen () {
      this.$refs.editor.open()
      if (!this.editor.form.company) {
        this.editor.form.company = this.companyOptions[0].id
        this.loadShippingMethodsByCompany(this.editor.form.company)
      }
    },
    handleEditorConfirm () {
      const data = Object.assign({}, this.editor.form)
      data.shipName = this.shippingMethodsOfCompany[data.company].find(item => (item.id === data.shipCode)).name
      this.addOrUpdate(data, () => {
        this.$refs.editor.close()
      })
    },
    handleEditorClosed () {
      this.editor.form.countryCode = null
      this.editor.form.weightMin = 0
      this.editor.form.weightMax = 0
      this.editor.form.sizeLength = 0
      this.editor.form.sizeWidth = 0
      this.editor.form.sizeHeight = 0
    },
    handleUpdateItem () {
      let data = Object.assign({}, this.selectedItem)
      data = Object.assign(data, this.selectedShipForm)
      this.addOrUpdate(data, () => {
        Object.assign(this.selectedItem, this.selectedShipForm)
      })
    },
    handleRemoveItem () {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          this.pageLoading = true
          deleteById()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              kit.arr.removeItem(this.dataList, this.selectedItem)
              this.selectedShipId = null
            })
            .send(this.selectedShipId)
        }
      })
    }
  },
  mounted () {
    getCountries().success(resp => (this.countryOptions = resp.data)).send()
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.cc-container {
  display: flex;
}

.company-list {
  width: 250px;
  .list-group-item{
  }
}

.shipping-methods {
  width: 400px;
  margin: 0 30px;
}

.value-list {
  flex: 1;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding-top: 20px;
}
</style>
