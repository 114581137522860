<template>
  <transition name="slideUp" :duration="100">
    <div class="comm-editor card-panel" v-show="visible">
      <div class="editor-head header">{{title}}</div>
      <div class="editor-body body">
        <slot></slot>
      </div>
      <div class="editor-foot">
        <a-button type="primary" @click="$emit('submit')" :loading="loading">提交</a-button>
        <a-button @click="$emit('update:visible', false)" :loading="loading">取消</a-button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  }
}
</script>

<style lang="less" scoped>
.comm-editor {
  position: absolute;
  background-color: #fff;
  top: 60px;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 9999;
  border-radius: 4px;
  border: solid 1px #ddd;
  box-shadow: 0 0 6px rgba(0,0,0,.3);

  .editor-body {
    padding: 10px 20px;
  }

  .editor-foot {
    border-top: solid 1px #eee;
    padding: 10px;
    text-align: center;
  }
}
</style>
