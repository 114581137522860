<template>
    <a-form-model v-if="order" :model="order" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-model-item label="申报名称(中文)" prop="parcel.cname" class="app_required-input">
        <a-input v-model="order.parcel.cname" />
        <p>
          <a-tag color="blue"
                 style="cursor: pointer;"
                 @click="order.parcel.cname = parcelInfo[k].nameCn"
                 v-for="k in Object.keys(parcelInfo)"
                 :key="k">{{parcelInfo[k].nameCn}}</a-tag>
        </p>
      </a-form-model-item>
      <a-form-model-item label="申报名称(英文)" prop="parcel.name" class="app_required-input">
        <a-input v-model="order.parcel.name" />
      </a-form-model-item>
      <a-form-model-item label="材料(中文)" prop="parcel.materialCn" class="app_required-input">
        <a-input v-model="order.parcel.materialCn" />
      </a-form-model-item>
      <a-form-model-item label="材料(英文)" prop="parcel.materialEn" class="app_required-input">
        <a-input v-model="order.parcel.materialEn" />
      </a-form-model-item>
      <a-form-model-item label="用途(中文)" prop="parcel.usageCn" class="app_required-input">
        <a-input v-model="order.parcel.usageCn" />
      </a-form-model-item>
      <a-form-model-item label="用途(英文)" prop="parcel.usageEn" class="app_required-input">
        <a-input v-model="order.parcel.usageEn" />
      </a-form-model-item>
      <a-form-model-item label="数量" prop="parcel.total" class="app_required-input">
        <a-input-number v-model="order.parcel.total" :min="1" />
      </a-form-model-item>
      <a-form-model-item label="申报单价" prop="parcel.price" class="app_required-input">
        <a-input-number v-model="order.parcel.price" />
        <div class="font-mini text-muted">欧盟发货，请正确填写申报单价。</div>
      </a-form-model-item>
      <a-form-model-item label="货币" prop="parcel.currency" class="app_required-input">
        <a-input v-model="order.parcel.currency" />
      </a-form-model-item>
      <a-form-model-item label="海关编码" prop="parcel.hsCode" class="app_required-input">
        <a-input v-model="order.parcel.hsCode" />
        <div class="font-mini text-muted">
          海关编码可以在<a href="https://www.hsbianma.com/Code/6208291010.html" target="_blank">这里</a>找
        </div>
      </a-form-model-item>
      <a-form-model-item v-show="showButton">
        <a-button @click="$emit('parcelChanged', order)">将当前数据应用到其他订单</a-button>
      </a-form-model-item>
    </a-form-model>
</template>

<script>

export default {
  props: {
    order: { type: Object },
    showButton: { type: Boolean, default: false }
  },
  data () {
    return {
      parcelInfo: {
        hair: {
          nameCn: '卷发棒',
          nameEn: 'Hair Rollers',
          materialCn: '塑料',
          materialEn: 'plastic',
          hsCode: '3924900000'
        },
        pajamas: {
          nameCn: '睡衣',
          nameEn: 'Pajamas',
          materialCn: '丝绸',
          materialEn: 'Silk',
          hsCode: '6208291010'
        },
        eyeMask: {
          nameCn: '眼罩',
          nameEn: 'EyeMask',
          materialCn: '丝绸',
          materialEn: 'Silk',
          hsCode: '6208291010'
        },
        bed: {
          nameCn: '床上用品',
          nameEn: 'Bed 4PCS',
          materialCn: '丝绸',
          materialEn: 'Silk',
          hsCode: '6208291010'
        },
        menShirt: {
          nameCn: '男士卫衣',
          nameEn: 'Men\'s Sweatshirt',
          materialCn: '棉',
          materialEn: 'Cotton',
          hsCode: '6110200090'
        }
      }
    }
  },
  computed: {
  },
  watch: {
    'order.parcel.cname' (name) {
      for (const key of Object.keys(this.parcelInfo)) {
        const item = this.parcelInfo[key]
        if (name === item.nameCn) {
          this.order.parcel.name = item.nameEn
          this.order.parcel.hsCode = item.hsCode
          this.order.parcel.materialCn = item.materialCn
          this.order.parcel.materialEn = item.materialEn
          break
        }
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
