<template>
  <modal ref="pr" :title="title" :width="500" @closed="onClosed" :hide-buttons="true">
    <div v-if="order" style="max-height: 400px; overflow-y: auto;">
      <multi-delivery-address-content :order="order"></multi-delivery-address-content>
    </div>
  </modal>
</template>

<script>
import MultiDeliveryAddressContent from '@/pages/delivery/comp/multi-delivery-address-content'

export default {
  components: { MultiDeliveryAddressContent },
  data () {
    return {
      order: null
    }
  },
  computed: {
    title () {
      return this.order ? '客户地址 - ' + this.order.orderNo : '客户地址'
    }
  },
  watch: {
  },
  methods: {
    open (order) {
      this.order = order
      this.$refs.pr.open()
    },
    onClosed () {
      this.order = null
    }
  }
}
</script>

<style lang="less" scoped>
</style>
