import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class Paragraph extends Section {
  constructor () {
    super('paragraph', '段落')

    this.title = null
    this.text = null
    this.lineHeight = 2
  }

  getHeaderDisplay () {
    if (this.title) {
      return this.title
    } else if (this.text) {
      return this.text.replace(/(<([^>]+)>)/ig, '')
    } else {
      return null
    }
  }
}
