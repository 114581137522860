import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class MessageBar extends Section {
  constructor () {
    super('promotion', '促销方块')

    // {title, description, code}
    this.promotions = []
    this.textColor = '#000000'
    this.codeColor = '#c12a2a'
    this.useSplitBorder = true
    this.splitBorderColor = '#f2f2f2'
  }

  getHeaderDisplay () {
    if (this.promotions.length > 0) {
      return this.promotions[0].title
    } else {
      return null
    }
  }
}
