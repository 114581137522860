/**
 * @author jiangxingshang
 * @date 2022/12/7
 */

import defineApi from '../define-api'
import http from '../application-api'


export const listNotifyUser = defineApi((config, query) => {
  config.params = query
  http.get('/notify_user/list', config)
})

export const addNotifyUser = defineApi((config, userIdArray, type) => {
  config.params = {
    userIdList: userIdArray.join(','),
    type: type
  }
  http.post('/notify_user/add', config)
})

export const deleteNotifyUser = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.post('/notify_user/delete', config)
})

