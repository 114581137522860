/**
 * SKU生成规则的特殊分组
 * @author jiangxingshang
 * @date 2022/10/11
 */
export const SKU_GROUP_NAME_SIZE = 'Size'
export const SKU_GROUP_NAME_MOMME = 'Momme'
export const SKU_GROUP_NAME_COLOR = 'Color'
export const SKU_GROUP_NAME_TYPE = 'Type'
export const SKU_GROUP_NAME_SUB_TYPE = 'SubType'
export const SKU_GROUP_NAME_SEX = 'Sex'
