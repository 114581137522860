import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class SingleProduct extends Section {
  constructor () {
    super('singleProduct', '单个产品')

    this.productUrl = null
    this.imageUrl = null
    this.title = null
    this.description = null
    this.buttonText = 'Shop Now'
    this.buttonBgColor = '#000000FF'
    this.buttonTextColor = '#ffffff'
    this.buttonBorderColor = '#000000'
    this.reverse = false
  }

  getHeaderDisplay () {
    return this.title
  }
}
