<template>
  <v-chart ref='chart' :option='option' :update-options="{ notMerge: true }"/>
</template>

<script>
/*
  https://echarts.apache.org/examples/zh/editor.html?c=line-gradient
 */
import VChart from 'vue-echarts'
import * as echarts from 'echarts/core'
import { GridComponent, TooltipComponent, VisualMapComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent, VisualMapComponent])

export default {
  props: {
    title: { type: String },
    /*
      // 多线条数据结构
      data = [
        {
          category: '线条1',
          values: [
            { timePoint: '2022-01-01', value: 12 },
            { timePoint: '2022-01-02', value: 12 },
            { timePoint: '2022-01-03', value: 12 }
          ]
        },
        {
          category: '线条2',
          values: [
            { timePoint: '2022-01-01', value: 22 },
            { timePoint: '2022-01-02', value: 22 },
            { timePoint: '2022-01-03', value: 22 }
          ]
        }
      ]

      // 单线条数据结构
      data = [
        { timePoint: '2022-01-01', value: 12 },
        { timePoint: '2022-01-02', value: 12 },
        { timePoint: '2022-01-03', value: 12 }
      ]
     */
    data: { type: Array }
  },
  components: { VChart },
  provide: {
  },
  data () {
    return {
      option: {
        legend: {
          data: []
        },
        title: [
          {
            left: 'center',
            text: this.title
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: { type: 'value' },
        series: [
          {
            type: 'line',
            data: []
          }
        ]
      }
    }
  },
  watch: {
    loading (f) {
    },
    data () {
      if (this.data.length > 0 && this.data[0].category !== undefined) {
        // 多线条
        const dateList = this.data[0].values.map(item => item.timePoint)
        this.option.xAxis.data = [...dateList]

        const series = []
        const legends = []
        for (const d of this.data) {
          legends.push(d.category)
          series.push({
            name: d.category,
            type: 'line',
            data: d.values.map(item => item.value)
          })
        }
        this.option.series = series
        this.option.legend.data = legends
      } else {
        // 单线条
        const dateList = this.data.map(function (item) {
          return item.timePoint
        })
        const valueList = this.data.map(function (item) {
          return item.value
        })
        this.option.xAxis.data = [...dateList]
        this.option.series[0].data = [...valueList]
      }
      this.$refs.chart.setOption(this.option)
    },
    title (title) {
      this.option.title[0].text = title
    }
  },
  methods: {
    resize (size) {
      this.$refs.chart.resize(size)
    }
  }
}
</script>

<style scoped>
</style>
