<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="记账编辑器"
    @save="onSave"
    @close="onClose"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="收支" prop="type">
        <a-button-group>
          <a-button :type="form.type === 0 ? 'primary' : ''" @click="form.type = 0">支出</a-button>
          <a-button :type="form.type === 1 ? 'primary' : ''" @click="form.type = 1">收入</a-button>
        </a-button-group>
      </a-form-model-item>
      <a-form-model-item label="类别" class="app_required-input" prop="category">
        <a-input v-model="form.category"/>
        <div v-show="form.type === 0">
          <a-tag color="purple" v-for="c in outCategoryOptions" :key="c" style="cursor: pointer;" @click="form.category = c">{{c}}</a-tag>
        </div>
        <div v-show="form.type === 1">
          <a-tag color="blue" v-for="c in inCategoryOptions" :key="c" style="cursor: pointer;" @click="form.category = c">{{c}}</a-tag>
        </div>
      </a-form-model-item>

      <a-form-model-item label="日期" prop="createDate">
        <a-date-picker v-model="form.createDate" value-format="yyyy-MM-DD" style="width: 100%;" />
      </a-form-model-item>
      <a-form-model-item label="摘要" prop="remark">
        <a-input v-model="form.remark" />
      </a-form-model-item>
      <a-form-model-item label="金额" prop="amount" class="app_required-input">
        <a-input-number v-model="form.amount" :min="0" :precision="2" style="width: 100%;" />
      </a-form-model-item>
      <a-form-model-item label="需要报销" prop="reimbursement">
        <a-switch v-model="form.reimbursement" />
      </a-form-model-item>
      <a-form-model-item label="附件图片" prop="filePath">
        <div class="image-container" @click="handleSelectFile" :style="{'background-image': `url(${form.imageUrl})`}">
          <span v-show="!form.imageUrl">选择图片</span>
          <span class="clear-image" v-show="form.imageUrl" @click.stop.prevent="form.imageUrl = null; form.filePath = null;">
            <a-icon type="close" />
          </span>
          <span class="show-image" v-show="form.imageUrl" @click.stop.prevent="$imageModal.open(form.imageUrl)">
            <a-icon type="fullscreen" />
          </span>
          <loading :loading="uploading" text="正在上传"></loading>
        </div>
      </a-form-model-item>
    </a-form-model>

  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import { add, update, upload } from '@/http/api/accounting'
import selectFiles from '@/utils/select-file'
import moment from 'moment'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: {
        category: null,
        remark: null,
        amount: 0,
        createDate: null,
        type: 0,
        filePath: null,
        imageUrl: null,
        reimbursement: false
      },
      // 支出类别
      outCategoryOptions: [
        '货款', '广告费', '物流费', '办公用品', '物业房租', '信用卡还款',
        'Shopify月租', '平面设计', '其他'
      ],
      // 收入类别
      inCategoryOptions: ['提款', '转账', '其他'],
      uploading: false
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.model
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        this.$nextTick(() => {
          kit.obj.getProperties(this.form, model)
        })
      } else {
        this.form.createDate = moment().format('yyyy-MM-DD')
      }
    },
    onClose () {
      this.$refs.form.resetFields()
      this.form.imageUrl = null
      this.model = null
    },
    onSave () {
      const data = Object.assign({
        id: this.isUpdate ? this.model.id : null
      }, this.form)
      delete data.imageUrl
      this.saveButtonLoading = true
      this.closable = false
      const api = this.isUpdate ? update : add
      api()
        .complete(() => {
          this.saveButtonLoading = false
          this.closable = true
        })
        .success(resp => {
          this.$emit('saved', resp.data, this.isUpdate)
          this.$nextTick(() => {
            this.visible = false
          })
        })
        .send(data)
    },
    handleSelectFile () {
      selectFiles({
        accept: ['image/jpg', 'image/png'],
        select: files => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            this.form.imageUrl = reader.result
          }, false)
          reader.readAsDataURL(files[0])
          this.uploading = true
          upload()
            .complete(() => (this.uploading = false))
            .success(resp => {
              this.form.filePath = resp.data
            })
            .send(files[0])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .image-container {
    position: relative;
    height: 200px;
    line-height: 200px;
    border: solid 1px #ddd;
    color: #ddd;
    cursor: pointer;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .clear-image, .show-image {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      background-color: #bb3e30;
      border-radius: 100%;
      cursor: pointer;
    }
    .show-image {
      right: 20px;
      background-color: #3d71a7;
    }
  }
</style>
