<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">
    <div slot="toolbar">
      <a-dropdown>
        <a-menu slot="overlay" @click="handleAddNotifyUser">
          <a-menu-item key="stock-alert">库存预警</a-menu-item>
          <a-menu-item key="order">订单</a-menu-item>
          <a-menu-item key="supplier-order">供应商订单</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 添加用户通知 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <a-button type="danger" @click="handleRemoveNotifyUser" :disabled="selectedRowKeys.length === 0">移除通知用户</a-button>
    </div>
    <div slot="top">
      <a-form-model layout="inline">
        <a-form-model-item label="状态">
          <a-select v-model="searchForm.status" :allowClear="true" style="width: 100px;">
            <a-select-option :key="1">可用</a-select-option>
            <a-select-option :key="0">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="searchForm.userType" :allowClear="true" style="width: 100px;">
            <a-select-option key="admin">管理员</a-select-option>
            <a-select-option key="supplier">供应商</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="通知类型">
          <a-select v-model="searchForm.type" :allowClear="true" style="width: 100px;">
            <a-select-option key="stock-alert">库存预警</a-select-option>
            <a-select-option key="order">订单</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="供应商">
          <a-select v-model="searchForm.supplierId" style="width: 140px;" :allow-clear="true">
            <a-select-option v-for="opt in supplierOptions" :key="opt.id" :value="opt.id">{{opt.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loadData">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onRowSelectChanged }"
    >
      <div slot="type" slot-scope="type">
        <span v-if="type === 'stock-alert'">库存预警</span>
        <span v-else-if="type === 'order'">订单</span>
        <span v-else-if="type === 'supplier-order'">供应商订单</span>
      </div>
      <div slot="userType" slot-scope="type">{{userTypeMapping[type]}}</div>
      <status slot="status" slot-scope="status" :status="status"></status>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <user-select ref="userSelect" @selected="handleUserSelected"></user-select>

  </app-page>
</template>

<script>
import { ROUTE_SETTING_NOTIFY_USER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { listSupplier } from '@/http/api/supplier'
import { USER_TYPE_MAPPING } from '@/constants/user-type'
import kit from '@/utils/kit'
import { listNotifyUser, addNotifyUser, deleteNotifyUser } from '@/http/api/notify-user'
import UserSelect from '@/components-business/user-select'

export default {
  components: { UserSelect },
  data () {
    return {
      routeName: ROUTE_SETTING_NOTIFY_USER,
      pageLoading: false,
      dataColumns: [
        { title: '姓名', dataIndex: 'name', width: 150 },
        { title: '通知类型', dataIndex: 'type', scopedSlots: { customRender: 'type' } },
        { title: '用户类型', dataIndex: 'userType', scopedSlots: { customRender: 'userType' } },
        { title: '所属供应商', dataIndex: 'supplierName' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        userType: null,
        status: null,
        type: null,
        supplierId: null
      },
      userTypeMapping: USER_TYPE_MAPPING,
      supplierOptions: [],
      selectedRowKeys: [],
      selectedAddButtonKey: null
    }
  },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  methods: {
    loadData () {
      const p = Object.assign({}, this.searchForm)
      this.dataLoading = true
      listNotifyUser()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    onRowSelectChanged (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleAddNotifyUser (e) {
      this.selectedAddButtonKey = e.key
      this.$refs.userSelect.open()
    },
    handleUserSelected (userIdList) {
      if (userIdList.length === 0) {
        return this.$message.error('请选择用户。')
      }
      this.pageLoading = true
      addNotifyUser()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.$message.success('操作成功。')
        })
        .send(userIdList, this.selectedAddButtonKey)
    },
    handleRemoveNotifyUser () {
      this.pageLoading = true
      deleteNotifyUser()
        .complete(() => (this.pageLoading = false))
        .success(() => {
          this.$message.success('操作成功。')
          this.reloadData()
        })
        .send(this.selectedRowKeys)
    }
  },
  mounted () {
    this.loadData()

    listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

</style>
