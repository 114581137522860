<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>消息</label>
        <span>
          <a-input v-model="section.message" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Model from '../model/message-bar'

export default {
  props: {
    section: { type: Model }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
