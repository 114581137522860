import kit from '@/utils/kit'

export const STATUS_SERVICE = 'service'
export const STATUS_COMPLETED = 'completed'
export const STATUS_ORDER_RETURN = 'orderReturn'
export const STATUS_REFUNDING = 'refunding'
export const STATUS_REFUND = 'refund'
export const STATUS_WAIT_CUSTOMER = 'waitCustomer'


export const STATUS_MAPPING = {
  [STATUS_SERVICE]: '服务中',
  [STATUS_ORDER_RETURN]: '等待客户退货',
  [STATUS_REFUNDING]: '正在退款',
  [STATUS_REFUND]: '已退款',
  [STATUS_WAIT_CUSTOMER]: '等待客户回复',
  [STATUS_COMPLETED]: '已结案'
}

export const STATUS_LIST = kit.obj.toArray(STATUS_MAPPING)



export const PRIORITY_NORMAL = 'normal'
export const PRIORITY_QUICK = 'quick'
export const PRIORITY_IMPORTANT = 'important'

export const PRIORITY_MAPPING = {
  [PRIORITY_NORMAL]: '普通',
  [PRIORITY_QUICK]: '快速',
  [PRIORITY_IMPORTANT]: '紧急'
}

export const PRIORITY_LIST = kit.obj.toArray(PRIORITY_MAPPING)

