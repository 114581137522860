<!-- 佣金配置 -->
<template>
  <div class="fee-config-panel">
    <a-alert message="提示" type="info">
      <div slot="description" style="font-size: 12px;">
        <div class="tip">
          <p>订单的佣金依赖这里的配置进行计算。</p>
          <p>
            <b>支付方式：</b>为每种支付方式配置一个佣金计算方式，如：Shopify Payment，Paypal。如果希望配置一个通用的，支付方式留空白即可。
          </p>
          <div>
            <b>佣金计算方式：</b>可以是一个固定数字，可以是一个数学表达式，参考下面的例子。
            <ul>
              <li><span class="expression">2.5</span>固定佣金。</li>
              <li><span class="expression">0.3 + 10 * 0.2</span>数学表达式。</li>
              <li><span class="expression">0.3 + ORDER_PRICE * 0.2</span>含变量数学表达式，这些变量会在计算前被替换成订单里相应的金额。</li>
            </ul>
            <p><b>可用变量：</b></p>
            <ul>
              <li><span class="var">ORDER_PRICE</span>订单总收入</li>
              <li><span class="var">PAY_PRICE</span>客户支付金额</li>
              <li><span class="var">SHIPPING_PRICE</span>运费</li>
            </ul>
          </div>
        </div>
      </div>
    </a-alert>

    <ul class="list-group" style="margin-top: 20px;">
      <li class="list-group-item">
        <div class="item-wrapper">
          <div class="gateway">支付方式</div>
          <div class="fee">佣金计算方式</div>
          <div class="actions">-</div>
        </div>
      </li>
      <li class="list-group-item" v-for="item in paymentGatewayFees" :key="item.gateway">
        <div class="item-wrapper">
          <div class="gateway">
            <a-input v-model="item.gateway"></a-input>
          </div>
          <div class="fee">
            <a-input v-model="item.fee" style="width: 100%;"></a-input>
          </div>
          <div class="actions">
            <a-button @click="handleOpenTestModal(item)">测试结果</a-button>
            <a-button type="danger" @click="handleRemovePaymentGatewayFee(item)">删除</a-button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="item-wrapper">
          <div class="gateway">
            <a-input v-model="paymentGatewayFeeForm.gateway"></a-input>
          </div>
          <div class="fee">
            <a-input v-model="paymentGatewayFeeForm.fee" style="width: 100%;"></a-input>
          </div>
          <div class="actions">
            <a-button @click="handleAddPaymentGatewayFee">新增</a-button>
          </div>
        </div>
      </li>
    </ul>
    <a-button type="primary" @click="handleSavePaymentGatewayFee" :loading="saveButtonLoading" style="margin: 10px 0;">保存</a-button>

    <modal ref="test" title="测试计算结果" :hide-buttons="true" @closed="onTestModalClosed">
      <a-form-model :label-col="{span: 6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="订单总收入">
          <a-input v-model="testModal.orderPrice"/>
        </a-form-model-item>
        <a-form-model-item label="客户支付金额">
          <a-input v-model="testModal.payPrice"/>
        </a-form-model-item>
        <a-form-model-item label="运费">
          <a-input v-model="testModal.shippingPrice"/>
        </a-form-model-item>
        <a-form-model-item label="计算方式">
          <a-input v-model="testModal.feeExpression"/>
        </a-form-model-item>
        <a-form-model-item label="计算结果">
          <a-button type="link" @click="handleStartEval">开始计算</a-button>
          <span>{{testModal.result}}</span>
        </a-form-model-item>
      </a-form-model>
    </modal>
  </div>
</template>

<script>
import kit from '@/utils/kit'
import { updatePaymentGatewayFee } from '@/http/api/shop'

export default {
  props: {
    shopIdList: { type: Array },
    payFeeConfigJson: { type: String }
  },
  data () {
    return {
      paymentGatewayFeeForm: {
        gateway: null,
        fee: null
      },
      saveButtonLoading: false,
      testModal: {
        orderPrice: 0,
        payPrice: 0,
        shippingPrice: 0,
        feeExpression: null,
        result: null,
        item: null
      }
    }
  },
  computed: {
    paymentGatewayFees () {
      try {
        return JSON.parse(this.payFeeConfigJson)
      } catch (e) {
        return []
      }
    }
  },
  methods: {
    handleAddPaymentGatewayFee () {
      if (this.paymentGatewayFees.find(item => item.gateway === this.paymentGatewayFeeForm.gateway)) {
        return this.$message.error('支付方式已经存在。')
      }
      this.paymentGatewayFees.push({
        gateway: this.paymentGatewayFeeForm.gateway,
        fee: this.paymentGatewayFeeForm.fee
      })
      this.paymentGatewayFeeForm.gateway = null
      this.paymentGatewayFeeForm.fee = null
    },
    handleRemovePaymentGatewayFee (item) {
      kit.arr.removeItem(this.paymentGatewayFees, item)
    },
    handleSavePaymentGatewayFee () {
      if (this.paymentGatewayFees.filter(item => !item.gateway).length > 1) {
        return this.$message.error('请能存在一个留空白的支付方式。')
      }
      this.closable = false
      this.saveButtonLoading = true
      updatePaymentGatewayFee()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(() => {
          this.$message.success('保存成功。')
          this.$emit('saved', JSON.stringify(this.paymentGatewayFees))
        })
        .send(this.shopIdList, this.paymentGatewayFees)
    },
    handleOpenTestModal (row) {
      this.testModal.item = row
      this.testModal.feeExpression = row.fee
      this.$refs.test.open()
    },
    handleStartEval () {
      if (this.testModal.feeExpression) {
        try {
          const exp = this.testModal.feeExpression
            .replace('ORDER_PRICE', this.testModal.orderPrice)
            .replace('PAY_PRICE', this.testModal.payPrice)
            .replace('SHIPPING_PRICE', this.testModal.shippingPrice)
          // eslint-disable-next-line no-eval
          this.testModal.result = eval(exp)
        } catch (e) {
          this.testModal.result = null
          this.$message.error('计算方式不符合规范。')
        }
      } else {
        this.testModal.result = null
        this.$message.error('计算方式不能为空。')
      }
    },
    onTestModalClosed () {
      if (this.testModal.feeExpression) {
        this.testModal.item.fee = this.testModal.feeExpression
      }
      this.testModal.result = null
    }
  }
}
</script>

<style lang="less" scoped>
.fee-config-panel {
  .item-wrapper {
    display: flex;
    .gateway {
      width: 150px;
    }
    .fee {
      flex: 1;
      padding: 0 20px;
    }
    .actions {
      width: 160px;
    }
  }
}

.tip {
  .expression {
    color: #a94442;
    margin-right: 20px;
  }
  .var {
    color: #3d71a7;
    margin-right: 20px;
  }
}
</style>
