import Section from './section'
/**
 * @author jiangxingshang
 * @date 2022/7/19
 */
export default class ImageList extends Section {
  constructor () {
    super('imageList', '图片集')

    // { imageUrl, link }
    this.images = []
    this.rounded = true
    // left, center, right
    this.align = 'center'
    // 单位px
    this.width = 100
    // 单位px
    this.height = 100
    // 图片之间的间隔，单位px
    this.gap = 10
  }

  getContentType () {
    return 'image'
  }

  getHeaderDisplay () {
    const arr = []
    for (const item of this.images) {
      if (item.imageUrl) {
        arr.push(item.imageUrl)
      }
    }
    return arr
  }
}
