<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :disableFooterButton="false"
      :loading="loading"
      :save-button-loading="saveButtonLoading"
      title="营销活动"
      :width="800"
      @close="onClose"
      @save="handleSave"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 2}" :wrapper-col="{span: 22}">
      <a-form-model-item label="标题" prop="title" class="app_required-input">
        <a-input v-model="form.title" />
        <p style="line-height: normal;" class="text-muted font-mini">只有在后台系统可以看到，不是邮件标题。</p>
      </a-form-model-item>
    </a-form-model>

    <web-design ref="design"></web-design>

<!--    <div class="wrapper">-->
<!--      <div class="left-content">-->
<!--        <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">-->
<!--          <a-form-model-item label="标题" prop="title" class="app_required-input">-->
<!--            <a-input v-model="form.title" />-->
<!--            <p style="line-height: normal;" class="text-muted font-mini">只有在后台系统可以看到，不是邮件标题。</p>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="邮件标题" prop="emailTitle" class="app_required-input">-->
<!--            <a-input v-model="form.emailTitle" />-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="From" prop="senderId" class="app_required-input">-->
<!--            <div class="left-right-content">-->
<!--              <div class="full">-->
<!--                <a-select v-model="form.senderId" style="width: 250px;">-->
<!--                  <a-select-option :value="item.id" v-for="item in senderOptions" :key="item.id">-->
<!--                    {{item.name}}（{{item.email}}）-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </div>-->
<!--              <div style="margin: 0 0 0 10px;">-->
<!--                <a-button icon="plus" type="primary" @click="handleSenderEditorOpen(false)"></a-button>-->
<!--                <a-button icon="edit" @click="handleSenderEditorOpen(true)"></a-button>-->
<!--                <a-button icon="delete" type="danger" @click="deleteSender()"></a-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="目标客户" prop="targetType" class="app_required-input">-->
<!--            <a-radio-group v-model="form.targetType">-->
<!--              <a-radio value="random">随机</a-radio>-->
<!--              <a-radio value="group">分组</a-radio>-->
<!--              <a-radio value="emails">指定邮箱</a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="随机目标数量" prop="target.randomTotal" class="app_required-input" v-if="form.targetType === 'random'">-->
<!--            <a-input-number v-model="form.target.randomTotal" :max="20000" :min="1" />-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="排除已发过邮箱" prop="target.randomExcludeSent" class="app_required-input" v-if="form.targetType === 'random'">-->
<!--            <a-switch v-model="form.target.randomExcludeSent" />-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="邮箱组" prop="target.groupId" class="app_required-input" v-if="form.targetType === 'group'">-->
<!--            <a-select v-model="form.target.groupId" style="width: 100%;">-->
<!--              <a-select-option :value="item.id" v-for="item in emailGroupOptions" :key="item.id">-->
<!--                {{item.name}}（{{item.emailTotal}}）-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--            <div class="font-mini text-muted">组内邮箱数量限制2万，超过的部分会被忽略发邮。</div>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="指定邮箱" v-if="form.targetType === 'emails'">-->
<!--            <a-button @click="handleOpenEmailSelector">选择目标客户（{{form.target.emails.length}} / {{maxTargetEmail}}）</a-button>-->
<!--          </a-form-model-item>-->
<!--        </a-form-model>-->

<!--        <ul class="list-group" style="margin-top: 20px;" v-if="form.targetType === 'emails'">-->
<!--          <li class="list-group-item" v-for="e in form.target.emails" :key="e.id">-->
<!--            <div class="left-right-content">-->
<!--              <div class="full">{{e.email}}</div>-->
<!--              <div>-->
<!--                <a-button size="small" type="danger" icon="delete" @click="handleRemoveTargetEmail(e)"></a-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->


<!--      <div class="right-content">-->
<!--        -->
<!--      </div>-->
<!--    </div>-->


    <a-tooltip slot="extra-button" placement="top">
      <template slot="title">
        <span>先保存再预览</span>
      </template>
      <a-button @click="previewEmail" :disabled="!model">预览邮件</a-button>
    </a-tooltip>
    <a-button slot="extra-button" @click="handleGetEmailBody" :disabled="!model">生成邮件代码</a-button>


    <modal ref="editor" title="邮箱发送端" @ok="handleSenderSave" @closed="handleSenderClose" :loading="senderEditor.loading">
      <a-form-model ref="senderForm" :model="senderEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="名称" prop="name" class="app_required-input">
          <a-input v-model="senderEditor.form.name" />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email" class="app_required-input">
          <a-input v-model="senderEditor.form.email" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password" class="app_required-input">
          <a-input v-model="senderEditor.form.password" />
        </a-form-model-item>
        <a-form-model-item label="主机" prop="host" class="app_required-input">
          <a-input v-model="senderEditor.form.host" />
        </a-form-model-item>
        <a-form-model-item label="端口" prop="port" class="app_required-input">
          <a-input-number v-model="senderEditor.form.port" />
        </a-form-model-item>
      </a-form-model>
    </modal>

    <modal ref="emailBodyCode" ok-text="复制" cancel-text="关闭" @ok="handleCopyEmailBody">
      <a-textarea v-model="emailBody" :auto-size="{ minRows: 3, maxRows: 8 }" />
    </modal>

    <email-select ref="emailSelect" @selected="handleEmailSelected"></email-select>

  </drawer>
</template>

<script>
import { listSender, addSender, updateSender, deleteSender } from '@/http/api/edm-sender'
import { listAll } from '@/http/api/edm-email-group'
import { addActivity, updateActivity, startActivity, getEmailBody } from '@/http/api/edm-activity'
import kit from '@/utils/kit'
import EmailSelect from './email-select'
import WebDesign from '@/web-design'

function createFormData () {
  return {
    title: null,
    senderId: null,
    emailTitle: null,
    targetType: 'random',
    target: {
      randomTotal: 10000, // 随机取多少个目标邮箱
      randomExcludeSent: true, // 排除已经发过邮件的邮箱
      groupId: null, // targetType == group，邮箱分组ID
      emails: [] // targetType == emails，指定邮箱：{ id, email }
    }
  }
}
export default {
  components: { EmailSelect, WebDesign },
  data () {
    return {
      visible: false,
      closable: true,
      loading: false,
      saveButtonLoading: false,
      model: null,
      form: createFormData(),
      maxTargetEmail: 100, // targetType == emails时，最多能选择多少个邮箱
      senderEditor: {
        loading: false,
        editingItem: null,
        form: {
          name: null,
          email: null,
          password: null,
          host: null,
          port: 0
        }
      },
      senderOptions: [],
      emailGroupOptions: [],
      emailBody: null
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model

      this.$nextTick(() => {
        if (model) {
          this.form.title = model.title
          this.form.emailTitle = model.emailTitle
          this.form.targetType = model.targetType
          // this.form.target = JSON.parse(model.targetInfo)
          // this.form.senderId = JSON.parse(model.sender).id
          this.$nextTick(() => {
            try {
              const emailBody = JSON.parse(model.emailBody)
              this.$refs.design.initData(emailBody)
            } catch (e) {}
          })
        }

        if (this.senderOptions.length === 0) {
          listSender().success(resp => (this.senderOptions = resp.data)).send()
        }

        listAll().success(resp => (this.emailGroupOptions = resp.data)).send(true)
      })
    },
    onClose () {
      this.form = createFormData()
      this.model = null
      this.form.target.emails = []
      this.$refs.design.initData(null)
      this.emailBody = null
    },
    handleSave () {
      const data = kit.obj.deepCopy(this.form)
      const sender = this.senderOptions.find(item => item.id === this.form.senderId)
      if (sender) {
        data.sender = JSON.stringify(sender)
      } else {
        data.sender = null
      }

      data.emailBody = JSON.stringify(this.$refs.design.getData())

      this.saveButtonLoading = true
      let api
      if (this.model) {
        api = updateActivity
        data.id = this.model.id
      } else {
        api = addActivity
      }
      api()
        .complete(() => (this.saveButtonLoading = false))
        .success(resp => {
          this.model = resp.data
          this.$message.success('保存成功。')
          this.$emit('saved')
        })
        .send(data)
    },
    handleSenderEditorOpen (isUpdate) {
      if (isUpdate) {
        const opt = this.senderOptions.find(item => item.id === this.form.senderId)
        if (opt) {
          this.senderEditor.editingItem = opt
          kit.obj.getProperties(this.senderEditor.form, opt)
        } else {
          return
        }
      }
      this.$refs.editor.open()
    },
    handleSenderSave () {
      const isUpdate = !!this.senderEditor.editingItem
      const api = isUpdate ? updateSender : addSender

      const data = Object.assign({}, this.senderEditor.form)
      if (isUpdate) {
        data.id = this.senderEditor.editingItem.id
      }
      this.senderEditor.loading = true
      api()
        .complete(() => (this.senderEditor.loading = false))
        .success(resp => {
          if (this.senderOptions.length === 0) {
            this.form.senderId = resp.data.id
          }
          kit.arr.pushOrReplace(this.senderOptions, resp.data)
          this.$message.success('保存成功。')
          this.$nextTick(() => {
            this.$refs.editor.close()
          })
        })
        .send(data)
    },
    handleSenderClose () {
      this.$refs.senderForm.resetFields()
      this.senderEditor.editingItem = null
    },
    deleteSender () {
      const opt = this.senderOptions.find(item => item.id === this.form.senderId)
      if (opt) {
        this.$confirm({
          title: '确认',
          content: `确定要删除【${opt.email}】吗？`,
          onOk: () => {
            deleteSender()
              .success(resp => {
                kit.arr.removeItem(this.senderOptions, opt)
                if (this.form.senderId === opt.id) {
                  this.form.senderId = null
                }
                this.$message.success('删除成功。')
              })
              .send(opt.id)
          }
        })
      }
    },
    handleOpenEmailSelector () {
      this.$refs.emailSelect.open()
    },
    handleEmailSelected (emails) {
      const arr = []
      for (const item of emails) {
        if (this.form.target.emails.find(a => a.id === item.id)) {
          continue
        }
        arr.push({ id: item.id, email: item.email })
      }
      this.form.target.emails.push(...arr)
      if (this.form.target.emails.length > this.maxTargetEmail) {
        this.form.target.emails.splice(this.maxTargetEmail)
      }
    },
    handleRemoveTargetEmail (email) {
      kit.arr.removeItem(this.form.target.emails, email)
    },
    handleStartActivity () {
      this.$confirm({
        title: '确认',
        content: '开始营销后，此活动不再可以编辑，确定要开始吗？',
        onOk: () => {
          this.saveButtonLoading = true
          startActivity()
            .complete(() => (this.saveButtonLoading = false))
            .success(resp => {
              this.$message.success('营销邮件已经开始。')
              this.$emit('saved')
              this.$nextTick(() => {
                this.visible = false
              })
            })
            .send(this.model.id)
        }
      })
    },
    previewEmail () {
      if (this.previewWindow === undefined || this.previewWindow.closed) {
        this.previewWindow = window.open('/api/backend/edm/activity/email_body?id=' + this.model.id, 'edmEmail')
      } else {
        this.previewWindow.focus()
        this.previewWindow.location.reload()
      }
    },
    handleGetEmailBody () {
      getEmailBody()
        .success(resp => {
          this.emailBody = resp.data
          this.$refs.emailBodyCode.open()
        })
        .send(this.model.id)
    },
    handleCopyEmailBody () {
      this.$copyText(this.emailBody).then(() => {
        this.$message.success('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
}
.left-content {
  width: 500px;
}
.right-content {
  flex: 1;
  margin-left: 20px;
}
</style>
