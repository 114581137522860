<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <div slot="toolbar">
      <a-button size="small" @click="reloadData">查询</a-button>
      <a-button size="small" type="primary" @click="handleOpenRegEditor">注册跟踪号</a-button>
      <a-button size="small" type="primary" @click="handleSyncTracking" :loading="syncButtonLoading">同步数据</a-button>
    </div>

    <div slot="top">
      <a-form-model layout="inline" :model="searchParams">
        <a-form-model-item label="运单号">
          <a-input v-model="searchParams.trackNo" :allow-clear="true" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="searchParams.status" :allow-clear="true" style="width: 150px;">
            <a-select-option :value="item.id" v-for="item in statusOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="物流状态">
          <a-select v-model="searchParams.deliveryStatus" :allow-clear="true" style="width: 150px;">
            <a-select-option :value="item.id" v-for="item in deliveryStatusOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="searchParams.remark" :allow-clear="true" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="status" slot-scope="status">
        <span class="status" :class="'status-' + status">{{statusMapping[status]}}</span>
      </div>
      <div slot="deliveryStatus" slot-scope="deliveryStatus">
        <span class="delivery-status" :class="'delivery-status-' + deliveryStatus">{{deliveryStatusMapping[deliveryStatus]}}</span>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <reg-editor ref="regEditor"></reg-editor>
  </app-page>
</template>

<script>
import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import RegEditor from './comp/t17track-reg-editor'
import { listT17track, sync } from '@/http/api/t17track'
import { ROUTE_T17TRACK } from '@/router/router-constants'
import {
  STATUS_LIST,
  STATUS_MAPPING,
  DELIVERY_STATUS_LIST,
  DELIVERY_STATUS_MAPPING
} from '@/constants/t17track-status'

function buildSearchParams () {
  return {
    status: null,
    deliveryStatus: null,
    trackNo: null,
    remark: null
  }
}

export default {
  components: { RegEditor },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_T17TRACK,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '跟踪号', dataIndex: 'trackNo' },
        { title: '物流公司', dataIndex: 'logisticsName' },
        { title: '注册时间', dataIndex: 'createTime' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '物流状态', dataIndex: 'deliveryStatus', scopedSlots: { customRender: 'deliveryStatus' } },
        { title: '物流时间', dataIndex: 'deliveryTime' },
        { title: '签收用时（天）', dataIndex: 'useDays' },
        { title: '备注', dataIndex: 'remark' }
      ],
      selectedRowKeys: [],
      searchParams: buildSearchParams(),
      statusOptions: STATUS_LIST,
      statusMapping: STATUS_MAPPING,
      deliveryStatusOptions: DELIVERY_STATUS_LIST,
      deliveryStatusMapping: DELIVERY_STATUS_MAPPING,
      syncButtonLoading: false
    }
  },
  computed: {
    selectedRows () {
      const arr = []
      for (const item of this.dataList) {
        if (this.selectedRowKeys.includes(item.id)) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      listT17track()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleSyncTracking () {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning('请选择操作行')
      }
      const trackNoList = this.selectedRows.map(item => item.trackNo)
      this.syncButtonLoading = true
      sync()
        .complete(() => (this.syncButtonLoading = false))
        .success(() => {
          this.$message.success('操作完成，请刷新数据列表查看最新信息。')
        })
        .send(trackNoList)
    },
    handleOpenRegEditor () {
      this.$refs.regEditor.open()
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.status, .delivery-status {
  font-size: 14px;
}
.status-reg {
  color: #3d71a7;
}
.status-unReg {
  color: #bb3e30;
}
.status-completed {
  color: #228018;
}

.delivery-status-shipping {
  color: #3d71a7;
}
.delivery-status-exception {
  color: #bb3e30;
}
.delivery-status-delivered {
  color: #228018;
}
.delivery-status-none {
  color: #606266;
}
</style>
