import kit from '@/utils/kit'

/**
 * @param [product]
 */
export default function (product) {
  const item = {
    id: kit.str.id(),
    title: null,
    stockQuantity: 0,
    imageUrl: null,
    orderProductId: null,
    fromSupplierId: null,
    total: 1,
    variant: null,
    sku: null,
    remark: null,
    cost: null,
    skuWeight: null,
    skuLength: null,
    skuWidth: null,
    skuHeight: null
  }
  if (product) {
    return kit.obj.getProperties(item, product)
  } else {
    return item
  }
}
