<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" type="primary" @click="handleCreateInventoryChangeRequest">创建申请单</a-button>

    <div slot="top" class="search-form">
      <a-form-model layout="inline" :model="searchParams">
        <div>
          <a-form-model-item label="标题">
            <a-input v-model="searchParams.title" placeholder="标题关键字" :allow-clear="true" style="width: 220px;" />
          </a-form-model-item>
          <a-form-model-item label="申请编号">
            <a-input v-model="searchParams.reqId" :allow-clear="true" style="width: 160px;" />
          </a-form-model-item>
          <a-form-model-item label="申请日期">
            <a-range-picker v-model="searchParams.dates"
                            value-format="YYYY-MM-DD"
                            style="width: 220px;"
            >
            </a-range-picker>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item label="仓库">
            <a-select v-model="searchParams.toWarehouseId" style="width: 220px;" :allow-clear="true">
              <a-select-option v-for="opt in warehouseOptions" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="变更类型">
            <a-select v-model="searchParams.changeType" style="width: 160px;" :allow-clear="true">
              <a-select-option v-for="opt in changeTypeOptions" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="入库状态">
            <a-select v-model="searchParams.inventoryStatus" style="width: 220px;" :allow-clear="true">
              <a-select-option v-for="opt in inventoryStatusOptions" :key="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>

      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="actions" slot-scope="row">
        <a-button size="small" @click="navProducts(row)">商品清单</a-button>
      </div>
    </a-table>


    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <request-editor ref="editor" @saved="onRequestEditorSaved"></request-editor>

  </app-page>
</template>

<script>
import { ROUTE_INVENTORY_CHANGE_REQUEST, ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS } from '@/router/router-constants'

import PaginationMixin from '@/mixins/pagination'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listInventoryChangeRequest } from '@/http/api/inventory-change-request'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import {
  INVENTORY_CHANGE_STATUS_LIST,
  INVENTORY_CHANGE_TYPE_LIST,
  INVENTORY_CHANGE_STATUS_MAPPING,
  INVENTORY_CHANGE_TYPE_MAPPING
} from '@/constants/inventory-change-request'
import RequestEditor from './comp/request-editor'

export default {
  components: { RequestEditor },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_INVENTORY_CHANGE_REQUEST,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '操作', width: 100, scopedSlots: { customRender: 'actions' } },
        { title: '申请编号', dataIndex: 'reqId', width: 100 },
        { title: '标题', dataIndex: 'title' },
        { title: '变更类型', dataIndex: 'changeType', width: 90, customRender: text => INVENTORY_CHANGE_TYPE_MAPPING[text] },
        { title: '入库状态', dataIndex: 'inventoryStatus', width: 90, customRender: text => INVENTORY_CHANGE_STATUS_MAPPING[text] },
        { title: '申请人', dataIndex: 'createUserName', width: 90 },
        { title: '出库仓库', dataIndex: 'fromWarehouseName', width: 110 },
        { title: '入库仓库', dataIndex: 'toWarehouseName', width: 110 },
        { title: '运费', dataIndex: 'freight', width: 90 }
      ],
      searchParams: {
        title: null,
        reqId: null,
        dates: [],
        changeType: null,
        inventoryStatus: null,
        toWarehouseId: null
      },
      warehouseOptions: [],
      changeTypeOptions: INVENTORY_CHANGE_TYPE_LIST,
      inventoryStatusOptions: INVENTORY_CHANGE_STATUS_LIST
    }
  },
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      if (p.dates.length === 2) {
        p.startDate = p.dates[0]
        p.endDate = p.dates[1]
      }
      delete p.dates
      this.dataLoading = true
      listInventoryChangeRequest()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    navProducts (row) {
      this.$router.push({ name: ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS, params: { id: row.id } })
    },
    handleCreateInventoryChangeRequest () {
      this.$refs.editor.open()
    },
    onRequestEditorSaved () {
      this.reloadData()
    }
  },
  mounted () {
    listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
    this.reloadData()
  }
}
</script>

<style scoped>

</style>
