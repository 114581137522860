<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <div slot="top">
      <div style="display: flex; align-items: center;">
        <a-alert message="上传Excel或CSV文件，第一行为标题，第二行开始为数据行。" type="info" show-icon />
        <a-button type="primary" style="margin-left: 10px;" @click="handleSelectFile()">上传文件</a-button>
        <a-button @click="handleExportSampleFile()">下载样例文件</a-button>
      </div>
    </div>

    <div slot="center">
      <a-table
          slot="center"
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="updateList"
          :pagination="false"
          :scroll="{ y: antdTableY }"
      >
        <div slot="sn" slot-scope="v, item, index">{{index + 1}}</div>
        <div slot="errorMessage" slot-scope="v">
          <span v-if="v === '成功'" class="text-success">{{v}}</span>
          <span v-else class="text-danger">{{v}}</span>
        </div>
      </a-table>
    </div>

    <div slot="bottom" v-if="updateList.length > 0" class="bottom-bar">
      <a-button size="large" @click="handleSelectFile()">重新上传文件</a-button>
      <a-button size="large" type="primary" @click="handleUpdateTracking">更新物流信息（共{{updateList.length}}个）</a-button>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE } from '@/router/router-constants'
import selectFiles from '@/utils/select-file'
import { listByTrackingFile, updateTrackingInfo, getUpdateTrackingSample } from '@/http/api/order-delivery'
import AntdTableHeightMixin from '@/mixins/antd-table-height'

export default {
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE,
      pageLoading: false,
      updateList: [],
      dataColumns: [
        { title: '序号', scopedSlots: { customRender: 'sn' }, width: 50 },
        { title: '订单号', dataIndex: 'orderNo', width: 80 },
        { title: '发货编号', dataIndex: 'deliveryOrderNo', width: 150 },
        { title: '运单号', dataIndex: 'trackNo' },
        { title: '物流公司', dataIndex: 'logistics' },
        { title: '运费', dataIndex: 'fee' },
        { title: '运费货币', dataIndex: 'feeCurrency' },
        { title: '服务费', dataIndex: 'feePrepare' },
        { title: '服务费货币', dataIndex: 'feePrepareCurrency' },
        { title: '重量(kg)', dataIndex: 'weight' },
        { title: '错误消息', dataIndex: 'errorMessage', scopedSlots: { customRender: 'errorMessage' } }
      ],
      lastedSelectCompanyId: null
    }
  },
  methods: {
    handleSelectFile () {
      if (this.pageLoading) {
        return
      }
      selectFiles({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multi: false,
        select: (files) => {
          this.pageLoading = true
          listByTrackingFile()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              this.updateList = resp.data
            })
            .send(files[0])
        }
      })
    },
    handleUpdateTracking () {
      this.$confirm({
        title: '确认',
        content: '确定要更新吗？',
        onOk: () => {
          this.pageLoading = true
          updateTrackingInfo()
            .complete(() => (this.pageLoading = false))
            .success(resp => {
              let errorCount = 0
              for (const item of this.updateList) {
                if (resp.data[item.id]) {
                  item.errorMessage = resp.data[item.id]
                  errorCount++
                } else {
                  item.errorMessage = '成功'
                }
              }
              if (errorCount > 0) {
                this.$error({
                  title: '错误提示',
                  content: `有${errorCount}个错误项，请检查。`
                })
              } else {
                this.$message.success('操作完成，请检查各行是否有错误。')
              }
            })
            .send(this.updateList)
        }
      })
    },
    handleExportSampleFile () {
      window.open(getUpdateTrackingSample())
    }
  }
}
</script>

<style lang="less" scoped>


.upload-wrapper {
  @padding: 0;
  position: absolute;
  left: @padding;
  top: @padding;
  right: @padding;
  bottom: @padding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  .block {
    margin: 20px;
    padding: 80px 15px 15px 15px;
    width: 400px;
    height: 300px;
    border: solid 1px #ddd;
    border-radius: 10px;
    background-color: #fff;
    .template-download {
      margin: 10px 0;
      text-align: center;
    }
    .message {
      font-size: 12px;
      text-align: center;
    }
  }
}

.bottom-bar {
  background-color: #fff;
  border-top: solid 1px #eee;
  padding: 20px 0;
  text-align: center;
}

</style>
