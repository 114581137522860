<template>
  <modal ref="pr" :title="title" :width="800" @closed="onClosed" :hide-buttons="true">
    <multi-delivery-products-content :order="order" :selected-warehouse="selectedWarehouse" :sku-stocks="skuStocks" :ship-sku-total="shipSkuTotal"></multi-delivery-products-content>
  </modal>
</template>

<script>
import MultiDeliveryProductsContent from '@/pages/delivery/comp/multi-delivery-products-content'

export default {
  components: { MultiDeliveryProductsContent },
  data () {
    return {
      order: null,
      selectedWarehouse: null,
      skuStocks: {},
      shipSkuTotal: {}
    }
  },
  computed: {
    title () {
      return this.order ? '发货产品 - ' + this.order.orderNo : '发货产品'
    }
  },
  methods: {
    open (order, selectedWarehouse, skuStocks, shipSkuTotal) {
      this.order = order
      this.selectedWarehouse = selectedWarehouse
      this.skuStocks = skuStocks
      this.shipSkuTotal = shipSkuTotal
      this.$refs.pr.open()
    },
    onClosed () {
      this.$emit('closed', this.order)
      this.order = null
      this.selectedWarehouse = null
      this.skuStocks = {}
      this.shipSkuTotal = {}
    }
  }
}
</script>

<style lang="less" scoped>
</style>
