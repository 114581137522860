<template>
  <modal ref="modal" title="导入数据" :loading="loading" @ok="handleConfirm" @closed="onClosed">
    <a-form-model :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="文件">
        <a-button size="small" @click="handleSelectFile">选择文件...</a-button>
        <div class="font-mini text-muted" style="line-height: normal;" v-if="selectedFile">{{selectedFile.name}}</div>
      </a-form-model-item>

      <a-form-model-item label="批次">
        <a-input v-model="form.batchId"></a-input>
      </a-form-model-item>

      <a-form-model-item label="客户">
        <a-select v-model="form.partnerId" style="width: 100%;">
          <a-select-option v-for="opt in partnerOptions" :key="opt.id">{{opt.name}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="平台">
        <a-select v-model="form.platform" style="width: 100%;">
          <a-select-option value="google">Google</a-select-option>
          <a-select-option value="tiktok">Tiktok</a-select-option>
          <a-select-option value="facebook">Facebook</a-select-option>
          <a-select-option value="bing">Bing</a-select-option>
          <a-select-option value="other">其他</a-select-option>
        </a-select>
      </a-form-model-item>

    </a-form-model>
  </modal>
</template>

<script>
import { importData } from '@/http/api/partner-ads-cost'
import { listOptions } from '@/http/api/partner'
import selectFile from '@/utils/select-file'

export default {
  data () {
    return {
      loading: false,
      form: {
        partnerId: null,
        batchId: null,
        platform: null
      },
      selectedFile: null,
      partnerOptions: []
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
      if (this.partnerOptions.length === 0) {
        listOptions().success(resp => (this.partnerOptions = resp.data)).send()
      }
    },
    onClosed () {
      this.form.platform = null
      this.form.batchId = null
      this.form.partnerId = null
      this.selectedFile = null
    },
    handleConfirm () {
      if (!this.selectedFile) {
        return this.$message.error('请选择数据文件。')
      }
      if (!this.form.partnerId) {
        return this.$message.error('请选择客户。')
      }
      this.loading = true
      importData()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.$message.success('导入成功。')
          this.$emit('imported', resp.data) // return batchId
          this.$nextTick(() => (this.$refs.modal.close()))
        })
        .send(this.selectedFile, this.form.partnerId, this.form.batchId, this.form.platform)
    },
    handleSelectFile () {
      selectFile({
        accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multi: false,
        select: (files) => {
          this.selectedFile = files[0]
          if (this.selectedFile) {
            this.form.batchId = this.selectedFile.name.split('.')[0]
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
