<template>
  <div>
    <div v-if="!role" class="font-mini text-muted content-center">要编辑角色的权限，请先选择一个角色。</div>
    <div v-else class="main-content">
      <div class="role-info left-right-content" style="align-items: center;">
        <div class="full">
          <span class="font-bold font-large text-danger">权限分配</span>
          <span class="font-mini text-muted" v-show="appliedProtectResourceTotal > 0">（{{appliedProtectResourceTotal}}）</span>
        </div>
        <div>
          <a-button @click="handleProtectResourceEditorOpen(null)">新增权限</a-button>
        </div>
      </div>
      <div class="protect-resource-list">
        <div style="margin: 10px 0;">
          <a-input v-model="searchPath" placeholder="输入URI路径查找匹配的权限" style="width: 100%;" :allow-clear="true"></a-input>
        </div>
        <div class="pr-group" v-for="(g, index) in protectResourceGroups" :key="index">
          <div class="title">
            <a-icon type="container" />
            {{g.title}}
          </div>
          <div class="pr-grid">
            <div class="pr-grid-item" :class="{'match': r._isSearchMatch}" v-for="r in g.resources" :key="r.id">
              <div class="checkbox"><a-checkbox v-model="r.applied" @change="handleRoleProtectResourceApplyChanged($event, r)"></a-checkbox></div>
              <div class="name">
                <a-tooltip placement="top" v-if="r.description">
                  <template slot="title">{{r.description}}</template>
                  <span :class="{'disabled': !r.enabled}">{{r.name}}</span>
                </a-tooltip>
                <span v-else :class="{'disabled': !r.enabled}">{{r.name}}</span>
                <span class="edit-btn">
                  <a-button size="small" type="link" @click="handleProtectResourceEditorOpen(r)">编辑</a-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading :loading="dataLoading"></loading>
    </div>


    <!-- 权限编辑对话框 -->
    <modal
        ref="protectResourceEditor"
        title="权限编辑"
        :width="800"
        :loading="protectResourceEditor.updating"
        @ok="handleProtectResourceEditorSave"
        @closed="handleProtectResourceEditorClose"
    >
      <a-form-model ref="form" :model="protectResourceEditor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="名称" class="app_required-input">
          <a-input v-model="protectResourceEditor.form.name"/>
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-textarea v-model="protectResourceEditor.form.description" :auto-size="{ minRows: 2, maxRows: 2 }" />
        </a-form-model-item>
        <a-form-model-item label="分组" class="app_required-input">
          <a-auto-complete
              v-model="protectResourceEditor.form.groupName"
              :data-source="protectResourceEditor.groupNameOptionsFilter"
              style="width: 100%;"
              @search="onGroupNameOptionSearch"
          />
        </a-form-model-item>
        <a-form-model-item label="是否可用">
          <a-switch v-model="protectResourceEditor.form.enabled"></a-switch>
        </a-form-model-item>
      </a-form-model>
      <div class="resource-container">
        <div class="title left-right-content" style="align-items: center;">
          <div class="full">系统可用资源</div>
          <div>
            <a-input v-model="protectResourceEditor.searchKeyword" placeholder="输入关键字查找" style="width: 300px;"></a-input>
          </div>
        </div>
        <div class="ms-resource">

          <div class="group-panel">
            <div class="group-wrapper">
              <div class="group-item"
                   :class="{
                    'selected': protectResourceEditor.selectedResourceGroup && protectResourceEditor.selectedResourceGroup === g,
                    'has-checked': g.resources.filter(item => item._checked).length > 0,
                    'search-match': g._isSearchMatch
              }"
                   v-for="g in protectResourceEditor.availableResources"
                   :key="g.id"
                   @click="protectResourceEditor.selectedResourceGroup = g"
              >
                {{g.name}}
                <span class="selected-total">{{g.resources.filter(item => item._checked).length}}</span>
              </div>
            </div>
          </div>

          <div class="resource-panel">
            <div v-if="protectResourceEditor.selectedResourceGroup">
              <div style="margin: 10px 0;">
                <a-checkbox v-model="protectResourceEditor.selectedResourceGroup._checked" @change="onAvailableResourceCheckChanged($event, protectResourceEditor.selectedResourceGroup, null)"></a-checkbox>
                <span class="font-bold" style="margin-left: 10px;">全选</span>
              </div>
              <div class="res-item res"
                   :class="{'search-match': r._isSearchMatch}"
                   v-for="r in protectResourceEditor.selectedResourceGroup.resources"
                   :key="r.path">
                <div>
                  <a-checkbox v-model="r._checked" @change="onAvailableResourceCheckChanged($event, protectResourceEditor.selectedResourceGroup, r)"></a-checkbox>
                </div>
                <div class="res-content">
                  <div class="name">{{r.name}}</div>
                  <div class="path">{{r.path}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-button slot="buttons" type="danger" v-show="protectResourceEditor.isUpdate" :loading="protectResourceEditor.updating" @click="handleProtectResourceEditorDelete()">删除此权限</a-button>
    </modal>
  </div>
</template>

<script>
import RoleModel from '@/data/model/role'
import { getRoleProtectResources, applyProtectResource } from '@/http/api/role'
import {
  listGroupNames,
  listAvailableResources,
  addProtectResource,
  updateProtectResource,
  getItemValuesById,
  deleteProtectResourceById
} from '@/http/api/protect-resource'
import CurrentRoleProtectResource from '@/data/dto/current-role-protect-resource'
import ProtectResource from '@/data/model/protect-resource'
import ProtectResourceUpdateDto from '@/data/dto/protect-resource-update'
import kit from '@/utils/kit'
import { debounce } from 'throttle-debounce'

export default {
  props: {
    role: RoleModel
  },
  data () {
    return {
      /**
       * [{title: String, resources: CurrentRoleProtectResource}]
       */
      protectResourceGroups: [],
      dataLoading: false,
      searchPath: null,
      protectResourceEditor: {
        updating: false,
        isUpdate: false,
        groupNameOptions: [],
        groupNameOptionsFilter: [],
        availableResources: [],
        selectedResourceGroup: null,
        form: new ProtectResource(),
        searchKeyword: null
      }
    }
  },
  watch: {
    role () {
      if (this.role) {
        this.loadRoleProtectResources()
      } else {
        this.protectResourceGroups = []
      }
    },
    'protectResourceEditor.searchKeyword' (keyword) {
      this.handleSearchMatchResourceInResourceEditor(keyword)
    },
    searchPath (path) {
      this.handleSearchMatchResource(path)
    }
  },
  computed: {
    checkedAvailableResourcePaths () {
      const paths = []
      for (const res of Object.values(this.availableResourceMapByPath)) {
        if (res._checked) {
          paths.push(res.path)
        }
      }
      return paths
    },
    availableResourceMapByPath () {
      const map = {}
      for (const g of this.protectResourceEditor.availableResources) {
        for (const res of g.resources) {
          map[res.path] = res
        }
      }
      return map
    },
    appliedProtectResourceTotal () {
      let t = 0
      for (const g of this.protectResourceGroups) {
        for (const r of g.resources) {
          if (r.applied) {
            t++
          }
        }
      }
      return t
    }
  },
  methods: {
    loadRoleProtectResources () {
      this.dataLoading = true
      getRoleProtectResources()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          const arr = []
          for (const groupName of Object.keys(resp.data)) {
            arr.push({
              title: groupName,
              resources: kit.arr.newList(resp.data[groupName], CurrentRoleProtectResource, item => {
                item._isSearchMatch = false
              })
            })
          }
          this.protectResourceGroups = arr
        })
        .send(this.role.id)
    },
    handleProtectResourceEditorOpen (protectResourceModel) {
      if (protectResourceModel) {
        this.protectResourceEditor.isUpdate = true
        this.protectResourceEditor.form = new ProtectResource(protectResourceModel)
        getItemValuesById()
          .success(resp => {
            for (const value of resp.data) {
              const res = this.availableResourceMapByPath[value]
              if (res) {
                res._checked = true
              }
            }
          })
          .send(protectResourceModel.id)
      } else {
        this.protectResourceEditor.isUpdate = false
        this.protectResourceEditor.form = new ProtectResource()
      }
      this.$refs.protectResourceEditor.open()
    },
    handleProtectResourceEditorSave () {
      if (this.checkedAvailableResourcePaths.length === 0) {
        this.$warning({
          title: '消息',
          content: '请至少选择一个权限资源。'
        })
      } else {
        const form = new ProtectResourceUpdateDto(this.protectResourceEditor.form)
        form.protectResourceItemValues = [...this.checkedAvailableResourcePaths]
        this.protectResourceEditor.updating = true
        const api = this.protectResourceEditor.isUpdate ? updateProtectResource : addProtectResource
        api()
          .complete(() => (this.protectResourceEditor.updating = false))
          .success(resp => {
            this.$message.success('保存成功。')
            this.loadRoleProtectResources()
            this.$nextTick(() => {
              this.$refs.protectResourceEditor.close()
            })
          })
          .send(form)
      }
    },
    handleProtectResourceEditorDelete () {
      this.$confirm({
        title: '确认',
        content: '确定要删除吗？',
        onOk: () => {
          this.protectResourceEditor.updating = true
          deleteProtectResourceById()
            .complete(() => (this.protectResourceEditor.updating = false))
            .success(() => {
              this.$message.success('删除成功。')
              this.loadRoleProtectResources()
              this.$nextTick(() => {
                this.$refs.protectResourceEditor.close()
              })
            })
            .send(this.protectResourceEditor.form.id)
        }
      })
    },
    handleProtectResourceEditorClose () {
      for (const res of Object.values(this.availableResourceMapByPath)) {
        res._checked = false
      }
      for (const g of this.protectResourceEditor.availableResources) {
        g._checked = false
        for (const res of g.resources) {
          res._checked = false
        }
      }
      this.protectResourceEditor.selectedResourceGroup = null
      this.protectResourceEditor.searchKeyword = null
    },
    onGroupNameOptionSearch (searchText) {
      if (searchText) {
        const arr = this.protectResourceEditor.groupNameOptions.filter(item => (item.includes(searchText)))
        if (arr.length > 0) {
          this.protectResourceEditor.groupNameOptionsFilter = arr
        } else {
          this.protectResourceEditor.groupNameOptionsFilter = [searchText]
        }
      } else {
        this.protectResourceEditor.groupNameOptionsFilter = this.protectResourceEditor.groupNameOptions
      }
    },
    onAvailableResourceCheckChanged (e, group, res) {
      if (res) {
        let allChecked = true
        for (const r of group.resources) {
          if (!r._checked) {
            allChecked = false
            break
          }
        }
        group._checked = allChecked
      } else {
        for (const r of group.resources) {
          r._checked = e.target.checked
        }
      }
    },
    /**
     * 权限分配更新
     * @param e
     * @param protectResource
     */
    handleRoleProtectResourceApplyChanged (e, protectResource) {
      applyProtectResource()
        .success(() => {
          this.$message.success(`【${protectResource.name}】操作成功。`)
        })
        .send(this.role.id, protectResource.id, e.target.checked ? 'apply' : 'remove')
    }
  },
  mounted () {
    listAvailableResources()
      .success(resp => {
        for (const g of resp.data) {
          g._checked = false
          g._isSearchMatch = false
          for (const res of g.resources) {
            res._checked = false
            res._isSearchMatch = false
          }
        }
        resp.data.sort((a, b) => (a.name.localeCompare(b.name)))
        this.protectResourceEditor.availableResources = resp.data
      })
      .send()
    listGroupNames()
      .success(resp => {
        this.protectResourceEditor.groupNameOptions = resp.data
        this.protectResourceEditor.groupNameOptionsFilter = resp.data
      })
      .send()

    this.handleSearchMatchResourceInResourceEditor = debounce(300, keyword => {
      for (const g of this.protectResourceEditor.availableResources) {
        g._isSearchMatch = false
        for (const res of g.resources) {
          res._isSearchMatch = keyword != null && keyword.length > 0 && (res.name.includes(keyword) || res.path.includes(keyword))
          if (res._isSearchMatch) {
            g._isSearchMatch = true
          }
        }
      }
    })

    this.handleSearchMatchResource = debounce(300, keyword => {
      for (const g of this.protectResourceGroups) {
        for (const res of g.resources) {
          res._isSearchMatch = false
          for (const path of res.resourcePaths) {
            if (keyword != null && keyword.length > 0 && path.includes(keyword)) {
              res._isSearchMatch = true
              break
            }
          }
        }
      }
    })
  }
}
</script>

<style lang="less" scoped>
.main-content {
  margin-left: 20px;
  padding-right: 10px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.role-info {
  padding: 4px 0;
  border-bottom: solid 1px #eee;
  .role-name {
    padding: 0 10px;
    font-size: 18px;
  }
}

// 角色的权限
.protect-resource-list {
  margin-top: 20px;
  overflow-y: auto;
  .pr-group + .pr-group {
    margin-top: 20px;
  }
  .pr-group {
    border: solid 1px #eee;
    &>.title {
      padding: 8px;
      font-size: 16px;
      border-bottom: solid 1px #eee;
      background-color: #f9f9f9;
    }
    .pr-grid {
      //display: grid;
      padding: 14px;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
      .pr-grid-item {
        display: flex;
        .name {
          flex: 1;
          margin-left: 10px;
          .disabled {
            text-decoration: line-through;
            color: #80181b;
          }
          .edit-btn {
            visibility: hidden;
          }
        }
        &:hover .name .edit-btn {
          visibility: visible;
        }
        &.match .name {
          color: #2f790e;
        }
      }
    }
  }
}


// 可用的系统权限资源
.resource-container {
  .title {
    padding: 5px 0;
    font-weight: bold;
    border-bottom: solid 1px #eee;
  }
  .ms-resource {
    display: flex;

    .group-panel {
      width: 300px;
      .group-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      margin-right: 10px;
      padding-right: 10px;
      border-right: solid 1px #eee;
      .group-item {
        position: relative;
        padding: 5px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
        &.selected {
          background-color: #3d71a7 !important;
          color: #fff;
        }

        .selected-total {
          visibility: hidden;
          font-weight: bold;
          color: #cb5f5c;
          margin-left: 7px;
          font-size: 14px;
        }
        &.has-checked .selected-total {
          visibility: visible;
        }
        &.has-checked:not(.selected) {
          color: #cb5f5c;
        }
        &.search-match {
          color: #2f790e !important;
        }
        &.selected.search-match {
          color: #ffffff !important;
        }
      }
    }

    .resource-panel {
      flex: 1;
      .res-item.group-head {
        padding: 8px 0;
        margin: 8px 0;
        border-bottom: dashed 1px #d98c8c;
        font-weight: bold;
      }

      .res-item.res {
        display: flex;
        .res-content {
          margin-left: 10px;
        }
        .name {
          color: #797979;
          font-size: 12px;
        }
        .path {
          font-size: 12px;
          color: #ddd;
        }
        &.search-match .name {
          color: #2f790e !important;
        }
      }
    }
  }
}
</style>
