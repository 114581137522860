<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">

    <a-button slot="toolbar" type="primary" @click="handleEditorOpen(null)">新增</a-button>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="handleEditorOpen(row)">编辑</a-button>
      </div>

      <status slot="enabled" slot-scope="enabled" :status="enabled"></status>
    </a-table>

    <modal
        ref="editor"
        title="编辑"
        :width="500"
        :loading="editor.updating"
        @ok="handleEditorSave"
        @closed="handleEditorClose"
    >
      <a-form-model ref="form" :model="editor.form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="名称" prop="name" class="app_required-input">
          <a-input v-model="editor.form.name"/>
        </a-form-model-item>
        <a-form-model-item label="编号" prop="code" class="app_required-input">
          <a-input v-model="editor.form.code"/>
        </a-form-model-item>
        <a-form-model-item label="是否可用" prop="enabled">
          <a-switch v-model="editor.form.enabled"/>
        </a-form-model-item>
      </a-form-model>
    </modal>

  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listAllSupplier, addSupplier, updateSupplier } from '@/http/api/supplier'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      routeName: ROUTE_SUPPLIER,
      dataColumns: [
        { title: '操作', width: 120, scopedSlots: { customRender: 'action' } },
        { title: '名称', dataIndex: 'name' },
        { title: '编号', dataIndex: 'code' },
        { title: '状态', dataIndex: 'enabled', scopedSlots: { customRender: 'enabled' } }
      ],
      dataList: [],
      dataLoading: false,
      editor: {
        updating: false,
        updateModel: null,
        form: {
          id: null,
          name: null,
          code: null,
          enabled: true
        }
      }
    }
  },
  mixins: [AntdTableHeightMixin],
  methods: {
    loadData () {
      this.dataLoading = true
      listAllSupplier()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send()
    },
    handleEditorOpen (model) {
      this.editor.updateModel = model
      if (model) {
        kit.obj.getProperties(this.editor.form, model)
      }
      this.$refs.editor.open()
    },
    handleEditorSave () {
      const isUpdate = !!this.editor.updateModel
      const api = isUpdate ? updateSupplier : addSupplier
      this.editor.updating = true
      api()
        .complete(() => (this.editor.updating = false))
        .success(resp => {
          kit.arr.pushOrReplace(this.dataList, resp.data)
          this.$message.success('操作成功。')
          this.$nextTick(() => (this.$refs.editor.close()))
        })
        .send(this.editor.form)
    },
    handleEditorClose () {
      this.editor.form.id = null
      this.editor.form.name = null
      this.editor.form.code = null
      this.editor.form.enabled = true
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
