import kit from '@/utils/kit'

export const STATUS_REG = 'reg'
export const STATUS_UN_REG = 'unReg'
export const STATUS_COMPLETED = 'completed'

export const STATUS_MAPPING = {
  [STATUS_REG]: '已注册',
  [STATUS_UN_REG]: '未注册',
  [STATUS_COMPLETED]: '已完成'
}

export const STATUS_LIST = kit.obj.toArray(STATUS_MAPPING)

export const DELIVERY_STATUS_SHIPPING = 'shipping'
export const DELIVERY_STATUS_EXCEPTION = 'exception'
export const DELIVERY_STATUS_DELIVERED = 'delivered'
export const DELIVERY_STATUS_NOT_FOUND = 'none'

export const DELIVERY_STATUS_MAPPING = {
  [DELIVERY_STATUS_SHIPPING]: '运输中',
  [DELIVERY_STATUS_EXCEPTION]: '异常',
  [DELIVERY_STATUS_DELIVERED]: '已签收',
  [DELIVERY_STATUS_NOT_FOUND]: '单号不存在'
}

export const DELIVERY_STATUS_LIST = kit.obj.toArray(DELIVERY_STATUS_MAPPING)

