import defineApi from '../define-api'
import http from '../application-api'

/**
 * @type {Function}
 */
export const getCurrencyConvertRate = defineApi((config, fromCurr, toCurr) => {
  config.params = {
    fromCurr: fromCurr,
    toCurr: toCurr
  }
  http.get('/exchange_rate/rate', config)
})
