import defineApi from '../define-api'
import http from '../application-api'


export const syncWarehouse = defineApi((config, companyId) => {
  config.params = {
    companyId: companyId
  }
  http.post('/abroad_shipping/warehouse/sync', config)
})

export const uploadSku = defineApi((config, companyId, id) => {
  config.params = {
    id: id,
    companyId: companyId
  }
  http.post('/abroad_shipping/sku/upload', config)
})

export const syncStock = defineApi((config, companyId) => {
  config.params = {
    companyId: companyId
  }
  http.post('/abroad_shipping/warehouse/stock/sync', config)
})
