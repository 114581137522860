<template>
  <modal title="邮箱选择" ref="modal" :width="1000" @ok="handleConfirm">
    <div class="search-form">
      <a-form-model layout="inline" :model="searchParams" class="form">
        <a-form-model-item label="邮箱">
          <a-input v-model="searchParams.email" :allow-clear="true" style="width: 160px;" />
        </a-form-model-item>
        <a-form-model-item label="分组">
          <a-select v-model="searchParams.groupId" style="width: 160px;" :allow-clear="true">
            <a-select-option :value="item.id" v-for="item in emailGroupOptions" :key="item.id">
              {{item.name}}（{{item.emailTotal}}）
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发送记录">
          <a-select v-model="searchParams.sent" style="width: 120px;" :allow-clear="true">
            <a-select-option :value="1">有发送过</a-select-option>
            <a-select-option :value="0">未发送过</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="buttons">
        <a-button @click="reloadData()" icon="reload">查询</a-button>
      </div>
    </div>

    <a-table
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: 300 }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="sent" slot-scope="row">
        <span v-if="row.sent" class="text-success font-mini">已发送</span>
        <span v-else class="text-muted font-mini">未发送</span>
      </div>
    </a-table>

    <div style="margin-top: 15px;">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

  </modal>
</template>

<script>
import PaginationMixin from '@/mixins/pagination'
import { listEdmEmails } from '@/http/api/edm-email'
import { listAll as listAllEmailGroups } from '@/http/api/edm-email-group'

export default {
  data () {
    return {
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '邮箱', dataIndex: 'email', width: 240 },
        { title: '发送记录', width: 160, scopedSlots: { customRender: 'sent' } },
        { title: '分组', dataIndex: 'groupName' }
      ],
      searchParams: {
        groupId: null,
        email: null,
        sent: null
      },
      emailGroupOptions: [],
      selectedRowKeys: []
    }
  },
  mixins: [PaginationMixin],
  methods: {
    open () {
      this.$refs.modal.open()
      if (this.dataList.length === 0) {
        this.loadData()
      }
      this.loadEmailGroups()
    },
    loadData () {
      const p = this.buildRequestParams(this.searchParams)
      this.dataLoading = true
      listEdmEmails()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.page.total = resp.data.total
          this.dataList = resp.data.records
        })
        .send(p)
    },
    loadEmailGroups () {
      listAllEmailGroups().success(resp => (this.emailGroupOptions = resp.data)).send(true)
    },
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    handleConfirm () {
      const arr = []
      const map = {}
      for (const key of this.selectedRowKeys) {
        map[key] = true
      }
      for (const data of this.dataList) {
        if (map[data.id]) {
          arr.push({
            id: data.id,
            email: data.email
          })
        }
      }
      this.$emit('selected', arr)
      this.selectedRowKeys = []
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="less" scoped>

</style>
