<template>
  <div class="container">
    <div class="top left-right-content" style="align-items: center;">
      <div class="info full">
        <div>
          <span class="font-bold text-danger">货币：USD</span>
          <span v-if="order && order.currency !== 'USD'">订单汇率（{{order.currency}} > USD）：{{order.orderCurrencyToUsd}}</span>
          <span v-if="order">运费，成本价汇率（CNY > USD）：{{order.cnyToUsd}}</span>
          <span v-if="order && order.payFeePercent">支付手续费率：{{order.payFeePercent * 100}}%</span>
          <span v-else>支付手续费率：无</span>
        </div>
        <div class="text-muted font-mini profit-desc">
          <p>实销额 = 销售额 - 折扣 - 退款 - 争议退款 - 争议手续费</p>
          <p>毛利 = 实销额 - 采购成本 - 物流费用 - 汇兑损益 - 包材成本</p>
          <p>利润 = 毛利 - 支付手续费 - 佣金</p>
        </div>
      </div>

      <div>
        <a-switch :loading="dataLoading" v-model="mergeSku" checked-children="合并SKU" un-checked-children="展开SKU" style="margin-right: 20px;"></a-switch>
        <a-button type="primary" :loading="updating" @click="handleUpdate">更新数据</a-button>
      </div>
    </div>

    <div class="bottom">
      <a-table
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
          :scroll="{ x: tableX }"
          :loading="dataLoading"
      >
        <div slot="sku" slot-scope="sku, row">
          {{sku}}
          <span v-if="row.total > 1" style="font-weight: bold; color: #a94442; margin-left: 5px;">x {{row.total}}</span>
        </div>

        <div slot="profit" slot-scope="profit">
          <span :class="{'text-success': profit > 0, 'text-danger': profit < 0}">{{profit}}</span>
        </div>
        <div slot="lirun" slot-scope="lirun">
          <span :class="{'text-success': lirun > 0, 'text-danger': lirun < 0}">{{lirun}}</span>
        </div>
        <div slot="profitPercent" slot-scope="profitPercent">
          <span v-if="profitPercent <= 0">-</span>
          <span v-else class="text-success">{{profitPercent}} %</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { listByOrder, updateByOrder } from '@/http/api/profit-loss'

export default {
  props: {
    order: { type: Object }
  },
  data () {
    return {
      updating: false,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: 'SKU', dataIndex: 'sku', width: 200, scopedSlots: { customRender: 'sku' }, fixed: 'left' },
        { title: '销售额', dataIndex: 'salePrice', width: 80 },
        { title: '实销额', dataIndex: 'realSalePrice', width: 80 },
        { title: '客户运费', dataIndex: 'shippingPrice', width: 80 },
        { title: '折扣', dataIndex: 'discountPrice', width: 80 },
        { title: '采购价', dataIndex: 'purchasePrice', width: 80 },
        { title: '本地运费', dataIndex: 'freightPriceLocal', width: 80 },
        { title: '头程运费', dataIndex: 'freightPriceHead', width: 80 },
        { title: '尾程运费', dataIndex: 'freightPriceTail', width: 80 },
        { title: '平台佣金', dataIndex: 'payFee', width: 80 },
        { title: '退款', dataIndex: 'refundPrice', width: 80 },
        { title: '争议扣款', dataIndex: 'chargebackPrice', width: 80 },
        { title: '争议手续费', dataIndex: 'chargebackFee', width: 90 },
        { title: '达人佣金', dataIndex: 'dryj', width: 80 },
        { title: '包材成本', dataIndex: 'bccb', width: 80 },
        { title: '汇兑损益', dataIndex: 'hdsy', width: 80 },
        { title: '毛利', dataIndex: 'profit', scopedSlots: { customRender: 'profit' }, fixed: 'right', width: 80 },
        { title: '利润', dataIndex: 'lirun', scopedSlots: { customRender: 'lirun' }, fixed: 'right', width: 80 },
        { title: '利润率', dataIndex: 'profitPercent', scopedSlots: { customRender: 'profitPercent' }, fixed: 'right', width: 80 }
      ],
      mergeSku: true
    }
  },
  computed: {
    tableX () {
      let width = 0
      for (const col of this.dataColumns) {
        width += col.width
      }
      return width
    }
  },
  watch: {
    mergeSku () {
      this.reload()
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
    },
    onInactive () {
    },
    onInit () {
      this.reload()
    },
    reload () {
      this.dataLoading = true
      listByOrder()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.order.id, this.mergeSku)
    },
    handleUpdate () {
      this.updating = true
      updateByOrder()
        .complete(() => (this.updating = false))
        .success(resp => {
          this.$message.success('更新成功。')
          this.reload()
        })
        .send(this.order.id)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}

.top .info {
  span {
    margin-right: 30px;
    font-size: 12px;
  }
}

.profit-desc {
  margin-top: 8px;
  p {
    margin-bottom: 3px;
  }
}

.bottom {
  flex: 1;
  padding-top: 20px;
  overflow-y: auto;
}
</style>
