<!-- 内部订单编辑器 -->
<template>
  <drawer
      :width="900"
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      title="内部订单编辑"
      @save="onSave"
      @close="onClose"
  >
    <div style="margin-bottom: 10px;">
      <a-dropdown>
        <a-menu slot="overlay" @click="handleFillAddressFromWarehouse">
          <a-menu-item :key="item.id" v-for="item in warehouses">{{item.name}}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 从仓库获取地址 <a-icon type="down" /> </a-button>
      </a-dropdown>
    </div>

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <div class="form-item-wrapper">
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="form.email" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="receiverPhone">
          <a-input v-model="form.receiverPhone" />
        </a-form-model-item>
      </div>
      <div class="form-item-wrapper">
        <a-form-model-item label="姓" prop="receiverFirstName">
          <a-input v-model="form.receiverFirstName" />
        </a-form-model-item>
        <a-form-model-item label="名" prop="receiverLastName">
          <a-input v-model="form.receiverLastName" />
        </a-form-model-item>
      </div>

      <div class="form-item-wrapper">
        <a-form-model-item label="省份/州" prop="receiverState">
          <a-input v-model="form.receiverState" />
        </a-form-model-item>
        <a-form-model-item label="城市" prop="receiverCity">
          <a-input v-model="form.receiverCity" />
        </a-form-model-item>
      </div>

      <div class="form-item-wrapper">
        <a-form-model-item label="地址1" prop="receiverAddress1">
          <a-input v-model="form.receiverAddress1" />
        </a-form-model-item>
        <a-form-model-item label="地址2" prop="receiverAddress2">
          <a-input v-model="form.receiverAddress2" />
        </a-form-model-item>
      </div>

      <div class="form-item-wrapper">
        <a-form-model-item label="邮政编号" prop="receiverPostalCode">
          <a-input v-model="form.receiverPostalCode" />
        </a-form-model-item>
        <a-form-model-item label="国家" prop="countryCode">
          <a-select v-model="form.receiverCountryCode"
                    style="width: 100%;"
                    show-search
                    option-filter-prop="children"
                    :filter-option="countryOptionFilter"
                    :allowClear="true">
            <a-select-option :value="item.code" v-for="item in countryOptions" :key="item.code">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </div>

      <div class="form-item-wrapper">
        <a-form-model-item label="公司" prop="receiverCompany">
          <a-input v-model="form.receiverCompany" />
        </a-form-model-item>
        <a-form-model-item label="传真" prop="receiverFax">
          <a-input v-model="form.receiverFax" />
        </a-form-model-item>
      </div>

      <div class="form-item-wrapper">
        <a-form-model-item label="备注" prop="adminRemark" class="app_required-input">
          <a-textarea v-model="form.adminRemark" :auto-size="{ minRows: 3 }" />
        </a-form-model-item>
      </div>
    </a-form-model>

    <a-divider orientation="left">可以为订单设置产品</a-divider>

    <div style="margin-bottom: 10px; align-items: center;" class="left-right-content">
      <div class="full">
        <a-button @click="handleSkuSelect">选择SKU</a-button>

        <a-tooltip>
          <template slot="title">请上传Excel或CSV文件，不要标题行，列1：SKU，列2：数量。</template>
          <a-button :loading="saveButtonLoading" @click="handleSkuSelectFromFile">从文件导入</a-button>
        </a-tooltip>

        <a-button type="danger" @click="handleRemoveAllProducts">移除所有SKU</a-button>
      </div>
      <div class="font-mini text-primary">{{products.length}} 个SKU，共 {{skuTotal}} 件产品。</div>
    </div>

    <a-table
        size="middle"
        rowKey="id"
        :columns="productColumns"
        :data-source="products"
        :pagination="false"
        :scroll="{ y: 500 }"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" type="danger" icon="delete" @click="handleRemoveProduct(row)"></a-button>
      </div>
      <div slot="image" slot-scope="imageUrl" class="image-item" :style="{'background-image': `url(${imageUrl})`}"></div>
      <div slot="total" slot-scope="total, row">
        <a-input-number :min="1" v-model="row.total"></a-input-number>
      </div>
    </a-table>

    <stock-product-select ref="skuSelector"></stock-product-select>
  </drawer>
</template>

<script>

import { getCountries } from '@/http/api/order-delivery'
import { addInternalOrder, updateInternalOrder } from '@/http/api/order'
import { checkSku } from '@/http/api/stock-product'
import kit from '@/utils/kit'
import selectFiles from '@/utils/select-file'
import StockProductSelect from '@/components-business/stock-product-select'
import { listWarehouseEnabled } from '@/http/api/warehouse'

export default {
  components: { StockProductSelect },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      form: {
        email: null,
        adminRemark: null,
        receiverFirstName: null,
        receiverLastName: null,
        receiverPhone: null,
        receiverCompany: null,
        receiverCountryCode: null,
        receiverCountry: null,
        receiverState: null,
        receiverCity: null,
        receiverAddress1: null,
        receiverAddress2: null,
        receiverPostalCode: null,
        receiverFax: null
      },
      products: [],
      model: null,
      countryOptions: [],
      productColumns: [
        { title: '', width: 70, scopedSlots: { customRender: 'action' } },
        { title: '图片', dataIndex: 'imageUrl', width: 130, scopedSlots: { customRender: 'image' } },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '数量', dataIndex: 'total', width: 200, scopedSlots: { customRender: 'total' } }
      ],
      warehouses: []
    }
  },
  computed: {
    skuTotal () {
      let t = 0
      for (const item of this.products) {
        t += item.total
      }
      return t
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
        for (const p of model.productItems) {
          this.products.push({
            sku: p.sku,
            imageUrl: p.imageUrl,
            total: p.total
          })
        }
      }
      if (this.countryOptions.length === 0) {
        getCountries().success(resp => (this.countryOptions = resp.data)).send()
      }

      listWarehouseEnabled()
        .success(resp => {
          const arr = []
          for (const item of resp.data) {
            if (item.address) {
              arr.push(item)
            }
          }
          this.warehouses = arr
        })
        .send()
    },
    onSave () {
      this.saveButtonLoading = true
      this.closable = false
      const p = Object.assign({}, this.form)
      let api = addInternalOrder
      if (this.model) {
        api = updateInternalOrder
        p.id = this.model.id
      }
      const country = this.countryOptions.find(item => item.code === this.form.receiverCountryCode)
      if (country) {
        p.receiverCountry = country.name
      }
      p.products = []
      for (const item of this.products) {
        p.products.push({ sku: item.sku, total: item.total })
      }
      api()
        .complete(() => {
          this.saveButtonLoading = false
          this.closable = true
        })
        .success(() => {
          this.$message.success('保存成功。')
          this.visible = false
          this.$emit('saved')
        })
        .send(p)
    },
    onClose () {
      this.$refs.form.resetFields()
      this.model = null
      this.products = []
    },
    countryOptionFilter (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    handleSkuSelect () {
      this.$refs.skuSelector.open(selected => {
        for (const item of selected) {
          this.products.push({
            imageUrl: item.imageUrl,
            sku: item.sku,
            total: 1
          })
        }
      })
    },
    handleRemoveProduct (row) {
      kit.arr.removeItem(this.products, row)
    },
    handleRemoveAllProducts () {
      this.$confirm({
        title: '确认',
        content: '确定要移除所有SKU吗？',
        onOk: () => {
          this.products = []
        }
      })
    },
    handleSkuSelectFromFile () {
      selectFiles({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        multi: false,
        select: (files, errors) => {
          this.saveButtonLoading = true
          checkSku()
            .complete(() => (this.saveButtonLoading = false))
            .success(resp => {
              for (const item of resp.data.found) {
                this.products.push({
                  imageUrl: item.imageUrl,
                  sku: item.sku,
                  total: item.total
                })
              }
              if (resp.data.notFound.length > 0) {
                this.$info({
                  title: '提示',
                  content: '一些SKU不存在系统中，点击确定后会打开不存在的SKU页面，请确认。',
                  onOk: () => {
                    const arr = []
                    arr.push(['SKU', 'Total'])
                    for (const item of resp.data.notFound) {
                      arr.push([item.sku, item.total])
                    }
                    const csvContent = 'data:text/csv;charset=utf-8,' + arr.map(e => e.join(',')).join('\n')
                    window.open(encodeURI(csvContent))
                  }
                })
              }
            })
            .send(files[0])
        }
      })
    },
    handleFillAddressFromWarehouse (e) {
      const w = this.warehouses.find(item => item.id === e.key)
      const addr = JSON.parse(w.address)
      this.form.receiverPhone = addr.phone
      this.form.receiverAddress1 = addr.addr1
      this.form.receiverAddress2 = addr.addr2
      this.form.receiverFirstName = addr.name
      this.form.receiverCity = addr.city
      this.form.receiverState = addr.state
      this.form.receiverCountryCode = addr.countryCode
      this.form.receiverCompany = addr.company
      this.form.receiverPostalCode = addr.postal
      this.form.email = addr.email
    }
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.form-item-wrapper {
  display: flex;
}
.form-item-wrapper @{deep} .ant-form-item {
  flex: .5;
}
.form-item-wrapper @{deep} .ant-form-item + .ant-form-item {
  margin-left: 20px;
}
</style>
