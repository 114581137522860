<template>
  <div class="wrapper">
    <div class="title">TikTok店铺API授权</div>
    <a-input-search enter-button="授权" placeholder="店铺编号" size="large" v-model="shopCode" @search="handleClick" :loading="loading" />
    <p class="message font-mini" style="margin-top: 10px;">
      <span v-if="result === 'fail'" class="text-danger">授权失败。</span>
      <span v-else-if="result === 'success'" class="text-success">授权成功。</span>
      <span v-else-if="result === 'none'" class="text-warning">店铺不存在。</span>
    </p>
  </div>
</template>

<script>
import defineApi from '@/http/define-api'
import http from '@/http/application-api'

const getShopAuthUrl = defineApi((config, code) => {
  config.disableBaseUrl = true
  config.params = { shopCode: code }
  http.get('/api/tiktok_shop/auth_url', config)
})

export default {
  data () {
    return {
      loading: false,
      shopCode: null
    }
  },
  computed: {
    result () {
      return this.$route.query.r
    },
    shopCodeParam () {
      return this.$route.query.code
    }
  },
  methods: {
    handleClick () {
      this.loading = true
      getShopAuthUrl()
        .complete(() => (this.loading = false))
        .success(resp => {
          if (resp.data) {
            window.location = resp.data
          } else {
            this.$message.error('店铺不存在。')
          }
        })
        .send(this.shopCode)
    }
  },
  mounted () {
    this.shopCode = this.shopCodeParam || null
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 600px;
    padding: 30px 50px;
    margin: 100px auto;
    background-color: #f9f9f9;
    border: solid 1px #eee;
    border-radius: 10px;
  }
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
</style>
