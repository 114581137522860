import defineApi from '../define-api'
import http from '../application-api'

export const uploadFile = defineApi((config, file, folderId) => {
  const form = new FormData()
  form.append('file', file)
  form.append('folderId', folderId)
  config.data = form
  return http.upload('/file/upload', config)
})

export const uploadCropFile = defineApi((config, id, file, replaceOrigin) => {
  const form = new FormData()
  form.append('file', file)
  form.append('id', id)
  form.append('replaceOrigin', replaceOrigin)
  config.data = form
  return http.upload('/file/upload/crop', config)
})

export const listFiles = defineApi((config, params) => {
  config.params = params
  http.load('/file/list', config)
})

export const deleteFiles = defineApi((config, idList) => {
  config.data = idList
  http.post('/file/delete', config)
})

/**
 * 移动文件到指定目录
 * @param idList {Array} 文件id列表
 * @param folderId {String} 文件夹id
 */
export const moveFilesToFolder = defineApi((config, idList, folderId) => {
  config.params = {
    idList: idList.join(','),
    folderId: folderId
  }
  http.post('/file/move', config)
})

export const updateFileName = defineApi((config, id, name) => {
  config.params = {
    id: id,
    name: name
  }
  http.post('/file/update_name', config)
})

/**
 * 获取通过文件id来访问图片的url地址。
 * @param id {String} 文件id
 * @param thumb {Boolean} true表示小图，false表示原图
 * @return {string}
 */
export const getUrlByFileId = function (id, thumb) {
  return http.baseUrl + `/file/image/by_id?id=${id}&thumb=${!!thumb}`
}
