<!--
  this.$refs.productSelect.open(products => {
    const idList = products.map(p => p.id)
  }, this.products, 100)

  参数1：确定选择后的回调函数，可以得到选择的产品列表。
  参数2：提供一个产品列表，作为默认选中的产品。
  参数3：最多可以选择多少个产品。
-->
<template>
  <a-modal
      title="选择库存产品"
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
      width="80%"
      :body-style="{height: '500px'}"
      :dialog-style="{top: '50px'}"
      wrap-class-name="stock-product-select-dialog"
  >
    <div class="body">
      <div slot="top">
        <a-form-model layout="inline" :model="searchParams">
          <div>
            <a-form-model-item label="标题">
              <a-input v-model="searchParams.title" placeholder="标题关键字" style="width: 350px;" :allow-clear="true" />
            </a-form-model-item>
            <a-form-model-item label="标签">
              <a-input v-model="searchParams.tags" :allow-clear="true" style="width: 120px;" />
            </a-form-model-item>
            <a-form-model-item label="仓库">
              <a-select v-model="searchParams.warehouseId" style="width: 170px;" :allow-clear="true">
                <a-select-option :value="item.id" v-for="item in warehouseOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="库存状态">
              <a-select v-model="searchParams.stockStatus" style="width: 114px;">
                <a-select-option :value="item.id" v-for="item in stockOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-button icon="search" type="primary" @click="reloadProducts">查询</a-button>
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="SKU">
              <a-input v-model="searchParams.sku" :allow-clear="true" style="width: 885px;" placeholder="多个SKU可用空格，逗号或换行分隔" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <div class="center">


        <!-- 待选择项目 -->
        <div class="product-container left">
          <div class="header">
            待选产品
            <a-button
                size="small"
                icon="arrow-right"
                style="float: right; margin-top: 10px;"
                :disabled="products.length === 0 || !selectable"
                @click="handleTransferAllToRight"
            >
              全部
            </a-button>
          </div>
          <div class="list">
            <div class="product-list">
              <div v-for="p in products" :key="p.id" class="product-item">
                <div class="product-wrapper">
                  <div class="image-item small" v-lazy:background-image="p.imageUrl"></div>
                  <div class="product-content">
                    <div class="left-right-content">
                      <div class="font-large text-primary" style="flex: .7;">{{p.sku}}</div>
                      <div class="font-mini" style="text-align: left;">
                        <span class="text-danger">库存：{{p.quantity}}</span>
                        <span class="text-normal" style="margin-left: 16px;">仓库：{{p.warehouseTotal}}</span>
                      </div>
                    </div>
                    <div class="font-mini text-muted text-overflow" style="width: 400px;">{{p.title}}</div>
                  </div>
                  <div class="buttons">
                    <a-button
                        v-if="!isSelected(p)"
                        size="small"
                        icon="arrow-right"
                        @click="handleTransferToRight(p)"
                        :disabled="!selectable"
                    >
                    </a-button>
                    <span v-else style="color: #2f790e;">已选择</span>
                  </div>
                </div>
              </div>
            </div>
            <loading :loading="page.loading"></loading>
          </div>
          <div class="bottom">
            <pagination
                :total="page.total"
                :start.sync="page.start"
                :limit.sync="page.limit"
                :show-size="false"
                size="small"
                style="padding: 3px 0 0 0;"
            >
            </pagination>
          </div>
        </div>


        <!-- 已经选择的项目 -->
        <div class="product-container right">
          <div class="header">
            <a-button
                size="small"
                icon="arrow-left"
                type="danger"
                @click="handleTransferAllToLeft"
                style="margin-right: 10px;"
            >
              全部
            </a-button>
            已选产品
            <span style="font-size: 12px; color: #bb3e30;">
              （已选择{{productsSelected.length}}个，最多能选择{{maxSelectCount}}个）
            </span>
          </div>
          <div class="list">
            <div class="product-list">
              <div v-for="p in productsSelected" :key="p.id" class="product-item">
                <div class="product-wrapper">
                  <div class="buttons" style="width: auto;">
                    <a-button size="small" type="danger" icon="arrow-left" @click="handleTransferToLeft(p)"></a-button>
                  </div>
                  <div class="image-item small" v-lazy:background-image="p.imageUrl"></div>
                  <div class="product-content">
                    <div class="left-right-content">
                      <div class="font-large text-primary" style="flex: .7;">{{p.sku}}</div>
                      <div class="font-mini" style="text-align: left;">
                        <span class="text-danger">库存：{{p.quantity}}</span>
                        <span class="text-normal" style="margin-left: 16px;">仓库：{{p.warehouseTotal}}</span>
                      </div>
                    </div>
                    <div class="font-mini text-muted text-overflow" style="width: 400px;">{{p.title}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleCancel(true)">取消并清空已选</a-button>
      <a-button type="primary" @click="handleOk">确定选择</a-button>
    </template>
  </a-modal>
</template>

<script>
import kit from '@/utils/kit'
import { listStockProducts } from '@/http/api/stock-product'
import { listWarehouseEnabled } from '@/http/api/warehouse'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      searchParams: {
        warehouseId: null,
        stockStatus: 'all',
        title: null,
        tags: null,
        sku: null
      },
      products: [],
      productsSelected: [],
      page: {
        total: 0,
        start: 0,
        limit: 50,
        loading: false
      },
      maxSelectCount: 1000,
      warehouseOptions: [],
      stockOptions: [
        { id: 'all', name: '所有' },
        { id: 'empty', name: '空库存' },
        { id: 'notEmpty', name: '有库存' }
      ]
    }
  },
  watch: {
    'page.start' () {
      this.loadProducts()
    }
  },
  computed: {
    selectable () {
      return this.productsSelected.length < this.maxSelectCount
    }
  },
  methods: {
    /**
     * @param selectCallback {Function} 可以得到已选产品列表
     * @param [selectedProducts] {Array}
     * @param [maxSelectCount] {Number} default 1000
     */
    open (selectCallback, selectedProducts, maxSelectCount) {
      this.selectCallback = selectCallback
      this.maxSelectCount = maxSelectCount || 1000
      if (selectedProducts) {
        selectedProducts.forEach(p => {
          this.addToSelectedProducts(p)
        })
      }
      this.visible = true
      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }
      if (this.products.length === 0) {
        this.reloadProducts()
      }
    },
    handleOk () {
      if (this.selectCallback) {
        this.selectCallback(this.productsSelected)
      }
      this.handleCancel(true)
    },
    handleCancel (clearSelected) {
      if (clearSelected === true) {
        this.productsSelected = []
      }
      this.visible = false
    },
    reloadProducts () {
      if (this.page.start === 0) {
        this.loadProducts()
      } else {
        this.page.start = 0
      }
    },
    loadProducts () {
      if (this.listProductCancel) {
        this.listProductCancel.cancel()
      }
      const p = Object.assign({
        start: this.page.start,
        limit: this.page.limit
      }, this.searchParams)
      this.page.loading = true
      this.listProductCancel = listStockProducts()
        .complete(() => {
          this.page.loading = false
          this.listProductCancel = null
        })
        .success(resp => {
          this.page.total = resp.data.total
          this.products = resp.data.records
        })
        .send(p)
    },
    handleTransferToRight (product) {
      if (this.selectable) {
        this.addToSelectedProducts(product)
      }
    },
    handleTransferToLeft (product) {
      kit.arr.removeItem(this.productsSelected, product)
    },
    handleTransferAllToRight () {
      this.products.forEach(p => {
        this.handleTransferToRight(p)
      })
    },
    handleTransferAllToLeft () {
      this.$confirm({
        title: '确认',
        content: '确定要移除所有已选产品吗？',
        onOk: () => {
          this.productsSelected = []
        }
      })
    },
    addToSelectedProducts (data) {
      if (!this.isSelected(data)) {
        this.productsSelected.push(Object.assign({}, data))
      }
    },
    isSelected (data) {
      return this.productsSelected.findIndex(item => item.id === data.id) >= 0
    }
  }
}
</script>

<style lang="less">
.stock-product-select-dialog .ant-modal-body {
  position: relative !important;
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.body {
  display: flex;
  flex-direction: column;
  @padding: 20px;
  position: absolute;
  left: @padding;
  top: @padding;
  right: @padding;
  bottom: @padding;
}
.center {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  .product-container {
    flex: .5;
    position: relative;
    border: solid 1px #eee;
    &:first-of-type {
      margin-right: 20px;
    }
    &.left {
    }
    &.right {
      .list {
        bottom: 0;
      }
      .buttons {
        margin-right: 10px;
      }
    }
    @headerHeight: 40px;
    @footerHeight: 40px;
    .header {
      height: @headerHeight;
      line-height: @headerHeight;
      padding: 0 10px;
      border-bottom: solid 1px #eee;
      background-color: #f9f9f9;
      font-weight: bold;
      font-size: 14px;
    }
    .list {
      position: absolute;
      top: @headerHeight + 1;
      bottom: @footerHeight - 1;
      left: 0;
      right: 0;
      overflow: hidden;
    }
    .product-list {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
      .product-item {
        &:not(:first-of-type) {
          border-top: solid 1px #eee;
        }
        &:hover {
          background-color: #ececec;
          .checkbox-text {
            color: #337ab7;
          }
        }
        padding: 15px 10px;
      }
      .product-wrapper {
        display: flex;
        overflow: hidden;
        .product-content {
          flex: 1;
          padding: 0 10px;
        }
        .buttons {
          width: 80px;
          text-align: right;
        }
        .image-item {
          background-color: #fff;
        }
        .product-content {
          padding: 0 10px;
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: @footerHeight;
      line-height: @footerHeight;
      border-top: solid 1px #eee;
      background-color: #f9f9f9;
    }
  }
}
</style>
