import kit from '@/utils/kit'

export const STOCK_TRANSFER_STATUS_LOCK = 'lock'
export const STOCK_TRANSFER_STATUS_IN_STOCK = 'inStock'

export const STOCK_TRANSFER_STATUS_MAPPING = {
  [STOCK_TRANSFER_STATUS_LOCK]: '冻结',
  [STOCK_TRANSFER_STATUS_IN_STOCK]: '已入库'
}

export const STOCK_TRANSFER_STATUS_LIST = kit.obj.toArray(STOCK_TRANSFER_STATUS_MAPPING)
