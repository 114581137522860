<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    :width="800"
    :title="dialogTitle"
    @save="onSave"
    @close="onClose"
    saveButtonText="预览SKU"
  >
    <div class="wrapper">

      <a-form-model layout="inline" :model="filter">
        <a-form-model-item>
          <a-checkbox v-model="filter.hideSame">隐藏新旧相同</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox v-model="filter.hideSuccess">隐藏成功SKU</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox v-model="filter.hideError">隐藏失败SKU</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox v-model="filter.hideEmptyOldSku">隐藏空的旧SKU</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-button size="small" @click="$emit('openSkuManager')">打开SKU规则管理器</a-button>
        </a-form-model-item>
      </a-form-model>



      <div class="product-item" v-for="model in models" :key="model.id">
        <div>
          <a :href="model.productUrl" target="_blank"><strong>{{model.title}}</strong></a>
        </div>
        <div style="margin-top: 8px;">{{model.tags}}</div>

        <ul class="list-group" style="margin-top: 20px;">
          <li class="list-group-item">
            <div class="row-item">
              <div class="col">属性</div>
              <div class="col">旧SKU</div>
              <div class="col">新SKU</div>
            </div>
          </li>
          <li class="list-group-item" v-for="v in model.variants" :key="v.id"
              v-show="!(filter.hideSame && '相同' === v._newSku) &&
              !(filter.hideSuccess && v._newSkuGenerateStatus) &&
              !(filter.hideError && !v._newSkuGenerateStatus) &&
              !(filter.hideEmptyOldSku && !v.sku)">
            <div class="row-item">
              <div class="col">{{v.variant}}</div>
              <div class="col">{{v.sku}}</div>
              <div class="col">
                <span :class="{'text-danger': !v._newSkuGenerateStatus}">{{v._newSku}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <a-button slot="extra-button" @click="handleExportSkuCsv">导出新旧SKU</a-button>
  </drawer>
</template>

<script>
import { previewSku } from '@/http/api/shopify-product'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      shopId: null,
      models: [],
      filter: {
        hideSame: false, // 隐藏旧SKU和新SKU相同的记录
        hideSuccess: false, // 隐藏生成SKU成功的记录
        hideError: false, // 隐藏生成SKU失败的记录
        hideEmptyOldSku: false // 隐藏没有旧SKU的记录
      }
    }
  },
  watch: {
  },
  computed: {
    dialogTitle () {
      if (this.models.length > 0) {
        return `正在预览${this.models.length}个产品的SKU`
      } else {
        return 'SKU预览'
      }
    }
  },
  methods: {
    open (shopId, models) {
      this.shopId = shopId
      const arr = []
      for (const item of models) {
        const m = {
          id: item.id,
          title: item.title,
          productUrl: item.productUrl,
          tags: item.tags,
          shopId: shopId,
          variants: []
        }
        for (const v of item.variants) {
          m.variants.push(Object.assign({
            _newSku: null,
            _newSkuGenerateStatus: false
          }, v))
        }
        arr.push(m)
      }

      this.models = arr

      this.visible = true
    },
    onClose () {
      this.shopId = null
      this.model = null
      this.variants = []
    },
    onSave () {
      const params = []
      for (const m of this.models) {
        const data = {
          shopId: this.shopId,
          productId: m.id,
          tags: m.tags,
          variants: []
        }
        for (const v of m.variants) {
          const variant = {
            id: v.id,
            optionNames: [],
            values: []
          }
          for (const opt of v.options) {
            variant.optionNames.push(opt.name)
            variant.values.push(opt.value)
          }
          data.variants.push(variant)
        }
        params.push(data)
      }


      this.saveButtonLoading = true
      previewSku()
        .complete(() => (this.saveButtonLoading = false))
        .success(resp => {
          this.$message.success('生成成功，请检查。')
          const result = resp.data

          for (const m of this.models) {
            const tmp = result[m.id]
            for (const v of m.variants) {
              const item = tmp[v.id]
              v._newSkuGenerateStatus = item.success
              v._newSku = item.message
              if (v._newSku === v.sku) {
                v._newSku = '相同'
              }
            }
          }
        })
        .send(params)
    },
    handleExportSkuCsv () {
      let csv = ''
      csv += 'Old Sku,New Sku, Product Id\n'
      for (const m of this.models) {
        for (const v of m.variants) {
          if (this.filter.hideSame && v._newSku === '相同') {
            continue
          }
          if (this.filter.hideSuccess && v._newSkuGenerateStatus) {
            continue
          }
          if (this.filter.hideError && !v._newSkuGenerateStatus) {
            continue
          }
          if (this.filter.hideEmptyOldSku && !v.sku) {
            continue
          }
          csv += `${v.sku},${v._newSku},${m.id}\n`
        }
      }
      const content = new Blob(['\uFEFF' + csv])
      const urlObject = window.URL || window.webkitURL || window
      const url = urlObject.createObjectURL(content)
      const el = document.createElement('a')
      el.href = url
      el.download = 'SKU.csv'
      el.click()
      urlObject.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="less" scoped>
.product-item + .product-item {
  margin-top: 30px;
}
.product-item {
  padding-top: 20px;
  border-top: solid 1px #eee;
}
.row-item {
  display: flex;
  .col {
    width: 33.33%;
    max-width: 33.33%;
  }
}
</style>
