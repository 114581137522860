/**
 * @author jiangxingshang
 * @date 2023/3/28
 */

export const appendSupplierCodeToSku = function (sku, code) {
  return sku + '@' + code
}

export const removeSupplierCodeFromSku = function (sku) {
  if (sku) {
    return sku.split('@')[0]
  } else {
    return sku
  }
}

export const splitSkuAndSupplierCode = function (sku) {
  if (sku) {
    const tmp = sku.split('@')
    const obj = {}
    obj.sku = tmp[0]
    obj.supplierCode = tmp.length >= 2 ? tmp[1] : null
    return obj
  } else {
    return { sku: null, supplierCode: null }
  }
}
