<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="handleRemove" type="danger">移出待发货</a-button>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
    >
      <div slot="number" slot-scope="value, item">
        {{value}}
        <a-tooltip placement="top" v-if="item.remark">
          <template slot="title">
            <span>有备注</span>
          </template>
          <a-icon type="exception" style="color: #bb3e30; margin-left: 6px;" />
        </a-tooltip>
        <a-tooltip placement="top" v-if="item.delay">
          <template slot="title">
            <span>工厂延迟处理</span>
          </template>
          <a-icon type="fall" style="color: #3679c9; margin-left: 6px;" />
        </a-tooltip>
      </div>
      <div slot="image" slot-scope="v, r">
        <div class="image-item" v-lazy:background-image="r.getFirstImage()" @click="$imageModal.open(getDisplayImages(r))">
          <span v-if="r.getImages().length > 1" class="total">{{r.getImages().length}}</span>
        </div>
      </div>
      <div slot="limitation" class="font-mini" slot-scope="v, item">
        <div v-if="item.dayNewToAccept > 0">
          <span v-if="item.confirmTime">接单用时：{{item.dayNewToAccept}}天</span>
          <span v-else class="text-danger">未接单（已过{{item.dayNewToAccept}}天）</span>
        </div>
        <div v-if="item.dayAcceptToDelivery > 0">
          <span v-if="item.deliveryTime">接单到发货用时：{{item.dayAcceptToDelivery}}天</span>
          <span v-else class="text-danger">未发货（已过{{item.dayAcceptToDelivery}}天）</span>
        </div>
      </div>
    </a-table>

    <div slot="bottom" style="text-align: center; padding: 10px 0; border-top: solid 1px #eee; background-color: #fff;">
      <a-form-model layout="inline" :model="submitForm">
        <a-form-model-item prop="trackNo" label="运单号">
          <a-input v-model="submitForm.trackNo"></a-input>
        </a-form-model-item>

        <a-form-model-item label="物流公司">
          <a-select v-model="submitForm.logisticsId" style="width: 160px;">
            <a-select-option :value="item.id" v-for="item in logisticsOptions" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" :loading="submitting" @click="handleSubmit">提交</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

  </app-page>
</template>

<script>
import { ROUTE_SUPPLIER_DELIVERY_READY } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listProduceItem, delivery, removeFromDeliveryList } from '@/http/api/delivery-produce-item'
import SupplierProduceItem from '@/model/supplier-produce-item'

export default {
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_SUPPLIER_DELIVERY_READY,
      pageLoading: false,
      dataLoading: false,
      dataList: [],
      dataColumns: [
        { title: '图片', width: 90, scopedSlots: { customRender: 'image' } },
        { title: '编号', dataIndex: 'number', width: 120, scopedSlots: { customRender: 'number' } },
        { title: '规格', dataIndex: 'variant' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: '时效', width: 160, scopedSlots: { customRender: 'limitation' } },
        { title: '数量', dataIndex: 'quantity', width: 80 },
        { title: '生产费用', dataIndex: 'price', width: 80 },
        { title: '下单日期', dataIndex: 'createTime', width: 140 },
        { title: '供应商', dataIndex: 'supplierName', width: 120 }
      ],
      selectedRowKeys: [],
      submitForm: {
        trackNo: null,
        logisticsId: '100012'
      },
      submitting: false,
      logisticsOptions: [
        { id: '100012', name: '顺丰速运' },
        { id: '190157', name: '圆通' },
        { id: '190324', name: '申通' },
        { id: 'other', name: '其他' }
      ]
    }
  },
  methods: {
    handleSelectChange (selectedKeys) {
      this.selectedRowKeys = selectedKeys
    },
    loadData () {
      this.loading = true
      listProduceItem()
        .complete(() => (this.loading = false))
        .success(resp => {
          const arr = []
          resp.data.forEach(item => {
            arr.push(new SupplierProduceItem(item))
          })
          this.dataList = arr
        })
        .send()
    },
    getDisplayImages (record) {
      const remarks = []
      if (record.remark) remarks.push(record.remark)
      if (record.skuRemark) remarks.push(record.skuRemark)
      const arr = []
      for (const url of record.getImages()) {
        const item = {
          url: url,
          infos: [
            { name: '订单号', value: record.number },
            { name: 'SKU', value: record.sku },
            { name: '备注', value: remarks.join('，'), type: 'remark' }
          ]
        }
        if (record.dayNewToAccept > 0 && !record.confirmTime) {
          item.infos.push({ name: '未接单', value: `已过${record.dayNewToAccept}天`, type: 'danger' })
        }
        if (record.dayAcceptToDelivery > 0 && !record.deliveryTime) {
          item.infos.push({ name: '未发货', value: `已过${record.dayAcceptToDelivery}天`, type: 'danger' })
        }
        arr.push(item)
      }
      return arr
    },
    handleSubmit () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择操作的行。')
      } else {
        const arr = []
        for (const item of this.dataList) {
          if (this.selectedRowKeys.includes(item.id)) {
            arr.push({ deliveryId: item.deliveryId, produceItemId: item.id })
          }
        }
        const data = {
          trackNo: this.submitForm.trackNo,
          logisticsCode: this.submitForm.logisticsId,
          logisticsName: this.logisticsOptions.find(a => a.id === this.submitForm.logisticsId).name,
          items: arr
        }
        this.submitting = true
        delivery()
          .complete(() => (this.submitting = false))
          .success(() => {
            this.$message.success('发货成功。')
            this.loadData()
            this.submitForm.trackNo = null
          })
          .send(data)
      }
    },
    handleRemove () {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择操作的行。')
      } else {
        const idArr = []
        for (const item of this.dataList) {
          if (this.selectedRowKeys.includes(item.id)) {
            idArr.push(item.deliveryId)
          }
        }
        this.pageLoading = true
        removeFromDeliveryList()
          .complete(() => (this.pageLoading = false))
          .success(() => {
            this.$message.success('操作成功。')
            this.loadData()
          })
          .send(idArr.join(','))
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
