<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="库存预警编辑"
    @save="onSave"
    @close="onClose"
  >

    <div class="section sku">
      <div class="head">
        <span class="head-title">
          SKU列表
          <span v-show="skuProducts.length > 0">（{{skuProducts.length}}）</span>
        </span>
        <a-button type="primary" size="small" @click="handleSelectSku">选择</a-button>
        <a-button type="danger" size="small" @click="handleRemoveSelectedSku">移除选中</a-button>
        <a-button type="danger" size="small" @click="handleRemoveAllSku">移除所有</a-button>
      </div>
      <div class="filter">
        <a-input placeholder="输入关键字选择下面的列表" v-model="skuProductFilterKeyword" />
        <div class="content-center font-mini text-success" style="padding: 5px 0;" v-show="selectedSkuProductsByFilterKeyword.length > 0">
          根据关键字选中了{{selectedSkuProductsByFilterKeyword.length}}个SKU
        </div>
      </div>
      <div class="body">
        <div class="sku-item" v-for="sp in skuProducts" :key="sp.id" :class="{'selected': skuProductFilterKeyword && sp.sku.includes(skuProductFilterKeyword)}">
          <div class="image-item small" v-lazy:background-image="sp.imageUrl"></div>
          <div class="content">{{sp.sku}}</div>
          <a-button icon="delete" size="small" type="danger"></a-button>
        </div>
      </div>
    </div>

    <div class="section alert">
      <div class="head">库存预警设置</div>
      <div class="body">
        <div class="warehouse-item title">
          <div class="col-1">仓库</div>
          <div class="col-2">预警阀值</div>
          <div class="col-3">开启预警</div>
        </div>
        <div class="warehouse-item" v-for="w in warehouseList" :key="w.warehouseId">
          <div class="col-1">{{w.warehouseName}}</div>
          <div class="col-2">
            <a-input-number v-model="w.minTotal" :min="0" />
          </div>
          <div class="col-3">
            <a-switch v-model="w.enabled" />
          </div>
        </div>
      </div>
    </div>

    <stock-product-select ref="productSelector"></stock-product-select>

  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import StockProductSelect from '@/components-business/stock-product-select'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import { update } from '@/http/api/stock-alert'
const MIN_TOTAL = 5

export default {
  components: { StockProductSelect },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      skuProducts: [],
      skuProductFilterKeyword: null,
      warehouseList: []
    }
  },
  watch: {
  },
  computed: {
    selectedSkuProductsByFilterKeyword () {
      if (this.skuProductFilterKeyword) {
        const arr = []
        for (const item of this.skuProducts) {
          if (item.sku.includes(this.skuProductFilterKeyword)) {
            arr.push(item)
          }
        }
        return arr
      } else {
        return []
      }
    }
  },
  methods: {
    /**
     *
     * @param [model] {Array | StockAlert}
     */
    open (model) {
      this.visible = true

      if (model) {
        if (Array.isArray(model)) {
          for (const item of model) {
            this.skuProducts.push({
              id: item.stockProductId,
              imageUrl: item.imageUrl,
              sku: item.sku
            })
          }
        } else {
          this.skuProducts.push({
            id: model.stockProductId,
            imageUrl: model.imageUrl,
            sku: model.sku
          })
          for (const item of this.warehouseList) {
            const config = model.stockAlertContent.alerts.find(a => a.warehouseId === item.warehouseId)
            if (config) {
              item.enabled = true
              item.minTotal = config.minTotal
            } else {
              item.enabled = false
            }
          }
        }
      }
    },
    onClose () {
      this.skuProducts = []
      this.skuProductFilterKeyword = null
      for (const item of this.warehouseList) {
        item.minTotal = MIN_TOTAL
        item.enabled = true
      }
    },
    onSave () {
      if (this.skuProducts.length === 0) {
        return this.$message.error('请选择要预警的SKU。')
      }
      if (this.warehouseList.length === 0) {
        return this.$message.error('预警仓库不能为空。')
      }
      const data = {
        stockProductIds: this.skuProducts.map(item => item.id),
        warehouseAlerts: []
      }
      for (const item of this.warehouseList) {
        if (item.enabled) {
          data.warehouseAlerts.push({
            warehouseId: item.warehouseId,
            minTotal: item.minTotal
          })
        }
      }

      this.closable = false
      this.saveButtonLoading = true
      update()
        .complete(() => {
          this.closable = true
          this.saveButtonLoading = false
        })
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved')
        })
        .send(data)
    },
    handleSelectSku () {
      this.$refs.productSelector.open(selected => {
        for (const item of selected) {
          this.skuProducts.push(item)
        }
      })
    },
    handleRemoveSelectedSku () {
      const idArr = []
      for (const item of this.selectedSkuProductsByFilterKeyword) {
        idArr.push(item.id)
      }
      for (const id of idArr) {
        kit.arr.remove(this.skuProducts, { id: id })
      }
    },
    handleRemoveAllSku () {
      this.skuProducts = []
    }
  },
  mounted () {
    listWarehouseEnabled()
      .success(resp => {
        const arr = []
        for (const item of resp.data) {
          arr.push({
            warehouseId: item.id,
            warehouseName: item.name,
            minTotal: 5,
            enabled: true
          })
        }
        this.warehouseList = arr
      })
      .send()
  }
}
</script>

<style lang="less" scoped>

@border: solid 1px #ddd;
.section + .section {
  margin-top: 20px;
}
.section {
  flex: .5;
  border: @border;
  border-radius: 5px;
  .head {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border-bottom: @border;
    font-weight: bold;
    .head-title {
      flex: 1;
      margin-right: 10px;
    }
  }
  .body {
    flex: 1;
    overflow-y: auto;
    min-height: 100px;
  }
}

.section.sku {
  .filter {
    padding: 10px;
  }
  .body {
    max-height: 300px;
    overflow-y: auto;
  }
}

.sku-item {
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: #f9f9f9;
  }
  &:not(:first-of-type) {
    border-top: solid 1px #eee;
  }
  .content {
    flex: 1;
    padding: 0 10px;
  }
  &.selected .content {
    font-weight: bold;
    color: #228018;
  }
}

.warehouse-item {
  display: flex;
  padding: 10px;
  align-items: center;
  &:not(:first-of-type) {
    border-top: solid 1px #eee;
  }
  .col-1 {
    flex: 1;
  }
  .col-2 {
    width: 120px;
  }
  .col-3 {
    width: 100px;
  }
}
</style>
