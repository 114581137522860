import defineApi from '../define-api'
import http from '../application-api'

export const listWarehouseEnabled = defineApi((config) => {
  http.get('/stock/warehouse/list/enabled', config)
})

export const listWarehouse = defineApi((config) => {
  http.get('/stock/warehouse/list', config)
})

export const addWarehouse = defineApi((config, data) => {
  config.data = data
  return http.post('/stock/warehouse/add', config)
})

export const updateWarehouse = defineApi((config, data) => {
  config.data = data
  return http.post('/stock/warehouse/update', config)
})

