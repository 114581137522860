/*
    对系统各页面进行路由配置，路由必须都提供唯一的名称，路由跳转建议使用name而不是path。
    每个相关模块的路由都新建一个配置文件在./config目录下，然后在本文件集中配置。
    /////////////////////////////////////
    /////////  路由的特殊设置  ////////////
    /////////////////////////////////////
    安全验证：
    ------------------------
    默认所有路由都是需要验证用户是否登录，如果你需要将某个路由跳过安全验证，请做如下配置：
    meta: {auth: false}

    路由访问：
    ------------------------
    有时候用户第一次打开系统时就直接访问深度路由，如：#/a/b/c，该路由前面还有两级父路由#/a和#/a/b，如果
    你不希望某个路由可以直接被用户打开，而是必须从它的父路由过来的，你可以做如下设置：
    meta: {fromParent: true}
    比如，用户必须在#/a/b跳转到#/a/b/c，如果从其他路由如：#/1/2 -> #/a/b/c 或者在浏览器直接打开#/a/b/c
    的话，页面就会被跳转到#/a/b/c的上一级路由，也就是#/a/b
 */

import Vue from 'vue'
import Router from 'vue-router'
import Security from './router-security'
import RouterBefore from './router-before'
import {
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_USER,
  ROUTE_WAREHOUSE,
  ROUTE_STOCK_PRODUCT,
  ROUTE_WAREHOUSE_STOCK_RECORD,
  ROUTE_ORDER_DELIVERY,
  ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE,
  ROUTE_STOCK_CHECK,
  ROUTE_STOCK_CHECK_ITEM,
  ROUTE_STOCK_TRANSFER,
  ROUTE_T17TRACK,
  ROUTE_ORDER_RETURN,
  ROUTE_AFTER_SALE_SESSION,
  ROUTE_AFTER_SALE_SESSION_DETAIL,
  ROUTE_ACCOUNTING,
  ROUTE_STOCK_ALERT,
  ROUTE_PRODUCT_ADD,
  ROUTE_SUPPLIER_PRODUCE_ITEM,
  ROUTE_ORDER,
  ROUTE_ORDER_DEMO,
  ROUTE_ORDER_DETAIL,
  ROUTE_SUPPLIER_DELIVERY,
  ROUTE_SUPPLIER_DELIVERY_DETAIL,
  ROUTE_EDM_EMAIL,
  ROUTE_EDM_ACTIVITY,
  ROUTE_EDM_ACTIVITY_EMAILS,
  ROUTE_ABANDONED_CHECKOUT,
  ROUTE_SUPPLIER_ORDER_DELIVERY,
  ROUTE_PRODUCT_SKU_GENERATOR,
  ROUTE_AIRWALLEX,
  ROUTE_ABROAD_UPLOAD_SKU,
  ROUTE_SKU_STOCK_TRANSFER,
  ROUTE_ROLE,
  ROUTE_SETTING_NOTIFY_USER,
  ROUTE_SUPPLIER,
  ROUTE_ORDER_DELIVERY_PREPARE,
  ROUTE_ANALYSIS_COST,
  ROUTE_ANALYSIS_STOCK_ALERT,
  ROUTE_ANALYSIS_SALE,
  ROUTE_ANALYSIS_PROFIT_LOSS,
  ROUTE_PROFIT_LOSS_SYNC,
  ROUTE_SHOP,
  ROUTE_SHOP_ORDER_SYNC,
  ROUTE_WAREHOUSE_PREPARE_USER,
  ROUTE_SUPPLIER_DELIVERY_READY,
  ROUTE_ORDER_IMPORT,
  ROUTE_SHIPPING_METHOD_ADVICE,
  ROUTE_PRODUCT_SKU_STYLE,
  ROUTE_INFLUENCER,
  ROUTE_INFLUENCER_DETAIL,
  ROUTE_ORDER_DEMO_DETAIL,
  ROUTE_ORDER_DELIVERY_ITEM,
  ROUTE_PARTNER,
  ROUTE_PARTNER_ADS_COST,
  ROUTE_ORDER_COMMISSION_UPDATE,
  ROUTE_INVENTORY_CHANGE_REQUEST,
  ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS,
  ROUTE_TEST
} from '@/router/router-constants'

import ShopApiTest from '@/pages/test'

import Home from '@/pages/home'
import Dashboard from '@/pages/dashboard'
import NotFound from '@/pages/404'
import Login from '@/pages/login'
import User from '@/pages/user'
import Role from '@/pages/user/role'

import Warehouse from '@/pages/stock/warehouse'
import StockProduct from '@/pages/stock/stock-product'
import WarehouseStockRecord from '@/pages/stock/warehouse-stock-records'
import StockCheck from '@/pages/stock/stock-check'
import StockCheckItem from '@/pages/stock/stock-check/check-detail'
import StockTransfer from '@/pages/stock/transfer'
import StockAlert from '@/pages/stock/alert'
import Abroad from '@/pages/stock/abroad'
import SkuStockTransfer from '@/pages/stock/sku-stock-transfer'
import InventoryChangeRequest from '@/pages/stock/inventory'
import InventoryChangeRequestProducts from '@/pages/stock/inventory/products'

import Order from '@/pages/order'
import OrderDemo from '@/pages/order/demo'
import OrderDetail from '@/pages/order/detail'
import AbandonedCheckout from '@/pages/order/abandoned'
import OrderAirwallex from '@/pages/order/airwallex'
import OrderImport from '@/pages/order/import'
import OrderCommissionUpdate from '@/pages/order/commission'

import OrderDelivery from '@/pages/delivery/order-delivery'
import OrderDeliveryItem from '@/pages/delivery/order-delivery-item'
import OrderDeliveryUpdateTrackingFromFile from '@/pages/delivery/update-tracking-from-file'
import T17track from '@/pages/delivery/t17track'
import OrderDeliveryPrepare from '@/pages/delivery/prepare'

import OrderReturn from '@/pages/aftersale/return'
import AfterSaleSession from '@/pages/aftersale/session'
import AfterSaleSessionDetail from '@/pages/aftersale/session/detail'

import ProductAdd from '@/pages/product/add'
import ProductSkuGenerator from '@/pages/product/sku-generator'
import SkuStyleGenerator from '@/pages/product/sku'

import Accounting from '@/pages/financial/accounting'

import SupplierProduceItem from '@/pages/supplier/produce-item/index'
import SupplierDelivery from '@/pages/supplier/delivery'
import SupplierDeliveryDetail from '@/pages/supplier/delivery/produce-items'
import SupplierDeliveryReady from '@/pages/supplier/produce-item/delivery-ready'

import EdmEmail from '@/pages/edm/email'
import EdmActivity from '@/pages/edm/activity'
import EdmActivityEmails from '@/pages/edm/activity/emails'

import SupplierOrderDelivery from '@/pages/supplier-module/self-delivery'
import Supplier from '@/pages/supplier'

import NotifyUser from '@/pages/setting/notify-user'

import AnalysisCost from '@/pages/data-analysis/cost'
import AnalysisStockAlert from '@/pages/data-analysis/stock-alert'
import AnalysisSale from '@/pages/data-analysis/sale'
import AnalysisProfit from '@/pages/data-analysis/profit'
import ProfitLossSync from '@/pages/data-analysis/profit/sync'

import Shop from '@/pages/shop'
import ShopOrderSync from '@/pages/shop/order-sync'

import ShippingMethodAdvice from '@/pages/setting/shipping-method-advice'

import TikTokAuth from '@/pages/tiktok-auth'

import { userStore } from '@/store'
import WarehousePrepareUser from '@/pages/stock/warehouse-prepare-user'

import Influencer from '@/pages/influencer'
import InfluencerDetail from '@/pages/influencer/detail'

import Partner from '@/pages/partner'
import PartnerAdsCost from '@/pages/partner/ads-cost'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'Root',
      path: '/',
      redirect: to => {
        return { name: userStore.getLoginUserDashboard() }
      }
    },
    { name: ROUTE_404, path: '/404', component: NotFound, meta: { auth: false } },
    { name: ROUTE_LOGIN, path: '/login', component: Login, meta: { auth: false } },
    { name: 'tiktok-auth', path: '/tiktok', component: TikTokAuth, meta: { auth: false } },
    {
      name: ROUTE_HOME,
      path: '/h',
      component: Home,
      children: [
        { name: ROUTE_DASHBOARD, path: 'dashboard', component: Dashboard, meta: { title: '分析' } },
        {
          name: ROUTE_USER,
          path: 'admin',
          component: User,
          meta: { title: '用户管理' },
          children: [
            {
              name: ROUTE_ROLE,
              path: 'role',
              component: Role,
              meta: { title: '用户角色' }
            }
          ]
        },
        {
          name: ROUTE_WAREHOUSE,
          path: 'warehouse',
          component: Warehouse,
          meta: { title: '仓库' }
        },
        {
          name: ROUTE_STOCK_PRODUCT,
          path: 'stock-product',
          component: StockProduct,
          meta: { title: '库存商品' },
          children: [
            {
              name: ROUTE_WAREHOUSE_STOCK_RECORD,
              path: ':stockProductId/warehouse-stock-record',
              component: WarehouseStockRecord,
              meta: { title: 'SKU库存记录' }
            },
            {
              name: ROUTE_ABROAD_UPLOAD_SKU,
              path: 'abroad',
              component: Abroad,
              meta: { title: '海外仓上传SKU' }
            }
          ]
        },
        {
          name: ROUTE_STOCK_CHECK,
          path: 'stock-check',
          component: StockCheck,
          meta: { title: '库存盘点' },
          children: [
            {
              name: ROUTE_STOCK_CHECK_ITEM,
              path: ':stockCheckId/check',
              component: StockCheckItem,
              meta: { title: '库存盘点明细' }
            }
          ]
        },
        {
          name: ROUTE_STOCK_TRANSFER,
          path: 'stock-transfer',
          component: StockTransfer,
          meta: { title: '库存迁移' }
        },
        {
          name: ROUTE_SKU_STOCK_TRANSFER,
          path: 'sku-stock-transfer',
          component: SkuStockTransfer,
          meta: { title: 'SKU库存转移' }
        },
        {
          name: ROUTE_STOCK_ALERT,
          path: 'stock-alert',
          component: StockAlert,
          meta: { title: '库存预警' }
        },
        {
          name: ROUTE_ORDER,
          path: 'order',
          component: Order,
          meta: { title: '客户订单' },
          children: [
            {
              name: ROUTE_ORDER_DETAIL,
              path: ':orderId/detail',
              component: OrderDetail,
              meta: { title: '订单详情' }
            },
            {
              name: ROUTE_ORDER_IMPORT,
              path: 'import',
              component: OrderImport,
              meta: { title: '导入订单' }
            }
          ]
        },
        {
          name: ROUTE_ORDER_DEMO,
          path: 'order-demo',
          component: OrderDemo,
          meta: { title: '寄样订单' },
          children: [
            {
              name: ROUTE_ORDER_DEMO_DETAIL,
              path: ':orderId/detail',
              component: OrderDetail,
              meta: { title: '订单详情' }
            }
          ]
        },
        {
          name: ROUTE_ABANDONED_CHECKOUT,
          path: 'abandoned-checkout',
          component: AbandonedCheckout,
          meta: { title: '弃单' }
        },
        {
          name: ROUTE_ORDER_DELIVERY,
          path: 'order-delivery',
          component: OrderDelivery,
          meta: { title: '订单发货' },
          children: [
            {
              name: ROUTE_ORDER_DELIVERY_UPDATE_TRACKING_FROM_FILE,
              path: 'update-tracking',
              component: OrderDeliveryUpdateTrackingFromFile,
              meta: { title: '更新物流信息' }
            }
          ]
        },
        {
          name: ROUTE_ORDER_DELIVERY_ITEM,
          path: 'order-delivery-item',
          component: OrderDeliveryItem,
          meta: { title: '补填发货成本' }
        },
        {
          name: ROUTE_ORDER_DELIVERY_PREPARE,
          path: 'order-delivery-prepare',
          component: OrderDeliveryPrepare,
          meta: { title: '物流配货' }
        },
        {
          name: ROUTE_T17TRACK,
          path: 't17track',
          component: T17track,
          meta: { title: 'T17track' }
        },
        {
          name: ROUTE_ORDER_RETURN,
          path: 'order-return',
          component: OrderReturn,
          meta: { title: '退货' }
        },
        {
          name: ROUTE_AFTER_SALE_SESSION,
          path: 'after-sale-session',
          component: AfterSaleSession,
          meta: { title: '售后服务' },
          children: [
            {
              name: ROUTE_AFTER_SALE_SESSION_DETAIL,
              path: ':email/detail',
              component: AfterSaleSessionDetail,
              meta: { title: '售后详情' }
            }
          ]
        },
        {
          name: ROUTE_ACCOUNTING,
          path: 'accounting',
          component: Accounting,
          meta: { title: '记账' }
        },
        {
          name: ROUTE_PRODUCT_ADD,
          path: 'product-add',
          component: ProductAdd,
          meta: { title: '上传产品' }
        },
        {
          name: ROUTE_PRODUCT_SKU_GENERATOR,
          path: 'product-sku-generator',
          component: ProductSkuGenerator,
          meta: { title: 'SKU生成' }
        },
        {
          name: ROUTE_SUPPLIER,
          path: 'supplier',
          component: Supplier,
          meta: { title: '供应商管理' }
        },
        {
          name: ROUTE_SUPPLIER_PRODUCE_ITEM,
          path: 'supplier-produce-item',
          component: SupplierProduceItem,
          meta: { title: '工厂订单' },
          children: [
            {
              name: ROUTE_SUPPLIER_DELIVERY_READY,
              path: 'delivery-ready',
              component: SupplierDeliveryReady,
              meta: { title: '工厂订单待发货' }
            }
          ]
        },
        {
          name: ROUTE_SUPPLIER_DELIVERY,
          path: 'supplier-delivery',
          component: SupplierDelivery,
          meta: { title: '供应商快递' },
          children: [
            {
              name: ROUTE_SUPPLIER_DELIVERY_DETAIL,
              path: 'produce-items/:trackNo',
              component: SupplierDeliveryDetail,
              meta: { title: '发货清单' }
            }
          ]
        },
        {
          name: ROUTE_EDM_EMAIL,
          path: 'edm-email',
          component: EdmEmail,
          meta: { title: '营销邮箱' }
        },
        {
          name: ROUTE_EDM_ACTIVITY,
          path: 'edm-activity',
          component: EdmActivity,
          meta: { title: '营销活动' },
          children: [
            {
              name: ROUTE_EDM_ACTIVITY_EMAILS,
              path: 'emails/:activityId',
              component: EdmActivityEmails,
              meta: { title: '营销活动详情' }
            }
          ]
        },
        {
          name: ROUTE_SUPPLIER_ORDER_DELIVERY,
          path: 'supplier-order-delivery',
          component: SupplierOrderDelivery,
          meta: { title: '厂商自发货' }
        },
        {
          name: ROUTE_AIRWALLEX,
          path: 'airwallex',
          component: OrderAirwallex,
          meta: { title: '空中云汇订单报告' }
        },
        {
          name: ROUTE_SETTING_NOTIFY_USER,
          path: 'notify-user',
          component: NotifyUser,
          meta: { title: '用户通知' }
        },
        {
          name: ROUTE_ANALYSIS_COST,
          path: 'analysis-cost',
          component: AnalysisCost,
          meta: { title: '费用分析' }
        },
        {
          name: ROUTE_ANALYSIS_STOCK_ALERT,
          path: 'analysis-stock-alert',
          component: AnalysisStockAlert,
          meta: { title: '库存预警' }
        },
        {
          name: ROUTE_ANALYSIS_SALE,
          path: 'analysis-sale',
          component: AnalysisSale,
          meta: { title: '销售分析' }
        },
        {
          name: ROUTE_ANALYSIS_PROFIT_LOSS,
          path: 'analysis-profit-loss',
          component: AnalysisProfit,
          meta: { title: '损益表' },
          children: [
            {
              name: ROUTE_PROFIT_LOSS_SYNC,
              path: 'sync',
              component: ProfitLossSync,
              meta: { title: '损益表同步记录' }
            }
          ]
        },
        {
          name: ROUTE_SHOP,
          path: 'shop',
          component: Shop,
          meta: { title: '店铺' },
          children: [
            {
              name: ROUTE_SHOP_ORDER_SYNC,
              path: ':shopId/order-sync',
              component: ShopOrderSync,
              meta: { title: '订单同步' }
            }
          ]
        },
        {
          name: ROUTE_WAREHOUSE_PREPARE_USER,
          path: 'warehouse-prepare-user',
          component: WarehousePrepareUser,
          meta: { title: '仓库配货员' }
        },
        {
          name: ROUTE_SHIPPING_METHOD_ADVICE,
          path: 'shipping-method-advice',
          component: ShippingMethodAdvice,
          meta: { title: '发货渠道尺寸重量建议配置' }
        },
        {
          name: ROUTE_PRODUCT_SKU_STYLE,
          path: 'sku-style',
          component: SkuStyleGenerator,
          meta: { title: 'SKU款号生成' }
        },
        {
          name: ROUTE_INFLUENCER,
          path: 'influencer',
          component: Influencer,
          meta: { title: '达人管理' },
          children: [
            {
              name: ROUTE_INFLUENCER_DETAIL,
              path: 'detail/:id',
              component: InfluencerDetail,
              meta: { title: '达人沟通' }
            }
          ]
        },
        {
          name: ROUTE_PARTNER,
          path: 'partner',
          component: Partner,
          meta: { title: '客户档案' }
        },
        {
          name: ROUTE_PARTNER_ADS_COST,
          path: 'partner-ads-cost',
          component: PartnerAdsCost,
          meta: { title: '客户广告消耗' }
        },
        {
          name: ROUTE_ORDER_COMMISSION_UPDATE,
          path: 'order-commission-update',
          component: OrderCommissionUpdate,
          meta: { title: '订单佣金更新' }
        },
        {
          name: ROUTE_INVENTORY_CHANGE_REQUEST,
          path: 'inventory-change-request',
          component: InventoryChangeRequest,
          meta: { title: '库存变更申请' },
          children: [
            {
              name: ROUTE_INVENTORY_CHANGE_REQUEST_PRODUCTS,
              path: 'products/:id',
              component: InventoryChangeRequestProducts,
              meta: { title: '商品清单' }
            }
          ]
        },
        {
          name: ROUTE_TEST,
          path: 'shop-api-test',
          component: ShopApiTest,
          meta: { title: 'Shop API Test' }
        }
      ]
    }
  ]
})

router = Security(router)
router = RouterBefore(router)

export default router
