import defineApi from '../define-api'
import http from '../application-api'

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/order_return/list', config)
})

export const listByOrder = defineApi((config, orderId) => {
  config.params = {
    orderId: orderId
  }
  http.get('/order_return/list/by_order', config)
})

export const add = defineApi((config, data) => {
  config.data = data
  http.post('/order_return/add', config)
})

export const update = defineApi((config, data) => {
  config.data = data
  http.post('/order_return/update', config)
})

export const deleteById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/order_return/delete', config)
})

/**
 * @param sku {String} 多个可以用逗号分割
 */
export const getStockProductBySku = defineApi((config, sku) => {
  config.params = {
    sku: sku
  }
  http.get('/order_return/get/return_item/by_sku', config)
})
