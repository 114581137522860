import defineApi from '../define-api'
import http from '../application-api'

export const listSupplier = defineApi((config) => {
  http.get('/supplier/list/options', config)
})

export const listAllSupplier = defineApi((config) => {
  http.get('/supplier/list', config)
})

export const listSupplierUser = defineApi((config, id) => {
  config.params = {
    supplierId: id
  }
  http.get('/supplier/users/by_id', config)
})

export const getSupplier = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/supplier/get_by_id', config)
})

export const addSupplier = defineApi((config, supplier) => {
  config.data = supplier
  return http.post('/supplier/add', config)
})

export const updateSupplier = defineApi((config, supplier) => {
  config.data = supplier
  return http.post('/supplier/update', config)
})

export const deleteSupplier = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/supplier/delete', config)
})
