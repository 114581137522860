import defineApi from '../define-api'
import http from '../application-api'
import { userStore } from '@/store'

export const loginValidateCode = defineApi((config) => {
  http.load('/login/validate_code', config)
})

export const getMyProfiles = defineApi((config) => {
  http.get('/admin/my', config)
})

/**
 * 发送登录请求，将服务端返回的用户信息保存到本地。
 * @type {Function}
 */
export const login = defineApi((config, phone, sms, orPassword) => {
  config = Object.assign({
    params: {
      phone: phone,
      sms: sms,
      password: orPassword
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/login', config)
})

export const wechatBind = defineApi((config, openId, phone) => {
  config = Object.assign({
    params: {
      openId: openId,
      phone: phone
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/wechat/account_bind', config)
})

export const wechatLogin = defineApi((config, openId) => {
  config = Object.assign({
    params: {
      openId: openId
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/wechat/login', config)
})

/**
 * 发送登出请求，请求成功后，移除本地登录用户信息，并新获取sessionId后再将回调函数交给外部。
 * @type {Function}
 */
export const logout = defineApi((config) => {
  const successCallback = config.success
  config.success = function (resp) {
    userStore.removeUserInfo()
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/logout', config)
})

export const getLoginCode = defineApi((config, phone) => {
  config.params = {
    phone: phone
  }
  http.post('/login/code', config)
})

export const getSessionsByUsername = defineApi((config, username) => {
  config.params = { username: username }
  return http.get('/admin/sessions', config)
})

export const killUserSession = defineApi((config, sessionId) => {
  config.params = { sessionId: sessionId }
  http.post('/admin/session/kill', config)
})

export const updatePassword = defineApi((config, oldPassword, newPassword) => {
  config.params = {
    oldPassword: oldPassword,
    newPassword: newPassword,
    logoutOnSuccess: false
  }
  http.post('/admin/update_password', config)
})

export const getValidateCodeForForgotPassword = defineApi((config, username) => {
  config.params = {
    username: username
  }
  http.get('/password/forgot/code', config)
})

export const updateForgotPassword = defineApi((config, username, password, code) => {
  config.params = {
    username: username,
    password: password,
    code: code
  }
  http.post('/password/forgot/reset', config)
})

export const managerList = defineApi((config, params) => {
  config.params = params
  return http.load('/admin/list', config)
})

/**
 * @param manager
 *   username {string}
 *   password {string}
 *   name {string}
 *   enabled {boolean}
 *   sex {int} 1或0
 *   [title] {string}
 *   [shopId] {string}
 * @type {Function}
 */
export const addManager = defineApi((config, manager) => {
  config.data = manager
  http.post('/admin/add', config)
})


export const addSupplierUser = defineApi((config, supplierUser) => {
  config.data = supplierUser
  http.post('/admin/supplier/user/add', config)
})

/**
 * @param manager
 *   id {string}
 *   password {string} optional
 *   name {string}
 *   enabled {boolean}
 *   sex {int} 1或0
 *   [title] {string}
 *   [shopId] {string}
 * @type {Function}
 */
export const updateManager = defineApi((config, manager) => {
  config.data = manager
  http.post('/admin/update', config)
})

export const deleteManager = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/admin/delete', config)
})


/**
 * @param [userId] {String}
 * @return {CurrentUserRole}
 */
export const getUserRoles = defineApi((config, userId) => {
  config.params = { userId: userId }
  http.get('/admin/user_roles', config)
})

export const updateMyPassword = defineApi((config, password) => {
  config.params = {
    password: password
  }
  http.post('/admin/update/my_password', config)
})

export const batchApplyRole = defineApi((config, data) => {
  config.data = data
  http.post('/admin/role/batch_apply', config)
})

export const batchUpdatePassword = defineApi((config, data) => {
  config.data = data
  http.post('/admin/password/batch_update', config)
})
