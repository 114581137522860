<template>
  <div class="container">
    <div v-if="order">


      <div class="header">
        <div class="order-no font-bold font-large-x">{{order.orderNo}}</div>
        <div class="text-muted font-normal">下单日期：{{order.createTime}}</div>
        <div class="status-bar">
          <div class="status status-default" :class="'status-' + order.status">{{statusMapping[order.status]}}</div>
          <div class="status status-pay" :class="'status-' + order.statusPay">{{statusPayMapping[order.statusPay]}}</div>
          <div class="status status-ship" style="background-color: #368e0c; color: #fff;" v-if="order.allShip">全部发完</div>
          <div class="status status-ship" :class="'status-' + order.statusShipping" v-else>{{statusShipMapping[order.statusShipping]}}</div>
          <div class="status status-customize" v-if="hasCustomizeProduct">定制</div>
        </div>
      </div>


      <a-row :gutter="16">

        <a-col :span="8">
          <!-- 客户地址 -->
          <a-card title="客户" class="section-customer">
            <div style="padding: 6px 0;">
              <span class="font-large-x full">{{order.email}}</span>
            </div>

            <div>
              <span class="font-mini font-bold" style="flex: 1; margin-right: 10px;">收货地址</span>
            </div>
            <div class="info-detail" style="margin-top: 10px;">
              <div class="detail-item">
                <label>姓名</label>
                <span>{{order.receiverLastName}} {{order.receiverFirstName}}</span>
              </div>
              <div class="detail-item">
                <label>电话</label>
                <span>{{order.receiverPhone}}</span>
              </div>
              <div class="detail-item">
                <label>国家</label>
                <span>{{order.receiverCountry}}</span>
              </div>
              <div class="detail-item">
                <label>省 / 州</label>
                <span>{{order.receiverState}}</span>
              </div>
              <div class="detail-item">
                <label>城市</label>
                <span>{{order.receiverCity}}</span>
              </div>
              <div class="detail-item">
                <label>地址</label>
                <span>{{order.receiverAddress1}} {{order.receiverAddress2}}</span>
              </div>
              <div class="detail-item">
                <label>邮编</label>
                <span>{{order.receiverPostalCode}}</span>
              </div>
            </div>
          </a-card>

          <!-- 金额 -->
          <a-card :title="'金额 - ' + order.currency" class="section-price">
            <ul>
              <li>
                <div class="label">小计</div>
                <div class="info">{{order.products | orderItemTotal}} 件产品</div>
                <div class="price">{{order.priceProducts}}</div>
              </li>
              <li>
                <div class="label">物流</div>
                <div class="info">{{order.shippingMethod}}</div>
                <div class="price">{{order.priceShipping}}</div>
              </li>
              <li>
                <div class="label">商家物流折扣</div>
                <div class="info"></div>
                <div class="price">{{order.priceShippingDiscount}}</div>
              </li>
              <li>
                <div class="label">平台物流折扣</div>
                <div class="info"></div>
                <div class="price">{{order.priceShippingPlatformDiscount}}</div>
              </li>
              <li>
                <div class="label">税费</div>
                <div class="info"></div>
                <div class="price">{{order.priceTax}}</div>
              </li>
              <li>
                <div class="label">平台佣金</div>
                <div class="info"></div>
                <div class="price">{{order.priceFee}}</div>
              </li>
              <li>
                <div class="label">达人佣金</div>
                <div class="info"></div>
                <div class="price">{{order.priceAffiliateCommission}}</div>
              </li>
              <li>
                <div class="label">商家折扣</div>
                <div class="info"></div>
                <div class="price">{{order.priceDiscount}}</div>
              </li>
              <li>
                <div class="label">平台折扣</div>
                <div class="info"></div>
                <div class="price">{{order.pricePlatformDiscount}}</div>
              </li>
              <li>
                <div class="label">客户应付</div>
                <div class="info"></div>
                <div class="price">{{order.pricePay}}</div>
              </li>
              <li style="font-weight: bold; font-size: 16px;">
                <div class="label">订单收入</div>
                <div class="info"></div>
                <div class="price">{{order.priceOrder}}</div>
              </li>
              <li style="font-weight: bold; font-size: 16px; color: #2f790e;" v-if="order.settlementStatus">
                <div class="label">平台结算</div>
                <div class="info"></div>
                <div class="price">{{(order.priceOrder - order.priceFee - order.priceAffiliateCommission - order.priceTax) | numberScale}}</div>
              </li>

              <li v-if="refundAmount > 0" style="font-weight: bold; font-size: 16px;">
                <div class="label">退款实付</div>
                <div class="info"></div>
                <div class="price">{{pricePayWithRefund}}</div>
              </li>
            </ul>

            <div v-if="order.refunds.length > 0">
              <a-divider orientation="center">退款金额</a-divider>
              <ul class="text-danger">
                <li v-for="r in order.refunds" :key="r.id">
                  <div class="label">{{r.createDate}}</div>
                  <div class="info">
                    <span v-if="r.type === 'platform'">平台退款</span>
                    <span v-else-if="r.type === 'manual'">私下退款</span>
                  </div>
                  <div class="price">{{r.currency}} {{r.amount}}</div>
                </li>
              </ul>
            </div>
          </a-card>
        </a-col>


        <a-col :span="14">
          <!-- 备注 -->
          <a-card title="备注" class="section-remark">
            <div>
              <h5>客户备注</h5>
              <p v-if="order.remark">
                <a-alert :message="order.remark" type="warning" show-icon />
              </p>
              <p v-else>无</p>
            </div>
            <div>
              <h5>
                管理员备注
                <a-button size="small" type="link" @click="handleAdminRemarkEditorOpen()" v-if="$authorityCheck.check($authority.orderRemarkUpdate)">编辑</a-button>
              </h5>
              <p v-if="order.adminRemark">
                <a-alert :message="order.adminRemark" type="info" show-icon />
              </p>
              <p v-else>无</p>
            </div>
          </a-card>

          <!-- 订单关注 -->
          <a-card title="关注" class="section-attention" v-if="order.impAttend">
            <div class="info-detail">
              <div class="detail-item">
                <label>优先级</label>
                <span>
                  <span v-if="order.impPriority === 0">实时关注</span>
                  <span v-else-if="order.impPriority === 3">重要</span>
                  <span v-else>一般</span>
                  <a-button size="small" type="link" @click="$refs.importantOrder.open(order)">编辑</a-button>
                </span>
              </div>
              <div class="detail-item">
                <label>标签</label>
                <span>{{order.impTags}}</span>
              </div>
            </div>

            <a-divider orientation="left">关注留言</a-divider>

            <div class="attention-list">
              <div class="attention-item" v-for="m in attentionMessages" :key="m.dateTime">
                <div class="name">
                  {{m.name}}
                  <div>{{m.dateTime}}</div>
                </div>
                <div class="message">
                  <a-textarea v-model="m.text" auto-size />
                </div>
                <div class="buttons">
                  <a-button @click="handleAddAttentionMessage" :loading="attentionMessageUpdating">修改</a-button>
                </div>
              </div>

              <div class="attention-item">
                <div class="name" style="color: #80181b">留言内容</div>
                <div class="message">
                  <a-textarea v-model="attentionMessageUpdateText" auto-size />
                </div>
                <div class="buttons">
                  <a-button type="primary" @click="handleAddAttentionMessage" :loading="attentionMessageUpdating">添加</a-button>
                </div>
              </div>
            </div>

          </a-card>


          <!-- 产品 -->
          <a-card title="产品" class="section-product">
            <ul class="product-list">
              <li class="product-item" v-for="p in order.products" :key="p.id">
                <div class="product-image" :style="{'background-image': `url(${p.imageUrl})`}" @click="$imageModal.open([{url: p.imageUrl, title: p.title, description: p.sku}])"></div>
                <div class="main-info" style="word-break: break-word;">
                  <div class="product-title">
                    <span>{{p.title}}</span>
                    <a :href="p.productUrl" target="_blank" style="color: #3d71a7;">
                      <a-icon type="link" style="margin-left: 10px;" />
                    </a>
                  </div>
                  <div class="variant">规格：{{p.variant}}</div>
                  <div class="font-mini text-muted">SKU：{{p.sku}}</div>
                  <div class="font-mini" v-if="p.customizeInfo">
                    定制：
                    <span v-for="(item, index) in splitCustomize(p.customizeInfo)" :key="index" class="customize-item">
                    {{item.name}}：{{item.value}} ({{item.unit}})
                  </span>
                  </div>
                  <div class="single font-mini text-muted">
                    <span>单价：{{order.currency}} {{p.salePrice}}</span>
                    <span v-if="p.discountPrice > 0">折后价：{{p.discountPrice}}</span>
                    <span>数量：{{p.total}}</span>
                  </div>
                  <div style="font-weight: bold; font-size: 13px; color: #e87608" v-if="p.produceItemTotal > 0">
                    供应商生产：{{p.produceItemTotal}} 个订单
                  </div>
                </div>
              </li>
            </ul>
          </a-card>
        </a-col>
      </a-row>

    </div>

    <modal
        ref="adminRemarkEditor"
        title="管理员备注编辑"
        @ok="handleAdminRemarkEditorSave"
        :loading="adminRemarkEditor.updating"
    >
      <a-textarea v-model="adminRemarkEditor.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
    </modal>

    <important-order-editor ref="importantOrder"></important-order-editor>
  </div>
</template>

<script>
import CustomizeMixin from '@/mixins/customize'
import {
  ORDER_STATUS_MAPPING,
  ORDER_PAY_STATUS_MAPPING,
  ORDER_SHIP_STATUS_MAPPING
} from '@/constants/order-status'
import { updateAdminRemark } from '@/http/api/order'
import { updateImportantOrderMessage } from '@/http/api/important-order'
import moment from 'moment'
import ImportantOrderEditor from '../comp/important-order-editor'
import kit from '@/utils/kit'

export default {
  components: { ImportantOrderEditor },
  props: {
    order: { type: Object }
  },
  data () {
    return {
      statusMapping: ORDER_STATUS_MAPPING,
      statusPayMapping: ORDER_PAY_STATUS_MAPPING,
      statusShipMapping: ORDER_SHIP_STATUS_MAPPING,
      adminRemarkEditor: {
        updating: false,
        remark: null
      },
      attentionMessages: [],
      attentionMessageUpdateText: null,
      attentionMessageUpdating: false
    }
  },
  mixins: [CustomizeMixin],
  computed: {
    hasCustomizeProduct () {
      if (this.order) {
        return this.order.products.filter(item => item.customizeInfo).length > 0
      } else {
        return false
      }
    },
    refundAmount () {
      let a = 0
      for (const r of this.order.refunds) {
        a += r.amount
      }
      return a
    },
    pricePayWithRefund () {
      return kit.num.toFixedTrimZero(this.order.pricePay - this.refundAmount, 2)
    }
  },
  filters: {
    orderItemTotal (items) {
      if (items) {
        let total = 0
        for (const item of items) {
          total += item.total
        }
        return total
      }
      return 0
    },
    numberScale (n) {
      return kit.num.toFixedTrimZero(n)
    }
  },
  watch: {
    order () {
      if (this.order && this.order.impMessage) {
        this.attentionMessages = JSON.parse(this.order.impMessage)
      }
    }
  },
  methods: {
    onActive (isFirst) {
      if (isFirst) {
        this.onInit()
      }
    },
    onInactive () {
    },
    onInit () {
    },
    handleAdminRemarkEditorOpen () {
      this.adminRemarkEditor.remark = this.order.adminRemark
      this.$refs.adminRemarkEditor.open()
    },
    handleAdminRemarkEditorSave () {
      this.adminRemarkEditor.updating = true
      updateAdminRemark()
        .complete(() => (this.adminRemarkEditor.updating = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.order.adminRemark = this.adminRemarkEditor.remark
          this.$nextTick(() => {
            this.$refs.adminRemarkEditor.close()
          })
        })
        .send(this.order.id, this.adminRemarkEditor.remark)
    },
    handleAddAttentionMessage () {
      const arr = [...this.attentionMessages]
      if (this.attentionMessageUpdateText) {
        const m = {
          dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          name: this.$userStore.state.name,
          text: this.attentionMessageUpdateText
        }
        arr.push(m)
      }
      this.attentionMessageUpdating = true
      updateImportantOrderMessage()
        .complete(() => (this.attentionMessageUpdating = false))
        .success(() => {
          this.$message.success('更新成功。')
          this.attentionMessages = arr
          this.attentionMessageUpdateText = null
        })
        .send(this.order.id, JSON.stringify(arr))
    }
  }
}
</script>

<style lang="less" scoped>

  @deep: ~'>>>';

  .container @{deep} .ant-card-head {
    background-color: #f2f2f2;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > * {
      margin-right: 40px;
    }
  }

  .status-bar {
    flex: 1;
    .status {
      & + .status {
        margin-left: 15px;
      }
      display: inline-block;
      padding: 3px 10px;
      font-size: 13px;
      border-radius: 20px;
      background-color: #d2d2d2;
    }
    .status-produce {
      color: #fff;
      background-color: #e36f1a;
    }
    .status-complete {
      color: #fff;
      background-color: #368e0c;
    }
    .status-close, .status-cancel {
      color: #fff;
      background-color: #bb3e30;
    }
    .status-payPartially, .status-pay {
      color: #fff;
      background-color: #368e0c;
    }
    .status-refund, .status-refundPartially {
      color: #fff;
      background-color: #bb3e30;
    }

    .status-send {
      color: #fff;
      background-color: #0976bb;
    }
    .status-arrived {
      color: #fff;
      background-color: #368e0c;
    }

    .status-customize {
      color: #fff;
      background-color: #a738f6;
    }
  }

  .section-customer {
    .detail-item label {
      width: 60px !important;
    }
  }

  .section-product {
    margin-top: 20px;
    .product-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .product-item + .product-item {
      margin-top: 16px;
    }
    .product-item {
      display: flex;
      .product-image {
        width: 50px;
        height: 50px;
        border: solid 1px #ddd;
        border-radius: 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .main-info {
        flex: 1;
        margin-left: 15px;
        color: #404040;
      }
      .product-title {
        font-size: 14px;
        color: #3d71a7;
      }
      .variant {
        color: #8b8b8b;
      }
      .customize-item + .customize-item {
        margin-left: 38px;
      }
      .customize-item {
        display: inline-block;
        color: #d4371a;
      }
      .single > span + span {
        margin-left: 15px;
      }
    }
  }

  .section-price {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        padding: 5px 0;
        .label {
          width: 100px;
        }
        .info {
          flex: 1;
          padding: 0 10px;
        }
        .price {
          text-align: right;
        }
      }
    }
  }

  .section-attention {
    margin-top: 20px;

    .attention-list {
      .attention-item + .attention-item {
        margin-top: 10px;
      }
      .attention-item {
        display: flex;
        .name {
          width: 120px;
          font-size: 12px;
          text-align: right;
        }
        .message {
          flex: 1;
          padding: 0 10px;
        }
        .buttons {

        }
      }
    }
  }

</style>
