/**
 * @author jiangxingshang
 * @date 2022/12/7
 */

import defineApi from '../define-api'
import http from '../application-api'


export const getRoleUiJson = defineApi((config) => {
  http.get('/role_ui/get/ui_json', config)
})

export const updateRoleUiJson = defineApi((config, jsonStr) => {
  config.params = {
    json: jsonStr
  }
  http.post('/role_ui/update/ui_json', config)
})

export const listRoleUiCodeList = defineApi((config, roleId) => {
  config.params = { roleId: roleId }
  http.get('/role_ui/list_ui_code/by_role', config)
})

export const applyUiToRole = defineApi((config, roleId, uiCode, uiType) => {
  config.params = {
    roleId: roleId,
    uiCode: uiCode,
    uiType: uiType
  }
  http.post('/role_ui/apply/to_role', config)
})

export const removeUiFromRole = defineApi((config, roleId, uiCode) => {
  config.params = {
    roleId: roleId,
    uiCode: uiCode
  }
  http.post('/role_ui/remove/from_role', config)
})
