<template>
  <div>
    <div class="info-detail">
      <div class="detail-item">
        <label>产品</label>
        <span>
          <a-button @click="handleOpenProductDialog">选择产品（{{section.products.length}}）</a-button>
          <draggable
              tag="div"
              v-model="section.products"
              v-bind="productDrag.dragOptions"
              @start="productDrag.drag = true"
              @end="productDrag.drag = false"
          >
            <transition-group tag="ul" type="transition" :name="!productDrag.drag ? 'flip-list' : null" class="sortable-list list-group" style="max-height: 250px; overflow-y: auto; margin: 10px 0;">
              <li class="list-group-item" v-for="(p, index) in section.products" :key="p.id">
                <div class="product-wrapper">
                  <div>
                    <div class="order-icon">
                      <a-icon type="unordered-list" />
                    </div>
                  </div>
                  <div>
                    <div class="image-item small" v-lazy:background-image="p.imageUrl"></div>
                  </div>
                  <div class="title">{{index + 1}}. {{p.title}}</div>
                  <div class="price">{{p.price}}</div>
                  <div class="buttons">
                    <a-button size="small" icon="delete" type="danger" @click="removeProduct(p)"></a-button>
                  </div>
                </div>
              </li>
            </transition-group>
          </draggable>
        </span>
      </div>

      <div class="detail-item">
        <label>圆角图片</label>
        <span>
          <a-switch v-model="section.rounded" />
        </span>
      </div>

      <div class="detail-item">
        <label>产品每行几个</label>
        <span>
          <a-input-number v-model="section.countPerRow" :min="1" :max="6" style="width: 100px;" />
        </span>
      </div>

      <div class="detail-item">
        <label>产品间隔</label>
        <span>
          <a-input-number v-model="section.gap" :min="0" :max="50" style="width: 100px;" />
        </span>
      </div>

      <div class="detail-item">
        <label>显示金额</label>
        <span>
          <a-switch v-model="section.showPrice" />
        </span>
      </div>

      <div class="detail-item">
        <label>货币符号</label>
        <span>
          <a-input v-model="section.priceSymbol" style="width: 100px;" />
        </span>
      </div>
    </div>
    <shop-product-selector ref="product"></shop-product-selector>
  </div>
</template>

<script>
import ShopProductSelector from '@/components-business/shop-product-select'
import ProductModel from '../model/product'
import kit from '@/utils/kit'

export default {
  components: { ShopProductSelector },
  props: {
    section: { type: ProductModel, required: true }
  },
  data () {
    return {
      productDrag: {
        dragOptions: {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          handle: '.order-icon'
        },
        drag: false
      }
    }
  },
  computed: {
    productIdMap () {
      const map = {}
      for (const item of this.section.products) {
        map[item.id] = true
      }
      return map
    }
  },
  watch: {
  },
  methods: {
    handleOpenProductDialog () {
      this.$refs.product.open(products => {
        for (const p of products) {
          if (this.productIdMap[p.id]) {
            continue
          }
          this.section.products.push({
            id: p.id,
            title: p.title,
            price: p.price,
            imageUrl: p.imageUrl,
            productUrl: p.productUrl
          })
        }
      })
    },
    removeProduct (p) {
      kit.arr.removeItem(this.section.products, p)
    }
  }
}
</script>

<style lang="less" scoped>
.product-wrapper {
  display: flex;
  .order-icon {
    margin-right: 10px;
    cursor: move;
  }
  .title {
    flex: 1;
    padding: 0 10px;
  }
  .price {
    padding-right: 10px;
  }
}
</style>
