import kit from '@/utils/kit'

export default {
  data () {
    return {
      editor: {
        editingItem: null,
        form: this.createEditorForm()
      }
    }
  },
  methods: {
    /**
     * 返回一个表单数据对象
     */
    createEditorForm () {
      return {}
    },
    /**
     * 返回一个列表，编辑好的数据对象会被push到这个列表里
     */
    getListItems () {
      return []
    },
    openEditor (item) {
      if (item) {
        this.editor.editingItem = item
        kit.obj.getProperties(this.editor.form, item)
      }
      this.$refs.editor.open()
    },
    onEditorOk () {
      let item
      let insert = true
      if (this.editor.editingItem) {
        item = this.editor.editingItem
        insert = false
      } else {
        item = this.createEditorForm()
        item.id = kit.str.id()
      }
      Object.assign(item, this.editor.form)
      if (insert) {
        this.getListItems().push(item)
      }
      this.$refs.editor.close()
    },
    onEditorClosed () {
      this.editor.editingItem = null
      this.editor.form = this.createEditorForm()
    }
  }
}
