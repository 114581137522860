import defineApi from '../define-api'
import http from '../application-api'

export const getStockProductById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/stock/by_id', config)
})

export const getStockProductBySku = defineApi((config, sku) => {
  config.params = { sku: sku }
  http.get('/stock/by_sku', config)
})

export const listStockProducts = defineApi((config, params) => {
  config.params = params
  http.get('/stock/list', config)
})

export const getSkuTypeOptions = defineApi((config) => {
  http.get('/stock/list/options/sku_type', config)
})

export const getSourceOptions = defineApi(config => {
  http.get('/stock/list/source/options', config)
})

export const addStockProduct = defineApi((config, data) => {
  config.data = data
  http.post('/stock/add', config)
})

export const updateStockProduct = defineApi((config, data) => {
  config.data = data
  http.post('/stock/update', config)
})

export const deleteStockProduct = defineApi((config, idArray) => {
  config.params = {
    idList: idArray.join(',')
  }
  http.post('/stock/delete', config)
})

export const getShopOptions = defineApi((config) => {
  http.get('/stock/options/shop', config)
})

export const syncFromShop = defineApi((config, shopId, productIds, tags) => {
  config.params = {
    shopId: shopId,
    productIds: productIds,
    tags: tags
  }
  http.post('/stock/import/from_shop', config)
})

export const uploadStockUpdateFile = defineApi((config, warehouseId, replace, file, message) => {
  const form = new FormData()
  form.append('file', file)
  form.append('warehouseId', warehouseId)
  form.append('replace', replace)
  form.append('message', message)
  config.data = form
  http.upload('/stock/update/quantity/import_file', config)
})

export const updateRemarkFromFile = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.upload('/stock/update/remark/from_file', config)
})

/**
 * 手动更新库存
 * @param data {Object} {sku: String, warehouseId: String, quantity: Number, message: String}
 */
export const updateStockQuantityManual = defineApi((config, data) => {
  config.data = data
  http.post('/stock/update/quantity', config)
})

export const getStockBySku = defineApi((config, sku, warehouseId) => {
  config.params = { sku: sku, warehouseId: warehouseId }
  http.get('/stock/sku/stock', config)
})

export const getRecords = defineApi((config, params) => {
  config.params = params
  http.get('/stock/stock_records', config)
})

export const getAlerts = defineApi((config, params) => {
  config.params = params
  http.get('/stock/alerts', config)
})

export const getSaleReportGroupByStyle = defineApi((config, params) => {
  config.params = params
  http.get('/stock/sale_report', config)
})

export const getSaleReport = defineApi((config, style, startTime, endTime) => {
  config.params = {
    startTime: startTime,
    endTime: endTime
  }
  http.get('/stock/sale_report/' + style, config)
})

export const exportSaleReport = function (params) {
  const url = http.baseUrl + '/stock/sale_report/excel?'
  const paramArr = []
  for (const key of Object.keys(params)) {
    if (params[key] != null && params[key] !== undefined) {
      paramArr.push(key + '=' + params[key])
    }
  }
  return url + paramArr.join('&')
}

export const exportInventory = function (params) {
  const url = http.baseUrl + '/stock/export?'
  const paramArr = []
  for (const key of Object.keys(params)) {
    if (params[key] != null && params[key] !== undefined) {
      paramArr.push(key + '=' + params[key])
    }
  }
  return url + paramArr.join('&')
}

export const uploadSkuCsvToGetStock = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/stock/sku_upload/get_stock', config)
})

/**
 * 转移SKU的库存到另一个SKU
 * @param dto {Object} {deleteOldSku: boolean, skuList: [[sku1, sku2]]}
 */
export const transferSkuStock = defineApi((config, dto) => {
  config.data = dto
  http.post('/stock/sku_stock/transfer', config)
})

export const getColorOptions = defineApi(config => {
  http.get('/stock/options/color', config)
})

export const getCategoryOptions = defineApi(config => {
  http.get('/stock/options/category', config)
})

export const checkSku = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/stock/check', config)
})

export const updateWeightVolume = defineApi((config, sku, weight, length, width, height) => {
  config.params = {
    sku: sku,
    weight: weight,
    length: length,
    width: width,
    height: height
  }
  http.post('/stock/update/weight_volume', config)
})


export const listComposite = defineApi((config, mainStockProductId) => {
  config.params = { mainStockProductId: mainStockProductId }
  http.get('/stock/composite/list', config)
})

export const addComposite = defineApi((config, mainStockProductId, subStockProductIdArray) => {
  config.params = {
    mainStockProductId: mainStockProductId,
    subStockProductIdList: subStockProductIdArray.join(',')
  }
  http.post('/stock/composite/add', config)
})

export const updateComposite = defineApi((config, id, total) => {
  config.params = {
    id: id,
    total: total
  }
  http.post('/stock/composite/update', config)
})

export const deleteComposite = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/stock/composite/delete', config)
})

export const listBySkuList = defineApi((config, skuList) => {
  config.params = { skuList: skuList.join(',') }
  http.get('/stock/list/by_sku_list', config)
})
