<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button type="primary" @click="$refs.userEditor.open()">添加</a-button>
      <a-button @click="handleBatchRoleApplyEditorOpen()" :disabled="selectedRowKeys.length === 0">批量分配角色</a-button>
      <a-button @click="$refs.batchPasswordUpdateEditor.open()" :disabled="selectedRowKeys.length === 0">批量更新密码</a-button>
      <a-button @click="$refs.dept.open()">部门管理</a-button>
      <a-button @click="navRole">角色管理</a-button>
    </div>
    <div slot="top">
      <div class="search-form">
        <a-form-model layout="inline" class="form">
          <a-form-model-item label="关键字">
            <a-input v-model="searchForm.keyword" :allowClear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select v-model="searchForm.status" :allowClear="true" style="width: 100px;">
              <a-select-option :key="1">可用</a-select-option>
              <a-select-option :key="0">禁用</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="类型">
            <a-select v-model="searchForm.type" :allowClear="true" style="width: 100px;">
              <a-select-option v-for="t in typeOptions" :key="t.id">{{t.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="部门">
            <a-select v-model="searchForm.deptId" :allow-clear="true" style="width: 120px;">
              <a-select-option v-for="s in deptOptions" :key="s.id">{{s.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="供应商">
            <a-select v-model="searchForm.supplierId" style="width: 140px;" :allow-clear="true">
              <a-select-option v-for="opt in supplierOptions" :key="opt.id" :value="opt.id">{{opt.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
          </a-form-model-item>
        </a-form-model>
        <div class="buttons">
          <a-button @click="loadData">查询</a-button>
        </div>
      </div>
    </div>


    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onRowSelectChanged }"
    >
      <div slot="action" slot-scope="v, record">
<!--        <a-button size="small" @click="$refs.sessEditor.open(record)">会话</a-button>-->
        <a-button v-if="record.editable" size="small" @click="$refs.userEditor.open(record)">编辑</a-button>
      </div>
      <div slot="dept" slot-scope="row">{{row.deptName}}<span v-show="row.deptLeader" class="text-success">（领导）</span></div>
      <status slot="status" slot-scope="status" :status="status"></status>
      <status slot="wechatSubscribe" slot-scope="wechatSubscribe" :status="wechatSubscribe" enabledText="已订阅" disabledText="未订阅"></status>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>


    <user-editor ref="userEditor" :supplier-options="supplierOptions" @saved="handleUserSave"></user-editor>
    <session-editor ref="sessEditor" @sessionCount="handleSessionTotalChange"></session-editor>


    <modal
        ref="batchRoleApplyEditor"
        title="批量角色分配或移除"
        :width="500"
        :loading="batchRoleApplyEditor.updating"
        @ok="handleBatchRoleApplyEditorSave"
        @closed="handleBatchRoleApplyEditorClose"
    >
      <a-switch v-model="batchRoleApplyEditor.isApply" checked-children="分配角色" un-checked-children="移除角色" style="margin-bottom: 10px;" />
      <a-list size="small" bordered :data-source="batchRoleApplyEditor.enabledRoles">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-checkbox v-model="item._checked"></a-checkbox>
          {{item.name}}
        </a-list-item>
      </a-list>
    </modal>

    <modal
        ref="batchPasswordUpdateEditor"
        title="批量更新用户密码"
        :width="500"
        :loading="batchPasswordUpdateEditor.updating"
        @ok="handleBatchPasswordUpdateEditorSave"
        @closed="handleBatchPasswordUpdateEditorClose"
    >
      <a-input v-model="batchPasswordUpdateEditor.password" placeholder="请输入密码"></a-input>
    </modal>

    <dept-manager ref="dept"></dept-manager>

  </app-page>
</template>

<script>
import { ROUTE_USER, ROUTE_ROLE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { managerList, batchApplyRole, batchUpdatePassword } from '@/http/api/admin'
import { listAllEnabledRoles } from '@/http/api/role'
import { listSupplier } from '@/http/api/supplier'
import UserEditor from './comp/user-editor'
import SessionEditor from './comp/session-editor'
import { USER_TYPE_LIST, USER_TYPE_MAPPING, USER_TYPE_ADMIN } from '@/constants/user-type'
import { listAll as listDept } from '@/http/api/dept'
import DeptManager from '@/components-business/dept-manager'

export default {
  components: { UserEditor, SessionEditor, DeptManager },
  data () {
    return {
      routeName: ROUTE_USER,
      dataColumns: [
        { title: '操作', width: 120, scopedSlots: { customRender: 'action' } },
        { title: '姓名', dataIndex: 'name', width: 150 },
        { title: '帐号', dataIndex: 'username' },
        { title: '用户类型', dataIndex: 'type', customRender: value => USER_TYPE_MAPPING[value] },
        { title: '标签', dataIndex: 'tags' },
        { title: '部门', scopedSlots: { customRender: 'dept' } },
        { title: '所属供应商', dataIndex: 'supplierName' },
        { title: '创建日期', dataIndex: 'createDate' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
        { title: '微信订阅', dataIndex: 'wechatSubscribe', scopedSlots: { customRender: 'wechatSubscribe' } }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        keyword: null,
        status: 1,
        type: USER_TYPE_ADMIN,
        supplierId: null,
        roleIdList: [],
        deptId: null
      },
      typeOptions: USER_TYPE_LIST,
      supplierOptions: [],
      deptOptions: [],
      selectedRowKeys: [],
      batchRoleApplyEditor: {
        enabledRoles: [],
        selectedRoleIds: [],
        isApply: true,
        updating: false
      },
      batchPasswordUpdateEditor: {
        updating: false,
        password: null
      }
    }
  },
  mixins: [PaginationMixin, AntdTableHeightMixin],
  methods: {
    loadData () {
      const p = Object.assign({}, this.searchForm)
      this.dataLoading = true
      managerList()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleSessionTotalChange (username, total) {
      this.dataList.find(u => u.username === username).sessionTotal = total
    },
    handleUserSave (user) {
      this.loadData()
    },
    navRole () {
      this.$router.push({ name: ROUTE_ROLE })
    },
    onRowSelectChanged (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleBatchRoleApplyEditorOpen () {
      listAllEnabledRoles().success(resp => {
        for (const item of resp.data) {
          item._checked = false
        }
        this.batchRoleApplyEditor.enabledRoles = resp.data
      }).send()
      this.$refs.batchRoleApplyEditor.open()
    },
    handleBatchRoleApplyEditorSave () {
      const selectedIds = []
      for (const r of this.batchRoleApplyEditor.enabledRoles) {
        if (r._checked) {
          selectedIds.push(r.id)
        }
      }
      const data = {
        apply: this.batchRoleApplyEditor.isApply,
        roleIdList: selectedIds,
        userIdList: this.selectedRowKeys
      }
      this.batchRoleApplyEditor.updating = true
      batchApplyRole()
        .complete(() => (this.batchRoleApplyEditor.updating = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.$nextTick(() => (this.$refs.batchRoleApplyEditor.close()))
        })
        .send(data)
    },
    handleBatchRoleApplyEditorClose () {
      for (const r of this.batchRoleApplyEditor.enabledRoles) {
        r._checked = false
      }
    },
    handleBatchPasswordUpdateEditorSave () {
      this.batchPasswordUpdateEditor.updating = true
      batchUpdatePassword()
        .complete(() => (this.batchPasswordUpdateEditor.updating = false))
        .success(() => {
          this.$message.success('保存成功。')
          this.$nextTick(() => (this.$refs.batchPasswordUpdateEditor.close()))
        })
        .send({
          userIdList: this.selectedRowKeys,
          password: this.batchPasswordUpdateEditor.password
        })
    },
    handleBatchPasswordUpdateEditorClose () {
      this.batchPasswordUpdateEditor.password = null
    }
  },
  mounted () {
    this.loadData()

    listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
    listDept().success(resp => (this.deptOptions = resp.data)).send()
  }
}
</script>

<style lang="less" scoped>

</style>
