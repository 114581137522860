<template>
  <app-page :route-name="routeName" :use-tcb-layout="false" :page-loading="pageLoading">
    <div class="wrapper">
      <communication style="flex: 1;" :influencer="influencer"></communication>
      <base-info style="width: 500px;" :influencer="influencer"></base-info>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_INFLUENCER_DETAIL } from '@/router/router-constants'
import Communication from '@/pages/influencer/detail/communication'
import BaseInfo from '@/pages/influencer/detail/base-info'
import { getById } from '@/http/api/influencer'

export default {
  components: { Communication, BaseInfo },
  data () {
    return {
      routeName: ROUTE_INFLUENCER_DETAIL,
      pageLoading: false,
      influencer: null
    }
  },
  computed: {
    influencerId () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.pageLoading = true
    getById()
      .complete(() => (this.pageLoading = false))
      .success(resp => (this.influencer = resp.data))
      .send(this.influencerId)
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
</style>
