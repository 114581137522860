<template>
  <modal
      ref="modal"
      title="达人合作状态快速标签查询配置"
      :loading="loading"
      @ok="handleOk"
  >
    <a-button @click="handleReset()" :loading="loading">重新配置</a-button>
    <draggable
        tag="div"
        style="margin-top: 10px;"
        v-model="statusOptions"
        v-bind="drag.dragOptions"
        @start="drag.drag = true"
        @end="drag.drag = false"
    >
      <transition-group tag="ul" type="transition" :name="!drag.drag ? 'flip-list' : null" class="sortable-list list-group">
        <li class="list-group-item status-opt" v-for="(opt, index) in statusOptions" :key="opt.id">
          <a-switch checked-children="有效" un-checked-children="无效" v-model="opt.enabled" style="margin-right: 10px;" />
          {{index + 1}}. {{opt.name}}
        </li>
      </transition-group>
    </draggable>
  </modal>
</template>

<script>
import { getCooperationStatusSearchConfig, updateCooperationStatusSearchConfig } from '@/http/api/influencer'
import Draggable from 'vuedraggable'

export default {
  components: { Draggable },
  data () {
    return {
      loading: false,
      statusOptions: [],
      drag: {
        dragOptions: { handle: '.status-opt' },
        drag: false
      }
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open () {
      this.$refs.modal.open()
      this.loadOptions()
    },
    loadOptions () {
      getCooperationStatusSearchConfig()
        .success(resp => {
          this.statusOptions = resp.data
        })
        .send()
    },
    handleOk () {
      this.save(JSON.stringify(this.statusOptions), configJsonString => {
        this.$emit('updated', JSON.parse(configJsonString))
      })
    },
    handleReset () {
      this.save(null, () => {
        this.loadOptions()
      })
    },
    save (configJsonString, successCallback) {
      this.loading = true
      updateCooperationStatusSearchConfig()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$message.success('保存成功。')
          successCallback && successCallback(configJsonString)
        })
        .send(configJsonString)
    }
  }
}
</script>

<style lang="less" scoped>
.status-opt {
  cursor: pointer;
  background-color: #fff;
}
</style>
